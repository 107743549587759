import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { Button, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import utils, { colors } from "utils";
import ButtonBox from "components/ButtonBox";
import {
  AddRounded,
  CheckCircleOutlineRounded,
  ErrorOutlineOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { LoadingComponent } from "components/Loading";
import AddApplicant from "./AddApplicant";
import { format as formatDate } from "fecha";
import CustomLoadingButton from "components/Button";
import useOnboardingData from "../../hooks/useOnboardingData";
import { IStepProps } from "../../types";
import { OnboardingStepsCategories, OnboardingStepsData } from "../../data";
import { InfoToolTip } from "components/InfoToolTip";
import { usePostHog } from "posthog-js/react";
import { isValidDateString } from "utils/helpers";

const ApplicantDetails = ({ setCurrentStep }: IStepProps) => {
  const { t } = useTranslation();
  const { onboardingData, isOnboardingDataLoading } = useOnboardingData();

  const [showAddApplicantModal, setShowAddApplicantModal] =
    useState<boolean>(false);
  const [isNewApplicant, setIsNewApplicant] = useState<boolean>(true);

  const applicant = onboardingData?.applicant;

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const posthog = usePostHog();

  const isError = useMemo(
    () => !applicant || !!applicant.validationErrors?.length,
    [applicant]
  );

  if (isOnboardingDataLoading) {
    return (
      <Stack height={"200px"} alignItems={"center"} justifyContent={"center"}>
        <LoadingComponent />
      </Stack>
    );
  }

  const isDisabled = !applicant || !!applicant.validationErrors?.length;

  const handleOnClose = () => {
    setShowAddApplicantModal(false);
    setIsNewApplicant(true);
  };

  return (
    <>
      {showAddApplicantModal && (
        <AddApplicant
          isNewApplicant={isNewApplicant}
          showAddApplicantModal={showAddApplicantModal}
          handleOnClose={handleOnClose}
        />
      )}
      <Stack mt={isTablet ? 3 : 4} mb={5}>
        <Grid container spacing={isTablet ? 2 : 3}>
          <Grid item xs={12} sm={6} md={4}>
            <Stack
              width={"100%"}
              minHeight={"200px"}
              height={"100%"}
              component={Button}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                bgcolor: colors.lightGrey,
                borderRadius: 6,
                p: 3,
                transition: "all .3s ease-out",
                "&:hover": {
                  bgcolor: colors.grey,
                },
              }}
              onClick={() => {
                posthog?.capture("money_onboarding_add_applicant_click");

                setShowAddApplicantModal(true);
              }}
            >
              <Stack borderRadius={"50%"} bgcolor={colors.grey} p={2}>
                <AddRounded />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {applicant && applicant.firstName && applicant.lastName && (
              <Stack
                width={"100%"}
                minHeight={"200px"}
                height={"100%"}
                justifyContent={"space-between"}
                gap={2}
                border={`1px solid ${colors.borderBlue}`}
                borderRadius={6}
                bgcolor={colors.lightBlue}
                p={3}
              >
                <Stack justifyContent={"center"} alignItems={"flex-start"}>
                  <Stack
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={1}
                  >
                    <Typography
                      fontSize={16}
                      fontWeight={500}
                      sx={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {`${applicant.firstName} ${applicant.lastName}`}
                    </Typography>
                    {isError ? (
                      <InfoToolTip
                        title={t("general.missingInformation") as string}
                        description={
                          <ul
                            style={{
                              listStyleType: "disc",
                              paddingLeft: "12px",
                            }}
                          >
                            {Array.from(
                              new Set(applicant?.validationErrors)
                            ).map((error, index) => (
                              <li key={index}>{error}</li>
                            ))}
                          </ul>
                        }
                        icon={
                          <ErrorOutlineOutlined
                            sx={{
                              fontSize: "18px",
                              color: colors.lightContentWarning,
                              display: "flex",
                            }}
                          />
                        }
                      />
                    ) : (
                      <CheckCircleOutlineRounded
                        sx={{ fontSize: "18px", color: colors.green }}
                      />
                    )}
                  </Stack>
                  <Typography color={colors.contentTertiary}>
                    {isError
                      ? t("general.missingInformation")
                      : isValidDateString(applicant.dateOfBirth) &&
                        formatDate(
                          new Date(applicant.dateOfBirth ?? 0),
                          "MM/DD/YYYY"
                        )}
                  </Typography>
                </Stack>
                <CustomLoadingButton
                  dark={false}
                  endIcon={<VisibilityOutlined />}
                  buttonText={t("general.review")}
                  onClick={() => {
                    posthog?.capture("money_onboarding_review_applicant_click");

                    setIsNewApplicant(false);
                    setShowAddApplicantModal(true);
                  }}
                  styles={{
                    bgcolor: colors.borderBlue,
                    ":hover": {
                      bgcolor: colors.lightPrimaryBlue,
                    },
                  }}
                />
              </Stack>
            )}
          </Grid>
        </Grid>
      </Stack>
      <ButtonBox
        isLoading={isOnboardingDataLoading}
        disabled={isDisabled}
        onClickBack={() => {
          posthog?.capture("money_onboarding_applicant_back_btn_click");

          setCurrentStep(
            OnboardingStepsData[OnboardingStepsCategories.membersInformation]
          );
        }}
        onClickNext={() => {
          posthog?.capture("money_onboarding_applicant_next_btn_click");

          setCurrentStep(
            OnboardingStepsData[
              OnboardingStepsCategories.riskAssessmentInformation
            ]
          );
        }}
      />
    </>
  );
};

export default ApplicantDetails;
