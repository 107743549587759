import { Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { colors } from "utils";
import jiv from "./assets/jiv.png";
import Consultation from "./components/Consultation";
import Button from "components/Button";
import ConsultationsModal from "./components/ConsultationsModal";
import useCompany from "graphql/hooks/UseCompany";
import { LoadingComponent } from "components/Loading";

const Upcoming = () => {
  const { currentCompany } = useCompany();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const consultations = useMemo(
    () => currentCompany?.upcomingConsultations ?? [],
    [currentCompany?.upcomingConsultations]
  );

  const hasConsultations = useMemo(
    () => consultations && consultations.length > 0,
    [consultations]
  );

  if (!currentCompany) return <LoadingComponent />;

  const onShowModalHandler = () => {
    setIsModalOpen(true);
  };
  const onCloseModalHandler = () => {
    setIsModalOpen(false);
  };

  return (
    <Stack
      sx={{
        bgcolor: colors.lightGrey,
        borderRadius: 8,
        p: 4,
        height: "100%",
      }}
    >
      <Stack gap={4} alignItems={"center"}>
        <Typography alignSelf={"flex-start"} fontWeight={700} variant="h3">
          {hasConsultations ? "Upcoming consultations 📆" : "Meet our CPAs ✍️"}
        </Typography>
        {hasConsultations ? (
          consultations.length > 3 ? (
            <>
              {consultations
                .slice(0, 2)
                .map(
                  (consultation, index) =>
                    consultation && (
                      <Consultation key={index} consultation={consultation} />
                    )
                )}
              <Button
                dark={false}
                buttonText={`See all (${consultations.length})`}
                onClick={onShowModalHandler}
                styles={{ bgcolor: colors.grey, alignSelf: "flex-end" }}
              />
            </>
          ) : (
            consultations.map(
              (consultation, index) =>
                consultation && (
                  <Consultation key={index} consultation={consultation} />
                )
            )
          )
        ) : (
          <img
            style={{ width: "auto", maxWidth: "250px" }}
            alt="doola_jiv"
            src={jiv}
          ></img>
        )}
      </Stack>
      {hasConsultations && (
        <ConsultationsModal
          consultations={consultations}
          open={isModalOpen}
          onClose={onCloseModalHandler}
        />
      )}
    </Stack>
  );
};

export default Upcoming;
