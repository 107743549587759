import { useContext } from "react";
import { OnboardingContext } from "./OnboardingProvider";
import FlowAside, { AccordionContents } from "components/FlowAside";
import { LoadingComponent } from "components/Loading";
import useCompany from "graphql/hooks/UseCompany";
import { EntityType } from "graphql/__generated__/types";

const accordionContents: AccordionContents = {
  0: {
    callOut: {
      title: "You’re in good hands with doola!",
      body: "Watch this area for best practices and tips to guide you through formation.",
    },
    tips: [
      {
        title: "What You’ll Need",
        content: [
          {
            title: "Company Information",
            body: "The state and the US Internal Revenue Service (IRS) want to know more about your company including what you’ll be doing and where it’s located. This ultimately will be used to assess any tax liabilities and whether you’re complying with tax laws and regulations.",
          },
          {
            title: "Member Information",
            body: "A high quality operating agreement, like the one you get with your formation through doola, requires listing all the members within an organization, who they are to the company, and their ownership. Some states may also require this for record keeping, operation, or taxation purposes.",
          },
        ],
      },
      {
        title: "If You Need Help",
        content: [
          {
            title: "Best Practices & Tips",
            body: "Watch this area on every screen for helpful guidance. You’ll be able to see why this information is requested and how it’s used. Most customers are able to complete this within a few minutes using this guidance.",
          },
          {
            title: "Consultation",
            body: "doola’s tax advisors are available for consultations to help you set up  your company. If you’re already a doola Compliance Plan customer, a consultation with a tax advisor or CPA is included. Contact us to upgrade to Compliance Plan with doola or schedule an advisory consultation.",
          },
        ],
      },
    ],
  },
  1: {
    callOut: {
      title: "Company name is up first",
      body: "This is the official name of the company to the state. Your  brand, website URL, and social media handles can be, and often times are, different.",
    },
    tips: [
      {
        title: "Why Names Get Rejected",
        content: [
          {
            title: "Similar Names",
            body: "If the proposed company name is too similar to an existing company name in the state, the state may reject it to avoid confusion and prevent trademark infringement.",
          },
          {
            title: "Restricted Words",
            body: "Some words and phrases may be restricted or prohibited in company names in certain states. For example, some states may not allow the use of words like 'bank' or 'insurance', for example, unless the company is licensed to provide those services.",
          },
          {
            title: "Offensive Language",
            body: "States may reject company names that contain offensive language or words that are deemed inappropriate.",
          },
          {
            title: "Misleading Names",
            body: "States may reject company names that are misleading or deceptive, such as names that suggest the company is associated with a government agency or that it provides services it does not actually offer.",
          },
          {
            title: "Noncompliance",
            body: "If the proposed company name does not comply with the state's naming requirements, such as including the required company identifier (e.g. 'LLC' or 'Inc.'), the state may reject it.",
          },
        ],
      },
    ],
  },
  2: {
    callOut: {
      title: "The state needs to know where you are",
      body: "This is the address the state expects you or someone you designate to be able to receive important tax and legal documents on behalf of the company. Many companies use a Registered Agent or RA for this.",
    },
    tips: [
      {
        title: "How to choose an Address",
        content: [
          {
            title: "Legal Compliance",
            body: "The state will typically require an address within the state they are registered to do business. Using the doola Registered Agent or similar service you can meet and stay in compliance with the local laws.",
          },
          {
            title: "Privacy",
            body: "When using the doola Registered Agent or similar service, you can avoid listing your home or office address in state public records. This is a good way to avoid unwanted solicitation and guard your privacy.",
          },
          {
            title: "Convenience",
            body: "The state requires this address to ensure prompt and reliable delivery of legal and tax documents. Most often, you are liable regardless of delivery so it’s in your best interest to choose an address where legal and tax documents can be received or forwarded.",
          },
          {
            title: "Flexibility",
            body: "If you anticipate changing your address frequently or not having a set address within the state, the doola Registered Agent can handle receiving your state compliance notifications while your address changes.",
          },
        ],
      },
    ],
  },
  3: {
    callOut: {
      title: "Your industry says a lot about your business",
      body: "Try to choose the industry that best aligns with your company to take advantage of applicable credits and tax treatments.",
    },
    tips: [
      {
        title: "How to pick your industry",
        content: [
          {
            title: "Use Standardized Industry Classifications",
            body: "The IRS uses classifications based on the North American Industry Classification System or NAICS. You can use these as a guide to choose the industry your new company best belongs in.",
          },
          {
            title: "Consider your primary source of income",
            body: "If your business offers multiple products or services, it’s best to consider your largest source of revenue for your industry.",
          },
          {
            title: "Look at similar businesses",
            body: "Research your competitors or similar businesses to see how they are classified. You can use the same classification to accurately choose your industry.",
          },
          {
            title: "Consult with doola",
            body: "Sometimes it’s best to chat with a tax expert. If you’re a doola Compliance customer, this consultation is included. Contact us to upgrade to Total Compliance or schedule an advisory consultation through this link.",
          },
        ],
      },
      {
        title: "Choose your industry wisely",
        content: [
          {
            title: "Legal Compliance",
            body: "Based on your company’s industry, the state may require specific licenses, permits, or certifications to legally operate.",
          },
          {
            title: "Taxation",
            body: "The IRS and state will use your industry to determine if your new company is subject to specific tax regulations and requirements or even favorable tax credits.",
          },
          {
            title: "Statistical Analysis",
            body: "The state uses your industry to understand the types of business conducted within the state. They may use this information to make policy decisions that could impact future compliance.",
          },
          {
            title: "Business Assistance",
            body: "Some states offer specific industries favorable credits and resources to support businesses. Your industry may allow you special access to information, programs, or even funding unique to your needs.",
          },
        ],
      },
    ],
  },
  4: {
    callOut: {
      title: "What you’re known for",
      body: "The IRS needs to know what you primarily sell, manufacture, or provide to classify and tax you appropriately. In other words, what your customers come to you for in simple terms.",
    },
    tips: [
      {
        title: "How to describe your business",
        content: [
          {
            title: "Keep it concise",
            body: "The IRS needs a brief and accurate description. It should be clear and to the point. You don’t need to get into differentiators or infuse your brand here.",
          },
          {
            title: "Focus on your primary activity",
            body: "Your business might do a few things so think about how it all comes together or what you expect your customers to know you for. For example, let’s say you sell clothing on your website for men and women that feature animals printed on them. You could simply say you’re a “Clothing retailer”.",
          },
          {
            title: "Be Specific",
            body: "If possible, use specific terms to describe what you do instead of generic terms. Using our example above, if you only sold clothing for men instead of men and women, your description could be “Men’s clothing retailer”.",
          },
          {
            title: "Use Industry-specific Language",
            body: "If your business operates as part of a specialized industry, it’s expected that you would use terms from that industry. This can help for classification.",
          },
        ],
      },
    ],
  },
  5: {
    callOut: {
      title: "Who’s in",
      body: "This information appears on your Operating Agreement, a guiding document for how your business is structured and will run. Some states will publish this information also.",
    },
    tips: [
      {
        title: "What is a member",
        content: [
          {
            title: "Ownership",
            body: "All members, by definition, have an ownership percentage in your business. They are entitled to a share of the profits and losses. Typically, most member-managed LLCs have owners actively involved in the management and decision making of the business.",
          },
          {
            title: "Operating Agreement",
            body: "A member is typically listed on the operating agreement and, depending on the state, in public records as a part of your company. This document will include ownership percentage and personal investment contributions.",
          },
          {
            title: "Legal & Tax Implications",
            body: "Single member and multi-member companies can be taxed differently. Members within a company may also be subject to different requirements. doola’s tax advisors are available for consultations if you prefer to chat about this. If you’re a doola Compliance customer, a tax advisory consultation is included. Contact us to upgrade to Compliance or schedule a consultation through this link.",
          },
        ],
      },
      {
        title: "Remove a member after formation",
        content: [
          {
            title: "Update the Operating Agreement",
            body: "Once formation is complete, adding or removing a member, or changing their ownership percentage would require updating or replacing the operating agreement. doola can help you with this but there is typically a charge as it is a legal document to your business.",
          },
          {
            title: "Transferring Ownership",
            body: "Once formation is complete and depending on your operating agreement, there could be restrictions on how, when, or whom you could sell your ownership to. This is important to review before becoming a member in an LLC.",
          },
          {
            title: "Changes to EIN",
            body: "If removing one or more members of your LLC results in going from a multi-member LLC to single member LLC, then you will need to get a new EIN.",
          },
        ],
      },
    ],
  },
  6: {
    callOut: {
      title: "Impact to EIN",
      body: "If the responsible party is a United States Resident or otherwise has a social security number to their name, the IRS will skip the backlog to process the application.",
    },
    tips: [
      {
        title: "Responsible Party Responsibilities",
        content: [
          {
            title: "Tax Administration",
            body: "The responsible party serves as the primary contact person for the IRS regarding tax matters. This includes receiving correspondence, notices, and inquiries from the IRS related to the business entity's tax obligations.",
          },
          {
            title: "Communication and Compliance",
            body: "Designating a responsible party ensures effective communication between the IRS and the business entity. It helps the IRS efficiently address tax-related issues, verify information, and ensure compliance with tax laws and regulations.",
          },
          {
            title: "Accountability",
            body: "The responsible party assumes accountability for the accuracy and completeness of the information provided to the IRS. They are responsible for signing and filing the entity's tax returns and other required forms.",
          },
          {
            title: "Verification of Identity",
            body: "The IRS requires the responsible party's personal identification information, such as name, address, and Social Security Number (SSN) – if applicable. This information helps the IRS verify the responsible party's identity and establish their connection to the business entity.",
          },
          {
            title: "Fraud Prevention",
            body: "Identifying a responsible party helps prevent potential fraudulent activities, such as the misuse of business entities for illegal purposes or tax evasion. The responsible party's information helps the IRS track and investigate any suspicious or fraudulent behavior.",
          },
        ],
      },
    ],
  },
  7: {
    callOut: {
      title: "Who gets what and why",
      body: "This information appears on your Operating Agreement and will lay the foundation of how your company will make decisions.",
    },
    tips: [
      {
        title: "Ownership Considerations",
        content: [
          {
            title: "Capital Contributions",
            body: "If members within the LLC have different capital contributions, it's essential to take these factors into account when determining ownership percentages. Some members may have made unequal or ongoing contributions, this could affect how you would like to allocate their ownership.",
          },
          {
            title: "Other Contributions",
            body: "Examine all non-capital contributions made by each member to the LLC. Contributions can include initial property, equipment, intellectual property, or services rendered. The value of these contributions can be a basis for determining ownership percentages.",
          },
          {
            title: "Profit & Loss Allocation",
            body: "Profit and loss sharing often aligns with ownership percentages but can be customized based on the agreement.",
          },
          {
            title: "Voting Rights",
            body: "Consider the allocation of voting rights in the LLC. Ownership percentages are often tied to voting power, giving members with larger ownership stakes greater decision-making authority.",
          },
        ],
      },
    ],
  },
  8: {
    callOut: {
      title: "Almost there",
      body: "The information on this page will be submitted to the state for formation, the IRS for an EIN, and create your very first operating agreement.",
    },
    tips: [
      {
        title: "Before you submit",
        content: [
          {
            title: "Company Name",
            body: "While you can conduct business under a brand name, your company name is the legal name for your company and cannot be changed.",
          },
          {
            title: "Industry",
            body: "When you choose your company’s industry, you self-identify the types of tax rules and regulatory requirements you plan to apply to your company. This can be changed later to reflect your new choice,  but you will not be able to retroactively apply it.",
          },
          {
            title: "Members",
            body: "Adding or removing members may require additional filings and paperwork. This can be costly to do even if it’s allowed based on your operating agreement.",
          },
        ],
      },
    ],
  },
};

const accordionContentsCCorp: AccordionContents = {
  5: {
    callOut: {
      title: "You’re in good hands with doola!",
      body: "Watch this area for best practices and tips to guide you through formation.",
    },
    tips: [
      {
        title: "Haven’t defined these roles yet?",
        content: [
          {
            title: "",
            body: "Don’t sweat it! One person can hold all officer positions in a C Corporation. This means that an individual can simultaneously serve as the CEO, CFO, Secretary, and any other officer roles defined by the corporation's bylaws or articles of incorporation. This structure is common in small businesses and startups where there may be a single founder handling all aspects of the company's operations.",
          },
        ],
      },
      {
        title: "Can I update these roles later?",
        content: [
          {
            title: "",
            body: "Yes, you can update the officer roles in your C Corporation later on by amending the corporation's bylaws and filing appropriate documentation with state authorities. When the time comes, doola can help you properly file the business amendment with the State to ensure your company’s records are up to date.",
          },
        ],
      },
    ],
  },
  7: {
    callOut: {
      title: "You’re in good hands with doola!",
      body: "Watch this area for best practices and tips to guide you through formation.",
    },
    tips: [
      {
        title: "Shares guide",
        content: [
          {
            title: "Default",
            body: "If you are not sure of when you will be raising funds, go for a smaller authorized share capital of 5000 at $0.02 par value per share.",
          },
          {
            title: "Fundraising",
            body: "If you are looking to raise funds in the next 2 years, go for 10M shares at $0.00001 par value per share.",
          },
        ],
      },
    ],
  },
};

const OnboardingAside = () => {
  const { currentCompany } = useCompany();

  const isCCorpFlow = currentCompany?.entityType === EntityType.CCORP;

  const accordionData = isCCorpFlow
    ? { ...accordionContents, ...accordionContentsCCorp }
    : accordionContents;

  const { currentOnboardingStep } = useContext(OnboardingContext);

  const accordionStepContent = accordionData[currentOnboardingStep];

  if (!accordionStepContent) {
    return <LoadingComponent />;
  }

  return <FlowAside accordionStepContent={accordionStepContent} />;
};

export default OnboardingAside;
