import {
  CompanyServices,
  ProductDuration,
  ServiceCategory,
  ServiceVariant,
} from "graphql/__generated__/types";
import { ReactComponent as PercentageImage } from "assets/Percentage.svg";
import { ReactComponent as BooksImage } from "assets/Banners/books.svg";
import { ReactComponent as PresentationImage } from "assets/Presentation.svg";
import { JSXElementConstructor, ReactElement } from "react";
import { colors } from "utils";
import { PRODUCTS_DB_KEYS } from "resources/productsLookupKeys";

export enum ServicesSubCategories {
  Formation = "Formation",
  RegisteredAgent = "Registered Agent",
  Tax = "Tax",
  Bookkeeping = "Bookkeeping",
  BOIFiling = "BOI Filing",
}

export interface IServiceData {
  name: string;
  subCategory: ServicesSubCategories;
  title: string;
  description: string;
  included?: string[];
  price?: number;
  productKey?: string;
  allowCheckout?: boolean;
  duration?: ProductDuration;
  variant?: ServiceVariant;
  logo?: boolean;
  image?: ReactElement<any, string | JSXElementConstructor<any>>;
  imageBgColor?: string;
}

export const SERVICES_DATA: Record<string, IServiceData> = {
  [CompanyServices.FORMATION]: {
    name: CompanyServices.FORMATION,
    subCategory: ServicesSubCategories.Formation,
    title:
      "doola navigates the complexities of business formation with the state.",
    description:
      "A step by step wizard guides you through organizing member information, picking a company name, and more. doola’s experts handle all the paperwork to ensure compliance with state regulations.",
    included: [
      "Business Formation with the State",
      "Articles of Organization",
      "Operating Agreement",
    ],
  },
  [`${CompanyServices.FORMATION}-${ServiceVariant.EXPEDITE}`]: {
    name: "Expedited Formation",
    subCategory: ServicesSubCategories.Formation,
    title:
      "doola navigates the complexities of business formation with the state.",
    description:
      "A step by step wizard guides you through organizing member information, picking a company name, and more. doola’s experts handle all the paperwork to ensure compliance with state regulations.",
    included: [
      "Business Formation with the State",
      "Articles of Organization",
      "Operating Agreement",
    ],
  },
  [CompanyServices.EINCREATION]: {
    name: "EIN Creation",
    subCategory: ServicesSubCategories.Formation,
    title:
      "doola submits the paperwork to the Internal Revenue Service (IRS) on your behalf once the state has approved your company.",
    description:
      "The IRS will issue a 9 digit number that allows your business to open a bank account and hire employees in the US.",
    included: [
      "SS4 Creation and Submission to the IRS",
      "EIN Number",
      "EIN Confirmation Letter",
    ],
  },
  [`${CompanyServices.EINCREATION}-${ServiceVariant.EXPEDITE}`]: {
    name: "Expedited EIN",
    subCategory: ServicesSubCategories.Formation,
    variant: ServiceVariant.EXPEDITE,
    title:
      "Get your EIN from the IRS in record time with our expedited service.",
    description:
      "Streamline your business setup process with our efficient and hassle-free EIN process. Our in-house CAA (Certified Acceptance Agent) will work directly with the IRS on your behalf to receive your EIN number as quick as possible. By expediting your EIN process, you can receive your EIN number in half the time as the standard EIN delivery time.",
    included: [
      "CAA (Certified Acceptance Agent) service",
      "SS4 Creation and Submission to the IRS",
      "Individualized outreach to IRS",
      "EIN Number",
      "EIN Confirmation Letter",
    ],
  },
  [CompanyServices.REGISTEREDAGENT]: {
    name: "Registered Agent",
    subCategory: ServicesSubCategories.RegisteredAgent,
    title:
      "Ensure compliance and receive a level of privacy from doola’s Registered Agents.",
    description:
      "Available to act as your address of record with the state, they receive and forward legal documents, tax notices, and other official correspondence from the state or Internal Revenue Service (IRS).",
    included: [
      "1-year of Registered Agent Service",
      "Business Address in the State your business if formed in",
      "Mailing Address (for state and federal government correspondence only)",
    ],
  },
  [CompanyServices.ANNUALSTATEFILING]: {
    name: "Annual State Filings",
    subCategory: ServicesSubCategories.RegisteredAgent,
    productKey: PRODUCTS_DB_KEYS.ANNUAL_STATE_FILINGS,
    allowCheckout: true,
    duration: ProductDuration.ANNUAL,
    title:
      "Let doola handle your annual State compliance filing and ensure your business remains in good standing.",
    description:
      "States require businesses to file an annual report that provides updated information about the company's management, ownership, and contact information. The State also charges an Annual Fee for maintaining a business' standing in that state. Employ doola to handle the Annual Filing process and never worry about whether your business is in compliance again. State Fees vary by state and will be collected separately from the cost for the filing.",
  },
  [CompanyServices.TAX]: {
    name: "Annual Tax Filings",
    subCategory: ServicesSubCategories.Tax,
    productKey: PRODUCTS_DB_KEYS.ANNUAL_TAX_PACKAGE,
    allowCheckout: true,
    duration: ProductDuration.ANNUAL,
    title:
      "doola can prepare your annual tax return for submission to the Internal Revenue Service (IRS).",
    description:
      "Experts at doola will keep you informed of changes to tax laws and help you claim any applicable credits.",
    included: [
      "Required Federal Business Tax Filings",
      "Financial Statements",
      "CPA consultation(s)",
    ],
    image: <PercentageImage />,
    imageBgColor: colors.lightAccentYellow,
  },
  [CompanyServices.CPACONSULTATION]: {
    name: "CPA Consultations",
    subCategory: ServicesSubCategories.Tax,
    productKey: PRODUCTS_DB_KEYS.CPA_CONSULTATIONS,
    allowCheckout: true,
    price: 150,
    duration: ProductDuration.ONETIME,
    title:
      "Receive exclusive access to a one-on-one tax consultation tailored to your specific business needs.",
    description:
      "In this personalized session, an experienced tax professional will provide expert guidance tailored to your business’ unique financial goals and challenges.",
    included: ["1:1 meeting with a tax professional for up to 1 hour"],
    image: <PresentationImage />,
    imageBgColor: colors.borderBlue,
  },
  [CompanyServices.RECORDKEEPING]: {
    name: "Invoicing",
    subCategory: ServicesSubCategories.Bookkeeping,
    variant: ServiceVariant.INVOICEONLY,
    productKey: PRODUCTS_DB_KEYS.RECORDKEEPING_INVOICE_ONLY_ANNUAL,
    allowCheckout: true,
    duration: ProductDuration.ANNUAL,
    logo: true,
    title:
      "Simplify your invoicing process with our doola’s user-friendly invoicing product.",
    description:
      "Our invoicing solution offers a seamless way to create, customize, and send professional invoices. Track payments, set up recurring billing, and generate insightful reports effortlessly. With intuitive features and payment integration capabilities, you can streamline your billing process and focus on growing your business.",
    included: [
      "Customizable Invoice Template",
      "Easy to Use Platform",
      "Integration to Stripe account for payment processing",
      "Standard Reports",
    ],
  },
  [`${CompanyServices.RECORDKEEPING}-${ServiceVariant.COMPLETE}`]: {
    name: "Books",
    subCategory: ServicesSubCategories.Bookkeeping,
    variant: ServiceVariant.COMPLETE,
    productKey: PRODUCTS_DB_KEYS.RECORDKEEPING_COMPLETE_ANNUAL,
    allowCheckout: true,
    duration: ProductDuration.ANNUAL,
    logo: true,
    title:
      "Effortlessly track your income and expenses in one centralized platform.",
    description:
      "Categorize transactions, manage receipts, and generate detailed reports with ease. Gain valuable insights into your spending patterns and identify areas for improvement. Our user-friendly interface makes it simple to maintain accurate records and stay on top of your financial health.",
    included: [
      "All features of doola Invoicing",
      "Integrations with 1,000+ financial platforms",
      "Manage income and expense tracking from all financial institutions in one place",
      "Automated rules that make bookkeeping a breeze",
      "Standard reports for business insights",
    ],
    image: <BooksImage />,
    imageBgColor: colors.darkAccentGreen,
  },
  [CompanyServices.BOOKKEEPING]: {
    name: "Dedicated Bookkeeping",
    subCategory: ServicesSubCategories.Bookkeeping,
    price: 1500,
    duration: ProductDuration.ANNUAL,
    title:
      "Stress-free bookkeeping with our dedicated bookkeeping solution designed to simplify your financial management.",
    description:
      "Our expert team of bookkeepers is committed to maintaining accurate and up-to-date records to meet your business needs. From recording transactions and reconciling accounts to managing payables and receivables, we handle it all. Our personalized approach ensures that your unique financial needs are met, giving you more time to focus on your core business.",
    included: [
      "Dedicated Bookkeeper",
      "Bookkeeping platform access",
      "Bookkeeping preparation to make your taxes a breeze",
    ],
  },
  [CompanyServices.BOIFILING]: {
    name: "BOI Reporting",
    subCategory: ServicesSubCategories.BOIFiling,
    productKey: PRODUCTS_DB_KEYS.BOI_FILING,
    allowCheckout: true,
    price: 149,
    duration: ProductDuration.ONETIME,
    title:
      "All US businesses are required to file the BOI report with FinCen after formation or any ownership changes.",
    description:
      "Let doola handle this and ensure your BOI report is filed correctly to avoid any adverse action.",
    included: [ServicesSubCategories.BOIFiling],
  },
};

export const RA_SERVICES_DATA: Record<string, IServiceData> = {
  "Certificate of Good Standing": {
    name: "Certificate of Good Standing",
    subCategory: ServicesSubCategories.RegisteredAgent,
    title:
      "Validate your business's compliance with our Certificate of Good Standing.",
    description: "Enhance credibility and unlock new opportunities.",
    price: 99,
    duration: ProductDuration.ONETIME,
  },
  "Certified Certificate of Good Standing": {
    name: "Certified Certificate of Good Standing",
    subCategory: ServicesSubCategories.RegisteredAgent,
    title:
      "Validate your business's compliance with our Certified Certificate of Good Standing.",
    description: "Enhance credibility and unlock new opportunities.",
    price: 149,
    duration: ProductDuration.ONETIME,
  },
  "Corporate Book and Seal": {
    name: "Corporate Book and Seal",
    subCategory: ServicesSubCategories.RegisteredAgent,
    title:
      "Commemorate your business and elevate your corporate image with our Corporate Book and Seal.",
    description:
      "This official package includes a professional company book and a custom corporate seal, perfect for formalizing legal documents and transactions.",
    price: 399,
    duration: ProductDuration.ONETIME,
  },
  "DBA/Trade Name Designation": {
    name: "DBA/Trade Name Designation",
    subCategory: ServicesSubCategories.RegisteredAgent,
    title: "Register your 'Doing Business As' (DBA) designation with ease.",
    description:
      "Establish your unique brand identity while operating under a different name than your legal entity, ensuring compliance and expanding your business opportunities.",
    price: 149,
    duration: ProductDuration.ONETIME,
  },
  "Apostille (shipping domestic in WY and DE)": {
    name: "Apostille (shipping domestic in WY and DE)",
    subCategory: ServicesSubCategories.RegisteredAgent,
    title:
      "Streamline your domestic shipping document processing with our Apostille service.",
    description:
      "Our experts handle the authentication and legalization of your shipping-related documents, ensuring they meet the requirements of domestic jurisdictions. Simplify the paperwork for hassle-free domestic shipping with our reliable Apostille service.",
    price: 299,
    duration: ProductDuration.ONETIME,
  },
  "Apostille (shipping internationally)": {
    name: "Apostille (shipping internationally)",
    subCategory: ServicesSubCategories.RegisteredAgent,
    title:
      "Streamline your international shipping document processing with our Apostille service.",
    description:
      "Our experts handle the authentication and legalization of your shipping-related documents, ensuring they meet the requirements of foreign jurisdictions. Simplify the paperwork for hassle-free international shipping with our reliable Apostille service.",
    price: 499,
    duration: ProductDuration.ONETIME,
  },
  Dissolution: {
    name: "Dissolution",
    subCategory: ServicesSubCategories.RegisteredAgent,
    title:
      "Navigating business closure is stress-free with our dissolution services.",
    description:
      "We manage the entire process, from filing paperwork to settling taxes, ensuring a compliant and seamless termination of your company. Trust us for a hassle-free and legally compliant dissolution experience.",
    price: 149,
    duration: ProductDuration.ONETIME,
  },
  "ITIN Registration": {
    name: "ITIN Registration",
    subCategory: ServicesSubCategories.RegisteredAgent,
    title:
      "The ITIN registration product simplifies the process of obtaining an Individual Taxpayer Identification Number.",
    description:
      "doola will work with the IRS on your behalf and provide you with an ITIN as quick as possible.",
    price: 499,
    duration: ProductDuration.ONETIME,
  },
  Amendment: {
    name: "Amendment",
    subCategory: ServicesSubCategories.RegisteredAgent,
    title:
      "Update and modify your company's information effortlessly with our Company Amendment service.",
    description:
      "Our experts handle the necessary paperwork and legal requirements, ensuring accurate and compliant changes to your company's details. Simplify the process of updating your company's information with our reliable Company Amendment service.",
    price: 149,
    duration: ProductDuration.ONETIME,
  },
  "Foreign Qualification": {
    name: "Foreign Qualification",
    subCategory: ServicesSubCategories.RegisteredAgent,
    title:
      "Expand your business's reach with our Foreign Qualification service.",
    description:
      "We handle the necessary paperwork and legal requirements to help you operate in new states or countries, ensuring compliance and enabling growth opportunities. Simplify your expansion process with our trusted Foreign Qualification services.",
    price: 149,
    duration: ProductDuration.ANNUAL,
  },
};

export const SERVICE_STATUSES = {
  [ServiceCategory.ACTIVE]: "Active",
  [ServiceCategory.CANCELED]: "Cancelled",
  [ServiceCategory.COMPLETED]: "Completed",
  [ServiceCategory.INPROGRESS]: "In Progress",
  [ServiceCategory.NOTAVAILABLE]: "Not Available",
  [ServiceCategory.NOTSTARTED]: "Not Started",
};

export const SERVICE_VARIANTS = [
  ServiceVariant.COMPLETE,
  ServiceVariant.EXPEDITE,
];
