import { Close, NavigateBefore, NavigateNext } from "@mui/icons-material";
import { IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import { CustomCard } from "components/Cards";
import { Logo } from "components/Logos";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSwiper } from "swiper/react";
import utils, { colors } from "utils";
import { AffiliatePopCard } from "../AffiliatePopCard";
import { Affiliate } from "../data";
import { usePostHog } from "posthog-js/react";
import Button from "components/Button";

export interface IAffiliateCardProps {
  affiliate: Affiliate;
}

export const AffiliateCard: React.FC<IAffiliateCardProps> = ({ affiliate }) => {
  const { t } = useTranslation();
  const swiper = useSwiper();
  const posthog = usePostHog();
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const onShowModalHandler = () => {
    setIsModalOpen(true);
  };
  const onCloseModalHandler = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <CustomCard
        $styles={{
          background: affiliate.color,
          height: "240px",
          display: "flex",
        }}
        $shadow="none"
        $radius="strong"
        $padding={4}
      >
        <Stack width={"100%"} minHeight={"100%"} gap={4} direction={"row"}>
          <Stack
            width={"100%"}
            maxWidth={"100%"}
            justifyContent={"space-between"}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              gap={1}
            >
              <Logo width={75} $linesColor={colors.black} />
              <Close />
              <img
                src={affiliate.logo}
                alt={t(`dashboard.affiliates.${affiliate.name}.title`) ?? ""}
              />
            </Stack>
            <Typography variant="h3" fontWeight={700}>
              {t(`dashboard.affiliates.${affiliate.name}.title`)}
            </Typography>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              gap={1}
            >
              <Button
                endIcon={<NavigateNext />}
                buttonText={affiliate.buttonText}
                onClick={() => {
                  posthog?.capture("affiliate-link-click", {
                    referral_name: affiliate.name,
                  });

                  window.open(affiliate.link);
                }}
              />
              <Button
                transparent
                buttonText={t("general.showDetails")}
                onClick={() => {
                  onShowModalHandler();
                  swiper.autoplay.pause();

                  posthog?.capture("affiliate-open-modal-click", {
                    referral_name: affiliate.name,
                  });
                }}
              />
            </Stack>
          </Stack>
          {!isMobile && (
            <Stack
              width={"100%"}
              maxWidth={"40%"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <img
                src={affiliate.cover}
                style={{ height: "100%", maxHeight: "180px", maxWidth: "100%" }}
                alt="cover"
              />
              <Stack
                width={"100%"}
                justifyContent={"flex-end"}
                direction={"row"}
                gap={0.5}
              >
                <IconButton
                  onClick={() => swiper.slidePrev()}
                  sx={{
                    bgcolor: utils.secondaryColors.secondaryDark,
                    color: colors.grey,
                    p: "10px",
                    "&:hover": {
                      bgcolor: colors.charcoal,
                    },
                  }}
                >
                  <NavigateBefore />
                </IconButton>
                <IconButton
                  onClick={() => swiper.slideNext()}
                  sx={{
                    bgcolor: utils.secondaryColors.secondaryDark,
                    color: colors.grey,
                    p: "10px",
                    "&:hover": {
                      bgcolor: colors.charcoal,
                    },
                  }}
                >
                  <NavigateNext />
                </IconButton>
              </Stack>
            </Stack>
          )}
        </Stack>
      </CustomCard>
      <AffiliatePopCard
        affiliate={affiliate}
        open={isModalOpen}
        onClose={onCloseModalHandler}
      />
    </>
  );
};
