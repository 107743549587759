import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { CorporateFareOutlined } from "@mui/icons-material";
import utils from "utils";
import ButtonBox from "components/ButtonBox";
import { LoadingComponent } from "components/Loading";
import { useSetMoneyOnboardingMutation } from "graphql/__generated__/operations/MoneyOnboardingValidation.generated";
import {
  LookupTypeKey,
  OnboardingBusinessType,
  OnboardingStakeholderBusinessEntityType,
  OnboardingStakeholderType,
  WalletOnboard,
} from "graphql/__generated__/types";
import useOnboardingData from "views/DashboardApp/Money/Onboarding/hooks/useOnboardingData";
import useGetMoneyLookups from "views/DashboardApp/Money/hooks/useGetMoneyLookups";
import { EINRegex, Windows1252Regex } from "utils/regexValidations";
import CustomInput from "components/Form/CustomInput";
import CustomSelect from "components/Form/CustomSelect";
import { addMemberSteps } from "../types";
import { IAddMemberStepsProps } from "../../types";
import {
  InitialCompanyMemberValuesType,
  INTITAL_COMPANY_MEMBER_VALUES,
} from "./helpers/initialMemberAddressData";
import { MemberDialogFormModeEnum } from "../../../types";
import { removeTypename } from "utils/helpers/removeTypename";
import { isValueValid } from "views/DashboardApp/Money/helpers";
import EINMask from "components/EINMask";

const CompanyMemberDetails = ({
  setMemberDialogDetails,
  setAddMemberCurrentStep,
  memberDialogDetails,
}: IAddMemberStepsProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const { t } = useTranslation();

  const { onboardingData, isOnboardingDataLoading } = useOnboardingData();
  const { lookupsData, isLookupsDataLoading } = useGetMoneyLookups({
    neededLookups: [
      LookupTypeKey.COUNTRYOFOPERATION,
      LookupTypeKey.BUSINESSTYPE,
    ],
  });
  const [updateMoneyOnboarding] = useSetMoneyOnboardingMutation();

  const { control, formState, handleSubmit, getValues, trigger, setValue } =
    useForm<InitialCompanyMemberValuesType>({
      defaultValues: { ...INTITAL_COMPANY_MEMBER_VALUES },
      mode: "onChange",
    });

  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  const isEditMode = memberDialogDetails.mode === MemberDialogFormModeEnum.edit;

  useEffect(() => {
    const {
      businessName,
      businessType,
      businessRegistrationNumber,
      registeredCountry,
    } = getValues();

    if (
      isValueValid(businessName) &&
      isValueValid(businessType) &&
      isValueValid(businessRegistrationNumber) &&
      isValueValid(registeredCountry)
    ) {
      trigger();
    }
  }, [getValues, trigger]);

  useEffect(() => {
    if (isEditMode) {
      const filteredOnboardingData: WalletOnboard =
        removeTypename(onboardingData);

      const targetedStakeholder = filteredOnboardingData?.stakeholders?.find(
        (stakeholder) => stakeholder?.uuid === memberDialogDetails.stakeholderId
      );

      setValue("businessName", targetedStakeholder?.businessName || "");
      setValue(
        "businessType",
        targetedStakeholder?.businessType ||
          OnboardingBusinessType.LIMITED_LIABILITY_COMPANY
      );
      setValue(
        "businessRegistrationNumber",
        targetedStakeholder?.businessRegistrationNumber || ""
      );
      setValue(
        "registeredCountry",
        targetedStakeholder?.registeredCountry || ""
      );
    }
  }, [isEditMode, memberDialogDetails.stakeholderId, onboardingData, setValue]);

  if (
    !lookupsData ||
    isLookupsDataLoading ||
    !onboardingData ||
    isOnboardingDataLoading
  ) {
    return (
      <Stack height={"200px"} alignItems={"center"} justifyContent={"center"}>
        <LoadingComponent />
      </Stack>
    );
  }

  const onSubmitHandler = async (
    newStakeholder: InitialCompanyMemberValuesType
  ) => {
    const filteredOnboardingData: WalletOnboard =
      removeTypename(onboardingData);

    try {
      setIsLoading(true);

      if (isEditMode) {
        const stakeholdersWithUpdatedData =
          filteredOnboardingData?.stakeholders?.map((stakeholder) =>
            stakeholder?.uuid === memberDialogDetails.stakeholderId
              ? {
                  ...stakeholder,
                  ...newStakeholder,
                  businessEntityType:
                    OnboardingStakeholderBusinessEntityType.UBO,
                }
              : stakeholder
          );

        await updateMoneyOnboarding({
          variables: {
            ...filteredOnboardingData,
            stakeholders: stakeholdersWithUpdatedData,
          },
        });
      } else {
        await updateMoneyOnboarding({
          variables: {
            ...filteredOnboardingData,
            stakeholders: [
              ...(filteredOnboardingData.stakeholders || []),
              {
                ...newStakeholder,
                uuid: uuidv4(),
                ownershipPercentage: "0",
                type: OnboardingStakeholderType.BUSINESS,
                businessEntityType: OnboardingStakeholderBusinessEntityType.UBO,
              },
            ],
          },
        });
      }

      setMemberDialogDetails({
        show: false,
        stakeholderId: "",
        mode: MemberDialogFormModeEnum.add,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isDisabled = !formState.isValid;

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} gap={1.5} mb={3}>
        <CorporateFareOutlined />
        <Typography fontSize={16} fontWeight={500}>
          {t("money.onboarding.step.membersInformation.companyMember")}
        </Typography>
      </Stack>
      <Stack gap={4}>
        <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
          <Stack gap={5} mb={4}>
            <Stack gap={2}>
              <Stack direction={isTablet ? "column" : "row"} gap={3}>
                <Controller
                  name="businessName"
                  {...COMMON_PROPS}
                  rules={{
                    required: true,
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      placeholder={
                        t(
                          "money.onboarding.step.membersInformation.companyName.placeholder"
                        ) as string
                      }
                      label={
                        t(
                          "money.onboarding.step.membersInformation.companyName"
                        ) as string
                      }
                    />
                  )}
                />
                {lookupsData?.BUSINESSTYPE && (
                  <Controller
                    name="businessType"
                    {...COMMON_PROPS}
                    rules={{
                      pattern: {
                        value: Windows1252Regex,
                        message: t("error.invalidCharacters"),
                      },
                      required: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <CustomSelect
                        {...field}
                        error={error !== undefined}
                        styles={{ width: "100%" }}
                        placeholder={
                          t(
                            "money.onboarding.step.membersInformation.entityType.placeholder"
                          ) as string
                        }
                        label={
                          t(
                            "money.onboarding.step.membersInformation.entityType"
                          ) as string
                        }
                        defaultValue={field.value}
                        onChange={(e: SelectChangeEvent<unknown>) => {
                          field.onChange(e.target.value);
                        }}
                      >
                        {lookupsData.BUSINESSTYPE.map((item, index) => (
                          <MenuItem key={index} value={item?.key ?? ""}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    )}
                  />
                )}
              </Stack>
              <Stack direction={isTablet ? "column" : "row"} gap={3}>
                <Controller
                  name="businessRegistrationNumber"
                  {...COMMON_PROPS}
                  rules={{
                    required: true,
                    pattern: {
                      value: EINRegex,
                      message: t("error.invalidEIN"),
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      error={error !== undefined}
                      isProtected
                      styles={{ width: "100%" }}
                      placeholder={
                        t(
                          "money.onboarding.step.membersInformation.ein.placeholder"
                        ) as string
                      }
                      label={
                        t(
                          "money.onboarding.step.membersInformation.ein"
                        ) as string
                      }
                      mask={EINMask as any}
                    />
                  )}
                />
                {lookupsData?.COUNTRYOFOPERATION && (
                  <Controller
                    name="registeredCountry"
                    {...COMMON_PROPS}
                    rules={{
                      pattern: {
                        value: Windows1252Regex,
                        message: t("error.invalidCharacters"),
                      },
                      required: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <CustomSelect
                        {...field}
                        error={error !== undefined}
                        styles={{ width: "100%" }}
                        placeholder={
                          t(
                            "money.onboarding.step.membersInformation.registeredCountry.placeholder"
                          ) as string
                        }
                        label={
                          t(
                            "money.onboarding.step.membersInformation.registeredCountry"
                          ) as string
                        }
                        defaultValue={field.value}
                        onChange={(e: SelectChangeEvent<unknown>) => {
                          field.onChange(e.target.value);
                        }}
                      >
                        {lookupsData.COUNTRYOFOPERATION.map((item, index) => (
                          <MenuItem key={index} value={item?.key ?? ""}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    )}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
          <ButtonBox
            showBackButton={!isEditMode}
            isLoading={isLoading}
            disabled={isDisabled}
            rightButtonText={"Save"}
            onClickBack={() =>
              setAddMemberCurrentStep(addMemberSteps.memberType)
            }
            onClickNext={() => onSubmitHandler}
          />
        </form>
      </Stack>
    </>
  );
};

export default CompanyMemberDetails;
