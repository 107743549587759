import ButtonBox from "components/ButtonBox";
import { IQuestionProps } from "./types";
import { InputBase, Stack, Typography, useMediaQuery } from "@mui/material";
import utils, { colors } from "utils";
import { staticQuestionData } from "./data";
import { Controller, useForm } from "react-hook-form";
import { Windows1252Regex } from "utils/regexValidations";
import { t } from "i18next";
import { useEffect } from "react";
import { UserSurveyAnswerInput } from "graphql/__generated__/types";

export const Text = ({
  currentStep,
  question,
  setStep,
  isLastStep,
  savedQuestionData,
  submitStepData,
  isLoading,
}: IQuestionProps) => {
  const { control, trigger, setValue, formState, handleSubmit } =
    useForm<UserSurveyAnswerInput>({
      defaultValues: {
        doolaUserSurveyQuestionId: question?.id ?? "",
        questionType: question?.type ?? "",
        answerText: "",
      },
      mode: "onTouched",
    });
  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);

  useEffect(() => {
    if (question?.id && question?.type) {
      setValue("answerText", savedQuestionData?.answerText || "");
      setValue("doolaUserSurveyQuestionId", question.id);
      setValue("questionType", question.type);

      trigger();
    }
  }, [question, savedQuestionData?.answerText, setValue, trigger]);

  const isNotFirstStep = currentStep !== 0;
  const helperText = staticQuestionData?.[currentStep]?.helpText;

  return (
    <Stack gap={5}>
      <Typography variant="h3" fontSize={isMobile ? "24px" : "28px"}>
        {question?.question}
      </Typography>
      <Stack>
        <Controller
          name={"answerText"}
          {...COMMON_PROPS}
          rules={{
            required: true,
            pattern: {
              value: Windows1252Regex,
              message: t("error.invalidCharacters"),
            },
          }}
          render={({ field: { ref, ...field } }) => (
            <InputBase
              {...field}
              minRows={7}
              multiline
              placeholder="Type your answer here..."
              inputProps={{
                style: {
                  background: colors.grey,
                  border: "none",
                  padding: "1rem",
                  borderRadius: "1rem",
                },
              }}
            />
          )}
        />
        {helperText && (
          <Typography fontSize={"12px"} px={2} color={colors.contentSecondary}>
            {helperText}
          </Typography>
        )}
      </Stack>
      <ButtonBox
        isLoading={isLoading}
        disabled={!formState.isValid}
        {...(isLastStep && {
          rightButtonText: "Submit",
        })}
        showBackButton={false}
        {...(isNotFirstStep && {
          showBackButton: true,
          onClickBack: () => setStep((prevStep) => prevStep - 1),
        })}
        onClickNext={handleSubmit(submitStepData)}
      />
    </Stack>
  );
};
