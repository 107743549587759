import { Outlet } from "react-router-dom";
import AuthGuard from "utils/route-guard/AuthGuard";
import { DashboardLayout } from "views/DashboardApp/Layout";
import {
  BooksSectionItems,
  TabsData,
  TaxRoutes,
} from "views/DashboardApp/Layout/Sidebar/data";
import Profile from "views/DashboardApp/Profile";
import { ROUTER_PATHS } from "./routes";
import MoneyGateway from "views/DashboardApp/Money/MoneyGateway";
import Onboarding from "views/DashboardApp/Money/Onboarding";
import Transactions from "views/DashboardApp/Money/Transactions";
import Transfer from "views/DashboardApp/Money/Transfer";
import Beneficiary from "views/DashboardApp/Money/Beneficiary";
import MoneyLandingPage from "views/DashboardApp/Money/LandingPage";
import MoneyOverview from "views/DashboardApp/Money/Overview";
import TransferFlow from "views/DashboardApp/Money/Transfer/TransferFlow";

const MainDashboardRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: ROUTER_PATHS.SETTINGS,
          element: <Profile />,
        },
        ...TabsData.map(({ route, element, children }) => ({
          path: route,
          element: element,
          children,
        })),
        {
          path: "/",
          element: (
            <MoneyGateway>
              <Outlet />
            </MoneyGateway>
          ),
          children: [
            {
              path: ROUTER_PATHS.MONEY,
              element: <MoneyLandingPage />,
            },
            {
              path: ROUTER_PATHS.MONEY_ONBOARDING,
              element: <Onboarding />,
            },
            {
              path: ROUTER_PATHS.MONEY_OVERVIEW,
              element: <MoneyOverview />,
            },
            {
              path: ROUTER_PATHS.MONEY_TRANSACTIONS,
              element: <Transactions />,
            },
            {
              path: ROUTER_PATHS.MONEY_TRANSFER,
              element: <Transfer />,
            },
            {
              path: ROUTER_PATHS.MONEY_SEND,
              element: <TransferFlow />,
            },
            {
              path: ROUTER_PATHS.MONEY_BENEFICIARY,
              element: <Beneficiary />,
            },
          ],
        },
        ...BooksSectionItems.map(({ route, element }) => ({
          path: route,
          element: element,
        })),
        {
          path: TaxRoutes.route,
          element: TaxRoutes.element,
        },
      ],
    },
  ],
};

export default MainDashboardRoutes;
