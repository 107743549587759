import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTokenFieldsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetTokenFieldsQuery = { __typename?: 'Query', myToken?: { __typename?: 'ResolverContext', authorizedCustomerId?: string | null, authorizedCompanyIds?: string | null } | null };


export const GetTokenFieldsDocument = gql`
    query GetTokenFields {
  myToken {
    authorizedCustomerId
    authorizedCompanyIds
  }
}
    `;

/**
 * __useGetTokenFieldsQuery__
 *
 * To run a query within a React component, call `useGetTokenFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTokenFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTokenFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTokenFieldsQuery(baseOptions?: Apollo.QueryHookOptions<GetTokenFieldsQuery, GetTokenFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTokenFieldsQuery, GetTokenFieldsQueryVariables>(GetTokenFieldsDocument, options);
      }
export function useGetTokenFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTokenFieldsQuery, GetTokenFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTokenFieldsQuery, GetTokenFieldsQueryVariables>(GetTokenFieldsDocument, options);
        }
export type GetTokenFieldsQueryHookResult = ReturnType<typeof useGetTokenFieldsQuery>;
export type GetTokenFieldsLazyQueryHookResult = ReturnType<typeof useGetTokenFieldsLazyQuery>;
export type GetTokenFieldsQueryResult = Apollo.QueryResult<GetTokenFieldsQuery, GetTokenFieldsQueryVariables>;