import { useEffect, useState } from "react";
import { useGetMoneyBeneficiariesLazyQuery } from "graphql/__generated__/operations/MoneyBeneficiaries.generated";
import useCompany from "graphql/hooks/UseCompany";

const useGetBeneficiaries = () => {
  const { currentCompany } = useCompany();
  const [isBeneficiariesLoading, setIsBeneficiariesLoading] =
    useState<boolean>(true);

  const [fetchBeneficiaries, { data, refetch }] =
    useGetMoneyBeneficiariesLazyQuery();

  useEffect(() => {
    if (currentCompany?.id) {
      const getBeneficiaries = async () => {
        try {
          await fetchBeneficiaries({
            variables: {
              companyId: currentCompany.id,
            },
          });
        } catch (error) {
          console.error("Failed to fetch beneficiaries error: ", error);
        } finally {
          setIsBeneficiariesLoading(false);
        }
      };

      getBeneficiaries();
    }
  }, [currentCompany?.id, fetchBeneficiaries]);

  return {
    beneficiaries: data?.beneficiaries?.payload,
    isBeneficiariesLoading,
    refetchBeneficiaries: refetch,
  };
};

export default useGetBeneficiaries;
