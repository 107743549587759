import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRaAddressesQueryVariables = Types.Exact<{
  state?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetRaAddressesQuery = { __typename?: 'Query', registeredAgentAddresses?: { __typename?: 'RegisteredAgentAddressResponse', error?: string | null, payload?: Array<{ __typename?: 'RegisteredAgentAddress', address?: string | null, address1?: string | null, address2?: string | null, city?: string | null, county?: string | null, email?: string | null, fein?: string | null, id: string, individual?: string | null, postalCode?: string | null, registeredAgent?: string | null, registeredAgentId?: string | null, state: string, stateAbbreviation: string, title?: string | null } | null> | null } | null };


export const GetRaAddressesDocument = gql`
    query GetRaAddresses($state: String) {
  registeredAgentAddresses(state: $state) {
    error
    payload {
      address
      address1
      address2
      city
      county
      email
      fein
      id
      individual
      postalCode
      registeredAgent
      registeredAgentId
      state
      stateAbbreviation
      title
    }
  }
}
    `;

/**
 * __useGetRaAddressesQuery__
 *
 * To run a query within a React component, call `useGetRaAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRaAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRaAddressesQuery({
 *   variables: {
 *      state: // value for 'state'
 *   },
 * });
 */
export function useGetRaAddressesQuery(baseOptions?: Apollo.QueryHookOptions<GetRaAddressesQuery, GetRaAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRaAddressesQuery, GetRaAddressesQueryVariables>(GetRaAddressesDocument, options);
      }
export function useGetRaAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRaAddressesQuery, GetRaAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRaAddressesQuery, GetRaAddressesQueryVariables>(GetRaAddressesDocument, options);
        }
export type GetRaAddressesQueryHookResult = ReturnType<typeof useGetRaAddressesQuery>;
export type GetRaAddressesLazyQueryHookResult = ReturnType<typeof useGetRaAddressesLazyQuery>;
export type GetRaAddressesQueryResult = Apollo.QueryResult<GetRaAddressesQuery, GetRaAddressesQueryVariables>;