import {
  MenuItem,
  SelectChangeEvent,
  Stack,
  useMediaQuery,
} from "@mui/material";
import ButtonBox from "components/ButtonBox";
import utils from "utils";
import { Controller, useForm } from "react-hook-form";
import { EINRegex, Windows1252Regex } from "utils/regexValidations";
import { INITIAL_INFORMATION_VALUES } from "./helpers/initialInformationData";
import CustomSelect from "components/Form/CustomSelect";
import CustomInput from "components/Form/CustomInput";
import { t } from "i18next";
import {
  LookupTypeKey,
  OnboardingBusinessType,
  OnboardingCompanyInfoInput,
  WalletOnboard,
} from "graphql/__generated__/types";
import CustomDatePicker from "components/Form/CustomDatePicker";
import dayjs from "dayjs";
import { ISubStepProps, companySubSteps } from "../../types";
import {
  OnboardingStepsCategories,
  OnboardingStepsData,
} from "views/DashboardApp/Money/Onboarding/data";
import useGetMoneyLookup from "views/DashboardApp/Money/hooks/useGetMoneyLookup";
import { useSetMoneyOnboardingMutation } from "graphql/__generated__/operations/MoneyOnboardingValidation.generated";
import useOnboardingData from "views/DashboardApp/Money/Onboarding/hooks/useOnboardingData";
import { useEffect, useMemo, useState } from "react";
import { LoadingComponent } from "components/Loading";
import ErrorBox from "components/ErrorBox";
import { removeTypename } from "utils/helpers/removeTypename";
import { isValueValid } from "views/DashboardApp/Money/helpers";
import { usePostHog } from "posthog-js/react";
import EINMask from "components/EINMask";

const CompanyInformation = ({
  setCurrentStep,
  setCurrentSubStep,
}: ISubStepProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { onboardingData, isOnboardingDataLoading } = useOnboardingData();

  const { lookupData: businessTypes, isLookupDataLoading } = useGetMoneyLookup({
    category: LookupTypeKey.BUSINESSTYPE,
  });

  const [updateMoneyOnboarding] = useSetMoneyOnboardingMutation();

  const { control, formState, setValue, trigger, handleSubmit } = useForm<
    typeof INITIAL_INFORMATION_VALUES
  >({
    defaultValues: { ...INITIAL_INFORMATION_VALUES },
    mode: "onChange",
  });

  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  const filteredOnboardingData: WalletOnboard = useMemo(
    () => removeTypename(onboardingData),
    [onboardingData]
  );

  useEffect(() => {
    const companyInfoData = filteredOnboardingData?.companyInfo;

    if (companyInfoData) {
      setValue("businessName", companyInfoData.businessName || "");
      setValue(
        "businessRegistrationNumber",
        companyInfoData.businessRegistrationNumber || ""
      );

      const businessType =
        companyInfoData.businessType &&
        [
          OnboardingBusinessType.LIMITED_LIABILITY_COMPANY,
          OnboardingBusinessType.CORPORATION,
        ].includes(companyInfoData.businessType)
          ? companyInfoData.businessType
          : OnboardingBusinessType.LIMITED_LIABILITY_COMPANY;

      setValue("businessType", businessType);

      setValue(
        "legalRegisteredDate",
        companyInfoData.legalRegisteredDate || ""
      );
      setValue("website", companyInfoData.website || "");
      setValue("description", companyInfoData.description || "");

      if (
        isValueValid(companyInfoData.businessName) &&
        isValueValid(companyInfoData.businessRegistrationNumber) &&
        isValueValid(companyInfoData.businessType) &&
        isValueValid(companyInfoData.website) &&
        isValueValid(companyInfoData.description)
      ) {
        trigger();
      }
    }
  }, [filteredOnboardingData, setValue, trigger]);

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const posthog = usePostHog();

  if (isLookupDataLoading || !onboardingData || isOnboardingDataLoading) {
    return (
      <Stack height={"200px"} alignItems={"center"} justifyContent={"center"}>
        <LoadingComponent />
      </Stack>
    );
  }

  const onSubmitHandler = async (data: OnboardingCompanyInfoInput) => {
    posthog?.capture("money_onboarding_company_information_next_btn_click");

    try {
      setIsLoading(true);

      await updateMoneyOnboarding({
        variables: {
          ...filteredOnboardingData,
          companyInfo: { ...filteredOnboardingData?.companyInfo, ...data },
        },
      });

      setCurrentSubStep(companySubSteps.registeredAddress);
    } finally {
      setIsLoading(false);
    }
  };

  const isDisabled = !formState.isValid;

  return (
    <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
      <Stack gap={5} mt={4} mb={isTablet ? 2 : 0}>
        <Stack gap={2}>
          <Stack direction={isTablet ? "column" : "row"} gap={3}>
            <Controller
              name="businessName"
              {...COMMON_PROPS}
              rules={{
                required: true,
                pattern: {
                  value: Windows1252Regex,
                  message: t("error.invalidCharacters"),
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  error={error !== undefined}
                  styles={{ width: "100%" }}
                  placeholder={
                    t(
                      "money.onboarding.step.company.companyInformation.businessName.placeholder"
                    ) as string
                  }
                  label={
                    t(
                      "money.onboarding.step.company.companyInformation.businessName"
                    ) as string
                  }
                />
              )}
            />
            {businessTypes && (
              <Controller
                name="businessType"
                {...COMMON_PROPS}
                rules={{
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                  required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomSelect
                    {...field}
                    error={error !== undefined}
                    styles={{ width: "100%" }}
                    placeholder={
                      t(
                        "money.onboarding.step.company.companyInformation.businessType.placeholder"
                      ) as string
                    }
                    label={
                      t(
                        "money.onboarding.step.company.companyInformation.businessType"
                      ) as string
                    }
                    defaultValue={field.value}
                    onChange={(e: SelectChangeEvent<unknown>) => {
                      field.onChange(e.target.value);
                    }}
                  >
                    {businessTypes?.map(
                      (businessType, index) =>
                        (businessType?.key ===
                          OnboardingBusinessType.LIMITED_LIABILITY_COMPANY ||
                          businessType?.key ===
                            OnboardingBusinessType.CORPORATION) && (
                          <MenuItem key={index} value={businessType.key}>
                            {businessType.description}
                          </MenuItem>
                        )
                    )}
                  </CustomSelect>
                )}
              />
            )}
          </Stack>
          <Stack direction={isTablet ? "column" : "row"} gap={3}>
            <Controller
              name="businessRegistrationNumber"
              {...COMMON_PROPS}
              rules={{
                required: true,
                pattern: {
                  value: EINRegex,
                  message: t("error.invalidEIN"),
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  autoComplete=""
                  error={error !== undefined}
                  isProtected
                  styles={{ width: "100%" }}
                  placeholder={
                    t(
                      "money.onboarding.step.company.companyInformation.businessRegistrationNumber.placeholder"
                    ) as string
                  }
                  label={
                    t(
                      "money.onboarding.step.company.companyInformation.businessRegistrationNumber"
                    ) as string
                  }
                  mask={EINMask as any}
                />
              )}
            />
            <Controller
              name="legalRegisteredDate"
              rules={{
                required: true,
              }}
              {...COMMON_PROPS}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <CustomDatePicker
                  value={dayjs(new Date(value ?? 0))}
                  error={error !== undefined}
                  styles={{ width: "100%" }}
                  onChange={(newValue) =>
                    onChange(dayjs(newValue).format("YYYY-MM-DD"))
                  }
                  placeholder={
                    t(
                      "money.onboarding.step.company.companyInformation.legalRegisteredDate.placeholder"
                    ) as string
                  }
                  label={
                    t(
                      "money.onboarding.step.company.companyInformation.legalRegisteredDate"
                    ) as string
                  }
                  disableFuture
                />
              )}
            />
          </Stack>
          <Stack direction={isTablet ? "column" : "row"} gap={3}>
            <Controller
              name="website"
              {...COMMON_PROPS}
              rules={{
                pattern: {
                  value: Windows1252Regex,
                  message: t("error.invalidCharacters"),
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  error={error !== undefined}
                  styles={{ width: "100%" }}
                  placeholder={
                    t(
                      "money.onboarding.step.company.companyInformation.website.placeholder"
                    ) as string
                  }
                  label={
                    t(
                      "money.onboarding.step.company.companyInformation.website"
                    ) as string
                  }
                />
              )}
            />
          </Stack>
          <Stack direction={isTablet ? "column" : "row"} gap={3}>
            <Controller
              name="description"
              {...COMMON_PROPS}
              rules={{
                required: true,
                pattern: {
                  value: Windows1252Regex,
                  message: t("error.invalidCharacters"),
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  error={error !== undefined}
                  multiline
                  styles={{ width: "100%" }}
                  placeholder={
                    t(
                      "money.onboarding.step.company.companyInformation.description.placeholder"
                    ) as string
                  }
                  label={
                    t(
                      "money.onboarding.step.company.companyInformation.description"
                    ) as string
                  }
                  rows={6}
                />
              )}
            />
          </Stack>
        </Stack>
        <ErrorBox formState={formState} />
        <ButtonBox
          isLoading={isLoading}
          disabled={isDisabled}
          onClickBack={() => {
            posthog?.capture(
              "money_onboarding_company_information_back_btn_click"
            );

            setCurrentStep(
              OnboardingStepsData[OnboardingStepsCategories.prepareInfo]
            );
          }}
          onClickNext={() => onSubmitHandler}
        />
      </Stack>
    </form>
  );
};

export default CompanyInformation;
