import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetStripeBillingPortalQueryVariables = Types.Exact<{
  customerId: Types.Scalars['ID'];
  returnUrl: Types.Scalars['String'];
}>;


export type GetStripeBillingPortalQuery = { __typename?: 'Query', customer?: { __typename?: 'Customer', id?: string | null, stripeBillingPortalMeta?: { __typename?: 'StripeBillingPortalMetadata', url: string } | null } | null };


export const GetStripeBillingPortalDocument = gql`
    query GetStripeBillingPortal($customerId: ID!, $returnUrl: String!) {
  customer(id: $customerId) {
    id
    stripeBillingPortalMeta(returnUrl: $returnUrl) {
      url
    }
  }
}
    `;

/**
 * __useGetStripeBillingPortalQuery__
 *
 * To run a query within a React component, call `useGetStripeBillingPortalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeBillingPortalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeBillingPortalQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      returnUrl: // value for 'returnUrl'
 *   },
 * });
 */
export function useGetStripeBillingPortalQuery(baseOptions: Apollo.QueryHookOptions<GetStripeBillingPortalQuery, GetStripeBillingPortalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripeBillingPortalQuery, GetStripeBillingPortalQueryVariables>(GetStripeBillingPortalDocument, options);
      }
export function useGetStripeBillingPortalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripeBillingPortalQuery, GetStripeBillingPortalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripeBillingPortalQuery, GetStripeBillingPortalQueryVariables>(GetStripeBillingPortalDocument, options);
        }
export type GetStripeBillingPortalQueryHookResult = ReturnType<typeof useGetStripeBillingPortalQuery>;
export type GetStripeBillingPortalLazyQueryHookResult = ReturnType<typeof useGetStripeBillingPortalLazyQuery>;
export type GetStripeBillingPortalQueryResult = Apollo.QueryResult<GetStripeBillingPortalQuery, GetStripeBillingPortalQueryVariables>;