import { ProductDuration } from "graphql/__generated__/types";
import { PRODUCTS_DB_KEYS } from "resources/productsLookupKeys";

export type BooksPackageValue = typeof INVOICING | typeof BOOKS;

export const INVOICING = "Invoicing";
export const BOOKS = "Books";

interface IBooksPackage {
  name: BooksPackageValue;
  highlights: string[];
  duration: {
    [key in ProductDuration]?: {
      price: number;
      lookupKey: PRODUCTS_DB_KEYS;
    };
  };
}

export interface IBooksPackages {
  [key: string]: IBooksPackage;
}
