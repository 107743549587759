import { useGetProductsQuery } from "graphql/__generated__/operations/Products.generated";
import { PRODUCTS_DB_KEYS } from "resources/productsLookupKeys";

export default function useGetStateFeesProducts() {
  const { data, loading: isProductQueryLoading } = useGetProductsQuery({
    fetchPolicy: "cache-first",
    errorPolicy: "all",
  });

  const productServices = data?.productService?.payload;

  const filteredFeesProducts = productServices?.filter((item) =>
    item?.key.includes(PRODUCTS_DB_KEYS.STATE_FEES_DB_KEY)
  );

  return { filteredFeesProducts, isProductQueryLoading };
}
