import { Services } from "graphql/__generated__/types";
import { createContext, ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";

interface OnboardingProviderContextValue {
  currentOnboardingStep: number;
}

export const OnboardingContext = createContext<OnboardingProviderContextValue>(
  {} as OnboardingProviderContextValue
);

export const ROUTE_TO_ONBOARDING_STEP = {
  [ROUTER_PATHS.ONBOARDING_INTRO]: 0,
  [ROUTER_PATHS.ONBOARDING_COMPANY_NAME]: 1,
  [ROUTER_PATHS.ONBOARDING_ADDRESS]: 2,
  [ROUTER_PATHS.ONBOARDING_INDUSTRY]: 3,
  [ROUTER_PATHS.ONBOARDING_DESCRIPTION]: 4,
  [ROUTER_PATHS.ONBOARDING_MEMBERS]: 5,
  [ROUTER_PATHS.ONBOARDING_EXECUTIVE]: 5,
  [ROUTER_PATHS.ONBOARDING_RESPONSIBLE_PARTY]: 6,
  [ROUTER_PATHS.ONBOARDING_OWNERSHIP]: 7,
  [ROUTER_PATHS.ONBOARDING_SHARE]: 7,
  [ROUTER_PATHS.ONBOARDING_REVIEW]: 8,
};

// the values are coming from the BE and indicate the Formation service sub statuses
const FORMATION_TO_ONBOARDING_STEP = {
  WaitingForNameData: 1,
  WaitingForAddressData: 2,
  WaitingForActivityData: 3,
  WaitingForDescriptionData: 4,
  WaitingForExecutiveData: 5,
  WaitingForMemberData: 7,
  WaitingForResponsiblePartyData: 7,
  WaitingForMemberOwnershipData: 7,
  WaitingForValuationData: 7,
  WaitingToFileWithState: 8,
};

type Props = {
  children: ReactNode;
  formationService: Services;
};

const OnboardingProvider = ({ children, formationService }: Props) => {
  const location = useLocation();

  const subStatus = formationService.subStatus;

  const onboardingStepFromUser = ROUTE_TO_ONBOARDING_STEP[location.pathname];

  const highestOnboardingStepReached =
    FORMATION_TO_ONBOARDING_STEP[
      subStatus as keyof typeof FORMATION_TO_ONBOARDING_STEP
    ] || 20;

  const value = {
    currentOnboardingStep:
      onboardingStepFromUser <= highestOnboardingStepReached
        ? onboardingStepFromUser
        : highestOnboardingStepReached,
  };

  return (
    <OnboardingContext.Provider value={value}>
      {children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingProvider;
