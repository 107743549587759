import { useState } from "react";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons/lib/icons";
import {
  Autocomplete,
  Box,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useFormContext } from "react-hook-form";
import utils, { colors } from "utils";
import { SignupSelect } from "views/SignupFlow/styles"; // refactor
import { isValidPhoneNumber } from "react-phone-number-input";
import { useGetCountriesQuery } from "graphql/__generated__/operations/Countries.generated";
import { Iso31661Alpha3 } from "graphql/__generated__/types";
import { LoadingComponent } from "components/Loading";
import { INITIAL_MEMBER_VALUES } from "../helpers/initialMembersData";
import ISOCountries from "i18n-iso-countries";
import ButtonBox, { RightButtonIconTypes } from "components/ButtonBox";
import { SSNRegex, Windows1252Regex } from "utils/regexValidations";
import { t } from "i18next";
import ErrorBox from "components/ErrorBox";
import SSNMask from "components/SSNMask";
import { CustomPhoneInput } from "components/Form/CustomInput/CustomPhoneStyles";

interface IResponsiblePartyModal {
  isOpenModal: boolean;
  isLoading: boolean;
  toggleModalHandler: () => void;
  onAddHandler: (data: typeof INITIAL_MEMBER_VALUES) => void;
}

const ResponsiblePartyModal = ({
  isOpenModal,
  isLoading,
  toggleModalHandler,
  onAddHandler,
}: IResponsiblePartyModal) => {
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);

  const [showSSN, setShowSSN] = useState(false);
  const [showConfirmSSN, setShowConfirmSSN] = useState(false);

  const { data, loading } = useGetCountriesQuery();
  const countryList = data?.countries;

  const { control, formState, watch, setValue, getValues, reset } =
    useFormContext<typeof INITIAL_MEMBER_VALUES>();

  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  const countryCode = watch("address.country");
  const address = watch("address");
  const legalFirstName = watch("legalFirstName");
  const legalLastName = watch("legalLastName");
  const socialSecurityNumber = watch("socialSecurityNumber");
  const confirmSocialSecurityNumber = watch("confirmSocialSecurityNumber");

  const countrySubdvisions =
    countryList?.find((country) => country?.code === countryCode)
      ?.subdivisions || [];

  const hasStates = !!countrySubdvisions.length;

  const isUSCustomer = !!(address.country === "USA");

  if (loading && !countryList) {
    return <LoadingComponent />;
  }

  const { line2, ...requiredAddressFields } = address;
  const isAddressValid = Object.values(requiredAddressFields).every(Boolean);
  const areNamesValid = !!(legalFirstName && legalLastName);
  const isSSNValid =
    socialSecurityNumber || confirmSocialSecurityNumber
      ? socialSecurityNumber === confirmSocialSecurityNumber
      : true;

  const isDisabled = () => {
    if (isUSCustomer) {
      return !(
        isAddressValid &&
        areNamesValid &&
        isSSNValid &&
        formState.isValid
      );
    }
    return !(isAddressValid && areNamesValid && formState.isValid);
  };

  return (
    <Modal
      open={isOpenModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      onClose={toggleModalHandler}
      data-testid="OnboardingMembersModal"
    >
      <Stack
        direction="column"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isTablet ? "100%" : 676,
          height: isTablet ? "100%" : "auto",
          maxHeight: isTablet ? "100%" : "90%",
          boxShadow: 24,
          borderRadius: isTablet ? 0 : 4,
          p: 4,
          bgcolor: colors.white,
          overflow: "scroll",
        }}
        gap={3}
      >
        <Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography mb={0} variant="h3">
              Add Responsible Party
            </Typography>
            <IconButton onClick={toggleModalHandler}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Stack component="form">
          <Stack spacing={2}>
            <Box>
              <Typography variant="h5">
                Responsible Party Information
              </Typography>
              <Typography variant="h6">
                Use your full legal name as it appears on your passport, birth
                certificate, or other legal form of identification. The use of
                nicknames or shortened names may result in increased processing
                time and/or rejection.
              </Typography>
            </Box>
            <Stack direction={isMobile ? "column" : "row"} spacing={2}>
              <Stack width={isMobile ? "100%" : "50%"} spacing={1}>
                <Typography
                  variant="h6"
                  color={utils.secondaryColors.secondaryMain}
                >
                  Legal First Name
                </Typography>
                <Stack>
                  <Controller
                    name="legalFirstName"
                    {...COMMON_PROPS}
                    rules={{
                      required: true,
                      pattern: {
                        value: Windows1252Regex,
                        message: t("error.invalidCharacters"),
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        name="legalFirstName"
                        id="legalFirstName"
                        placeholder="Enter legal first name"
                        error={error !== undefined}
                        sx={{
                          ".MuiInputBase-root": {
                            height: "40px",
                          },
                        }}
                      />
                    )}
                  />
                </Stack>
              </Stack>
              <Stack width={isMobile ? "100%" : "50%"} spacing={1}>
                <Typography
                  variant="h6"
                  color={utils.secondaryColors.secondaryMain}
                >
                  Legal Last Name
                </Typography>
                <Stack>
                  <Controller
                    name="legalLastName"
                    {...COMMON_PROPS}
                    rules={{
                      required: true,
                      pattern: {
                        value: Windows1252Regex,
                        message: t("error.invalidCharacters"),
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        name="legalLastName"
                        id="legalLastName"
                        placeholder="Enter legal last name"
                        error={error !== undefined}
                        sx={{
                          ".MuiInputBase-root": {
                            height: "40px",
                          },
                        }}
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack width={isMobile ? "100%" : "calc(50% - 8px)"} spacing={1}>
              <Typography
                variant="h6"
                color={utils.secondaryColors.secondaryMain}
              >
                Phone Number
              </Typography>
              <Controller
                name="address.phone"
                {...COMMON_PROPS}
                rules={{
                  required: true,
                  validate: (value) =>
                    value ? isValidPhoneNumber(value) : true,
                }}
                render={({ field: { onChange, value } }) => (
                  <CustomPhoneInput
                    name="address.phone"
                    id="address.phone"
                    onChange={onChange}
                    value={value || ""}
                    defaultCountry="US"
                    international
                    style={{ height: 40 }}
                  />
                )}
              />
            </Stack>
          </Stack>
          <Divider sx={{ mt: 4, mb: 3 }} />
          <Stack spacing={2}>
            <Box>
              <Typography variant="h5">
                Responsible Party Street Address
              </Typography>
              <Typography variant="h6">
                The state requires the Responsible Party to use a physical
                street address, including those outside the United States. The
                use of PO Boxes or similar may result in increased processing
                time and/or rejection.
              </Typography>
            </Box>
            <Stack direction={isMobile ? "column" : "row"} spacing={2}>
              <Stack width={isMobile ? "100%" : "calc(50% - 8px)"} spacing={1}>
                <Typography
                  variant="h6"
                  color={utils.secondaryColors.secondaryMain}
                >
                  Address Line 1
                </Typography>
                <Controller
                  name="address.line1"
                  {...COMMON_PROPS}
                  rules={{
                    required: true,
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      name="address.line1"
                      id="address.line1"
                      placeholder="Enter Address Line 1"
                      error={error !== undefined}
                      sx={{
                        ".MuiInputBase-root": {
                          height: "40px",
                        },
                      }}
                    />
                  )}
                />
              </Stack>
              <Stack width={isMobile ? "100%" : "calc(50% - 8px)"} spacing={1}>
                <Typography
                  variant="h6"
                  color={utils.secondaryColors.secondaryMain}
                >
                  Address Line 2 (Optional)
                </Typography>
                <Controller
                  name="address.line2"
                  {...COMMON_PROPS}
                  rules={{
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      name="address.line2"
                      id="address.line2"
                      placeholder="Enter Address Line 2"
                      error={error !== undefined}
                      sx={{
                        ".MuiInputBase-root": {
                          height: "40px",
                        },
                      }}
                    />
                  )}
                />
              </Stack>
            </Stack>
            <Stack direction={isMobile ? "column" : "row"} spacing={2}>
              <Stack width={isMobile ? "100%" : "calc(50% - 8px)"}>
                <Typography
                  variant="h6"
                  color={utils.secondaryColors.secondaryMain}
                  mb={1}
                >
                  Country
                </Typography>
                <Controller
                  name="address.country"
                  {...COMMON_PROPS}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Autocomplete
                      fullWidth
                      options={countryList as any}
                      autoHighlight
                      getOptionLabel={(option) =>
                        option.name ? option.name : ""
                      }
                      value={{
                        name:
                          countryList?.find(
                            (country) => country?.code === address.country
                          )?.name || "",
                        code:
                          countryList?.find(
                            (country) => country?.code === address.country
                          )?.code || "",
                      }}
                      onChange={(_, data) => {
                        // update the state value to be an empty string when the country changes
                        setValue("address.state", "");
                        setValue(
                          "address.country",
                          (data?.code as Iso31661Alpha3) || ""
                        );
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option?.code === value.code
                      }
                      disablePortal
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.code && (
                            <img
                              loading="lazy"
                              width="20"
                              src={`https://flagcdn.com/w20/${ISOCountries.alpha3ToAlpha2(
                                option?.code
                              )?.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${ISOCountries.alpha3ToAlpha2(
                                option?.code
                              )?.toLowerCase()}.png 2x`}
                              alt=""
                            />
                          )}
                          {option.name} ({option.code})
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...field}
                          {...params}
                          fullWidth
                          onChange={() => {}}
                          id="address.country"
                          placeholder="Select country"
                          name="address.country"
                          type="text"
                          variant="outlined"
                          sx={{
                            ".MuiInputBase-input": {
                              height: "8px",
                            },
                          }}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Stack>
              <Stack width={isMobile ? "100%" : "calc(50% - 8px)"} spacing={1}>
                <Typography
                  variant="h6"
                  color={utils.secondaryColors.secondaryMain}
                >
                  State
                </Typography>
                <Controller
                  name="address.state"
                  {...COMMON_PROPS}
                  rules={{
                    required: true,
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                  }}
                  render={({ field, fieldState: { error } }) =>
                    hasStates ? (
                      <SignupSelect
                        {...field}
                        fullWidth
                        displayEmpty
                        error={error !== undefined}
                        onChange={(_, data: any) => {
                          field.onChange(data?.props?.value || "");
                        }}
                      >
                        <MenuItem disabled value="">
                          Select State
                        </MenuItem>
                        {countrySubdvisions.map((subdivision, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={subdivision?.code || ""}
                            >
                              {subdivision?.name || ""}
                            </MenuItem>
                          );
                        })}
                      </SignupSelect>
                    ) : (
                      <TextField
                        {...field}
                        fullWidth
                        id="address.state"
                        placeholder={"Enter state"}
                        name="address.state"
                        type="text"
                        variant="outlined"
                        error={error !== undefined}
                        sx={{
                          ".MuiInputBase-root": {
                            height: "40px",
                          },
                        }}
                      />
                    )
                  }
                />
              </Stack>
            </Stack>
            <Stack direction={isMobile ? "column" : "row"} spacing={2}>
              <Stack width={isMobile ? "100%" : "calc(50% - 8px)"} spacing={1}>
                <Typography
                  variant="h6"
                  color={utils.secondaryColors.secondaryMain}
                >
                  City
                </Typography>
                <Controller
                  name="address.city"
                  {...COMMON_PROPS}
                  rules={{
                    required: true,
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      id="address.city"
                      placeholder="Enter city"
                      name="address.city"
                      type="text"
                      variant="outlined"
                      error={error !== undefined}
                      sx={{
                        ".MuiInputBase-root": {
                          height: "40px",
                        },
                      }}
                    />
                  )}
                />
              </Stack>
              <Stack width={isMobile ? "100%" : "calc(50% - 8px)"} spacing={1}>
                <Typography
                  variant="h6"
                  color={utils.secondaryColors.secondaryMain}
                >
                  Zip Code
                </Typography>
                <Controller
                  name="address.postalCode"
                  {...COMMON_PROPS}
                  rules={{
                    required: true,
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      id="address.postalCode"
                      placeholder={"Enter zip/postal code"}
                      name="address.postalCode"
                      type="text"
                      autoComplete="postal-code"
                      error={error !== undefined}
                      sx={{
                        ".MuiInputBase-root": {
                          height: "40px",
                        },
                      }}
                    />
                  )}
                />
              </Stack>
            </Stack>
          </Stack>
          <Divider sx={{ mt: 4, mb: 3 }} />
          {isUSCustomer && (
            <Stack>
              <Typography variant="h5">
                Social Security Number (Optional)
              </Typography>
              <Stack
                bgcolor={colors.pink}
                sx={{
                  borderRadius: "24px",
                  justifyContent: "center",
                  padding: 3,
                  mt: 3,
                }}
                spacing={2}
              >
                <Typography variant="h5">🇺🇸 US Resident?</Typography>
                <Typography>
                  As a US resident, your Employment Identification Number (EIN)
                  application gets prioritized over the backlog of other EIN
                  applications if it is requested with your Social Security
                  Number (SSN) on the application. This can mean receiving your
                  new company’s EIN in a matter of days instead of weeks.
                </Typography>
              </Stack>
              <Stack spacing={2} mt={2}>
                <Stack spacing={1}>
                  <Typography
                    variant="h6"
                    color={utils.secondaryColors.secondaryMain}
                  >
                    Social Security Number
                  </Typography>
                  <Stack>
                    <Controller
                      name="socialSecurityNumber"
                      {...COMMON_PROPS}
                      rules={{
                        required: false,
                        pattern: {
                          value: SSNRegex,
                          message: t("error.invalidSSN"),
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          name="socialSecurityNumber"
                          id="socialSecurityNumber"
                          placeholder="Enter your Social Security Number"
                          type={showSSN ? "text" : "password"}
                          autoComplete="one-time-code"
                          sx={{
                            ".MuiInputBase-root": {
                              height: "40px",
                            },
                          }}
                          InputProps={{
                            inputComponent: SSNMask as any,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowSSN(!showSSN)}
                                  edge="end"
                                  color="secondary"
                                  size="small"
                                >
                                  {showSSN ? (
                                    <EyeOutlined />
                                  ) : (
                                    <EyeInvisibleOutlined />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Stack>
                </Stack>
                <Stack spacing={1}>
                  <Typography
                    variant="h6"
                    color={utils.secondaryColors.secondaryMain}
                  >
                    Confirm Social Security Number
                  </Typography>
                  <Stack>
                    <Controller
                      name="confirmSocialSecurityNumber"
                      {...COMMON_PROPS}
                      rules={{
                        required: false,
                        pattern: {
                          value: SSNRegex,
                          message: t("error.invalidSSN"),
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type={showConfirmSSN ? "text" : "password"}
                          name="confirmSocialSecurityNumber"
                          id="confirmSocialSecurityNumber"
                          placeholder="Confirm Social Security Number"
                          autoComplete="one-time-code"
                          sx={{
                            ".MuiInputBase-root": {
                              height: "40px",
                            },
                          }}
                          InputProps={{
                            inputComponent: SSNMask as any,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setShowConfirmSSN(!showConfirmSSN)
                                  }
                                  edge="end"
                                  color="secondary"
                                  size="small"
                                >
                                  {showConfirmSSN ? (
                                    <EyeOutlined />
                                  ) : (
                                    <EyeInvisibleOutlined />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
        <ErrorBox formState={formState} />
        <Stack direction={isMobile ? "column" : "row"} gap={1}>
          <ButtonBox
            onClickBack={toggleModalHandler}
            onClickNext={() => {
              onAddHandler(getValues());
              reset();
            }}
            rightButtonText="Add"
            leftIcon="close"
            rightIcon={RightButtonIconTypes.ADD}
            disableSticky
            disabled={isDisabled()}
            isLoading={isLoading}
          />
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ResponsiblePartyModal;
