import * as Types from '../types';

import { gql } from '@apollo/client';
import { CompanyResponsiblePartyInfoFragmentDoc } from './Company.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateResponsiblePartyMutationVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  member: Types.ResponsiblePartyInput;
}>;


export type UpdateResponsiblePartyMutation = { __typename?: 'Mutation', company?: { __typename?: 'CompanyMutation', updateResponsibleParty?: { __typename?: 'Company', id: string, responsibleParty?: { __typename?: 'ResponsibleParty', legalFirstName: string, legalLastName: string, ssn?: string | null, address: { __typename?: 'Address', line1: string, line2?: string | null, state: string, city: string, phone?: string | null, postalCode: string, country: Types.Iso31661Alpha3 } } | null, services?: Array<{ __typename?: 'Services', service: Types.CompanyServices, variant?: Types.ServiceVariant | null, status: Types.ServiceCategory, subStatus?: string | null }> | null } | null } | null };


export const UpdateResponsiblePartyDocument = gql`
    mutation UpdateResponsibleParty($companyId: ID!, $member: ResponsiblePartyInput!) {
  company(id: $companyId) {
    updateResponsibleParty(new: $member) {
      id
      responsibleParty {
        ...CompanyResponsiblePartyInfo
      }
      services {
        service
        variant
        status
        subStatus
      }
    }
  }
}
    ${CompanyResponsiblePartyInfoFragmentDoc}`;
export type UpdateResponsiblePartyMutationFn = Apollo.MutationFunction<UpdateResponsiblePartyMutation, UpdateResponsiblePartyMutationVariables>;

/**
 * __useUpdateResponsiblePartyMutation__
 *
 * To run a mutation, you first call `useUpdateResponsiblePartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResponsiblePartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResponsiblePartyMutation, { data, loading, error }] = useUpdateResponsiblePartyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      member: // value for 'member'
 *   },
 * });
 */
export function useUpdateResponsiblePartyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateResponsiblePartyMutation, UpdateResponsiblePartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateResponsiblePartyMutation, UpdateResponsiblePartyMutationVariables>(UpdateResponsiblePartyDocument, options);
      }
export type UpdateResponsiblePartyMutationHookResult = ReturnType<typeof useUpdateResponsiblePartyMutation>;
export type UpdateResponsiblePartyMutationResult = Apollo.MutationResult<UpdateResponsiblePartyMutation>;
export type UpdateResponsiblePartyMutationOptions = Apollo.BaseMutationOptions<UpdateResponsiblePartyMutation, UpdateResponsiblePartyMutationVariables>;