import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { FAQDashboardProps, FaqItem } from "./types";
import { CustomCard } from "components/Cards";
import { LinkExternalDomain } from "components/LinkExternalDomain";
import { colors } from "utils";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Stack, Typography } from "@mui/material";
import { usePostHog } from "posthog-js/react";

export const FAQDashboard: React.FC<FAQDashboardProps> = ({
  faqs,
  firstOpened,
}) => {
  const posthog = usePostHog();
  const [expanded, setExpanded] = useState(firstOpened ? faqs[0].question : "");

  const handleChange = (question: string, isExpanded: boolean) => {
    setExpanded(!isExpanded ? question : "");
    posthog?.capture(`accordion-${isExpanded ? "contract" : "expand"}`, {
      accordion_name: "dashboard-faq",
      faq_q: question,
    });
  };

  const getFaqItem = (
    { question, answer, readMorelink }: FaqItem,
    isExpanded: boolean
  ) => {
    return (
      <>
        <AccordionSummary
          expandIcon={isExpanded ? <RemoveIcon /> : <AddIcon />}
          sx={{
            background: colors.lightGrey,
            py: 2,
            pl: 0,
          }}
        >
          <Typography fontSize={16} fontWeight={500} color={colors.black}>
            {question}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: isExpanded ? "" : "none",
            pl: 0,
            py: 0,
          }}
        >
          {answer}
          {readMorelink && (
            <Stack alignItems={"flex-end"} my={2}>
              <LinkExternalDomain to={readMorelink}>
                <Typography variant="subtitle1">Read more →</Typography>
              </LinkExternalDomain>
            </Stack>
          )}
        </AccordionDetails>
      </>
    );
  };

  return (
    <CustomCard $radius="strong" $shadow="xstrong" $padding={4}>
      <Typography variant="h3" color={colors.black}>
        FAQ's
      </Typography>
      {faqs.map((faqItem, index) => {
        const isExpanded = expanded === faqItem.question;
        return (
          <Accordion
            data-attr="dashboard-faq"
            key={index}
            expanded={isExpanded}
            elevation={0}
            disableGutters
            onChange={() => handleChange(faqItem.question, isExpanded)}
            sx={{
              bgcolor: colors.lightGrey,
            }}
          >
            <Box key={index}>{getFaqItem(faqItem, isExpanded)}</Box>
          </Accordion>
        );
      })}
    </CustomCard>
  );
};
