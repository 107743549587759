import styled from "styled-components";
import { ReactComponent as DoolaLogo } from "assets/doola_logo_black.svg";

export const StyledLogo = styled(DoolaLogo)<{
  width?: number;
  $darkMode?: boolean;
  $linesColor?: string;
}>`
  width: ${({ width }) => `${width ?? 110}px`};

  .brandName {
    fill: ${({ $darkMode }) => ($darkMode ? "#fff" : "#1e1e1e")};
  }

  .brandLines {
    fill: ${({ $linesColor }) => $linesColor || "#EAB94E"};
  }
`;
