import { TransferSteps } from "./data";
import TransferInfo from "./Steps/TransferInfo";
import { IStepProps } from "./types";
import { Dispatch, SetStateAction } from "react";
import Review from "./Steps/Review";
import Amount from "./Steps/Amount";
import Details from "./Steps/Details";

interface IStepContentProps extends IStepProps {
  currentStep: TransferSteps;
  setIsFinalStep: Dispatch<SetStateAction<boolean>>;
}

const StepContent = ({
  currentStep,
  setCurrentStep,
  setIsFinalStep,
  transferData,
  setTransferData,
}: IStepContentProps) => {
  const renderStepContent = (currentStep: TransferSteps) => {
    const commonProps: IStepProps = {
      setCurrentStep,
      transferData,
      setTransferData,
    };

    switch (currentStep) {
      case TransferSteps.transferInfo:
        return <TransferInfo {...commonProps} />;
      case TransferSteps.amount:
        return <Amount {...commonProps} />;
      case TransferSteps.details:
        return <Details {...commonProps} />;
      case TransferSteps.review:
        return <Review {...commonProps} setIsFinalStep={setIsFinalStep} />;
    }
  };

  return <>{renderStepContent(currentStep)}</>;
};

export default StepContent;
