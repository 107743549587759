import { useGetProductsQuery } from "graphql/__generated__/operations/Products.generated";
import { ProductDuration } from "graphql/__generated__/types";
import { BOOKS, IBooksPackages, INVOICING } from "../data";
import { PRODUCTS_DB_KEYS } from "resources/productsLookupKeys";

export default function useGetBooksProducts() {
  const { data, loading: isProductQueryLoading } = useGetProductsQuery({
    fetchPolicy: "cache-first",
    errorPolicy: "all",
  });

  const productServices = data?.productService?.payload;

  const filteredPlans = productServices?.filter((item) =>
    [
      PRODUCTS_DB_KEYS.RECORDKEEPING_COMPLETE_MONTHLY,
      PRODUCTS_DB_KEYS.RECORDKEEPING_COMPLETE_ANNUAL,
      PRODUCTS_DB_KEYS.RECORDKEEPING_INVOICE_ONLY_MONTHLY,
      PRODUCTS_DB_KEYS.RECORDKEEPING_INVOICE_ONLY_ANNUAL,
    ].includes(item?.key as PRODUCTS_DB_KEYS)
  );

  const modifiedPlans = filteredPlans?.map((plan) => ({
    ...plan,
    usdPrice: (plan?.usdPriceCents ?? 0) / 100,
  }));

  const findPlanByKey = (key: string) =>
    modifiedPlans?.find((item) => item?.key === key);

  const BOOKS_MONTHLY = findPlanByKey(
    PRODUCTS_DB_KEYS.RECORDKEEPING_COMPLETE_MONTHLY
  );
  const BOOKS_ANNUAL = findPlanByKey(
    PRODUCTS_DB_KEYS.RECORDKEEPING_COMPLETE_ANNUAL
  );
  const INVOICING_MONTHLY = findPlanByKey(
    PRODUCTS_DB_KEYS.RECORDKEEPING_INVOICE_ONLY_MONTHLY
  );
  const INVOICING_ANNUAL = findPlanByKey(
    PRODUCTS_DB_KEYS.RECORDKEEPING_INVOICE_ONLY_ANNUAL
  );

  const BOOKS_PLANS: IBooksPackages = {
    [INVOICING]: {
      name: INVOICING,
      highlights: [
        "signup.formation.invoicing.highlight.1",
        "signup.formation.invoicing.highlight.2",
        "signup.formation.invoicing.highlight.3",
        "signup.formation.invoicing.highlight.4",
        "signup.formation.invoicing.highlight.5",
        "signup.formation.invoicing.highlight.6",
        "signup.formation.invoicing.highlight.7",
      ],
      duration: {
        [INVOICING_ANNUAL?.duration as ProductDuration]: {
          price: INVOICING_ANNUAL?.usdPrice,
          lookupKey: PRODUCTS_DB_KEYS.RECORDKEEPING_INVOICE_ONLY_ANNUAL,
        },
        [INVOICING_MONTHLY?.duration as ProductDuration]: {
          price: INVOICING_MONTHLY?.usdPrice,
          lookupKey: PRODUCTS_DB_KEYS.RECORDKEEPING_INVOICE_ONLY_MONTHLY,
        },
      },
    },
    [BOOKS]: {
      name: BOOKS,
      highlights: [
        "signup.formation.books.highlight.1",
        "signup.formation.books.highlight.2",
        "signup.formation.books.highlight.3",
        "signup.formation.books.highlight.4",
        "signup.formation.books.highlight.5",
      ],
      duration: {
        [BOOKS_ANNUAL?.duration as ProductDuration]: {
          price: BOOKS_ANNUAL?.usdPrice,
          lookupKey: PRODUCTS_DB_KEYS.RECORDKEEPING_COMPLETE_ANNUAL,
        },
        [BOOKS_MONTHLY?.duration as ProductDuration]: {
          price: BOOKS_MONTHLY?.usdPrice,
          lookupKey: PRODUCTS_DB_KEYS.RECORDKEEPING_COMPLETE_MONTHLY,
        },
      },
    },
  };

  return { BOOKS_PLANS, isProductQueryLoading };
}
