import { Stack, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import utils, { colors } from "utils";
import {
  BusinessCenterOutlined,
  CorporateFareOutlined,
  PersonOutlined,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import FormControlElement from "views/SignupFlow/components/FormControlElement";
import ButtonBox from "components/ButtonBox";
import { addMemberSteps } from "../types";
import { IAddMemberStepsProps } from "../../types";
import { MemberDialogFormModeEnum } from "../../../types";

const MemberType = ({
  setMemberDialogDetails,
  setAddMemberCurrentStep,
}: IAddMemberStepsProps) => {
  const [isIndividual, setIsIndividual] = useState(true);

  const { t } = useTranslation();
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  return (
    <>
      <Stack mb={3} gap={1}>
        <Stack direction={"row"} alignItems={"center"} gap={1.5}>
          <BusinessCenterOutlined />
          <Typography fontSize={16} fontWeight={500}>
            {t("general.memberType")}
          </Typography>
        </Stack>
        <Typography fontSize={16} color={colors.contentTertiary} mt={0.5}>
          {t("money.onboarding.step.membersInformation.addModal.subtitle")}
        </Typography>
      </Stack>
      <Stack gap={4}>
        <Stack direction={isTablet ? "column" : "row"} gap={3} width={"100%"}>
          <FormControlElement
            outerStyles={{ width: "100%" }}
            optionValue={true}
            selectedValue={isIndividual}
            changeHandler={() => setIsIndividual(true)}
            content={
              <Stack
                width={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={1.5}
              >
                <Stack
                  borderRadius={"50%"}
                  bgcolor={isIndividual ? colors.borderBlue : colors.grey}
                  p={2}
                >
                  <PersonOutlined />
                </Stack>
                <Typography fontWeight={500} fontSize={16} textAlign={"center"}>
                  {t("general.individual")}
                </Typography>
              </Stack>
            }
          />
          <FormControlElement
            outerStyles={{ width: "100%" }}
            optionValue={false}
            selectedValue={isIndividual}
            changeHandler={() => setIsIndividual(false)}
            content={
              <Stack
                width={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={1.5}
              >
                <Stack
                  borderRadius={"50%"}
                  bgcolor={!isIndividual ? colors.borderBlue : colors.grey}
                  p={2}
                >
                  <CorporateFareOutlined />
                </Stack>
                <Typography fontWeight={500} fontSize={16} textAlign={"center"}>
                  {t("general.company")}
                </Typography>
              </Stack>
            }
          />
        </Stack>
        <ButtonBox
          leftIcon={"close"}
          onClickBack={() =>
            setMemberDialogDetails({
              show: false,
              stakeholderId: "",
              mode: MemberDialogFormModeEnum.add,
            })
          }
          onClickNext={() =>
            isIndividual
              ? setAddMemberCurrentStep(addMemberSteps.individualMemberDetails)
              : setAddMemberCurrentStep(addMemberSteps.companyMemberDetails)
          }
        />
      </Stack>
    </>
  );
};

export default MemberType;
