import { Stack, Typography } from "@mui/material";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import { colors } from "utils";
import { useTranslation } from "react-i18next";

export const CustomExpandIconDesktop = () => (
  <Stack
    sx={{
      alignItems: "center",
      ".Mui-expanded & > .collapsIconWrapper": {
        display: "none",
      },
      ".expandIconWrapper": {
        display: "none",
      },
      ".Mui-expanded & > .expandIconWrapper": {
        display: "block",
      },
    }}
  >
    <RemoveCircleOutlineRoundedIcon
      className="expandIconWrapper"
      color="primary"
    />
    <AddCircleOutlineRoundedIcon
      className="collapsIconWrapper"
      color="primary"
    />
  </Stack>
);

export const CustomExpandIconTablet = () => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        alignItems: "center",
        color: colors.black,
        borderRadius: 30,
        border: `1px solid ${colors.black}`,
        p: "6px 16px",
        mt: 2,
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none",
        },
        ".expandIconWrapper": {
          display: "none",
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "block",
        },
      }}
    >
      <Typography className="expandIconWrapper">
        {t("general.hideDetails")}
      </Typography>
      <Typography className="collapsIconWrapper">
        {t("general.showDetails")}
      </Typography>
    </Stack>
  );
};
