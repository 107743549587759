import { OnboardingBusinessType } from "graphql/__generated__/types";

export type InitialCompanyMemberValuesType = {
  businessName: string;
  businessType: OnboardingBusinessType;
  businessRegistrationNumber: string;
  registeredCountry: string;
};

export const INTITAL_COMPANY_MEMBER_VALUES: InitialCompanyMemberValuesType = {
  businessName: "",
  businessType: OnboardingBusinessType.LIMITED_LIABILITY_COMPANY,
  businessRegistrationNumber: "",
  registeredCountry: "",
};
