import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import {
  LogoutOutlined,
  ScheduleOutlined,
  UserOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import { LinkExternalDomain } from "components/LinkExternalDomain";

interface Props {
  stripeCustomerPortalLink: string | null;
  handleLogout: () => void;
}

const ProfileTab = ({ handleLogout, stripeCustomerPortalLink }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const menuItemClickHandler = (routeTo: string) => {
    navigate(routeTo);
  };

  return (
    <List
      component="nav"
      sx={{
        p: 0,
        "& .MuiListItemIcon-root": {
          minWidth: 32,
          color: theme.palette.secondary.dark,
        },
      }}
    >
      <ListItemButton
        onClick={() => menuItemClickHandler(ROUTER_PATHS.SETTINGS)}
      >
        <ListItemIcon>
          <UserOutlined />
        </ListItemIcon>
        <ListItemText primary="View Profile" />
      </ListItemButton>
      {stripeCustomerPortalLink && (
        <LinkExternalDomain to={stripeCustomerPortalLink}>
          <ListItemButton>
            <ListItemIcon>
              <WalletOutlined />
            </ListItemIcon>
            <ListItemText primary="Billing" />
          </ListItemButton>
        </LinkExternalDomain>
      )}
      <LinkExternalDomain to="https://bit.ly/doola-free-consultation">
        <ListItemButton>
          <ListItemIcon>
            <ScheduleOutlined />
          </ListItemIcon>
          <ListItemText primary="Book a free consultation!" />
        </ListItemButton>
      </LinkExternalDomain>
      <ListItemButton onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
};

export default ProfileTab;
