import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { Stack, Typography } from "@mui/material";
import { UpcomingConsultations } from "graphql/__generated__/types";
import { colors } from "utils";
import { format as formatDate } from "fecha";

type Props = {
  consultation: UpcomingConsultations;
  isModalType?: boolean;
};

const Consultation = ({ consultation, isModalType = false }: Props) => {
  // Convert the string to a number
  const startTimeNumeric = consultation.startTime
    ? parseInt(consultation.startTime, 10)
    : Date.now();

  const startTime = new Date(startTimeNumeric || 0);

  return (
    <Stack
      width={"100%"}
      direction={"row"}
      justifyContent={"space-between"}
      sx={{
        ...(isModalType && {
          p: 3,
          bgcolor: colors.lightGrey,
          borderRadius: 3,
        }),
      }}
    >
      <Stack>
        <Typography variant="h5" fontWeight={500}>
          {consultation.eventName}
        </Typography>
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <CalendarMonthOutlinedIcon sx={{ fontSize: "16px" }} />{" "}
          {formatDate(startTime, "ddd MMM Do, YYYY - hh:mm A")}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Consultation;
