import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import ButtonBox from "components/ButtonBox";
import utils, { colors } from "utils";
import { t } from "i18next";
import { ISubStepProps, companySubSteps } from "../../types";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { DescriptionOutlined, FileUploadOutlined } from "@mui/icons-material";
import {
  OnboardingStepsCategories,
  OnboardingStepsData,
} from "views/DashboardApp/Money/Onboarding/data";
import useCompany from "graphql/hooks/UseCompany";
import Button from "components/Button";
import { LoadingComponent } from "components/Loading";
import { useGenerateDocServiceUploadUrlMutation } from "graphql/__generated__/operations/GenerateDocServiceUploadUrl.generated";
import { toast } from "toast";
import { useSetMoneyOnboardingMutation } from "graphql/__generated__/operations/MoneyOnboardingValidation.generated";
import useOnboardingData from "views/DashboardApp/Money/Onboarding/hooks/useOnboardingData";
import {
  OnboardingDocumentType,
  Source,
  WalletOnboard,
  WalletOnboardingDocumentInput,
} from "graphql/__generated__/types";
import { removeTypename } from "utils/helpers/removeTypename";
import { MoneyDocumentsSourceCategories } from "views/DashboardApp/Money/Onboarding/helpers/types";
import { UploadStatuses } from "components/FileUpload/UploadedDocument/helpers";
import { useDeleteDocumentDocServiceMutation } from "graphql/__generated__/operations/DeleteDocumentDocService.generated";
import UploadedDocument from "components/FileUpload/UploadedDocument";
import { usePostHog } from "posthog-js/react";

const EinLetter = ({
  setCurrentStep,
  setCurrentSubStep,
  setShowHeading,
}: ISubStepProps) => {
  const { currentCompany, refetchCompany } = useCompany();

  const [fileList, setFileList] = useState<File[]>([]);
  const [fileStatus, setFileStatus] = useState<{
    [key: string]: UploadStatuses;
  }>({});
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [generateDocServiceUploadUrl] =
    useGenerateDocServiceUploadUrlMutation();
  const { onboardingData, isOnboardingDataLoading } = useOnboardingData();
  const [updateMoneyOnboarding] = useSetMoneyOnboardingMutation();
  const [deleteDocumentDocService] = useDeleteDocumentDocServiceMutation();

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const posthog = usePostHog();

  useEffect(() => {
    setShowHeading(false);

    return () => {
      setShowHeading(true);
    };
  }, [setShowHeading]);

  const filteredOnboardingData: WalletOnboard = useMemo(
    () => removeTypename(onboardingData),
    [onboardingData]
  );

  if (!onboardingData || isOnboardingDataLoading) {
    return (
      <Stack height={"200px"} alignItems={"center"} justifyContent={"center"}>
        <LoadingComponent />
      </Stack>
    );
  }

  const updateStatus = (key: string, status: UploadStatuses) => {
    setFileStatus((prevFileStatus) => ({ ...prevFileStatus, [key]: status }));
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ? [...e.target.files] : [];
    e.target.value = ""; // to reset the input value

    if (!files.length && !currentCompany?.id) {
      return;
    }

    setFileList(files);
    setIsUploading(true);

    await Promise.all(
      files.map(async (file, index) => {
        return await uploadFile(file, currentCompany?.id ?? "", index);
      })
    );

    setIsLoading(true);

    setFileList([]);
    setFileStatus({});
    setIsUploading(false);

    await refetchCompany();

    setIsLoading(false);
  };

  const handleUploadClick = () => {
    posthog?.capture("money_onboarding_ein_letter_upload_btn_click");
    inputRef.current?.click();
  };

  const uploadFile = async (file: File, companyId: string, index: number) => {
    const key = file.name + index;

    updateStatus(key, UploadStatuses.INPROGRESS);

    const { data } = await generateDocServiceUploadUrl({
      variables: {
        companyId: companyId,
        companyIdString: companyId,
        objectKey: file.name,
        source: Source.BANKING,
        sourcecategory:
          MoneyDocumentsSourceCategories.BUSINESS_VERIFICATION_DOCUMENT,
      },
    });

    const url = data?.company?.generateDocServiceUploadUrl?.url;

    const tags = data?.company?.generateDocServiceUploadUrl?.tags?.reduce(
      (tags, tag) => {
        if (tag?.key && tag?.value) {
          return { ...tags, [tag.key]: tag.value };
        }

        return tags;
      },
      {} as { [key: string]: string }
    );

    try {
      if (url && tags && Object.keys(tags).length > 0) {
        const response = await fetch(url, {
          method: "PUT",
          body: file,
          headers: {
            ...tags,
            "content-type": file.type,
            "content-length": `${file.size}`,
          },
        });

        if (response.status === 200) {
          updateStatus(key, UploadStatuses.SUCCESSFUL);

          const uploadedDocument: WalletOnboardingDocumentInput = {
            docServiceFileRefId: `${companyId}/${tags["x-amz-meta-filename"]}`,
            onboardingDocType: OnboardingDocumentType.BUSINESS_REGISTRATION_DOC,
            fileName: tags["x-amz-meta-filename"],
            fileType: "PDF",
          };

          await updateOnboardingDocument(uploadedDocument);
        }
      }
    } catch (err) {
      updateStatus(key, UploadStatuses.FAILED);

      toast.error(`Error occured while uploading ${file.name}`);
    }
  };

  const updateOnboardingDocument = async (
    uploadedDocument: WalletOnboardingDocumentInput
  ) => {
    try {
      setIsLoading(true);

      filteredOnboardingData?.doolaCompanyId &&
        (await updateMoneyOnboarding({
          variables: {
            ...filteredOnboardingData,
            companyInfo: {
              ...filteredOnboardingData?.companyInfo,
              documents: [
                ...(filteredOnboardingData?.companyInfo?.documents ?? []),
                uploadedDocument,
              ],
            },
          },
        }));

      posthog?.capture("money_onboarding_ein_letter_file_upload_success", {
        filename: uploadedDocument.fileName,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteDocument = async (documentId: string, fileName: string) => {
    try {
      setIsLoading(true);

      if (documentId && currentCompany?.id) {
        await deleteDocumentDocService({
          variables: {
            companyId: currentCompany?.id,
            documentId: documentId,
          },
        });

        await updateMoneyOnboarding({
          variables: {
            ...filteredOnboardingData,
            companyInfo: {
              ...filteredOnboardingData?.companyInfo,
              documents: [],
            },
          },
        });

        posthog?.capture("money_onboarding_ein_letter_file_delete_success", {
          filename: fileName,
        });
      }
    } catch {
      toast.error(`Error occured while deleting the ${fileName}`);
    } finally {
      setIsLoading(false);
    }
  };

  const uploadedDocumentsLength =
    onboardingData?.companyInfo?.documents?.length;

  const isDisabled = !uploadedDocumentsLength || isUploading;

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} gap={1.5}>
        <DescriptionOutlined />
        <Typography fontSize={16} fontWeight={500}>
          {t("money.onboarding.step.company.einLetter.title")}
        </Typography>
      </Stack>
      <Typography fontSize={16} color={colors.contentTertiary} mt={0.5}>
        {t("money.onboarding.step.company.einLetter.description")}
      </Typography>
      <Stack gap={5} mt={4} mb={isTablet ? 2 : 0}>
        <Stack gap={4}>
          <input
            type="file"
            accept=".pdf"
            ref={inputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <Box>
            <Button
              disabled={isLoading || !!uploadedDocumentsLength}
              dark={false}
              endIcon={<FileUploadOutlined />}
              buttonText={t("general.upload")}
              onClick={handleUploadClick}
              styles={{
                bgcolor: colors.grey,
              }}
            />
          </Box>
          {isLoading ? (
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              minHeight={"200px"}
            >
              <LoadingComponent />
            </Stack>
          ) : (
            <>
              {fileList.map((file: File, index) => (
                <UploadedDocument
                  key={index}
                  filename={file.name}
                  status={fileStatus?.[file.name + index]}
                  handleDeleteDocument={(docId: string, fileName: string) =>
                    handleDeleteDocument(docId, fileName)
                  }
                />
              ))}
              {onboardingData?.companyInfo?.documents?.map((file, index) => (
                <UploadedDocument
                  key={index}
                  filename={file?.fileName ?? ""}
                  documentId={file?.docServiceFileRefId ?? ""}
                  handleDeleteDocument={(docId: string, fileName: string) =>
                    handleDeleteDocument(docId, fileName)
                  }
                />
              ))}
            </>
          )}

          <Typography color={colors.contentTertiary}>
            {t("general.uploadTip", { value: "PDF" })}
          </Typography>
        </Stack>
        <ButtonBox
          isLoading={isLoading}
          disabled={isDisabled}
          onClickBack={() => {
            posthog?.capture("money_onboarding_ein_letter_back_btn_click");
            setCurrentSubStep(companySubSteps.registeredAddress);
          }}
          onClickNext={() => {
            posthog?.capture("money_onboarding_ein_letter_next_btn_click");
            setCurrentStep(
              OnboardingStepsData[OnboardingStepsCategories.membersInformation]
            );
          }}
        />
      </Stack>
    </>
  );
};

export default EinLetter;
