import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMoneyRecentBeneficiariesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['String'];
}>;


export type GetMoneyRecentBeneficiariesQuery = { __typename?: 'Query', recentBeneficiaries?: { __typename?: 'BeneficiaryList', error?: string | null, payload?: Array<{ __typename?: 'BeneficiaryResponse', beneficiaryAccountNumber?: string | null, beneficiaryAccountType?: Types.AccountType | null, beneficiaryBankAccountType?: string | null, beneficiaryBankCode?: string | null, beneficiaryBankName?: string | null, beneficiaryCity?: string | null, beneficiaryCountryCode?: string | null, beneficiaryHashId?: string | null, beneficiaryName?: string | null, beneficiaryAddress?: string | null, beneficiaryPostcode?: string | null, beneficiaryState?: string | null, destinationCountry?: string | null, destinationCurrency?: string | null, doolaCompanyId?: string | null, doolaCustomerId?: string | null, payoutMethod?: string | null, routingCodeType1?: string | null, routingCodeValue1?: string | null } | null> | null } | null };


export const GetMoneyRecentBeneficiariesDocument = gql`
    query GetMoneyRecentBeneficiaries($companyId: String!) {
  recentBeneficiaries(doolaCompanyId: $companyId) {
    error
    payload {
      beneficiaryAccountNumber
      beneficiaryAccountType
      beneficiaryBankAccountType
      beneficiaryBankCode
      beneficiaryBankName
      beneficiaryCity
      beneficiaryCountryCode
      beneficiaryHashId
      beneficiaryName
      beneficiaryAddress
      beneficiaryPostcode
      beneficiaryState
      destinationCountry
      destinationCurrency
      doolaCompanyId
      doolaCustomerId
      payoutMethod
      routingCodeType1
      routingCodeValue1
    }
  }
}
    `;

/**
 * __useGetMoneyRecentBeneficiariesQuery__
 *
 * To run a query within a React component, call `useGetMoneyRecentBeneficiariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoneyRecentBeneficiariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoneyRecentBeneficiariesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetMoneyRecentBeneficiariesQuery(baseOptions: Apollo.QueryHookOptions<GetMoneyRecentBeneficiariesQuery, GetMoneyRecentBeneficiariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMoneyRecentBeneficiariesQuery, GetMoneyRecentBeneficiariesQueryVariables>(GetMoneyRecentBeneficiariesDocument, options);
      }
export function useGetMoneyRecentBeneficiariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMoneyRecentBeneficiariesQuery, GetMoneyRecentBeneficiariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMoneyRecentBeneficiariesQuery, GetMoneyRecentBeneficiariesQueryVariables>(GetMoneyRecentBeneficiariesDocument, options);
        }
export type GetMoneyRecentBeneficiariesQueryHookResult = ReturnType<typeof useGetMoneyRecentBeneficiariesQuery>;
export type GetMoneyRecentBeneficiariesLazyQueryHookResult = ReturnType<typeof useGetMoneyRecentBeneficiariesLazyQuery>;
export type GetMoneyRecentBeneficiariesQueryResult = Apollo.QueryResult<GetMoneyRecentBeneficiariesQuery, GetMoneyRecentBeneficiariesQueryVariables>;