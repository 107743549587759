import { Box, useMediaQuery } from "@mui/material";
import { LinkRouter } from "components/LinkRouter";
import { Logo } from "components/Logos";
import { ROUTER_PATHS } from "routes/routes";
import utils from "utils";

interface SidebarHeaderProps {
  isDarkMode: boolean;
}

const SidebarHeader = ({ isDarkMode }: SidebarHeaderProps) => {
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  return (
    <Box my={4} mx={isTablet ? 2 : 3}>
      <LinkRouter to={ROUTER_PATHS.DASHBOARD}>
        <Logo width={100} $darkMode={isDarkMode} />
      </LinkRouter>
    </Box>
  );
};

export default SidebarHeader;
