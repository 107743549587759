import ButtonBox from "components/ButtonBox";
import { IStepProps } from "../../types";
import { TaxFilingStepsCategories, TaxFilingStepsData } from "../../data";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import utils, { colors } from "utils";
import { t } from "i18next";
import CustomLoadingButton from "components/Button";
import { FileUploadOutlined } from "@mui/icons-material";
import { ChangeEvent, useRef, useState } from "react";
import { useGenerateDocServiceUploadUrlMutation } from "graphql/__generated__/operations/GenerateDocServiceUploadUrl.generated";
import { toast } from "toast";
import { LoadingComponent } from "components/Loading";
import { usePostHog } from "posthog-js/react";
import { Source } from "graphql/__generated__/types";
import { TaxDocumentsSourceCategories } from "./types";
import { UploadStatuses } from "components/FileUpload/UploadedDocument/helpers";
import { useDeleteDocumentDocServiceMutation } from "graphql/__generated__/operations/DeleteDocumentDocService.generated";
import UploadedDocument from "components/FileUpload/UploadedDocument";
import useCompanyWithDocs from "graphql/hooks/UseCompanyWithDocs";

const TaxDocuments = ({ setCurrentStep }: IStepProps) => {
  const posthog = usePostHog();
  //TODO : revert after doc service 2.0 is ready
  const { currentCompany, refetchCompany, isCompanyLoading } =
    useCompanyWithDocs();

  const [fileList, setFileList] = useState<File[]>([]);
  const [fileStatus, setFileStatus] = useState<{
    [key: string]: UploadStatuses;
  }>({});
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [generateDocServiceUploadUrl] =
    useGenerateDocServiceUploadUrlMutation();
  const [deleteDocumentDocService] = useDeleteDocumentDocServiceMutation();

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const updateStatus = (key: string, status: UploadStatuses) => {
    setFileStatus((prevFileStatus) => ({ ...prevFileStatus, [key]: status }));
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ? [...e.target.files] : [];
    e.target.value = ""; // to reset the input value

    if (!files.length && !currentCompany?.id) {
      return;
    }

    setFileList(files);
    setIsUploading(true);

    await Promise.all(
      files.map(async (file, index) => {
        return await uploadFile(file, currentCompany?.id ?? "", index);
      })
    );

    setIsLoading(true);

    setFileList([]);
    setFileStatus({});
    setIsUploading(false);

    await refetchCompany();

    setIsLoading(false);
  };

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const uploadFile = async (file: File, companyId: string, index: number) => {
    const key = file.name + index;

    updateStatus(key, UploadStatuses.INPROGRESS);

    const { data } = await generateDocServiceUploadUrl({
      variables: {
        companyId: companyId,
        companyIdString: companyId,
        objectKey: file.name,
        source: Source.TAX,
        sourcecategory: TaxDocumentsSourceCategories.TAX_DOCUMENTS,
      },
    });

    const url = data?.company?.generateDocServiceUploadUrl?.url;

    const tags = data?.company?.generateDocServiceUploadUrl?.tags?.reduce(
      (tags, tag) => {
        if (tag?.key && tag?.value) {
          return { ...tags, [tag.key]: tag.value };
        }

        return tags;
      },
      {} as { [key: string]: string }
    );

    try {
      if (url && tags && Object.keys(tags).length > 0) {
        const response = await fetch(url, {
          method: "PUT",
          body: file,
          headers: {
            ...tags,
            "content-type": file.type,
            "content-length": `${file.size}`,
          },
        });

        if (response.status === 200) {
          updateStatus(key, UploadStatuses.SUCCESSFUL);
        }
      }
    } catch (err) {
      updateStatus(key, UploadStatuses.FAILED);

      toast.error(`Error occured while uploading ${file.name}`);
    }
  };

  const handleDeleteDocument = async (documentId: string) => {
    if (documentId && currentCompany?.id) {
      await deleteDocumentDocService({
        variables: {
          companyId: currentCompany?.id,
          documentId: documentId,
        },
      });
    }
  };

  const isDisabled = isUploading;

  const isComponentLoading = isLoading || isCompanyLoading;

  return (
    <>
      <Stack gap={2} mt={3} mb={isTablet ? 9 : 3}>
        <input
          type="file"
          accept=".pdf"
          ref={inputRef}
          multiple
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        <Box>
          <CustomLoadingButton
            disabled={isDisabled}
            dark={false}
            endIcon={<FileUploadOutlined />}
            buttonText={t("general.upload")}
            onClick={handleUploadClick}
            styles={{
              bgcolor: colors.grey,
            }}
          />
        </Box>
        {isComponentLoading ? (
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            minHeight={"200px"}
          >
            <LoadingComponent />
          </Stack>
        ) : (
          <>
            {fileList.map((file: File, index) => (
              <UploadedDocument
                key={index}
                filename={file.name}
                status={fileStatus?.[file.name + index]}
                handleDeleteDocument={(docId: string, fileName: string) =>
                  handleDeleteDocument(docId)
                }
              />
            ))}
            {currentCompany?.documentServiceDocs
              ?.filter(
                (document) =>
                  document?.metadata?.source === Source.TAX &&
                  document?.metadata?.sourcecategory ===
                    TaxDocumentsSourceCategories.TAX_DOCUMENTS
              )
              ?.map((file, index) => (
                <UploadedDocument
                  key={index}
                  filename={file?.metadata?.filename ?? ""}
                  documentId={file?.documentId ?? ""}
                  handleDeleteDocument={(docId: string, fileName: string) =>
                    handleDeleteDocument(docId)
                  }
                />
              ))}
          </>
        )}

        <Typography color={colors.contentTertiary} mt={3}>
          {t("general.uploadTip", { value: "PDF" })}
        </Typography>
      </Stack>
      <ButtonBox
        disabled={isDisabled}
        isLoading={isComponentLoading}
        onClickBack={() =>
          setCurrentStep(TaxFilingStepsData[TaxFilingStepsCategories.questions])
        }
        onClickNext={() => {
          setCurrentStep(
            TaxFilingStepsData[TaxFilingStepsCategories.bookConsultation]
          );

          posthog?.capture(`tax_modal_documents_next_clicked`);
        }}
      />
    </>
  );
};

export default TaxDocuments;
