declare global {
  interface Window {
    Rewardful: any;
    rewardful: any;
  }
}

type StripeCoupon = {
  id: string;
  amount_off: number | undefined;
  percent_off: number | undefined;
};

export const getReferralData = (): {
  referral: string;
  coupon: StripeCoupon | undefined;
} => {
  if (window.Rewardful) {
    const referralId = window.Rewardful.referral;
    const couponObject = window.Rewardful.coupon as StripeCoupon;
    return {
      referral: referralId || "",
      coupon: couponObject,
    };
  } else {
    // return mockRewardfulFixed;
    return {
      referral: "",
      coupon: undefined,
    };
  }
};

export const convertReferral = (email: string) => {
  if (window.Rewardful && window.Rewardful.referral) {
    window.rewardful("convert", { email: email });
  }
};

// const mockRewardfulFixed = {
//   referral: "some id referral from rewarful",
//   coupon: {
//     id: "9MvaOXzq",
//     amount_off: 5000, //it comes in cents (currency specified usd)
//     percent_off: undefined,
//   },
// };
// const mockRewardfulPercentage = {
//   referral: "some id referral from rewarful",
//   coupon: {
//     id: "9MvaOXzq",
//     amount_off: undefined,
//     percent_off: 20,
//   },
// };
