import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCompanyNameMutationVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  companyName: Types.Scalars['String'];
}>;


export type UpdateCompanyNameMutation = { __typename?: 'Mutation', company?: { __typename?: 'CompanyMutation', updateName?: { __typename?: 'Company', id: string, name?: string | null } | null } | null };


export const UpdateCompanyNameDocument = gql`
    mutation UpdateCompanyName($companyId: ID!, $companyName: String!) {
  company(id: $companyId) {
    updateName(new: $companyName) {
      id
      name
    }
  }
}
    `;
export type UpdateCompanyNameMutationFn = Apollo.MutationFunction<UpdateCompanyNameMutation, UpdateCompanyNameMutationVariables>;

/**
 * __useUpdateCompanyNameMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyNameMutation, { data, loading, error }] = useUpdateCompanyNameMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      companyName: // value for 'companyName'
 *   },
 * });
 */
export function useUpdateCompanyNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyNameMutation, UpdateCompanyNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyNameMutation, UpdateCompanyNameMutationVariables>(UpdateCompanyNameDocument, options);
      }
export type UpdateCompanyNameMutationHookResult = ReturnType<typeof useUpdateCompanyNameMutation>;
export type UpdateCompanyNameMutationResult = Apollo.MutationResult<UpdateCompanyNameMutation>;
export type UpdateCompanyNameMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyNameMutation, UpdateCompanyNameMutationVariables>;