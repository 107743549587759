import { Popover, Stack } from "@mui/material";
import { P } from "components/Fonts";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useState } from "react";

type Props = {
  linkToCopy: string;
};

export const CopyIcon: React.FC<Props> = ({ linkToCopy }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    navigator.clipboard.writeText(linkToCopy);
    setAnchorEl(event.currentTarget);

    setTimeout(() => {
      handleClose();
    }, 2000);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Stack
      justifyContent={"center"}
      style={{ cursor: "pointer" }}
      onClick={(event) => handleClick(event as any)}
    >
      <ContentCopyIcon fontSize="small" />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <P size="xsmall" display="inline" $margin="10px">
          The link has been copied to your clipboard.
        </P>
      </Popover>
    </Stack>
  );
};
