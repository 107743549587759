import {
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { addMemberSteps } from "./AddMemberSteps/types";
import { useState } from "react";
import utils from "utils";
import MemberType from "./AddMemberSteps/MemberType";
import IndividualMemberDetails from "./AddMemberSteps/IndividualMemberDetails";
import CompanyMemberDetails from "./AddMemberSteps/CompanyMemberDetails";
import IndividualMemberDocuments from "./AddMemberSteps/IndividualMemberDocuments";
import IndividualMemberAddress from "./AddMemberSteps/IndividualMemberAddress";
import { AddMemberProps, IAddMemberStepsProps } from "./types";
import { CloseRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useOnboardingData from "views/DashboardApp/Money/Onboarding/hooks/useOnboardingData";
import { LoadingComponent } from "components/Loading";
import {
  OnboardingStakeholder,
  OnboardingStakeholderType,
} from "graphql/__generated__/types";
import { stakeholdersInitObj } from "./helpers";
import { MemberDialogFormModeEnum } from "../types";

const AddMember = ({
  memberDialogDetails,
  setMemberDialogDetails,
}: AddMemberProps) => {
  const determineAddMemberCurrentStep = (): addMemberSteps => {
    if (memberDialogDetails.mode === MemberDialogFormModeEnum.add) {
      return addMemberSteps.memberType;
    }

    return memberDialogDetails.onboardingStakeholderType ===
      OnboardingStakeholderType.PERSON
      ? addMemberSteps.individualMemberDetails
      : addMemberSteps.companyMemberDetails;
  };

  const [addMemberCurrentStep, setAddMemberCurrentStep] =
    useState<addMemberSteps>(determineAddMemberCurrentStep());
  const [stakeholderObj, setStakeholderObj] =
    useState<OnboardingStakeholder>(stakeholdersInitObj);
  const [isApplicant, setIsApplicant] = useState<boolean>(false);

  const { onboardingData, isOnboardingDataLoading } = useOnboardingData();

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const { t } = useTranslation();

  if (isOnboardingDataLoading) {
    return (
      <Stack height={"200px"} alignItems={"center"} justifyContent={"center"}>
        <LoadingComponent />
      </Stack>
    );
  }

  const handleOnClose = () => {
    setMemberDialogDetails({
      show: false,
      stakeholderId: "",
      mode: MemberDialogFormModeEnum.add,
    });
  };

  const renderModalContent = (addMemberCurrentStep: addMemberSteps) => {
    const commonProps: IAddMemberStepsProps = {
      setAddMemberCurrentStep: setAddMemberCurrentStep,
      setMemberDialogDetails: setMemberDialogDetails,
      stakeholderObj,
      setStakeholderObj,
      onboardingData,
      memberDialogDetails,
      isApplicant,
      setIsApplicant,
    };

    switch (addMemberCurrentStep) {
      case addMemberSteps.memberType:
        return <MemberType {...commonProps} />;

      case addMemberSteps.individualMemberDetails:
        return <IndividualMemberDetails {...commonProps} />;

      case addMemberSteps.individualMemberAddress:
        return <IndividualMemberAddress {...commonProps} />;

      case addMemberSteps.individualMemberDocuments:
        return <IndividualMemberDocuments {...commonProps} />;

      case addMemberSteps.companyMemberDetails:
        return <CompanyMemberDetails {...commonProps} />;
    }
  };

  return (
    <Dialog
      fullScreen={isTablet}
      PaperProps={{
        style: {
          ...(!isTablet && { width: "700px" }),
          borderRadius: !isTablet ? "32px" : "0",
          padding: isTablet ? "1rem" : "2rem",
          ...(isTablet && { paddingBottom: "90px" }),
        },
      }}
      open={memberDialogDetails.show}
      onClose={() => handleOnClose()}
    >
      <Stack>
        <DialogTitle sx={{ padding: "0px" }}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            mb={isTablet ? 2 : 4}
          >
            <Typography fontSize={isTablet ? 24 : 28} fontWeight={700}>
              {t("general.member")}
            </Typography>
            <IconButton onClick={() => handleOnClose()}>
              <CloseRounded color="primary" />
            </IconButton>
          </Stack>
        </DialogTitle>
        {renderModalContent(addMemberCurrentStep)}
      </Stack>
    </Dialog>
  );
};

export default AddMember;
