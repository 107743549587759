import { useEffect, useState } from "react";
import { MenuItem, Stack, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { t } from "i18next";
import { SignupSelect } from "views/SignupFlow/styles";
import { ENTITY_INDUSTRIES } from "resources/entityIndustries";
import utils, { colors } from "utils";
import useCompany from "graphql/hooks/UseCompany";
import { LoadingComponent } from "components/Loading";
import { useUpdateCompanyActivityMutation } from "graphql/__generated__/operations/UpdateCompanyActivity.generated";
import { useUpdateCompanyActivityOtherMutation } from "graphql/__generated__/operations/UpdateCompanyActivityOther.generated";
import ButtonBox from "components/ButtonBox";
import { Windows1252Regex } from "utils/regexValidations";
import { usePostHog } from "posthog-js/react";

const OnboardingIndustry = () => {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { currentCompany } = useCompany();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [entityIndustry, setEntityIndustry] = useState<string>("");
  const [showActivityOther, setShowActivityOther] = useState<boolean>(false);
  const [entityIndustryOther, setEntityIndustryOther] = useState<string>("");
  const [hasError, setHasError] = useState<boolean>(false);

  const [updateCompanyActivity] = useUpdateCompanyActivityMutation();
  const [updateCompanyActivityOther] = useUpdateCompanyActivityOtherMutation();

  useEffect(() => {
    setEntityIndustry(currentCompany?.activity || "");
    setEntityIndustryOther(currentCompany?.activityOther || "");
  }, [currentCompany?.activity, currentCompany?.activityOther]);

  useEffect(() => {
    setShowActivityOther(entityIndustry === "Other");
  }, [entityIndustry]);

  const isDisabled =
    !entityIndustry || (showActivityOther && !entityIndustryOther && hasError);

  const onClickNextHandler = async () => {
    setIsLoading(true);

    if (currentCompany?.id) {
      try {
        await updateCompanyActivity({
          variables: {
            companyId: currentCompany?.id,
            activity: entityIndustry,
          },
        });

        if (entityIndustryOther) {
          await updateCompanyActivityOther({
            variables: {
              companyId: currentCompany?.id,
              activity: entityIndustryOther,
            },
          });
        }
      } catch {
        setIsLoading(true);
      }
    }

    posthog?.capture("company-industry-set", {
      entity_industry: entityIndustry,
      ...(entityIndustryOther && {
        entity_industry_other: entityIndustryOther,
      }),
    });

    navigate(ROUTER_PATHS.ONBOARDING_DESCRIPTION);
  };

  if (!currentCompany) {
    return <LoadingComponent />;
  }

  return (
    <Stack direction="column" gap={3}>
      <Typography variant="h1">
        Choose the industry that best fits your company
      </Typography>
      <Stack>
        <Typography
          variant="h6"
          pb={1}
          color={utils.secondaryColors.secondaryMain}
        >
          Select Industry Type
        </Typography>
        <SignupSelect
          displayEmpty
          value={entityIndustry}
          onChange={(e) => setEntityIndustry(e.target.value as string)}
          data-testid="OnboardingIndustrySelect"
        >
          <MenuItem disabled value="">
            {t("general.selectIndustry")}
          </MenuItem>
          {ENTITY_INDUSTRIES.map((value, index) => {
            return (
              <MenuItem key={index} value={value}>
                {value}
              </MenuItem>
            );
          })}
        </SignupSelect>
        {showActivityOther && (
          <>
            <Typography
              variant="h6"
              pt={3}
              pb={1}
              color={utils.secondaryColors.secondaryMain}
            >
              Industry Type (e.g. food, consulting)
            </Typography>
            <Stack>
              <TextField
                placeholder={
                  t("general.companyIndustryOtherPlaceholder") as string
                }
                fullWidth
                value={entityIndustryOther}
                onChange={(e) => {
                  const inputValue = e.target.value;

                  setEntityIndustryOther(inputValue);
                  if (inputValue) {
                    const isValid = Windows1252Regex.test(inputValue);
                    setHasError(!isValid);
                  } else {
                    setHasError(false);
                  }
                }}
                error={hasError}
                sx={{
                  ".MuiInputBase-root": {
                    height: "40px",
                  },
                }}
              />
            </Stack>
          </>
        )}
      </Stack>
      <Stack direction="row" alignItems={"center"} gap={2}>
        <InfoIcon />
        <Typography>
          This is shared with the IRS as your principal activity to retrieve
          your Employer Identification Number or EIN — a necessary number to
          hire employees, open a bank account, and conduct other business
          activities in the United States.
        </Typography>
      </Stack>
      {hasError && showActivityOther && (
        <Typography color={colors.red} fontSize={12}>
          {t("error.invalidCharacters")}
        </Typography>
      )}
      <ButtonBox
        isLoading={isLoading}
        disabled={isDisabled}
        onClickBack={() => navigate(ROUTER_PATHS.ONBOARDING_ADDRESS)}
        onClickNext={onClickNextHandler}
      />
    </Stack>
  );
};

export default OnboardingIndustry;
