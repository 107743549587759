import { EmailBuilder } from "./services";
import { CustomButton } from "components/Buttons";
import { CustomButtonVariants } from "components/Buttons/types";

type EmailSenderProps = {
  linkToSend: string;
  referralName: string;
  emailTo: string;
};

const SUBJECT = "Invitation to join doola";

const EmailSender = ({
  linkToSend,
  referralName,
  emailTo,
}: EmailSenderProps) => {
  const emailServiceBuilder = new EmailBuilder(referralName, linkToSend);
  const mailTo =
    "mailto:" +
    emailTo +
    "?subject=" +
    SUBJECT +
    "&body=" +
    encodeURIComponent(emailServiceBuilder.getEmailAsInnerText());

  return (
    <a href={mailTo} style={{ textDecoration: "none", float: "right" }}>
      <CustomButton onClick={() => {}} $variant={CustomButtonVariants.primary}>
        Send
      </CustomButton>
    </a>
  );
};

export default EmailSender;
