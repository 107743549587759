import { colors } from "utils";
import { colorWithOpacity } from "utils/formatters";

export type StatussesKey = "invited" | "signed-up" | "bonus-sent";

type TStatussesData = {
  [key in StatussesKey]: {
    label: string;
    color: string;
  };
};

export const StatussesData: TStatussesData = {
  invited: {
    label: "Invited",
    color: colorWithOpacity(colors.charcoal, 50),
  },
  "signed-up": {
    label: "Signed Up",
    color: colorWithOpacity(colors.yellow, 50),
  },
  "bonus-sent": {
    label: "Bonus sent",
    color: colorWithOpacity(colors.green, 50),
  },
};
