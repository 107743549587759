import { LoadingComponent } from "components/Loading";
import { WrapperCompanyItem } from "../CompanySelectorContent/styles";
import { Box, Typography } from "@mui/material";
import { colors } from "utils";
import { useGetCompanyWithoutDocServiceQuery } from "graphql/__generated__/operations/CompanyWithoutDocService.generated";

type Props = {
  companyId: string;
  onClick: () => void;
  currentCompanyId: string;
};

const CompanyItem: React.FC<Props> = ({
  companyId,
  onClick,
  currentCompanyId,
}) => {
  //TODO : switch back to useGetCompanyQuery after getCompany is fixed
  const { data } = useGetCompanyWithoutDocServiceQuery({
    variables: { companyId },
    errorPolicy: "all",
  });

  const isSelected = companyId === currentCompanyId;

  return (
    <WrapperCompanyItem onClick={onClick}>
      <Box
        width={"100%"}
        p={2}
        bgcolor={isSelected ? colors.lightGrey : colors.white}
        borderRadius={3}
        sx={{
          "&:hover": {
            bgcolor: colors.grey,
          },
        }}
      >
        {data?.company?.name ? (
          <Typography color={colors.black}>{data?.company?.name}</Typography>
        ) : (
          <LoadingComponent />
        )}
      </Box>
    </WrapperCompanyItem>
  );
};

export default CompanyItem;
