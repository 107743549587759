import { useEffect } from "react";
import { P } from "components/Fonts";
import { StyledLink } from "./styles";
import { CopyIcon } from "./CopyIcon";
import useCustomer from "graphql/hooks/useCustomer";
import { useGenerateReferralLinkMutation } from "graphql/__generated__/operations/GenerateReferralLink.generated";
import { useGetTokenFieldsQuery } from "graphql/__generated__/operations/Whoami.generated";

export const LinkReferral = () => {
  const { data: tokenData } = useGetTokenFieldsQuery();
  const { currentUser } = useCustomer();
  const [generateReferralLink] = useGenerateReferralLinkMutation();

  const referralLink = currentUser?.referralMetadata?.link;
  const customerId = tokenData?.myToken?.authorizedCustomerId;

  useEffect(() => {
    if (!referralLink && customerId && currentUser) {
      generateReferralLink({
        variables: { customerId },
      });
    }
  }, [currentUser, customerId, generateReferralLink, referralLink]);

  return (
    <StyledLink>
      <CopyIcon linkToCopy={referralLink || ""} />
      <P
        size="xsmall"
        display="inline"
        $margin="auto 0"
        style={{ wordBreak: "break-all" }}
      >
        {referralLink || ""}
      </P>
    </StyledLink>
  );
};
