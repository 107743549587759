import {
  Box,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { toast } from "toast";
import { DescriptionOutlined, FileUploadOutlined } from "@mui/icons-material";
import { addMemberSteps } from "../types";
import utils, { colors } from "utils";
import { useTranslation } from "react-i18next";
import ButtonBox from "components/ButtonBox";
import { IAddMemberStepsProps } from "../../types";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import Button from "components/Button";
import { LoadingComponent } from "components/Loading";
import useCompany from "graphql/hooks/UseCompany";
import { MemberDialogFormModeEnum } from "../../../types";
import {
  LookupTypeKey,
  OnboardingDocumentType,
  Source,
  WalletOnboard,
  WalletOnboardingDocumentInput,
} from "graphql/__generated__/types";
import { useGenerateDocServiceUploadUrlMutation } from "graphql/__generated__/operations/GenerateDocServiceUploadUrl.generated";
import useOnboardingData from "views/DashboardApp/Money/Onboarding/hooks/useOnboardingData";
import { useSetMoneyOnboardingMutation } from "graphql/__generated__/operations/MoneyOnboardingValidation.generated";
import { removeTypename } from "utils/helpers/removeTypename";
import { stakeholdersInitObj } from "../../helpers";
import {
  IdentityDocumentInfoValuesType,
  MoneyDocumentsSourceCategories,
} from "views/DashboardApp/Money/Onboarding/helpers/types";
import { Controller, useForm } from "react-hook-form";
import { Windows1252Regex } from "utils/regexValidations";
import CustomSelect from "components/Form/CustomSelect";
import CustomInput from "components/Form/CustomInput";
import useGetMoneyLookups from "views/DashboardApp/Money/hooks/useGetMoneyLookups";
import CustomDatePicker from "components/Form/CustomDatePicker";
import dayjs from "dayjs";
import { useDeleteDocumentDocServiceMutation } from "graphql/__generated__/operations/DeleteDocumentDocService.generated";
import { UploadStatuses } from "components/FileUpload/UploadedDocument/helpers";
import {
  INTITAL_IDENTITY_DOCUMENT_INFO_VALUES,
  OnboardingDocumentTypes,
} from "views/DashboardApp/Money/Onboarding/helpers";
import UploadedDocument from "components/FileUpload/UploadedDocument";
import AlertNotification from "components/AlertNotification";

const IndividualMemberDocuments = ({
  setAddMemberCurrentStep,
  setMemberDialogDetails,
  stakeholderObj,
  setStakeholderObj,
  isApplicant,
}: IAddMemberStepsProps) => {
  const [fileList, setFileList] = useState<File[]>([]);
  const [fileStatus, setFileStatus] = useState<{
    [key: string]: UploadStatuses;
  }>({});
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const { onboardingData, isOnboardingDataLoading } = useOnboardingData();
  const { currentCompany } = useCompany();
  const [generateDocServiceUploadUrl] =
    useGenerateDocServiceUploadUrlMutation();
  const [updateMoneyOnboarding] = useSetMoneyOnboardingMutation();
  const [deleteDocumentDocService] = useDeleteDocumentDocServiceMutation();
  const { lookupsData, isLookupsDataLoading } = useGetMoneyLookups({
    neededLookups: [LookupTypeKey.COUNTRYOFOPERATION],
  });

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const { t } = useTranslation();

  const { control, formState, getValues, setValue, trigger } =
    useForm<IdentityDocumentInfoValuesType>({
      defaultValues: { ...INTITAL_IDENTITY_DOCUMENT_INFO_VALUES },
      mode: "onChange",
    });

  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  const filteredOnboardingData: WalletOnboard = useMemo(
    () => removeTypename(onboardingData),
    [onboardingData]
  );

  useEffect(() => {
    const stakeholderDocument =
      stakeholderObj?.documents && stakeholderObj.documents[0];

    setValue(
      "documentIssuanceCountry",
      stakeholderDocument?.documentIssuanceCountry || ""
    );
    setValue("documentNumber", stakeholderDocument?.documentNumber || "");
    setValue("expirationDate", stakeholderDocument?.expirationDate || "");
    setValue(
      "onboardingDocType",
      stakeholderDocument?.onboardingDocType ||
        INTITAL_IDENTITY_DOCUMENT_INFO_VALUES.onboardingDocType
    );

    trigger();
  }, [setValue, stakeholderObj.documents, trigger]);

  if (
    !onboardingData ||
    isOnboardingDataLoading ||
    !lookupsData ||
    isLookupsDataLoading
  ) {
    return (
      <Stack height={"200px"} alignItems={"center"} justifyContent={"center"}>
        <LoadingComponent />
      </Stack>
    );
  }

  const isEditMode = !!stakeholderObj?.documents?.length;

  const updateStatus = (key: string, status: UploadStatuses) => {
    setFileStatus((prevFileStatus) => ({ ...prevFileStatus, [key]: status }));
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ? [...e.target.files] : [];
    e.target.value = ""; // to reset the input value

    if (!files.length && !currentCompany?.id) {
      return;
    }

    setFileList(files);
    setIsUploading(true);

    await Promise.all(
      files.map(async (file, index) => {
        return await uploadFile(file, currentCompany?.id ?? "", index);
      })
    );

    setIsLoading(true);
    setFileList([]);
    setFileStatus({});
    setIsUploading(false);
    setIsLoading(false);
  };

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const uploadFile = async (file: File, companyId: string, index: number) => {
    const key = file.name + index;

    updateStatus(key, UploadStatuses.INPROGRESS);

    const { data } = await generateDocServiceUploadUrl({
      variables: {
        companyId: companyId,
        companyIdString: companyId,
        objectKey: file.name,
        source: Source.BANKING,
        sourcecategory:
          MoneyDocumentsSourceCategories.STAKEHOLDER_IDENTITY_DOCUMENT,
      },
    });

    const url = data?.company?.generateDocServiceUploadUrl?.url;

    const tags = data?.company?.generateDocServiceUploadUrl?.tags?.reduce(
      (tags, tag) => {
        if (tag?.key && tag?.value) {
          return { ...tags, [tag.key]: tag.value };
        }

        return tags;
      },
      {} as { [key: string]: string }
    );

    try {
      if (url && tags && Object.keys(tags).length > 0) {
        const response = await fetch(url, {
          method: "PUT",
          body: file,
          headers: {
            ...tags,
            "content-type": file.type,
            "content-length": `${file.size}`,
          },
        });

        if (response.status === 200) {
          updateStatus(key, UploadStatuses.SUCCESSFUL);

          const {
            documentIssuanceCountry,
            documentNumber,
            expirationDate,
            onboardingDocType,
          } = getValues();

          const uploadedDocument: WalletOnboardingDocumentInput = {
            docServiceFileRefId: `${companyId}/${tags["x-amz-meta-filename"]}`,
            fileType: "PDF",
            onboardingDocType,
            expirationDate,
            documentIssuanceCountry,
            documentNumber,
            fileName: tags["x-amz-meta-filename"],
          };

          await updateOnboardingDocument(uploadedDocument);
        }
      }
    } catch (err) {
      updateStatus(key, UploadStatuses.FAILED);

      toast.error(`Error occured while uploading ${file.name}`);
    }
  };

  const updateOnboardingDocument = async (
    uploadedDocument: WalletOnboardingDocumentInput
  ) => {
    try {
      setIsLoading(true);

      const stakeholdersWithUpdatedData =
        filteredOnboardingData?.stakeholders?.map((stakeholder) =>
          stakeholder?.uuid === stakeholderObj.uuid
            ? {
                ...stakeholderObj,
                documents: [uploadedDocument],
              }
            : stakeholder
        );

      const applicant = filteredOnboardingData.applicant;

      await updateMoneyOnboarding({
        variables: {
          ...filteredOnboardingData,
          stakeholders: stakeholdersWithUpdatedData,
          ...(isApplicant && {
            applicant: {
              ...applicant,
              documents: [uploadedDocument],
            },
          }),
        },
      });

      setStakeholderObj({
        ...stakeholderObj,
        documents: [uploadedDocument],
      });
    } catch {
      toast.error(`Error occured while uploading the document`);
    } finally {
      setIsLoading(false);
    }
  };

  const isUploadDisabled = isUploading || !formState.isValid;

  const handleDeleteDocument = async (documentId: string, fileName: string) => {
    try {
      setIsLoading(true);

      if (documentId && currentCompany?.id) {
        await deleteDocumentDocService({
          variables: {
            companyId: currentCompany?.id,
            documentId: documentId,
          },
        });

        const stakeholdersWithUpdatedData =
          filteredOnboardingData?.stakeholders?.map((stakeholder) =>
            stakeholder?.uuid === stakeholderObj.uuid
              ? {
                  ...stakeholder,
                  documents: [],
                }
              : stakeholder
          );

        const applicant = filteredOnboardingData.applicant;

        await updateMoneyOnboarding({
          variables: {
            ...filteredOnboardingData,
            stakeholders: stakeholdersWithUpdatedData,
            ...(isApplicant && {
              applicant: {
                ...applicant,
                documents: [],
              },
            }),
          },
        });

        setStakeholderObj({ ...stakeholderObj, documents: [] });
      }
    } catch {
      toast.error(`Error occured while deleting the ${fileName}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveStakeholder = () => {
    setStakeholderObj(stakeholdersInitObj);
    setMemberDialogDetails({
      show: false,
      stakeholderId: "",
      mode: MemberDialogFormModeEnum.add,
    });
  };

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} gap={1.5}>
        <DescriptionOutlined />
        <Typography fontSize={16} fontWeight={500}>
          {t("money.onboarding.step.membersInformation.identityDocument")}
        </Typography>
      </Stack>
      <Typography fontSize={16} color={colors.contentTertiary} mt={0.5}>
        {t("general.uploadDescriptionIdentity")}
      </Typography>
      <Stack mt={3}>
        <form noValidate onSubmit={(e) => e.preventDefault()}>
          <Stack gap={3}>
            <Stack gap={2}>
              <Stack direction={isTablet ? "column" : "row"} gap={3}>
                <Controller
                  name="onboardingDocType"
                  {...COMMON_PROPS}
                  rules={{
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                    required: true,
                  }}
                  render={({ field }) => (
                    <CustomSelect
                      disabled={isEditMode}
                      {...field}
                      styles={{ width: "100%" }}
                      placeholder={
                        t(
                          "money.onboarding.identityDocument.upload.onboardingDocType.placeholder"
                        ) as string
                      }
                      label={
                        t(
                          "money.onboarding.identityDocument.upload.onboardingDocType"
                        ) as string
                      }
                      defaultValue={field.value}
                      onChange={(e: SelectChangeEvent<unknown>) => {
                        field.onChange(e.target.value);
                      }}
                    >
                      {OnboardingDocumentTypes.filter(
                        (documentType) =>
                          !isApplicant ||
                          (isApplicant &&
                            documentType.key ===
                              OnboardingDocumentType.NATIONAL_ID)
                      ).map((item, index) => (
                        <MenuItem key={index} value={item?.key ?? ""}>
                          {item.description}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  )}
                />
                <Controller
                  name="documentNumber"
                  {...COMMON_PROPS}
                  rules={{
                    required: true,
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                  }}
                  render={({ field }) => (
                    <CustomInput
                      disabled={isEditMode}
                      {...field}
                      styles={{ width: "100%" }}
                      placeholder={
                        t(
                          "money.onboarding.identityDocument.upload.documentNumber.placeholder"
                        ) as string
                      }
                      label={
                        t(
                          "money.onboarding.identityDocument.upload.documentNumber"
                        ) as string
                      }
                    />
                  )}
                />
              </Stack>
              <Stack direction={isTablet ? "column" : "row"} gap={3}>
                {lookupsData?.COUNTRYOFOPERATION && (
                  <Controller
                    name="documentIssuanceCountry"
                    {...COMMON_PROPS}
                    rules={{
                      pattern: {
                        value: Windows1252Regex,
                        message: t("error.invalidCharacters"),
                      },
                      required: true,
                    }}
                    render={({ field }) => (
                      <CustomSelect
                        disabled={isEditMode}
                        {...field}
                        styles={{ width: "100%" }}
                        placeholder={
                          t(
                            "money.onboarding.identityDocument.upload.documentIssuanceCountry.placeholder"
                          ) as string
                        }
                        label={
                          t(
                            "money.onboarding.identityDocument.upload.documentIssuanceCountry"
                          ) as string
                        }
                        defaultValue={field.value}
                        onChange={(e: SelectChangeEvent<unknown>) => {
                          field.onChange(e.target.value);
                        }}
                      >
                        {lookupsData.COUNTRYOFOPERATION.map((item, index) => (
                          <MenuItem key={index} value={item?.key ?? ""}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    )}
                  />
                )}
                <Controller
                  name="expirationDate"
                  rules={{
                    required: true,
                  }}
                  {...COMMON_PROPS}
                  render={({ field: { value, onChange } }) => (
                    <CustomDatePicker
                      disabled={isEditMode}
                      value={dayjs(new Date(value ?? 0))}
                      styles={{ width: "100%" }}
                      onChange={(newValue) =>
                        onChange(dayjs(newValue).format("YYYY-MM-DD"))
                      }
                      placeholder={
                        t(
                          "money.onboarding.identityDocument.upload.expirationDate.placeholder"
                        ) as string
                      }
                      label={
                        t(
                          "money.onboarding.identityDocument.upload.expirationDate"
                        ) as string
                      }
                      disablePast
                    />
                  )}
                />
              </Stack>
            </Stack>
            {!isEditMode && (
              <Stack>
                <input
                  type="file"
                  accept=".pdf"
                  ref={inputRef}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <Box>
                  <Button
                    disabled={isUploadDisabled}
                    dark={false}
                    endIcon={<FileUploadOutlined />}
                    buttonText={t("general.upload")}
                    onClick={handleUploadClick}
                    styles={{
                      bgcolor: colors.grey,
                    }}
                  />
                </Box>
                <Typography color={colors.contentTertiary} mt={2}>
                  {t("general.uploadTip", { value: "PDF" })}
                </Typography>
              </Stack>
            )}
          </Stack>
        </form>
      </Stack>
      <Stack gap={5} mt={4} mb={isTablet ? 2 : 0}>
        <Stack>
          {isLoading ? (
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              minHeight={"200px"}
            >
              <LoadingComponent />
            </Stack>
          ) : (
            <>
              {fileList.map((file: File, index) => (
                <UploadedDocument
                  key={index}
                  filename={file.name}
                  status={fileStatus?.[file.name + index]}
                  handleDeleteDocument={(docId: string, fileName: string) =>
                    handleDeleteDocument(docId, fileName)
                  }
                />
              ))}
              {onboardingData?.stakeholders
                ?.find(
                  (stakeholder) => stakeholder?.uuid === stakeholderObj.uuid
                )
                ?.documents?.map((file, index) => (
                  <UploadedDocument
                    key={index}
                    filename={file?.fileName ?? ""}
                    documentId={file?.docServiceFileRefId ?? ""}
                    handleDeleteDocument={(docId: string, fileName: string) =>
                      handleDeleteDocument(docId, fileName)
                    }
                  />
                ))}
              {isEditMode && (
                <AlertNotification
                  style={{ mt: 3 }}
                  body={
                    t(
                      "money.onboarding.identityDocument.upload.info.text"
                    ) as string
                  }
                />
              )}
            </>
          )}
        </Stack>
        <ButtonBox
          isLoading={isLoading}
          rightButtonText="Close"
          onClickBack={() =>
            setAddMemberCurrentStep(addMemberSteps.individualMemberDetails)
          }
          onClickNext={() => handleSaveStakeholder()}
        />
      </Stack>
    </>
  );
};

export default IndividualMemberDocuments;
