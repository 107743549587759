import { Skeleton, Stack, Typography } from "@mui/material";
import { colors } from "utils";
import { LiveTv, NavigateNext, Schedule } from "@mui/icons-material";
import { t } from "i18next";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { moneyFormatter } from "utils/formatters";
import useCompany from "graphql/hooks/UseCompany";
import { CompanyServices, ProductDuration } from "graphql/__generated__/types";
import Button from "components/Button";
import ServicePurchaseModal from "components/ServicePurchaseModal";
import { useState } from "react";
import { SERVICES_DATA } from "views/DashboardApp/Services/data";
import { usePostHog } from "posthog-js/react";
import { useRequestStripeCheckoutSessionDdsMutation } from "graphql/__generated__/operations/RequestStripeCheckoutSessionFromDDS.generated";
import { useUpdateCustomerStripeIdMutation } from "graphql/__generated__/operations/UpdateCustomerStripeId.generated";
import useCustomer from "graphql/hooks/useCustomer";
import { renderServiceDuration } from "views/DashboardApp/Services/helpers";
import { TAX_FILING_CONSULTATION, redirectTaxesBaseUri } from "../constants";
import CpaModal from "./CpaModal";
import useGetTaxRelatedProducts from "../hooks/useGetTaxRelatedProducts";

const CpaConsultations = () => {
  const posthog = usePostHog();
  const { currentCompany } = useCompany();
  const { currentUser } = useCustomer();
  const { TAX_PLANS, isProductQueryLoading } = useGetTaxRelatedProducts();
  const [checkoutWithStripe] = useRequestStripeCheckoutSessionDdsMutation();
  const [updateCustomerStripeId] = useUpdateCustomerStripeIdMutation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isCpaModalOpen, setIsCpaModalOpen] = useState<boolean>(false);

  if (!currentCompany || isProductQueryLoading)
    return (
      <Skeleton
        width={"100%"}
        height={324}
        sx={{ borderRadius: 8, bgcolor: colors.lightGrey }}
        variant="rectangular"
        animation="wave"
      />
    );

  const availableCpaCredits = currentCompany.cpaCredits ?? 0;
  const hasTaxConsultationScheduled =
    !!currentCompany.upcomingConsultations?.some(
      (item) => item?.eventType === TAX_FILING_CONSULTATION
    );
  const numberOfScheduledConsultations =
    (currentCompany.upcomingConsultations?.length ?? 0) -
    +hasTaxConsultationScheduled;

  // Check if the user can schedule more consultations based on the balance
  const isBalanceExhausted =
    numberOfScheduledConsultations >= availableCpaCredits;

  const onCheckoutHandler = async () => {
    setIsLoading(true);

    try {
      const companyId = currentCompany.id;
      const customerId = currentUser?.id || "";
      const { data } = await checkoutWithStripe({
        variables: {
          companyId,
          customerId,
          products: [TAX_PLANS.CPA_CONSULTATIONS.lookupKey],
          redirectBaseUri: redirectTaxesBaseUri,
          email: currentUser?.email || "",
        },
      });

      const stripeCheckoutUrl =
        data?.company?.requestStripeCheckoutSessionDDS?.url;
      const stripeCustomerId =
        data?.company?.requestStripeCheckoutSessionDDS?.stripeCustomerId;

      if (stripeCustomerId && customerId) {
        await updateCustomerStripeId({
          variables: { customerId, stripeCustomerId },
        });
      }

      if (stripeCheckoutUrl) {
        posthog?.capture(`cpa-consultation-checkout-started`);

        await new Promise((resolve) => window.setTimeout(resolve, 500)); // wait 500ms
        window.location.replace(stripeCheckoutUrl);
      }
    } catch {
      setIsLoading(false);
    }
  };

  return (
    <Stack
      sx={{
        bgcolor: colors.lightGrey,
        borderRadius: 8,
        p: 4,
        height: "100%",
      }}
      gap={4}
    >
      <Stack gap={2} alignItems={"flex-start"}>
        <Stack direction={"row"} gap={2}>
          <Stack
            direction={"row"}
            gap={1}
            sx={{
              bgcolor: colors.grey,
              borderRadius: 3,
              px: 1.5,
              py: 1,
              fontSize: "1rem",
              fontWeight: 500,
            }}
            alignItems={"center"}
          >
            <VideocamOutlinedIcon sx={{ fontSize: "1.2rem" }} />
            {t("dashboard.tax.cpaconsultations.name")}
          </Stack>
          <Stack
            direction={"row"}
            gap={1}
            sx={{
              bgcolor: colors.grey,
              borderRadius: 3,
              px: 1.5,
              py: 1,
              fontSize: "1rem",
              fontWeight: 500,
            }}
            alignItems={"center"}
          >
            <Schedule sx={{ fontSize: "1.2rem" }} />
            {t("dashboard.tax.cpaconsultations.time")}
          </Stack>
        </Stack>
        <Typography variant="h3" fontWeight={700}>
          {t("dashboard.tax.cpaconsultations.title")}
        </Typography>
        <Typography fontSize={16}>
          {t("dashboard.tax.cpaconsultations.text")}
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={2}
      >
        {availableCpaCredits ? (
          <>
            <Stack>
              <Typography variant="body1" color={colors.contentSecondary}>
                {t("dashboard.tax.cpaconsultations.yourBalance")}
              </Typography>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                {<LiveTv />}
                <Typography fontSize={16} fontWeight={500} color={colors.black}>
                  {availableCpaCredits +
                    " " +
                    t("dashboard.tax.cpaconsultations.consultations")}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={"row"} gap={1}>
              <Button
                styles={{ backgroundColor: colors.grey }}
                dark={false}
                buttonText={t("general.purchase")}
                onClick={() => setIsModalOpen(true)}
              />
              <Button
                disabled={isBalanceExhausted}
                endIcon={<NavigateNext />}
                onClick={() => {
                  setIsCpaModalOpen(true);

                  posthog?.capture(`cpa_consultation_book_now_clicked`);
                }}
                buttonText={t("dashboard.tax.cpaconsultations.bookNow")}
              />
              <CpaModal
                isModalOpen={isCpaModalOpen}
                onCloseModalHandler={() => setIsCpaModalOpen(false)}
              />
            </Stack>
          </>
        ) : (
          <>
            <Stack direction={"row"} alignItems={"flex-end"} spacing={1}>
              <Typography variant="h2" fontSize={28} lineHeight={1}>
                {moneyFormatter(TAX_PLANS.CPA_CONSULTATIONS.price)}
              </Typography>
              <Typography variant="body1" color={colors.contentTertiary}>
                {renderServiceDuration(
                  SERVICES_DATA[CompanyServices.CPACONSULTATION].duration ??
                    ProductDuration.ONETIME
                )}
              </Typography>
            </Stack>
            <Button
              endIcon={<NavigateNext />}
              buttonText={t("general.purchase")}
              onClick={() => setIsModalOpen(true)}
            />
          </>
        )}
        <ServicePurchaseModal
          isModalOpen={isModalOpen}
          onCloseModalHandler={() => setIsModalOpen(false)}
          itemToShow={SERVICES_DATA[CompanyServices.CPACONSULTATION]}
          selectedServiceStatus={null}
          isLoading={isLoading}
          onCheckoutHandler={onCheckoutHandler}
          image={SERVICES_DATA[CompanyServices.CPACONSULTATION].image}
          imageBgColor={
            SERVICES_DATA[CompanyServices.CPACONSULTATION].imageBgColor
          }
        />
      </Stack>
    </Stack>
  );
};

export default CpaConsultations;
