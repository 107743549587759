import { Button, Stack, Typography } from "@mui/material";
import { colors } from "utils";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";

interface IQuizButton {
  text?: string;
  onClick: () => void;
}

export const QuizButton = ({ text, onClick }: IQuizButton) => {
  return (
    <Button
      disableElevation
      onClick={onClick}
      sx={{
        background: colors.lightGrey,
        borderRadius: 4,
        p: 2,
        "&:hover, &:focus": {
          bgcolor: colors.lightGrey,
        },
      }}
    >
      <Stack
        width={"100%"}
        direction={"row"}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Typography variant="body1" fontSize={16} fontWeight="500">
          {text}
        </Typography>
        <KeyboardArrowRightRoundedIcon
          sx={{
            width: 36,
            height: 36,
            background: colors.grey,
            borderRadius: "50%",
            padding: "5px",
          }}
        />
      </Stack>
    </Button>
  );
};

export const TextQuizButton = ({
  text = "I'm not sure yet",
  onClick,
}: IQuizButton) => {
  return (
    <Typography
      variant="body1"
      fontWeight="500"
      textAlign={"center"}
      onClick={onClick}
      sx={{ cursor: "pointer" }}
    >
      {text}
    </Typography>
  );
};
