export enum CustomButtonVariants {
  primary = "primary",
  inversedPrimary = "inversed-primary",
  black = "black",
  pureBlack = "pureBlack",
  inversedBlack = "inversed-black",
  green = "green",
}

export type CustomButtonProps = {
  $roundedBorders?: boolean;
  $variant: CustomButtonVariants;
  width?: "100%" | string;
  onClick?: (() => void) | ((e: any) => Promise<void>);
  disabled?: boolean;
  loading?: boolean;
  $loading?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  style?: any;
  children?: React.ReactNode;
};
