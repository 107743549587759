import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import {
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { EmailOutlined } from "@mui/icons-material";
import utils from "utils";
import ButtonBox from "components/ButtonBox";
import { Windows1252Regex } from "utils/regexValidations";
import CustomInput from "components/Form/CustomInput";
import useGetMoneyLookups from "views/DashboardApp/Money/hooks/useGetMoneyLookups";
import {
  LookupTypeKey,
  OnboardingStakeholderBusinessEntityType,
  WalletOnboard,
} from "graphql/__generated__/types";
import { LoadingComponent } from "components/Loading";
import CustomSelect from "components/Form/CustomSelect";
import { useSetMoneyOnboardingMutation } from "graphql/__generated__/operations/MoneyOnboardingValidation.generated";
import { IAddMemberStepsProps } from "../../types";
import { addMemberSteps } from "../types";
import {
  INTITAL_INDIVIDUAL_MEMBER_ADDRESS_VALUES,
  InitialIndividualMemberAddressValuesType,
} from "./helpers/initialMemberAddressData";
import { MemberDialogFormModeEnum } from "../../../types";
import { removeTypename } from "utils/helpers/removeTypename";
import { US_KEY, isValueValid } from "views/DashboardApp/Money/helpers";
import { extractOnboardingApplicant } from "views/DashboardApp/Money/Onboarding/helpers";

const IndividualMemberAddress = ({
  setAddMemberCurrentStep,
  stakeholderObj,
  onboardingData,
  setStakeholderObj,
  memberDialogDetails,
  isApplicant,
}: IAddMemberStepsProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const { t } = useTranslation();

  const { lookupsData, isLookupsDataLoading } = useGetMoneyLookups({
    neededLookups: [LookupTypeKey.COUNTRYOFOPERATION, LookupTypeKey.STATE],
  });
  const [updateMoneyOnboarding] = useSetMoneyOnboardingMutation();

  const {
    control,
    formState,
    watch,
    handleSubmit,
    getValues,
    trigger,
    setValue,
  } = useForm<InitialIndividualMemberAddressValuesType>({
    defaultValues: { ...INTITAL_INDIVIDUAL_MEMBER_ADDRESS_VALUES },
    mode: "onChange",
  });

  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  const country = watch("country");

  useEffect(() => {
    const { address1, address2, country, city, state, postalCode } =
      getValues();

    if (
      isValueValid(address1) &&
      isValueValid(address2) &&
      isValueValid(country) &&
      isValueValid(city) &&
      isValueValid(state) &&
      isValueValid(postalCode)
    ) {
      trigger();
    }
  }, [getValues, trigger]);

  useEffect(() => {
    setValue("address1", stakeholderObj?.address?.address1 || "");
    setValue("address2", stakeholderObj?.address?.address2 || "");
    setValue("country", stakeholderObj?.address?.country || "");
    setValue("city", stakeholderObj?.address?.city || "");
    setValue("state", stakeholderObj?.address?.state || "");
    setValue("postalCode", stakeholderObj?.address?.postalCode || "");
  }, [setValue, stakeholderObj]);

  if (!lookupsData || isLookupsDataLoading) {
    return (
      <Stack height={"200px"} alignItems={"center"} justifyContent={"center"}>
        <LoadingComponent />
      </Stack>
    );
  }

  const onSubmitHandler = async (
    newStakeholderAddress: InitialIndividualMemberAddressValuesType
  ) => {
    const filteredOnboardingData: WalletOnboard =
      removeTypename(onboardingData);

    try {
      setIsLoading(true);

      const applicantData = extractOnboardingApplicant({
        ...stakeholderObj,
      });

      const applicant = filteredOnboardingData.applicant;

      if (memberDialogDetails.mode === MemberDialogFormModeEnum.add) {
        await updateMoneyOnboarding({
          variables: {
            ...filteredOnboardingData,
            stakeholders: [
              ...(filteredOnboardingData.stakeholders || []),
              {
                ...stakeholderObj,
                address: { ...newStakeholderAddress },
              },
            ],
            ...(isApplicant && {
              applicant: {
                ...applicant,
                ...applicantData,
                address: { ...newStakeholderAddress },
                professionalDetailsPositions: [
                  OnboardingStakeholderBusinessEntityType.CONTROL_PRONG,
                ],
              },
            }),
          },
        });
      } else {
        const stakeholdersWithUpdatedData =
          filteredOnboardingData?.stakeholders?.map((stakeholder) =>
            stakeholder?.uuid === memberDialogDetails.stakeholderId
              ? { ...stakeholderObj, address: { ...newStakeholderAddress } }
              : stakeholder
          );

        await updateMoneyOnboarding({
          variables: {
            ...filteredOnboardingData,
            stakeholders: stakeholdersWithUpdatedData,
            ...(isApplicant && {
              applicant: {
                ...applicant,
                ...applicantData,
                address: { ...newStakeholderAddress },
                professionalDetailsPositions: [
                  OnboardingStakeholderBusinessEntityType.CONTROL_PRONG,
                ],
              },
            }),
          },
        });
      }

      setStakeholderObj({
        ...stakeholderObj,
        address: { ...newStakeholderAddress },
      });

      setAddMemberCurrentStep(addMemberSteps.individualMemberDocuments);
    } finally {
      setIsLoading(false);
    }
  };

  const isDisabled = !formState.isValid;

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} gap={1.5}>
        <EmailOutlined />
        <Typography fontSize={16} fontWeight={500}>
          {t("money.onboarding.step.membersInformation.memberAddress")}
        </Typography>
      </Stack>
      <Stack gap={4} mt={3}>
        <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
          <Stack gap={5} mb={2}>
            <Stack gap={2}>
              <Stack direction={isTablet ? "column" : "row"} gap={3}>
                <Controller
                  name="address1"
                  {...COMMON_PROPS}
                  rules={{
                    required: true,
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      placeholder={
                        t(
                          "money.onboarding.step.membersInformation.address1.placeholder"
                        ) as string
                      }
                      label={
                        t(
                          "money.onboarding.step.membersInformation.address1"
                        ) as string
                      }
                    />
                  )}
                />
                <Controller
                  name="address2"
                  {...COMMON_PROPS}
                  rules={{
                    required: false,
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      placeholder={
                        t(
                          "money.onboarding.step.membersInformation.address2.placeholder"
                        ) as string
                      }
                      label={
                        t(
                          "money.onboarding.step.membersInformation.address2"
                        ) as string
                      }
                    />
                  )}
                />
              </Stack>
              <Stack direction={isTablet ? "column" : "row"} gap={3}>
                {lookupsData?.COUNTRYOFOPERATION && (
                  <Controller
                    name="country"
                    {...COMMON_PROPS}
                    rules={{
                      pattern: {
                        value: Windows1252Regex,
                        message: t("error.invalidCharacters"),
                      },
                      required: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <CustomSelect
                        {...field}
                        error={error !== undefined}
                        styles={{ width: "100%" }}
                        placeholder={
                          t(
                            "money.onboarding.step.membersInformation.country.placeholder"
                          ) as string
                        }
                        label={
                          t(
                            "money.onboarding.step.membersInformation.country"
                          ) as string
                        }
                        value={country}
                        defaultValue={field.value}
                        onChange={(e: SelectChangeEvent<unknown>) => {
                          field.onChange(e.target.value);

                          setValue("state", "");
                        }}
                      >
                        {lookupsData.COUNTRYOFOPERATION.map((item, index) => (
                          <MenuItem key={index} value={item?.key ?? ""}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    )}
                  />
                )}
                <Controller
                  name="city"
                  {...COMMON_PROPS}
                  rules={{
                    required: true,
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      placeholder={
                        t(
                          "money.onboarding.step.membersInformation.city.placeholder"
                        ) as string
                      }
                      label={
                        t(
                          "money.onboarding.step.membersInformation.city"
                        ) as string
                      }
                    />
                  )}
                />
              </Stack>
              <Stack direction={isTablet ? "column" : "row"} gap={3}>
                {lookupsData?.STATE && (
                  <Controller
                    name="state"
                    {...COMMON_PROPS}
                    rules={{
                      pattern: {
                        value: Windows1252Regex,
                        message: t("error.invalidCharacters"),
                      },
                      required: true,
                    }}
                    render={({ field, fieldState: { error } }) =>
                      country === US_KEY ? (
                        <CustomSelect
                          {...field}
                          error={error !== undefined}
                          styles={{ width: "100%" }}
                          placeholder={
                            t(
                              "money.onboarding.step.membersInformation.state.select.placeholder"
                            ) as string
                          }
                          label={
                            t(
                              "money.onboarding.step.membersInformation.state"
                            ) as string
                          }
                          defaultValue={field.value}
                          onChange={(e: SelectChangeEvent<unknown>) => {
                            field.onChange(e.target.value);
                          }}
                        >
                          {lookupsData.STATE.map((item, index) => (
                            <MenuItem key={index} value={item?.key ?? ""}>
                              {item.description}
                            </MenuItem>
                          ))}
                        </CustomSelect>
                      ) : (
                        <CustomInput
                          {...field}
                          error={error !== undefined}
                          styles={{ width: "100%" }}
                          placeholder={
                            t(
                              "money.onboarding.step.membersInformation.state.enter.placeholder"
                            ) as string
                          }
                          label={
                            t(
                              "money.onboarding.step.membersInformation.state"
                            ) as string
                          }
                        />
                      )
                    }
                  />
                )}
                <Controller
                  name="postalCode"
                  {...COMMON_PROPS}
                  rules={{
                    required: true,
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      placeholder={
                        t(
                          "money.onboarding.step.membersInformation.zipCode.placeholder"
                        ) as string
                      }
                      label={
                        t(
                          "money.onboarding.step.membersInformation.zipCode"
                        ) as string
                      }
                    />
                  )}
                />
              </Stack>
            </Stack>
          </Stack>
          <ButtonBox
            isLoading={isLoading}
            disabled={isDisabled}
            rightButtonText="Save"
            onClickBack={() =>
              setAddMemberCurrentStep(addMemberSteps.individualMemberDetails)
            }
            onClickNext={() => onSubmitHandler}
          />
        </form>
      </Stack>
    </>
  );
};

export default IndividualMemberAddress;
