import styled from "styled-components";
import utils, { colors } from "utils";

export const StyledLink = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  color: ${colors.black};
  font-weight: 500;
  background: ${colors.darkAccentGreen};
  border-radius: 12px;
  padding: 16px;

  @media only screen and (max-width: ${utils.screenSize.mobile}) {
    > div:last-child {
      max-width: 165px;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;
