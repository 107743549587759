import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMoneyTransactionStatementsQueryVariables = Types.Exact<{
  doolaCompanyId: Types.Scalars['String'];
  startDate: Types.Scalars['String'];
  endDate: Types.Scalars['String'];
}>;


export type GetMoneyTransactionStatementsQuery = { __typename?: 'Query', statements?: string | null };


export const GetMoneyTransactionStatementsDocument = gql`
    query GetMoneyTransactionStatements($doolaCompanyId: String!, $startDate: String!, $endDate: String!) {
  statements(
    doolaCompanyId: $doolaCompanyId
    startDate: $startDate
    endDate: $endDate
  )
}
    `;

/**
 * __useGetMoneyTransactionStatementsQuery__
 *
 * To run a query within a React component, call `useGetMoneyTransactionStatementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoneyTransactionStatementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoneyTransactionStatementsQuery({
 *   variables: {
 *      doolaCompanyId: // value for 'doolaCompanyId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetMoneyTransactionStatementsQuery(baseOptions: Apollo.QueryHookOptions<GetMoneyTransactionStatementsQuery, GetMoneyTransactionStatementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMoneyTransactionStatementsQuery, GetMoneyTransactionStatementsQueryVariables>(GetMoneyTransactionStatementsDocument, options);
      }
export function useGetMoneyTransactionStatementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMoneyTransactionStatementsQuery, GetMoneyTransactionStatementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMoneyTransactionStatementsQuery, GetMoneyTransactionStatementsQueryVariables>(GetMoneyTransactionStatementsDocument, options);
        }
export type GetMoneyTransactionStatementsQueryHookResult = ReturnType<typeof useGetMoneyTransactionStatementsQuery>;
export type GetMoneyTransactionStatementsLazyQueryHookResult = ReturnType<typeof useGetMoneyTransactionStatementsLazyQuery>;
export type GetMoneyTransactionStatementsQueryResult = Apollo.QueryResult<GetMoneyTransactionStatementsQuery, GetMoneyTransactionStatementsQueryVariables>;