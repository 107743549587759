import { Alert, Grid, Stack, Typography } from "@mui/material";
import Upcoming from "./Upcoming";
import Countdown from "./Countdown";
import TaxFilings from "./TaxFilings";
import CpaConsultations from "./CpaConsultations";
import {
  GetStripeSessionDetailsQuery,
  useGetStripeSessionDetailsLazyQuery,
} from "graphql/__generated__/operations/StripeSessionDetails.generated";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { StripeSessionStatus } from "graphql/__generated__/types";
import { toast } from "toast";
import { usePostHog } from "posthog-js/react";
import { t } from "i18next";
import useCompany from "graphql/hooks/UseCompany";
import { Trans } from "react-i18next";
import { useIntercom } from "react-use-intercom";

const Taxes: React.FC<{}> = () => {
  const { show: showIntercom } = useIntercom();
  const posthog = usePostHog();
  const { refetchCompany } = useCompany();

  const [fetchSessionDetails] = useGetStripeSessionDetailsLazyQuery();

  const [sessionDetails, setSessionDetails] = useState<
    GetStripeSessionDetailsQuery | undefined
  >();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const sessionId = searchParams.get("session");

    if (!sessionId) return;

    const fetchStripeSessionDetails = async () => {
      try {
        const { data } = await fetchSessionDetails({
          variables: { sessionId },
        });

        setSessionDetails(data);

        const stripeCheckoutId = data?.stripeSessionDetails?.id || "";
        const status = data?.stripeSessionDetails?.status;
        const paymentIntentId = data?.stripeSessionDetails?.paymentIntentId;
        const totalAmount =
          (data?.stripeSessionDetails?.amountTotal || 0) / 100;

        if (status === StripeSessionStatus.COMPLETE) {
          toast.success(
            t("services.upsell.stripePaymentSuccessfulToast") as string
          );

          refetchCompany();

          posthog?.capture("upsell-product-checkout-completed", {
            purchase_value: totalAmount,
            paymentIntentId,
            stripeCheckoutId,
          });
        } else {
          toast.error(t("services.upsell.stripePaymentCancelled") as string);

          posthog?.capture("upsell-product-checkout-failed", {
            purchase_value: totalAmount,
            paymentIntentId,
            stripeCheckoutId,
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchStripeSessionDetails();
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Stack mb={5}>
      {sessionDetails?.stripeSessionDetails?.status ===
        StripeSessionStatus.COMPLETE && (
        <Alert severity="success" sx={{ mb: 2 }}>
          <Trans
            i18nKey="services.upsell.stripePaymentSuccessful"
            values={{
              value: t("services.upsell.stripePaymentSuccessful.value"),
            }}
            components={[
              <Typography
                component={"span"}
                onClick={showIntercom}
                variant="body2"
                style={{
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              />,
            ]}
          ></Trans>
        </Alert>
      )}
      <Typography variant="h2" mb={5}>
        Taxes
      </Typography>
      <Grid container item xs={12} spacing={4}>
        <Grid container item xs={12} spacing={4}>
          <Grid item xs={12} md={4}>
            <Countdown />
          </Grid>
          <Grid item xs={12} md={8}>
            <TaxFilings />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={4}>
          <Grid item xs={12} md={8}>
            <CpaConsultations />
          </Grid>
          <Grid item xs={12} md={4}>
            <Upcoming />
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Taxes;
