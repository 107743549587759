import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMoneyLookupsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMoneyLookupsQuery = { __typename?: 'Query', lookups?: { __typename?: 'LookupsResponse', payload?: Array<{ __typename?: 'LookupCategory', name?: Types.LookupTypeKey | null, lookups?: Array<{ __typename?: 'Lookup', description?: string | null, key?: string | null } | null> | null } | null> | null } | null };


export const GetMoneyLookupsDocument = gql`
    query GetMoneyLookups {
  lookups {
    payload {
      lookups {
        description
        key
      }
      name
    }
  }
}
    `;

/**
 * __useGetMoneyLookupsQuery__
 *
 * To run a query within a React component, call `useGetMoneyLookupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoneyLookupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoneyLookupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMoneyLookupsQuery(baseOptions?: Apollo.QueryHookOptions<GetMoneyLookupsQuery, GetMoneyLookupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMoneyLookupsQuery, GetMoneyLookupsQueryVariables>(GetMoneyLookupsDocument, options);
      }
export function useGetMoneyLookupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMoneyLookupsQuery, GetMoneyLookupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMoneyLookupsQuery, GetMoneyLookupsQueryVariables>(GetMoneyLookupsDocument, options);
        }
export type GetMoneyLookupsQueryHookResult = ReturnType<typeof useGetMoneyLookupsQuery>;
export type GetMoneyLookupsLazyQueryHookResult = ReturnType<typeof useGetMoneyLookupsLazyQuery>;
export type GetMoneyLookupsQueryResult = Apollo.QueryResult<GetMoneyLookupsQuery, GetMoneyLookupsQueryVariables>;