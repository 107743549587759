import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestStripeCheckoutSessionDdsMutationVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  customerId: Types.Scalars['String'];
  products: Array<Types.Scalars['String']> | Types.Scalars['String'];
  redirectBaseUri?: Types.InputMaybe<Types.Scalars['String']>;
  email: Types.Scalars['String'];
}>;


export type RequestStripeCheckoutSessionDdsMutation = { __typename?: 'Mutation', company?: { __typename?: 'CompanyMutation', requestStripeCheckoutSessionDDS?: { __typename?: 'StripeCheckoutSession', url?: string | null, id: string, stripeCustomerId: string } | null } | null };


export const RequestStripeCheckoutSessionDdsDocument = gql`
    mutation RequestStripeCheckoutSessionDDS($companyId: ID!, $customerId: String!, $products: [String!]!, $redirectBaseUri: String, $email: String!) {
  company(id: $companyId) {
    requestStripeCheckoutSessionDDS(
      customerId: $customerId
      products: $products
      redirectBaseUri: $redirectBaseUri
      email: $email
    ) {
      url
      id
      stripeCustomerId
    }
  }
}
    `;
export type RequestStripeCheckoutSessionDdsMutationFn = Apollo.MutationFunction<RequestStripeCheckoutSessionDdsMutation, RequestStripeCheckoutSessionDdsMutationVariables>;

/**
 * __useRequestStripeCheckoutSessionDdsMutation__
 *
 * To run a mutation, you first call `useRequestStripeCheckoutSessionDdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestStripeCheckoutSessionDdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestStripeCheckoutSessionDdsMutation, { data, loading, error }] = useRequestStripeCheckoutSessionDdsMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      customerId: // value for 'customerId'
 *      products: // value for 'products'
 *      redirectBaseUri: // value for 'redirectBaseUri'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestStripeCheckoutSessionDdsMutation(baseOptions?: Apollo.MutationHookOptions<RequestStripeCheckoutSessionDdsMutation, RequestStripeCheckoutSessionDdsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestStripeCheckoutSessionDdsMutation, RequestStripeCheckoutSessionDdsMutationVariables>(RequestStripeCheckoutSessionDdsDocument, options);
      }
export type RequestStripeCheckoutSessionDdsMutationHookResult = ReturnType<typeof useRequestStripeCheckoutSessionDdsMutation>;
export type RequestStripeCheckoutSessionDdsMutationResult = Apollo.MutationResult<RequestStripeCheckoutSessionDdsMutation>;
export type RequestStripeCheckoutSessionDdsMutationOptions = Apollo.BaseMutationOptions<RequestStripeCheckoutSessionDdsMutation, RequestStripeCheckoutSessionDdsMutationVariables>;