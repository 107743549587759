import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetMoneyFinalDocMutationVariables = Types.Exact<{
  doolaCompanyId: Types.Scalars['String'];
  doolaCustomerId: Types.Scalars['String'];
  id?: Types.InputMaybe<Types.Scalars['String']>;
  applicant?: Types.InputMaybe<Types.OnboardingApplicantInput>;
  companyInfo?: Types.InputMaybe<Types.OnboardingCompanyInfoInput>;
  isSameBusinessAddress?: Types.InputMaybe<Types.Scalars['Boolean']>;
  riskAssessmentInfo?: Types.InputMaybe<Types.OnboardingRiskAssessmentInfoInput>;
  stakeholders?: Types.InputMaybe<Array<Types.InputMaybe<Types.OnboardingStakeholderInput>> | Types.InputMaybe<Types.OnboardingStakeholderInput>>;
}>;


export type SetMoneyFinalDocMutation = { __typename?: 'Mutation', onboard?: { __typename?: 'WalletOnboardResponse', error?: Array<string | null> | null, payload?: { __typename?: 'WalletOnboard', id?: string | null, doolaCompanyId: string, doolaCustomerId: string } | null } | null };


export const SetMoneyFinalDocDocument = gql`
    mutation SetMoneyFinalDoc($doolaCompanyId: String!, $doolaCustomerId: String!, $id: String, $applicant: OnboardingApplicantInput, $companyInfo: OnboardingCompanyInfoInput, $isSameBusinessAddress: Boolean, $riskAssessmentInfo: OnboardingRiskAssessmentInfoInput, $stakeholders: [OnboardingStakeholderInput]) {
  onboard(
    onboarding: {id: $id, applicant: $applicant, companyInfo: $companyInfo, doolaCompanyId: $doolaCompanyId, doolaCustomerId: $doolaCustomerId, isSameBusinessAddress: $isSameBusinessAddress, riskAssessmentInfo: $riskAssessmentInfo, stakeholders: $stakeholders}
  ) {
    payload {
      id
      doolaCompanyId
      doolaCustomerId
    }
    error
  }
}
    `;
export type SetMoneyFinalDocMutationFn = Apollo.MutationFunction<SetMoneyFinalDocMutation, SetMoneyFinalDocMutationVariables>;

/**
 * __useSetMoneyFinalDocMutation__
 *
 * To run a mutation, you first call `useSetMoneyFinalDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMoneyFinalDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMoneyFinalDocMutation, { data, loading, error }] = useSetMoneyFinalDocMutation({
 *   variables: {
 *      doolaCompanyId: // value for 'doolaCompanyId'
 *      doolaCustomerId: // value for 'doolaCustomerId'
 *      id: // value for 'id'
 *      applicant: // value for 'applicant'
 *      companyInfo: // value for 'companyInfo'
 *      isSameBusinessAddress: // value for 'isSameBusinessAddress'
 *      riskAssessmentInfo: // value for 'riskAssessmentInfo'
 *      stakeholders: // value for 'stakeholders'
 *   },
 * });
 */
export function useSetMoneyFinalDocMutation(baseOptions?: Apollo.MutationHookOptions<SetMoneyFinalDocMutation, SetMoneyFinalDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetMoneyFinalDocMutation, SetMoneyFinalDocMutationVariables>(SetMoneyFinalDocDocument, options);
      }
export type SetMoneyFinalDocMutationHookResult = ReturnType<typeof useSetMoneyFinalDocMutation>;
export type SetMoneyFinalDocMutationResult = Apollo.MutationResult<SetMoneyFinalDocMutation>;
export type SetMoneyFinalDocMutationOptions = Apollo.BaseMutationOptions<SetMoneyFinalDocMutation, SetMoneyFinalDocMutationVariables>;