import {
  Stack,
  Card,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Divider,
  AccordionDetails,
  useTheme,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { P } from "components/Fonts";
import { Logo } from "components/Logos";
import { t } from "i18next";
import { colors } from "utils";
import Service from "components/UpsellOldService";
import { StyledAccordion, StyledAccordionSummary } from "./styles";
import { ExpandMoreRounded } from "@mui/icons-material";
import { LoadingComponent } from "components/Loading";
import useGetBooksProducts from "../hooks/useGetBooksProducts";
import { BOOKS, BooksPackageValue, INVOICING } from "../data";
import { usePostHog } from "posthog-js/react";

type Props = {
  onChange: (packageName: BooksPackageValue) => void;
  renderPackageTitle: (packageName: BooksPackageValue) => JSX.Element;
  isDoolaBooksSelected: boolean;
  booksPackageName: BooksPackageValue;
  customTitle?: string;
  showInvoiceUpsell?: boolean;
};

const BooksUpsell = ({
  onChange,
  renderPackageTitle,
  isDoolaBooksSelected,
  booksPackageName,
  customTitle,
  showInvoiceUpsell = true,
}: Props) => {
  const posthog = usePostHog();
  const theme = useTheme();
  const matchDownSM = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { BOOKS_PLANS, isProductQueryLoading } = useGetBooksProducts();

  const isInvoicingSelected =
    isDoolaBooksSelected && booksPackageName === INVOICING;
  const isCompleteBooksSelected =
    isDoolaBooksSelected && booksPackageName === BOOKS;

  if (isProductQueryLoading) {
    return <LoadingComponent />;
  }

  return (
    <Stack spacing={1}>
      <Card
        variant="outlined"
        sx={{
          borderRadius: 1,
          ...(isDoolaBooksSelected && { background: "#E6F7FF" }),
          border: `1px solid ${
            isDoolaBooksSelected ? colors.blue : theme.palette.secondary.light
          }`,
          p: matchDownSM ? 2.5 : 3,
          mb: 3,
          transition: "all .1s ease-out",
        }}
      >
        <Box width="100%">
          <Stack direction={"row"} spacing={1} mb={2}>
            <Logo />
            <P
              size="30px"
              $weight="700"
              style={{
                lineHeight: 1,
                marginTop: "7px",
              }}
            >
              Books
            </P>
          </Stack>
          <Typography variant="h5" mb={2}>
            {customTitle || t("signup.formation.booksProduct.title")}
          </Typography>
        </Box>
        {showInvoiceUpsell && (
          <Stack spacing={1} mb={2}>
            <FormControlLabel
              control={
                <Checkbox
                  disableRipple
                  name={`books-type`}
                  checked={isInvoicingSelected}
                  onChange={() => onChange(INVOICING)}
                  sx={{
                    padding: 0,
                    paddingRight: 1,
                    color: theme.palette.secondary.light,
                    "&.Mui-checked": {
                      color: colors.blue,
                    },
                  }}
                />
              }
              label={
                <Stack>
                  {renderPackageTitle(INVOICING)}
                  {BOOKS_PLANS[INVOICING].highlights?.map((highlight) => (
                    <Typography
                      key={highlight}
                      sx={{ "&:before": { content: "' • '", mr: 1 } }}
                    >
                      {t(highlight)}
                    </Typography>
                  ))}
                </Stack>
              }
              sx={{ alignItems: "flex-start" }}
            />
            <Divider />
            <Box>
              <StyledAccordion
                onChange={(_e, expanded) => {
                  posthog?.capture(
                    expanded
                      ? "books-accordion-expand"
                      : "books-accordion-contract",
                    {
                      accordion_name: `Accordion for #${INVOICING}`,
                    }
                  );
                }}
                sx={{ mb: 1, mt: 0 }}
              >
                <StyledAccordionSummary
                  expandIcon={
                    <ExpandMoreRounded sx={{ color: colors.charcoal }} />
                  }
                >
                  <Typography fontWeight={700}>
                    {t("signup.formation.included")}
                  </Typography>
                </StyledAccordionSummary>
                <AccordionDetails sx={{ mt: 2, ml: 1, mr: 1, mb: 2, p: 0 }}>
                  <Service title={t("signup.formation.invoicing.smas")} />
                  <Service title={t("signup.formation.invoicing.cit")} />
                  <Service title={t("signup.formation.invoicing.cips")} />
                  <Service title={t("signup.formation.invoicing.ccm")} />
                </AccordionDetails>
              </StyledAccordion>
            </Box>
          </Stack>
        )}
        <Stack spacing={1}>
          <FormControlLabel
            control={
              <Checkbox
                disableRipple
                name={`books-type`}
                checked={isCompleteBooksSelected}
                onChange={() => onChange(BOOKS)}
                sx={{
                  padding: 0,
                  paddingRight: 1,
                  color: theme.palette.secondary.light,
                  "&.Mui-checked": {
                    color: colors.blue,
                  },
                }}
              />
            }
            label={
              <Stack>
                {renderPackageTitle(BOOKS)}
                {BOOKS_PLANS[BOOKS].highlights?.map((highlight) => (
                  <Typography
                    key={highlight}
                    sx={{ "&:before": { content: "' • '", mr: 1 } }}
                  >
                    {t(highlight)}
                  </Typography>
                ))}
              </Stack>
            }
            sx={{ alignItems: "flex-start" }}
          />
          <Divider />
          <Box>
            <StyledAccordion
              onChange={(_e, expanded) => {
                posthog?.capture(
                  expanded
                    ? "books-accordion-expand"
                    : "books-accordion-contract",
                  {
                    accordion_name: `Accordion for #${BOOKS}`,
                  }
                );
              }}
              sx={{ mb: 1, mt: 0 }}
            >
              <StyledAccordionSummary
                expandIcon={
                  <ExpandMoreRounded sx={{ color: colors.charcoal }} />
                }
              >
                <Typography fontWeight={700}>
                  {t("signup.formation.included")}
                </Typography>
              </StyledAccordionSummary>
              <AccordionDetails sx={{ mt: 2, ml: 1, mr: 1, mb: 2, p: 0 }}>
                <Service title={t("signup.formation.invoicing.smas")} />
                <Service title={t("signup.formation.invoicing.cit")} />
                <Service title={t("signup.formation.invoicing.cips")} />
                <Service title={t("signup.formation.invoicing.ccm")} />
                <Service title={t("signup.formation.books.ict")} />
                <Service title={t("signup.formation.books.ect")} />
                <Service title={t("signup.formation.books.aim")} />
                <Service title={t("signup.formation.books.pat")} />
              </AccordionDetails>
            </StyledAccordion>
          </Box>
        </Stack>
      </Card>
    </Stack>
  );
};

export default BooksUpsell;
