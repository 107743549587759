import { useState, useEffect, useMemo } from "react";
import {
  IconButton,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import utils, { colors } from "utils";
import { ROUTER_PATHS } from "routes/routes";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import MemberExpandable from "./MemberExpandable";
import { LoadingComponent } from "components/Loading";
import useCompany from "graphql/hooks/UseCompany";
import stateKeys from "resources/stateKeys";
import { AddressType, EntityType } from "graphql/__generated__/types";
import CloseIcon from "@mui/icons-material/Close";
import { useFileWithRaMutation } from "graphql/__generated__/operations/FileWithRa.generated";
import ButtonBox from "components/ButtonBox";
import ResponsiblePartyExpandable from "./ResponsiblePartyExpandable";
import { StyledEditButton } from "components/StyledEditButton";
import { usePostHog } from "posthog-js/react";
import { useTranslation } from "react-i18next";

const OnboardingReview = () => {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);
  const { currentCompany } = useCompany();

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation();

  const [fileWithRa] = useFileWithRaMutation();

  const [memberAsRpPosition, setMemberAsRpPosition] = useState<number | null>(
    null
  );

  const isCCorpFlow = useMemo(
    () => currentCompany?.entityType === EntityType.CCORP,
    [currentCompany?.entityType]
  );

  useEffect(() => {
    if (isCCorpFlow) {
      if (!currentCompany.ccorpExecInfo) {
        navigate(ROUTER_PATHS.ONBOARDING_EXECUTIVE);
      }

      if (!currentCompany.ccorpValuationInfo) {
        navigate(ROUTER_PATHS.ONBOARDING_EXECUTIVE);
      }
    } else {
      const hasAllMemberPercentages = currentCompany?.members?.every(
        (member) => member?.ownershipPercent && member.ownershipPercent > 0
      );

      if (currentCompany && !hasAllMemberPercentages) {
        navigate(ROUTER_PATHS.ONBOARDING_OWNERSHIP);
      }
    }
  }, [currentCompany, isCCorpFlow, navigate]);

  useEffect(() => {
    const responsibleParty = currentCompany?.responsibleParty;
    const members = currentCompany?.members;

    if (responsibleParty && members) {
      const chosenMember = currentCompany?.members?.find(
        (member) =>
          member.legalFirstName ===
            currentCompany?.responsibleParty?.legalFirstName &&
          member.legalLastName ===
            currentCompany?.responsibleParty?.legalLastName
      );

      if (chosenMember) {
        setMemberAsRpPosition(chosenMember.position!);
      }
    }
  }, [currentCompany?.members, currentCompany?.responsibleParty]);

  if (!currentCompany) {
    return <LoadingComponent />;
  }

  const state = stateKeys[currentCompany.state as keyof typeof stateKeys];

  const members = currentCompany?.members;
  const usAddress = currentCompany?.usMailingAddress;
  const companyNames = currentCompany?.nameOptions;

  const defaultCompanyName = companyNames?.find((x) => x?.position === 0);
  const alternateCompany1 = companyNames?.find((x) => x?.position === 1);
  const alternateCompany2 = companyNames?.find((x) => x?.position === 2);

  const onConfirm = async () => {
    posthog?.capture("company-submit", {
      sessions_before_submission: "",
    });

    try {
      setIsLoading(true);

      await fileWithRa({
        variables: {
          companyId: currentCompany?.id,
        },
      });

      navigate(ROUTER_PATHS.DASHBOARD);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Stack direction="column" gap={2} mb={5}>
        <Typography variant="h1">Review your Filing</Typography>
        <Stack spacing={2} bgcolor={colors.lightGrey} p={3} borderRadius="24px">
          <Typography variant="h5">General Information</Typography>
          <Stack direction="row" justifyContent="space-between">
            <Typography>Entity Type</Typography>
            <Typography>{currentCompany.entityType}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography>State</Typography>
            <Typography>{state}</Typography>
          </Stack>
        </Stack>
        <Stack spacing={2} bgcolor={colors.lightGrey} p={3} borderRadius="24px">
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">Company Name</Typography>
            <StyledEditButton
              onClick={() => {
                posthog?.capture("company-review-edit", {
                  review_edit: ROUTER_PATHS.ONBOARDING_COMPANY_NAME,
                });
                navigate(ROUTER_PATHS.ONBOARDING_COMPANY_NAME);
              }}
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography>Preferred Company Name</Typography>
            <Typography>{defaultCompanyName?.name || ""}</Typography>
          </Stack>
          {alternateCompany1?.name && (
            <Stack direction="row" justifyContent="space-between">
              <Typography>Alternate Company Name 1</Typography>
              <Typography>{alternateCompany1?.name || ""}</Typography>
            </Stack>
          )}
          {alternateCompany2?.name && (
            <Stack direction="row" justifyContent="space-between">
              <Typography>Alternate Company Name 2</Typography>
              <Typography>{alternateCompany2?.name || ""}</Typography>
            </Stack>
          )}
        </Stack>
        <Stack spacing={2} bgcolor={colors.lightGrey} p={3} borderRadius="24px">
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">Company Address</Typography>
            <StyledEditButton
              onClick={() => {
                posthog?.capture("company-review-edit", {
                  review_edit: ROUTER_PATHS.ONBOARDING_ADDRESS,
                });
                navigate(ROUTER_PATHS.ONBOARDING_ADDRESS);
              }}
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography>Type</Typography>
            <Typography>
              {currentCompany.addressType === AddressType.REGISTEREDAGENT
                ? "Registered Agent"
                : "Personal"}
            </Typography>
          </Stack>
          {currentCompany.addressType === AddressType.OTHERUS && usAddress && (
            <Stack direction="row" justifyContent="space-between">
              <Typography>Address</Typography>
              <Stack
                direction="column"
                width="40%"
                maxWidth="140px"
                textAlign="right"
              >
                <Typography>{usAddress.line1}</Typography>
                {usAddress.line2 && <Typography>{usAddress.line2}</Typography>}
                <Typography>{`${usAddress.city}, ${usAddress.state}, ${usAddress.postalCode}`}</Typography>
                <Typography>{usAddress.country}</Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
        <Stack spacing={2} bgcolor={colors.lightGrey} p={3} borderRadius="24px">
          <Stack direction="row" spacing={1} alignItems="center">
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              justifyContent="space-between"
              width="100%"
            >
              <Typography variant="h5">Industry</Typography>
              <StyledEditButton
                onClick={() => {
                  posthog?.capture("company-review-edit", {
                    review_edit: ROUTER_PATHS.ONBOARDING_INDUSTRY,
                  });
                  navigate(ROUTER_PATHS.ONBOARDING_INDUSTRY);
                }}
              />
            </Stack>
          </Stack>
          <Typography>{currentCompany.activity}</Typography>
        </Stack>
        <Stack spacing={2} bgcolor={colors.lightGrey} p={3} borderRadius="24px">
          <Stack direction="row" spacing={1} alignItems="center">
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              justifyContent="space-between"
              width="100%"
            >
              <Typography variant="h5">Description</Typography>
              <StyledEditButton
                onClick={() => {
                  posthog?.capture("company-review-edit", {
                    review_edit: ROUTER_PATHS.ONBOARDING_DESCRIPTION,
                  });
                  navigate(ROUTER_PATHS.ONBOARDING_DESCRIPTION);
                }}
              />
            </Stack>
          </Stack>
          <Typography>{currentCompany.description}</Typography>
        </Stack>
        {!isCCorpFlow && (
          <Stack
            spacing={2}
            bgcolor={colors.lightGrey}
            p={3}
            borderRadius="24px"
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5">Members</Typography>
              <StyledEditButton
                onClick={() => {
                  posthog?.capture("company-review-edit", {
                    review_edit: ROUTER_PATHS.ONBOARDING_MEMBERS,
                  });
                  navigate(ROUTER_PATHS.ONBOARDING_MEMBERS);
                }}
              />
            </Stack>
            <Stack>
              {members?.map((member) => (
                <MemberExpandable
                  key={member.position}
                  member={member}
                  isResponsibleParty={member.position === memberAsRpPosition}
                />
              ))}
            </Stack>
          </Stack>
        )}
        {isCCorpFlow && (
          <Stack
            spacing={2}
            bgcolor={colors.lightGrey}
            p={3}
            borderRadius="24px"
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5">Executives</Typography>
              <StyledEditButton
                onClick={() => {
                  posthog?.capture("company-review-edit", {
                    review_edit: ROUTER_PATHS.ONBOARDING_EXECUTIVE,
                  });
                  navigate(ROUTER_PATHS.ONBOARDING_EXECUTIVE);
                }}
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <Stack>
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography variant="h5">{`${currentCompany.ccorpExecInfo?.president?.firstName} ${currentCompany.ccorpExecInfo?.president?.lastName}`}</Typography>
                </Stack>
                <Typography>
                  {t("onboarding.ccorp.executive.president")}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Stack>
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography variant="h5">{`${currentCompany.ccorpExecInfo?.treasurer?.firstName} ${currentCompany.ccorpExecInfo?.treasurer?.lastName}`}</Typography>
                </Stack>
                <Typography>
                  {t("onboarding.ccorp.executive.treasurer")}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Stack>
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography variant="h5">{`${currentCompany.ccorpExecInfo?.secretary?.firstName} ${currentCompany.ccorpExecInfo?.secretary?.lastName}`}</Typography>
                </Stack>
                <Typography>
                  {t("onboarding.ccorp.executive.secretary")}
                </Typography>
              </Stack>
            </Stack>
            {currentCompany?.ccorpExecInfo?.directors?.map(
              (director, index) => (
                <Stack direction="row" spacing={2} key={index}>
                  <Stack>
                    <Stack direction="row" gap={1} alignItems="center">
                      <Typography variant="h5">{`${director?.firstName} ${director?.lastName}`}</Typography>
                    </Stack>
                    <Typography>
                      {t("onboarding.ccorp.executive.director")} #{index + 1}
                    </Typography>
                  </Stack>
                </Stack>
              )
            )}
          </Stack>
        )}
        {!memberAsRpPosition && (
          <Stack
            spacing={2}
            bgcolor={colors.lightGrey}
            p={3}
            borderRadius="24px"
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5">Responsible Party</Typography>
              <StyledEditButton
                onClick={() => {
                  posthog?.capture("company-review-edit", {
                    review_edit: ROUTER_PATHS.ONBOARDING_RESPONSIBLE_PARTY,
                  });
                  navigate(ROUTER_PATHS.ONBOARDING_RESPONSIBLE_PARTY);
                }}
              />
            </Stack>
            <Stack>
              {currentCompany.responsibleParty && (
                <ResponsiblePartyExpandable
                  responsibleParty={currentCompany.responsibleParty}
                />
              )}
            </Stack>
          </Stack>
        )}
        {isCCorpFlow && (
          <Stack
            spacing={2}
            bgcolor={colors.lightGrey}
            p={3}
            borderRadius="24px"
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5">Company Valuation</Typography>
              <StyledEditButton
                onClick={() => {
                  posthog?.capture("company-review-edit", {
                    review_edit: ROUTER_PATHS.ONBOARDING_SHARE,
                  });
                  navigate(ROUTER_PATHS.ONBOARDING_SHARE);
                }}
              />
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography>
                {t("onboarding.ccorp.share.numberOfShares")}
              </Typography>
              <Typography>
                {currentCompany.ccorpValuationInfo?.noOfShares}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography>
                {t("onboarding.ccorp.share.parValueOfShares")}
              </Typography>
              <Typography>
                ${currentCompany.ccorpValuationInfo?.valuePerShare}
              </Typography>
            </Stack>
          </Stack>
        )}
        <Stack direction="row" alignItems={"center"} gap={2}>
          <InfoIcon />
          <Typography>
            Please double-check the information you entered before clicking
            submit. Any edits after submitting may result in additional costs,
            delays in processing, or both. Clicking “Submit” below will file
            your new company with the state of {state}.
          </Typography>
        </Stack>
        <Stack direction={isMobile ? "column" : "row"} gap={1}>
          <ButtonBox
            onClickBack={() => {
              posthog?.capture("company-review-edit", {
                review_edit: ROUTER_PATHS.ONBOARDING_OWNERSHIP,
              });
              navigate(ROUTER_PATHS.ONBOARDING_OWNERSHIP);
            }}
            onClickNext={() => setShowModal(!showModal)}
            rightButtonText="Submit"
          />
        </Stack>
      </Stack>
      <Modal open={showModal} data-testid="OnboardingConfirmationModal">
        <Stack
          direction="column"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isTablet ? "100%" : 420,
            height: isTablet ? "100%" : "auto",
            maxHeight: isTablet ? "100%" : "90%",
            boxShadow: 24,
            borderRadius: isTablet ? 0 : 4,
            p: 2.5,
            bgcolor: colors.white,
            overflow: "scroll",
          }}
          gap={2}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Confirm Information</Typography>
            <IconButton onClick={() => setShowModal(!showModal)}>
              <CloseIcon color="primary" />
            </IconButton>
          </Stack>
          <Typography>
            Please double-check the information you entered before clicking
            submit. Any need to edit information supplied in this form after it
            is submitted may result in additional costs, delays in processing,
            or both.
          </Typography>
          <Typography>
            Ready to file your company's information with the state?
          </Typography>
          <Stack>
            <ButtonBox
              styles={{
                alignItems: isTablet ? "normal" : "flex-end",
              }}
              isLoading={isLoading}
              disabled={isLoading}
              disableSticky
              onClickBack={() => setShowModal(!showModal)}
              onClickNext={onConfirm}
              rightButtonText="Submit"
              leftIcon="close"
            />
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};

export default OnboardingReview;
