import { MailOutlined, PersonOutlineRounded } from "@mui/icons-material";
import { MenuItem, SelectChangeEvent, Stack, Typography } from "@mui/material";
import ButtonBox from "components/ButtonBox";
import CustomDatePicker from "components/Form/CustomDatePicker";
import CustomInput from "components/Form/CustomInput";
import CustomSelect from "components/Form/CustomSelect";
import {
  Iso31661Alpha3,
  TaxMemberInfo,
  TaxMemberInfoInput,
} from "graphql/__generated__/types";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { INITIAL_MEMBER_VALUES } from "../helpers/initialMembersData";
import { SSNITINRegex, Windows1252Regex } from "utils/regexValidations";
import { t } from "i18next";
import { useMediaQuery } from "@mui/material";
import utils from "utils";
import ErrorBox from "components/ErrorBox";
import { useGetCountriesQuery } from "graphql/__generated__/operations/Countries.generated";
import { LoadingComponent } from "components/Loading";
import useCompany from "graphql/hooks/UseCompany";
import { useEditTaxMemberInfoMutation } from "graphql/__generated__/operations/EditTaxMemberInfo.generated";
import SSNMask from "components/SSNMask";
import dayjs from "dayjs";
import { IMemberInformationProps } from "../types";

const MemberInformation = ({
  setShowHeading,
  resetData,
  member,
}: IMemberInformationProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { currentCompany } = useCompany();
  const [editTaxMemberInfo] = useEditTaxMemberInfoMutation();

  const { control, formState, watch, setValue, handleSubmit, trigger } =
    useForm<TaxMemberInfoInput>({
      defaultValues: { ...INITIAL_MEMBER_VALUES },
      mode: "onChange",
    });

  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  const legalFirstName = watch("legalFirstName");
  const legalLastName = watch("legalLastName");
  const countryCode = watch("address.country");

  const { data: countriesData, loading: isCountriesDataLoading } =
    useGetCountriesQuery();
  const countryList = countriesData?.countries;

  const countrySubdvisions =
    countryList?.find((country) => country?.code === countryCode)
      ?.subdivisions || [];

  const hasStates = !!countrySubdvisions.length;

  const isDisabled = isLoading || isCountriesDataLoading || !formState.isValid;

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const availablePosition = currentCompany?.companyTaxInfo?.members?.length
    ? currentCompany?.companyTaxInfo?.members[
        currentCompany?.companyTaxInfo?.members?.length - 1
      ]?.position! + 1
    : 0;

  useEffect(() => {
    if (member) {
      setValue("legalFirstName", member.legalFirstName || "");
      setValue("legalLastName", member.legalLastName || "");
      setValue("dob", member.dob || "");
      setValue("ssn", member.ssn || "");
      setValue("ownershipPercent", member.ownershipPercent || 0);

      if (member.address) {
        const { __typename, ...address } = member.address;
        setValue("address", address);
      }

      setValue("isNaturalPerson", true);
      setValue("position", member.position);

      // trigger form check after all the values are set
      setTimeout(() => {
        if (JSON.stringify(member) !== JSON.stringify(INITIAL_MEMBER_VALUES)) {
          trigger();
        }
      }, 1000);
    }
  }, [member, setValue, trigger]);

  useEffect(() => {
    setShowHeading(false);

    return () => {
      setShowHeading(true);
    };
  }, [setShowHeading]);

  useEffect(() => {
    setValue("contactFullName", `${legalFirstName} ${legalLastName}`);
  }, [legalFirstName, legalLastName, setValue]);

  if (isCountriesDataLoading && !countryList) {
    return <LoadingComponent />;
  }

  const onSubmitHandler = async (member: TaxMemberInfo) => {
    try {
      setIsLoading(true);

      await editTaxMemberInfo({
        variables: {
          companyId: currentCompany?.id!,
          memberInfo: {
            member: {
              ...member,
              position: member.position ?? availablePosition,
            },
            position: member.position ?? availablePosition,
          },
        },
      });

      resetData();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack mb={isTablet ? 9 : 3}>
      <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
        <Stack gap={4}>
          <Stack gap={3}>
            <Stack direction={"row"} alignItems={"center"} gap={1.5}>
              <PersonOutlineRounded />
              <Typography fontSize={16} fontWeight={500}>
                Member information
              </Typography>
            </Stack>
            <Stack gap={2}>
              <Stack direction={isTablet ? "column" : "row"} gap={3}>
                <Controller
                  name="legalFirstName"
                  {...COMMON_PROPS}
                  rules={{
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      placeholder="Enter first name"
                      label="First name"
                    />
                  )}
                />
                <Controller
                  name="legalLastName"
                  {...COMMON_PROPS}
                  rules={{
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      placeholder="Enter last name"
                      label="Last name"
                    />
                  )}
                />
              </Stack>
              <Stack direction={isTablet ? "column" : "row"} gap={3}>
                <Controller
                  name="dob"
                  rules={{
                    required: true,
                  }}
                  {...COMMON_PROPS}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <CustomDatePicker
                      value={dayjs(new Date(value ?? 0))}
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      onChange={(newValue) =>
                        onChange(dayjs(newValue).format())
                      }
                      placeholder="Enter date"
                      label="Date of birth"
                      maxDate={utils.maxAgeDate}
                      disableFuture
                    />
                  )}
                />
                <Controller
                  name="ssn"
                  {...COMMON_PROPS}
                  rules={{
                    pattern: {
                      value: SSNITINRegex,
                      message: t("error.invalidSSNITIN"),
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      isProtected
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      placeholder="Enter SSN / ITIN"
                      label="SSN / ITIN (optional)"
                      autoComplete=""
                      mask={SSNMask as any}
                    />
                  )}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack gap={3}>
            <Stack direction={"row"} alignItems={"center"} gap={1.5}>
              <MailOutlined />
              <Typography fontSize={16} fontWeight={500}>
                {"Address"}
              </Typography>
            </Stack>
            <Stack gap={2}>
              <Stack direction={isTablet ? "column" : "row"} gap={3}>
                <Controller
                  name="address.line1"
                  {...COMMON_PROPS}
                  rules={{
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      placeholder="Enter address line 1"
                      label="Address line 1"
                    />
                  )}
                />
                <Controller
                  name="address.line2"
                  {...COMMON_PROPS}
                  rules={{
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      placeholder="Enter address line 2"
                      label="Address line 2 (optional)"
                    />
                  )}
                />
              </Stack>
              <Stack direction={isTablet ? "column" : "row"} gap={3}>
                <Controller
                  name="address.country"
                  {...COMMON_PROPS}
                  rules={{
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomSelect
                      {...field}
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      placeholder="Select country"
                      label="Country"
                      value={countryCode}
                      defaultValue={field.value}
                      onChange={(e: SelectChangeEvent<unknown>) => {
                        // update the state value to be an empty string when the country changes
                        setValue("address.state", "");
                        field.onChange(e.target.value);
                      }}
                    >
                      {countryList?.map((country, index) => (
                        <MenuItem
                          key={index}
                          value={country?.code as Iso31661Alpha3}
                        >
                          {country?.name}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  )}
                />
                <Controller
                  name="address.city"
                  {...COMMON_PROPS}
                  rules={{
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      placeholder="Enter city"
                      label="City"
                    />
                  )}
                />
              </Stack>
              <Stack direction={isTablet ? "column" : "row"} gap={3}>
                <Controller
                  name="address.state"
                  {...COMMON_PROPS}
                  rules={{
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) =>
                    hasStates ? (
                      <CustomSelect
                        {...field}
                        error={error !== undefined}
                        styles={{ width: "100%" }}
                        placeholder="Select state"
                        label="State"
                        defaultValue={field.value}
                        onChange={(e: SelectChangeEvent<unknown>) => {
                          field.onChange(e.target.value);
                        }}
                      >
                        {countrySubdvisions.map((subdivision, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={subdivision?.code || ""}
                            >
                              {subdivision?.name || ""}
                            </MenuItem>
                          );
                        })}
                      </CustomSelect>
                    ) : (
                      <CustomInput
                        {...field}
                        error={error !== undefined}
                        styles={{ width: "100%" }}
                        placeholder="Enter state"
                        label="State"
                      />
                    )
                  }
                />
                <Controller
                  name="address.postalCode"
                  {...COMMON_PROPS}
                  rules={{
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      placeholder="Enter zip code"
                      label="Zip code"
                    />
                  )}
                />
              </Stack>
            </Stack>
          </Stack>
          <ErrorBox formState={formState} />
          <ButtonBox
            isLoading={isLoading}
            disabled={isDisabled}
            onClickBack={() => resetData()}
            onClickNext={() => onSubmitHandler}
            rightButtonText={"Save"}
          />
        </Stack>
      </form>
    </Stack>
  );
};

export default MemberInformation;
