import IntTransferImage from "assets/Money/landing-int-transfer.svg";
import PayoutsImage from "assets/Money/landing-payouts.svg";
import TrackImage from "assets/Money/landing-track.svg";

export const MoneyLandingPageFeatures = [
  {
    title: "money.landingPage.featureOne.title",
    description: "money.landingPage.featureOne.description",
    image: IntTransferImage,
  },
  {
    title: "money.landingPage.featureTwo.title",
    description: "money.landingPage.featureTwo.description",
    image: PayoutsImage,
  },
  {
    title: "money.landingPage.featureThree.title",
    description: "money.landingPage.featureThree.description",
    image: TrackImage,
  },
];
