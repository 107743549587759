import { Lookup, LookupTypeKey } from "graphql/__generated__/types";
import { useGetMoneyLookupLazyQuery } from "graphql/__generated__/operations/MoneyLookup.generated";
import { useCallback, useEffect, useState } from "react";

interface IMoneyLookups {
  lookupData?: (Lookup | null)[];
  isLookupDataLoading: boolean;
  category: LookupTypeKey;
}

export default function useGetMoneyLookup({
  category,
}: {
  category: LookupTypeKey;
}): IMoneyLookups {
  const [isLookupDataLoading, setIsLookupDataLoading] = useState<boolean>(true);
  const [lookupData, setLookupData] = useState<(Lookup | null)[]>();

  const [fetchMoneyLookupData] = useGetMoneyLookupLazyQuery({
    fetchPolicy: "cache-first",
    errorPolicy: "all",
  });

  const getLookupData = useCallback(async () => {
    try {
      const { data: lookupData } = await fetchMoneyLookupData({
        variables: { category },
      });

      const lookup = lookupData?.lookup?.payload;

      if (lookup) {
        setLookupData(lookup);
      }
    } catch (error) {
      console.log("Failed to fetch lookups data, error: ", error);
    } finally {
      setIsLookupDataLoading(false);
    }
  }, [category, fetchMoneyLookupData]);

  useEffect(() => {
    if (category) {
      getLookupData();
    }
  }, [fetchMoneyLookupData, category, getLookupData]);

  return {
    lookupData,
    isLookupDataLoading,
    category,
  };
}
