import { IStepProps } from "../../types";
import { Dispatch, SetStateAction } from "react";

export enum membersSubSteps {
  membersInformation = "membersInformation",
  ownershipPercentage = "ownershipPercentage",
}

export interface ISubStepProps extends IStepProps {
  setCurrentSubStep: Dispatch<SetStateAction<membersSubSteps>>;
  setShowHeading: Dispatch<SetStateAction<boolean>>;
}
