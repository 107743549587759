import { colors } from "utils";
import React, {
  CSSProperties,
  JSXElementConstructor,
  ReactElement,
  RefCallback,
  useState,
} from "react";
import { Stack, TextField, TextFieldProps, Typography } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { IconButton } from "@mui/material";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { InputBaseComponentProps } from "@mui/material";
import { CustomPhoneInput } from "./CustomPhoneStyles";

type Props = TextFieldProps & {
  styles?: CSSProperties;
  inputStyles?: CSSProperties;
  placeholder?: string;
  mask?: React.ElementType<InputBaseComponentProps>;
  isProtected?: boolean;
  startIcon?: ReactElement<any, string | JSXElementConstructor<any>>;
  endIcon?: ReactElement<any, string | JSXElementConstructor<any>>;
  dataTestId?: string;
  inputProps?: InputBaseComponentProps;
  isPhone?: boolean;
};

const CustomInput = React.forwardRef<HTMLElement, Props>(function CustomInput(
  {
    styles,
    inputStyles,
    dataTestId,
    placeholder = "",
    isProtected = false,
    mask,
    label,
    startIcon,
    endIcon,
    type,
    inputProps,
    error,
    isPhone,
    ...props
  },
  ref
) {
  const [showInput, setShowInput] = useState(!isProtected);

  return (
    <Stack
      sx={{
        ...(styles && { ...styles }),
      }}
      gap={"4px"}
    >
      <TextField
        {...props}
        variant="filled"
        type={showInput ? type || "text" : "password"}
        inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
        sx={{
          input: {
            ...(isPhone && { fontSize: "16px" }),
            ...(error && { color: colors.red }),
            "&::placeholder": {
              color: colors.contentTertiary,
              padding: 0,
              opacity: 1,
            },
          },
        }}
        placeholder={placeholder}
        InputProps={{
          ...(isPhone && {
            inputComponent: CustomPhoneInput as any,
          }),
          ...(mask && {
            inputComponent: mask,
          }),
          ...(startIcon && {
            startAdornment: (
              <Stack color={colors.contentTertiary} sx={{ pl: 2 }}>
                {startIcon}
              </Stack>
            ),
          }),
          ...(endIcon && {
            endAdornment: (
              <Stack color={colors.contentTertiary} sx={{ pr: 2 }}>
                {endIcon}
              </Stack>
            ),
          }),
          ...(isProtected && {
            endAdornment: (
              <InputAdornment position="end" sx={{ pr: 2 }}>
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowInput(!showInput)}
                  edge="end"
                  color="secondary"
                  size="small"
                >
                  {showInput ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </IconButton>
              </InputAdornment>
            ),
          }),
          disableUnderline: true,
          inputProps: {
            style: {
              padding: "0.75rem 1rem",
              borderRadius: "24px 0 0 24px",
              border: 0,
            },
            ...inputProps,
          },
          sx: {
            fontSize: "1rem",
            padding: 0,
            borderRadius: 4,
            backgroundColor: error ? colors.lightRed : colors.grey,
            "& textarea::placeholder": {
              color: colors.contentTertiary,
              padding: 0,
              opacity: 1,
            },
            ...(inputStyles && { ...inputStyles }),
          },
        }}
        data-testid={dataTestId}
      />
      <Typography
        sx={{
          px: 2,
          color: error ? colors.red : colors.contentSecondary,
          fontSize: "12px",
        }}
      >
        {label}
      </Typography>
    </Stack>
  );
});

export default CustomInput;
