import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "config";
import { HurdlrTokenResponse } from "models/Requests";
import { getTokens } from "utils/auth";

const BOOKS_API_URL = `${config.api.URL}/banking/am`;

export interface GetHurdlrTokenRequest {
  companyId: string;
}

export interface GetHurdlrUnitOptInRequest {
  companyId: string;
  value: boolean;
}

export const createHurdlerAccount = async (
  companyId: string
): Promise<HurdlrTokenResponse> => {
  const url = `${BOOKS_API_URL}/v1/hurdlr/create/${companyId}`;
  const { idToken } = getTokens();
  return new Promise<HurdlrTokenResponse>((resolve) => {
    const headers = { authorization: idToken };
    fetch(url, { headers: headers, method: "POST" }).then((resp) => {
      resolve(resp.json());
    });
  });
};

export const booksApi = createApi({
  reducerPath: "doolaBookeepingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BOOKS_API_URL,
    prepareHeaders: async (headers) => {
      try {
        const { idToken } = getTokens();
        headers.set("authorization", idToken);
      } catch (e) {
        console.log(e);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getHurdlerTokens: builder.mutation<
      HurdlrTokenResponse,
      GetHurdlrTokenRequest
    >({
      query: ({ companyId }) => ({
        url: `/v1/hurdlr/${companyId}`,
        method: "GET",
      }),
      transformResponse: (res: HurdlrTokenResponse) => {
        return res;
      },
      transformErrorResponse: (res) => {
        return res;
      },
    }),
    transferUnitTransactions: builder.mutation<
      HurdlrTokenResponse,
      GetHurdlrTokenRequest
    >({
      query: ({ companyId }) => ({
        url: `/v1/hurdlr/migrate-unit-transactions/${companyId}`,
        method: "POST",
      }),
      transformResponse: (res: HurdlrTokenResponse) => {
        return res;
      },
      transformErrorResponse: (res) => {
        return res;
      },
    }),
    unitTransferTransactionsOptIn: builder.mutation<
      HurdlrTokenResponse,
      GetHurdlrUnitOptInRequest
    >({
      query: ({ companyId, value }) => ({
        url: `/v1/hurdlr/unit-tracking-opt-in/${companyId}?value=${value}`,
        method: "POST",
      }),
      transformResponse: (res: HurdlrTokenResponse) => {
        return res;
      },
      transformErrorResponse: (res) => {
        return res;
      },
    }),
    createHurdlerAccount: builder.mutation<
      HurdlrTokenResponse,
      GetHurdlrTokenRequest
    >({
      query: ({ companyId }) => ({
        url: `/v1/hurdlr/create/${companyId}`,
        method: "POST",
      }),
      transformResponse: (res: HurdlrTokenResponse) => {
        return res;
      },
      transformErrorResponse: (res) => {
        return res;
      },
    }),
  }),
});

export const {
  useGetHurdlerTokensMutation,
  useCreateHurdlerAccountMutation,
  useTransferUnitTransactionsMutation,
  useUnitTransferTransactionsOptInMutation,
} = booksApi;
