import { useState } from "react";
import {
  Button,
  Chip,
  MenuItem,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { SignupSelect } from "views/SignupFlow/styles";
import { getStateOptions } from "services";
import { EntityType } from "graphql/__generated__/types";
import stateKeys from "resources/stateKeys";
import utils, { colors } from "utils";
import { QuizButton, TextQuizButton } from "./components/QuizButton";
import { QuestionWrapper } from "./components/QuestionWrapper";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { FinalStep } from "./components/FinalStep";
import { usePostHog } from "posthog-js/react";

export type Props = {
  onClose: (stateAbbr?: string, entityType?: EntityType) => void;
};

const EntityTypeQuiz: React.FC<Props> = ({ onClose }) => {
  const posthog = usePostHog();
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const [step, setStep] = useState(1);
  const [stepThreeAnswer, setStepThreeAnswer] = useState<
    "" | "mix" | "international" | "usa"
  >("");
  const [state, setState] = useState("");
  const [entityType, setEntityType] = useState<undefined | EntityType>();

  const capturePosthogEvent = () => {
    posthog?.capture("entity-quiz-complete", {
      quiz_entity_result: entityType,
      quiz_state_result: state,
    });
  };

  const StepOne = () => {
    return (
      <QuestionWrapper
        questionNumber={1}
        title={
          "Are you planning to raise venture capital or funding from investors?"
        }
        onClose={() => onClose(state, entityType)}
      >
        <Stack gap={1}>
          <QuizButton
            onClick={() => {
              setStep(2);
              setEntityType(EntityType.CCORP);
              setState("DE");
            }}
            text="Yes"
          />
          <QuizButton onClick={() => setStep(2)} text="No" />
        </Stack>
        <TextQuizButton onClick={() => setStep(2)} />
      </QuestionWrapper>
    );
  };

  const StepTwo = () => {
    return (
      <QuestionWrapper
        questionNumber={2}
        title={
          "Are you planning to issue stocks to employees or other stakeholders?"
        }
        onClose={() => onClose(state, entityType)}
      >
        <Stack gap={1}>
          <QuizButton
            onClick={() => {
              setStep(3);
              setEntityType(EntityType.CCORP);
              setState("DE");
            }}
            text="Yes"
          />
          <QuizButton
            onClick={() => {
              setStep(3);
              setEntityType(EntityType.LLC);
            }}
            text="No"
          />
        </Stack>
        <TextQuizButton
          onClick={() => {
            setStep(3);
            setEntityType(EntityType.LLC);
          }}
        />
      </QuestionWrapper>
    );
  };

  const StepThree = () => {
    if (entityType === EntityType.CCORP) {
      capturePosthogEvent();

      return (
        <FinalStep
          titleElement={
            <Typography variant="h5" fontWeight={500} color={colors.white}>
              We recommend incorporating a{" "}
              <Chip
                label="C-Corp"
                sx={{
                  fontSize: 16,
                  color: colors.white,
                  background: "#4f6f6f",
                  borderRadius: 2,
                }}
              />{" "}
              in{" "}
              <Chip
                label="Delaware"
                sx={{
                  fontSize: 16,
                  color: colors.white,
                  background: "#4f6f6f",
                  borderRadius: 2,
                }}
              />
            </Typography>
          }
          text="Delaware is the only state in the US with laws and regulations
              that allow C-Corps to raise venture capital and issue shares."
          onClose={() => onClose(state, entityType)}
          onReset={() => {
            setStep(1);
            setStepThreeAnswer("");
            setState("");
            setEntityType(undefined);
          }}
        />
      );
    }

    return (
      <QuestionWrapper
        questionNumber={3}
        title={"Where are the founding member(s) based out of?"}
        onClose={() => onClose(state, entityType)}
      >
        <Stack gap={1}>
          <QuizButton
            onClick={() => {
              setStep(4);
              setStepThreeAnswer("usa");
            }}
            text="USA"
          />
          <QuizButton
            onClick={() => {
              setStep(4);
              setStepThreeAnswer("international");
              setState("WY");
            }}
            text="International (Non-US)"
          />
        </Stack>
        <TextQuizButton
          onClick={() => {
            setStep(4);
            setStepThreeAnswer("mix");
          }}
          text="Mix of both"
        />
      </QuestionWrapper>
    );
  };

  const StepFour = () => {
    if (stepThreeAnswer === "international") {
      capturePosthogEvent();

      return (
        <FinalStep
          titleElement={
            <Typography variant="h5" fontWeight={500} color={colors.white}>
              We recommend incorporating an{" "}
              <Chip
                label="LLC"
                sx={{
                  fontSize: 16,
                  color: colors.white,
                  background: "#4f6f6f",
                  borderRadius: 2,
                }}
              />{" "}
              in the state of{" "}
              <Chip
                label="Wyoming"
                sx={{
                  fontSize: 16,
                  color: colors.white,
                  background: "#4f6f6f",
                  borderRadius: 2,
                }}
              />
            </Typography>
          }
          text="Wyoming has the friendliest laws and amongst the lowest-priced
            state fees."
          onClose={() => onClose(state, entityType)}
          onReset={() => {
            setStep(1);
            setStepThreeAnswer("");
            setState("");
            setEntityType(undefined);
          }}
        />
      );
    }

    return (
      <QuestionWrapper
        questionNumber={4}
        title={"In which US State do you have a physical presence?"}
        onClose={() => onClose(state, entityType)}
      >
        <Stack>
          <Typography
            variant="h6"
            pb={1}
            color={utils.secondaryColors.secondaryMain}
          >
            Select State
          </Typography>
          <SignupSelect
            displayEmpty
            value={state}
            onChange={(e) => setState(e.target.value as string)}
          >
            <MenuItem disabled value="">
              Select State
            </MenuItem>
            {getStateOptions(EntityType.LLC).map(({ name, value }, index) => {
              return (
                <MenuItem key={index} value={value}>
                  {name}
                </MenuItem>
              );
            })}
          </SignupSelect>
        </Stack>
        <Stack alignItems={"flex-end"}>
          <Button
            disableElevation
            variant="contained"
            onClick={() => setStep(5)}
            disabled={!state}
            endIcon={<KeyboardArrowRightRoundedIcon fontSize="small" />}
            sx={{
              width: 100,
              height: 48,
              borderRadius: "30px",
              bgcolor: colors.grey,
              color: colors.black,
              "&:hover": {
                bgcolor: colors.darkGrey,
              },
            }}
          >
            Next
          </Button>
        </Stack>
      </QuestionWrapper>
    );
  };

  const StepFive = () => {
    capturePosthogEvent();

    return (
      <FinalStep
        titleElement={
          <Typography variant="h5" fontWeight={500} color={colors.white}>
            We recommend incorporating an{" "}
            <Chip
              label="LLC"
              sx={{
                fontSize: 16,
                color: colors.white,
                background: "#4f6f6f",
                borderRadius: 2,
              }}
            />{" "}
            in the state of{" "}
            <Chip
              label={stateKeys[state]}
              sx={{
                fontSize: 16,
                color: colors.white,
                background: "#4f6f6f",
                borderRadius: 2,
              }}
            />{" "}
            since you live/have a physical presence there.
          </Typography>
        }
        text={`If you incorporate in another state and have a physical presence in
          ${stateKeys[state]} you will have to pay taxes in both states!`}
        onClose={() => onClose(state, entityType)}
        onReset={() => {
          setStep(1);
          setStepThreeAnswer("");
          setState("");
          setEntityType(undefined);
        }}
      />
    );
  };

  const renderScreen = () => {
    if (step === 1) {
      return <StepOne />;
    }

    if (step === 2) {
      return <StepTwo />;
    }

    if (step === 3) {
      return <StepThree />;
    }

    if (step === 4) {
      return <StepFour />;
    }

    if (step === 5) {
      return <StepFive />;
    }
  };

  return (
    <Modal open onClose={() => onClose(state, entityType)}>
      <Stack
        sx={{
          position: "absolute",
          top: isTablet ? "0" : "50%",
          left: isTablet ? "0" : "50%",
          ...(!isTablet && { transform: "translate(-50%, -50%)" }),
          ...(isTablet && { width: "100%" }),
          height: isTablet ? "100%" : "auto",
          boxShadow: 24,
          borderRadius: isTablet ? 0 : 8,
          overflow: "scroll",
        }}
      >
        {renderScreen()}
      </Stack>
    </Modal>
  );
};

export default EntityTypeQuiz;
