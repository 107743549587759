import { Accordion, AccordionSummary } from "@mui/material";
import styled from "styled-components";

export const StyledAccordion = styled(Accordion)`
  background: none;
  border: none;
  box-shadow: none;

  :before {
    display: none;
  }

  &.Mui-expanded {
    margin-top: 0;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  min-height: auto;
  padding: 0;

  &.Mui-expanded {
    min-height: auto;
  }

  .MuiAccordionSummary-content {
    margin: 0;
  }
`;
