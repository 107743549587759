import {
  FormControlLabel,
  Checkbox,
  Stack,
  Typography,
  useTheme,
  Radio,
  CheckboxProps,
  RadioProps,
  Chip,
} from "@mui/material";
import { colors } from "utils";

interface IFormControlElementProps {
  title: string;
  subtitle?: string;
  optionValue: string;
  selectedValue: null | string;
  changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: "radio" | "checkbox";
  chipText?: string;
}

const FormControlElement: React.FC<IFormControlElementProps> = ({
  title,
  subtitle,
  optionValue,
  selectedValue,
  type = "radio",
  chipText,
  changeHandler,
}) => {
  const theme = useTheme();

  const Wrapper = (props: RadioProps | CheckboxProps) => {
    if (type === "radio") {
      return <Radio {...props} />;
    }

    return <Checkbox {...props} />;
  };

  return (
    <Stack spacing={1}>
      <FormControlLabel
        control={
          <Wrapper
            disableRipple
            name={title}
            checked={selectedValue === optionValue}
            onChange={changeHandler}
            value={optionValue}
            sx={{
              padding: 0,
              color: theme.palette.secondary.light,
              "&.Mui-checked": {
                color: colors.blue,
              },
            }}
          />
        }
        label={
          <Stack spacing={1}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography fontSize={16} fontWeight={500}>
                {title}
              </Typography>
              {chipText && (
                <Chip
                  label={chipText}
                  sx={{
                    height: 24,
                    background: colors.green,
                    color: colors.white,
                    padding: 0,
                    borderRadius: 2,
                  }}
                />
              )}
            </Stack>

            {subtitle && <Typography>{subtitle}</Typography>}
          </Stack>
        }
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
          gap: 1,
          padding: 3,
          margin: 0,
          borderRadius: "24px",
          bgcolor: colors.lightGrey,
          border: `1px solid ${colors.lightGrey}`,
          ...(selectedValue === optionValue && {
            background: colors.lightBlue,
            border: `1px solid ${colors.borderBlue}`,
          }),
          transition: "all .1s ease-out",
          "& .MuiFormControlLabel-label": {
            width: "100%",
          },
        }}
      />
    </Stack>
  );
};

export default FormControlElement;
