import { IStepProps } from "../../types";
import ButtonBox from "components/ButtonBox";
import { AddBeneficiarySteps } from "../../data";
import {
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  CorporateFareOutlined,
  EmailOutlined,
  PersonOutlined,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import utils, { colors } from "utils";
import { useEffect, useState } from "react";
import FormControlElement from "views/SignupFlow/components/FormControlElement";
import { Controller, useForm } from "react-hook-form";
import {
  BeneficiaryInformationDetails,
  INITIAL_BENEFICIARY_INFORMATION_VALUES,
} from "./helpers";
import { AccountType, LookupTypeKey } from "graphql/__generated__/types";
import { Windows1252Regex } from "utils/regexValidations";
import CustomInput from "components/Form/CustomInput";
import useGetMoneyLookups from "views/DashboardApp/Money/hooks/useGetMoneyLookups";
import CustomSelect from "components/Form/CustomSelect";
import { LoadingComponent } from "components/Loading";
import { US_KEY, isValueValid } from "views/DashboardApp/Money/helpers";

interface IBeneficiaryInformationProps extends IStepProps {
  onCloseModalHandler: () => void;
}

const BeneficiaryInformation = ({
  setCurrentStep,
  onCloseModalHandler,
  beneficiaryData,
  setBeneficiaryData,
  beneficiaryHashId,
}: IBeneficiaryInformationProps) => {
  const isEditMode = !!beneficiaryHashId;

  const [isIndividual, setIsIndividual] = useState<boolean>(true);

  const { t } = useTranslation();
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const { lookupsData, isLookupsDataLoading } = useGetMoneyLookups({
    neededLookups: [LookupTypeKey.COUNTRYOFOPERATION, LookupTypeKey.STATE],
  });

  const { control, formState, handleSubmit, trigger, setValue, watch } =
    useForm<BeneficiaryInformationDetails>({
      defaultValues: { ...INITIAL_BENEFICIARY_INFORMATION_VALUES },
      mode: "onChange",
    });

  const country = watch("beneficiaryCountryCode");

  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  useEffect(() => {
    setIsIndividual(
      beneficiaryData.beneficiaryAccountType === AccountType.INDIVIDUAL
    );

    setValue("beneficiaryName", beneficiaryData.beneficiaryName || "");
    setValue("beneficiaryAddress", beneficiaryData.beneficiaryAddress || "");
    setValue(
      "beneficiaryCountryCode",
      beneficiaryData.beneficiaryCountryCode || ""
    );
    setValue("beneficiaryCity", beneficiaryData.beneficiaryCity || "");
    setValue("beneficiaryState", beneficiaryData.beneficiaryState || "");
    setValue("beneficiaryPostcode", beneficiaryData.beneficiaryPostcode || "");

    if (
      isEditMode &&
      isValueValid(beneficiaryData.beneficiaryName) &&
      isValueValid(beneficiaryData.beneficiaryAddress) &&
      isValueValid(beneficiaryData.beneficiaryCountryCode) &&
      isValueValid(beneficiaryData.beneficiaryCity) &&
      isValueValid(beneficiaryData.beneficiaryState) &&
      isValueValid(beneficiaryData.beneficiaryPostcode)
    ) {
      trigger();
    }
  }, [beneficiaryData, isEditMode, setValue, trigger]);

  if (!lookupsData || isLookupsDataLoading) {
    return (
      <Stack height={"200px"} alignItems={"center"} justifyContent={"center"}>
        <LoadingComponent />
      </Stack>
    );
  }

  const onSubmitHandler = (
    beneficiaryInformation: BeneficiaryInformationDetails
  ) => {
    setBeneficiaryData({
      ...beneficiaryData,
      ...{
        ...beneficiaryInformation,
        beneficiaryAccountType: isIndividual
          ? AccountType.INDIVIDUAL
          : AccountType.CORPORATE,
      },
    });

    setCurrentStep(AddBeneficiarySteps.transferMethodInformation);
  };

  const isDisabled = !formState.isValid;

  return (
    <Stack>
      <Stack direction={"row"} alignItems={"center"} gap={1.5}>
        <WorkOutlineOutlined sx={{ fontSize: "20px" }} />
        <Typography fontSize={16} fontWeight={500}>
          {t("money.beneficiary.add.title")}
        </Typography>
      </Stack>
      <Typography fontSize={16} color={colors.contentTertiary} mt={1}>
        {t("money.beneficiary.add.subTitle")}
      </Typography>
      <Stack mt={3}>
        <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
          <Stack mb={isTablet ? 20 : 4}>
            <Stack gap={4}>
              {/* Radio button section */}
              <Stack
                direction={isTablet ? "column" : "row"}
                gap={isTablet ? 2 : 3}
                width={"100%"}
              >
                <FormControlElement
                  isDisabled={isEditMode}
                  outerStyles={{ width: "100%" }}
                  optionValue={true}
                  selectedValue={isIndividual}
                  changeHandler={() => setIsIndividual(true)}
                  content={
                    <Stack
                      width={"100%"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={1.5}
                    >
                      <Stack
                        borderRadius={"50%"}
                        bgcolor={isIndividual ? colors.borderBlue : colors.grey}
                        p={2}
                      >
                        <PersonOutlined />
                      </Stack>
                      <Typography
                        fontWeight={500}
                        fontSize={16}
                        textAlign={"center"}
                      >
                        {t("general.individual")}
                      </Typography>
                    </Stack>
                  }
                />
                <FormControlElement
                  isDisabled={isEditMode}
                  outerStyles={{ width: "100%" }}
                  optionValue={false}
                  selectedValue={isIndividual}
                  changeHandler={() => setIsIndividual(false)}
                  content={
                    <Stack
                      width={"100%"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={1.5}
                    >
                      <Stack
                        borderRadius={"50%"}
                        bgcolor={
                          !isIndividual ? colors.borderBlue : colors.grey
                        }
                        p={2}
                      >
                        <CorporateFareOutlined />
                      </Stack>
                      <Typography
                        fontWeight={500}
                        fontSize={16}
                        textAlign={"center"}
                      >
                        {t("general.company")}
                      </Typography>
                    </Stack>
                  }
                />
              </Stack>
              {/* Information Section */}
              <Stack gap={3}>
                <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                  <PersonOutlined sx={{ fontSize: "20px" }} />
                  <Typography fontSize={16} fontWeight={500}>
                    {t("general.information")}
                  </Typography>
                </Stack>
                <Controller
                  name="beneficiaryName"
                  {...COMMON_PROPS}
                  rules={{
                    required: true,
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      placeholder={
                        t(
                          "money.beneficiary.recipientName.placeholder"
                        ) as string
                      }
                      label={t("money.beneficiary.recipientName") as string}
                    />
                  )}
                />
              </Stack>
              {/* Address Section */}
              <Stack gap={isTablet ? 2 : 3}>
                <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                  <EmailOutlined sx={{ fontSize: "20px" }} />
                  <Typography fontSize={16} fontWeight={500}>
                    {t("general.address")}
                  </Typography>
                </Stack>
                <Stack gap={2}>
                  <Stack
                    direction={isTablet ? "column" : "row"}
                    gap={isTablet ? 2 : 3}
                  >
                    <Controller
                      name="beneficiaryAddress"
                      {...COMMON_PROPS}
                      rules={{
                        required: true,
                        pattern: {
                          value: Windows1252Regex,
                          message: t("error.invalidCharacters"),
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          {...field}
                          error={error !== undefined}
                          styles={{ width: "100%" }}
                          placeholder={
                            t("money.beneficiary.address.placeholder") as string
                          }
                          label={t("money.beneficiary.address") as string}
                        />
                      )}
                    />
                  </Stack>
                  <Stack
                    direction={isTablet ? "column" : "row"}
                    gap={isTablet ? 2 : 3}
                  >
                    {lookupsData?.COUNTRYOFOPERATION && (
                      <Controller
                        name="beneficiaryCountryCode"
                        {...COMMON_PROPS}
                        rules={{
                          pattern: {
                            value: Windows1252Regex,
                            message: t("error.invalidCharacters"),
                          },
                          required: true,
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <CustomSelect
                            {...field}
                            error={error !== undefined}
                            styles={{ width: "100%" }}
                            placeholder={
                              t(
                                "money.beneficiary.country.placeholder"
                              ) as string
                            }
                            label={t("money.beneficiary.country") as string}
                            value={country}
                            defaultValue={field.value}
                            onChange={(e: SelectChangeEvent<unknown>) => {
                              field.onChange(e.target.value);

                              setValue("beneficiaryState", "");
                            }}
                          >
                            {lookupsData.COUNTRYOFOPERATION.map(
                              (item, index) => (
                                <MenuItem key={index} value={item?.key ?? ""}>
                                  {item.description}
                                </MenuItem>
                              )
                            )}
                          </CustomSelect>
                        )}
                      />
                    )}
                    <Controller
                      name="beneficiaryCity"
                      {...COMMON_PROPS}
                      rules={{
                        required: true,
                        pattern: {
                          value: Windows1252Regex,
                          message: t("error.invalidCharacters"),
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          {...field}
                          error={error !== undefined}
                          styles={{ width: "100%" }}
                          placeholder={
                            t("money.beneficiary.city.placeholder") as string
                          }
                          label={t("money.beneficiary.city") as string}
                        />
                      )}
                    />
                  </Stack>
                  <Stack
                    direction={isTablet ? "column" : "row"}
                    gap={isTablet ? 2 : 3}
                  >
                    {lookupsData?.STATE && (
                      <Controller
                        name="beneficiaryState"
                        {...COMMON_PROPS}
                        rules={{
                          pattern: {
                            value: Windows1252Regex,
                            message: t("error.invalidCharacters"),
                          },
                          required: true,
                        }}
                        render={({ field, fieldState: { error } }) =>
                          country === US_KEY ? (
                            <CustomSelect
                              {...field}
                              error={error !== undefined}
                              styles={{ width: "100%" }}
                              placeholder={
                                t(
                                  "money.beneficiary.state.select.placeholder"
                                ) as string
                              }
                              label={t("money.beneficiary.state") as string}
                              defaultValue={field.value}
                              onChange={(e: SelectChangeEvent<unknown>) => {
                                field.onChange(e.target.value);
                              }}
                            >
                              {lookupsData.STATE.map((item, index) => (
                                <MenuItem key={index} value={item?.key ?? ""}>
                                  {item.description}
                                </MenuItem>
                              ))}
                            </CustomSelect>
                          ) : (
                            <CustomInput
                              {...field}
                              error={error !== undefined}
                              styles={{ width: "100%" }}
                              placeholder={
                                t(
                                  "money.beneficiary.state.enter.placeholder"
                                ) as string
                              }
                              label={t("money.beneficiary.state") as string}
                            />
                          )
                        }
                      />
                    )}
                    <Controller
                      name="beneficiaryPostcode"
                      {...COMMON_PROPS}
                      rules={{
                        required: true,
                        pattern: {
                          value: Windows1252Regex,
                          message: t("error.invalidCharacters"),
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          {...field}
                          error={error !== undefined}
                          styles={{ width: "100%" }}
                          placeholder={
                            t("money.beneficiary.zipCode.placeholder") as string
                          }
                          label={t("money.beneficiary.zipCode") as string}
                        />
                      )}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          {/* Submit button section */}
          <ButtonBox
            disabled={isDisabled}
            leftIcon="close"
            onClickBack={onCloseModalHandler}
            onClickNext={() => onSubmitHandler}
          />
        </form>
      </Stack>
    </Stack>
  );
};

export default BeneficiaryInformation;
