import { useCallback, useEffect, useState } from "react";
import { useGetMoneyCurrencyFeeInfoLazyQuery } from "graphql/__generated__/operations/MoneyCurrencyFeeInfo.generated";
import useCompany from "graphql/hooks/UseCompany";
import { FeeInfo } from "graphql/__generated__/types";

interface ICurrencyFeeInfo {
  getCurrencyFeeInfo: (countryCode: string) => Promise<void>;
  currencyFeeInfoData?: FeeInfo | null;
  isCurrencyFeeInfoDataLoading: boolean;
}

export default function useGetCurrencyFeeInfo(
  currencyCode?: string
): ICurrencyFeeInfo {
  const { currentCompany } = useCompany();

  const [isCurrencyFeeInfoDataLoading, setIsCurrencyFeeInfoDataLoading] =
    useState<boolean>(false);
  const [currencyFeeInfoData, setCurrencyFeeInfoData] =
    useState<FeeInfo | null>();

  const [fetchCurrencyFeeInfo] = useGetMoneyCurrencyFeeInfoLazyQuery({
    errorPolicy: "all",
  });

  const getCurrencyFeeInfo = useCallback(
    async (currencyCode: string) => {
      setIsCurrencyFeeInfoDataLoading(true);

      try {
        const { data: currencyFeeInfoData } = await fetchCurrencyFeeInfo({
          variables: {
            currencyCode,
          },
        });

        const currencyFeeInfo = currencyFeeInfoData?.currencyFeeInfo?.payload;

        if (currencyFeeInfo) {
          setCurrencyFeeInfoData(currencyFeeInfo);
        }
      } catch (error) {
        console.log("Failed to fetch currencyFeeInfo data, error: ", error);
      } finally {
        setIsCurrencyFeeInfoDataLoading(false);
      }
    },
    [fetchCurrencyFeeInfo]
  );

  useEffect(() => {
    if (currencyCode && currentCompany?.id) {
      getCurrencyFeeInfo(currencyCode);
    }
  }, [getCurrencyFeeInfo, currencyCode, currentCompany?.id]);

  return {
    getCurrencyFeeInfo,
    currencyFeeInfoData,
    isCurrencyFeeInfoDataLoading,
  };
}
