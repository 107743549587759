import { ToastContainer as ToastContainer_ } from "react-toastify";
import styled from "styled-components";

export const ToastContainer = styled(ToastContainer_)`
  &&&.Toastify__toast-container {
  }
  .Toastify__toast {
    min-height: 0;
    padding: 0;
  }
  .Toastify__toast-body {
    padding: 0;
  }
  .Toastify__toast-icon {
    display: none;
  }
`;
