import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { LinkRouterProps } from "./types";

export const LinkRouter: FunctionComponent<LinkRouterProps> = ({
  to,
  children,
}) => (
  <Link to={to} style={{ textDecoration: "none", color: "inherit" }}>
    {children}
  </Link>
);
