import * as Types from '../types';

import { gql } from '@apollo/client';
import { CompanyMembersInfoFragmentDoc } from './Company.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveCompanyMemberMutationVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  position: Types.Scalars['Int'];
}>;


export type RemoveCompanyMemberMutation = { __typename?: 'Mutation', company?: { __typename?: 'CompanyMutation', removeMember?: { __typename?: 'Company', id: string, members?: Array<{ __typename?: 'Member', legalFirstName: string, legalLastName: string, contactFullName?: string | null, ownershipPercent?: number | null, isNaturalPerson: boolean, ssn?: string | null, position?: number | null, address: { __typename?: 'Address', line1: string, line2?: string | null, state: string, city: string, phone?: string | null, postalCode: string, country: Types.Iso31661Alpha3 } }> | null, services?: Array<{ __typename?: 'Services', service: Types.CompanyServices, variant?: Types.ServiceVariant | null, status: Types.ServiceCategory, subStatus?: string | null }> | null } | null } | null };


export const RemoveCompanyMemberDocument = gql`
    mutation RemoveCompanyMember($companyId: ID!, $position: Int!) {
  company(id: $companyId) {
    removeMember(position: $position) {
      id
      members {
        ...CompanyMembersInfo
      }
      services {
        service
        variant
        status
        subStatus
      }
    }
  }
}
    ${CompanyMembersInfoFragmentDoc}`;
export type RemoveCompanyMemberMutationFn = Apollo.MutationFunction<RemoveCompanyMemberMutation, RemoveCompanyMemberMutationVariables>;

/**
 * __useRemoveCompanyMemberMutation__
 *
 * To run a mutation, you first call `useRemoveCompanyMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompanyMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompanyMemberMutation, { data, loading, error }] = useRemoveCompanyMemberMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useRemoveCompanyMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCompanyMemberMutation, RemoveCompanyMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCompanyMemberMutation, RemoveCompanyMemberMutationVariables>(RemoveCompanyMemberDocument, options);
      }
export type RemoveCompanyMemberMutationHookResult = ReturnType<typeof useRemoveCompanyMemberMutation>;
export type RemoveCompanyMemberMutationResult = Apollo.MutationResult<RemoveCompanyMemberMutation>;
export type RemoveCompanyMemberMutationOptions = Apollo.BaseMutationOptions<RemoveCompanyMemberMutation, RemoveCompanyMemberMutationVariables>;