import styled from "styled-components";
import { colors } from "utils";

export const HelperIconWrapper = styled.div<{ $styledCss: any }>`
  width: fit-content;
  cursor: pointer;
  display: inline-block;
  ${({ $styledCss }) => $styledCss}
`;

export const ToolTipWrapper = styled.div`
  padding: 20px 15px;
  display: flex;
`;

export const InfoIconWrapper = styled.div`
  font-size: 16px;
  color: ${colors.green};
  margin: 0px 10px 10px 0px;
`;
