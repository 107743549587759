import {
  Button,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import utils, { colors } from "utils";
import CloseIcon from "@mui/icons-material/Close";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";

interface IFinalStep {
  titleElement: JSX.Element;
  text: string;
  onClose: () => void;
  onReset: () => void;
}

export const FinalStep = ({
  titleElement,
  text,
  onClose,
  onReset,
}: IFinalStep) => {
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);

  return (
    <>
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          top: isTablet ? 32 : 24,
          right: isTablet ? 32 : 24,
        }}
      >
        <CloseIcon sx={{ color: colors.white }} />
      </IconButton>
      <Stack
        sx={{
          width: isTablet ? "100%" : 400,
          ...(isTablet && { height: "100%" }),
          p: 3,
          bgcolor: colors.darkGreen,
        }}
      >
        <Stack
          mt={isTablet ? 8 : 0}
          sx={{ textAlign: isTablet ? "center" : "left" }}
        >
          <Stack
            alignSelf={isTablet ? "center" : "flex-start"}
            sx={{ ...(isTablet && { width: "300px" }) }}
          >
            <DoneRoundedIcon
              sx={{
                width: 54,
                height: 54,
                background: colors.white,
                borderRadius: "50%",
                p: "15px",
                margin: "8px auto 32px",
              }}
            />
            {titleElement}
            <Typography
              variant="body1"
              color={colors.contentInverseSecondary}
              mt={2}
              mb={3}
            >
              {text}
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            gap={1}
            sx={{
              ...(isTablet && {
                position: "fixed",
                left: 0,
                bottom: 0,
                width: "100%",
                bgcolor: colors.darkGreen,
                borderTop: `1px solid #476969`,
                zIndex: 1,
                py: isMobile ? 1 : 2,
                px: isMobile ? 2 : 3,
              }),
            }}
          >
            <IconButton
              onClick={onReset}
              sx={{
                width: 48,
                height: 48,
                color: colors.white,
                background: colors.contentSecondary,
                borderRadius: "50%",
                padding: "12px",
                "&:hover": {
                  background: colors.grey,
                  color: colors.black,
                },
              }}
            >
              <RefreshRoundedIcon
                sx={{ transform: "scaleX(-1) rotate(-90deg)" }}
              />
            </IconButton>
            <Button
              onClick={onClose}
              variant="contained"
              sx={{
                width: 120,
                borderRadius: "24px",
                p: { md: "16px 24px", xs: "10px 16px" },
                background: colors.white,
                color: colors.black,
                boxShadow: "none",
              }}
            >
              <Typography fontWeight={500} lineHeight={"16px"}>
                Done
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
