import styled, { css } from "styled-components";
import utils, { colors } from "utils";
import { CustomButtonProps, CustomButtonVariants } from "./types";

export const ButtonWrapper = styled.button<CustomButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  width: ${({ width }) => width ?? "fit-content"};
  border: none;
  border-radius: ${({ $roundedBorders }) => ($roundedBorders ? "24px" : "8x")};
  padding: ${utils.constants.units.main}px ${utils.constants.units.main * 3}px;
  opacity: ${({ $loading }) => ($loading ? "50%" : "100%")};
  cursor: pointer;
  ${({ $variant }) => additionalStyles($variant)};
  transition: all 0.3s ease-out;

  &:hover {
    box-shadow: 3px 3px 2px rgb(0 0 0 / 10%);

    ${({ $variant }) =>
      $variant === CustomButtonVariants.black &&
      css`
        background: #595959;
      `}
    ${({ $variant }) =>
      $variant === CustomButtonVariants.green &&
      css`
        background: #2e7d32;
      `}
      ${({ $variant }) =>
      $variant === CustomButtonVariants.pureBlack &&
      css`
        background: ${colors.charcoal};
      `}
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: #d9d9d9;
      background: #f5f5f5;
      border: 1px solid #d9d9d9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      cursor: not-allowed;

      &:hover {
        box-shadow: none;
        background: #f5f5f5;
      }
    `}
`;

function additionalStyles(variant: CustomButtonVariants) {
  switch (variant) {
    case CustomButtonVariants.primary:
      return css`
        background-color: ${colors.green};
        color: white;
      `;
    case CustomButtonVariants.inversedPrimary:
      return css`
        background-color: white;
        color: ${colors.green};
      `;
    case CustomButtonVariants.black:
      return css`
        background-color: ${colors.charcoal};
        color: white;
        border: 1px solid ${colors.charcoal};
      `;
    case CustomButtonVariants.pureBlack:
      return css`
        background-color: ${colors.black};
        color: white;
        border: 1px solid ${colors.black};
      `;
    case CustomButtonVariants.inversedBlack:
      return css`
        background-color: white;
        color: ${colors.charcoal};
        border: 1px solid #d9d9d9;

        &:hover {
          background-color: ${colors.charcoal};
          color: white;
        }
      `;
    case CustomButtonVariants.green:
      return css`
        background-color: ${colors.green};
        color: white;
      `;
  }
}
