import { WarningAmberRounded } from "@mui/icons-material";
import { List, ListItem, Stack, SxProps, Typography } from "@mui/material";
import { t } from "i18next";
import { FieldValues, FormState } from "react-hook-form";
import { colors } from "utils";

type Props = { formState: FormState<FieldValues>; style?: SxProps };

const ErrorBox = ({ formState, style }: Props) => {
  const hasErrors = Object.keys(formState.errors).length > 0;

  if (!hasErrors) {
    return null;
  }

  const uniqueErrors = new Set<string>();
  const uniqueErrorList: { key: string; message: string }[] = [];

  Object.entries(formState.errors).forEach(([key, value]) => {
    const extractErrorMessage = (errorObj: any): string => {
      // If the errorObj has a message property, return its value as the error message
      if (errorObj?.message) {
        return errorObj.message;
      }
      // If the errorObj is an object, recursively search for an error message in its properties
      else if (typeof errorObj === "object") {
        for (const prop in errorObj) {
          return extractErrorMessage(errorObj[prop]);
        }
      }
      // If no error message is found, return a default missing information error message
      return t("error.missingInformation");
    };

    const errorMessage = extractErrorMessage(value);

    // Check if the error message is unique and add it to the uniqueErrors set
    if (!uniqueErrors.has(errorMessage)) {
      uniqueErrors.add(errorMessage);
      uniqueErrorList.push({ key, message: errorMessage });
    }
  });

  return (
    <Stack
      sx={{ bgcolor: colors.lightRed, borderRadius: 6, p: 3, ...style }}
      gap={2}
    >
      <WarningAmberRounded />
      <List sx={{ p: 0 }}>
        {uniqueErrorList.map(({ message }, index) => (
          <ListItem key={index} sx={{ p: 0 }}>
            <Typography variant="body2" fontSize={"1rem"} component="div">
              <span>&ndash;</span> {message}
            </Typography>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

export default ErrorBox;
