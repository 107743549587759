import { Box, Stack } from "@mui/material";
import MuiAvatar from "@mui/material/Avatar";
import React from "react";
import { colors } from "utils";
import { getInitials } from "utils/formatters";

type Props = {
  name: string;
  countryCode?: string | null;
};

const avatarBgColors = [
  colors.darkAccentYellow,
  colors.borderBlue,
  colors.lightAccentGreen,
  colors.grey,
  colors.lightAccentRed,
];

const Avatar = ({ name, countryCode }: Props) => {
  const avatarRandomBgColor =
    avatarBgColors[Math.floor(Math.random() * avatarBgColors.length)];

  return (
    <Stack sx={{ position: "relative" }}>
      <MuiAvatar
        sx={{
          bgcolor: avatarRandomBgColor,
          fontSize: 14,
          fontWeight: 500,
          color: colors.black,
          width: "48px",
          height: "48px",
        }}
        {...getInitials(name)}
      />
      {countryCode && (
        <Box
          sx={{
            position: "absolute",
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            left: 32,
            top: 32,
          }}
        >
          <img
            loading="lazy"
            src={`https://flagcdn.com/w20/${countryCode.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${countryCode.toLowerCase()}.png 2x`}
            alt=""
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "50%",
            }}
          />
        </Box>
      )}
    </Stack>
  );
};

export const MemoizedAvatar = React.memo(Avatar);
