import { useEffect, useState } from "react";
import useCompany from "graphql/hooks/UseCompany";
import { CompanyServices } from "graphql/__generated__/types";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import { LoadingComponent } from "components/Loading";
import useGetMoneyAccounts from "./hooks/useGetMoneyAccounts";

const removeTrailingSlash = (input: string) => {
  return input.replace(/\/$/, ""); // Removes trailing slash if present
};

const MoneyGateway: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const currentPathName = removeTrailingSlash(useLocation().pathname);

  const [isLoading, setIsLoading] = useState(true);
  const { currentCompany } = useCompany();
  const { accountsResponse, isAccountsDataLoading, isPendingReview } =
    useGetMoneyAccounts();

  useEffect(() => {
    if (currentCompany && accountsResponse) {
      const moneyService = currentCompany.services?.find(
        ({ service }) => service === CompanyServices.MONEY
      );

      if (!moneyService) {
        return navigate(ROUTER_PATHS.DASHBOARD);
      }

      if (currentPathName === ROUTER_PATHS.MONEY_ONBOARDING) {
        if (accountsResponse.payload || isPendingReview) {
          return navigate(ROUTER_PATHS.MONEY_OVERVIEW);
        }
      } else if (currentPathName === ROUTER_PATHS.MONEY) {
        if (accountsResponse.payload) {
          return navigate(ROUTER_PATHS.MONEY_OVERVIEW);
        }
      } else {
        if (!accountsResponse.payload) {
          return navigate(ROUTER_PATHS.MONEY);
        }
      }

      setIsLoading(false);
    }
  }, [
    accountsResponse,
    currentCompany,
    currentPathName,
    navigate,
    isPendingReview,
  ]);

  if (
    !currentCompany ||
    !accountsResponse ||
    isAccountsDataLoading ||
    isLoading
  ) {
    return <LoadingComponent />;
  }

  return <>{children}</>;
};

export default MoneyGateway;
