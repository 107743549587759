import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import {
  Container,
  Toolbar,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import useConfig from "hooks/useConfig";
import { LoadingComponent } from "components/Loading";
import Header from "./Header";
import { SidebarWebApp } from "./Sidebar";
import { ROUTER_PATHS } from "routes/routes";
import useCustomer from "graphql/hooks/useCustomer";
import useCompany from "graphql/hooks/UseCompany";
import {
  CompanyServices,
  DashboardStatus,
  ServiceCategory,
} from "graphql/__generated__/types";
import utils from "utils";
import DashboardAccessBlocker from "./DashboardAccessBlocker";
import { usePostHog } from "posthog-js/react";

export const DashboardLayout: React.FC<{}> = () => {
  const theme = useTheme();
  const posthog = usePostHog();

  const matchDownXL = useMediaQuery(theme.breakpoints.down("xl"));
  const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));

  const { miniDrawer } = useConfig();
  const { currentUser, isCustomerLoading } = useCustomer();
  const { currentCompany, isCompanyLoading } = useCompany();

  const [open, setOpen] = useState(!miniDrawer);

  const formationService = currentCompany?.services?.find(
    (s) => s.service === CompanyServices.FORMATION
  );

  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!matchDownXL);
    }
  }, [matchDownXL]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    document.title = utils.constants.title.user;
  }, []);

  const handleDrawerToggle = () => setOpen(!open);

  const hasCompany = !!currentUser?.companyIds?.length;

  const isLoading =
    isCustomerLoading || isCompanyLoading || !hasCompany || !formationService;

  if (isLoading) {
    return (
      <Wrapper>
        <LoadingComponent
          text={
            currentUser && !hasCompany
              ? "Unfortunately the user does not have a company associated"
              : !formationService && currentCompany && !isCompanyLoading
              ? "No formation service is found on a company"
              : "Loading..."
          }
        />
      </Wrapper>
    );
  }

  if (formationService) {
    const hasBeenFiled =
      formationService.status === ServiceCategory.INPROGRESS &&
      formationService.subStatus === "WaitingForRaToSucceed";

    const hasCompletedOnboarding =
      formationService.status === ServiceCategory.COMPLETED;

    // we need to redirect people to postpurchase flow if they haven't filed with state or completed their onboarding yet
    if (!hasBeenFiled && !hasCompletedOnboarding) {
      posthog?.capture("onboarding-start", {
        entity_type: currentCompany?.entityType,
        first_time: false,
      });

      return <Navigate to={ROUTER_PATHS.ONBOARDING_INTRO} />;
    }
  }

  const dashboardAccess = currentCompany?.dashboardStatus;
  const isAccessBlocked = dashboardAccess === DashboardStatus.BLOCKED;
  const isAccessBilling =
    dashboardAccess === DashboardStatus.CANCELLED ||
    dashboardAccess === DashboardStatus.PASTDUE;

  return (
    <Wrapper>
      {isAccessBlocked || isAccessBilling ? (
        <DashboardAccessBlocker />
      ) : (
        <>
          {matchDownLG && (
            <Header open={open} handleDrawerToggle={handleDrawerToggle} />
          )}
          <SidebarWebApp open={open} handleDrawerToggle={handleDrawerToggle} />
          <Box
            component="main"
            sx={{
              width: "calc(100% - 260px)",
              flexGrow: 1,
              p: { xs: 2, sm: 3 },
            }}
          >
            <Container
              maxWidth="xl"
              sx={{
                px: { xs: 0, sm: 2 },
                position: "relative",
                minHeight: "calc(100vh - 110px)",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Outlet />
            </Container>
          </Box>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <>
      {matchDownLG && <Toolbar />}
      <Box sx={{ display: "flex", width: "100%" }}>{children}</Box>
    </>
  );
};
