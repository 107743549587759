import { CustomButtonProps } from "./types";
import { ButtonWrapper } from "./styles";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Typography } from "@mui/material";

export const CustomButton: React.FC<CustomButtonProps> = ({
  loading,
  ...props
}) => {
  const propsToPass = {
    ...props,
    onClick: props.disabled || loading ? () => {} : props.onClick,
  };

  return (
    <ButtonWrapper $loading={loading} {...propsToPass}>
      {loading && <CircularProgress size={"1.2rem"} color="inherit" />}
      <Typography component={Box}>{props.children}</Typography>
    </ButtonWrapper>
  );
};
