import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AuthForgotPasswordMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type AuthForgotPasswordMutation = { __typename?: 'Mutation', auth?: { __typename?: 'AuthMutation', forgotPassword?: { __typename?: 'AuthResponse', success: boolean, message: string } | null } | null };


export const AuthForgotPasswordDocument = gql`
    mutation AuthForgotPassword($email: String!) {
  auth {
    forgotPassword(email: $email) {
      success
      message
    }
  }
}
    `;
export type AuthForgotPasswordMutationFn = Apollo.MutationFunction<AuthForgotPasswordMutation, AuthForgotPasswordMutationVariables>;

/**
 * __useAuthForgotPasswordMutation__
 *
 * To run a mutation, you first call `useAuthForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authForgotPasswordMutation, { data, loading, error }] = useAuthForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAuthForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<AuthForgotPasswordMutation, AuthForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthForgotPasswordMutation, AuthForgotPasswordMutationVariables>(AuthForgotPasswordDocument, options);
      }
export type AuthForgotPasswordMutationHookResult = ReturnType<typeof useAuthForgotPasswordMutation>;
export type AuthForgotPasswordMutationResult = Apollo.MutationResult<AuthForgotPasswordMutation>;
export type AuthForgotPasswordMutationOptions = Apollo.BaseMutationOptions<AuthForgotPasswordMutation, AuthForgotPasswordMutationVariables>;