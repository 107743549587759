import * as Types from '../types';

import { gql } from '@apollo/client';
import { TaxQuestionsFragmentDoc } from './Company.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveTaxAnswerMutationVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  answer?: Types.InputMaybe<Types.Scalars['String']>;
  questionId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SaveTaxAnswerMutation = { __typename?: 'Mutation', company?: { __typename?: 'CompanyMutation', saveTaxAnswer?: { __typename?: 'Company', id: string, taxQuestions?: Array<{ __typename?: 'TaxQuestionAnswers', answer?: string | null, customerId?: string | null, question?: string | null, questionId?: string | null } | null> | null } | null } | null };


export const SaveTaxAnswerDocument = gql`
    mutation SaveTaxAnswer($companyId: ID!, $answer: String, $questionId: String) {
  company(id: $companyId) {
    saveTaxAnswer(answer: $answer, questionId: $questionId) {
      id
      taxQuestions {
        ...TaxQuestions
      }
    }
  }
}
    ${TaxQuestionsFragmentDoc}`;
export type SaveTaxAnswerMutationFn = Apollo.MutationFunction<SaveTaxAnswerMutation, SaveTaxAnswerMutationVariables>;

/**
 * __useSaveTaxAnswerMutation__
 *
 * To run a mutation, you first call `useSaveTaxAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTaxAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTaxAnswerMutation, { data, loading, error }] = useSaveTaxAnswerMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      answer: // value for 'answer'
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useSaveTaxAnswerMutation(baseOptions?: Apollo.MutationHookOptions<SaveTaxAnswerMutation, SaveTaxAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveTaxAnswerMutation, SaveTaxAnswerMutationVariables>(SaveTaxAnswerDocument, options);
      }
export type SaveTaxAnswerMutationHookResult = ReturnType<typeof useSaveTaxAnswerMutation>;
export type SaveTaxAnswerMutationResult = Apollo.MutationResult<SaveTaxAnswerMutation>;
export type SaveTaxAnswerMutationOptions = Apollo.BaseMutationOptions<SaveTaxAnswerMutation, SaveTaxAnswerMutationVariables>;