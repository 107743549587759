import Layout from "components/Layout";
import { ROUTER_PATHS } from "./routes";
import GuestGuard from "utils/route-guard/GuestGuard";
import Login from "views/Auth/Login";
import RecoverPassword from "views/Auth/RecoverPassword";
import ResetPassword from "views/Auth/ResetPassword";

const LoginRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <GuestGuard>
          <Layout />
        </GuestGuard>
      ),
      children: [
        {
          path: ROUTER_PATHS.LOGIN,
          element: <Login />,
        },
        {
          path: ROUTER_PATHS.RECOVER_PASSWORD,
          element: <RecoverPassword />,
        },
        {
          path: ROUTER_PATHS.RESET_PASSWORD,
          element: <ResetPassword />,
        },
      ],
    },
  ],
};

export default LoginRoutes;
