import useSignupFlow from "graphql/hooks/UseSignupFlow";
import { ReactNode, createContext, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import { FormationSteps } from "./services";
import { LoadingComponent } from "components/Loading";

interface SignupProviderContextValue {
  formationStep: number;
}

export const SignupContext = createContext<SignupProviderContextValue>(
  {} as SignupProviderContextValue
);

export const ROUTES_TO_FORMATION_STEP = {
  [ROUTER_PATHS.PRE_PURCHASE_REGISTRATION]: FormationSteps.Registration,
  // [ROUTER_PATHS.PRE_PURCHASE_COMPANY_ORIGIN]: FormationSteps.CompanyOrigin,
  [ROUTER_PATHS.PRE_PURCHASE_COUNTRY_OF_RESIDENCE]:
    FormationSteps.CountryOfResidence,
  [ROUTER_PATHS.PRE_PURCHASE_ENTITY_TYPE]: FormationSteps.EntityType,
  [ROUTER_PATHS.PRE_PURCHASE_COMPANY_STATE]: FormationSteps.CompanyState,
  [ROUTER_PATHS.PRE_PURCHASE_COMPANY_NAME]: FormationSteps.CompanyName,
  [ROUTER_PATHS.PRE_PURCHASE_TC]: FormationSteps.TotalCompliance,
  [ROUTER_PATHS.PRE_PURCHASE_PACKAGES]: FormationSteps.Packages,
  [ROUTER_PATHS.PRE_PURCHASE_EXPEDITED]: FormationSteps.Expedited,
  [ROUTER_PATHS.PRE_PURCHASE_BOI_FILING]: FormationSteps.BoiFiling,
  [ROUTER_PATHS.PRE_PURCHASE_REVIEW]: FormationSteps.Review,
};

type Props = {
  children: ReactNode;
};

const SignupProvider = ({ children }: Props) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);

  // the highest formation step the user has reached
  const {
    companyId,
    hasServices,
    highestFormationStep,
    refetchSignupFlowData,
  } = useSignupFlow(); // max formation step

  useEffect(() => {
    const sessionId = searchParams.get("session");

    let timeoutId: NodeJS.Timeout;
    let callCount = 0;

    const fetchData = async () => {
      if (companyId) {
        await refetchSignupFlowData();
      }

      if (!hasServices && sessionId && callCount < 6) {
        callCount++;
        timeoutId = setTimeout(fetchData, 1000);
      } else {
        setIsLoading(false);
      }
    };

    // Initial call after 2 seconds
    if (sessionId) {
      timeoutId = setTimeout(fetchData, 1000);
    } else {
      setIsLoading(false);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchParams, refetchSignupFlowData, hasServices, companyId]);

  // the formation step the user is trying to reach from the url path
  const formationStepFromUser = ROUTES_TO_FORMATION_STEP[location.pathname];

  if (highestFormationStep === undefined) {
    return null;
  }

  const calculateFormationStep = () => {
    // otherwise let the user access the step they're trying to access
    if (formationStepFromUser < highestFormationStep) {
      return formationStepFromUser;
    }

    return highestFormationStep;
  };

  const formationStep = calculateFormationStep();

  const value = { formationStep };

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <SignupContext.Provider value={value}>{children}</SignupContext.Provider>
  );
};

export default SignupProvider;
