import { PayoutMethodType } from "graphql/__generated__/types";

export enum RoutingCodeTypes {
  ACH_CODE = "ACH CODE",
  SWIFT = "SWIFT",
}

export enum TransferMethodEnum {
  ACH = "ACH",
  WIRE = "Wire",
  INTERNATIONAL_WIRE = "International Wire",
}

export const US_KEY = "US";
export const USD_CURRENCY_KEY = "USD";
export const USD_CURRENCY_NAME = "US Dollar";

export const isValueValid = (value: any) =>
  value !== null && value !== undefined && value !== "" && value !== 0;

interface ITransferMethodProps {
  payoutMethod: string;
  destinationCountry: string;
  destinationCurrency?: string;
  routingCodeType1?: string;
}

export const getTransferMethod = ({
  payoutMethod,
  destinationCountry,
  destinationCurrency,
  routingCodeType1,
}: ITransferMethodProps): TransferMethodEnum => {
  switch (payoutMethod) {
    case PayoutMethodType.SWIFT:
      return destinationCountry === US_KEY
        ? TransferMethodEnum.WIRE
        : TransferMethodEnum.INTERNATIONAL_WIRE;

    case PayoutMethodType.LOCAL:
      return routingCodeType1 === RoutingCodeTypes.ACH_CODE
        ? TransferMethodEnum.ACH
        : destinationCurrency === USD_CURRENCY_KEY
        ? TransferMethodEnum.ACH
        : TransferMethodEnum.INTERNATIONAL_WIRE;

    default:
      return TransferMethodEnum.ACH;
  }
};

export const TransferMethods = {
  [TransferMethodEnum.ACH]: {
    title: TransferMethodEnum.ACH,
    daysValue: "3",
    paymentMethod: PayoutMethodType.LOCAL,
    routingCodeType1: RoutingCodeTypes.ACH_CODE,
  },
  [TransferMethodEnum.WIRE]: {
    title: TransferMethodEnum.WIRE,
    daysValue: "1",
    paymentMethod: PayoutMethodType.SWIFT,
    routingCodeType1: RoutingCodeTypes.SWIFT,
  },
  [TransferMethodEnum.INTERNATIONAL_WIRE]: {
    title: TransferMethodEnum.INTERNATIONAL_WIRE,
    daysValue: "1-5",
    paymentMethod: PayoutMethodType.SWIFT,
    routingCodeType1: RoutingCodeTypes.SWIFT,
  },
};

export const maskSensitiveData = (input: string | number): string => {
  const inputString = String(input);
  const visibleDigits = inputString.slice(-4);
  const maskedPart = "•".repeat(4);

  return `${maskedPart} ${visibleDigits}`;
};
