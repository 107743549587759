import { Button, Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Logo } from "components/Logos";
import { useTranslation } from "react-i18next";
import { ROUTER_PATHS } from "routes/routes";
import { colors } from "utils";
import Cart from "./Cart";
import { usePostHog } from "posthog-js/react";

export const Header = () => {
  const { t } = useTranslation();

  const posthog = usePostHog();

  return (
    <Stack
      pt={3}
      direction="row"
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <RouterLink
        to={ROUTER_PATHS.PRE_PURCHASE_REGISTRATION}
        style={{ display: "inline-flex" }}
      >
        <Logo width={100} />
      </RouterLink>
      <Stack direction="row" gap={2} alignItems={"center"}>
        <Cart />
        <Link
          href="https://www.doola.com/free-consultation/submit/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <Button
            variant="outlined"
            sx={{
              borderRadius: "24px",
              p: { md: "15px 24px", xs: "10px 10px" },
              "&:hover": {
                color: colors.charcoal,
              },
            }}
            onClick={() => posthog?.capture("free_consultation_click")}
          >
            <Typography
              fontWeight={500}
              lineHeight={"16px"}
              textTransform="initial"
            >
              {t("general.freeConsultation")}
            </Typography>
          </Button>
        </Link>
      </Stack>
    </Stack>
  );
};
