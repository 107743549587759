import { Link, Typography, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <Stack
      direction={"row"}
      justifyContent={"center"}
      spacing={2}
      textAlign={"inherit"}
    >
      <Typography variant="body2" color="secondary" component="span">
        doola &#169; {new Date().getFullYear()}
      </Typography>
      <Typography
        variant="body2"
        color="secondary"
        component={Link}
        href="https://www.doola.com/privacy-policy"
        target="_blank"
        underline="always"
        rel="noreferrer"
      >
        {t("general.privacy")}
      </Typography>
      <Typography
        variant="body2"
        color="secondary"
        component={Link}
        href="https://www.doola.com/terms-of-service"
        target="_blank"
        underline="always"
        rel="noreferrer"
      >
        {t("general.terms")}
      </Typography>
    </Stack>
  );
};
