export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export enum AccountStatusType {
  ACTIVE = 'Active',
  BLOCKED = 'Blocked',
  CLEAR = 'Clear',
  FAILED = 'Failed',
  PENDING = 'Pending',
  SUSPENDED = 'Suspended'
}

export enum AccountType {
  CORPORATE = 'CORPORATE',
  INDIVIDUAL = 'INDIVIDUAL'
}

export type AddServiceInput = {
  service: CompanyServices;
  variant?: InputMaybe<ServiceVariant>;
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  country: Iso31661Alpha3;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  state: Scalars['String'];
};

export type AddressInput = {
  city: Scalars['String'];
  country: Iso31661Alpha3;
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  state: Scalars['String'];
};

export enum AddressType {
  FIRSTMEMBER = 'FirstMember',
  MAILSERVICE = 'MailService',
  OTHERUS = 'OtherUS',
  REGISTEREDAGENT = 'RegisteredAgent'
}

export type AdminNote = {
  __typename?: 'AdminNote';
  note?: Maybe<Scalars['String']>;
  noteId: Scalars['ID'];
  status?: Maybe<AdminNoteStatus>;
};

export enum AdminNoteStatus {
  DELETED = 'Deleted',
  RESOLVED = 'Resolved',
  UNRESOLVED = 'Unresolved'
}

export type ApplicantAddressInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
};

export type AuthMutation = {
  __typename?: 'AuthMutation';
  changePassword?: Maybe<AuthResponse>;
  chooseFirstPassword?: Maybe<AuthResponse>;
  confirmForgotPassword?: Maybe<AuthResponse>;
  forgotPassword?: Maybe<AuthResponse>;
  refreshTokens?: Maybe<AuthResponseTokens>;
  signIn?: Maybe<AuthResponseTokens>;
  signOut?: Maybe<AuthResponse>;
  signUp?: Maybe<SignUpResponse>;
};


export type AuthMutationChangePasswordArgs = {
  newPassword: Scalars['String'];
};


export type AuthMutationChooseFirstPasswordArgs = {
  newPassword: Scalars['String'];
};


export type AuthMutationConfirmForgotPasswordArgs = {
  input: ConfirmForgotPassword;
};


export type AuthMutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type AuthMutationRefreshTokensArgs = {
  input: RefreshTokensInput;
};


export type AuthMutationSignInArgs = {
  credentials: EmailPassword;
};


export type AuthMutationSignUpArgs = {
  input: NewUser;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type AuthResponseTokens = {
  __typename?: 'AuthResponseTokens';
  companyIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  customerId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
  tokens?: Maybe<AuthTokens>;
};

export type AuthTokens = {
  __typename?: 'AuthTokens';
  accessToken: Scalars['String'];
  idToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type BeneficiaryDetails = {
  beneficiaryAccountNumber: Scalars['String'];
  beneficiaryAccountType?: InputMaybe<AccountType>;
  beneficiaryAddress?: InputMaybe<Scalars['String']>;
  beneficiaryCity?: InputMaybe<Scalars['String']>;
  beneficiaryCountryCode: Scalars['String'];
  beneficiaryName: Scalars['String'];
  beneficiaryPostcode?: InputMaybe<Scalars['String']>;
  beneficiaryState?: InputMaybe<Scalars['String']>;
  destinationCountry: Scalars['String'];
  destinationCurrency: Scalars['String'];
  doolaCompanyId: Scalars['String'];
  payoutMethod?: InputMaybe<PayoutMethodType>;
  routingCodeType1: Scalars['String'];
  routingCodeValue1: Scalars['String'];
};

export type BeneficiaryList = {
  __typename?: 'BeneficiaryList';
  error?: Maybe<Scalars['String']>;
  payload?: Maybe<Array<Maybe<BeneficiaryResponse>>>;
};

export type BeneficiaryResponse = {
  __typename?: 'BeneficiaryResponse';
  beneficiaryAccountNumber?: Maybe<Scalars['String']>;
  beneficiaryAccountType?: Maybe<AccountType>;
  beneficiaryAddress?: Maybe<Scalars['String']>;
  beneficiaryBankAccountType?: Maybe<Scalars['String']>;
  beneficiaryBankCode?: Maybe<Scalars['String']>;
  beneficiaryBankName?: Maybe<Scalars['String']>;
  beneficiaryCity?: Maybe<Scalars['String']>;
  beneficiaryCountryCode?: Maybe<Scalars['String']>;
  beneficiaryCreatedAt?: Maybe<Scalars['String']>;
  beneficiaryHashId?: Maybe<Scalars['String']>;
  beneficiaryName?: Maybe<Scalars['String']>;
  beneficiaryPostcode?: Maybe<Scalars['String']>;
  beneficiaryState?: Maybe<Scalars['String']>;
  destinationCountry?: Maybe<Scalars['String']>;
  destinationCurrency?: Maybe<Scalars['String']>;
  doolaCompanyId?: Maybe<Scalars['String']>;
  doolaCustomerId?: Maybe<Scalars['String']>;
  payoutHashId?: Maybe<Scalars['String']>;
  payoutMethod?: Maybe<Scalars['String']>;
  routingCodeType1?: Maybe<Scalars['String']>;
  routingCodeValue1?: Maybe<Scalars['String']>;
};

export type BeneficiaryResponsePayload = {
  __typename?: 'BeneficiaryResponsePayload';
  error?: Maybe<Scalars['String']>;
  payload?: Maybe<BeneficiaryResponse>;
};

export enum BookkeepingVariant {
  ESSENTIAL = 'ESSENTIAL',
  PREMIUM = 'PREMIUM'
}

export type BusinessTypeInput = {
  name: Scalars['String'];
  style: Scalars['String'];
};

export type CcorpExecutiveInfo = {
  __typename?: 'CcorpExecutiveInfo';
  directors: Array<Maybe<ExecutiveName>>;
  president: ExecutiveName;
  secretary: ExecutiveName;
  treasurer: ExecutiveName;
};

export type CcorpValuationInfo = {
  __typename?: 'CcorpValuationInfo';
  noOfShares: Scalars['Int'];
  valuePerShare: Scalars['Float'];
};

export type Companies = {
  __typename?: 'Companies';
  edges?: Maybe<Array<Maybe<CompaniesEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type CompaniesEdge = {
  __typename?: 'CompaniesEdge';
  cursor: Scalars['String'];
  node?: Maybe<Company>;
};

/**
 * Typical entity for a company, formed, or being formed, or currently collecting data
 * in anticipation of being formed.
 */
export type Company = {
  __typename?: 'Company';
  EIN?: Maybe<Scalars['String']>;
  ITIN?: Maybe<Scalars['String']>;
  SSN?: Maybe<Scalars['String']>;
  activity?: Maybe<Scalars['String']>;
  activityOther?: Maybe<Scalars['String']>;
  addressType?: Maybe<AddressType>;
  adminNotes?: Maybe<Array<AdminNote>>;
  balance?: Maybe<Scalars['Int']>;
  ccorpExecInfo?: Maybe<CcorpExecutiveInfo>;
  ccorpValuationInfo?: Maybe<CcorpValuationInfo>;
  companyOrigin?: Maybe<CompanyOrigin>;
  companyTaxInfo?: Maybe<TaxInfo>;
  cpaCredits?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  customerIds?: Maybe<Array<Scalars['String']>>;
  dashboardStatus?: Maybe<DashboardStatus>;
  description?: Maybe<Scalars['String']>;
  documentServiceDocs?: Maybe<Array<Maybe<DocServiceDocuments>>>;
  documents?: Maybe<CompanyDocuments>;
  dudaSSOUrl?: Maybe<Scalars['String']>;
  dudaSiteStatus?: Maybe<DudaSiteStatus>;
  /** Admin Actions for listing available services on company */
  eligibleServices?: Maybe<Array<Maybe<ServiceForProduct>>>;
  entityType?: Maybe<EntityType>;
  entityTypeEnding?: Maybe<Scalars['String']>;
  faxRecords8821?: Maybe<Array<Maybe<FaxRecord8821>>>;
  form8821Documents?: Maybe<Array<Maybe<Document>>>;
  history?: Maybe<Array<LogCommand>>;
  id: Scalars['ID'];
  managedBy?: Maybe<ManagedByTypes>;
  members?: Maybe<Array<Member>>;
  /**
   * name: will be the preferred nameOptions
   * For older companies which doesn't have nameOptions, will show the old name from updateName() mutation
   */
  name?: Maybe<Scalars['String']>;
  /** provide multiple names in case of state rejection */
  nameOptions?: Maybe<Array<Maybe<CompanyName>>>;
  pandaDocSignedDocuments?: Maybe<Array<Maybe<SignedDocument>>>;
  registeredAgent?: Maybe<RegisteredAgent>;
  registeredAgentId?: Maybe<Scalars['String']>;
  responsibleParty?: Maybe<ResponsibleParty>;
  services?: Maybe<Array<Services>>;
  signatureRecipientEmail?: Maybe<Scalars['String']>;
  ss4Documents?: Maybe<Array<Maybe<Document>>>;
  ss4FaxRecords?: Maybe<Array<Maybe<Ss4FaxRecord>>>;
  ss4SigRecipientEmail?: Maybe<Scalars['String']>;
  state?: Maybe<Iso31662Us>;
  status?: Maybe<CompanyStatus>;
  stripeCheckoutSessions?: Maybe<Array<Maybe<StripeSessionDetails>>>;
  stripePaymentLinks?: Maybe<Array<Maybe<StripePaymentLink>>>;
  taxQuestions?: Maybe<Array<Maybe<TaxQuestionAnswers>>>;
  tosAccepted?: Maybe<Scalars['Boolean']>;
  /** In the case of LLCs with absolute percentage ownership, this is the total shares. The amount of shares allocated to members may be less */
  totalShares?: Maybe<Scalars['Int']>;
  unitBankAccountTokenCredentials?: Maybe<UnitBankAccountTokenCredentials>;
  upcomingConsultations?: Maybe<Array<Maybe<UpcomingConsultations>>>;
  upsells?: Maybe<Array<CompanyUpsell>>;
  url?: Maybe<Scalars['String']>;
  usMailingAddress?: Maybe<UsAddress>;
  websites?: Maybe<Website>;
};


/**
 * Typical entity for a company, formed, or being formed, or currently collecting data
 * in anticipation of being formed.
 */
export type CompanyDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type CompanyDetailsInput = {
  EIN: Scalars['String'];
  activity: Scalars['String'];
  activityOther: Scalars['String'];
  addressType: Scalars['String'];
  businessType: BusinessTypeInput;
  description: Scalars['String'];
  geographicState: Scalars['String'];
  name: Scalars['String'];
  owners: Array<OwnerInput>;
  url: Scalars['String'];
};

export type CompanyDocument = {
  __typename?: 'CompanyDocument';
  checksum: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  name: Scalars['String'];
  read?: Maybe<Scalars['Boolean']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  type: DocumentType;
  url: Scalars['String'];
};

export type CompanyDocumentEdge = {
  __typename?: 'CompanyDocumentEdge';
  cursor: Scalars['String'];
  node: CompanyDocument;
};

export type CompanyDocuments = {
  __typename?: 'CompanyDocuments';
  edges?: Maybe<Array<Maybe<CompanyDocumentEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export enum CompanyFormationInProgressCurrentStep {
  AWAITING_REGISTERED_AGENT = 'AWAITING_REGISTERED_AGENT',
  COLLECTING_DATA = 'COLLECTING_DATA',
  DOCUMENTS_SENT_TO_CONTACT_PERSON = 'DOCUMENTS_SENT_TO_CONTACT_PERSON',
  PROCESSING_PAPERWORK = 'PROCESSING_PAPERWORK'
}

export enum CompanyFormationStatus {
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED'
}

export type CompanyMutation = {
  __typename?: 'CompanyMutation';
  acceptTos?: Maybe<Company>;
  activateRecordKeepingService?: Maybe<Company>;
  addAdminNote?: Maybe<Company>;
  addBankingService?: Maybe<Company>;
  addBookkeepingService?: Maybe<Company>;
  addCompanyWebsiteDomain?: Maybe<Company>;
  addEinCreationService?: Maybe<Company>;
  addEinService?: Maybe<Company>;
  addExpeditedEinCreationService?: Maybe<Company>;
  addFormationService?: Maybe<Company>;
  addItinService?: Maybe<Company>;
  addMember?: Maybe<Company>;
  addService?: Maybe<Company>;
  addServices?: Maybe<Company>;
  addTaxService?: Maybe<Company>;
  attachArticlesDocument?: Maybe<Company>;
  attachEinLetter?: Maybe<Company>;
  attachOtherDocument?: Maybe<Company>;
  attachRegisteredAgentId?: Maybe<Company>;
  attachSignedForm8821?: Maybe<Company>;
  attachSignedSs4?: Maybe<Company>;
  attachUnsignedForm8821?: Maybe<Company>;
  attachUnsignedSs4?: Maybe<Company>;
  cancelStripePaymentLink?: Maybe<Company>;
  checkoutCompleted?: Maybe<Company>;
  /** @deprecated Use generateUnsignedSS4 mutation instead. */
  createSs4Document?: Maybe<Company>;
  createStripePaymentLink?: Maybe<StripePaymentLink>;
  createStripePaymentLinkDDS?: Maybe<StripePaymentLink>;
  deleteAdminNote?: Maybe<Company>;
  deleteDocument?: Maybe<DeleteDocumentMeta>;
  deleteDocumentDocService?: Maybe<Company>;
  editTaxCompanyInfo?: Maybe<Company>;
  editTaxMemberInfo?: Maybe<Company>;
  fax8821?: Maybe<Company>;
  fax8821ToIrs?: Maybe<Company>;
  fileTaxWithIrs?: Maybe<Company>;
  fileWithRa?: Maybe<Company>;
  generateDocServiceUploadUrl?: Maybe<GeneratedUrl>;
  generateForm8821?: Maybe<Company>;
  generateOA?: Maybe<Company>;
  generateUnsignedSS4?: Maybe<GeneratedSs4>;
  initiateTaxMember?: Maybe<Company>;
  /** @deprecated Internal use only */
  migrateFromOldSystem?: Maybe<Company>;
  refresh?: Maybe<Company>;
  removeAllMembers?: Maybe<Company>;
  removeMember?: Maybe<Company>;
  removeService?: Maybe<Company>;
  requestDocumentUploadUrl?: Maybe<SignedDocumentUploadUrl>;
  requestForm8821Signature?: Maybe<Company>;
  requestSs4Signature?: Maybe<Company>;
  requestStripeCheckoutSession?: Maybe<StripeCheckoutSession>;
  requestStripeCheckoutSessionDDS?: Maybe<StripeCheckoutSession>;
  resendEmail?: Maybe<Company>;
  resolveAdminNote?: Maybe<Company>;
  saveTaxAnswer?: Maybe<Company>;
  saveTaxInfo?: Maybe<Company>;
  sendSs4ToIrs?: Maybe<Company>;
  sendTaxPrepForCustomerReview?: Maybe<Company>;
  sign8821?: Maybe<Company>;
  signedSs4Received?: Maybe<Company>;
  updateActivity?: Maybe<Company>;
  updateActivityOther?: Maybe<Company>;
  updateAddressType?: Maybe<Company>;
  updateCcorpExecInfo?: Maybe<Company>;
  updateCcorpValuationInfo?: Maybe<Company>;
  updateCompanyOrigin?: Maybe<Company>;
  updateCustomerTaxInfo?: Maybe<Company>;
  updateDashboardStatus?: Maybe<Company>;
  updateDescription?: Maybe<Company>;
  updateDudaSiteId?: Maybe<Company>;
  updateEin?: Maybe<Company>;
  updateEntityType?: Maybe<Company>;
  updateEntityTypeEnding?: Maybe<Company>;
  updateForm8821SignatureRecipientEmail?: Maybe<Company>;
  updateFormationFilingDate?: Maybe<Company>;
  updateFormationFilingNumber?: Maybe<Company>;
  updateItin?: Maybe<Company>;
  updateManagedBy?: Maybe<Company>;
  /** @deprecated Replaced by updateNameOptions mutation */
  updateName?: Maybe<Company>;
  updateNameOptions?: Maybe<Company>;
  updateOtherUsAddress?: Maybe<Company>;
  updateRaFilingJobStatus?: Maybe<Company>;
  updateResponsibleParty?: Maybe<Company>;
  updateSs4SignatureRecipientEmail?: Maybe<Company>;
  updateSsn?: Maybe<Company>;
  updateState?: Maybe<Company>;
  updateTaxFilingPrepStatus?: Maybe<Company>;
  updateUrl?: Maybe<Company>;
};


export type CompanyMutationAddAdminNoteArgs = {
  note?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationAddBookkeepingServiceArgs = {
  new?: InputMaybe<BookkeepingVariant>;
};


export type CompanyMutationAddCompanyWebsiteDomainArgs = {
  new: Scalars['String'];
};


export type CompanyMutationAddEinServiceArgs = {
  new?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationAddMemberArgs = {
  new: MemberInput;
};


export type CompanyMutationAddServiceArgs = {
  service: AddServiceInput;
};


export type CompanyMutationAddServicesArgs = {
  services: Array<AddServiceInput>;
};


export type CompanyMutationAttachArticlesDocumentArgs = {
  articlesFileName?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationAttachEinLetterArgs = {
  einLetterFileName?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationAttachOtherDocumentArgs = {
  otherDocumentFileName?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationAttachRegisteredAgentIdArgs = {
  registeredAgentId: Scalars['String'];
};


export type CompanyMutationAttachSignedForm8821Args = {
  signedForm8821FileName?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationAttachSignedSs4Args = {
  signedSs4FileName?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationAttachUnsignedForm8821Args = {
  unsignedForm8821FileName?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationAttachUnsignedSs4Args = {
  unsignedSs4FileName?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationCancelStripePaymentLinkArgs = {
  products: Array<Scalars['String']>;
};


export type CompanyMutationCheckoutCompletedArgs = {
  checkoutSessionId: Scalars['String'];
};


export type CompanyMutationCreateStripePaymentLinkArgs = {
  products: Array<Scalars['String']>;
};


export type CompanyMutationCreateStripePaymentLinkDdsArgs = {
  customerId: Scalars['String'];
  products: Array<Scalars['String']>;
};


export type CompanyMutationDeleteAdminNoteArgs = {
  noteId: Scalars['ID'];
};


export type CompanyMutationDeleteDocumentArgs = {
  key: Scalars['String'];
};


export type CompanyMutationDeleteDocumentDocServiceArgs = {
  documentId: Scalars['String'];
};


export type CompanyMutationEditTaxCompanyInfoArgs = {
  companyTaxInfo?: InputMaybe<CompanyTaxInfoInput>;
};


export type CompanyMutationEditTaxMemberInfoArgs = {
  memberInfo?: InputMaybe<EditTaxMemberInfoInput>;
};


export type CompanyMutationFax8821ToIrsArgs = {
  message: Scalars['String'];
  phaxioId: Scalars['Int'];
};


export type CompanyMutationFileWithRaArgs = {
  forced?: InputMaybe<Scalars['Boolean']>;
};


export type CompanyMutationGenerateDocServiceUploadUrlArgs = {
  contentType: Scalars['String'];
  metadata: Array<InputMaybe<DocumentMetadata>>;
  objectKey: Scalars['String'];
  source?: InputMaybe<Source>;
};


export type CompanyMutationMigrateFromOldSystemArgs = {
  company?: InputMaybe<MigrateCompanyInput>;
};


export type CompanyMutationRemoveMemberArgs = {
  position: Scalars['Int'];
};


export type CompanyMutationRemoveServiceArgs = {
  service: RemoveServiceInput;
};


export type CompanyMutationRequestDocumentUploadUrlArgs = {
  fileName?: InputMaybe<Scalars['String']>;
  type: DocumentType;
};


export type CompanyMutationRequestForm8821SignatureArgs = {
  rightSignatureSendingRequestId?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationRequestSs4SignatureArgs = {
  ss4SigRecipientEmail?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationRequestStripeCheckoutSessionArgs = {
  email: Scalars['String'];
  products: Array<Scalars['String']>;
  redirectBaseUri?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationRequestStripeCheckoutSessionDdsArgs = {
  customerId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  products: Array<Scalars['String']>;
  redirectBaseUri?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationResendEmailArgs = {
  email?: InputMaybe<Scalars['String']>;
  emailType: EmailTypes;
  fullname?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationResolveAdminNoteArgs = {
  noteId: Scalars['ID'];
};


export type CompanyMutationSaveTaxAnswerArgs = {
  answer?: InputMaybe<Scalars['String']>;
  questionId?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationSaveTaxInfoArgs = {
  taxInfo?: InputMaybe<TaxInfoInput>;
};


export type CompanyMutationSignedSs4ReceivedArgs = {
  ss4Payload?: InputMaybe<Ss4PayloadInput>;
};


export type CompanyMutationUpdateActivityArgs = {
  new?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationUpdateActivityOtherArgs = {
  new?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationUpdateAddressTypeArgs = {
  new?: InputMaybe<AddressType>;
};


export type CompanyMutationUpdateCcorpExecInfoArgs = {
  directors: Array<InputMaybe<ExecutiveNameInput>>;
  president: ExecutiveNameInput;
  secretary: ExecutiveNameInput;
  treasurer: ExecutiveNameInput;
};


export type CompanyMutationUpdateCcorpValuationInfoArgs = {
  noOfShares: Scalars['Int'];
  valuePerShare: Scalars['Float'];
};


export type CompanyMutationUpdateCompanyOriginArgs = {
  new?: InputMaybe<CompanyOrigin>;
};


export type CompanyMutationUpdateDashboardStatusArgs = {
  status: DashboardStatus;
};


export type CompanyMutationUpdateDescriptionArgs = {
  new?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationUpdateDudaSiteIdArgs = {
  siteId: Scalars['String'];
};


export type CompanyMutationUpdateEinArgs = {
  new?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationUpdateEntityTypeArgs = {
  new?: InputMaybe<EntityType>;
};


export type CompanyMutationUpdateEntityTypeEndingArgs = {
  new?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationUpdateForm8821SignatureRecipientEmailArgs = {
  new?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationUpdateFormationFilingDateArgs = {
  new: Scalars['String'];
};


export type CompanyMutationUpdateFormationFilingNumberArgs = {
  new: Scalars['String'];
};


export type CompanyMutationUpdateItinArgs = {
  new?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationUpdateManagedByArgs = {
  new?: InputMaybe<ManagedByTypes>;
};


export type CompanyMutationUpdateNameArgs = {
  new?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationUpdateNameOptionsArgs = {
  names: Array<InputMaybe<CompanyNameInput>>;
};


export type CompanyMutationUpdateOtherUsAddressArgs = {
  new?: InputMaybe<UsAddressInput>;
};


export type CompanyMutationUpdateRaFilingJobStatusArgs = {
  jobId: Scalars['String'];
  reasonFailed?: InputMaybe<Scalars['String']>;
  registeredAgentId?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
  timestamp: Scalars['String'];
};


export type CompanyMutationUpdateResponsiblePartyArgs = {
  new?: InputMaybe<ResponsiblePartyInput>;
};


export type CompanyMutationUpdateSs4SignatureRecipientEmailArgs = {
  new?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationUpdateSsnArgs = {
  new?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationUpdateStateArgs = {
  new?: InputMaybe<Scalars['String']>;
};


export type CompanyMutationUpdateTaxFilingPrepStatusArgs = {
  new?: InputMaybe<TaxFilingPrepStatus>;
};


export type CompanyMutationUpdateUrlArgs = {
  new?: InputMaybe<Scalars['String']>;
};

export type CompanyName = {
  __typename?: 'CompanyName';
  entityTypeEnding: Scalars['String'];
  name: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
  status?: Maybe<CompanyNameStatus>;
};

export type CompanyNameInput = {
  entityTypeEnding: Scalars['String'];
  name: Scalars['String'];
  position?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<CompanyNameStatus>;
};

export enum CompanyNameStatus {
  /** Approved name by RA */
  APPROVED = 'Approved',
  /** Inputted name, before sending it to RA. Default option. */
  INPUTTED = 'Inputted',
  /** Rejected name by RA */
  REJECTED = 'Rejected',
  /** Sent name to RA. Waiting for confirmation, could end in approved or rejected */
  SUBMITTED = 'Submitted'
}

export enum CompanyOrigin {
  DOOLA = 'Doola',
  OTHER = 'Other'
}

export enum CompanyServices {
  ACCOUNTMANAGEMENT = 'AccountManagement',
  ANNUALSTATEFILING = 'AnnualStateFiling',
  BANKING = 'Banking',
  BOIFILING = 'BoiFiling',
  BOOKKEEPING = 'Bookkeeping',
  COMPLIANCE = 'Compliance',
  CPACONSULTATION = 'CpaConsultation',
  EINCREATION = 'EinCreation',
  FORMATION = 'Formation',
  ITINCREATION = 'ItinCreation',
  MAIL = 'Mail',
  MONEY = 'Money',
  RECORDKEEPING = 'RecordKeeping',
  REGISTEREDAGENT = 'RegisteredAgent',
  STRIPEPAYMENTGATEWAY = 'StripePaymentGateway',
  TAX = 'Tax',
  WEBSITEBUILDER = 'WebsiteBuilder'
}

/**
 * In the FORMATION_IN_PROGRESS category, the currentStep field will be populated,
 * indicating at what stage of the company formation lifecycle the company currently is.
 */
export type CompanyStatus = {
  __typename?: 'CompanyStatus';
  category: CompanyFormationStatus;
  currentStep?: Maybe<CompanyFormationInProgressCurrentStep>;
};

export type CompanyTaxInfo = {
  __typename?: 'CompanyTaxInfo';
  assets?: Maybe<Scalars['String']>;
  businessDescription?: Maybe<Scalars['String']>;
  ein?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  grossRevenue?: Maybe<Scalars['String']>;
  taxFilingStatus?: Maybe<Scalars['String']>;
};

export type CompanyTaxInfoInput = {
  assets?: InputMaybe<Scalars['String']>;
  businessDescription?: InputMaybe<Scalars['String']>;
  ein?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  grossRevenue?: InputMaybe<Scalars['String']>;
  taxFilingStatus?: InputMaybe<Scalars['String']>;
};

export type CompanyUpsell = {
  __typename?: 'CompanyUpsell';
  name: Scalars['String'];
  services?: Maybe<Array<Maybe<UpsellServices>>>;
  stripePricingId: Scalars['String'];
  usdPriceCents: Scalars['Int'];
};

export type ConfirmForgotPassword = {
  confirmationCode: Scalars['String'];
  email: Scalars['String'];
  newPassword: Scalars['String'];
};

export type Country = {
  __typename?: 'Country';
  code?: Maybe<Iso31661Alpha3>;
  name: Scalars['String'];
  subdivisions?: Maybe<Array<Maybe<CountrySubdivision>>>;
};

export type CountrySubdivision = {
  __typename?: 'CountrySubdivision';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type CurrencyDetail = {
  __typename?: 'CurrencyDetail';
  countryName?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  currencyName?: Maybe<Scalars['String']>;
  feeInfo?: Maybe<FeeInfo>;
  iso2Name?: Maybe<Scalars['String']>;
};

export type CurrencyDetailList = {
  __typename?: 'CurrencyDetailList';
  error?: Maybe<Scalars['String']>;
  payload?: Maybe<Array<Maybe<CurrencyDetail>>>;
};

export type Customer = {
  __typename?: 'Customer';
  cognitoId?: Maybe<Scalars['String']>;
  commissionRecipient?: Maybe<Scalars['String']>;
  companyIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  countryOfResidence?: Maybe<Iso31661Alpha3>;
  createdAt?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  excludeFromMetrics?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  history?: Maybe<Array<LogCommand>>;
  id?: Maybe<Scalars['String']>;
  joinSecretUserId?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  lastUpdated?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  referralMetadata?: Maybe<CustomerReferralMetadata>;
  secretOffersRedeemed?: Maybe<Array<Maybe<SecretOffersRedeemed>>>;
  ssn?: Maybe<Scalars['String']>;
  stripeBillingPortalMeta?: Maybe<StripeBillingPortalMetadata>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  wasReferred?: Maybe<Scalars['Boolean']>;
};


export type CustomerStripeBillingPortalMetaArgs = {
  returnUrl: Scalars['String'];
};

export type CustomerMutation = {
  __typename?: 'CustomerMutation';
  addJoinSecretUserId?: Maybe<Customer>;
  addStripeCustomerId?: Maybe<Customer>;
  createJoinSecretUser?: Maybe<Customer>;
  dissociateCompany?: Maybe<Customer>;
  excludeFromMetrics?: Maybe<Customer>;
  generateReferralLink?: Maybe<Customer>;
  makeCompanyOwner?: Maybe<Customer>;
  /** @deprecated Internal use only */
  migrateFromOldSystem?: Maybe<Customer>;
  redeemOffer?: Maybe<Customer>;
  refresh?: Maybe<Customer>;
  removeFirstName?: Maybe<Customer>;
  removeLastName?: Maybe<Customer>;
  removeSsn?: Maybe<Customer>;
  updateCognitoId?: Maybe<Customer>;
  updateCountryOfResidence?: Maybe<Customer>;
  updateEmail?: Maybe<Customer>;
  updateFirstName?: Maybe<Customer>;
  updateLastName?: Maybe<Customer>;
  /** @deprecated Use updateFirstName() and updateLastName() mutations */
  updateName?: Maybe<Customer>;
  updatePhoneNumber?: Maybe<Customer>;
  updateSsn?: Maybe<Customer>;
  updateTimezone?: Maybe<Customer>;
  /**
   * Note: new is a less than ideal argument name, it shold ideally be referredBy, as we only
   * use this for referral tracking
   */
  updateTrackingData?: Maybe<Customer>;
};


export type CustomerMutationAddJoinSecretUserIdArgs = {
  joinSecretUserId?: InputMaybe<Scalars['Int']>;
};


export type CustomerMutationAddStripeCustomerIdArgs = {
  new?: InputMaybe<Scalars['String']>;
};


export type CustomerMutationDissociateCompanyArgs = {
  companyId?: InputMaybe<Scalars['String']>;
};


export type CustomerMutationMakeCompanyOwnerArgs = {
  new?: InputMaybe<Scalars['String']>;
};


export type CustomerMutationMigrateFromOldSystemArgs = {
  customer?: InputMaybe<MigrateCustomerInput>;
};


export type CustomerMutationRedeemOfferArgs = {
  offerId: Scalars['Int'];
};


export type CustomerMutationUpdateCognitoIdArgs = {
  cognitoId: Scalars['String'];
};


export type CustomerMutationUpdateCountryOfResidenceArgs = {
  new: Iso31661Alpha3;
};


export type CustomerMutationUpdateEmailArgs = {
  new?: InputMaybe<Scalars['String']>;
};


export type CustomerMutationUpdateFirstNameArgs = {
  new?: InputMaybe<Scalars['String']>;
};


export type CustomerMutationUpdateLastNameArgs = {
  new?: InputMaybe<Scalars['String']>;
};


export type CustomerMutationUpdateNameArgs = {
  new?: InputMaybe<Scalars['String']>;
};


export type CustomerMutationUpdatePhoneNumberArgs = {
  new?: InputMaybe<Scalars['String']>;
};


export type CustomerMutationUpdateSsnArgs = {
  new?: InputMaybe<Scalars['String']>;
};


export type CustomerMutationUpdateTimezoneArgs = {
  new?: InputMaybe<Scalars['String']>;
};


export type CustomerMutationUpdateTrackingDataArgs = {
  new?: InputMaybe<Scalars['String']>;
};

export type CustomerReferralMetadata = {
  __typename?: 'CustomerReferralMetadata';
  link: Scalars['String'];
  token: Scalars['String'];
};

export type Customers = {
  __typename?: 'Customers';
  edges?: Maybe<Array<Maybe<CustomersEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type CustomersEdge = {
  __typename?: 'CustomersEdge';
  cursor: Scalars['String'];
  node?: Maybe<Customer>;
};

export enum DashboardStatus {
  ACTIVE = 'active',
  BLOCKED = 'blocked',
  CANCELLED = 'cancelled',
  PASTDUE = 'pastDue'
}

export type DataServiceResponse = {
  __typename?: 'DataServiceResponse';
  error?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['String']>;
};

export type DeleteDocumentMeta = {
  __typename?: 'DeleteDocumentMeta';
  deleteMarker?: Maybe<Scalars['Boolean']>;
  versionId?: Maybe<Scalars['String']>;
};

export type DeleteDocumentResponse = {
  __typename?: 'DeleteDocumentResponse';
  message: Scalars['String'];
};

export type DocServiceDocuments = {
  __typename?: 'DocServiceDocuments';
  companyId?: Maybe<Scalars['String']>;
  documentId?: Maybe<Scalars['String']>;
  metadata?: Maybe<DocumentMetaData>;
  url?: Maybe<Scalars['String']>;
};

export type Document = {
  __typename?: 'Document';
  documentType?: Maybe<DocumentType>;
  rightSignatureSendingRequestId?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  s3Location?: Maybe<Scalars['String']>;
  ss4SigRecipientEmail?: Maybe<Scalars['String']>;
};

export type DocumentInput = {
  articlesFileId?: InputMaybe<Scalars['String']>;
  googleDriveFolderId?: InputMaybe<Scalars['String']>;
  signedSS4FileId?: InputMaybe<Scalars['String']>;
};

export type DocumentListResponse = {
  __typename?: 'DocumentListResponse';
  documentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DocumentMetaData = {
  __typename?: 'DocumentMetaData';
  companyid?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  sourcecategory?: Maybe<Scalars['String']>;
};

export type DocumentMetadata = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type DocumentMutations = {
  __typename?: 'DocumentMutations';
  deleteDocument?: Maybe<DeleteDocumentResponse>;
  generateUploadUrl?: Maybe<GeneratedUploadUrl>;
};


export type DocumentMutationsDeleteDocumentArgs = {
  documentId: Scalars['String'];
};


export type DocumentMutationsGenerateUploadUrlArgs = {
  contentType: Scalars['String'];
  metadata: Array<InputMaybe<UploadMetaData>>;
  objectKey: Scalars['String'];
};

export type DocumentQueries = {
  __typename?: 'DocumentQueries';
  getDocument?: Maybe<DocumentResponse>;
  getDocumentKeyList?: Maybe<DocumentListResponse>;
};


export type DocumentQueriesGetDocumentArgs = {
  documentId: Scalars['String'];
};


export type DocumentQueriesGetDocumentKeyListArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
};

export type DocumentResponse = {
  __typename?: 'DocumentResponse';
  documentUrl: Scalars['String'];
};

export enum DocumentType {
  ANNUALREPORT = 'AnnualReport',
  ARTICLESOFORGANIZATION = 'ArticlesOfOrganization',
  DELIVEREDSERVICEOFPROCESS = 'DeliveredServiceOfProcess',
  EINLETTER = 'EinLetter',
  FILINGDOCUMENT = 'FilingDocument',
  HIGHPRIORITY = 'HighPriority',
  INITIALFILING = 'InitialFiling',
  MERGEDSS4ANDARTICLES = 'MergedSS4AndArticles',
  OTHER = 'OTHER',
  ONGOINGFILING = 'OngoingFiling',
  ORIGINALFILING = 'OriginalFiling',
  REGULARMAIL = 'RegularMail',
  REJECTIONNOTICE = 'RejectionNotice',
  SIGNEDFORM8821 = 'SignedForm8821',
  SIGNEDSS4 = 'SignedSS4',
  STATECOMPLIANCE = 'StateCompliance',
  UNSIGNEDFORM8821 = 'UnsignedForm8821',
  UNSIGNEDSS4 = 'UnsignedSS4'
}

export type DudaSiteStatus = {
  __typename?: 'DudaSiteStatus';
  account_name?: Maybe<Scalars['String']>;
  canonical_url?: Maybe<Scalars['String']>;
  cookie_notification__enabled?: Maybe<Scalars['Boolean']>;
  creation_date?: Maybe<Scalars['String']>;
  edit_site_url?: Maybe<Scalars['String']>;
  force_https?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  modification_date?: Maybe<Scalars['String']>;
  overview_site_url?: Maybe<Scalars['String']>;
  preview_site_url?: Maybe<Scalars['String']>;
  publish_status?: Maybe<SiteStatus>;
  site_default_domain?: Maybe<Scalars['String']>;
  site_name?: Maybe<Scalars['String']>;
  store_status?: Maybe<Scalars['String']>;
  template_id?: Maybe<Scalars['Int']>;
};

export type EditTaxMemberInfo = {
  __typename?: 'EditTaxMemberInfo';
  memberInfo?: Maybe<TaxMemberInfo>;
};

export type EditTaxMemberInfoInput = {
  member?: InputMaybe<TaxMemberInfoInput>;
  position: Scalars['Int'];
};

export type EmailPassword = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export enum EmailTypes {
  ACCOUNTCREATED = 'AccountCreated',
  ANNUALREPORTCOMPLETE = 'AnnualReportComplete',
  BOIFILINGCOMPLETE = 'BoiFilingComplete',
  COMPANYDISSOLUTIONCOMPLETE = 'CompanyDissolutionComplete',
  EINRECEIVED = 'EinReceived',
  EINUPDATED = 'EinUpdated',
  FILINGRECEIVED = 'FilingReceived',
  FILINGUNDERREVIEW = 'FilingUnderReview',
  IRSNOTICE = 'IrsNotice',
  NEWNAMENEEDED = 'NewNameNeeded',
  PHONELINE = 'PhoneLine',
  POSTALMAILRECEIVED = 'PostalMailReceived',
  SECONDSUBMISSIONSENT = 'SecondSubmissionSent',
  SS4SENTTOIRS = 'Ss4SentToIrs',
  STATECOMPLIANCENOTICE = 'StateComplianceNotice',
  TRUSTPILOT = 'Trustpilot'
}

/**
 * EntityType, an enumeration of the current company types we have in the Formation product. Other parts of
 * the API move the "US" qualification into the type name, such as USState, however for the company type enum
 * the "US" qualification is a prefix on the enum members, such that other international company types may be
 * formed via the same endpoints in the future without splitting the API into US and non-US subdomains.
 */
export enum EntityType {
  CCORP = 'CCorp',
  DAO = 'DAO',
  LLC = 'LLC'
}

export type ExchangeRate = {
  __typename?: 'ExchangeRate';
  additionalFxMarkup?: Maybe<Scalars['String']>;
  audit_id?: Maybe<Scalars['String']>;
  destination_currency?: Maybe<Scalars['String']>;
  fx_hold_id?: Maybe<Scalars['String']>;
  fx_rate?: Maybe<Scalars['String']>;
  hold_expiry_at?: Maybe<Scalars['String']>;
  markup_rate?: Maybe<Scalars['String']>;
  source_currency?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type ExchangeRateResponse = {
  __typename?: 'ExchangeRateResponse';
  error?: Maybe<Scalars['String']>;
  payload?: Maybe<ExchangeRate>;
};

export enum ExpediteOptions {
  EXPEDITED = 'Expedited',
  EXPEDITED1DAY = 'Expedited1Day',
  STANDARD = 'Standard'
}

export type FaxRecord8821 = {
  __typename?: 'FaxRecord8821';
  message?: Maybe<Scalars['String']>;
  phaxioId?: Maybe<Scalars['Int']>;
};

export type FeeInfo = {
  __typename?: 'FeeInfo';
  currencyCode?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  doolaRate?: Maybe<Scalars['Float']>;
  niumPerTransactionFee?: Maybe<Scalars['Float']>;
  niumRate?: Maybe<Scalars['Float']>;
};

export type FeeInfoResponse = {
  __typename?: 'FeeInfoResponse';
  error?: Maybe<Scalars['String']>;
  payload?: Maybe<FeeInfo>;
};

export type FormationMutation = {
  __typename?: 'FormationMutation';
  /**
   * Create a company, this is a provisional step which creates the initial "stub" company record.
   * With a stub company created, subsequent steps collect additional information required to form
   * in different states.
   */
  oneShotFormation?: Maybe<Company>;
};


export type FormationMutationOneShotFormationArgs = {
  company: OneShotCompanyInfo;
};

export type GeneratedSs4 = {
  __typename?: 'GeneratedSS4';
  companyId: Scalars['ID'];
  documentType?: Maybe<DocumentType>;
  s3Key?: Maybe<Scalars['String']>;
};

export type GeneratedUploadUrl = {
  __typename?: 'GeneratedUploadUrl';
  url: Scalars['String'];
};

export type GeneratedUrl = {
  __typename?: 'GeneratedUrl';
  tags?: Maybe<Array<Maybe<UploadMetaDataType>>>;
  url: Scalars['String'];
};

/**
 * Three letter country codes according to ISO 3166-1 Alpha-3. Three character shortcodes
 * are preferred for visual disambiguation from the two letter state codes contained in
 * ISO 3166-2.
 */
export enum Iso31661Alpha3 {
  /** Aruba */
  ABW = 'ABW',
  /** Afghanistan */
  AFG = 'AFG',
  /** Angola */
  AGO = 'AGO',
  /** Anguilla */
  AIA = 'AIA',
  /** Åland Islands */
  ALA = 'ALA',
  /** Albania */
  ALB = 'ALB',
  /** Andorra */
  AND = 'AND',
  /** United Arab Emirates */
  ARE = 'ARE',
  /** Argentina */
  ARG = 'ARG',
  /** Armenia */
  ARM = 'ARM',
  /** American Samoa */
  ASM = 'ASM',
  /** Antarctica */
  ATA = 'ATA',
  /** French Southern Territories */
  ATF = 'ATF',
  /** Antigua and Barbuda */
  ATG = 'ATG',
  /** Australia */
  AUS = 'AUS',
  /** Austria */
  AUT = 'AUT',
  /** Azerbaijan */
  AZE = 'AZE',
  /** Burundi */
  BDI = 'BDI',
  /** Belgium */
  BEL = 'BEL',
  /** Benin */
  BEN = 'BEN',
  /** Bonaire, Sint Eustatius and Saba */
  BES = 'BES',
  /** Burkina Faso */
  BFA = 'BFA',
  /** Bangladesh */
  BGD = 'BGD',
  /** Bulgaria */
  BGR = 'BGR',
  /** Bahrain */
  BHR = 'BHR',
  /** Bahamas */
  BHS = 'BHS',
  /** Bosnia and Herzegovina */
  BIH = 'BIH',
  /** Saint Barthélemy */
  BLM = 'BLM',
  /** Belarus */
  BLR = 'BLR',
  /** Belize */
  BLZ = 'BLZ',
  /** Bermuda */
  BMU = 'BMU',
  /** Bolivia (Plurinational State of) */
  BOL = 'BOL',
  /** Brazil */
  BRA = 'BRA',
  /** Barbados */
  BRB = 'BRB',
  /** Brunei Darussalam */
  BRN = 'BRN',
  /** Bhutan */
  BTN = 'BTN',
  /** Bouvet Island */
  BVT = 'BVT',
  /** Botswana */
  BWA = 'BWA',
  /** Central African Republic */
  CAF = 'CAF',
  /** Canada */
  CAN = 'CAN',
  /** Cocos (Keeling) Islands */
  CCK = 'CCK',
  /** Switzerland */
  CHE = 'CHE',
  /** Chile */
  CHL = 'CHL',
  /** China */
  CHN = 'CHN',
  /** Côte d'Ivoire */
  CIV = 'CIV',
  /** Cameroon */
  CMR = 'CMR',
  /** Congo, Democratic Republic of the */
  COD = 'COD',
  /** Congo */
  COG = 'COG',
  /** Cook Islands */
  COK = 'COK',
  /** Colombia */
  COL = 'COL',
  /** Comoros */
  COM = 'COM',
  /** Cabo Verde */
  CPV = 'CPV',
  /** Costa Rica */
  CRI = 'CRI',
  /** Cuba */
  CUB = 'CUB',
  /** Curaçao */
  CUW = 'CUW',
  /** Christmas Island */
  CXR = 'CXR',
  /** Cayman Islands */
  CYM = 'CYM',
  /** Cyprus */
  CYP = 'CYP',
  /** Czechia */
  CZE = 'CZE',
  /** Germany */
  DEU = 'DEU',
  /** Djibouti */
  DJI = 'DJI',
  /** Dominica */
  DMA = 'DMA',
  /** Denmark */
  DNK = 'DNK',
  /** Dominican Republic */
  DOM = 'DOM',
  /** Algeria */
  DZA = 'DZA',
  /** Ecuador */
  ECU = 'ECU',
  /** Egypt */
  EGY = 'EGY',
  /** Eritrea */
  ERI = 'ERI',
  /** Western Sahara */
  ESH = 'ESH',
  /** Spain */
  ESP = 'ESP',
  /** Estonia */
  EST = 'EST',
  /** Ethiopia */
  ETH = 'ETH',
  /** Finland */
  FIN = 'FIN',
  /** Fiji */
  FJI = 'FJI',
  /** Falkland Islands (Malvinas) */
  FLK = 'FLK',
  /** France */
  FRA = 'FRA',
  /** Faroe Islands */
  FRO = 'FRO',
  /** onesia (Federated States of) */
  FSM = 'FSM',
  /** Gabon */
  GAB = 'GAB',
  /** United Kingdom of Great Britain and Northern Ireland */
  GBR = 'GBR',
  /** Georgia */
  GEO = 'GEO',
  /** Guernsey */
  GGY = 'GGY',
  /** Ghana */
  GHA = 'GHA',
  /** Gibraltar */
  GIB = 'GIB',
  /** Guinea */
  GIN = 'GIN',
  /** Guadeloupe */
  GLP = 'GLP',
  /** Gambia */
  GMB = 'GMB',
  /** Guinea-Bissau */
  GNB = 'GNB',
  /** Equatorial Guinea */
  GNQ = 'GNQ',
  /** Greece */
  GRC = 'GRC',
  /** Grenada */
  GRD = 'GRD',
  /** Greenland */
  GRL = 'GRL',
  /** Guatemala */
  GTM = 'GTM',
  /** French Guiana */
  GUF = 'GUF',
  /** Guam */
  GUM = 'GUM',
  /** Guyana */
  GUY = 'GUY',
  /** Hong Kong */
  HKG = 'HKG',
  /** Heard Island and McDonald Islands */
  HMD = 'HMD',
  /** Honduras */
  HND = 'HND',
  /** Croatia */
  HRV = 'HRV',
  /** Haiti */
  HTI = 'HTI',
  /** Hungary */
  HUN = 'HUN',
  /** Indonesia */
  IDN = 'IDN',
  /** Isle of Man */
  IMN = 'IMN',
  /** India */
  IND = 'IND',
  /** British Indian Ocean Territory */
  IOT = 'IOT',
  /** Ireland */
  IRL = 'IRL',
  /** Iran (Islamic Republic of) */
  IRN = 'IRN',
  /** Iraq */
  IRQ = 'IRQ',
  /** Iceland */
  ISL = 'ISL',
  /** Israel */
  ISR = 'ISR',
  /** Italy */
  ITA = 'ITA',
  /** Jamaica */
  JAM = 'JAM',
  /** Jersey */
  JEY = 'JEY',
  /** Jordan */
  JOR = 'JOR',
  /** Japan */
  JPN = 'JPN',
  /** Kazakhstan */
  KAZ = 'KAZ',
  /** Kenya */
  KEN = 'KEN',
  /** Kyrgyzstan */
  KGZ = 'KGZ',
  /** Cambodia */
  KHM = 'KHM',
  /** Kiribati */
  KIR = 'KIR',
  /** Saint Kitts and Nevis */
  KNA = 'KNA',
  /** Korea, Republic of */
  KOR = 'KOR',
  /** Kuwait */
  KWT = 'KWT',
  /** Lao People's Democratic Republic */
  LAO = 'LAO',
  /** Lebanon */
  LBN = 'LBN',
  /** Liberia */
  LBR = 'LBR',
  /** Libya */
  LBY = 'LBY',
  /** Saint Lucia */
  LCA = 'LCA',
  /** Liechtenstein */
  LIE = 'LIE',
  /** Sri Lanka */
  LKA = 'LKA',
  /** Lesotho */
  LSO = 'LSO',
  /** Lithuania */
  LTU = 'LTU',
  /** Luxembourg */
  LUX = 'LUX',
  /** Latvia */
  LVA = 'LVA',
  /** Macao */
  MAC = 'MAC',
  /** Saint Martin (French part) */
  MAF = 'MAF',
  /** Morocco */
  MAR = 'MAR',
  /** Monaco */
  MCO = 'MCO',
  /** Moldova, Republic of */
  MDA = 'MDA',
  /** Madagascar */
  MDG = 'MDG',
  /** Maldives */
  MDV = 'MDV',
  /** Mexico */
  MEX = 'MEX',
  /** Marshall Islands */
  MHL = 'MHL',
  /** North Macedonia */
  MKD = 'MKD',
  /** Mali */
  MLI = 'MLI',
  /** Malta */
  MLT = 'MLT',
  /** Myanmar */
  MMR = 'MMR',
  /** Montenegro */
  MNE = 'MNE',
  /** Mongolia */
  MNG = 'MNG',
  /** Northern Mariana Islands */
  MNP = 'MNP',
  /** Mozambique */
  MOZ = 'MOZ',
  /** Mauritania */
  MRT = 'MRT',
  /** Montserrat */
  MSR = 'MSR',
  /** Martinique */
  MTQ = 'MTQ',
  /** Mauritius */
  MUS = 'MUS',
  /** Malawi */
  MWI = 'MWI',
  /** Malaysia */
  MYS = 'MYS',
  /** Mayotte */
  MYT = 'MYT',
  MICR = 'Micr',
  /** Namibia */
  NAM = 'NAM',
  /** New Caledonia */
  NCL = 'NCL',
  /** Niger */
  NER = 'NER',
  /** Norfolk Island */
  NFK = 'NFK',
  /** Nigeria */
  NGA = 'NGA',
  /** Nicaragua */
  NIC = 'NIC',
  /** Niue */
  NIU = 'NIU',
  /** Netherlands */
  NLD = 'NLD',
  /** Norway */
  NOR = 'NOR',
  /** Nepal */
  NPL = 'NPL',
  /** Nauru */
  NRU = 'NRU',
  /** New Zealand */
  NZL = 'NZL',
  /** Oman */
  OMN = 'OMN',
  /** Pakistan */
  PAK = 'PAK',
  /** Panama */
  PAN = 'PAN',
  /** Pitcairn */
  PCN = 'PCN',
  /** Peru */
  PER = 'PER',
  /** Philippines */
  PHL = 'PHL',
  /** Palau */
  PLW = 'PLW',
  /** Papua New Guinea */
  PNG = 'PNG',
  /** Poland */
  POL = 'POL',
  /** Puerto Rico */
  PRI = 'PRI',
  /** Korea (Democratic People's Republic of) */
  PRK = 'PRK',
  /** Portugal */
  PRT = 'PRT',
  /** Paraguay */
  PRY = 'PRY',
  /** Palestine, State of */
  PSE = 'PSE',
  /** French Polynesia */
  PYF = 'PYF',
  /** Qatar */
  QAT = 'QAT',
  /** Réunion */
  REU = 'REU',
  /** Romania */
  ROU = 'ROU',
  /** Russian Federation */
  RUS = 'RUS',
  /** Rwanda */
  RWA = 'RWA',
  /** Saudi Arabia */
  SAU = 'SAU',
  /** Sudan */
  SDN = 'SDN',
  /** Senegal */
  SEN = 'SEN',
  /** Singapore */
  SGP = 'SGP',
  /** South Georgia and the South Sandwich Islands */
  SGS = 'SGS',
  /** Saint Helena, Ascension and Tristan da Cunha */
  SHN = 'SHN',
  /** Svalbard and Jan Mayen */
  SJM = 'SJM',
  /** Solomon Islands */
  SLB = 'SLB',
  /** Sierra Leone */
  SLE = 'SLE',
  /** El Salvador */
  SLV = 'SLV',
  /** San Marino */
  SMR = 'SMR',
  /** Somalia */
  SOM = 'SOM',
  /** Saint Pierre and Miquelon */
  SPM = 'SPM',
  /** Serbia */
  SRB = 'SRB',
  /** South Sudan */
  SSD = 'SSD',
  /** Sao Tome and Principe */
  STP = 'STP',
  /** Suriname */
  SUR = 'SUR',
  /** Slovakia */
  SVK = 'SVK',
  /** Slovenia */
  SVN = 'SVN',
  /** Sweden */
  SWE = 'SWE',
  /** Eswatini */
  SWZ = 'SWZ',
  /** Sint Maarten (Dutch part) */
  SXM = 'SXM',
  /** Seychelles */
  SYC = 'SYC',
  /** Syrian Arab Republic */
  SYR = 'SYR',
  /** Turks and Caicos Islands */
  TCA = 'TCA',
  /** Chad */
  TCD = 'TCD',
  /** Togo */
  TGO = 'TGO',
  /** Thailand */
  THA = 'THA',
  /** Tajikistan */
  TJK = 'TJK',
  /** Tokelau */
  TKL = 'TKL',
  /** Turkmenistan */
  TKM = 'TKM',
  /** Timor-Leste */
  TLS = 'TLS',
  /** Tonga */
  TON = 'TON',
  /** Trinidad and Tobago */
  TTO = 'TTO',
  /** Tunisia */
  TUN = 'TUN',
  /** Türkiye */
  TUR = 'TUR',
  /** Tuvalu */
  TUV = 'TUV',
  /** Taiwan, Province of China */
  TWN = 'TWN',
  /** Tanzania, United Republic of */
  TZA = 'TZA',
  /** Uganda */
  UGA = 'UGA',
  /** Ukraine */
  UKR = 'UKR',
  /** United States Minor Outlying Islands */
  UMI = 'UMI',
  /** Uruguay */
  URY = 'URY',
  /** United States of America */
  USA = 'USA',
  /** Uzbekistan */
  UZB = 'UZB',
  /** Holy See */
  VAT = 'VAT',
  /** Saint Vincent and the Grenadines */
  VCT = 'VCT',
  /** Venezuela (Bolivarian Republic of) */
  VEN = 'VEN',
  /** Virgin Islands (British) */
  VGB = 'VGB',
  /** Virgin Islands (U.S.) */
  VIR = 'VIR',
  /** Viet Nam */
  VNM = 'VNM',
  /** Vanuatu */
  VUT = 'VUT',
  /** Wallis and Futuna */
  WLF = 'WLF',
  /** Samoa */
  WSM = 'WSM',
  /** Republic of Kosovo */
  XKX = 'XKX',
  XXK = 'XXK',
  XXX = 'XXX',
  /** Yemen */
  YEM = 'YEM',
  /** South Africa */
  ZAF = 'ZAF',
  /** Zambia */
  ZMB = 'ZMB',
  /** Zimbabwe */
  ZWE = 'ZWE'
}

export enum Iso31662Us {
  /** Alaska */
  AK = 'AK',
  /** Alabama */
  AL = 'AL',
  /** Arkansas */
  AR = 'AR',
  /** American Samoa */
  AS = 'AS',
  /** Arizona */
  AZ = 'AZ',
  /** California */
  CA = 'CA',
  /** Colarado */
  CO = 'CO',
  /** Conneticut */
  CT = 'CT',
  /** District of Columbia */
  DC = 'DC',
  /** Delaware */
  DE = 'DE',
  /** Florida */
  FL = 'FL',
  /** Georgia */
  GA = 'GA',
  /** Guam */
  GU = 'GU',
  /** Hawaii */
  HI = 'HI',
  /** Iowa */
  IA = 'IA',
  /** Idaho */
  ID = 'ID',
  /** Illinois */
  IL = 'IL',
  /** Indiana */
  IN = 'IN',
  /** Kansas */
  KS = 'KS',
  /** Kentucky */
  KY = 'KY',
  /** Louisiana */
  LA = 'LA',
  /** Massachusetts */
  MA = 'MA',
  /** Maryland */
  MD = 'MD',
  /** Maine */
  ME = 'ME',
  /** Michigan */
  MI = 'MI',
  /** Minnesota */
  MN = 'MN',
  /** Missouri */
  MO = 'MO',
  /** Northern Mariana Islands */
  MP = 'MP',
  /** Mississippi */
  MS = 'MS',
  /** Montana */
  MT = 'MT',
  /** North Carolina */
  NC = 'NC',
  /** North Dakota */
  ND = 'ND',
  /** Nebraska */
  NE = 'NE',
  /** New Hampshire */
  NH = 'NH',
  /** New Jersey */
  NJ = 'NJ',
  /** New Mexico */
  NM = 'NM',
  /** Nevada */
  NV = 'NV',
  /** New Toyk */
  NY = 'NY',
  /** Ohio */
  OH = 'OH',
  /** Oklahoma */
  OK = 'OK',
  /** Oregon */
  OR = 'OR',
  /** Pensylvania */
  PA = 'PA',
  /** Puerto Rico */
  PR = 'PR',
  /** Rhose Island */
  RI = 'RI',
  /** South Carolina */
  SC = 'SC',
  /** South Dakota */
  SD = 'SD',
  /** Tennessee */
  TN = 'TN',
  /** Texas */
  TX = 'TX',
  /** United Stated Minor Outlying Islands */
  UM = 'UM',
  /** Utah */
  UT = 'UT',
  /** Virginia */
  VA = 'VA',
  /** Virgin Islands, U.S. */
  VI = 'VI',
  /** Vermont */
  VT = 'VT',
  /** Washington */
  WA = 'WA',
  /** Wisconsin */
  WI = 'WI',
  /** West Virginia */
  WV = 'WV',
  /** Wyoming */
  WY = 'WY'
}

export type InputAddress = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type InternalDetailsInput = {
  comment?: InputMaybe<Scalars['String']>;
};

export type LogCommand = {
  __typename?: 'LogCommand';
  events?: Maybe<Array<LogEvent>>;
  name?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
  token_claims?: Maybe<Scalars['String']>;
};

export type LogEvent = {
  __typename?: 'LogEvent';
  name?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['String']>;
};

export type Lookup = {
  __typename?: 'Lookup';
  description?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
};

export type LookupCategory = {
  __typename?: 'LookupCategory';
  lookups?: Maybe<Array<Maybe<Lookup>>>;
  name?: Maybe<LookupTypeKey>;
};

export type LookupResponse = {
  __typename?: 'LookupResponse';
  error?: Maybe<Scalars['String']>;
  payload?: Maybe<Array<Maybe<Lookup>>>;
};

export enum LookupTypeKey {
  ANNUALTURNOVER = 'ANNUALTURNOVER',
  BUSINESSTYPE = 'BUSINESSTYPE',
  COUNTRYNAME = 'COUNTRYNAME',
  COUNTRYOFOPERATION = 'COUNTRYOFOPERATION',
  DOCUMENTTYPE = 'DOCUMENTTYPE',
  INDUSTRYSECTOR = 'INDUSTRYSECTOR',
  INTENDEDUSEOFACCOUNT = 'INTENDEDUSEOFACCOUNT',
  LISTEDEXCHANGE = 'LISTEDEXCHANGE',
  POSITION = 'POSITION',
  RESTRICTEDCOUNTRIES = 'RESTRICTEDCOUNTRIES',
  RFITEMPLATES = 'RFITEMPLATES',
  STATE = 'STATE',
  TOTALEMPLOYEES = 'TOTALEMPLOYEES',
  UNREGULATEDTRUSTTYPE = 'UNREGULATEDTRUSTTYPE'
}

export type LookupsResponse = {
  __typename?: 'LookupsResponse';
  error?: Maybe<Scalars['String']>;
  payload?: Maybe<Array<Maybe<LookupCategory>>>;
};

export enum ManagedByTypes {
  MANAGER = 'Manager',
  MEMBER = 'Member'
}

export type Member = {
  __typename?: 'Member';
  address: Address;
  contactFullName?: Maybe<Scalars['String']>;
  isNaturalPerson: Scalars['Boolean'];
  legalFirstName: Scalars['String'];
  legalLastName: Scalars['String'];
  /** In the case of C-Corps this may be present */
  ownershipAbsolute?: Maybe<Scalars['Int']>;
  /** In the case of LLCs ownershipPercent should be used, and should always sum to 100%. */
  ownershipPercent?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Int']>;
  ssn?: Maybe<Scalars['String']>;
};

export type MemberInput = {
  address: AddressInput;
  contactFullName?: InputMaybe<Scalars['String']>;
  isNaturalPerson: Scalars['Boolean'];
  legalFirstName: Scalars['String'];
  legalLastName: Scalars['String'];
  ownershipAbsolute?: InputMaybe<Scalars['Int']>;
  ownershipPercent?: InputMaybe<Scalars['Float']>;
  position?: InputMaybe<Scalars['Int']>;
  ssn?: InputMaybe<Scalars['String']>;
};

/** MIGRATION INPUTS */
export type MigrateCompanyInput = {
  aggregatedState: Scalars['String'];
  applicantAddress: ApplicantAddressInput;
  companyDetails: CompanyDetailsInput;
  documents?: InputMaybe<DocumentInput>;
  id: Scalars['String'];
  internalDetails?: InputMaybe<InternalDetailsInput>;
  services: Array<ServiceInput>;
  status: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type MigrateCustomerInput = {
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  createdAt?: InputMaybe<Scalars['String']>;
  customerIoId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['String'];
  intercomId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  stripeId?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

export type MoneyAccount = {
  __typename?: 'MoneyAccount';
  accountCreateDate?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  balances?: Maybe<Array<Maybe<MoneyAccountBalances>>>;
  bankAddress?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  doolaCompanyId?: Maybe<Scalars['String']>;
  doolaCustomerId?: Maybe<Scalars['String']>;
  fullBankName?: Maybe<Scalars['String']>;
  routingCodeType1?: Maybe<Scalars['String']>;
  routingCodeType2?: Maybe<Scalars['String']>;
  routingCodeValue1?: Maybe<Scalars['String']>;
  routingCodeValue2?: Maybe<Scalars['String']>;
  status?: Maybe<AccountStatusType>;
  uniquePaymentId?: Maybe<Scalars['String']>;
};

export type MoneyAccountBalances = {
  __typename?: 'MoneyAccountBalances';
  balance?: Maybe<Scalars['Float']>;
  curSymbol?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  withHoldingBalance?: Maybe<Scalars['Float']>;
};

export type MoneyAccountResponse = {
  __typename?: 'MoneyAccountResponse';
  error?: Maybe<Scalars['String']>;
  payload?: Maybe<Array<Maybe<MoneyAccount>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addUserSurveyResponse?: Maybe<UserSurveyResponseResult>;
  auth?: Maybe<AuthMutation>;
  beneficiary?: Maybe<WalletBeneficiaryResponse>;
  company?: Maybe<CompanyMutation>;
  customer?: Maybe<CustomerMutation>;
  deleteBeneficiary?: Maybe<DataServiceResponse>;
  documents?: Maybe<DocumentMutations>;
  formation?: Maybe<FormationMutation>;
  manuallyCreateCompany?: Maybe<Company>;
  myToken?: Maybe<ResolverContext>;
  onboard?: Maybe<WalletOnboardResponse>;
  onboardingValidation?: Maybe<WalletOnboardDocumentResponse>;
  status?: Maybe<Scalars['String']>;
  transfer?: Maybe<DataServiceResponse>;
  updateBeneficiary?: Maybe<WalletBeneficiaryResponse>;
};


export type MutationAddUserSurveyResponseArgs = {
  userSurveyResponse: UserSurveyResponseInput;
};


export type MutationBeneficiaryArgs = {
  beneficiaryDetails: BeneficiaryDetails;
};


export type MutationCompanyArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationCustomerArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBeneficiaryArgs = {
  beneficiaryHashId: Scalars['String'];
  doolaCompanyId: Scalars['String'];
};


export type MutationManuallyCreateCompanyArgs = {
  company: OneShotCompanyInfo;
};


export type MutationOnboardArgs = {
  onboarding?: InputMaybe<WalletOnboardInput>;
};


export type MutationOnboardingValidationArgs = {
  onboarding?: InputMaybe<WalletOnboardInput>;
};


export type MutationTransferArgs = {
  doolaCompanyId: Scalars['String'];
  transferRequest: WalletTransferRequest;
};


export type MutationUpdateBeneficiaryArgs = {
  beneficiaryDetails: BeneficiaryDetails;
  beneficiaryHashId: Scalars['String'];
};

export type NameInput = {
  first: Scalars['String'];
  last: Scalars['String'];
};

export type NewUser = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
};

export type OnboardingApplicant = {
  __typename?: 'OnboardingApplicant';
  additionalInfoApplicationDeclaration?: Maybe<Scalars['String']>;
  address?: Maybe<WalletAddress>;
  contactCountryCode?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactNumber?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Maybe<WalletOnboardingDocument>>>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  professionalDetailsPositions?: Maybe<Array<Maybe<Scalars['String']>>>;
  validationErrors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OnboardingApplicantInput = {
  additionalInfoApplicationDeclaration?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<WalletAddressInput>;
  contactCountryCode?: InputMaybe<Scalars['String']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactNumber?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  documents?: InputMaybe<Array<InputMaybe<WalletOnboardingDocumentInput>>>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  professionalDetailsPositions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  validationErrors?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum OnboardingBusinessType {
  CORPORATION = 'CORPORATION',
  ESTATE = 'ESTATE',
  GENERAL_PARTNERSHIP = 'GENERAL_PARTNERSHIP',
  LIMITED_LIABILITY_COMPANY = 'LIMITED_LIABILITY_COMPANY',
  LIMITED_LIABILITY_PARTNERSHIP = 'LIMITED_LIABILITY_PARTNERSHIP',
  LIMITED_PARTNERSHIP = 'LIMITED_PARTNERSHIP',
  PUBLIC_COMPANY = 'PUBLIC_COMPANY',
  SOLE_TRADER = 'SOLE_TRADER',
  TRUST = 'TRUST',
  UNINCORP_ASSOCIATION = 'UNINCORP_ASSOCIATION'
}

export type OnboardingCompanyInfo = {
  __typename?: 'OnboardingCompanyInfo';
  address?: Maybe<WalletAddress>;
  businessName?: Maybe<Scalars['String']>;
  businessRegistrationNumber?: Maybe<Scalars['String']>;
  businessType?: Maybe<OnboardingBusinessType>;
  description?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Maybe<WalletOnboardingDocument>>>;
  legalRegisteredDate?: Maybe<Scalars['String']>;
  registeredCountry?: Maybe<Scalars['String']>;
  validationErrors?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Scalars['String']>;
};

export type OnboardingCompanyInfoInput = {
  address?: InputMaybe<WalletAddressInput>;
  businessName?: InputMaybe<Scalars['String']>;
  businessRegistrationNumber?: InputMaybe<Scalars['String']>;
  businessType?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  documents?: InputMaybe<Array<InputMaybe<WalletOnboardingDocumentInput>>>;
  legalRegisteredDate?: InputMaybe<Scalars['String']>;
  registeredCountry?: InputMaybe<Scalars['String']>;
  validationErrors?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  website?: InputMaybe<Scalars['String']>;
};

export enum OnboardingDocumentType {
  ASSOCIATION_DEED = 'ASSOCIATION_DEED',
  BUSINESS_REGISTRATION_DOC = 'BUSINESS_REGISTRATION_DOC',
  CERTIFICATE_OF_GOOD_STANDING = 'CERTIFICATE_OF_GOOD_STANDING',
  DRIVER_LICENCE = 'DRIVER_LICENCE',
  LOA = 'LOA',
  NATIONAL_ID = 'NATIONAL_ID',
  PARTNERSHIP_DEED = 'PARTNERSHIP_DEED',
  PASSPORT = 'PASSPORT',
  POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
  PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
  PROOF_OF_EXISTENCE = 'PROOF_OF_EXISTENCE',
  TRUST_DEED = 'TRUST_DEED'
}

export type OnboardingRiskAssessmentInfo = {
  __typename?: 'OnboardingRiskAssessmentInfo';
  annualTurnover?: Maybe<Scalars['String']>;
  countriesOfOperation?: Maybe<Array<Maybe<Scalars['String']>>>;
  industrySector?: Maybe<Scalars['String']>;
  intendedUseOfAccount?: Maybe<Scalars['String']>;
  totalNumberOfEmployees?: Maybe<Scalars['String']>;
  transactionCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
  validationErrors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OnboardingRiskAssessmentInfoInput = {
  annualTurnover?: InputMaybe<Scalars['String']>;
  countriesOfOperation?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  industrySector?: InputMaybe<Scalars['String']>;
  intendedUseOfAccount?: InputMaybe<Scalars['String']>;
  totalNumberOfEmployees?: InputMaybe<Scalars['String']>;
  transactionCountries?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  validationErrors?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type OnboardingStakeholder = {
  __typename?: 'OnboardingStakeholder';
  address?: Maybe<WalletAddress>;
  businessEntityType?: Maybe<OnboardingStakeholderBusinessEntityType>;
  /** type == 'business' */
  businessName?: Maybe<Scalars['String']>;
  businessRegistrationNumber?: Maybe<Scalars['String']>;
  businessType?: Maybe<OnboardingBusinessType>;
  dateOfBirth?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Maybe<WalletOnboardingDocument>>>;
  /** type == 'person' */
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  ownershipPercentage?: Maybe<Scalars['String']>;
  professionalDetails?: Maybe<Array<Maybe<Scalars['String']>>>;
  registeredCountry?: Maybe<Scalars['String']>;
  type?: Maybe<OnboardingStakeholderType>;
  uuid?: Maybe<Scalars['String']>;
  validationErrors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum OnboardingStakeholderBusinessEntityType {
  CONTROL_PRONG = 'CONTROL_PRONG',
  DIRECTOR = 'DIRECTOR',
  EXECUTOR = 'EXECUTOR',
  MEMBERS = 'MEMBERS',
  PARTNER = 'PARTNER',
  PROTECTOR = 'PROTECTOR',
  SETTLOR = 'SETTLOR',
  SHAREHOLDER = 'SHAREHOLDER',
  SIGNATORY = 'SIGNATORY',
  TRUSTEE = 'TRUSTEE',
  UBO = 'UBO'
}

export type OnboardingStakeholderInput = {
  address?: InputMaybe<WalletAddressInput>;
  businessEntityType?: InputMaybe<OnboardingStakeholderBusinessEntityType>;
  /** type == 'business' */
  businessName?: InputMaybe<Scalars['String']>;
  businessRegistrationNumber?: InputMaybe<Scalars['String']>;
  businessType?: InputMaybe<OnboardingBusinessType>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  documents?: InputMaybe<Array<InputMaybe<WalletOnboardingDocumentInput>>>;
  /** type == 'person' */
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  ownershipPercentage?: InputMaybe<Scalars['String']>;
  professionalDetails?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  registeredCountry?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<OnboardingStakeholderType>;
  uuid?: InputMaybe<Scalars['String']>;
  validationErrors?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum OnboardingStakeholderType {
  BUSINESS = 'business',
  PERSON = 'person'
}

export enum OnboardingStatusType {
  COMPLETED = 'COMPLETED',
  INITIATED = 'INITIATED',
  INPROGRESS = 'INPROGRESS',
  REJECTED = 'REJECTED'
}

export type OneShotCompanyInfo = {
  activity?: InputMaybe<Scalars['String']>;
  addressType?: InputMaybe<AddressType>;
  description?: InputMaybe<Scalars['String']>;
  entityType: EntityType;
  members: Array<MemberInput>;
  nameOptions?: InputMaybe<Array<InputMaybe<CompanyNameInput>>>;
  responsibleParty?: InputMaybe<ResponsiblePartyInput>;
  ss4SignatureRecipientEmail?: InputMaybe<Scalars['String']>;
  state: Iso31662Us;
  totalShares?: InputMaybe<Scalars['Int']>;
  usMailingAddress?: InputMaybe<UsAddressInput>;
};

export type OwnerInput = {
  fullName: Scalars['String'];
  isCompany: Scalars['Boolean'];
  ownership: Scalars['Float'];
};

/**
 * PageInfo per the GraphQL Cursor Connections Specification
 * https://relay.dev/graphql/connections.htm
 */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** optional, the last cursor in this page */
  endCursor?: Maybe<Scalars['String']>;
  /** true if this page has a next page */
  hasNextPage: Scalars['Boolean'];
  /** true if this page has a previous page */
  hasPreviousPage: Scalars['Boolean'];
  /** optional, the first cursor in this page */
  startCursor?: Maybe<Scalars['String']>;
};

export enum PandadocName {
  BOOKMATE_ENGAGEMENT = 'BOOKMATE_ENGAGEMENT',
  DOOLA_ENGAGEMENT = 'DOOLA_ENGAGEMENT'
}

export enum PandadocStatus {
  APPROVED = 'APPROVED',
  COMPLETED = 'COMPLETED',
  DECLINED = 'DECLINED',
  DEFAULT = 'DEFAULT',
  DRAFT = 'DRAFT',
  PAID = 'PAID',
  REJECTED = 'REJECTED',
  SENT = 'SENT',
  UPLOADED = 'UPLOADED',
  VIEWED = 'VIEWED',
  VOIDED = 'VOIDED',
  WAITING_APPROVAL = 'WAITING_APPROVAL',
  WAITING_PAY = 'WAITING_PAY'
}

/**
 * Partner represents any partner entity (including Doola ourselves) who can create users, customers,
 * companies, etc. Your service account, or personal API token will indicate which partners you can
 * access, and any access to Partner listing (edge) endpoints will take into account your RBAC+ visibility
 * into those Partners.
 */
export type Partner = {
  __typename?: 'Partner';
  /** Lists companies under this partner */
  companies?: Maybe<Companies>;
  /** Lists companies under this partner */
  customers?: Maybe<Customers>;
  /** Name will only include charactfers in the range UTF-8 0x0020 to 0x007E */
  name: Scalars['String'];
  /** conforms to the GraphQL Cursor Connections Specification */
  products: Products;
  /** Search Customers and Companies in this Partner. */
  search?: Maybe<SearchResults>;
};


/**
 * Partner represents any partner entity (including Doola ourselves) who can create users, customers,
 * companies, etc. Your service account, or personal API token will indicate which partners you can
 * access, and any access to Partner listing (edge) endpoints will take into account your RBAC+ visibility
 * into those Partners.
 */
export type PartnerCompaniesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


/**
 * Partner represents any partner entity (including Doola ourselves) who can create users, customers,
 * companies, etc. Your service account, or personal API token will indicate which partners you can
 * access, and any access to Partner listing (edge) endpoints will take into account your RBAC+ visibility
 * into those Partners.
 */
export type PartnerCustomersArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


/**
 * Partner represents any partner entity (including Doola ourselves) who can create users, customers,
 * companies, etc. Your service account, or personal API token will indicate which partners you can
 * access, and any access to Partner listing (edge) endpoints will take into account your RBAC+ visibility
 * into those Partners.
 */
export type PartnerSearchArgs = {
  terms?: InputMaybe<Scalars['String']>;
};

export type PartnerEdge = {
  __typename?: 'PartnerEdge';
  cursor: Scalars['String'];
  node?: Maybe<Partner>;
};

export type PartnerQuery = {
  __typename?: 'PartnerQuery';
  all: Partners;
};

export type Partners = {
  __typename?: 'Partners';
  edges?: Maybe<Array<Maybe<PartnerEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export enum PayoutMethodType {
  LOCAL = 'LOCAL',
  SWIFT = 'SWIFT'
}

/**
 * The Product sub-type is a work in progress type for exposing Products (aka Packages) via our API for specific
 * partners
 */
export type Product = {
  __typename?: 'Product';
  duration?: Maybe<ProductDuration>;
  /** The ID, an opaque string. Safe for storage. */
  id: Scalars['ID'];
  key: Scalars['String'];
  /** Name will only include characters in the range UTF-8 0x0020 to 0x007E */
  name: Scalars['String'];
  productType: Scalars['String'];
  stripePricingId: Scalars['String'];
  usdPriceCents?: Maybe<Scalars['Int']>;
};

export enum ProductDuration {
  ANNUAL = 'Annual',
  MONTHLY = 'Monthly',
  ONETIME = 'OneTime'
}

/**
 * Paginated products according to the GraphQL Cursor Connections Specification
 * https://relay.dev/graphql/connections.htm
 * Paginated products. Note that in many cases the `cursor`, and `node.id` will be identical, as there is no intermediate
 * "edge" in the graph, however this must not be relied upon. Please do not store cursors or expect them to be portable or durable.
 */
export type ProductEdge = {
  __typename?: 'ProductEdge';
  cursor: Scalars['String'];
  node?: Maybe<Product>;
};

export type ProductServiceResult = {
  __typename?: 'ProductServiceResult';
  error?: Maybe<Scalars['String']>;
  payload?: Maybe<Array<Maybe<Product>>>;
};

export type Products = {
  __typename?: 'Products';
  edges?: Maybe<Array<Maybe<ProductEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export enum PurposeCode {
  IR001_TRANSFER_TO_OWN_ACCOUNT = 'IR001_Transfer_to_own_account',
  IR002_FAMILY_MAINTENANCE = 'IR002_Family_Maintenance',
  IR003_EDUCATION_RELATED_STUDENT_EXPENSES = 'IR003_Education_related_student_expenses',
  IR004_MEDICAL_TREATMENT = 'IR004_Medical_Treatment',
  IR005_HOTEL_ACCOMMODATION = 'IR005_Hotel_Accommodation',
  IR006_TRAVEL = 'IR006_Travel',
  IR007_UTILITY_BILLS = 'IR007_Utility_Bills',
  IR008_REPAYMENT_OF_LOANS = 'IR008_Repayment_of_Loans',
  IR009_TAX_PAYMENT = 'IR009_Tax_Payment',
  IR010_PURCHASE_OF_RESIDENTIAL_PROPERTY = 'IR010_Purchase_of_Residential_Property',
  IR011_PAYMENT_OF_PROPERTY_RENTAL = 'IR011_Payment_of_Property_Rental',
  IR012_INSURANCE_PREMIUM = 'IR012_Insurance_Premium',
  IR013_PRODUCT_INDEMNITY_INSURANCE = 'IR013_Product_indemnity_insurance',
  IR014_INSURANCE_CLAIMS_PAYMENT = 'IR014_Insurance_Claims_Payment',
  IR015_MUTUAL_FUND_INVESTMENT = 'IR015_Mutual_Fund_Investment',
  IR016_INVESTMENT_IN_SHARES = 'IR016_Investment_in_Shares',
  IR017_DONATIONS = 'IR017_Donations',
  IR01801_INFORMATION_SERVICE_CHARGES = 'IR01801_Information_Service_Charges',
  IR01802_ADVERTISING_AND_PUBLIC_RELATIONS_RELATED_EXPENSES = 'IR01802_Advertising_and_Public_relations_related_expenses',
  IR01803_ROYALTY_FEES_TRADEMARK_FEES_PATENT_FEES_COPYRIGHT_FEES = 'IR01803_Royalty_fees_trademark_fees_patent_fees_copyright_fees',
  IR01804_FEES_FOR_BROKERS_FRONT_END_FEE_COMMITMENT_FEE_GUARANTEE_FEE_AND_CUSTODIAN_FEE = 'IR01804_Fees_for_brokers_front_end_fee_commitment_fee_guarantee_fee_and_custodian_fee',
  IR01805_FEES_FOR_ADVISORS_TECHNICAL_ASSISTANCE_AND_ACADEMIC_KNOWLEDGE_INCLUDING_REMUNERATION_FOR_SPECIALISTS = 'IR01805_Fees_for_advisors_technical_assistance_and_academic_knowledge_including_remuneration_for_specialists',
  IR01806_REPRESENTATIVE_OFFICE_EXPENSES = 'IR01806_Representative_office_expenses',
  IR01807_CONSTRUCTION_COSTS_EXPENSES = 'IR01807_Construction_costs_expenses',
  IR01808_TRANSPORTATION_FEES_FOR_GOODS = 'IR01808_Transportation_fees_for_goods',
  IR01809_FOR_PAYMENT_OF_EXPORTED_GOODS = 'IR01809_For_payment_of_exported_goods',
  IR01810_DELIVERY_FEES_FOR_GOODS = 'IR01810_Delivery_fees_for_goods',
  IR01811_GENERAL_GOODS_TRADES_OFFLINE_TRADE = 'IR01811_General_Goods_Trades_Offline_trade'
}

export type Query = {
  __typename?: 'Query';
  accounts?: Maybe<MoneyAccountResponse>;
  beneficiaries?: Maybe<BeneficiaryList>;
  company?: Maybe<Company>;
  countries?: Maybe<Array<Maybe<Country>>>;
  currencyFeeInfo?: Maybe<FeeInfoResponse>;
  customer?: Maybe<Customer>;
  documents?: Maybe<DocumentQueries>;
  exchangeRate?: Maybe<ExchangeRateResponse>;
  getBeneficiary?: Maybe<BeneficiaryResponsePayload>;
  getCurrency?: Maybe<CurrencyDetailList>;
  getRoutingCode?: Maybe<RoutingCodeList>;
  lookup?: Maybe<LookupResponse>;
  lookups?: Maybe<LookupsResponse>;
  myToken?: Maybe<ResolverContext>;
  onboardingDocument?: Maybe<WalletOnboardDocumentResponse>;
  partner?: Maybe<Partner>;
  /** Partner(s) related queries are isolated to this graph */
  partners?: Maybe<PartnerQuery>;
  productService?: Maybe<ProductServiceResult>;
  recentBeneficiaries?: Maybe<BeneficiaryList>;
  registeredAgentAddresses?: Maybe<RegisteredAgentAddressResponse>;
  /** List of reward offers available to the public */
  rewards?: Maybe<Array<Maybe<Reward>>>;
  statements?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** Retrieve details for a stripe session */
  stripeSessionDetails?: Maybe<StripeSessionDetails>;
  transactions?: Maybe<WalletTransactionResponse>;
  transactionsAggregate?: Maybe<TransactionAggregatePayload>;
  userSurvey?: Maybe<UserSurveyResult>;
  userSurveyResponse?: Maybe<UserSurveyResponsesResult>;
};


export type QueryAccountsArgs = {
  doolaCompanyId: Scalars['String'];
};


export type QueryBeneficiariesArgs = {
  doolaCompanyId?: InputMaybe<Scalars['String']>;
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryCurrencyFeeInfoArgs = {
  currencyCode: Scalars['String'];
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryExchangeRateArgs = {
  destinationCurrencyCode: Scalars['String'];
  doolaCompanyId: Scalars['String'];
  sourceCurrencyCode: Scalars['String'];
};


export type QueryGetBeneficiaryArgs = {
  beneficiaryId?: InputMaybe<Scalars['String']>;
  doolaCompanyId?: InputMaybe<Scalars['String']>;
};


export type QueryGetCurrencyArgs = {
  countryCode: Scalars['String'];
};


export type QueryGetRoutingCodeArgs = {
  countryCode: Scalars['String'];
};


export type QueryLookupArgs = {
  category: LookupTypeKey;
};


export type QueryOnboardingDocumentArgs = {
  onboarding?: InputMaybe<WalletOnboardInput>;
};


export type QueryPartnerArgs = {
  name: Scalars['String'];
};


export type QueryRecentBeneficiariesArgs = {
  doolaCompanyId?: InputMaybe<Scalars['String']>;
};


export type QueryRegisteredAgentAddressesArgs = {
  state?: InputMaybe<Scalars['String']>;
};


export type QueryStatementsArgs = {
  doolaCompanyId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryStripeSessionDetailsArgs = {
  sessionId: Scalars['String'];
};


export type QueryTransactionsArgs = {
  doolaCompanyId?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['String']>;
};


export type QueryTransactionsAggregateArgs = {
  doolaCompanyId?: InputMaybe<Scalars['String']>;
};


export type QueryUserSurveyArgs = {
  key: Scalars['String'];
};


export type QueryUserSurveyResponseArgs = {
  doolaCustomerId: Scalars['String'];
  key: Scalars['String'];
};

export type RaInHouse = {
  __typename?: 'RaInHouse';
  formationFilingDate?: Maybe<Scalars['String']>;
  formationFilingNumber?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type RaPortal = {
  __typename?: 'RaPortal';
  id?: Maybe<Scalars['String']>;
};

export enum ReasonCode {
  PAYMENT_TO_SELF_04 = 'Payment_to_Self_04',
  RECURRING_TRANSACTIONS_03 = 'Recurring_Transactions_03',
  TRUSTED_BENEFICIARY_01 = 'Trusted_Beneficiary_01'
}

export type RefreshTokensInput = {
  idToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type RegisteredAgent = {
  __typename?: 'RegisteredAgent';
  inHouse?: Maybe<RaInHouse>;
  portal?: Maybe<RaPortal>;
};

export type RegisteredAgentAddress = {
  __typename?: 'RegisteredAgentAddress';
  address?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fein?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  individual?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  registeredAgent?: Maybe<Scalars['String']>;
  registeredAgentId?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  stateAbbreviation: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type RegisteredAgentAddressResponse = {
  __typename?: 'RegisteredAgentAddressResponse';
  error?: Maybe<Scalars['String']>;
  payload?: Maybe<Array<Maybe<RegisteredAgentAddress>>>;
};

export type RemoveServiceInput = {
  forced?: InputMaybe<Scalars['Boolean']>;
  service: CompanyServices;
  variant?: InputMaybe<ServiceVariant>;
};

/**
 * A diagnostic query to help you ensure that your token has all the claims and access
 * you expect.
 */
export type ResolverContext = {
  __typename?: 'ResolverContext';
  authorizationToken?: Maybe<Scalars['String']>;
  authorizedCompanyIds?: Maybe<Scalars['String']>;
  authorizedCustomerId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isExpired: Scalars['Boolean'];
};

export type ResponsibleParty = {
  __typename?: 'ResponsibleParty';
  address: Address;
  legalFirstName: Scalars['String'];
  legalLastName: Scalars['String'];
  ssn?: Maybe<Scalars['String']>;
};

export type ResponsiblePartyInput = {
  address: AddressInput;
  legalFirstName: Scalars['String'];
  legalLastName: Scalars['String'];
  ssn?: InputMaybe<Scalars['String']>;
};

export type Reward = {
  __typename?: 'Reward';
  /** Offer description in english */
  body: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** If this offer has an eligibilityProcess */
  eligibilityProcess?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  isDoolaReward?: Maybe<Scalars['Boolean']>;
  /** Commercial name of offer */
  name: Scalars['String'];
  /** URL to offer website */
  offerUrl: Scalars['String'];
  /** URL to retrieve logo for the offer */
  pictureUrl?: Maybe<Scalars['String']>;
  /** Product category */
  productCategory?: Maybe<Scalars['String']>;
};

export type RoutingCodeList = {
  __typename?: 'RoutingCodeList';
  error?: Maybe<Scalars['String']>;
  payload?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Ss4FaxRecord = {
  __typename?: 'SS4FaxRecord';
  message?: Maybe<Scalars['String']>;
  phaxioId?: Maybe<Scalars['Int']>;
};

export type Ss4PayloadInput = {
  callbackType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  documentState?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type SavedAnswer = {
  answer?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  questionId?: InputMaybe<Scalars['String']>;
};

export type SearchResult = Company | Customer;

export type SearchResultEdge = {
  __typename?: 'SearchResultEdge';
  cursor: Scalars['String'];
  node?: Maybe<SearchResult>;
};

export type SearchResults = {
  __typename?: 'SearchResults';
  edges?: Maybe<Array<Maybe<SearchResultEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type SecretOffersRedeemed = {
  __typename?: 'SecretOffersRedeemed';
  instructions: Scalars['String'];
  offerId: Scalars['Int'];
};

export enum ServiceActor {
  CUSTOMER = 'Customer',
  SERVICEPROVIDER = 'ServiceProvider',
  THIRDPARTY = 'ThirdParty'
}

export enum ServiceCategory {
  ACTIVE = 'Active',
  CANCELED = 'Canceled',
  COMPLETED = 'Completed',
  INPROGRESS = 'InProgress',
  NOTAVAILABLE = 'NotAvailable',
  NOTSTARTED = 'NotStarted'
}

export type ServiceForProduct = {
  __typename?: 'ServiceForProduct';
  duration?: Maybe<ProductDuration>;
  hideFromUi?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  service: CompanyServices;
  variant: ServiceVariant;
  version?: Maybe<Scalars['String']>;
};

export type ServiceInput = {
  name: Scalars['String'];
  serviceState: Scalars['String'];
  status: Scalars['String'];
  updatedAt: Scalars['String'];
  validUntil: Scalars['String'];
};

export enum ServiceVariant {
  COMPLETE = 'Complete',
  ESSENTIAL = 'Essential',
  EXECUTIVE = 'Executive',
  EXPEDITE = 'Expedite',
  INVOICEONLY = 'InvoiceOnly',
  NONE = 'NONE',
  PREMIER = 'Premier',
  PREMIUM = 'Premium',
  STANDARD = 'Standard'
}

export type Services = {
  __typename?: 'Services';
  hideFromUi?: Maybe<Scalars['Boolean']>;
  nextActor?: Maybe<ServiceActor>;
  service: CompanyServices;
  status: ServiceCategory;
  subStatus?: Maybe<Scalars['String']>;
  variant?: Maybe<ServiceVariant>;
};

export type SignUpResponse = {
  __typename?: 'SignUpResponse';
  companyIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  customerId: Scalars['String'];
  email: Scalars['String'];
  isReturningUser: Scalars['Boolean'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
  tokens: AuthTokens;
};

export type SignedDocument = {
  __typename?: 'SignedDocument';
  documentId?: Maybe<Scalars['String']>;
  name?: Maybe<PandadocName>;
  sharedUrl?: Maybe<Scalars['String']>;
  status?: Maybe<PandadocStatus>;
};

export type SignedDocumentUploadUrl = {
  __typename?: 'SignedDocumentUploadUrl';
  name: Scalars['String'];
  url: Scalars['String'];
};

export enum SiteStatus {
  NOT_PUBLISHED_YET = 'NOT_PUBLISHED_YET',
  PUBLISHED = 'PUBLISHED'
}

export enum SourceOfFunds {
  BUSINESS_OWNERSHAREHOLDER = 'Business_OwnerShareholder',
  CORPORATE_ACCOUNT = 'Corporate_Account',
  LOAN_FACILITY = 'Loan_Facility',
  PERSONAL_ACCOUNT = 'Personal_Account',
  PERSONAL_SAVINGS = 'Personal_Savings',
  PERSONAL_WEALTH = 'Personal_Wealth',
  RETIREMENT_FUNDS = 'Retirement_Funds',
  SALARY = 'Salary'
}

export type StripeBillingPortalMetadata = {
  __typename?: 'StripeBillingPortalMetadata';
  url: Scalars['String'];
};

export type StripeCheckoutSession = {
  __typename?: 'StripeCheckoutSession';
  /** Session ID */
  id: Scalars['ID'];
  /** Stripe Customer ID for this checkout session */
  stripeCustomerId: Scalars['ID'];
  /** Secure Stripe Session URL. Use to redirect the user to a stripe checkout page */
  url?: Maybe<Scalars['String']>;
};

export type StripePaymentLink = {
  __typename?: 'StripePaymentLink';
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type StripeSessionDetails = {
  __typename?: 'StripeSessionDetails';
  amountSubtotal?: Maybe<Scalars['Float']>;
  amountTotal?: Maybe<Scalars['Float']>;
  created: Scalars['Int'];
  id: Scalars['ID'];
  paymentIntentId?: Maybe<Scalars['String']>;
  status?: Maybe<StripeSessionStatus>;
  totalDetails?: Maybe<StripeSessionTotalDetails>;
  url?: Maybe<Scalars['String']>;
};

export enum StripeSessionStatus {
  COMPLETE = 'Complete',
  EXPIRED = 'Expired',
  OPEN = 'Open'
}

export type StripeSessionTotalDetails = {
  __typename?: 'StripeSessionTotalDetails';
  amountDiscount?: Maybe<Scalars['Float']>;
  amountShipping?: Maybe<Scalars['Float']>;
  amountTax?: Maybe<Scalars['Float']>;
};

export enum SwiftFeeType {
  OUR = 'OUR',
  SHA = 'SHA'
}

export type TaxAddress = {
  __typename?: 'TaxAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type TaxAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export enum TaxFilingPrepStatus {
  DONE = 'done',
  INPROGRESS = 'inProgress'
}

export type TaxInfo = {
  __typename?: 'TaxInfo';
  companyTaxInfo?: Maybe<CompanyTaxInfo>;
  members?: Maybe<Array<Maybe<TaxMemberInfo>>>;
};

export type TaxInfoInput = {
  companyTaxInfo?: InputMaybe<CompanyTaxInfoInput>;
  members?: InputMaybe<Array<InputMaybe<TaxMemberInfoInput>>>;
};

export type TaxMemberInfo = {
  __typename?: 'TaxMemberInfo';
  address?: Maybe<TaxAddress>;
  contactFullName?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  ein?: Maybe<Scalars['String']>;
  entityType?: Maybe<EntityType>;
  isNaturalPerson?: Maybe<Scalars['Boolean']>;
  legalFirstName?: Maybe<Scalars['String']>;
  legalLastName?: Maybe<Scalars['String']>;
  /** In the case of C-Corps this may be present */
  ownershipAbsolute?: Maybe<Scalars['Int']>;
  /** In the case of LLCs ownershipPercent should be used, and should always sum to 100%. */
  ownershipPercent?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Int']>;
  ssn?: Maybe<Scalars['String']>;
};

export type TaxMemberInfoInput = {
  address?: InputMaybe<InputAddress>;
  contactFullName?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['String']>;
  ein?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<EntityType>;
  isNaturalPerson?: InputMaybe<Scalars['Boolean']>;
  legalFirstName?: InputMaybe<Scalars['String']>;
  legalLastName?: InputMaybe<Scalars['String']>;
  /** In the case of C-Corps this may be present */
  ownershipAbsolute?: InputMaybe<Scalars['Int']>;
  /** In the case of LLCs ownershipPercent should be used, and should always sum to 100%. */
  ownershipPercent?: InputMaybe<Scalars['Float']>;
  position?: InputMaybe<Scalars['Int']>;
  ssn?: InputMaybe<Scalars['String']>;
};

export type TaxQuestionAnswers = {
  __typename?: 'TaxQuestionAnswers';
  answer?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['String']>;
};

export type TransactionAggregate = {
  __typename?: 'TransactionAggregate';
  dimension?: Maybe<Scalars['String']>;
  moneyIn?: Maybe<Scalars['Float']>;
  moneyOut?: Maybe<Scalars['Float']>;
};

export type TransactionAggregatePayload = {
  __typename?: 'TransactionAggregatePayload';
  error?: Maybe<Scalars['String']>;
  payload?: Maybe<Array<Maybe<TransactionAggregate>>>;
};

export type UsAddress = {
  __typename?: 'USAddress';
  city: Scalars['String'];
  country: Iso31661Alpha3;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  state: Scalars['String'];
};

export type UsAddressInput = {
  city: Scalars['String'];
  country: Iso31661Alpha3;
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  state: Scalars['String'];
};

export type UnitBankAccountTokenCredentials = {
  __typename?: 'UnitBankAccountTokenCredentials';
  accountId?: Maybe<Scalars['String']>;
  applicationId?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  unitCustomerId?: Maybe<Scalars['String']>;
};

export type UpcomingConsultations = {
  __typename?: 'UpcomingConsultations';
  email?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
  eventType?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
};

export type UploadMetaData = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type UploadMetaDataType = {
  __typename?: 'UploadMetaDataType';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type UpsellServices = {
  __typename?: 'UpsellServices';
  service: CompanyServices;
  variant?: Maybe<ServiceVariant>;
};

export type UserSurvey = {
  __typename?: 'UserSurvey';
  activeQuestionnaire?: Maybe<UserSurveyQuestionnaire>;
  created?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lookupKey?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updated?: Maybe<Scalars['String']>;
};

export type UserSurveyAnswer = {
  __typename?: 'UserSurveyAnswer';
  answerText?: Maybe<Scalars['String']>;
  doolaUserSurveyQuestionId: Scalars['String'];
  optionId?: Maybe<Scalars['String']>;
  questionType: Scalars['String'];
  rangeValue?: Maybe<Scalars['String']>;
};

export type UserSurveyAnswerInput = {
  answerText?: InputMaybe<Scalars['String']>;
  doolaUserSurveyQuestionId: Scalars['String'];
  optionId?: InputMaybe<Scalars['String']>;
  questionType: Scalars['String'];
  rangeValue?: InputMaybe<Scalars['String']>;
};

export type UserSurveyQuestion = {
  __typename?: 'UserSurveyQuestion';
  created?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lookupKey?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<UserSurveyQuestionOption>>>;
  qidx?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Int']>;
  textOption?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
};

export type UserSurveyQuestionOption = {
  __typename?: 'UserSurveyQuestionOption';
  id?: Maybe<Scalars['String']>;
  optionIdx?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type UserSurveyQuestionnaire = {
  __typename?: 'UserSurveyQuestionnaire';
  created?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<Maybe<UserSurveyQuestion>>>;
  updated?: Maybe<Scalars['String']>;
};

export type UserSurveyResponse = {
  __typename?: 'UserSurveyResponse';
  answers?: Maybe<Array<Maybe<UserSurveyAnswer>>>;
  created?: Maybe<Scalars['String']>;
  doolaCustomerId?: Maybe<Scalars['String']>;
  doolaUserSurveyId: Scalars['String'];
};

export type UserSurveyResponseInput = {
  answers?: InputMaybe<Array<InputMaybe<UserSurveyAnswerInput>>>;
  doolaCustomerId?: InputMaybe<Scalars['String']>;
  doolaUserSurveyId: Scalars['String'];
};

export type UserSurveyResponseResult = {
  __typename?: 'UserSurveyResponseResult';
  error?: Maybe<Scalars['String']>;
  payload?: Maybe<UserSurveyResponse>;
};

export type UserSurveyResponsesResult = {
  __typename?: 'UserSurveyResponsesResult';
  error?: Maybe<Scalars['String']>;
  payload?: Maybe<Array<Maybe<UserSurveyResponse>>>;
};

export type UserSurveyResult = {
  __typename?: 'UserSurveyResult';
  error?: Maybe<Scalars['String']>;
  payload?: Maybe<UserSurvey>;
};

export type WalletAddress = {
  __typename?: 'WalletAddress';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  state: Scalars['String'];
};

export type WalletAddressInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  postalCode?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  validationErrors?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type WalletBeneficiaryResponse = {
  __typename?: 'WalletBeneficiaryResponse';
  error?: Maybe<Scalars['String']>;
  payload?: Maybe<BeneficiaryResponse>;
};

export type WalletOnboard = {
  __typename?: 'WalletOnboard';
  applicant?: Maybe<OnboardingApplicant>;
  companyInfo?: Maybe<OnboardingCompanyInfo>;
  createdAt?: Maybe<Scalars['String']>;
  doolaCompanyId: Scalars['String'];
  doolaCustomerId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isSameBusinessAddress?: Maybe<Scalars['Boolean']>;
  riskAssessmentInfo?: Maybe<OnboardingRiskAssessmentInfo>;
  stakeholders?: Maybe<Array<Maybe<OnboardingStakeholder>>>;
  status?: Maybe<OnboardingStatusType>;
  updatedAt?: Maybe<Scalars['String']>;
  validationErrors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type WalletOnboardDocumentResponse = {
  __typename?: 'WalletOnboardDocumentResponse';
  error?: Maybe<Scalars['String']>;
  payload?: Maybe<WalletOnboard>;
};

export type WalletOnboardInput = {
  applicant?: InputMaybe<OnboardingApplicantInput>;
  companyInfo?: InputMaybe<OnboardingCompanyInfoInput>;
  createdAt?: InputMaybe<Scalars['String']>;
  doolaCompanyId: Scalars['String'];
  doolaCustomerId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  isSameBusinessAddress?: InputMaybe<Scalars['Boolean']>;
  riskAssessmentInfo?: InputMaybe<OnboardingRiskAssessmentInfoInput>;
  stakeholders?: InputMaybe<Array<InputMaybe<OnboardingStakeholderInput>>>;
  status?: InputMaybe<OnboardingStatusType>;
  updatedAt?: InputMaybe<Scalars['String']>;
  validationErrors?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type WalletOnboardResponse = {
  __typename?: 'WalletOnboardResponse';
  error?: Maybe<Array<Maybe<Scalars['String']>>>;
  payload?: Maybe<WalletOnboard>;
};

export type WalletOnboardingDocument = {
  __typename?: 'WalletOnboardingDocument';
  docServiceFileRefId?: Maybe<Scalars['String']>;
  documentIssuanceCountry?: Maybe<Scalars['String']>;
  documentNumber?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
  onboardingDocType?: Maybe<OnboardingDocumentType>;
  validationErrors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type WalletOnboardingDocumentInput = {
  docServiceFileRefId?: InputMaybe<Scalars['String']>;
  documentIssuanceCountry?: InputMaybe<Scalars['String']>;
  documentNumber?: InputMaybe<Scalars['String']>;
  expirationDate?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  fileType?: InputMaybe<Scalars['String']>;
  onboardingDocType?: InputMaybe<OnboardingDocumentType>;
  validationErrors?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type WalletTransaction = {
  __typename?: 'WalletTransaction';
  authAmount?: Maybe<Scalars['String']>;
  authCode?: Maybe<Scalars['String']>;
  authCurrencyCode?: Maybe<Scalars['String']>;
  beneficiaryId?: Maybe<Scalars['String']>;
  beneficiaryName?: Maybe<Scalars['String']>;
  billingAmount?: Maybe<Scalars['String']>;
  billingCurrencyCode?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customerHashId?: Maybe<Scalars['String']>;
  dateOfTransaction?: Maybe<Scalars['String']>;
  debit?: Maybe<Scalars['Boolean']>;
  destinationCountry?: Maybe<Scalars['String']>;
  displayText?: Maybe<Scalars['String']>;
  doolaCompanyId?: Maybe<Scalars['String']>;
  doolaCustomerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  payoutMethod?: Maybe<Scalars['String']>;
  previousBalance?: Maybe<Scalars['String']>;
  retrievalReferenceNumber?: Maybe<Scalars['String']>;
  settlementAmount?: Maybe<Scalars['String']>;
  settlementAuthAmount?: Maybe<Scalars['String']>;
  settlementBillingAmount?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  systemTraceAuditNumber?: Maybe<Scalars['String']>;
  transactionCurrencyCode?: Maybe<Scalars['String']>;
  transactionType?: Maybe<Scalars['String']>;
  walletHashId?: Maybe<Scalars['String']>;
};

export type WalletTransactionResponse = {
  __typename?: 'WalletTransactionResponse';
  error?: Maybe<Scalars['String']>;
  payload?: Maybe<Array<Maybe<WalletTransaction>>>;
};

export type WalletTransferRequest = {
  amount: Scalars['String'];
  beneficiaryHashId: Scalars['String'];
  doolaRate?: InputMaybe<Scalars['String']>;
  purposeCode?: InputMaybe<PurposeCode>;
  reasonCode?: InputMaybe<ReasonCode>;
  sourceOfFunds?: InputMaybe<SourceOfFunds>;
  swiftFeeType?: InputMaybe<SwiftFeeType>;
};

export type Website = {
  __typename?: 'Website';
  domainName?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
};

export type ExecutiveName = {
  __typename?: 'executiveName';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type ExecutiveNameInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export enum Source {
  BANKING = 'BANKING',
  OTHER = 'OTHER',
  TAX = 'TAX'
}
