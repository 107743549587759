import { ProductDuration, ServiceCategory } from "graphql/__generated__/types";
import { IServiceData, SERVICE_STATUSES } from "./data";
import { Typography } from "@mui/material";
import { colors } from "utils";
import {
  HourglassTopRounded,
  PauseCircleOutlineRounded,
} from "@mui/icons-material";
import CheckRounded from "@mui/icons-material/CheckRounded";
import CloseRounded from "@mui/icons-material/CloseRounded";

export const renderServiceDuration = (
  duration: ProductDuration,
  fullString?: boolean
) => {
  switch (duration) {
    case ProductDuration.ANNUAL:
      return fullString ? "Annual subscription billed once per year." : "/year";
    case ProductDuration.MONTHLY:
      return fullString
        ? "Monthly subscription billed once per month."
        : "/month";
    case ProductDuration.ONETIME:
      return fullString ? "One-time payment billed once." : "One-time";
    default:
      return "";
  }
};

export const filterServicesByCategory = (
  servicesList: [string, IServiceData][],
  selectedFilter: string | null
): [string, IServiceData][] => {
  if (servicesList.length === 0) {
    return [];
  }

  if (!selectedFilter) {
    return servicesList;
  }

  return servicesList.filter(
    (service: [string, IServiceData]) =>
      service[1].subCategory === selectedFilter
  );
};

export const renderServiceStatus = (status: ServiceCategory) => {
  switch (status) {
    case ServiceCategory.ACTIVE:
    case ServiceCategory.COMPLETED:
      return (
        <>
          <CheckRounded sx={{ width: 18, color: colors.green }} />
          <Typography fontWeight={500} color={colors.green}>
            {SERVICE_STATUSES[status]}
          </Typography>
        </>
      );
    case ServiceCategory.NOTSTARTED:
      return (
        <>
          <PauseCircleOutlineRounded
            sx={{ width: 18, color: colors.charcoal }}
          />
          <Typography fontWeight={500} color={colors.charcoal}>
            {SERVICE_STATUSES[status]}
          </Typography>
        </>
      );
    case ServiceCategory.INPROGRESS:
      return (
        <>
          <HourglassTopRounded sx={{ width: 18, color: colors.yellow }} />
          <Typography fontWeight={500} color={colors.yellow}>
            {SERVICE_STATUSES[status]}
          </Typography>
        </>
      );
    case ServiceCategory.CANCELED:
    case ServiceCategory.NOTAVAILABLE:
      return (
        <>
          <CloseRounded sx={{ width: 18, color: colors.red }} />
          <Typography fontWeight={500} color={colors.red}>
            {SERVICE_STATUSES[status]}
          </Typography>
        </>
      );
  }
};
