import { useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  ClickAwayListener,
  IconButton,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MainCard from "components/MainCard";
import Transitions from "components/@extended/Transitions";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { colors } from "utils";

const Notification = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <IconButton
        disableRipple
        color="secondary"
        sx={{
          width: "100%",
          flex: "auto",
          justifyContent: "center",
          alignItems: "center",
          p: 1,
          bgcolor: colors.lightGrey,
          borderRadius: 4,
          color: colors.black,
          fontSize: "24px",
          "&:hover": {
            color: colors.contentInverseTertiary,
            transition: "all .2s ease-in-out",
          },
          "&::after": { boxShadow: "none" },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <NotificationsNoneOutlinedIcon />
      </IconButton>
      <Popper
        placement={matchesXs ? "bottom" : "bottom-end"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal={false}
        sx={{ zIndex: "1200" }}
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [matchesXs ? -5 : 0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: "100%",
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down("md")]: {
                  maxWidth: 285,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="Notifications"
                  elevation={0}
                  border={false}
                  content={false}
                >
                  <MainCard
                    sx={{
                      ".MuiCardContent-root": {
                        width: "200px",
                        minHeight: "200px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        margin: "auto",
                      },
                    }}
                  >
                    <NotificationsIcon
                      style={{
                        color: "#1e1e1e50",
                        fontSize: "20px",
                        marginBottom: "10px",
                      }}
                    />
                    <Typography color={colors.contentInverseTertiary}>
                      There are no notifications at the moment
                    </Typography>
                  </MainCard>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default Notification;
