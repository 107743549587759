import { useUserSurveyResultQuery } from "graphql/__generated__/operations/UserSurvey.generated";

export const USER_SURVEY_KEY: string = "USER_FEEDBACK_SURVEY";

const useUserSurvey = () => {
  const { data, loading: isUserSurveyLoading } = useUserSurveyResultQuery({
    errorPolicy: "all",
    variables: { key: USER_SURVEY_KEY },
  });

  return {
    userSurvey: data?.userSurvey,
    isUserSurveyLoading,
  };
};

export default useUserSurvey;
