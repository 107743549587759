import CompanyName from "./CompanyName";
import CompanyState from "./CompanyState";
import EntityTypeStep from "./EntityType";
import Registration from "./Registration";
import Success from "./Success";
import CountryOfResidence from "./CountryOfResidence";
import Packages from "./Packages";
import PostHog from "posthog-js";
import Expedited from "./Expedited";
import Review from "./Review";
import TotalCompliance from "./TotalCompliance";
import BoiFiling from "./BoiFiling";

export enum FormationScreenNames {
  CountryOfResidence = "Onboarding Country of Residence",
  EntityType = "Onboarding Entity Type",
  CompanyState = "Onboarding Entity State",
  CompanyName = "Onboarding Entity Name",
  Packages = "Onboarding Package Selection",
  Expedited = "Onboarding Expedited Selection",
  BoiFiling = "Onboarding BOI Filing Selection",
  Review = "Onboarding Review",
  TotalCompliance = "Onboarding Total Compliance",
}

export enum FormationSteps {
  Registration = 0,
  // TODO: Uncomment for phase 1 with company origin and change the order
  // CompanyOrigin = 1,
  CountryOfResidence = 1,
  EntityType = 2,
  CompanyState = 3,
  CompanyName = 4,
  TotalCompliance = 5,
  Packages = 6,
  Expedited = 7,
  BoiFiling = 8,
  Review = 9,
  Checkout = 10,
  PurchaseSuccess = 11,
}

export const getFormationSignupStepContent = (step: number) => {
  switch (step) {
    // TODO: Uncomment for phase 1 with company origin
    // case FormationSteps.CompanyOrigin:
    //   return <CompanyOrigin />;
    case FormationSteps.CountryOfResidence:
      return <CountryOfResidence />;
    case FormationSteps.EntityType:
      return <EntityTypeStep />;
    case FormationSteps.CompanyState:
      return <CompanyState />;
    case FormationSteps.CompanyName:
      return <CompanyName />;
    case FormationSteps.TotalCompliance:
      return <TotalCompliance />;
    case FormationSteps.Packages:
      return <Packages />;
    case FormationSteps.Expedited:
      return <Expedited />;
    case FormationSteps.BoiFiling:
      return <BoiFiling />;
    case FormationSteps.Review:
      return <Review />;
    case FormationSteps.PurchaseSuccess:
      return <Success />;
    default:
      return <Registration />;
  }
};

export const trackPosthogAddOn = (
  title: string,
  stripePriceId: string,
  price: number
) => {
  PostHog.capture("additional-product-selected", {
    additional_product_name: title,
    additional_product_id: stripePriceId,
    additional_product_value: price,
  });
};
