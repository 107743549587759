import { useEffect, useState } from "react";
import { useGetMoneyTransactionsAggregateLazyQuery } from "graphql/__generated__/operations/MoneyTransactionsAggregate.generated";
import useCompany from "graphql/hooks/UseCompany";

const useGetTransactionsAggregate = () => {
  const { currentCompany } = useCompany();

  const [isTransactionsAggregateLoading, setIsTransactionsAggregateLoading] =
    useState<boolean>(true);

  const [fetchTransactionsAggregate, { data }] =
    useGetMoneyTransactionsAggregateLazyQuery();

  useEffect(() => {
    setIsTransactionsAggregateLoading(true);

    if (currentCompany?.id) {
      const getTransactionsAggregate = async () => {
        try {
          await fetchTransactionsAggregate({
            variables: {
              companyId: currentCompany.id,
            },
          });
        } catch (error) {
          console.error(
            "Failed to fetch transactions aggregate, error: ",
            error
          );
        } finally {
          setIsTransactionsAggregateLoading(false);
        }
      };

      getTransactionsAggregate();
    }
  }, [currentCompany?.id, fetchTransactionsAggregate]);

  return {
    transactionsAggregate: data?.transactionsAggregate?.payload,
    isTransactionsAggregateLoading: isTransactionsAggregateLoading,
  };
};

export default useGetTransactionsAggregate;
