import ButtonBox from "components/ButtonBox";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import { Stack, TextField, Typography, useMediaQuery } from "@mui/material";
import utils, { colors } from "utils";
import { useEffect, useState } from "react";
import { Add, CloseOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import {
  INITIAL_CCORP_EXECUTIVE_VALUES,
  initialExecutiveValues,
} from "./helpers/initialCCorpExecutivesData";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { CcorpExecutiveInfo } from "graphql/__generated__/types";
import { useUpdateCompanyCCorpExecutiveInfoMutation } from "graphql/__generated__/operations/UpdateCompanyCCorpExecutiveInfo.generated";
import useCompany from "graphql/hooks/UseCompany";
import { Windows1252Regex } from "utils/regexValidations";
import { removeTypename } from "utils/helpers/removeTypename";
import ErrorBox from "components/ErrorBox";
import { useTranslation } from "react-i18next";

const OnboardingExecutive = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentCompany } = useCompany();

  const [updateCCorpExecutiveInfo] =
    useUpdateCompanyCCorpExecutiveInfoMutation();

  const { control, formState, handleSubmit, setValue } =
    useForm<CcorpExecutiveInfo>({
      defaultValues: { ...INITIAL_CCORP_EXECUTIVE_VALUES },
      mode: "onChange",
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "directors",
  });

  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const isDisabled = !formState.isValid;

  useEffect(() => {
    const ccorpExecInfo = removeTypename(currentCompany?.ccorpExecInfo);

    if (ccorpExecInfo) {
      setValue("president", ccorpExecInfo?.president);
      setValue("treasurer", ccorpExecInfo?.treasurer);
      setValue("secretary", ccorpExecInfo?.secretary);
      setValue("directors", ccorpExecInfo?.directors);
    }
  }, [currentCompany, setValue]);

  const onSubmitHandler = async (data: CcorpExecutiveInfo) => {
    try {
      setIsLoading(true);

      await updateCCorpExecutiveInfo({
        variables: {
          companyId: currentCompany?.id,
          ...data,
        },
      });

      navigate(ROUTER_PATHS.ONBOARDING_RESPONSIBLE_PARTY);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
      <Stack direction="column" gap={isTablet ? 3 : 4} mb={4}>
        <Stack gap={2}>
          <Typography variant="h1">
            {t("onboarding.ccorp.executive.title")}
          </Typography>
          <Typography color={colors.contentTertiary}>
            {t("onboarding.ccorp.executive.description")}
          </Typography>
        </Stack>
        <Stack gap={2}>
          <Typography fontSize={18} fontWeight={500}>
            {t("onboarding.ccorp.executive.president")}
          </Typography>
          <Stack direction={isTablet ? "column" : "row"} gap={2}>
            <Stack spacing={1} sx={{ width: "100%" }}>
              <Typography
                variant="h6"
                color={utils.secondaryColors.secondaryMain}
              >
                {t("onboarding.ccorp.executive.firstName")}
              </Typography>
              <Controller
                name="president.firstName"
                {...COMMON_PROPS}
                rules={{
                  required: true,
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={error !== undefined}
                    placeholder={
                      t(
                        "onboarding.ccorp.executive.firstName.placeholder"
                      ) as string
                    }
                    fullWidth
                    sx={{
                      ".MuiInputBase-root": {
                        height: "40px",
                      },
                    }}
                  />
                )}
              />
            </Stack>
            <Stack spacing={1} sx={{ width: "100%" }}>
              <Typography
                variant="h6"
                color={utils.secondaryColors.secondaryMain}
              >
                {t("onboarding.ccorp.executive.lastName")}
              </Typography>
              <Controller
                name="president.lastName"
                {...COMMON_PROPS}
                rules={{
                  required: true,
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={error !== undefined}
                    placeholder={
                      t(
                        "onboarding.ccorp.executive.lastName.placeholder"
                      ) as string
                    }
                    fullWidth
                    sx={{
                      ".MuiInputBase-root": {
                        height: "40px",
                      },
                    }}
                  />
                )}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack gap={2}>
          <Typography fontSize={18} fontWeight={500}>
            {t("onboarding.ccorp.executive.secretary")}
          </Typography>
          <Stack direction={isTablet ? "column" : "row"} gap={2}>
            <Stack spacing={1} sx={{ width: "100%" }}>
              <Typography
                variant="h6"
                color={utils.secondaryColors.secondaryMain}
              >
                {t("onboarding.ccorp.executive.firstName")}
              </Typography>
              <Controller
                name="secretary.firstName"
                {...COMMON_PROPS}
                rules={{
                  required: true,
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={error !== undefined}
                    placeholder={
                      t(
                        "onboarding.ccorp.executive.firstName.placeholder"
                      ) as string
                    }
                    fullWidth
                    sx={{
                      ".MuiInputBase-root": {
                        height: "40px",
                      },
                    }}
                  />
                )}
              />
            </Stack>
            <Stack spacing={1} sx={{ width: "100%" }}>
              <Typography
                variant="h6"
                color={utils.secondaryColors.secondaryMain}
              >
                {t("onboarding.ccorp.executive.lastName")}
              </Typography>
              <Controller
                name="secretary.lastName"
                {...COMMON_PROPS}
                rules={{
                  required: true,
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={error !== undefined}
                    placeholder={
                      t(
                        "onboarding.ccorp.executive.lastName.placeholder"
                      ) as string
                    }
                    fullWidth
                    sx={{
                      ".MuiInputBase-root": {
                        height: "40px",
                      },
                    }}
                  />
                )}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack gap={2}>
          <Typography fontSize={18} fontWeight={500}>
            {t("onboarding.ccorp.executive.treasurer")}
          </Typography>
          <Stack direction={isTablet ? "column" : "row"} gap={2}>
            <Stack spacing={1} sx={{ width: "100%" }}>
              <Typography
                variant="h6"
                color={utils.secondaryColors.secondaryMain}
              >
                {t("onboarding.ccorp.executive.firstName")}
              </Typography>
              <Controller
                name="treasurer.firstName"
                {...COMMON_PROPS}
                rules={{
                  required: true,
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={error !== undefined}
                    placeholder={
                      t(
                        "onboarding.ccorp.executive.firstName.placeholder"
                      ) as string
                    }
                    fullWidth
                    sx={{
                      ".MuiInputBase-root": {
                        height: "40px",
                      },
                    }}
                  />
                )}
              />
            </Stack>
            <Stack spacing={1} sx={{ width: "100%" }}>
              <Typography
                variant="h6"
                color={utils.secondaryColors.secondaryMain}
              >
                {t("onboarding.ccorp.executive.lastName")}
              </Typography>
              <Controller
                name="treasurer.lastName"
                {...COMMON_PROPS}
                rules={{
                  required: true,
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={error !== undefined}
                    placeholder={
                      t(
                        "onboarding.ccorp.executive.lastName.placeholder"
                      ) as string
                    }
                    fullWidth
                    sx={{
                      ".MuiInputBase-root": {
                        height: "40px",
                      },
                    }}
                  />
                )}
              />
            </Stack>
          </Stack>
        </Stack>
        {fields.map((director, index) => {
          return (
            <Stack key={director.id} gap={2}>
              <Stack direction={"row"} gap={2} alignItems={"center"}>
                <Typography fontSize={18} fontWeight={500}>
                  {t("onboarding.ccorp.executive.director") + ` #${index + 1}`}
                </Typography>
                {fields.length > 1 && (
                  <IconButton
                    onClick={() => remove(index)}
                    sx={{
                      alignSelf: "flex-end",
                      bgcolor: colors.grey,
                    }}
                  >
                    <CloseOutlined
                      sx={{
                        fontSize: "18px",
                        color: colors.red,
                      }}
                    />
                  </IconButton>
                )}
              </Stack>
              <Stack direction={isTablet ? "column" : "row"} gap={2}>
                <Stack spacing={1} sx={{ width: "100%" }}>
                  <Typography
                    variant="h6"
                    color={utils.secondaryColors.secondaryMain}
                  >
                    {t("onboarding.ccorp.executive.firstName")}
                  </Typography>
                  <Controller
                    name={`directors.${index}.firstName`}
                    {...COMMON_PROPS}
                    rules={{
                      required: true,
                      pattern: {
                        value: Windows1252Regex,
                        message: t("error.invalidCharacters"),
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={error !== undefined}
                        placeholder={
                          t(
                            "onboarding.ccorp.executive.firstName.placeholder"
                          ) as string
                        }
                        fullWidth
                        sx={{
                          ".MuiInputBase-root": {
                            height: "40px",
                          },
                        }}
                      />
                    )}
                  />
                </Stack>
                <Stack spacing={1} sx={{ width: "100%" }}>
                  <Typography
                    variant="h6"
                    color={utils.secondaryColors.secondaryMain}
                  >
                    {t("onboarding.ccorp.executive.lastName")}
                  </Typography>
                  <Controller
                    name={`directors.${index}.lastName`}
                    {...COMMON_PROPS}
                    rules={{
                      required: true,
                      pattern: {
                        value: Windows1252Regex,
                        message: t("error.invalidCharacters"),
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={error !== undefined}
                        placeholder={
                          t(
                            "onboarding.ccorp.executive.lastName.placeholder"
                          ) as string
                        }
                        fullWidth
                        sx={{
                          ".MuiInputBase-root": {
                            height: "40px",
                          },
                        }}
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Stack>
          );
        })}
        <Stack
          alignItems={"center"}
          borderRadius={3}
          p={3}
          gap={1.5}
          bgcolor={colors.lightGrey}
          sx={{ cursor: "pointer" }}
          onClick={() => append(initialExecutiveValues)}
        >
          <IconButton sx={{ bgcolor: colors.grey, padding: 2 }}>
            <Add />
          </IconButton>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={colors.contentSecondary}
          >
            {t("onboarding.ccorp.executive.addDirector")}
          </Typography>
        </Stack>
        <ErrorBox formState={formState} />
        <ButtonBox
          onClickBack={() => navigate(ROUTER_PATHS.ONBOARDING_DESCRIPTION)}
          onClickNext={() => onSubmitHandler}
          disabled={isDisabled}
          isLoading={isLoading}
        />
      </Stack>
    </form>
  );
};

export default OnboardingExecutive;
