import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { colors } from "utils";
import { IPlan, PackageValue } from "../data";
import { PACKAGES_FEATURES } from "./data";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { useFormContext } from "react-hook-form";
import { InfoToolTip } from "components/InfoToolTip";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import useSignupFlow from "graphql/hooks/UseSignupFlow";
import { useTranslation } from "react-i18next";
import { LoadingComponent } from "components/Loading";
import useGetMainProducts from "../hooks/useGetMainProducts";
import Grid from "@mui/material/Unstable_Grid2";

interface IComparisonTable {
  plan?: IPlan;
}

const renderTableValue = (value: boolean | string) => {
  if (typeof value === "boolean") {
    return <CheckRoundedIcon sx={{ verticalAlign: "middle" }} />;
  }

  return <Typography color={colors.contentInverseTertiary}>{value}</Typography>;
};

export const ComparisonTable = ({ plan }: IComparisonTable) => {
  const { t } = useTranslation();
  const { entityType } = useSignupFlow();

  const { PLANS, isProductQueryLoading } = useGetMainProducts();

  const { watch } = useFormContext();
  const currentSelectedPackage: PackageValue = watch("name");

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (!entityType || isProductQueryLoading) {
    return <LoadingComponent />;
  }

  return (
    <Grid container gap={2}>
      <Grid
        container
        xs={12}
        sx={{
          ...(!isMobile && {
            padding: "20px 24px",
          }),
        }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {!isMobile && <Grid xs={4}></Grid>}

        {Object.values(PLANS).map((item, index) => (
          <Grid key={index} sx={{ pb: 1 }} xs={6} sm={4}>
            <Stack
              direction={"row"}
              justifyContent="center"
              alignItems={"center"}
              gap={1}
            >
              <Typography
                variant="h5"
                fontWeight={500}
                sx={{
                  ...(currentSelectedPackage === item.value && {
                    borderRadius: "24px",
                    px: 2,
                    py: 1,
                    color: colors.white,
                    background: colors.blue,
                  }),
                }}
              >
                {item.name}
              </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
      {PACKAGES_FEATURES.map((feature, indexFeat) => (
        <Grid
          key={indexFeat}
          xs={12}
          container
          borderRadius={"16px"}
          sx={{
            ...(isMobile && {
              bgcolor: colors.lightGrey,
              p: 2,
              pb: 0,
            }),
          }}
          gap={2}
        >
          <Grid xs={12}>
            <Typography variant="h5" fontWeight={500}>
              {feature.name}
            </Typography>
          </Grid>
          <Grid xs={12} container>
            {feature.features.map((service, indexSrv) => (
              <Grid
                xs={12}
                container
                key={indexSrv}
                sx={{
                  alignItems: "center",
                  ...(indexSrv % 2 === 0 &&
                    !isMobile && {
                      bgcolor: colors.lightGrey,
                    }),
                  ...(!isMobile && {
                    borderRadius: "16px",
                    py: 2.5,
                    px: 2,
                  }),
                }}
              >
                <Grid
                  justifyContent={"center"}
                  alignItems={"center"}
                  xs={12}
                  sm={4}
                  sx={{
                    ...(isMobile && {
                      bgcolor: colors.darkGrey,
                      borderRadius: "8px",
                      py: 2,
                    }),
                  }}
                >
                  <Stack
                    height={"100%"}
                    direction={"row"}
                    {...(isMobile && { justifyContent: "center" })}
                    alignItems="center"
                    gap={1}
                  >
                    <Typography
                      sx={{
                        ...(isMobile && {
                          fontWeight: "500",
                        }),
                      }}
                    >
                      {t(service.name)}
                    </Typography>
                    {!isMobile && (
                      <InfoToolTip
                        title={t(service.name) as string}
                        description={
                          <Typography>{service.description}</Typography>
                        }
                        icon={
                          <HelpOutlineRoundedIcon
                            style={{ color: colors.black, fontSize: 14 }}
                          />
                        }
                        $styledCss={{ display: "flex" }}
                      />
                    )}
                  </Stack>
                </Grid>
                {plan ? (
                  <Grid
                    justifyContent={"center"}
                    alignItems={"center"}
                    xs={6}
                    sm={4}
                    sx={{
                      textAlign: "center",
                      ...(isMobile && {
                        py: 2,
                      }),
                    }}
                  >
                    {renderTableValue(service.packages[plan.value])}
                  </Grid>
                ) : (
                  Object.values(service.packages).map((value, indexVal) => (
                    <Grid
                      justifyContent={"center"}
                      alignItems={"center"}
                      xs={6}
                      sm={4}
                      key={indexVal}
                      sx={{
                        textAlign: "center",
                        ...(isMobile && {
                          py: 2,
                        }),
                      }}
                    >
                      {renderTableValue(value)}
                    </Grid>
                  ))
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
