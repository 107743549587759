import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddUserSurveyResponseMutationVariables = Types.Exact<{
  userSurveyResponse: Types.UserSurveyResponseInput;
}>;


export type AddUserSurveyResponseMutation = { __typename?: 'Mutation', addUserSurveyResponse?: { __typename?: 'UserSurveyResponseResult', error?: string | null, payload?: { __typename?: 'UserSurveyResponse', doolaUserSurveyId: string, doolaCustomerId?: string | null, answers?: Array<{ __typename?: 'UserSurveyAnswer', answerText?: string | null, doolaUserSurveyQuestionId: string, optionId?: string | null, questionType: string, rangeValue?: string | null } | null> | null } | null } | null };


export const AddUserSurveyResponseDocument = gql`
    mutation AddUserSurveyResponse($userSurveyResponse: UserSurveyResponseInput!) {
  addUserSurveyResponse(userSurveyResponse: $userSurveyResponse) {
    error
    payload {
      doolaUserSurveyId
      answers {
        answerText
        doolaUserSurveyQuestionId
        optionId
        questionType
        rangeValue
      }
      doolaCustomerId
    }
  }
}
    `;
export type AddUserSurveyResponseMutationFn = Apollo.MutationFunction<AddUserSurveyResponseMutation, AddUserSurveyResponseMutationVariables>;

/**
 * __useAddUserSurveyResponseMutation__
 *
 * To run a mutation, you first call `useAddUserSurveyResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserSurveyResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserSurveyResponseMutation, { data, loading, error }] = useAddUserSurveyResponseMutation({
 *   variables: {
 *      userSurveyResponse: // value for 'userSurveyResponse'
 *   },
 * });
 */
export function useAddUserSurveyResponseMutation(baseOptions?: Apollo.MutationHookOptions<AddUserSurveyResponseMutation, AddUserSurveyResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserSurveyResponseMutation, AddUserSurveyResponseMutationVariables>(AddUserSurveyResponseDocument, options);
      }
export type AddUserSurveyResponseMutationHookResult = ReturnType<typeof useAddUserSurveyResponseMutation>;
export type AddUserSurveyResponseMutationResult = Apollo.MutationResult<AddUserSurveyResponseMutation>;
export type AddUserSurveyResponseMutationOptions = Apollo.BaseMutationOptions<AddUserSurveyResponseMutation, AddUserSurveyResponseMutationVariables>;