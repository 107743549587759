import { useEffect, useRef, useState } from "react";
import {
  Button,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import utils, { colors } from "utils";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import useCompany from "graphql/hooks/UseCompany";
import { LoadingComponent } from "components/Loading";
import { Member } from "graphql/__generated__/types";
import { useUpdateCompanyMemberMutation } from "graphql/__generated__/operations/UpdateCompanyAddMember.generated";
import { useRemoveAllCompanyMembersMutation } from "graphql/__generated__/operations/RemoveAllCompanyMembers.generated";
import ButtonBox from "components/ButtonBox";
import { usePostHog } from "posthog-js/react";
import { distributeSharesAmongMembers } from "utils/sharesDistribution";
import { t } from "i18next";

type MemberProps = {
  member: Member;
  onUpdateOwnership: (e: string, position: number) => void;
  hasError: boolean;
};

const MemberCard = ({ member, onUpdateOwnership, hasError }: MemberProps) => {
  return (
    <Stack spacing={3} direction="row" justifyContent="space-between">
      <Stack>
        <Typography variant="h5">{member.contactFullName}</Typography>
        <Typography>SSN 🇺🇸: {member.ssn ? "Provided" : " N/A"}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={2}>
        <TextField
          sx={{
            width: "90px",
            "> .MuiOutlinedInput-root": {
              backgroundColor: "white",
              borderRadius: "8px",
              color: hasError ? colors.red : colors.black,
            },
            ".MuiInputBase-root": {
              height: "36px",
            },
          }}
          value={member.ownershipPercent}
          id="ownership"
          name="ownership"
          type="number"
          InputProps={{
            inputProps: { min: 0, max: 100, step: 1 },
          }}
          variant="outlined"
          onChange={(e) => onUpdateOwnership(e.target.value, member.position!)}
        />
        <Typography variant="h5">%</Typography>
      </Stack>
    </Stack>
  );
};

const OnboardingOwnership = () => {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);
  const { currentCompany } = useCompany();

  const [removeAllCompanyMembers] = useRemoveAllCompanyMembersMutation();
  const [updateCompanyMember] = useUpdateCompanyMemberMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [membersState, setMembersState] = useState<Member[] | undefined>();
  const [isDefaultPercentage, setIsDefaultPercentage] = useState(true);

  const hasLoadedRef = useRef(false);

  // navigate to members if no members found in the company
  useEffect(() => {
    if (!hasLoadedRef.current) {
      const hasMembers = !!(
        currentCompany?.members && currentCompany?.members.length > 0
      );

      if (currentCompany) {
        hasLoadedRef.current = true;
        if (!hasMembers) {
          navigate(ROUTER_PATHS.ONBOARDING_MEMBERS);
        }
      }
    }
  }, [currentCompany, navigate]);

  useEffect(() => {
    if (!membersState && currentCompany?.members) {
      const numberOfMembers = currentCompany?.members?.length;

      const defaultShares = distributeSharesAmongMembers(numberOfMembers);

      const formattedMembers = currentCompany.members.map((member, i) => {
        const { __typename, ownershipPercent, ...memberData } = member;
        const { __typename: addressTypeName, ...addressData } = member.address;

        if (ownershipPercent) {
          setIsDefaultPercentage(false);
        }

        return {
          ownershipPercent: ownershipPercent || defaultShares[i],
          ...memberData,
          ssn: memberData.ssn || "",
          address: addressData,
        };
      });

      setMembersState(formattedMembers);
    }
  }, [currentCompany?.members, membersState]);

  if (!currentCompany || !membersState) {
    return <LoadingComponent />;
  }

  const handleUpdateOwnership = (ownership: string, position: number) => {
    const ownershipFloat = parseFloat(ownership);

    const copyOfState = [...membersState];

    const memberAtPosition = membersState.find(
      (member) => member.position === position
    );

    const index = copyOfState.findIndex(
      (member) => member.position === position
    );

    if (isNaN(ownershipFloat)) {
      const updatedMemberAtPosition = {
        ...memberAtPosition,
        ownershipPercent: 0,
      };

      copyOfState[index] = updatedMemberAtPosition as Member;
      setMembersState(copyOfState);
    } else {
      const updatedMemberAtPosition = {
        ...memberAtPosition,
        ownershipPercent: ownershipFloat,
      };

      copyOfState[index] = updatedMemberAtPosition as Member;
      setMembersState(copyOfState);
    }

    setIsDefaultPercentage(false);
  };

  const handleOnNext = async () => {
    try {
      setIsLoading(true);

      await removeAllCompanyMembers({
        variables: { companyId: currentCompany.id },
      });

      await Promise.all(
        membersState.map(async (member) => {
          return await updateCompanyMember({
            variables: {
              companyId: currentCompany?.id,
              member: { ...member },
            },
          });
        })
      );

      posthog?.capture("company-ownership-set", {
        count: membersState.length,
        default: isDefaultPercentage,
      });

      navigate(ROUTER_PATHS.ONBOARDING_REVIEW);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const onResetMembers = () => {
    const numberOfMembers = membersState.length || 0;
    const defaultShares = distributeSharesAmongMembers(numberOfMembers);

    const copyMembersState = [...membersState];
    const updatedMemberState = copyMembersState.map((member, i) => ({
      ...member,
      ownershipPercent: defaultShares[i],
    }));

    setMembersState(updatedMemberState);
    setIsDefaultPercentage(true);

    posthog?.capture("company-ownership-reset");
  };

  const totalPercentage = Math.fround(
    membersState.reduce(
      (sum, member) => sum + (member.ownershipPercent ?? 0),
      0
    )
  );

  const hasAtLeastOneMemberWithoutPercentage = membersState?.some(
    (member) => !member?.ownershipPercent || member.ownershipPercent === 0
  );

  const showError =
    hasAtLeastOneMemberWithoutPercentage || totalPercentage !== 100;

  const isDisabled = totalPercentage !== 100;

  return (
    <Stack direction="column" gap={3}>
      <Typography variant="h1">Set Ownership Percentage</Typography>
      <Stack spacing={2} bgcolor={colors.lightGrey} p={3} borderRadius="24px">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5">Members</Typography>
          <Button
            disableElevation
            variant="contained"
            onClick={onResetMembers}
            endIcon={<RefreshRoundedIcon fontSize="medium" />}
            sx={{
              borderRadius: "30px",
              bgcolor: colors.grey,
              color: colors.black,
              "&:hover": {
                bgcolor: colors.darkGrey,
              },
            }}
          >
            Reset
          </Button>
        </Stack>
        {membersState?.map((member) => (
          <MemberCard
            key={member.position}
            member={member}
            onUpdateOwnership={handleUpdateOwnership}
            hasError={showError}
          />
        ))}
      </Stack>
      {showError && (
        <Stack
          alignItems="center"
          p={3}
          bgcolor={colors.lightRed}
          border={`1px solid ${colors.red}`}
          borderRadius={6}
        >
          <Typography sx={{ color: colors.red }}>
            {t("general.ownershipPercentageError.1")} Current total ownership
            percentage is {totalPercentage.toFixed(2)}
          </Typography>
        </Stack>
      )}
      <Stack direction="row" alignItems={"center"} gap={2}>
        <InfoIcon />
        <Typography>
          Confirm or edit the ownership percentage of each member in your{" "}
          {currentCompany.entityType}. Typically this percentage is based on
          equity contributions at the time of formation and expected split of
          profits. This information will not be submitted to the state but will
          be present on your Operating Agreement.
        </Typography>
      </Stack>
      <Stack direction={isMobile ? "column" : "row"} gap={1}>
        <ButtonBox
          onClickBack={() =>
            navigate(ROUTER_PATHS.ONBOARDING_RESPONSIBLE_PARTY)
          }
          onClickNext={handleOnNext}
          rightButtonText="Review"
          disabled={isDisabled}
          isLoading={isLoading}
        />
      </Stack>
    </Stack>
  );
};

export default OnboardingOwnership;
