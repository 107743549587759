import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSignupFlowDataQueryVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  customerId: Types.Scalars['ID'];
}>;


export type GetSignupFlowDataQuery = { __typename?: 'Query', company?: { __typename?: 'Company', id: string, entityType?: Types.EntityType | null, entityTypeEnding?: string | null, description?: string | null, activity?: string | null, activityOther?: string | null, state?: Types.Iso31662Us | null, name?: string | null, services?: Array<{ __typename?: 'Services', service: Types.CompanyServices, status: Types.ServiceCategory }> | null, members?: Array<{ __typename?: 'Member', legalFirstName: string }> | null } | null, customer?: { __typename?: 'Customer', id?: string | null, email?: string | null, stripeCustomerId?: string | null, name?: string | null, countryOfResidence?: Types.Iso31661Alpha3 | null } | null };


export const GetSignupFlowDataDocument = gql`
    query GetSignupFlowData($companyId: ID!, $customerId: ID!) {
  company(id: $companyId) {
    id
    entityType
    entityTypeEnding
    description
    activity
    activityOther
    state
    name
    services {
      service
      status
    }
    members {
      legalFirstName
    }
  }
  customer(id: $customerId) {
    id
    email
    stripeCustomerId
    name
    countryOfResidence
  }
}
    `;

/**
 * __useGetSignupFlowDataQuery__
 *
 * To run a query within a React component, call `useGetSignupFlowDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignupFlowDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignupFlowDataQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetSignupFlowDataQuery(baseOptions: Apollo.QueryHookOptions<GetSignupFlowDataQuery, GetSignupFlowDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSignupFlowDataQuery, GetSignupFlowDataQueryVariables>(GetSignupFlowDataDocument, options);
      }
export function useGetSignupFlowDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSignupFlowDataQuery, GetSignupFlowDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSignupFlowDataQuery, GetSignupFlowDataQueryVariables>(GetSignupFlowDataDocument, options);
        }
export type GetSignupFlowDataQueryHookResult = ReturnType<typeof useGetSignupFlowDataQuery>;
export type GetSignupFlowDataLazyQueryHookResult = ReturnType<typeof useGetSignupFlowDataLazyQuery>;
export type GetSignupFlowDataQueryResult = Apollo.QueryResult<GetSignupFlowDataQuery, GetSignupFlowDataQueryVariables>;