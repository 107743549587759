import { useEffect } from "react";
import { useGetSignupFlowDataLazyQuery } from "graphql/__generated__/operations/SignupFlow.generated";
import { useGetTokenFieldsLazyQuery } from "graphql/__generated__/operations/Whoami.generated";
import { getTokens } from "utils/auth";
import { FormationSteps } from "views/SignupFlow/services";
import { useAppSelector } from "state/hooks";
import { selectFormationPackage } from "state/formationFormSlice";

const useSignupFlow = () => {
  const [fetchTokenData, { data: tokenData }] = useGetTokenFieldsLazyQuery();

  // TO DO: temporary decision to check Redux for selected Formation product to allow customer to go further
  const selectedFormationPackage = useAppSelector(selectFormationPackage);

  const [
    fetchSignupFlowData,
    { data: signupFlowData, refetch: refetchSignupFlowData },
  ] = useGetSignupFlowDataLazyQuery();

  const { idToken } = getTokens();

  useEffect(() => {
    if (idToken) {
      fetchTokenData();
    }
  }, [fetchTokenData, idToken]);

  useEffect(() => {
    const companyIds = tokenData?.myToken?.authorizedCompanyIds?.split(",");
    const companyId = companyIds?.[0];

    const customerId = tokenData?.myToken?.authorizedCustomerId;

    if (companyId && customerId) {
      fetchSignupFlowData({ variables: { companyId, customerId } });
    }
  }, [fetchSignupFlowData, tokenData]);

  const companyId = signupFlowData?.company?.id || "";
  const customerId = signupFlowData?.customer?.id || "";
  const stripeCustomerId = signupFlowData?.customer?.stripeCustomerId || "";
  // const companyOrigin = signupFlowData?.company?.companyOrigin;
  const countryOfResidence = signupFlowData?.customer?.countryOfResidence || "";
  const entityType = signupFlowData?.company?.entityType;
  const email = signupFlowData?.customer?.email || "";
  const customerFullName = signupFlowData?.customer?.name || "";
  const entityState = signupFlowData?.company?.state;
  const entityEnding = signupFlowData?.company?.entityTypeEnding;
  const entityName = signupFlowData?.company?.name || "";

  const hasServices = !!(
    signupFlowData?.company?.services &&
    signupFlowData?.company?.services.length > 0
  );
  const hasMembers = !!(
    signupFlowData?.company?.members &&
    signupFlowData?.company?.members.length > 0
  );

  const getFormationStep = () => {
    // initially it's undefined
    let formationStep = undefined;

    // if the token isn't valid then registration
    if (!idToken) {
      formationStep = FormationSteps.Registration;
    }

    // TODO: Uncomment for phase 1 with company origin
    // if the API data has been fetched and is loaded, then company origin selector
    // if (!!signupFlowData && !companyOrigin) {
    //   formationStep = FormationSteps.CompanyOrigin;
    // }

    // // if no CountryOfResidence chosen then country of residence selector
    // if (companyOrigin) {
    //   formationStep = FormationSteps.CountryOfResidence;
    // }

    if (!!signupFlowData && !countryOfResidence) {
      formationStep = FormationSteps.CountryOfResidence;
    }

    // if no entityType chosen then entity type selector
    if (countryOfResidence) {
      formationStep = FormationSteps.EntityType;
    }

    // otherwise set the formation step based on API data
    if (countryOfResidence && entityType) {
      formationStep = FormationSteps.CompanyState; // if they already have entity type they can access state
    }

    if (countryOfResidence && entityType && entityState) {
      formationStep = FormationSteps.CompanyName; // if they already have entity type and state, they can access company name
    }

    if (
      countryOfResidence &&
      entityType &&
      entityState &&
      entityEnding &&
      !selectedFormationPackage &&
      !hasServices
    ) {
      formationStep = FormationSteps.Packages; // if they have entity type, state, name, and ending, but no presaved package they can access formation
    }

    if (
      countryOfResidence &&
      entityType &&
      entityState &&
      entityEnding &&
      selectedFormationPackage &&
      !hasServices
    ) {
      formationStep = FormationSteps.Review; // if they have entity type, state, name, and ending, and presaved package they can access review page
    }

    if (
      countryOfResidence &&
      entityType &&
      entityState &&
      entityEnding &&
      hasServices &&
      !hasMembers
    ) {
      formationStep = FormationSteps.PurchaseSuccess; // if the user has services but no members, they can access the purchase success page
    }

    return formationStep;
  };

  return {
    companyId,
    customerId,
    email,
    // companyOrigin,
    countryOfResidence,
    customerFullName,
    stripeCustomerId,
    entityType,
    entityState,
    entityEnding,
    entityName,
    companyServices: hasServices ? signupFlowData?.company?.services : [],
    hasServices,
    hasMembers,
    hasDataLoaded: !!signupFlowData,
    highestFormationStep: getFormationStep(),
    refetchSignupFlowData,
  };
};

export default useSignupFlow;
