import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCustomerLastNameMutationVariables = Types.Exact<{
  customerId: Types.Scalars['ID'];
  newLastName: Types.Scalars['String'];
}>;


export type UpdateCustomerLastNameMutation = { __typename?: 'Mutation', customer?: { __typename?: 'CustomerMutation', updateLastName?: { __typename?: 'Customer', id?: string | null, lastName?: string | null } | null } | null };


export const UpdateCustomerLastNameDocument = gql`
    mutation UpdateCustomerLastName($customerId: ID!, $newLastName: String!) {
  customer(id: $customerId) {
    updateLastName(new: $newLastName) {
      id
      lastName
    }
  }
}
    `;
export type UpdateCustomerLastNameMutationFn = Apollo.MutationFunction<UpdateCustomerLastNameMutation, UpdateCustomerLastNameMutationVariables>;

/**
 * __useUpdateCustomerLastNameMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerLastNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerLastNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerLastNameMutation, { data, loading, error }] = useUpdateCustomerLastNameMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      newLastName: // value for 'newLastName'
 *   },
 * });
 */
export function useUpdateCustomerLastNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerLastNameMutation, UpdateCustomerLastNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerLastNameMutation, UpdateCustomerLastNameMutationVariables>(UpdateCustomerLastNameDocument, options);
      }
export type UpdateCustomerLastNameMutationHookResult = ReturnType<typeof useUpdateCustomerLastNameMutation>;
export type UpdateCustomerLastNameMutationResult = Apollo.MutationResult<UpdateCustomerLastNameMutation>;
export type UpdateCustomerLastNameMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerLastNameMutation, UpdateCustomerLastNameMutationVariables>;