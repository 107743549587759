import { useCallback, useEffect, useState } from "react";
import { useGetMoneyBeneficiaryLazyQuery } from "graphql/__generated__/operations/MoneyBeneficiary.generated";
import { BeneficiaryResponse } from "graphql/__generated__/types";
import useCompany from "graphql/hooks/UseCompany";

interface IBeneficiary {
  getBeneficiary: (countryCode: string) => Promise<void>;
  beneficiary?: BeneficiaryResponse | null;
  isBeneficiaryLoading: boolean;
}

export default function useGetBeneficiary(
  beneficiaryId?: string
): IBeneficiary {
  const { currentCompany } = useCompany();

  const [isBeneficiaryLoading, setIsBeneficiaryLoading] =
    useState<boolean>(true);
  const [beneficiary, setBeneficiary] = useState<BeneficiaryResponse | null>();

  const [fetchBeneficiary] = useGetMoneyBeneficiaryLazyQuery({
    errorPolicy: "all",
  });

  const getBeneficiary = useCallback(
    async (beneficiaryId: string) => {
      setIsBeneficiaryLoading(true);

      try {
        if (beneficiaryId && currentCompany?.id) {
          const { data: beneficiaryData } = await fetchBeneficiary({
            variables: {
              doolaCompanyId: currentCompany.id,
              beneficiaryId: beneficiaryId,
            },
          });

          const beneficiary = beneficiaryData?.getBeneficiary?.payload;

          if (beneficiary) {
            setBeneficiary(beneficiary);
          }
        }
      } catch (error) {
        console.log("Failed to fetch beneficiary data, error: ", error);
      } finally {
        setIsBeneficiaryLoading(false);
      }
    },
    [currentCompany?.id, fetchBeneficiary]
  );

  useEffect(() => {
    if (beneficiaryId && currentCompany?.id) {
      getBeneficiary(beneficiaryId);
    }
  }, [getBeneficiary, beneficiaryId, currentCompany?.id]);

  return {
    getBeneficiary,
    beneficiary,
    isBeneficiaryLoading,
  };
}
