import Button from "components/Button";
import { colors } from "utils";

type Props = {
  buttonText: string;
  onClick: () => void;
  isActive: boolean;
};

const YearButton = ({ buttonText, onClick, isActive }: Props) => (
  <Button
    buttonText={buttonText}
    onClick={onClick}
    styles={{
      backgroundColor: isActive ? colors.black : colors.lightGrey,
      color: isActive ? colors.white : colors.black,
      ":hover": {
        backgroundColor: isActive ? colors.black : colors.grey,
        color: isActive ? colors.white : colors.black,
      },
      ":disabled": {
        backgroundColor: colors.darkGrey,
      },
    }}
  />
);

export default YearButton;
