import React from "react";
import Alert, { AlertProps } from "@mui/material/Alert";
import { TypeOptions as ToastTypeOptions } from "react-toastify";
import { AlertTitle } from "@mui/material";

function _translate(type_: ToastTypeOptions): AlertProps["color"] {
  if (!["success", "info", "warning", "error"].includes(type_)) {
    return "info";
  }

  return type_ as AlertProps["color"];
}

export function Toast({
  severity,
  subject,
  children,
}: {
  severity?: ToastTypeOptions;
  subject: string;
  children: string | React.ReactNode;
}) {
  return (
    <Alert
      severity={_translate(severity || "info")}
      variant="filled"
      onClose={() => {}}
    >
      <AlertTitle>{subject}</AlertTitle>
      {children}
    </Alert>
  );
}
