import {
  ChatOutlined,
  CheckRounded,
  CloseRounded,
  NavigateNext,
  PhoneEnabledRounded,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import utils, { colors } from "utils";
import { moneyFormatter } from "utils/formatters";
import {
  renderServiceDuration,
  renderServiceStatus,
} from "views/DashboardApp/Services/helpers";
import CustomLoadingButton from "components/Button";
import { useIntercom } from "react-use-intercom";
import { ProductDuration, ServiceCategory } from "graphql/__generated__/types";
import { Logo } from "components/Logos";
import { usePostHog } from "posthog-js/react";
import { IServiceData } from "views/DashboardApp/Services/data";
import useGetUpsellProducts from "views/DashboardApp/Services/hooks/useGetUpsellProducts";
import { JSXElementConstructor, ReactElement } from "react";
import { ReactComponent as RocketImage } from "assets/Rocket.svg";

type Props = {
  isModalOpen: boolean;
  onCloseModalHandler: () => void;
  itemToShow: IServiceData | null;
  selectedServiceStatus: ServiceCategory | null | undefined;
  isLoading: boolean;
  onCheckoutHandler: (item: IServiceData) => Promise<void>;
  image?: ReactElement<any, string | JSXElementConstructor<any>>;
  imageBgColor?: string;
};

const ServicePurchaseModal = ({
  isModalOpen,
  onCloseModalHandler,
  itemToShow,
  selectedServiceStatus,
  isLoading,
  onCheckoutHandler,
  image = <RocketImage />,
  imageBgColor = colors.lightBlue,
}: Props) => {
  const { show: showIntercom } = useIntercom();
  const posthog = usePostHog();
  const { UPSELL_PLANS } = useGetUpsellProducts();

  const isPc = useMediaQuery(`(max-width:${utils.screenSize.pc})`);
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);

  return (
    <Dialog
      open={isModalOpen}
      onClose={onCloseModalHandler}
      fullScreen={isTablet}
      PaperProps={{
        style: {
          ...(isPc && { minWidth: "70%" }),
          height: isTablet ? "100%" : "auto",
          boxShadow: "24px",
          borderRadius: isTablet ? 0 : "2rem",
          overflow: "scroll",
          backgroundColor: colors.white,
        },
      }}
    >
      {isTablet && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          mt={2}
          px={isMobile ? 2 : 4}
        >
          <IconButton onClick={onCloseModalHandler}>
            <CloseRounded color="primary" />
          </IconButton>
        </Stack>
      )}
      {itemToShow && (
        <>
          <Stack p={isTablet ? 3 : 4} mb={isTablet ? 9 : 0} gap={3}>
            <Stack direction={isTablet ? "column" : "row"} gap={4}>
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent="center"
                bgcolor={imageBgColor}
                p={3}
                borderRadius={6}
                minWidth="260px"
                height="260px"
                sx={{ img: { height: "100%" } }}
              >
                {image}
              </Stack>
              <Stack width={"100%"}>
                {!isTablet && (
                  <Stack direction="row" justifyContent="flex-end">
                    <IconButton onClick={onCloseModalHandler}>
                      <CloseRounded color="primary" />
                    </IconButton>
                  </Stack>
                )}
                <Stack justifyContent={"space-between"} gap={2}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    gap={2}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"baseline"}
                      spacing={1}
                    >
                      {itemToShow?.logo && <Logo width={90} />}
                      <Typography variant="h3">{itemToShow.name}</Typography>
                    </Stack>
                    {selectedServiceStatus && (
                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        {renderServiceStatus(selectedServiceStatus)}
                      </Stack>
                    )}
                  </Stack>
                  {itemToShow.included && (
                    <Stack gap={1.5} alignItems={"flex-start"}>
                      {itemToShow.included.map((benefit, index) => (
                        <Stack
                          key={index}
                          direction={"row"}
                          gap={1}
                          alignItems={"center"}
                          sx={{
                            bgcolor: colors.lightGrey,
                            p: "8px 16px 8px 12px",
                            borderRadius: "12px",
                          }}
                        >
                          <CheckRounded sx={{ width: 18 }} />
                          <Typography fontWeight={500}>{benefit}</Typography>
                        </Stack>
                      ))}
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Stack>
            <Stack gap={1}>
              <Typography variant="h6" fontSize={16}>
                {itemToShow.title} {itemToShow.description}
              </Typography>
              <Typography color={colors.contentSecondary}>
                {renderServiceDuration(
                  itemToShow.duration || ProductDuration.ANNUAL,
                  true
                )}
              </Typography>
            </Stack>
          </Stack>

          {!selectedServiceStatus && (
            <Stack
              width={"100%"}
              direction={"row"}
              justifyContent={
                isMobile &&
                !(
                  !!itemToShow.price ||
                  (itemToShow.productKey &&
                    UPSELL_PLANS[itemToShow.productKey].price)
                )
                  ? "flex-start"
                  : "space-between"
              }
              alignItems={"center"}
              sx={{
                gap: 1,
                borderTop: `1px solid ${colors.darkGrey}`,
                ...(isTablet && {
                  position: "fixed",
                  left: 0,
                  bottom: 0,
                  width: "100%",
                  bgcolor: colors.white,
                  zIndex: 1,
                }),
              }}
              px={isMobile ? 2 : 4}
              py={3}
            >
              <Box>
                {(!!itemToShow.price ||
                  (itemToShow.productKey &&
                    UPSELL_PLANS[itemToShow.productKey].price)) && (
                  <>
                    <Stack
                      direction={"row"}
                      alignItems={"flex-end"}
                      gap={"3px"}
                    >
                      <Typography variant="h2" fontSize={24} fontWeight={700}>
                        {moneyFormatter(
                          itemToShow.productKey
                            ? UPSELL_PLANS[itemToShow.productKey].price
                            : itemToShow?.price || 0
                        )}
                      </Typography>
                      <Typography
                        variant="body1"
                        color={colors.contentTertiary}
                      >
                        {renderServiceDuration(
                          itemToShow.duration || ProductDuration.ANNUAL
                        )}
                      </Typography>
                    </Stack>
                  </>
                )}
              </Box>
              <Stack direction={"row"} gap={1}>
                {!isTablet && (
                  <IconButton
                    onClick={() => {
                      posthog?.capture("service-chat-click", {
                        service_name: itemToShow.name,
                      });

                      showIntercom();
                    }}
                    sx={{
                      width: 48,
                      height: 48,
                      borderRadius: "50%",
                      bgcolor: colors.grey,
                    }}
                  >
                    <ChatOutlined sx={{ color: colors.black }} />
                  </IconButton>
                )}
                {itemToShow.allowCheckout && itemToShow.productKey ? (
                  <CustomLoadingButton
                    buttonText={"Buy Now"}
                    endIcon={<NavigateNext />}
                    isLoading={isLoading}
                    onClick={() => {
                      posthog?.capture("call-sales-click", {
                        service_name: itemToShow.name,
                      });
                      onCheckoutHandler(itemToShow);
                    }}
                  />
                ) : (
                  <Button
                    variant="outlined"
                    startIcon={<PhoneEnabledRounded />}
                    onClick={() => {
                      posthog?.capture("call-sales-click", {
                        service_name: itemToShow.name,
                      });
                    }}
                    sx={{
                      borderRadius: "24px",
                      p: 0,
                      pl: "16px",
                      "&:hover": {
                        color: colors.charcoal,
                      },
                    }}
                  >
                    <Link
                      href="tel:+18483012782"
                      fontWeight={500}
                      lineHeight={"16px"}
                      sx={{
                        textDecoration: "none",
                        p: {
                          md: "15px 24px 15px 0",
                          xs: "10px 16px 10px 0",
                        },
                      }}
                    >
                      {isTablet ? "Contact us" : "(848) 301-2782"}
                    </Link>
                  </Button>
                )}
                {isTablet && <Box width={48} />}
              </Stack>
            </Stack>
          )}
        </>
      )}
    </Dialog>
  );
};

export default ServicePurchaseModal;
