import {
  Lookup,
  LookupCategory,
  LookupTypeKey,
} from "graphql/__generated__/types";
import { useGetMoneyLookupsLazyQuery } from "graphql/__generated__/operations/MoneyLookups.generated";
import { useEffect, useState } from "react";

interface IMoneyLookups {
  lookupsData?: Record<LookupTypeKey, Lookup[]>;
  isLookupsDataLoading: boolean;
  neededLookups: LookupTypeKey[];
}

const transformLookups = (
  neededLookups: LookupTypeKey[],
  lookups: (LookupCategory | null)[]
): Record<LookupTypeKey, Lookup[]> => {
  const RESTRICTED_COUNTRIES = lookups?.find(
    (lookup) => lookup?.name === LookupTypeKey.RESTRICTEDCOUNTRIES
  );

  const filterNeededLookups = lookups?.filter((lookup) =>
    neededLookups.includes(lookup?.name as LookupTypeKey)
  );

  const filteredLookups = filterNeededLookups?.map((lookup) => {
    if (RESTRICTED_COUNTRIES) {
      const restrictedLookups = RESTRICTED_COUNTRIES.lookups;

      if (
        lookup?.name === LookupTypeKey.COUNTRYOFOPERATION ||
        lookup?.name === LookupTypeKey.COUNTRYNAME
      ) {
        lookup = {
          ...lookup,
          lookups: (lookup.lookups || [])
            .filter(
              (item) =>
                !restrictedLookups?.some(
                  (country) => country?.key === item?.key
                )
            )
            .sort((a, b) =>
              (a?.description || "").localeCompare(b?.description || "")
            ),
        };
      }
    }

    return lookup;
  });

  return (filteredLookups || []).reduce((acc, lookup) => {
    if (lookup?.name) {
      acc[lookup.name as LookupTypeKey] = (lookup.lookups || []) as Lookup[];
    }
    return acc;
  }, {} as Record<LookupTypeKey, Lookup[]>);
};

export default function useGetMoneyLookups({
  neededLookups,
}: {
  neededLookups: LookupTypeKey[];
}): IMoneyLookups {
  const [isLookupsDataLoading, setIsLookupsDataLoading] =
    useState<boolean>(true);
  const [lookupsData, setLookupsData] = useState<
    Record<LookupTypeKey, Lookup[]> | undefined
  >();

  const [fetchMoneyLookupsData] = useGetMoneyLookupsLazyQuery();

  useEffect(() => {
    const getLookupsData = async () => {
      try {
        const { data: lookupsData } = await fetchMoneyLookupsData({
          fetchPolicy: "cache-first",
          errorPolicy: "all",
        });

        const lookups = lookupsData?.lookups?.payload;

        if (lookups) {
          const transformedLookups = transformLookups(neededLookups, lookups);
          setLookupsData(transformedLookups);
        }
      } catch (error) {
        console.log("Failed to fetch lookups data, error: ", error);
      } finally {
        setIsLookupsDataLoading(false);
      }
    };

    getLookupsData();
  }, [fetchMoneyLookupsData]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    lookupsData,
    isLookupsDataLoading,
    neededLookups,
  };
}
