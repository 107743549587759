import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatetrackingDataMutationVariables = Types.Exact<{
  customerId: Types.Scalars['ID'];
  newToken: Types.Scalars['String'];
}>;


export type UpdatetrackingDataMutation = { __typename?: 'Mutation', customer?: { __typename?: 'CustomerMutation', updateTrackingData?: { __typename?: 'Customer', id?: string | null, wasReferred?: boolean | null } | null } | null };


export const UpdatetrackingDataDocument = gql`
    mutation UpdatetrackingData($customerId: ID!, $newToken: String!) {
  customer(id: $customerId) {
    updateTrackingData(new: $newToken) {
      id
      wasReferred
    }
  }
}
    `;
export type UpdatetrackingDataMutationFn = Apollo.MutationFunction<UpdatetrackingDataMutation, UpdatetrackingDataMutationVariables>;

/**
 * __useUpdatetrackingDataMutation__
 *
 * To run a mutation, you first call `useUpdatetrackingDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatetrackingDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatetrackingDataMutation, { data, loading, error }] = useUpdatetrackingDataMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      newToken: // value for 'newToken'
 *   },
 * });
 */
export function useUpdatetrackingDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdatetrackingDataMutation, UpdatetrackingDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatetrackingDataMutation, UpdatetrackingDataMutationVariables>(UpdatetrackingDataDocument, options);
      }
export type UpdatetrackingDataMutationHookResult = ReturnType<typeof useUpdatetrackingDataMutation>;
export type UpdatetrackingDataMutationResult = Apollo.MutationResult<UpdatetrackingDataMutation>;
export type UpdatetrackingDataMutationOptions = Apollo.BaseMutationOptions<UpdatetrackingDataMutation, UpdatetrackingDataMutationVariables>;