import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { colors } from "utils";
import { moneyFormatter } from "utils/formatters";
import useTotalCartValue from "views/SignupFlow/hooks/useTotalCartValue";

const Cart = () => {
  const { totalPayToday } = useTotalCartValue();
  const { t } = useTranslation();

  return (
    <Stack justifyContent={"center"} alignItems={"flex-end"}>
      <Typography
        fontWeight={"medium"}
        fontSize={"12px"}
        color={colors.contentTertiary}
      >
        {t("general.dueToday")}
      </Typography>
      <Typography fontWeight={"medium"} fontSize={"16px"}>
        {moneyFormatter(totalPayToday, false)}
      </Typography>
    </Stack>
  );
};

export default Cart;
