import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetRaAddressesLazyQuery } from "graphql/__generated__/operations/RAAddresses.generated";
import { RegisteredAgentAddress } from "graphql/__generated__/types";
import useCompany from "graphql/hooks/UseCompany";

interface IRaAddress {
  raAddressData?: RegisteredAgentAddress | null;
  isRaAddressDataLoading: boolean;
}

export default function useGetRaAddress(): IRaAddress {
  const { currentCompany } = useCompany();
  const [isRaAddressDataLoading, setIsRaAddressDataLoading] =
    useState<boolean>(true);
  const [raAddressData, setRaAddressData] =
    useState<RegisteredAgentAddress | null>();

  const [fetchRaAddresses] = useGetRaAddressesLazyQuery({
    errorPolicy: "all",
  });

  const state = useMemo(() => currentCompany?.state, [currentCompany?.state]);

  const getRaAddress = useCallback(async () => {
    try {
      const { data: raAddressesData } = await fetchRaAddresses({
        variables: { state },
      });

      const raAddress = raAddressesData?.registeredAgentAddresses?.payload;

      if (raAddress?.length) {
        setRaAddressData(raAddress[0]);
      }
    } catch (error) {
      console.log("Failed to fetch RA address data, error: ", error);
    } finally {
      setIsRaAddressDataLoading(false);
    }
  }, [fetchRaAddresses, state]);

  useEffect(() => {
    if (state) {
      getRaAddress();
    }
  }, [getRaAddress, state]);

  return {
    raAddressData,
    isRaAddressDataLoading,
  };
}
