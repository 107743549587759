import { Grid } from "@mui/material";
import { P } from "components/Fonts";
import React from "react";
import { DataSection } from "./DataSection";

type DataStructure = {
  label: string;
  value: string;
  sensitiveData?: boolean;
};

type DataEnteredSummaryProps = {
  contactDetails: DataStructure[];
  companyInfo: DataStructure[];
  memberData?: DataStructure[][];
  mailingAddress?: DataStructure[];
  extraData?: DataStructure[];
};

export const DataEnteredSummary: React.FC<DataEnteredSummaryProps> = ({
  contactDetails,
  companyInfo,
  memberData,
  mailingAddress,
  extraData,
}) => (
  <>
    <Grid container mb={4}>
      <Grid item lg={4} xs={12}>
        <P size="medium">Your Contact Details</P>
      </Grid>
      <Grid item lg={8}>
        <Grid container>
          {contactDetails.map((item, index) => (
            <Grid key={index} item xs={12} md={6}>
              <DataSection itemToShow={item} />
              <br />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>

    <Grid container mb={4}>
      <Grid item lg={4} xs={12}>
        <P size="medium">Company Information</P>
      </Grid>
      <Grid item lg={8}>
        <Grid container>
          {companyInfo.map((item, index) => (
            <Grid key={index} item xs={12} md={6}>
              <DataSection itemToShow={item} />
              <br />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>

    <Grid container mb={4}>
      <Grid item lg={4} xs={12}>
        <P size="medium">Company Members</P>
      </Grid>
      <Grid item lg={8} pt={1}>
        {memberData &&
          memberData.map((memberData, index) => (
            <React.Fragment key={index}>
              <P size="medium">
                {`${"Member "}`}
                <small>#</small>
                {index + 1}
              </P>
              <br />
              <Grid container>
                {memberData.map((item, index) => (
                  <Grid key={index} item xs={12} md={6}>
                    <DataSection itemToShow={item} />
                    <br />
                  </Grid>
                ))}
              </Grid>
            </React.Fragment>
          ))}
      </Grid>
    </Grid>

    <Grid container mb={4}>
      <Grid item lg={4} xs={12}>
        <P size="medium">Mailing address</P>
      </Grid>
      <Grid item lg={8}>
        <Grid container>
          {mailingAddress &&
            mailingAddress.map((item, index) => (
              <Grid key={index} item xs={12} md={6}>
                <DataSection itemToShow={item} />
                <br />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>

    <Grid container mb={4}>
      <Grid item lg={4} xs={12}>
        <P size="medium">Extras</P>
      </Grid>
      <Grid item lg={8} className=".fs-exclude">
        <Grid container>
          {extraData &&
            extraData.map((item, index) => (
              <Grid key={index} item xs={12} md={6}>
                <DataSection itemToShow={item} />
                <br />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  </>
);
