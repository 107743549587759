import {
  Grid,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MoneyHeader from "../Header";
import useGetTransactions from "../hooks/useGetTransactions";
import utils, { colors } from "utils";
import { WalletTransaction } from "graphql/__generated__/types";
import TransactionItem from "../components/TransactionRow";
import NoTransactionsUI from "../components/NoTransactionsUI";
import Button from "components/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { DescriptionOutlined } from "@mui/icons-material";
import Statements from "./Statements";
import useGetMoneyAccounts from "../hooks/useGetMoneyAccounts";
import { usePostHog } from "posthog-js/react";

const TRANSACTION_OFFSET_LENGTH = 10;

const Transactions = () => {
  const { t } = useTranslation();
  const posthog = usePostHog();

  const [isStatementsModalOpen, setIsStatementsModalOpen] =
    useState<boolean>(false);
  const [page, setPage] = useState<number>(0);

  const { transactions, isTransactionsLoading } = useGetTransactions({
    offset: TRANSACTION_OFFSET_LENGTH,
    page,
  });
  const { accountsData, isAccountsDataLoading } = useGetMoneyAccounts();

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const transactionLength = transactions ? transactions.length : 0;

  const isDataLoading = isTransactionsLoading || !transactions;

  return (
    <Stack gap={isTablet ? 3 : 4}>
      <MoneyHeader />
      <Grid
        container
        rowSpacing={isTablet ? 3 : 4}
        columnSpacing={isTablet ? 0 : 4}
        direction={isTablet ? "column-reverse" : "row"}
      >
        <Grid item xs={12} md={8}>
          {isDataLoading ? (
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={"650px"}
              animation="wave"
              sx={{ borderRadius: isTablet ? 6 : 8 }}
            />
          ) : (
            <Stack
              bgcolor={colors.lightGrey}
              p={isTablet ? 3 : 4}
              borderRadius={isTablet ? 6 : 8}
              gap={isTablet ? 3 : 4}
            >
              {transactionLength ? (
                transactions?.map(
                  (transaction: WalletTransaction | null) =>
                    transaction && (
                      <TransactionItem
                        key={transaction.id}
                        transaction={transaction}
                      />
                    )
                )
              ) : (
                <NoTransactionsUI />
              )}
            </Stack>
          )}
          {transactions && (
            <Stack
              gap={2}
              direction={"row"}
              mt={2}
              justifyContent={isTablet ? "flex-start" : "flex-end"}
            >
              <Button
                isLoading={isDataLoading}
                disabled={!page}
                buttonText={t("general.prev")}
                onClick={() => setPage((prevPage) => prevPage - 1)}
                styles={{
                  backgroundColor: colors.lightGrey,
                  color: colors.black,
                  ":hover": {
                    backgroundColor: colors.grey,
                  },
                  ":disabled": {
                    backgroundColor: colors.darkGrey,
                  },
                }}
              />
              <Button
                isLoading={isDataLoading}
                disabled={transactionLength < TRANSACTION_OFFSET_LENGTH}
                buttonText={t("general.next")}
                onClick={() => setPage((prevPage) => prevPage + 1)}
                styles={{
                  backgroundColor: colors.lightGrey,
                  color: colors.black,
                  ":hover": {
                    backgroundColor: colors.grey,
                  },
                  ":disabled": {
                    backgroundColor: colors.darkGrey,
                  },
                }}
              />
            </Stack>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack
            direction={isTablet ? "row-reverse" : "column"}
            gap={isTablet ? 2 : 4}
          >
            {isAccountsDataLoading ? (
              <Skeleton
                width={"100%"}
                height={isTablet ? "60px" : "110px"}
                sx={{ borderRadius: isTablet ? 4 : 8 }}
                variant="rectangular"
                animation="wave"
              />
            ) : (
              <>
                <Statements
                  isModalOpen={isStatementsModalOpen}
                  onCloseModalHandler={() => setIsStatementsModalOpen(false)}
                  accountCreatedDate={accountsData?.accountCreateDate || ""}
                />
                <Stack
                  p={isTablet ? 2 : 4}
                  borderRadius={isTablet ? 4 : 8}
                  bgcolor={colors.darkPink}
                  alignItems={"center"}
                  direction={"row"}
                  gap={1.5}
                  justifyContent={isTablet ? "start" : "space-between"}
                  width={"100%"}
                  onClick={() => {
                    if (isTablet) {
                      posthog?.capture(
                        "money_transactions_view_statement_btn_click"
                      );

                      setIsStatementsModalOpen(true);
                    } else {
                      return;
                    }
                  }}
                >
                  {isTablet && (
                    <DescriptionOutlined sx={{ fontSize: "20px" }} />
                  )}
                  <Typography
                    fontSize={isTablet ? 16 : 24}
                    fontWeight={isTablet ? 500 : 700}
                  >
                    {t("money.transactions.statements")}
                  </Typography>
                  {!isTablet && (
                    <Button
                      endIcon={
                        <VisibilityIcon
                          style={{ fontSize: 16, fontWeight: "700" }}
                        />
                      }
                      buttonText={t("general.view")}
                      onClick={() => {
                        posthog?.capture(
                          "money_transactions_view_statement_btn_click"
                        );

                        setIsStatementsModalOpen(true);
                      }}
                      styles={{
                        alignSelf: "flex-end",
                      }}
                    />
                  )}
                </Stack>
              </>
            )}
            {/* TODO: FILTERS TO BE ENABLED LATER */}
            {/* <Stack
              p={isTablet ? 2 : 4}
              borderRadius={isTablet ? 4 : 8}
              bgcolor={colors.lightAccentYellow}
              gap={1.5}
              direction={"row"}
              alignItems={"center"}
              width={"100%"}
            >
              {isTablet && <TuneIcon sx={{ fontSize: "20px" }} />}
              <Typography
                fontSize={isTablet ? 16 : 24}
                fontWeight={isTablet ? 500 : 700}
              >
                {t("general.filters")}
              </Typography>
            </Stack> */}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Transactions;
