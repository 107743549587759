import styled from "styled-components";
import { colors } from "utils";

export const WrapperTypeImage = styled.div`
  min-height: 200px;
  background-color: ${colors.lightAccentYellow};
  text-align: center;
  display: flex;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

export const WrapperFooterIcon = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
`;

export const WrapperImage = styled.div`
  display: flex;
  > img {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    width: 100%;
    object-fit: cover;
  }
`;
