import { IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import utils, { colors } from "utils";
import CloseIcon from "@mui/icons-material/Close";

interface IQuestionWrapper {
  questionNumber: number;
  title: string;
  onClose: () => void;
  children?: React.ReactNode;
}

export const QuestionWrapper = ({
  questionNumber,
  title,
  onClose,
  children,
}: IQuestionWrapper) => {
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  return (
    <>
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          top: isTablet ? 32 : 24,
          right: isTablet ? 32 : 24,
        }}
      >
        <CloseIcon color="primary" />
      </IconButton>
      <Stack
        sx={{
          width: isTablet ? "100%" : 690,
          ...(isTablet && { height: "100%" }),
          p: isTablet ? 4 : 3,
          bgcolor: colors.white,
        }}
      >
        <Stack
          direction={isTablet ? "column" : "row"}
          gap={isTablet ? 4 : 6}
          mt={isTablet ? 8 : 0}
        >
          <Stack
            justifyContent={"center"}
            width={isTablet ? "100%" : "45%"}
            minHeight="270px"
            height="100%"
            p={3}
            color="#fff"
            borderRadius={6}
            sx={{ background: colors.darkGreen }}
          >
            <Typography variant="h4" mb={1}>
              Question {questionNumber}
            </Typography>
            <Typography variant="h5" fontSize={18} fontWeight="500">
              {title}
            </Typography>
          </Stack>
          <Stack
            width={isTablet ? "100%" : "55%"}
            alignSelf={"center"}
            pr={isTablet ? 0 : 3}
            gap={2}
          >
            {children}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
