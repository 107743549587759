import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMoneyRoutingCodeQueryVariables = Types.Exact<{
  countryCode: Types.Scalars['String'];
}>;


export type GetMoneyRoutingCodeQuery = { __typename?: 'Query', getRoutingCode?: { __typename?: 'RoutingCodeList', error?: string | null, payload?: Array<string | null> | null } | null };


export const GetMoneyRoutingCodeDocument = gql`
    query GetMoneyRoutingCode($countryCode: String!) {
  getRoutingCode(countryCode: $countryCode) {
    error
    payload
  }
}
    `;

/**
 * __useGetMoneyRoutingCodeQuery__
 *
 * To run a query within a React component, call `useGetMoneyRoutingCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoneyRoutingCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoneyRoutingCodeQuery({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useGetMoneyRoutingCodeQuery(baseOptions: Apollo.QueryHookOptions<GetMoneyRoutingCodeQuery, GetMoneyRoutingCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMoneyRoutingCodeQuery, GetMoneyRoutingCodeQueryVariables>(GetMoneyRoutingCodeDocument, options);
      }
export function useGetMoneyRoutingCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMoneyRoutingCodeQuery, GetMoneyRoutingCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMoneyRoutingCodeQuery, GetMoneyRoutingCodeQueryVariables>(GetMoneyRoutingCodeDocument, options);
        }
export type GetMoneyRoutingCodeQueryHookResult = ReturnType<typeof useGetMoneyRoutingCodeQuery>;
export type GetMoneyRoutingCodeLazyQueryHookResult = ReturnType<typeof useGetMoneyRoutingCodeLazyQuery>;
export type GetMoneyRoutingCodeQueryResult = Apollo.QueryResult<GetMoneyRoutingCodeQuery, GetMoneyRoutingCodeQueryVariables>;