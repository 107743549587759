import { IconButton, Stack, Typography } from "@mui/material";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { colors } from "utils";
import { useState } from "react";

type Props = {
  itemToShow: {
    label: string;
    value: string;
    sensitiveData?: boolean;
  };
};

export const DataSection: React.FC<Props> = ({ itemToShow }) => {
  const [showData, setShowData] = useState<boolean>(false);

  const { label, value, sensitiveData } = itemToShow;

  const handleClickShowText = () => {
    setShowData(!showData);
  };

  const hiddenValue = sensitiveData && !showData ? "********" : value;

  return (
    <>
      <Typography variant="caption" color={colors.contentTertiary}>
        {label}
      </Typography>
      <Stack direction={"row"} alignItems={"center"} gap={1}>
        <Typography variant="caption">{hiddenValue}</Typography>
        {sensitiveData && (
          <IconButton
            aria-label="toggle text visibility"
            onClick={handleClickShowText}
            edge="end"
            color="secondary"
            size="small"
          >
            {showData ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          </IconButton>
        )}
      </Stack>
    </>
  );
};
