import {
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { EmailOutlined, PersonOutlined } from "@mui/icons-material";
import ButtonBox from "components/ButtonBox";
import utils from "utils";
import { useTranslation } from "react-i18next";
import CustomInput from "components/Form/CustomInput";
import { EmailRegex, Windows1252Regex } from "utils/regexValidations";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import CustomDatePicker from "components/Form/CustomDatePicker";
import {
  LookupTypeKey,
  OnboardingApplicant,
  OnboardingApplicantInput,
  OnboardingStakeholderBusinessEntityType,
  WalletOnboard,
} from "graphql/__generated__/types";
import CustomSelect from "components/Form/CustomSelect";
import { LoadingComponent } from "components/Loading";

import { IAddApplicantStepsProps } from "../../types";
import { useEffect, useMemo, useState } from "react";
import useGetMoneyLookups from "views/DashboardApp/Money/hooks/useGetMoneyLookups";
import { useSetMoneyOnboardingMutation } from "graphql/__generated__/operations/MoneyOnboardingValidation.generated";
import useOnboardingData from "views/DashboardApp/Money/Onboarding/hooks/useOnboardingData";
import { INTITAL_APPLICANT_INFORMATION_VALUES } from "./helpers/intialIndividualMemberInfoData";
import { addApplicantSteps } from "../types";
import { removeTypename } from "utils/helpers/removeTypename";
import { US_KEY, isValueValid } from "views/DashboardApp/Money/helpers";

const ApplicantInformation = ({
  setCurrentStep,
  handleOnClose,
  isNewApplicant,
}: IAddApplicantStepsProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { onboardingData, isOnboardingDataLoading } = useOnboardingData();
  const { lookupsData, isLookupsDataLoading } = useGetMoneyLookups({
    neededLookups: [LookupTypeKey.COUNTRYOFOPERATION, LookupTypeKey.STATE],
  });
  const [updateMoneyOnboarding] = useSetMoneyOnboardingMutation();

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const { t } = useTranslation();

  const { control, formState, handleSubmit, watch, trigger, setValue } =
    useForm<OnboardingApplicantInput>({
      defaultValues: { ...INTITAL_APPLICANT_INFORMATION_VALUES },
      mode: "onChange",
    });

  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  const country = watch("address.country");

  const filteredOnboardingData: WalletOnboard = useMemo(
    () => removeTypename(onboardingData),
    [onboardingData]
  );

  useEffect(() => {
    const applicant = filteredOnboardingData?.applicant;

    if (applicant && !isNewApplicant) {
      setValue(
        "additionalInfoApplicationDeclaration",
        applicant.additionalInfoApplicationDeclaration || ""
      );
      setValue("contactCountryCode", applicant.contactCountryCode || "");
      setValue("contactEmail", applicant.contactEmail || "");
      setValue("contactNumber", applicant.contactNumber || "");
      setValue("dateOfBirth", applicant.dateOfBirth || "");
      setValue("documents", applicant.documents || []);
      setValue("firstName", applicant.firstName || "");
      setValue("lastName", applicant.lastName || "");
      setValue("nationality", applicant.nationality || "");
      setValue("address.address1", applicant.address?.address1 || "");
      setValue("address.address2", applicant.address?.address2 || "");
      setValue("address.city", applicant.address?.city || "");
      setValue("address.country", applicant.address?.country || "");
      setValue("address.postalCode", applicant.address?.postalCode || "");
      setValue("address.state", applicant.address?.state || "");

      if (
        isValueValid(applicant.additionalInfoApplicationDeclaration) &&
        isValueValid(applicant.contactCountryCode) &&
        isValueValid(applicant.contactEmail) &&
        isValueValid(applicant.contactNumber) &&
        isValueValid(applicant.dateOfBirth) &&
        isValueValid(applicant.documents) &&
        isValueValid(applicant.firstName) &&
        isValueValid(applicant.lastName) &&
        isValueValid(applicant.nationality) &&
        isValueValid(applicant.address?.address1) &&
        isValueValid(applicant.address?.address2) &&
        isValueValid(applicant.address?.city) &&
        isValueValid(applicant.address?.country) &&
        isValueValid(applicant.address?.postalCode) &&
        isValueValid(applicant.address?.state)
      ) {
        trigger();
      }
    }
  }, [filteredOnboardingData?.applicant, isNewApplicant, setValue, trigger]);

  if (
    !lookupsData ||
    isLookupsDataLoading ||
    !onboardingData ||
    isOnboardingDataLoading
  ) {
    return (
      <Stack height={"200px"} alignItems={"center"} justifyContent={"center"}>
        <LoadingComponent />
      </Stack>
    );
  }

  const onSubmitHandler = async (applicant: OnboardingApplicant) => {
    try {
      setIsLoading(true);

      await updateMoneyOnboarding({
        variables: {
          ...filteredOnboardingData,
          applicant: {
            ...applicant,
            professionalDetailsPositions: [
              OnboardingStakeholderBusinessEntityType.CONTROL_PRONG,
            ],
          },
        },
      });

      setCurrentStep(addApplicantSteps.applicantDocuments);
    } finally {
      setIsLoading(false);
    }
  };

  const isDisabled = !formState.isValid;

  return (
    <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
      <Stack direction={"row"} alignItems={"center"} gap={1.5} mb={3}>
        <PersonOutlined />
        <Typography fontSize={16} fontWeight={500}>
          {t("money.onboarding.step.applicantDetails.title")}
        </Typography>
      </Stack>
      <Stack gap={4}>
        <Stack gap={5}>
          <Stack gap={2}>
            <Stack direction={isTablet ? "column" : "row"} gap={3}>
              <Controller
                name="firstName"
                {...COMMON_PROPS}
                rules={{
                  required: true,
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    error={error !== undefined}
                    styles={{ width: "100%" }}
                    placeholder={
                      t(
                        "money.onboarding.step.applicantDetails.firstName.placeholder"
                      ) as string
                    }
                    label={
                      t(
                        "money.onboarding.step.applicantDetails.firstName"
                      ) as string
                    }
                  />
                )}
              />
              <Controller
                name="lastName"
                {...COMMON_PROPS}
                rules={{
                  required: true,
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    error={error !== undefined}
                    styles={{ width: "100%" }}
                    placeholder={
                      t(
                        "money.onboarding.step.applicantDetails.lastName.placeholder"
                      ) as string
                    }
                    label={
                      t(
                        "money.onboarding.step.applicantDetails.lastName"
                      ) as string
                    }
                  />
                )}
              />
            </Stack>
            <Stack direction={isTablet ? "column" : "row"} gap={3}>
              {lookupsData?.COUNTRYOFOPERATION && (
                <Controller
                  name="nationality"
                  {...COMMON_PROPS}
                  rules={{
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomSelect
                      {...field}
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      placeholder={
                        t(
                          "money.onboarding.step.applicantDetails.nationality.placeholder"
                        ) as string
                      }
                      label={
                        t(
                          "money.onboarding.step.applicantDetails.nationality"
                        ) as string
                      }
                      defaultValue={field.value}
                      onChange={(e: SelectChangeEvent<unknown>) => {
                        field.onChange(e.target.value);

                        // assuming contanctCountryCode is tied to nationality
                        setValue(
                          "contactCountryCode",
                          e.target.value as string
                        );
                      }}
                    >
                      {lookupsData.COUNTRYOFOPERATION.map((item, index) => (
                        <MenuItem key={index} value={item?.key ?? ""}>
                          {item.description}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  )}
                />
              )}
              <Controller
                name="dateOfBirth"
                rules={{
                  required: true,
                }}
                {...COMMON_PROPS}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <CustomDatePicker
                    value={dayjs(new Date(value ?? 0))}
                    error={error !== undefined}
                    styles={{ width: "100%" }}
                    onChange={(newValue) =>
                      onChange(dayjs(newValue).format("YYYY-MM-DD"))
                    }
                    placeholder={
                      t(
                        "money.onboarding.step.applicantDetails.dob.placeholder"
                      ) as string
                    }
                    label={
                      t("money.onboarding.step.applicantDetails.dob") as string
                    }
                    maxDate={utils.maxAgeDate}
                    disableFuture
                  />
                )}
              />
            </Stack>
            <Stack direction={isTablet ? "column" : "row"} gap={3}>
              <Controller
                name="contactEmail"
                {...COMMON_PROPS}
                rules={{
                  required: true,
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                  validate: (value) =>
                    EmailRegex.test((value ?? "").trim()) ||
                    "Please use a valid email address",
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    error={error !== undefined}
                    styles={{ width: "100%" }}
                    placeholder={
                      t(
                        "money.onboarding.step.applicantDetails.contactEmail.placeholder"
                      ) as string
                    }
                    label={
                      t(
                        "money.onboarding.step.applicantDetails.contactEmail"
                      ) as string
                    }
                  />
                )}
              />
              <Controller
                name="contactNumber"
                {...COMMON_PROPS}
                rules={{
                  required: true,
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    isPhone
                    error={error !== undefined}
                    styles={{ width: "100%" }}
                    placeholder={
                      t(
                        "money.onboarding.step.applicantDetails.contactNumber.placeholder"
                      ) as string
                    }
                    label={
                      t(
                        "money.onboarding.step.applicantDetails.contactNumber"
                      ) as string
                    }
                  />
                )}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack mt={4} direction={"row"} alignItems={"center"} gap={1.5}>
        <EmailOutlined />
        <Typography fontSize={16} fontWeight={500}>
          {t("money.onboarding.step.applicantDetails.applicantAddress")}
        </Typography>
      </Stack>
      <Stack gap={4} mt={3}>
        <Stack gap={5} mb={isTablet ? 2 : 0}>
          <Stack gap={2}>
            <Stack direction={isTablet ? "column" : "row"} gap={3}>
              <Controller
                name="address.address1"
                {...COMMON_PROPS}
                rules={{
                  required: true,
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    error={error !== undefined}
                    styles={{ width: "100%" }}
                    placeholder={
                      t(
                        "money.onboarding.step.applicantDetails.address1.placeholder"
                      ) as string
                    }
                    label={
                      t(
                        "money.onboarding.step.applicantDetails.address1"
                      ) as string
                    }
                  />
                )}
              />
              <Controller
                name="address.address2"
                {...COMMON_PROPS}
                rules={{
                  required: false,
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    error={error !== undefined}
                    styles={{ width: "100%" }}
                    placeholder={
                      t(
                        "money.onboarding.step.applicantDetails.address2.placeholder"
                      ) as string
                    }
                    label={
                      t(
                        "money.onboarding.step.applicantDetails.address2"
                      ) as string
                    }
                  />
                )}
              />
            </Stack>
            <Stack direction={isTablet ? "column" : "row"} gap={3}>
              {lookupsData?.COUNTRYOFOPERATION && (
                <Controller
                  name="address.country"
                  {...COMMON_PROPS}
                  rules={{
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomSelect
                      {...field}
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      placeholder={
                        t(
                          "money.onboarding.step.applicantDetails.country.placeholder"
                        ) as string
                      }
                      label={
                        t(
                          "money.onboarding.step.applicantDetails.country"
                        ) as string
                      }
                      defaultValue={field.value}
                      onChange={(e: SelectChangeEvent<unknown>) => {
                        setValue("address.state", "");
                        field.onChange(e.target.value);
                      }}
                    >
                      {lookupsData.COUNTRYOFOPERATION.map((item, index) => (
                        <MenuItem key={index} value={item?.key ?? ""}>
                          {item.description}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  )}
                />
              )}
              <Controller
                name="address.city"
                {...COMMON_PROPS}
                rules={{
                  required: true,
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    error={error !== undefined}
                    styles={{ width: "100%" }}
                    placeholder={
                      t(
                        "money.onboarding.step.applicantDetails.city.placeholder"
                      ) as string
                    }
                    label={
                      t("money.onboarding.step.applicantDetails.city") as string
                    }
                  />
                )}
              />
            </Stack>
            <Stack direction={isTablet ? "column" : "row"} gap={3} mb={4}>
              {lookupsData?.STATE && (
                <Controller
                  name="address.state"
                  {...COMMON_PROPS}
                  rules={{
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) =>
                    country === US_KEY ? (
                      <CustomSelect
                        {...field}
                        error={error !== undefined}
                        styles={{ width: "100%" }}
                        placeholder={
                          t(
                            "money.onboarding.step.applicantDetails.state.select.placeholder"
                          ) as string
                        }
                        label={
                          t(
                            "money.onboarding.step.applicantDetails.state"
                          ) as string
                        }
                        defaultValue={field.value}
                        onChange={(e: SelectChangeEvent<unknown>) => {
                          field.onChange(e.target.value);
                        }}
                      >
                        {lookupsData.STATE.map((item, index) => (
                          <MenuItem key={index} value={item?.key ?? ""}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    ) : (
                      <CustomInput
                        {...field}
                        error={error !== undefined}
                        styles={{ width: "100%" }}
                        placeholder={
                          t(
                            "money.onboarding.step.applicantDetails.state.enter.placeholder"
                          ) as string
                        }
                        label={
                          t(
                            "money.onboarding.step.applicantDetails.state"
                          ) as string
                        }
                      />
                    )
                  }
                />
              )}
              <Controller
                name="address.postalCode"
                {...COMMON_PROPS}
                rules={{
                  required: true,
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    error={error !== undefined}
                    styles={{ width: "100%" }}
                    placeholder={
                      t(
                        "money.onboarding.step.applicantDetails.zipCode.placeholder"
                      ) as string
                    }
                    label={
                      t(
                        "money.onboarding.step.applicantDetails.zipCode"
                      ) as string
                    }
                  />
                )}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <ButtonBox
        leftIcon="close"
        isLoading={isLoading}
        disabled={isDisabled}
        onClickBack={() => handleOnClose()}
        onClickNext={() => onSubmitHandler}
      />
    </form>
  );
};

export default ApplicantInformation;
