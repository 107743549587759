import { IQuestionProps } from "./types";
import {
  Box,
  Button,
  IconButton,
  InputBase,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import utils, { colors } from "utils";
import { staticQuestionData } from "./data";
import ButtonBox from "components/ButtonBox";
import { Controller, useForm } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import {
  UserSurveyAnswerInput,
  UserSurveyQuestionOption,
} from "graphql/__generated__/types";
import { Windows1252Regex } from "utils/regexValidations";
import { t } from "i18next";

const OTHER_OPTION_TEXT = "Other";

export const Options = ({
  question,
  currentStep,
  setStep,
  isLastStep,
  savedQuestionData,
  submitStepData,
  isLoading,
}: IQuestionProps) => {
  const { control, trigger, setValue, watch, formState, handleSubmit } =
    useForm<UserSurveyAnswerInput>({
      defaultValues: {
        doolaUserSurveyQuestionId: question?.id ?? "",
        questionType: question?.type ?? "",
        answerText: "",
        optionId: "",
      },
      mode: "onTouched",
    });
  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };
  const [isOtherText, setIsOtherText] = useState<string>("");

  const answerText = watch("answerText");

  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);

  useEffect(() => {
    if (question?.id && question?.type) {
      const { answerText = "", optionId = "" } = savedQuestionData || {};

      setValue("answerText", answerText);
      setValue("optionId", optionId);
      setValue("doolaUserSurveyQuestionId", question.id);
      setValue("questionType", question.type);

      if (
        answerText &&
        question?.textOption &&
        !question?.options?.some(
          (option) =>
            answerText === option?.text && option?.text !== OTHER_OPTION_TEXT
        )
      ) {
        setIsOtherText(OTHER_OPTION_TEXT as string);
      }

      trigger();
    }
  }, [question, savedQuestionData, setValue, trigger]);

  useEffect(() => {
    trigger();
  }, [isOtherText, trigger]);

  const onOptionChange = useCallback(
    (option?: UserSurveyQuestionOption | null) => {
      if (option) {
        if (option.text === OTHER_OPTION_TEXT) {
          setValue("answerText", "");
          setIsOtherText(option.text as string);
        } else {
          setValue("answerText", option.text);
          setIsOtherText("");
        }

        setValue("optionId", option.id);
      }
    },
    [setValue]
  );

  const isNotFirstStep = currentStep !== 0;
  const helperText = staticQuestionData?.[currentStep]?.helpText;
  const isDisabled = !!!answerText || (!!isOtherText && !formState.isValid);

  const rendeTextOptions = () =>
    [...(question?.options ?? [])]
      ?.sort((a, b) => (a?.optionIdx ?? 0) - (b?.optionIdx ?? 0))
      ?.map((option, index) => (
        <Button
          key={index}
          sx={{
            bgcolor: colors.grey,
            color: colors.black,
            borderRadius: "1rem",
            p: "14px",
            boxShadow: 0,
            border: `1px solid ${colors.grey}`,
            whiteSpace: "nowrap",
            "&:hover": {
              bgcolor: colors.darkGrey,
              boxShadow: 0,
              ...((answerText === option?.text ||
                isOtherText === option?.id) && {
                background: colors.lightBlue,
                border: `1px solid ${colors.borderBlue}`,
              }),
            },
            ...((answerText === option?.text ||
              isOtherText === option?.text) && {
              background: colors.lightBlue,
              border: `1px solid ${colors.borderBlue}`,
            }),
          }}
          variant="contained"
          onClick={() => onOptionChange(option)}
        >
          <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            {option?.text}
          </Box>
        </Button>
      ));

  const renderOptionsButtons = () => (
    <>
      {question?.textOption
        ? rendeTextOptions()
        : [...(question?.options ?? [])]
            ?.sort((a, b) => (a?.optionIdx ?? 0) - (b?.optionIdx ?? 0))
            .map((option, index) => (
              <IconButton
                key={index}
                sx={{
                  bgcolor: colors.grey,
                  color: colors.black,
                  height: isMobile ? "51px" : "72px",
                  width: isMobile ? "51px" : "72px",
                  fontSize: "2rem",
                  "&:hover": {
                    bgcolor: colors.darkGrey,
                    ...(answerText === option?.text && {
                      background: colors.lightBlue,
                    }),
                  },
                  ...(answerText === option?.text && {
                    background: colors.lightBlue,
                    border: `1px solid ${colors.borderBlue}`,
                  }),
                }}
                onClick={() => onOptionChange(option)}
              >
                <span
                  style={{ padding: 0 }}
                  dangerouslySetInnerHTML={{ __html: option?.text ?? "" }}
                />
              </IconButton>
            ))}
    </>
  );

  return (
    <Stack gap={5}>
      <Typography variant="h3" fontSize={isMobile ? "24px" : "28px"}>
        {question?.question}
      </Typography>
      <Stack
        direction={"row"}
        gap={isMobile ? 1 : 2}
        flexWrap={"wrap"}
        {...(!question?.textOption && { justifyContent: "space-between" })}
      >
        <Controller
          name={"doolaUserSurveyQuestionId"}
          {...COMMON_PROPS}
          rules={{
            required: true,
          }}
          render={() => renderOptionsButtons()}
        />
        {isOtherText && (
          <Stack width={"100%"}>
            <Controller
              name={"answerText"}
              {...COMMON_PROPS}
              rules={{
                required: true,
                pattern: {
                  value: Windows1252Regex,
                  message: t("error.invalidCharacters"),
                },
              }}
              render={({ field: { ref, ...field } }) => (
                <InputBase
                  {...field}
                  fullWidth
                  multiline
                  placeholder="Type your answer here..."
                  inputProps={{
                    style: {
                      background: colors.grey,
                      border: "none",
                      padding: "1rem",
                      borderRadius: "1rem",
                    },
                  }}
                />
              )}
            />
            {helperText && (
              <Typography
                fontSize={"12px"}
                px={2}
                color={colors.contentSecondary}
              >
                {helperText}
              </Typography>
            )}
          </Stack>
        )}
      </Stack>
      <ButtonBox
        isLoading={isLoading}
        disabled={isDisabled}
        {...(isLastStep && {
          rightButtonText: "Submit",
        })}
        showBackButton={false}
        {...(isNotFirstStep && {
          showBackButton: true,
          onClickBack: () => setStep((prevStep) => prevStep - 1),
        })}
        onClickNext={handleSubmit(submitStepData)}
      />
    </Stack>
  );
};
