import React, { FunctionComponent } from "react";
import styled, { css } from "styled-components";
import utils, { colors } from "utils";

type Props = {
  $radius?: "light" | "medium" | "strong";
  $shadow?: "light" | "strong" | "xstrong" | "none";
  $styles?: any;
  $padding?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  onClick?: () => void;
  children?: React.ReactNode;
};

export const CustomCard: FunctionComponent<Props> = (props) => (
  <StyledBuilder {...props}>{props.children}</StyledBuilder>
);

const StyledBuilder = styled.div<Props>`
  background-color: ${colors.lightGrey};
  padding: ${(props) =>
    (props.$padding ? props.$padding : 3) * utils.constants.units.main}px;
  ${(props) => {
    switch (props.$radius) {
      case "light":
        return StyledRadiusLight;
      case "medium":
        return StyledRadiusMedium;
      case "strong":
        return StyledRadiusHard;
      default:
        return "";
    }
  }};
  ${(props) => props.$styles ?? {}}
`;

const StyledRadiusLight = css`
  border-radius: 6px;
`;

const StyledRadiusMedium = css`
  border-radius: 24px;
`;

const StyledRadiusHard = css`
  border-radius: 32px;
`;
