import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAddressTypeMutationVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  addressType: Types.AddressType;
}>;


export type UpdateAddressTypeMutation = { __typename?: 'Mutation', company?: { __typename?: 'CompanyMutation', updateAddressType?: { __typename?: 'Company', id: string, addressType?: Types.AddressType | null, services?: Array<{ __typename?: 'Services', service: Types.CompanyServices, variant?: Types.ServiceVariant | null, status: Types.ServiceCategory, subStatus?: string | null }> | null } | null } | null };


export const UpdateAddressTypeDocument = gql`
    mutation UpdateAddressType($companyId: ID!, $addressType: AddressType!) {
  company(id: $companyId) {
    updateAddressType(new: $addressType) {
      id
      addressType
      services {
        service
        variant
        status
        subStatus
      }
    }
  }
}
    `;
export type UpdateAddressTypeMutationFn = Apollo.MutationFunction<UpdateAddressTypeMutation, UpdateAddressTypeMutationVariables>;

/**
 * __useUpdateAddressTypeMutation__
 *
 * To run a mutation, you first call `useUpdateAddressTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressTypeMutation, { data, loading, error }] = useUpdateAddressTypeMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      addressType: // value for 'addressType'
 *   },
 * });
 */
export function useUpdateAddressTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAddressTypeMutation, UpdateAddressTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAddressTypeMutation, UpdateAddressTypeMutationVariables>(UpdateAddressTypeDocument, options);
      }
export type UpdateAddressTypeMutationHookResult = ReturnType<typeof useUpdateAddressTypeMutation>;
export type UpdateAddressTypeMutationResult = Apollo.MutationResult<UpdateAddressTypeMutation>;
export type UpdateAddressTypeMutationOptions = Apollo.BaseMutationOptions<UpdateAddressTypeMutation, UpdateAddressTypeMutationVariables>;