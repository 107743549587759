import { useCallback, useEffect, useState } from "react";
import { useGetMoneyRoutingCodeLazyQuery } from "graphql/__generated__/operations/MoneyRoutingCode.generated";

interface IRoutingCode {
  getRoutingCode: (countryCode: string) => Promise<void>;
  routingCodeData?: string | null;
  isRoutingCodeDataLoading: boolean;
}

export default function useGetRoutingCode(countryCode?: string): IRoutingCode {
  const [isRoutingCodeDataLoading, setIsRoutingCodeDataLoading] =
    useState<boolean>(true);
  const [routingCodeData, setRoutingCodeData] = useState<string | null>();

  const [fetchRoutingCode] = useGetMoneyRoutingCodeLazyQuery({
    errorPolicy: "all",
  });

  const getRoutingCode = useCallback(
    async (countryCode: string) => {
      setIsRoutingCodeDataLoading(true);

      try {
        const { data: routingCodeData } = await fetchRoutingCode({
          variables: { countryCode },
        });

        const routingCode = routingCodeData?.getRoutingCode?.payload?.[0];

        if (routingCode) {
          setRoutingCodeData(routingCode);
        }
      } catch (error) {
        console.log("Failed to fetch routingCode data, error: ", error);
      } finally {
        setIsRoutingCodeDataLoading(false);
      }
    },
    [fetchRoutingCode]
  );

  useEffect(() => {
    if (countryCode) {
      getRoutingCode(countryCode);
    }
  }, [getRoutingCode, countryCode]);

  return {
    getRoutingCode,
    routingCodeData,
    isRoutingCodeDataLoading,
  };
}
