import { useGetProductsQuery } from "graphql/__generated__/operations/Products.generated";
import { Product } from "graphql/__generated__/types";
import { PRODUCTS_DB_KEYS } from "resources/productsLookupKeys";

export default function useGetTaxRelatedProducts() {
  const { data, loading: isProductQueryLoading } = useGetProductsQuery({
    fetchPolicy: "cache-first",
    errorPolicy: "all",
  });

  const productServices = data?.productService?.payload;

  const filteredFormationProducts = productServices?.filter((item) =>
    [
      PRODUCTS_DB_KEYS.ANNUAL_TAX_PACKAGE,
      PRODUCTS_DB_KEYS.CPA_CONSULTATIONS,
    ].includes(item?.key as PRODUCTS_DB_KEYS)
  );

  const findPlanByKey = (key: string) =>
    filteredFormationProducts?.find((item) => item?.key === key);

  const createFormationData = (plan?: Product | null) => ({
    name: plan?.name,
    lookupKey: plan?.key ?? "",
    value: plan?.stripePricingId ?? "",
    price: (plan?.usdPriceCents ?? 0) / 100,
  });

  const TAX = findPlanByKey(PRODUCTS_DB_KEYS.ANNUAL_TAX_PACKAGE);
  const CPA_CONSULTATIONS = findPlanByKey(PRODUCTS_DB_KEYS.CPA_CONSULTATIONS);

  const TAX_PLANS = {
    TAX: createFormationData(TAX),
    CPA_CONSULTATIONS: createFormationData(CPA_CONSULTATIONS),
  };

  return { TAX_PLANS, isProductQueryLoading };
}
