import { CloseRounded } from "@mui/icons-material";
import {
  Dialog,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import utils, { colors } from "utils";
import FinalCpaModalStep from "./components/FinalCpaModalStep";
import { useState } from "react";
import useCustomer from "graphql/hooks/useCustomer";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { CPA_CALENDLY_LINK, CPA_CONSULTATION } from "../../constants";
import ButtonBox from "components/ButtonBox";
import { t } from "i18next";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import useCompany from "graphql/hooks/UseCompany";
import { LoadingComponent } from "components/Loading";
import { usePostHog } from "posthog-js/react";

interface ICpaModalProps {
  isModalOpen: boolean;
  onCloseModalHandler: () => void;
}

const CpaModal = ({ isModalOpen, onCloseModalHandler }: ICpaModalProps) => {
  const posthog = usePostHog();
  const { currentUser } = useCustomer();
  const { currentCompany, refetchCompany } = useCompany();
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const [isFinalStep, setIsFinalStep] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useCalendlyEventListener({
    onEventScheduled: () => {
      setIsDisabled(false);

      posthog?.capture(`cpa_consultation_is_scheduled`);
    },
  });

  return (
    <Dialog
      fullScreen={isTablet}
      PaperProps={{
        style: {
          borderRadius: !isTablet ? "32px" : "0",
          width: isTablet ? "100%" : "800px",
        },
      }}
      open={isModalOpen}
      onClose={() => {
        onCloseModalHandler();
        isFinalStep && setTimeout(() => setIsFinalStep(false), 500);
      }}
    >
      <Stack p={4}>
        <IconButton
          onClick={onCloseModalHandler}
          sx={{ alignSelf: "flex-end" }}
        >
          <CloseRounded color="primary" />
        </IconButton>
        {isFinalStep ? (
          <FinalCpaModalStep
            onClose={() => {
              onCloseModalHandler();
              setTimeout(() => setIsFinalStep(false), 500);
            }}
          />
        ) : (
          <>
            <Stack direction={"row"} alignItems={"center"} gap={1.5}>
              <CalendarMonthOutlinedIcon />
              <Typography fontSize={16} fontWeight={500}>
                {t("dashboard.tax.taxFilingModal.step.bookConsultation.title")}
              </Typography>
            </Stack>
            <Typography fontSize={16} color={colors.contentTertiary} mt={0.5}>
              {t("dashboard.tax.cpaModal.bookConsultation.description")}
            </Typography>
            <Stack mt={2} gap={4}>
              {currentUser && currentCompany ? (
                <InlineWidget
                  styles={{
                    height: "600px",
                  }}
                  url={CPA_CALENDLY_LINK}
                  prefill={{
                    email: currentUser?.email ?? "",
                    firstName: currentUser?.firstName ?? "",
                    lastName: currentUser?.lastName ?? "",
                    name: currentUser?.name ?? "",
                    smsReminderNumber: currentUser?.phoneNumber ?? "",
                    customAnswers: {
                      a1: "",
                    },
                  }}
                  utm={{
                    // to connect event with the companyId
                    utmSource: "companyId",
                    utmCampaign: currentCompany?.id ?? "",
                    // to detect either event CPA or TAX
                    utmTerm: CPA_CONSULTATION,
                  }}
                />
              ) : (
                <LoadingComponent />
              )}
              <ButtonBox
                disabled={isDisabled}
                showBackButton={false}
                onClickNext={() => {
                  setIsFinalStep(true);
                  refetchCompany();
                }}
              />
            </Stack>
          </>
        )}
      </Stack>
    </Dialog>
  );
};

export default CpaModal;
