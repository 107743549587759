import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserSurveyQuestionOptionFragment = { __typename?: 'UserSurveyQuestionOption', id?: string | null, text?: string | null, value?: string | null, optionIdx?: number | null };

export type UserSurveyQuestionFragment = { __typename?: 'UserSurveyQuestion', created?: string | null, end?: number | null, id?: string | null, lookupKey?: string | null, qidx?: number | null, question?: string | null, start?: number | null, textOption?: string | null, type?: string | null, updated?: string | null, options?: Array<{ __typename?: 'UserSurveyQuestionOption', id?: string | null, text?: string | null, value?: string | null, optionIdx?: number | null } | null> | null };

export type UserSurveyQuestionnaireFragment = { __typename?: 'UserSurveyQuestionnaire', created?: string | null, id?: string | null, updated?: string | null, questions?: Array<{ __typename?: 'UserSurveyQuestion', created?: string | null, end?: number | null, id?: string | null, lookupKey?: string | null, qidx?: number | null, question?: string | null, start?: number | null, textOption?: string | null, type?: string | null, updated?: string | null, options?: Array<{ __typename?: 'UserSurveyQuestionOption', id?: string | null, text?: string | null, value?: string | null, optionIdx?: number | null } | null> | null } | null> | null };

export type UserSurveyFragment = { __typename?: 'UserSurvey', created?: string | null, id?: string | null, lookupKey?: string | null, name: string, updated?: string | null, activeQuestionnaire?: { __typename?: 'UserSurveyQuestionnaire', created?: string | null, id?: string | null, updated?: string | null, questions?: Array<{ __typename?: 'UserSurveyQuestion', created?: string | null, end?: number | null, id?: string | null, lookupKey?: string | null, qidx?: number | null, question?: string | null, start?: number | null, textOption?: string | null, type?: string | null, updated?: string | null, options?: Array<{ __typename?: 'UserSurveyQuestionOption', id?: string | null, text?: string | null, value?: string | null, optionIdx?: number | null } | null> | null } | null> | null } | null };

export type UserSurveyResultQueryVariables = Types.Exact<{
  key: Types.Scalars['String'];
}>;


export type UserSurveyResultQuery = { __typename?: 'Query', userSurvey?: { __typename?: 'UserSurveyResult', error?: string | null, payload?: { __typename?: 'UserSurvey', created?: string | null, id?: string | null, lookupKey?: string | null, name: string, updated?: string | null, activeQuestionnaire?: { __typename?: 'UserSurveyQuestionnaire', created?: string | null, id?: string | null, updated?: string | null, questions?: Array<{ __typename?: 'UserSurveyQuestion', created?: string | null, end?: number | null, id?: string | null, lookupKey?: string | null, qidx?: number | null, question?: string | null, start?: number | null, textOption?: string | null, type?: string | null, updated?: string | null, options?: Array<{ __typename?: 'UserSurveyQuestionOption', id?: string | null, text?: string | null, value?: string | null, optionIdx?: number | null } | null> | null } | null> | null } | null } | null } | null };

export const UserSurveyQuestionOptionFragmentDoc = gql`
    fragment UserSurveyQuestionOption on UserSurveyQuestionOption {
  id
  text
  value
  optionIdx
}
    `;
export const UserSurveyQuestionFragmentDoc = gql`
    fragment UserSurveyQuestion on UserSurveyQuestion {
  created
  end
  id
  lookupKey
  options {
    ...UserSurveyQuestionOption
  }
  qidx
  question
  start
  textOption
  type
  updated
}
    ${UserSurveyQuestionOptionFragmentDoc}`;
export const UserSurveyQuestionnaireFragmentDoc = gql`
    fragment UserSurveyQuestionnaire on UserSurveyQuestionnaire {
  created
  id
  questions {
    ...UserSurveyQuestion
  }
  updated
}
    ${UserSurveyQuestionFragmentDoc}`;
export const UserSurveyFragmentDoc = gql`
    fragment UserSurvey on UserSurvey {
  activeQuestionnaire {
    ...UserSurveyQuestionnaire
  }
  created
  id
  lookupKey
  name
  updated
}
    ${UserSurveyQuestionnaireFragmentDoc}`;
export const UserSurveyResultDocument = gql`
    query UserSurveyResult($key: String!) {
  userSurvey(key: $key) {
    error
    payload {
      ...UserSurvey
    }
  }
}
    ${UserSurveyFragmentDoc}`;

/**
 * __useUserSurveyResultQuery__
 *
 * To run a query within a React component, call `useUserSurveyResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSurveyResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSurveyResultQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useUserSurveyResultQuery(baseOptions: Apollo.QueryHookOptions<UserSurveyResultQuery, UserSurveyResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserSurveyResultQuery, UserSurveyResultQueryVariables>(UserSurveyResultDocument, options);
      }
export function useUserSurveyResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserSurveyResultQuery, UserSurveyResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserSurveyResultQuery, UserSurveyResultQueryVariables>(UserSurveyResultDocument, options);
        }
export type UserSurveyResultQueryHookResult = ReturnType<typeof useUserSurveyResultQuery>;
export type UserSurveyResultLazyQueryHookResult = ReturnType<typeof useUserSurveyResultLazyQuery>;
export type UserSurveyResultQueryResult = Apollo.QueryResult<UserSurveyResultQuery, UserSurveyResultQueryVariables>;