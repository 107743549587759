import { Add } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Divider, Stack, Typography, useMediaQuery } from "@mui/material";
import { ReactComponent as RocketImage } from "assets/Rocket.svg";
import { LoadingComponent } from "components/Loading";
import { StyledEditButton } from "components/StyledEditButton";
import { CompanyServices, ProductDuration } from "graphql/__generated__/types";
import useSignupFlow from "graphql/hooks/UseSignupFlow";
import { t } from "i18next";
import { usePostHog } from "posthog-js/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import {
  selectExpeditedFormationPackage,
  selectFormationPackage,
  setExpeditedFormationPackage,
} from "state/formationFormSlice";
import { useAppDispatch, useAppSelector } from "state/hooks";
import utils, { colors } from "utils";
import { moneyFormatter } from "utils/formatters";
import useGetProcessingProducts from "views/SignupFlow/Expedited/hooks/useGetProcessingProducts";
import { TOTAL_COMPLIANCE } from "views/SignupFlow/Packages/data";
import { trackPosthogAddOn } from "views/SignupFlow/services";

const Expedited = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const posthog = usePostHog();
  const { countryOfResidence } = useSignupFlow();

  const [isPackageLoading, setIsPackageLoading] = useState(false);

  const selectedFormationPackage = useAppSelector(selectFormationPackage);
  const selectedExpeditedFormationPackage = useAppSelector(
    selectExpeditedFormationPackage
  );

  const { PROCESSING_PLANS, isProductQueryLoading } =
    useGetProcessingProducts();

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  if (isProductQueryLoading) {
    return <LoadingComponent />;
  }
  const isTotalCompliancePackage =
    selectedFormationPackage?.name === TOTAL_COMPLIANCE.value;
  const isUSResident = countryOfResidence === "USA";

  const onAddExpeditedOptionHandler = () => {
    setIsPackageLoading(true);

    const expediteFormationData = isUSResident
      ? PROCESSING_PLANS.EXPEDITED_FORMATION_DATA
      : PROCESSING_PLANS.EXPEDITED_EIN_DATA;

    const {
      name,
      value: stripePriceId,
      price,
      lookupKey,
    } = expediteFormationData;

    dispatch(
      setExpeditedFormationPackage({
        item_name: name,
        stripePriceId: stripePriceId,
        lookupKey,
        price: price,
        item_category2: isUSResident
          ? CompanyServices.FORMATION
          : CompanyServices.EINCREATION,
        item_category3: ProductDuration.ONETIME,
      })
    );

    posthog?.capture("expedited-product-selected", {
      expedited_product_name: name,
      expedited_product_id: stripePriceId,
      expedited_product_value: price,
      expedited_product_term: ProductDuration.ONETIME,
    });

    trackPosthogAddOn(name, stripePriceId, price);

    setIsPackageLoading(false);
  };

  return (
    <>
      {isTotalCompliancePackage || selectedExpeditedFormationPackage ? (
        <Stack spacing={2} bgcolor={colors.lightGrey} p={3} borderRadius="24px">
          {!isTotalCompliancePackage ? (
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                justifyContent="space-between"
                width="100%"
              >
                <Typography variant="h5">Options</Typography>
                <StyledEditButton
                  onClick={() => {
                    posthog?.capture("company-pre-purchase-review-edit", {
                      review_edit: ROUTER_PATHS.PRE_PURCHASE_EXPEDITED,
                    });
                    navigate(ROUTER_PATHS.PRE_PURCHASE_EXPEDITED);
                  }}
                />
              </Stack>
            </Stack>
          ) : (
            <Typography variant="h5">Options</Typography>
          )}
          <Stack direction="row" justifyContent="space-between">
            <Typography>Type</Typography>
            <Typography color={colors.contentSecondary}>
              {isUSResident ? "Formation Expedite" : "EIN Expedite"}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography>Billing</Typography>
            <Typography color={colors.contentSecondary}>
              {isTotalCompliancePackage ? "Included in Package" : "One-time"}
            </Typography>
          </Stack>
          <Divider />
          <Stack direction="row" justifyContent="flex-end">
            <Typography variant="h5" fontWeight={500}>
              {moneyFormatter(
                isTotalCompliancePackage
                  ? 0
                  : selectedExpeditedFormationPackage?.price || 0,
                false
              )}
            </Typography>
          </Stack>
        </Stack>
      ) : (
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
          bgcolor={colors.lightBlue}
          p={3}
          borderRadius="24px"
        >
          <Stack gap={1}>
            <Typography variant="h5" fontWeight={500}>
              {t("signup.expedited.title")}
            </Typography>
            <Stack direction={"row"} alignItems={"flex-end"} spacing={1}>
              <Typography variant="h2" fontSize={28} lineHeight={1}>
                {moneyFormatter(PROCESSING_PLANS.EXPEDITED_EIN_DATA.price)}
              </Typography>
              <Typography variant="body1" color={colors.contentTertiary}>
                One-time
              </Typography>
            </Stack>
            <Box mb={2}>
              <Typography>
                Expedited processing:{" "}
                {isUSResident ? "1-3 days" : "4 weeks on average"}
              </Typography>
              <Typography>
                Standard processing:{" "}
                {isUSResident ? "up to 2 weeks" : "8 weeks on average"}
              </Typography>
            </Box>
            <Box>
              <LoadingButton
                loading={isPackageLoading}
                onClick={onAddExpeditedOptionHandler}
                variant="contained"
                endIcon={<Add />}
                sx={{
                  borderRadius: "30px",
                  p: "6px 12px 6px 16px",
                  boxShadow: "none",
                  "&:hover": {
                    bgcolor: colors.charcoal,
                    boxShadow: "none",
                  },
                }}
              >
                Add
              </LoadingButton>
            </Box>
          </Stack>
          {!isTablet && <RocketImage height="170px" />}
        </Stack>
      )}
    </>
  );
};

export default React.memo(Expedited);
