import { buildProfileData } from "./services";
import { LoadingComponent } from "components/Loading";
import { DataEnteredSummary } from "components/DataEntered";
import { Alert, Grid, Typography, useMediaQuery } from "@mui/material";
import utils, { colors } from "utils";
import { CustomCard } from "components/Cards";
import useCompany from "graphql/hooks/UseCompany";
import useCustomer from "graphql/hooks/useCustomer";

const ProfileContainer = () => {
  const { currentCompany } = useCompany();
  const { currentUser } = useCustomer();

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  if (!currentCompany || !currentUser) return <LoadingComponent />;

  const dataToPass = buildProfileData({
    user: currentUser,
    company: currentCompany,
  });

  return (
    <Grid container spacing={3}>
      <Grid item lg={12}>
        <Typography variant="h2" mb={4}>
          Profile
        </Typography>
        <Alert severity="info" sx={{ width: isTablet ? "100%" : "65%" }}>
          <Typography fontSize={12}>
            The business member information provided is submitted to the state
            and cannot be updated here. If you’d like to amend any information
            with the state, please contact us at{" "}
            <a
              href={"mailto: hello@doola.com"}
              style={{
                textDecoration: "none",
                color: colors.green,
              }}
            >
              hello@doola.com
            </a>
          </Typography>
        </Alert>
      </Grid>

      <Grid item lg={12} md={8}>
        <CustomCard $shadow="xstrong" $radius="strong">
          <DataEnteredSummary {...dataToPass} />
        </CustomCard>
      </Grid>
    </Grid>
  );
};

export default ProfileContainer;
