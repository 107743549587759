import { Box, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import BooksImage from "assets/Banners/books.svg";
import TCImage from "assets/Banners/tc.svg";
import { FAQDashboard } from "components/FAQs";
import { FAQ_DATA } from "components/FAQs/data";
import { P } from "components/Fonts";
import { LinkRouter } from "components/LinkRouter";
import { LoadingComponent } from "components/Loading";
import config from "config";
import { useRequestStripeCheckoutSessionDdsMutation } from "graphql/__generated__/operations/RequestStripeCheckoutSessionFromDDS.generated";
import {
  Company,
  CompanyServices,
  Customer,
} from "graphql/__generated__/types";
import useCompany from "graphql/hooks/UseCompany";
import useCustomer from "graphql/hooks/useCustomer";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import { colors } from "utils";
import { capitalizedSentence } from "utils/formatters";
import { Affiliate } from "./Affliate/index";
import { Banner } from "./Banner";
import Feedback from "./Feedback";
import { InviteSomeone } from "./InviteSomeone";
import { RateCard } from "./RateCard";
import useUserSurveyResponse from "./hooks/useUserSurveyResponse";
import { isDateWithin90Days } from "./util";
import { usePostHog } from "posthog-js/react";
import Tracker from "./Tracker";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import { PRODUCTS_DB_KEYS } from "resources/productsLookupKeys";

type DashboardProps = {
  user: Customer;
  company: Company;
};

const Dashboard: React.FC<DashboardProps> = ({ user, company }) => {
  const navigate = useNavigate();
  const posthog = usePostHog();

  const [isFeedbackBlockVisible, setIsFeedbackBlockVisible] =
    useState<boolean>(false);

  const { userSurveyResponse, isUserSurveyResponseLoading } =
    useUserSurveyResponse();
  const [generateStripeLinkForTax] =
    useRequestStripeCheckoutSessionDdsMutation();

  useEffect(() => {
    if (!isUserSurveyResponseLoading && userSurveyResponse?.payload) {
      const responses = userSurveyResponse.payload;

      if (!responses.length) {
        setIsFeedbackBlockVisible(true);
      } else {
        const lastResponse = responses[responses.length - 1];

        if (lastResponse?.created) {
          setIsFeedbackBlockVisible(!isDateWithin90Days(lastResponse.created));
        }
      }
    }
  }, [isUserSurveyResponseLoading, userSurveyResponse]);

  const documents = company?.documents?.edges?.filter(Boolean) || [];

  const taxComplianceBundle = company?.upsells?.find(
    (upsell) => upsell.name === "Tax/Compliance Bundle" // this will be an enum value at some point in the future
  );
  const showTaxBanner = !!taxComplianceBundle;

  const booksUpsell = company?.upsells?.find((upsell) =>
    upsell.name.includes("Bookkeeping - Books")
  );

  const recordKeepingService = company?.services?.find(
    (s) => s.service === CompanyServices.RECORDKEEPING
  );

  const showBooksBanner = !recordKeepingService && !!booksUpsell;

  const clickTaxBanner = async () => {
    const companyId = company?.id;
    const customerId = user?.id || "";
    const email = user?.email || "";
    const products = [PRODUCTS_DB_KEYS.TOTAL_COMPLIANCE_ANNUAL];
    const redirectBaseUri = `${config.basePath}${ROUTER_PATHS.DASHBOARD}`;

    posthog?.capture("upsell-banner-click", {
      campaign_name: "total_compliance_upsell",
    });

    try {
      const { data } = await generateStripeLinkForTax({
        variables: { companyId, customerId, products, redirectBaseUri, email },
      });

      const stripeCheckoutUrl =
        data?.company?.requestStripeCheckoutSessionDDS?.url;

      if (stripeCheckoutUrl) {
        window.location.replace(stripeCheckoutUrl);
        // I'm not sure if we need to do anything with the stripe session id that we get back. Let's revisit this.
      }
    } catch {
      // error handling delegated upstream to the ErrorLink
    }
  };

  return (
    <>
      <Box mb={4}>
        <P size="medium">Welcome back,</P>
        <P size="large" data-testid="dashboard-full-name" $emphasis={true}>
          {capitalizedSentence(user.name || "")}
        </P>
      </Box>
      <Box mb={4}>
        <Tracker company={company || []} />
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={8}>
          <Stack gap={4}>
            {showBooksBanner && (
              <Banner
                bgColor={colors.lightAccentGreen}
                imageSrc={BooksImage}
                title="Stress-free bookkeeping for busy founders  📈"
                text="Manage expenses, invoices, and payments with ease, while staying on top of your business finances. See the difference with doola Books."
                buttonText="Get doola Books"
                onClick={() => {
                  posthog?.capture("upsell-banner-click", {
                    campaign_name: "doola_books_upsell",
                  });
                  navigate(ROUTER_PATHS.BOOKS);
                }}
              />
            )}
            {showTaxBanner && (
              <Banner
                bgColor={colors.borderBlue}
                imageSrc={TCImage}
                title="Get your taxes and compliance handled  🤝"
                text={
                  "Focus on your business growth, doola can keep your business compliant and take care of your tax filings."
                }
                buttonText="Upgrade"
                onClick={clickTaxBanner}
                learnMoreHref={
                  "https://www.doola.com/products/total-compliance/"
                }
              />
            )}
            <Box>
              <Affiliate />
            </Box>
            <FAQDashboard faqs={FAQ_DATA} />
          </Stack>
        </Grid>
        <Grid item lg={4} xs={12}>
          <Stack gap={2} mb={4}>
            <LinkRouter to={ROUTER_PATHS.DOCUMENTS}>
              <RateCard
                icon={<DescriptionOutlinedIcon style={{ fontSize: "24px" }} />}
                title="Documents"
                number={documents.length}
              />
            </LinkRouter>
            <LinkRouter to={ROUTER_PATHS.SERVICES}>
              <RateCard
                icon={
                  <FormatListBulletedOutlinedIcon
                    style={{ fontSize: "24px" }}
                  />
                }
                title="Services"
                number={company?.services?.length || 0}
              />
            </LinkRouter>
          </Stack>
          <Stack gap={4}>
            {isFeedbackBlockVisible && (
              <Feedback setVisibility={setIsFeedbackBlockVisible} />
            )}
            <InviteSomeone />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

const DashboardContainer = () => {
  const { currentUser } = useCustomer();
  const { currentCompany } = useCompany();

  if (!currentCompany || !currentUser) return <LoadingComponent />;

  return <Dashboard user={currentUser} company={currentCompany} />;
};

export default DashboardContainer;
