export interface InitialRiskAssessmentInfoValuesType {
  totalNumberOfEmployees: string;
  annualTurnover: string;
  industrySector: string;
  intendedUseOfAccount: string;
  countriesOfOperation: Array<string | null>;
  transactionCountries: Array<string | null>;
}

export const INITIAL_RISK_ASSESSMENT_INFO_VALUES: InitialRiskAssessmentInfoValuesType =
  {
    totalNumberOfEmployees: "",
    annualTurnover: "",
    industrySector: "",
    intendedUseOfAccount: "",
    countriesOfOperation: [],
    transactionCountries: [],
  };
