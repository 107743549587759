import {
  EntityType,
  Iso31661Alpha3,
  TaxMemberInfo,
} from "graphql/__generated__/types";

export const INITIAL_MEMBER_VALUES: TaxMemberInfo = {
  address: {
    city: "",
    country: "USA" as Iso31661Alpha3,
    line1: "",
    line2: "",
    postalCode: "",
    state: "",
    phone: "",
  },
  contactFullName: "",
  entityType: EntityType.LLC,
  ein: "",
  legalFirstName: "",
  isNaturalPerson: true,
  legalLastName: "",
  ssn: "",
  dob: "",
  ownershipPercent: 0,
};
