import { CustomCard } from "components/Cards";
import { CustomWrapper } from "./styles";
import { Box, Stack, Typography } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import { colors } from "utils";

type Props = {
  icon: JSX.Element;
  title: string;
  number: string | number;
};

export const RateCard: React.FC<Props> = ({ icon, title, number }) => {
  return (
    <CustomCard $styles={CustomWrapper} $radius="medium">
      <Stack
        direction={"row"}
        justifyItems={"space-between"}
        alignItems={"center"}
        gap={2}
      >
        {icon}
        <Typography fontSize={16} fontWeight={500}>
          {title}
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        justifyItems={"space-between"}
        alignItems={"center"}
        gap={2}
      >
        <Box px={1} bgcolor={colors.grey} borderRadius={3}>
          <Typography fontSize={16} fontWeight={500}>
            {number}
          </Typography>
        </Box>
        <NavigateNext />
      </Stack>
    </CustomCard>
  );
};
