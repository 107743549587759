export const ENTITY_INDUSTRIES = [
  "Health care & social assistance",
  "Wholesale-agent/broker",
  "Construction",
  "Rental & leasing",
  "Transportation & warehousing",
  "Accommodation & food service",
  "Wholesale-other",
  "Retail",
  "Real estate",
  "Manufacturing",
  "Finance & insurance",
  "Other",
];
