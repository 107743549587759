import {
  ITaxFilingStep,
  TaxFilingStepsCategories,
  TaxFilingStepsData,
} from "./data";
import { Box, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import PrepareInfo from "./Steps/PrepareInfo";
import { IStepProps } from "./types";
import Members from "./Steps/Members";
import BookConsultation from "./Steps/BookConsultation";
import { Dispatch, SetStateAction, useState } from "react";
import { colors } from "utils";
import Information from "./Steps/Information";
import Questions from "./Steps/Questions";
import TaxDocuments from "./Steps/TaxDocuments";

interface IStepContentProps extends IStepProps {
  isInHouseCustomer: boolean;
  currentStep: ITaxFilingStep;
  onCloseModalHandler: () => void;
  setIsFinalStep: Dispatch<SetStateAction<boolean>>;
}

const StepContent = ({
  isInHouseCustomer,
  currentStep,
  setCurrentStep,
  onCloseModalHandler,
  setIsFinalStep,
}: IStepContentProps) => {
  const [showHeading, setShowHeading] = useState(true);

  const renderStepContent = (currentStep: ITaxFilingStep) => {
    const commonProps: IStepProps = {
      setCurrentStep: setCurrentStep,
    };

    switch (currentStep) {
      case TaxFilingStepsData[TaxFilingStepsCategories.prepareInfo]:
        return (
          <PrepareInfo
            {...commonProps}
            isInHouseCustomer={isInHouseCustomer}
            onCloseModalHandler={onCloseModalHandler}
          />
        );
      case TaxFilingStepsData[TaxFilingStepsCategories.companyMembers]:
        return <Members {...commonProps} setShowHeading={setShowHeading} />;
      case TaxFilingStepsData[TaxFilingStepsCategories.companyInformation]:
        return <Information {...commonProps} />;
      case TaxFilingStepsData[TaxFilingStepsCategories.questions]:
        return <Questions {...commonProps} />;
      case TaxFilingStepsData[TaxFilingStepsCategories.taxDocuments]:
        return <TaxDocuments {...commonProps} />;
      case TaxFilingStepsData[TaxFilingStepsCategories.bookConsultation]:
        return (
          <BookConsultation
            {...commonProps}
            isInHouseCustomer={isInHouseCustomer}
            setIsFinalStep={setIsFinalStep}
          />
        );
      // Todo : make this active when required
      // case TaxFilingStepsData[TaxFilingStepsCategories.signDocuments]:
      //   return (
      //     <SignDocuments {...commonProps} setIsFinalStep={setIsFinalStep} />
      //   );
    }
  };

  return (
    <Box>
      {showHeading && (
        <>
          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
            {currentStep.icon}
            <Typography fontSize={16} fontWeight={500}>
              {t(currentStep.title)}
            </Typography>
          </Stack>
          {currentStep.description && (
            <Typography fontSize={16} color={colors.contentTertiary} mt={0.5}>
              {t(currentStep.description)}
            </Typography>
          )}
        </>
      )}
      {renderStepContent(currentStep)}
    </Box>
  );
};

export default StepContent;
