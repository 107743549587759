import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import ButtonBox from "components/ButtonBox";
import useCompany from "graphql/hooks/UseCompany";
import { useForm, FormProvider } from "react-hook-form";
import { LoadingComponent } from "components/Loading";
import { colors } from "utils";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import MemberCard from "./MemberCard";
import ResponsiblePartyModal from "./ResponsiblePartyModal";
import { INITIAL_MEMBER_VALUES } from "../helpers/initialMembersData";
import { EntityType, Member } from "graphql/__generated__/types";
import { useUpdateResponsiblePartyMutation } from "graphql/__generated__/operations/UpdateCompanyResponsibleParty.generated";

const OnboardingResponsibleParty = () => {
  const navigate = useNavigate();
  const { currentCompany } = useCompany();

  const [sortedIndividualMembers, setSortedIndividualMembers] = useState<
    Member[] | []
  >([]);
  const [
    selectedResponsiblePartyPosition,
    setSelectedResponsiblePartyPosition,
  ] = useState<number | null>(null);
  const [responsibleParty, setResponsibleParty] = useState<
    Member | undefined
  >();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [updateResponsibleParty] = useUpdateResponsiblePartyMutation();

  const responsiblePartyAddedRef = useRef(false);

  const methods = useForm<typeof INITIAL_MEMBER_VALUES>({
    defaultValues: { ...INITIAL_MEMBER_VALUES },
    mode: "onChange",
  });

  const hasLoadedRef = useRef(false);

  const isCCorpFlow = useMemo(
    () => currentCompany?.entityType === EntityType.CCORP,
    [currentCompany?.entityType]
  );

  // navigate to members if no members found in the company (For ccorp navigate to EXCEUTIVE page)
  useEffect(() => {
    if (!hasLoadedRef.current && currentCompany) {
      hasLoadedRef.current = true;

      if (isCCorpFlow) {
        if (!currentCompany.ccorpExecInfo) {
          navigate(ROUTER_PATHS.ONBOARDING_EXECUTIVE);
        }
      } else {
        const hasMembers = !!(
          currentCompany?.members && currentCompany?.members.length > 0
        );

        if (!hasMembers) {
          navigate(ROUTER_PATHS.ONBOARDING_MEMBERS);
        }
      }
    }
  }, [currentCompany, isCCorpFlow, navigate]);

  useEffect(() => {
    if (currentCompany?.responsibleParty) {
      const { __typename, ...RpData } = currentCompany?.responsibleParty;
      const { __typename: addressTypeName, ...addressData } =
        currentCompany?.responsibleParty.address;

      const availablePosition = currentCompany?.members?.length
        ? currentCompany.members[currentCompany.members.length - 1].position! +
          1
        : 0;

      setResponsibleParty({
        ...RpData,
        address: addressData,
        isNaturalPerson: true,
        position: availablePosition,
      });

      setSelectedResponsiblePartyPosition(availablePosition);
    }
  }, [currentCompany?.members, currentCompany?.responsibleParty]);

  // show only individual members sorted by ssn first
  useEffect(() => {
    if (isCCorpFlow) {
      if (responsibleParty) {
        setSortedIndividualMembers([responsibleParty]);
      }

      setSelectedResponsiblePartyPosition(responsibleParty?.position ?? null);
    } else {
      if (currentCompany?.members) {
        const sortedFilteredMembers = currentCompany.members
          ?.filter((member) => member.isNaturalPerson)
          .sort((a, b) => {
            return b.ssn ? 1 : a.ssn ? -1 : 0;
          });

        if (
          (responsiblePartyAddedRef.current ||
            currentCompany.responsibleParty) &&
          responsibleParty
        ) {
          const chosenMember = sortedFilteredMembers.find(
            (member) =>
              member.legalFirstName === responsibleParty.legalFirstName &&
              member.legalLastName === responsibleParty.legalLastName
          );

          if (chosenMember) {
            setSelectedResponsiblePartyPosition(chosenMember.position!);
          } else {
            sortedFilteredMembers.unshift(responsibleParty);

            setSelectedResponsiblePartyPosition(
              responsibleParty?.position || null
            );
          }
        }

        setSortedIndividualMembers(sortedFilteredMembers);
      } else {
        setSortedIndividualMembers([]);
      }
    }
  }, [
    currentCompany?.members,
    currentCompany?.responsibleParty,
    isCCorpFlow,
    responsibleParty,
  ]);

  if (!currentCompany) {
    return <LoadingComponent />;
  }

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: number = +event.target.value;

    setSelectedResponsiblePartyPosition(value);
  };

  const handleOnNext = async () => {
    try {
      setIsLoading(true);

      if (
        responsibleParty &&
        responsibleParty.position === selectedResponsiblePartyPosition
      ) {
        const { contactFullName, isNaturalPerson, position, ...member } =
          responsibleParty;

        await updateResponsibleParty({
          variables: {
            companyId: currentCompany?.id,
            member: { ...member },
          },
        });
      } else {
        const chosenMember = sortedIndividualMembers.find(
          (member) => member.position === selectedResponsiblePartyPosition
        );

        if (chosenMember) {
          const {
            __typename,
            contactFullName,
            isNaturalPerson,
            position,
            ownershipPercent,
            ...memberData
          } = chosenMember;
          const { __typename: addressTypeName, ...addressData } =
            chosenMember.address;

          await updateResponsibleParty({
            variables: {
              companyId: currentCompany?.id,
              member: {
                ...memberData,
                address: addressData,
              },
            },
          });
        }
      }

      if (isCCorpFlow) {
        navigate(ROUTER_PATHS.ONBOARDING_SHARE);
      } else {
        navigate(ROUTER_PATHS.ONBOARDING_OWNERSHIP);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const onAddHandler = async (data: typeof INITIAL_MEMBER_VALUES) => {
    setIsLoading(true);

    const { socialSecurityNumber, confirmSocialSecurityNumber, ...member } =
      data;

    const availablePosition = currentCompany?.members?.length
      ? currentCompany.members[currentCompany.members.length - 1].position! + 1
      : 0;

    setResponsibleParty({
      ...member,
      ssn: socialSecurityNumber,
      position: isCCorpFlow ? 1 : availablePosition,
    });

    responsiblePartyAddedRef.current = true;
    toggleModal();
    setIsLoading(false);
  };

  const isDisabled = typeof selectedResponsiblePartyPosition !== "number";

  return (
    <>
      <Stack direction="column" gap={3}>
        <Typography variant="h1">Set Responsible Party</Typography>
        <Stack bgcolor={colors.lightGrey} borderRadius="24px" p={3}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5">Responsible Party</Typography>
            <Button
              disableElevation
              variant="contained"
              onClick={toggleModal}
              endIcon={<AddIcon fontSize="small" />}
              sx={{
                height: 36,
                borderRadius: "30px",
                bgcolor: colors.grey,
                color: colors.black,
                "&:hover": {
                  bgcolor: colors.darkGrey,
                },
              }}
              data-testid="OnboardingResponsiblePartyAddButton"
            >
              Add
            </Button>
          </Stack>
          {sortedIndividualMembers.length > 0 && (
            <Stack gap={3} mt={2}>
              {sortedIndividualMembers?.map((member, index) => (
                <MemberCard
                  key={index}
                  chipText={
                    !responsibleParty &&
                    member.ssn &&
                    sortedIndividualMembers.length > 1
                      ? "Recommended"
                      : ""
                  }
                  member={member}
                  checked={selectedResponsiblePartyPosition === member.position}
                  value={member.position!}
                  onChangeHandler={onChangeHandler}
                />
              ))}
            </Stack>
          )}
        </Stack>
        <Stack direction="row" alignItems={"center"} gap={2}>
          <InfoIcon />
          <Typography>
            Select or add the responsible party who will be the primary contact
            person for the Internal Revenue Service (IRS). This must be an
            individual and not a company. If a company is a member, the
            Responsible Party may be an officer at that company. This individual
            bears no additional tax liability from a non-responsible party.
          </Typography>
        </Stack>
        <ButtonBox
          isLoading={isLoading}
          disabled={isDisabled}
          onClickBack={() =>
            isCCorpFlow
              ? navigate(ROUTER_PATHS.ONBOARDING_EXECUTIVE)
              : navigate(ROUTER_PATHS.ONBOARDING_MEMBERS)
          }
          onClickNext={handleOnNext}
        />
      </Stack>
      <FormProvider {...methods}>
        <ResponsiblePartyModal
          isOpenModal={showModal}
          isLoading={isLoading}
          toggleModalHandler={toggleModal}
          onAddHandler={onAddHandler}
        />
      </FormProvider>
    </>
  );
};

export default OnboardingResponsibleParty;
