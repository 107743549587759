import {
  CheckCircleOutline,
  RadioButtonUnchecked,
  Schedule,
} from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import Stepper from "components/Stepper";
import { Dispatch, SetStateAction } from "react";
import { colors } from "utils";
import { TrackerCategories, StepStatusType, TrackerData } from "../data";
import { ServiceCategory } from "graphql/__generated__/types";
import { t } from "i18next";

type Props = {
  category: string;
  subCategory: string;
  setSubCategory: Dispatch<SetStateAction<string>>;
  status: StepStatusType;
};

const renderIcon = (stepStatus: ServiceCategory, isCurrentStep: boolean) => {
  switch (stepStatus) {
    case ServiceCategory.COMPLETED:
      return (
        <CheckCircleOutline
          sx={{
            fontSize: "1.2rem",
            color: isCurrentStep ? colors.green : colors.contentTertiary,
          }}
        />
      );
    case ServiceCategory.INPROGRESS:
      return (
        <Schedule
          sx={{
            fontSize: "1.2rem",
            color: isCurrentStep
              ? colors.lightContentWarning
              : colors.contentTertiary,
          }}
        />
      );
    case ServiceCategory.NOTSTARTED:
      return (
        <RadioButtonUnchecked
          sx={{ fontSize: "1.2rem", color: colors.contentTertiary }}
        />
      );
  }
};

const ProgressTracker = ({
  category,
  subCategory,
  setSubCategory,
  status,
}: Props) => {
  // All steps are accessible only in Essentials tab
  const isEnabledStep = (step: string) => {
    return (
      status[step] !== ServiceCategory.NOTSTARTED ||
      category === TrackerCategories.essentials
    );
  };

  return (
    <Stack
      sx={{
        bgcolor: colors.lightGrey,
        borderRadius: "32px",
        p: 4,
        height: "100%",
      }}
      gap={4}
    >
      <Stepper
        label={t("general.step")}
        currentStep={
          Object.keys(TrackerData[category].steps).filter(
            (step) => status[step] === ServiceCategory.COMPLETED
          ).length
        }
        numberOfSteps={Object.keys(TrackerData[category].steps).length}
      />
      <Stack gap={3}>
        {Object.keys(TrackerData[category].steps).map((step, index) => {
          const isCurrentSelectedStep = step === subCategory;

          return (
            <Stack
              key={index}
              direction={"row"}
              alignItems={"center"}
              gap={2}
              sx={{ cursor: isEnabledStep(step) ? "pointer" : "default" }}
              onClick={() => isEnabledStep(step) && setSubCategory(step)}
            >
              {renderIcon(status[step], isCurrentSelectedStep)}
              <Typography
                color={
                  isCurrentSelectedStep ? colors.black : colors.contentTertiary
                }
                fontSize={16}
                fontWeight={500}
              >
                {step}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default ProgressTracker;
