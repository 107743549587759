import styled from "styled-components";
import utils from "utils";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

export const CustomPhoneInput = styled(PhoneInput)`
  border: 1px solid ${utils.secondaryColors.secondary400};
  border-radius: 4px;
  padding: 0 14px;

  &.PhoneInput--focus {
    border: 1px solid ${utils.secondaryColors.secondaryDarker};
  }

  input {
    border: none;
    background-color: transparent;

    &:focus,
    &:hover {
      outline: none;
    }
  }
`;
