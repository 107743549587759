import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerateReferralLinkMutationVariables = Types.Exact<{
  customerId: Types.Scalars['ID'];
}>;


export type GenerateReferralLinkMutation = { __typename?: 'Mutation', customer?: { __typename?: 'CustomerMutation', generateReferralLink?: { __typename?: 'Customer', id?: string | null, referralMetadata?: { __typename?: 'CustomerReferralMetadata', link: string, token: string } | null } | null } | null };


export const GenerateReferralLinkDocument = gql`
    mutation GenerateReferralLink($customerId: ID!) {
  customer(id: $customerId) {
    generateReferralLink {
      id
      referralMetadata {
        link
        token
      }
    }
  }
}
    `;
export type GenerateReferralLinkMutationFn = Apollo.MutationFunction<GenerateReferralLinkMutation, GenerateReferralLinkMutationVariables>;

/**
 * __useGenerateReferralLinkMutation__
 *
 * To run a mutation, you first call `useGenerateReferralLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateReferralLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateReferralLinkMutation, { data, loading, error }] = useGenerateReferralLinkMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGenerateReferralLinkMutation(baseOptions?: Apollo.MutationHookOptions<GenerateReferralLinkMutation, GenerateReferralLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateReferralLinkMutation, GenerateReferralLinkMutationVariables>(GenerateReferralLinkDocument, options);
      }
export type GenerateReferralLinkMutationHookResult = ReturnType<typeof useGenerateReferralLinkMutation>;
export type GenerateReferralLinkMutationResult = Apollo.MutationResult<GenerateReferralLinkMutation>;
export type GenerateReferralLinkMutationOptions = Apollo.BaseMutationOptions<GenerateReferralLinkMutation, GenerateReferralLinkMutationVariables>;