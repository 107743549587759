import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCompanyEntityTypeEndingMutationVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  typeEnding: Types.Scalars['String'];
}>;


export type UpdateCompanyEntityTypeEndingMutation = { __typename?: 'Mutation', company?: { __typename?: 'CompanyMutation', updateEntityTypeEnding?: { __typename?: 'Company', id: string, entityTypeEnding?: string | null } | null } | null };


export const UpdateCompanyEntityTypeEndingDocument = gql`
    mutation UpdateCompanyEntityTypeEnding($companyId: ID!, $typeEnding: String!) {
  company(id: $companyId) {
    updateEntityTypeEnding(new: $typeEnding) {
      id
      entityTypeEnding
    }
  }
}
    `;
export type UpdateCompanyEntityTypeEndingMutationFn = Apollo.MutationFunction<UpdateCompanyEntityTypeEndingMutation, UpdateCompanyEntityTypeEndingMutationVariables>;

/**
 * __useUpdateCompanyEntityTypeEndingMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyEntityTypeEndingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyEntityTypeEndingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyEntityTypeEndingMutation, { data, loading, error }] = useUpdateCompanyEntityTypeEndingMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      typeEnding: // value for 'typeEnding'
 *   },
 * });
 */
export function useUpdateCompanyEntityTypeEndingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyEntityTypeEndingMutation, UpdateCompanyEntityTypeEndingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyEntityTypeEndingMutation, UpdateCompanyEntityTypeEndingMutationVariables>(UpdateCompanyEntityTypeEndingDocument, options);
      }
export type UpdateCompanyEntityTypeEndingMutationHookResult = ReturnType<typeof useUpdateCompanyEntityTypeEndingMutation>;
export type UpdateCompanyEntityTypeEndingMutationResult = Apollo.MutationResult<UpdateCompanyEntityTypeEndingMutation>;
export type UpdateCompanyEntityTypeEndingMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyEntityTypeEndingMutation, UpdateCompanyEntityTypeEndingMutationVariables>;