import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { store } from "./state/store";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import { Tracker } from "services/tracking";
import { IntercomProvider } from "react-use-intercom";
import config from "config";
import { ConfigProvider } from "contexts/ConfigContext";
import { ApolloProvider } from "@apollo/client";
import { client } from "Apollo/client";
import { LoadingComponent } from "components/Loading";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

import "./i18n";

Tracker.getInstance();
let persistor = persistStore(store);

posthog.init(config.posthogKeys, {
  api_host: "https://app.posthog.com",
});

const MainApp: React.FC<{}> = () => {
  return (
    <React.StrictMode>
      <React.Suspense fallback={<LoadingComponent />}>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ConfigProvider>
                <BrowserRouter>
                  <IntercomProvider
                    appId={config.intercomAppId}
                    apiBase="https://api-iam.intercom.io"
                    autoBoot
                  >
                    <PostHogProvider client={posthog}>
                      <App />
                    </PostHogProvider>
                  </IntercomProvider>
                </BrowserRouter>
              </ConfigProvider>
            </PersistGate>
          </Provider>
        </ApolloProvider>
      </React.Suspense>
    </React.StrictMode>
  );
};

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<MainApp />);
