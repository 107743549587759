import * as Types from '../types';

import { gql } from '@apollo/client';
import { CompanyTaxInfoFragmentDoc, CompanyTaxMembersFragmentDoc, PandaDocSignedDocsInfoFragmentDoc } from './Company.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditTaxCompanyInfoMutationVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  taxInfo?: Types.InputMaybe<Types.CompanyTaxInfoInput>;
}>;


export type EditTaxCompanyInfoMutation = { __typename?: 'Mutation', company?: { __typename?: 'CompanyMutation', editTaxCompanyInfo?: { __typename?: 'Company', id: string, companyTaxInfo?: { __typename?: 'TaxInfo', companyTaxInfo?: { __typename?: 'CompanyTaxInfo', assets?: string | null, businessDescription?: string | null, ein?: string | null, email?: string | null, grossRevenue?: string | null, taxFilingStatus?: string | null } | null, members?: Array<{ __typename?: 'TaxMemberInfo', dob?: string | null, entityType?: Types.EntityType | null, ein?: string | null, contactFullName?: string | null, isNaturalPerson?: boolean | null, legalFirstName?: string | null, legalLastName?: string | null, ownershipPercent?: number | null, position?: number | null, ssn?: string | null, address?: { __typename?: 'TaxAddress', line1?: string | null, line2?: string | null, state?: string | null, city?: string | null, phone?: string | null, postalCode?: string | null, country?: string | null } | null } | null> | null } | null, pandaDocSignedDocuments?: Array<{ __typename?: 'SignedDocument', name?: Types.PandadocName | null, sharedUrl?: string | null, status?: Types.PandadocStatus | null } | null> | null } | null } | null };


export const EditTaxCompanyInfoDocument = gql`
    mutation EditTaxCompanyInfo($companyId: ID!, $taxInfo: CompanyTaxInfoInput) {
  company(id: $companyId) {
    editTaxCompanyInfo(companyTaxInfo: $taxInfo) {
      id
      companyTaxInfo {
        ...CompanyTaxInfo
        ...CompanyTaxMembers
      }
      pandaDocSignedDocuments {
        ...PandaDocSignedDocsInfo
      }
    }
  }
}
    ${CompanyTaxInfoFragmentDoc}
${CompanyTaxMembersFragmentDoc}
${PandaDocSignedDocsInfoFragmentDoc}`;
export type EditTaxCompanyInfoMutationFn = Apollo.MutationFunction<EditTaxCompanyInfoMutation, EditTaxCompanyInfoMutationVariables>;

/**
 * __useEditTaxCompanyInfoMutation__
 *
 * To run a mutation, you first call `useEditTaxCompanyInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTaxCompanyInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTaxCompanyInfoMutation, { data, loading, error }] = useEditTaxCompanyInfoMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      taxInfo: // value for 'taxInfo'
 *   },
 * });
 */
export function useEditTaxCompanyInfoMutation(baseOptions?: Apollo.MutationHookOptions<EditTaxCompanyInfoMutation, EditTaxCompanyInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTaxCompanyInfoMutation, EditTaxCompanyInfoMutationVariables>(EditTaxCompanyInfoDocument, options);
      }
export type EditTaxCompanyInfoMutationHookResult = ReturnType<typeof useEditTaxCompanyInfoMutation>;
export type EditTaxCompanyInfoMutationResult = Apollo.MutationResult<EditTaxCompanyInfoMutation>;
export type EditTaxCompanyInfoMutationOptions = Apollo.BaseMutationOptions<EditTaxCompanyInfoMutation, EditTaxCompanyInfoMutationVariables>;