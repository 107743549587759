import { Stack, Typography, useMediaQuery } from "@mui/material";
import { ReactComponent as PileOfCoins } from "assets/Money/PileOfCoins.svg";
import { useTranslation } from "react-i18next";
import utils from "utils";

const NoTransactionsUI = () => {
  const { t } = useTranslation();
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  return (
    <Stack
      direction={isTablet ? "column" : "row"}
      alignItems={"center"}
      gap={4}
    >
      <Stack>
        <PileOfCoins width="100%" height={isTablet ? "120px" : "180px"} />
      </Stack>
      <Stack textAlign={isTablet ? "center" : "left"}>
        <Typography mb={1} fontSize={"16px"} fontWeight={500}>
          {t("money.overview.noTransactionTitle")}
        </Typography>
        <Typography fontSize={"16px"}>
          {t("money.overview.noTransactionSubTitle")}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default NoTransactionsUI;
