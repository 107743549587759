import { useEffect, useState } from "react";
import { Stack, Typography, Link, useMediaQuery } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import {
  CognitoPasswordRegex,
  EmailRegex,
  Windows1252Regex,
} from "utils/regexValidations";
import { ROUTER_PATHS } from "routes/routes";
import { generate as generateKSUID } from "xksuid";
import { setTokens } from "utils/auth";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import utils, { colors } from "utils";
import { useUpdateCustomerEmailMutation } from "graphql/__generated__/operations/UpdateCustomerEmail.generated";
import { useUpdateCustomerFirstNameMutation } from "graphql/__generated__/operations/UpdateCustomerFirstName.generated";
import { useUpdateCustomerLastNameMutation } from "graphql/__generated__/operations/UpdateCustomerLastName.generated";
import { useMakeCompanyOwnerMutation } from "graphql/__generated__/operations/MakeCompanyOwner.generated";
import { useUpdatetrackingDataMutation } from "graphql/__generated__/operations/UpdateTrackingData.generated";
import { useAuthSignUpMutation } from "graphql/__generated__/operations/AuthSignUp.generated";
import { useAuthRefreshTokensMutation } from "graphql/__generated__/operations/AuthRefreshTokens.generated";
import { Trans, useTranslation } from "react-i18next";
import { getReferralData } from "utils/Referral";
import { TrackerGA4 } from "services/GA4tracking";
import ButtonBox from "components/ButtonBox";
import ErrorBox from "components/ErrorBox";
import { usePostHog } from "posthog-js/react";
import CustomInput from "components/Form/CustomInput";
import { LinkExternalDomain } from "components/LinkExternalDomain";
import { PasswordRequirements } from "components/PasswordRequirements";

const GA4Tracker = TrackerGA4.getInstance();
const PRIVACY_POLICY_LINK = "https://www.doola.com/privacy-policy";
const TOS_LINK = "https://www.doola.com/terms-of-service";

interface IRegistrationFormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const Registration: React.FC<{}> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const posthog = usePostHog();

  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState<string>("");

  const [updateCustomerFirstName] = useUpdateCustomerFirstNameMutation();
  const [updateCustomerLastName] = useUpdateCustomerLastNameMutation();
  const [updateCustomerEmail] = useUpdateCustomerEmailMutation();
  const [makeCompanyOwner] = useMakeCompanyOwnerMutation();
  const [updateTrackingData] = useUpdatetrackingDataMutation();
  const [authSignUp] = useAuthSignUpMutation();
  const [authRefreshTokens] = useAuthRefreshTokensMutation();

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  useEffect(() => {
    posthog?.capture("signup-start");
    GA4Tracker.signUpStart();
  }, [posthog]);

  const { control, formState, handleSubmit, watch } =
    useForm<IRegistrationFormData>({
      defaultValues: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
      mode: "onBlur",
    });
  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };
  const newPassword = watch("password");

  const signUpUserInCognito = async ({
    email,
    firstName,
    lastName,
    password,
  }: IRegistrationFormData) => {
    const companyId = generateKSUID(); // random KSUID
    const { referral } = getReferralData();

    try {
      const signUpData = await authSignUp({
        variables: {
          input: {
            email,
            firstName,
            lastName,
            password,
          },
        },
      });
      const customerId = signUpData?.data?.auth?.signUp?.customerId || "";
      const isReturningUser = signUpData.data?.auth?.signUp?.isReturningUser;
      const tokens = signUpData?.data?.auth?.signUp?.tokens;

      if (tokens) {
        setTokens(tokens);
      }

      // if the user email already exists we don't need to run these mutations again
      if (!isReturningUser) {
        await updateCustomerFirstName({
          variables: { customerId, newFirstName: firstName },
        });

        await updateCustomerLastName({
          variables: { customerId, newLastName: lastName },
        });

        await updateCustomerEmail({
          variables: { customerId, newEmail: email },
        });

        await makeCompanyOwner({
          variables: { companyId, customerId },
        });

        const updatedTokens = await authRefreshTokens({
          variables: {
            input: {
              idToken: tokens?.idToken!,
              refreshToken: tokens?.refreshToken!,
            },
          },
          // adding context here allows us to overwrite the headers in the AuthLink to use the headers specified here
          context: {
            isPublicMutation: true,
          },
        });

        setTokens(updatedTokens?.data?.auth?.refreshTokens?.tokens!);

        if (referral) {
          await updateTrackingData({
            variables: { customerId, newToken: referral },
          });
        }

        posthog?.identify(customerId || "", {
          first_name: firstName,
          last_name: lastName,
          full_name: `${firstName} ${lastName}`,
          email,
        });

        posthog?.capture("account-created");
        GA4Tracker.accountCreated({ customerId, email });
      }

      // last step before checking out with Stripe and BEFORE setting their own password
      // the state machine will put the user on the right step
      navigate(ROUTER_PATHS.PRE_PURCHASE_TC);
    } catch {
      setIsLoading(false);
    }
  };

  const onSubmitHandler = (data: IRegistrationFormData) => {
    setIsLoading(true);
    signUpUserInCognito(data);
  };

  return (
    <Stack gap={5}>
      <Stack gap={1}>
        <Typography variant={isTablet ? "h3" : "h2"}>
          {t("signup.registration.header")} 🚀
        </Typography>
        <span>
          <Trans
            i18nKey={"general.existingAccount"}
            values={{
              login: t("general.logIn"),
            }}
            components={[
              <Link
                component={RouterLink}
                to={ROUTER_PATHS.LOGIN}
                onClick={() => posthog?.capture("login-exit")}
                fontWeight={500}
                color={colors.blue}
                sx={{
                  textDecoration: "none",
                  transition: "all 3s. ease-out",
                  "&:hover": {
                    color: colors.charcoal,
                  },
                }}
              />,
            ]}
          />
        </span>
      </Stack>
      {/* TODO: We can uncomment this once we work on google sso. */}
      {/* <Stack>
          <Button
            buttonText={t("signup.registration.signupWithGoogle")}
            dark={false}
            startIcon={<Google />}
            onClick={() => {}}
          />
          <Divider sx={{ mt: 5, borderBottomWidth: "15px" }}>
            {t("general.or")}
          </Divider>
        </Stack> */}
      <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
        <Stack gap={2} mb={2}>
          <Stack
            direction={isTablet ? "column" : "row"}
            spacing={isTablet ? 1 : 2}
          >
            <Stack spacing={1} sx={{ width: isTablet ? "100%" : "50%" }}>
              <Controller
                name={"firstName"}
                {...COMMON_PROPS}
                rules={{
                  required: true,
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    error={error !== undefined}
                    placeholder={t("general.firstNamePlaceholder") as string}
                    label={t("general.firstName") as string}
                  />
                )}
              />
            </Stack>
            <Stack spacing={1} sx={{ width: isTablet ? "100%" : "50%" }}>
              <Controller
                name={"lastName"}
                {...COMMON_PROPS}
                rules={{
                  required: true,
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    error={error !== undefined}
                    placeholder={t("general.lastNamePlaceholder") as string}
                    label={t("general.lastName") as string}
                  />
                )}
              />
            </Stack>
          </Stack>
          <Stack spacing={1}>
            <Controller
              name={"email"}
              {...COMMON_PROPS}
              rules={{
                required: true,
                validate: (value) =>
                  EmailRegex.test(value.trim()) ||
                  "Please use a valid email address",
              }}
              render={({ field }) => (
                <CustomInput
                  {...field}
                  placeholder={t("general.emailPlaceholder") as string}
                  label={t("general.email") as string}
                  autoComplete="email"
                />
              )}
            />
          </Stack>
          <Stack spacing={1}>
            <Controller
              name="password"
              {...COMMON_PROPS}
              rules={{
                required: true,
                validate: (value) =>
                  CognitoPasswordRegex.test(value.trim()) ||
                  "Password is not complex enough. Please try again.",
              }}
              render={({ field }) => (
                <CustomInput
                  {...field}
                  isProtected
                  placeholder={t("general.passwordPlaceholder") as string}
                  label={t("general.password") as string}
                  autoComplete="new-password"
                  onKeyUp={(e) =>
                    setPassword((e.target as HTMLInputElement).value)
                  }
                />
              )}
            />
          </Stack>
          <Stack spacing={1}>
            <Controller
              name="confirmPassword"
              {...COMMON_PROPS}
              rules={{
                required: true,
                validate: (value) =>
                  value === newPassword || "The passwords do not match",
              }}
              render={({ field }) => (
                <CustomInput
                  {...field}
                  isProtected
                  placeholder={
                    t("general.confirmPasswordPlaceholder") as string
                  }
                  label={t("general.confirmPassword") as string}
                  autoComplete="new-password"
                />
              )}
            />
          </Stack>
        </Stack>
        <ErrorBox formState={formState} style={{ mb: 2 }} />
        <Stack mb={isTablet ? 8 : 4} gap={4}>
          <PasswordRequirements password={password} />
          <span>
            <Trans
              i18nKey={"signup.registration.acknowledgement"}
              values={{
                tos: t("general.tos"),
                privacyPolicy: t("general.privacyPolicy"),
              }}
              components={[
                <LinkExternalDomain
                  to={TOS_LINK}
                  style={{
                    color: colors.blue,
                    fontWeight: 500,
                  }}
                />,
                <LinkExternalDomain
                  to={PRIVACY_POLICY_LINK}
                  style={{
                    color: colors.blue,
                    fontWeight: 500,
                  }}
                />,
              ]}
            />
          </span>
        </Stack>
        <ButtonBox
          isLoading={isLoading}
          disabled={!formState.isValid}
          onClickNext={() => onSubmitHandler}
          showBackButton={false}
          rightButtonText={t("signup.registration.signUpWithEmail") as string}
        />
      </form>
    </Stack>
  );
};

export default Registration;
