import { OnboardingDocumentType } from "graphql/__generated__/types";

export enum MoneyDocumentsSourceCategories {
  APPLICANT_IDENTITY_DOCUMENT = "APPLICANT_IDENTITY_DOCUMENT",
  BUSINESS_VERIFICATION_DOCUMENT = "BUSINESS_VERIFICATION_DOCUMENT",
  STAKEHOLDER_IDENTITY_DOCUMENT = "STAKEHOLDER_IDENTITY_DOCUMENT",
}

export type IdentityDocumentInfoValuesType = {
  onboardingDocType: OnboardingDocumentType;
  documentNumber: string;
  documentIssuanceCountry: string;
  expirationDate: string;
};
