import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Divider,
  FormControlLabel,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ButtonBox from "components/ButtonBox";
import StyledCheckbox from "components/FormElements/Checkbox";
import { LoadingComponent } from "components/Loading";
import { StyledEditButton } from "components/StyledEditButton";
import config from "config";
import { useRequestStripeCheckoutSessionDdsMutation } from "graphql/__generated__/operations/RequestStripeCheckoutSessionFromDDS.generated";
import { useUpdateCompanyAcceptTosMutation } from "graphql/__generated__/operations/UpdateCompanyAcceptTos.generated";
import { useUpdateCustomerStripeIdMutation } from "graphql/__generated__/operations/UpdateCustomerStripeId.generated";
import { Iso31662Us, ProductDuration } from "graphql/__generated__/types";
import useSignupFlow from "graphql/hooks/UseSignupFlow";
import useGetStateFeesProducts from "hooks/useGetStateFees";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import stateKeys from "resources/stateKeys";
import { ROUTER_PATHS } from "routes/routes";
import { calculateFeeValue } from "services";
import { Tracker } from "services/tracking";
import {
  selectBoiFilingFormationPackage,
  selectExpeditedFormationPackage,
  selectFormationPackage,
} from "state/formationFormSlice";
import { useAppSelector } from "state/hooks";
import utils, { colors } from "utils";
import { moneyFormatter } from "utils/formatters";
import { TOTAL_COMPLIANCE } from "../Packages/data";
import { FormationScreenNames } from "../services";
import Expedited from "./components/Expedited";
import useTotalCartValue from "../hooks/useTotalCartValue";
import { usePostHog } from "posthog-js/react";
import StateNotification from "../components/StateNotification";
import BoiFiling from "./components/BoiFiling";

const tracker = Tracker.getInstance();

const redirectBaseUri = `${config.basePath}${ROUTER_PATHS.PRE_PURCHASE_SUCCESS}`;

const Review = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const posthog = usePostHog();
  const {
    companyId,
    customerId,
    email,
    entityState,
    entityType,
    entityName,
    hasDataLoaded,
  } = useSignupFlow();
  const selectedFormationPackage = useAppSelector(selectFormationPackage);
  const selectedExpeditedFormationPackage = useAppSelector(
    selectExpeditedFormationPackage
  );
  const selectedBoiFilingFormationPackage = useAppSelector(
    selectBoiFilingFormationPackage
  );

  const {
    filteredFeesProducts,
    isProductQueryLoading: isFeesProductsQueryLoading,
  } = useGetStateFeesProducts();
  const { totalPayToday, totalPayLater } = useTotalCartValue();

  const [isTOSAccepted, setIsTOSAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [checkoutWithStripe] = useRequestStripeCheckoutSessionDdsMutation();
  const [updateCustomerStripeId] = useUpdateCustomerStripeIdMutation();
  const [updateCompanyAcceptTos] = useUpdateCompanyAcceptTosMutation();

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  useEffect(() => {
    posthog?.capture("review-page-viewed");
  }, [posthog]);

  if (!hasDataLoaded || isFeesProductsQueryLoading) {
    return <LoadingComponent />;
  }

  const isTotalCompliancePackage =
    selectedFormationPackage?.name === TOTAL_COMPLIANCE.value;
  const isAnnualDuration =
    selectedFormationPackage?.duration === ProductDuration.ANNUAL;

  const onSubmitHandler = async () => {
    setIsLoading(true);

    // get lookup key for the state fee
    const stateProductLookupKey = filteredFeesProducts?.find((item) =>
      item?.key.startsWith(entityState ?? "")
    )?.key;

    // filter out undefined values if packages are not selected
    const allProductLookupKeys = [
      selectedFormationPackage?.lookupKey,
      selectedExpeditedFormationPackage?.lookupKey,
      selectedBoiFilingFormationPackage?.lookupKey,
      stateProductLookupKey,
    ].filter(Boolean) as string[];

    try {
      tracker.completeRegistration(email);

      const { data } = await checkoutWithStripe({
        variables: {
          companyId,
          customerId,
          products: allProductLookupKeys,
          redirectBaseUri,
          email: email || "",
        },
      });

      const stripeCheckoutUrl =
        data?.company?.requestStripeCheckoutSessionDDS?.url;
      const stripeCustomerId =
        data?.company?.requestStripeCheckoutSessionDDS?.stripeCustomerId;

      if (stripeCustomerId) {
        await updateCustomerStripeId({
          variables: { customerId, stripeCustomerId },
        });
      }

      await updateCompanyAcceptTos({
        variables: { companyId },
      });

      if (stripeCheckoutUrl) {
        posthog?.capture("checkout-started");
        await new Promise((resolve) => window.setTimeout(resolve, 500)); // wait 500ms
        window.location.replace(stripeCheckoutUrl);
      }
    } catch {
      setIsLoading(false);
    }
  };

  const onBackHandler = () => {
    posthog?.capture("funnel-back", {
      screen: FormationScreenNames.Review,
    });

    const navigationPath = !isTotalCompliancePackage
      ? ROUTER_PATHS.PRE_PURCHASE_BOI_FILING
      : ROUTER_PATHS.PRE_PURCHASE_PACKAGES;

    navigate(navigationPath);
  };

  const state = stateKeys[entityState as keyof typeof stateKeys];
  const stateFee = calculateFeeValue(
    entityState as string,
    filteredFeesProducts
  );

  return (
    <Stack direction="column" gap={2} mb={5}>
      <Typography variant={isTablet ? "h3" : "h2"}>
        Review your order
      </Typography>
      <Stack spacing={2} bgcolor={colors.lightGrey} p={3} borderRadius="24px">
        <Typography variant="h5">General Information</Typography>
        {entityName && (
          <Stack direction="row" justifyContent="space-between">
            <Typography>Company Name</Typography>
            <Typography color={colors.contentSecondary}>
              {entityName}
            </Typography>
          </Stack>
        )}
        <Stack direction="row" justifyContent="space-between">
          <Typography>Entity Type</Typography>
          <Typography color={colors.contentSecondary}>{entityType}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography>State</Typography>
          <Typography color={colors.contentSecondary}>{state}</Typography>
        </Stack>
      </Stack>
      {selectedFormationPackage && (
        <Stack spacing={2} bgcolor={colors.lightGrey} p={3} borderRadius="24px">
          <Stack direction="row" spacing={1} alignItems="center">
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              justifyContent="space-between"
              width="100%"
            >
              <Typography variant="h5">Package</Typography>
              <StyledEditButton
                onClick={() => {
                  posthog?.capture("company-pre-purchase-review-edit", {
                    review_edit: ROUTER_PATHS.PRE_PURCHASE_PACKAGES,
                  });
                  navigate(ROUTER_PATHS.PRE_PURCHASE_PACKAGES);
                }}
              />
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography>Type</Typography>
            <Typography color={colors.contentSecondary}>
              {selectedFormationPackage.displayName}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography>Billing</Typography>
            <Typography color={colors.contentSecondary}>
              {selectedFormationPackage.duration}
            </Typography>
          </Stack>
          <Divider />
          <Stack direction="row" justifyContent="flex-end">
            <Typography variant="h5" fontWeight={500}>
              {moneyFormatter(selectedFormationPackage.price ?? 0, false)}
            </Typography>
          </Stack>
        </Stack>
      )}
      <Expedited />
      {(isTotalCompliancePackage || selectedBoiFilingFormationPackage) && (
        <BoiFiling />
      )}
      <Stack spacing={2} bgcolor={colors.lightGrey} p={3} borderRadius="24px">
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            justifyContent="space-between"
            width="100%"
          >
            <Typography variant="h5">State Fee</Typography>
            <StyledEditButton
              onClick={() => {
                posthog?.capture("company-pre-purchase-review-edit", {
                  review_edit: ROUTER_PATHS.PRE_PURCHASE_COMPANY_STATE,
                });
                navigate(ROUTER_PATHS.PRE_PURCHASE_COMPANY_STATE);
              }}
            />
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography>State</Typography>
          <Typography color={colors.contentSecondary}>{state}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography>Billing</Typography>
          <Typography color={colors.contentSecondary}>One-time</Typography>
        </Stack>
        <Divider />
        <Stack direction="row" justifyContent="flex-end">
          <Typography variant="h5" fontWeight={500}>
            {moneyFormatter(stateFee, false)}
          </Typography>
        </Stack>
      </Stack>
      <Stack spacing={2} bgcolor={colors.lightGrey} p={3} borderRadius="24px">
        <Typography variant="h5">{t("general.total")}</Typography>
        <Box>
          <Stack direction="row" justifyContent="space-between">
            <Typography>Due total</Typography>
            <Typography variant="h4">
              {moneyFormatter(totalPayToday, false)}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" color={colors.contentSecondary}>
              Then {moneyFormatter(totalPayLater, false)} per{" "}
              {isAnnualDuration ? "year" : "month"}
            </Typography>
            <Typography variant="body2" color={colors.contentSecondary}>
              Sales Tax Not Included
            </Typography>
          </Stack>
        </Box>
      </Stack>
      {entityState === Iso31662Us.CA && <StateNotification />}
      <Stack spacing={2} bgcolor={colors.lightGrey} p={3} borderRadius="24px">
        <FormControlLabel
          control={
            <StyledCheckbox
              iconBgColor={colors.lightGrey}
              iconbgcolorchecked={colors.black}
              iconBorderColor={colors.black}
              onChange={() => setIsTOSAccepted(!isTOSAccepted)}
              checked={isTOSAccepted}
              data-testid="prePurchaseTocsCheckbox"
            />
          }
          label={
            <Typography mt={0.7}>
              {t("general.byCreatingAgreeTo")}{" "}
              <Typography
                component={Link}
                href="https://www.doola.com/terms-of-service"
                target="_blank"
                underline="always"
                rel="noreferrer"
              >
                {t("general.tos")}
              </Typography>{" "}
              {t("general.and")}{" "}
              <Typography
                component={Link}
                href="https://www.doola.com/privacy-policy"
                target="_blank"
                underline="always"
                rel="noreferrer"
              >
                {t("general.privacyPolicy")}
              </Typography>
              .
            </Typography>
          }
          sx={{ alignItems: "flex-start", mr: 0, gap: 1 }}
        />
      </Stack>
      <Stack direction="row" alignItems={"center"} gap={2}>
        <InfoOutlined />
        <Typography>{t("signup.review.tooltip")}</Typography>
      </Stack>
      <ButtonBox
        onClickBack={onBackHandler}
        onClickNext={onSubmitHandler}
        disabled={!isTOSAccepted}
        isLoading={isLoading}
        rightButtonText={t("general.confirmAndPay") as string}
        showRightIcon={false}
      />
    </Stack>
  );
};

export default Review;
