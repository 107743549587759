import { NavigateNext } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { CustomCard } from "components/Cards";
import { useTranslation } from "react-i18next";
import { colors } from "utils";
import FeedbackSurvey from "./FeedbackSurvey";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import { usePostHog } from "posthog-js/react";
import Button from "components/Button";

interface IFeedbackProps {
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

const Feedback = ({ setVisibility }: IFeedbackProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const posthog = usePostHog();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const onShowModalHandler = () => {
    setIsModalOpen(true);
  };
  const onCloseModalHandler = () => {
    navigate(ROUTER_PATHS.DASHBOARD);
  };

  useEffect(() => {
    if (
      location.pathname ===
      `${ROUTER_PATHS.DASHBOARD}/${ROUTER_PATHS.MODAL_FEEDBACK}`
    ) {
      onShowModalHandler();
    } else if (isModalOpen) {
      setIsModalOpen(false);
    }
  }, [isModalOpen, location.pathname]);

  return (
    <>
      <CustomCard
        $styles={{
          background: colors.lightAccentYellow,
        }}
        $radius="strong"
        $padding={4}
      >
        <Typography variant="h3">{t("dashboard.feedback.title")} ✏️</Typography>
        <Typography color={colors.black} my={3}>
          {t("dashboard.feedback.description")}
        </Typography>
        <Stack alignItems={"flex-end"}>
          <Button
            endIcon={<NavigateNext />}
            buttonText={t("dashboard.feedback.button")}
            onClick={() => {
              posthog?.capture("dashboard_user_survey_click");
              navigate(
                `${ROUTER_PATHS.DASHBOARD}/${ROUTER_PATHS.MODAL_FEEDBACK}`
              );
            }}
          />
        </Stack>
      </CustomCard>
      <FeedbackSurvey
        open={isModalOpen}
        onClose={onCloseModalHandler}
        setVisibility={setVisibility}
      />
    </>
  );
};

export default Feedback;
