import {
  Stack,
  Typography,
  useMediaQuery,
  IconButton,
  Grid,
  Skeleton,
} from "@mui/material";
import MoneyHeader from "../Header";
import { useTranslation } from "react-i18next";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import utils, { colors } from "utils";
import { useMemo, useState } from "react";
import TransactionItem from "../components/TransactionRow";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import RecentlyPaid from "../RecentlyPaid";
import NoTransactionsUI from "../components/NoTransactionsUI";
import MoneyChart from "views/DashboardApp/Money/components/MoneyChart";
import Button from "components/Button";
import NavigateNext from "@mui/icons-material/NavigateNext";
import { splitNumberIntoParts } from "./util";
import useGetTransactions from "../hooks/useGetTransactions";
import useGetMoneyAccounts from "../hooks/useGetMoneyAccounts";
import { WalletTransaction } from "graphql/__generated__/types";
import { USD_CURRENCY_KEY, maskSensitiveData } from "../helpers";
import useGetTransactionsAggregate from "./hooks/useGetTransactionsAggregate";
import { moneyFormatter } from "utils/formatters";
import { usePostHog } from "posthog-js/react";

const renderTotalBalance = (totalBalance: number) => {
  const { dollars, cents } = splitNumberIntoParts(totalBalance);

  return (
    <Typography component={"span"} fontSize={32} fontWeight={700}>
      {moneyFormatter(dollars)}.
      <Typography component={"span"} fontSize={20} fontWeight={700}>
        {cents}
      </Typography>
    </Typography>
  );
};

const renderMaskedOrUnMaskedNumber = (value: string, isMasked: boolean) => {
  if (isMasked) {
    return maskSensitiveData(value);
  }

  return value;
};

const numbersIconButtonStyles = {
  width: 36,
  height: 36,
  bgcolor: colors.grey,
  borderRadius: "50%",
  "&:hover": {
    bgcolor: colors.darkGrey,
  },
};

const numbersIconStyles = { fontSize: 16, color: colors.black };

const MoneyOverview = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { accountsData, isAccountsDataLoading } = useGetMoneyAccounts();
  const { transactions, isTransactionsLoading } = useGetTransactions();
  const { transactionsAggregate, isTransactionsAggregateLoading } =
    useGetTransactionsAggregate();

  const isTransactionsAggregateDataLoading =
    isTransactionsAggregateLoading || !transactionsAggregate;

  const [hideAccountNumber, setHideAccountNumber] = useState<boolean>(true);
  const [hideACHRoutingNumber, setHideACHRoutingNumber] =
    useState<boolean>(true);
  const [hideWireRoutingNumber, setHideWireRoutingNumber] =
    useState<boolean>(true);

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const posthog = usePostHog();

  const USDBalance = useMemo(
    () =>
      accountsData?.balances?.find(
        (balance) => balance?.curSymbol === USD_CURRENCY_KEY
      )?.balance || 0,
    [accountsData?.balances]
  );

  const ACHRoutingCode = accountsData?.routingCodeValue1;
  const WireRoutingCode = accountsData?.routingCodeValue2;

  const monthLabels =
    transactionsAggregate && transactionsAggregate?.length > 0
      ? transactionsAggregate
          .map((transaction) => transaction?.dimension || "")
          .reverse()
      : [];

  const moneyInData =
    transactionsAggregate && transactionsAggregate?.length > 0
      ? transactionsAggregate
          .map((transaction) => transaction?.moneyIn || 0)
          .reverse()
      : [];

  const moneyOutData =
    transactionsAggregate && transactionsAggregate?.length > 0
      ? transactionsAggregate
          .map((transaction) => transaction?.moneyOut || 0)
          .reverse()
      : [];

  const totalMoneyIn = moneyInData.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  const totalMoneyOut = moneyOutData.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  return (
    <Stack gap={isTablet ? 3 : 4}>
      <MoneyHeader />
      <Grid container item xs={12} spacing={isTablet ? 3 : 4}>
        <Grid item xs={12} md={4}>
          <Stack gap={isTablet ? 3 : 4}>
            {isAccountsDataLoading ? (
              <Skeleton
                variant="rectangular"
                animation="wave"
                height={140}
                sx={{
                  borderRadius: isTablet ? 6 : 8,
                  bgcolor: colors.lightAccentGreen,
                }}
              />
            ) : (
              <Stack
                bgcolor={colors.lightAccentGreen}
                p={isTablet ? 3 : 4}
                borderRadius={isTablet ? 6 : 8}
              >
                <Typography variant="body1" fontSize={16} fontWeight={500}>
                  {t("money.totalBalance")}
                </Typography>
                {renderTotalBalance(USDBalance)}
              </Stack>
            )}
            {isAccountsDataLoading ? (
              <Skeleton
                variant="rectangular"
                animation="wave"
                height={240}
                sx={{
                  borderRadius: isTablet ? 6 : 8,
                }}
              />
            ) : (
              <Stack
                bgcolor={colors.lightGrey}
                p={isTablet ? 3 : 4}
                borderRadius={isTablet ? 6 : 8}
                justifyContent={"space-between"}
                gap={2}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Stack>
                    <Typography variant="body1">
                      {t("money.accountNumber")}
                    </Typography>
                    <Typography variant="body1" fontSize={16} fontWeight={500}>
                      {renderMaskedOrUnMaskedNumber(
                        accountsData?.uniquePaymentId ?? "",
                        hideAccountNumber
                      )}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} gap={1}>
                    <IconButton
                      onClick={() => setHideAccountNumber(!hideAccountNumber)}
                      sx={numbersIconButtonStyles}
                    >
                      {!hideAccountNumber ? (
                        <VisibilityOffIcon style={numbersIconStyles} />
                      ) : (
                        <VisibilityIcon style={numbersIconStyles} />
                      )}
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        navigator.clipboard.writeText(
                          accountsData?.uniquePaymentId ?? ""
                        )
                      }
                      sx={numbersIconButtonStyles}
                    >
                      <ContentCopyIcon style={numbersIconStyles} />
                    </IconButton>
                  </Stack>
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Stack>
                    <Typography variant="body1">
                      {t("money.routingNumberAch")}
                    </Typography>
                    <Typography variant="body1" fontSize={16} fontWeight={500}>
                      {renderMaskedOrUnMaskedNumber(
                        ACHRoutingCode ?? "",
                        hideACHRoutingNumber
                      )}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} gap={1}>
                    <IconButton
                      onClick={() =>
                        setHideACHRoutingNumber(!hideACHRoutingNumber)
                      }
                      sx={numbersIconButtonStyles}
                    >
                      {!hideACHRoutingNumber ? (
                        <VisibilityOffIcon style={numbersIconStyles} />
                      ) : (
                        <VisibilityIcon style={numbersIconStyles} />
                      )}
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        navigator.clipboard.writeText(ACHRoutingCode ?? "")
                      }
                      sx={numbersIconButtonStyles}
                    >
                      <ContentCopyIcon style={numbersIconStyles} />
                    </IconButton>
                  </Stack>
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Stack>
                    <Typography variant="body1">
                      {t("money.routingNumberWire")}
                    </Typography>
                    <Typography variant="body1" fontSize={16} fontWeight={500}>
                      {renderMaskedOrUnMaskedNumber(
                        WireRoutingCode ?? "",
                        hideWireRoutingNumber
                      )}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} gap={1}>
                    <IconButton
                      onClick={() =>
                        setHideWireRoutingNumber(!hideWireRoutingNumber)
                      }
                      sx={numbersIconButtonStyles}
                    >
                      {!hideWireRoutingNumber ? (
                        <VisibilityOffIcon style={numbersIconStyles} />
                      ) : (
                        <VisibilityIcon style={numbersIconStyles} />
                      )}
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        navigator.clipboard.writeText(WireRoutingCode ?? "")
                      }
                      sx={numbersIconButtonStyles}
                    >
                      <ContentCopyIcon style={numbersIconStyles} />
                    </IconButton>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} md={8}>
          {isTransactionsAggregateDataLoading ? (
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={"100%"}
              sx={{ borderRadius: isTablet ? 6 : 8 }}
            />
          ) : (
            <Stack
              height={"100%"}
              justifyContent={"space-between"}
              bgcolor={colors.lightGrey}
              p={isTablet ? 3 : 4}
              borderRadius={isTablet ? 6 : 8}
              gap={2}
            >
              <MoneyChart
                transactionsAggregate={transactionsAggregate}
                monthLabels={monthLabels}
                moneyInData={moneyInData}
                moneyOutData={moneyOutData}
                totalMoneyIn={totalMoneyIn}
                totalMoneyOut={totalMoneyOut}
              />
            </Stack>
          )}
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={isTablet ? 3 : 4}>
        <Grid item xs={12} md={8}>
          {isTransactionsLoading ? (
            <Skeleton
              variant="rectangular"
              animation="wave"
              height={"500px"}
              sx={{ borderRadius: isTablet ? 6 : 8 }}
            />
          ) : (
            <Stack
              bgcolor={colors.lightGrey}
              p={isTablet ? 3 : 4}
              borderRadius={isTablet ? 6 : 8}
              gap={isTablet ? 3 : 4}
            >
              <Typography
                variant="h2"
                fontSize={isTablet ? 20 : 24}
                fontWeight={700}
              >
                {t("money.transactions")}
              </Typography>
              {transactions?.length ? (
                transactions.map(
                  (transaction: WalletTransaction | null) =>
                    transaction && (
                      <TransactionItem
                        key={transaction.id}
                        transaction={transaction}
                      />
                    )
                )
              ) : (
                <NoTransactionsUI />
              )}
              <Button
                endIcon={<NavigateNext />}
                buttonText={t("money.overview.seeTransactions")}
                onClick={() => {
                  posthog?.capture(
                    "money_overview_page_see_transactions_click"
                  );
                  navigate(ROUTER_PATHS.MONEY_TRANSACTIONS);
                }}
                styles={{ alignSelf: "flex-end" }}
              />
            </Stack>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <RecentlyPaid />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default MoneyOverview;
