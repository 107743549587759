import {
  OnboardingBusinessType,
  OnboardingCompanyInfoInput,
} from "graphql/__generated__/types";
import { US_KEY } from "views/DashboardApp/Money/helpers";

export const INITIAL_INFORMATION_VALUES: OnboardingCompanyInfoInput = {
  businessName: "",
  businessRegistrationNumber: "",
  businessType: OnboardingBusinessType.LIMITED_LIABILITY_COMPANY,
  legalRegisteredDate: "",
  registeredCountry: US_KEY,
  description: "",
  website: "",
};
