import useCompany from "graphql/hooks/UseCompany";
import { CompanyServices, ServiceVariant } from "graphql/__generated__/types";
import HurdlrAuthorisedContainer from "./HurdlrAuthorisedContainer";
import UpsellContainer from "./UpsellContainer";
import useConfig from "hooks/useConfig";
import { useEffect } from "react";
import { ThemeMode } from "models/ThemeConfig";

export enum BooksTypesEnum {
  DASHBOARD = "tiles",
  INVOICE = "invoiceDash",
  EXPENSE = "expenseDash",
  INCOME = "incomeDash",
}

export type RenderProps = {
  elementToRender: BooksTypesEnum;
};

const Books = ({ elementToRender }: RenderProps) => {
  const { currentCompany } = useCompany();
  const { onChangeMode } = useConfig();

  useEffect(() => {
    onChangeMode("dark" as ThemeMode);

    return () => {
      onChangeMode("light" as ThemeMode);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const recordKeepingService = currentCompany?.services?.find(
    (s) => s.service === CompanyServices.RECORDKEEPING
  );
  const hasInvoiceService =
    recordKeepingService?.variant === ServiceVariant.INVOICEONLY;

  const hasBooksService =
    recordKeepingService?.variant === ServiceVariant.COMPLETE;

  const hasAccessToInvoices =
    hasInvoiceService &&
    (elementToRender === BooksTypesEnum.INVOICE ||
      elementToRender === BooksTypesEnum.DASHBOARD);

  // If a user has access to books, they can access all routes
  // If a user has access to invoices, they can access the invoice and dashboard route only
  if (hasBooksService || hasAccessToInvoices) {
    return <HurdlrAuthorisedContainer elementToRender={elementToRender} />;
  }

  const invoiceUpsell = currentCompany?.upsells?.find((upsell) =>
    upsell.name.includes("Bookkeeping - Invoicing")
  );
  const hasInvoiceUpsell = !!invoiceUpsell;

  return <UpsellContainer showInvoiceUpsell={hasInvoiceUpsell} />;
};

export default Books;
