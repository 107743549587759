export const SENTRY_DNS = {
  local: "",
  development:
    "https://74d3de66bcb44c949a076baf625144ab@o1075040.ingest.sentry.io/6141739",
  sandbox:
    "https://74d3de66bcb44c949a076baf625144ab@o1075040.ingest.sentry.io/6141739",
  staging:
    "https://361e89e751b24801aaef8a9fca4f7cd8@o1075040.ingest.sentry.io/6141743",
  production:
    "https://6d12cb787adb40c79bd20a321ed7e581@o1075040.ingest.sentry.io/6141749",
};
