import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCustomerFirstNameMutationVariables = Types.Exact<{
  customerId: Types.Scalars['ID'];
  newFirstName: Types.Scalars['String'];
}>;


export type UpdateCustomerFirstNameMutation = { __typename?: 'Mutation', customer?: { __typename?: 'CustomerMutation', updateFirstName?: { __typename?: 'Customer', id?: string | null, firstName?: string | null } | null } | null };


export const UpdateCustomerFirstNameDocument = gql`
    mutation UpdateCustomerFirstName($customerId: ID!, $newFirstName: String!) {
  customer(id: $customerId) {
    updateFirstName(new: $newFirstName) {
      id
      firstName
    }
  }
}
    `;
export type UpdateCustomerFirstNameMutationFn = Apollo.MutationFunction<UpdateCustomerFirstNameMutation, UpdateCustomerFirstNameMutationVariables>;

/**
 * __useUpdateCustomerFirstNameMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerFirstNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerFirstNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerFirstNameMutation, { data, loading, error }] = useUpdateCustomerFirstNameMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      newFirstName: // value for 'newFirstName'
 *   },
 * });
 */
export function useUpdateCustomerFirstNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerFirstNameMutation, UpdateCustomerFirstNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerFirstNameMutation, UpdateCustomerFirstNameMutationVariables>(UpdateCustomerFirstNameDocument, options);
      }
export type UpdateCustomerFirstNameMutationHookResult = ReturnType<typeof useUpdateCustomerFirstNameMutation>;
export type UpdateCustomerFirstNameMutationResult = Apollo.MutationResult<UpdateCustomerFirstNameMutation>;
export type UpdateCustomerFirstNameMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerFirstNameMutation, UpdateCustomerFirstNameMutationVariables>;