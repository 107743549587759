import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import HorizontalRuleRoundedIcon from "@mui/icons-material/HorizontalRuleRounded";
import { EntityType } from "graphql/__generated__/types";

export const EntityTypesData = {
  [EntityType.LLC]: {
    type: "signup.entityType.llc.title",
    listItems: [
      {
        content: "signup.entityTypeInfo.llc.benefits.1",
        renderIcon: () => <DoneRoundedIcon />,
      },
      {
        content: "signup.entityTypeInfo.llc.benefits.2",
        renderIcon: () => <DoneRoundedIcon />,
      },
      {
        content: "signup.entityTypeInfo.llc.benefits.3",
        renderIcon: () => <DoneRoundedIcon />,
      },
      {
        content: "signup.entityTypeInfo.llc.benefits.4",
        renderIcon: () => <HorizontalRuleRoundedIcon />,
      },
    ],
  },
  [EntityType.CCORP]: {
    type: "signup.entityType.ccorp.title",
    listItems: [
      {
        content: "signup.entityTypeInfo.ccorp.benefits.1",
        renderIcon: () => <DoneRoundedIcon />,
      },
      {
        content: "signup.entityTypeInfo.ccorp.benefits.2",
        renderIcon: () => <DoneRoundedIcon />,
      },
      {
        content: "signup.entityTypeInfo.ccorp.benefits.3",
        renderIcon: () => <HorizontalRuleRoundedIcon />,
      },
      {
        content: "signup.entityTypeInfo.ccorp.benefits.4",
        renderIcon: () => <HorizontalRuleRoundedIcon />,
      },
    ],
  },
  [EntityType.DAO]: {
    type: "signup.entityType.dao.title",
    listItems: [
      {
        content: "signup.entityTypeInfo.dao.benefits.1",
        renderIcon: () => <DoneRoundedIcon />,
      },
      {
        content: "signup.entityTypeInfo.dao.benefits.2",
        renderIcon: () => <DoneRoundedIcon />,
      },
      {
        content: "signup.entityTypeInfo.dao.benefits.3",
        renderIcon: () => <DoneRoundedIcon />,
      },
      {
        content: "signup.entityTypeInfo.dao.benefits.4",
        renderIcon: () => <HorizontalRuleRoundedIcon />,
      },
    ],
  },
};
