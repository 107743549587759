export const staticQuestionData: { [key: number]: { helpText: string } } = {
  1: {
    helpText: "Describe what you like about doola",
  },
  2: {
    helpText: "Tell us what to improve",
  },
  3: {
    helpText: "Tell us what you need",
  },
  4: {
    helpText: "Explain your choice (Optional)",
  },
};
