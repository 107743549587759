import { P } from "components/Fonts";
import InfoModal from "components/InfoModal";
import { useEffect, useRef, useState } from "react";
import { colors } from "utils";
import { StyledWrapperToolTip, ReadMoreWrapperStyled } from "./styles";

type Props = {
  refresh: boolean;
  description: JSX.Element | string;
  title: JSX.Element | string;
  shortVersion?: boolean;
};

export const ToolTipDescription: React.FC<Props> = ({
  description,
  refresh,
  title,
  shortVersion,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const WrapTooltipRef: any = useRef(null);

  const [showReadMore, setShowReadMore] = useState(false);

  const calculateHeightWrapTooltip = () => {
    if (WrapTooltipRef.current) {
      const currentHeight = WrapTooltipRef.current.offsetHeight;
      const maxHeight = shortVersion ? 48 : 150; //It comes from the amount of lines defined by css: prop -webkit-line-clamp.
      setShowReadMore(currentHeight >= maxHeight);
    }
  };

  useEffect(() => {
    calculateHeightWrapTooltip();
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <StyledWrapperToolTip ref={WrapTooltipRef} shortVersion={shortVersion}>
        <P size="small">{description}</P>
        {openModal && (
          <InfoModal
            title={title}
            description={description}
            handleClose={() => setOpenModal(false)}
          />
        )}
      </StyledWrapperToolTip>
      {showReadMore && (
        <ReadMoreWrapperStyled
          onClick={(event) => {
            event.stopPropagation();
            setOpenModal(true);
            event.preventDefault();
          }}
        >
          <P
            size="small"
            color={colors.green}
            $emphasis
            $margin="0px 0px 10px 0px"
          >
            Read more →
          </P>
        </ReadMoreWrapperStyled>
      )}
    </>
  );
};
