import { Close, CloseRounded, NavigateNext } from "@mui/icons-material";
import {
  Dialog,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { CustomCard } from "components/Cards";
import { Logo } from "components/Logos";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSwiper } from "swiper/react";
import utils from "utils";
import { IAffiliateCardProps } from "../AffiliateCard";
import { usePostHog } from "posthog-js/react";
import Button from "components/Button";

export interface IAffiliatePopCardProps extends IAffiliateCardProps {
  open: boolean;
  onClose: () => void;
}

export const AffiliatePopCard: React.FC<IAffiliatePopCardProps> = ({
  affiliate,
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const swiper = useSwiper();
  const posthog = usePostHog();
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const onCloseClickHandler = () => {
    onClose();
    swiper.autoplay.resume();
  };

  return (
    <Dialog
      fullScreen={isTablet}
      PaperProps={{
        style: {
          borderRadius: !isTablet ? "32px" : "0",
          width: isTablet ? "100%" : "800px",
        },
      }}
      open={open}
      onClose={onCloseClickHandler}
    >
      <CustomCard
        $styles={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
        }}
        $shadow="none"
        $padding={4}
      >
        {isTablet && (
          <Stack direction="row" justifyContent="flex-end">
            <IconButton onClick={onCloseClickHandler}>
              <CloseRounded color="primary" />
            </IconButton>
          </Stack>
        )}
        <Stack
          direction={isTablet ? "column" : "row"}
          gap={4}
          justifyContent={"space-between"}
        >
          <Stack
            sx={{ background: affiliate.color }}
            minWidth={"260px"}
            height={"260px"}
            gap={0.5}
            borderRadius={4}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <img
              src={affiliate.cover}
              style={{ height: "12rem" }}
              alt="cover"
            />
          </Stack>
          <Stack justifyContent={"flex-start"}>
            {!isTablet && (
              <Stack direction="row" justifyContent="flex-end">
                <IconButton onClick={onCloseClickHandler}>
                  <CloseRounded color="primary" />
                </IconButton>
              </Stack>
            )}
            <Stack gap={3}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                gap={1}
              >
                <Logo width={85} />
                <Close />
                <img
                  src={affiliate.logo}
                  alt={t(`dashboard.affiliates.${affiliate.name}.title`) ?? ""}
                />
              </Stack>
              <Typography variant="h3" fontWeight={700}>
                {t(`dashboard.affiliates.${affiliate.name}.title`)}
              </Typography>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                gap={1}
              >
                <Button
                  endIcon={<NavigateNext />}
                  buttonText={affiliate.buttonText}
                  onClick={() => {
                    posthog?.capture("affiliate-link-click", {
                      referral_name: affiliate.name,
                    });

                    window.open(affiliate.link);
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        {affiliate.text.map((text, index) => (
          <React.Fragment key={index}>
            {index !== 0 && (
              <>
                {" "}
                <br />
                <br />
              </>
            )}
            {t(`dashboard.affiliates.${affiliate.name}.${text.source}`)}
            {text.type === "list" &&
              t(`dashboard.affiliates.${affiliate.name}.${text.list}`)
                .split(",")
                .map((service, index) => (
                  <React.Fragment key={index}>
                    <br />- {service}
                  </React.Fragment>
                ))}
          </React.Fragment>
        ))}
      </CustomCard>
    </Dialog>
  );
};
