import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCustomerCountryOfResidenceMutationVariables = Types.Exact<{
  customerId: Types.Scalars['ID'];
  countryOfResidence: Types.Iso31661Alpha3;
}>;


export type UpdateCustomerCountryOfResidenceMutation = { __typename?: 'Mutation', customer?: { __typename?: 'CustomerMutation', updateCountryOfResidence?: { __typename?: 'Customer', id?: string | null, countryOfResidence?: Types.Iso31661Alpha3 | null } | null } | null };


export const UpdateCustomerCountryOfResidenceDocument = gql`
    mutation UpdateCustomerCountryOfResidence($customerId: ID!, $countryOfResidence: ISO31661Alpha3!) {
  customer(id: $customerId) {
    updateCountryOfResidence(new: $countryOfResidence) {
      id
      countryOfResidence
    }
  }
}
    `;
export type UpdateCustomerCountryOfResidenceMutationFn = Apollo.MutationFunction<UpdateCustomerCountryOfResidenceMutation, UpdateCustomerCountryOfResidenceMutationVariables>;

/**
 * __useUpdateCustomerCountryOfResidenceMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerCountryOfResidenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerCountryOfResidenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerCountryOfResidenceMutation, { data, loading, error }] = useUpdateCustomerCountryOfResidenceMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      countryOfResidence: // value for 'countryOfResidence'
 *   },
 * });
 */
export function useUpdateCustomerCountryOfResidenceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerCountryOfResidenceMutation, UpdateCustomerCountryOfResidenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerCountryOfResidenceMutation, UpdateCustomerCountryOfResidenceMutationVariables>(UpdateCustomerCountryOfResidenceDocument, options);
      }
export type UpdateCustomerCountryOfResidenceMutationHookResult = ReturnType<typeof useUpdateCustomerCountryOfResidenceMutation>;
export type UpdateCustomerCountryOfResidenceMutationResult = Apollo.MutationResult<UpdateCustomerCountryOfResidenceMutation>;
export type UpdateCustomerCountryOfResidenceMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerCountryOfResidenceMutation, UpdateCustomerCountryOfResidenceMutationVariables>;