import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCustomerStripeIdMutationVariables = Types.Exact<{
  customerId: Types.Scalars['ID'];
  stripeCustomerId: Types.Scalars['String'];
}>;


export type UpdateCustomerStripeIdMutation = { __typename?: 'Mutation', customer?: { __typename?: 'CustomerMutation', addStripeCustomerId?: { __typename?: 'Customer', id?: string | null, name?: string | null } | null } | null };


export const UpdateCustomerStripeIdDocument = gql`
    mutation UpdateCustomerStripeId($customerId: ID!, $stripeCustomerId: String!) {
  customer(id: $customerId) {
    addStripeCustomerId(new: $stripeCustomerId) {
      id
      name
    }
  }
}
    `;
export type UpdateCustomerStripeIdMutationFn = Apollo.MutationFunction<UpdateCustomerStripeIdMutation, UpdateCustomerStripeIdMutationVariables>;

/**
 * __useUpdateCustomerStripeIdMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerStripeIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerStripeIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerStripeIdMutation, { data, loading, error }] = useUpdateCustomerStripeIdMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      stripeCustomerId: // value for 'stripeCustomerId'
 *   },
 * });
 */
export function useUpdateCustomerStripeIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerStripeIdMutation, UpdateCustomerStripeIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerStripeIdMutation, UpdateCustomerStripeIdMutationVariables>(UpdateCustomerStripeIdDocument, options);
      }
export type UpdateCustomerStripeIdMutationHookResult = ReturnType<typeof useUpdateCustomerStripeIdMutation>;
export type UpdateCustomerStripeIdMutationResult = Apollo.MutationResult<UpdateCustomerStripeIdMutation>;
export type UpdateCustomerStripeIdMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerStripeIdMutation, UpdateCustomerStripeIdMutationVariables>;