import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FileWithRaMutationVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
}>;


export type FileWithRaMutation = { __typename?: 'Mutation', company?: { __typename?: 'CompanyMutation', fileWithRa?: { __typename?: 'Company', id: string, services?: Array<{ __typename?: 'Services', service: Types.CompanyServices, variant?: Types.ServiceVariant | null, status: Types.ServiceCategory, subStatus?: string | null }> | null } | null } | null };


export const FileWithRaDocument = gql`
    mutation FileWithRa($companyId: ID!) {
  company(id: $companyId) {
    fileWithRa {
      id
      services {
        service
        variant
        status
        subStatus
      }
    }
  }
}
    `;
export type FileWithRaMutationFn = Apollo.MutationFunction<FileWithRaMutation, FileWithRaMutationVariables>;

/**
 * __useFileWithRaMutation__
 *
 * To run a mutation, you first call `useFileWithRaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileWithRaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileWithRaMutation, { data, loading, error }] = useFileWithRaMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useFileWithRaMutation(baseOptions?: Apollo.MutationHookOptions<FileWithRaMutation, FileWithRaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FileWithRaMutation, FileWithRaMutationVariables>(FileWithRaDocument, options);
      }
export type FileWithRaMutationHookResult = ReturnType<typeof useFileWithRaMutation>;
export type FileWithRaMutationResult = Apollo.MutationResult<FileWithRaMutation>;
export type FileWithRaMutationOptions = Apollo.BaseMutationOptions<FileWithRaMutation, FileWithRaMutationVariables>;