import { IAddOnPackage } from "state/formationFormSlice";
import { IPackage } from "../Packages";
import { TOTAL_COMPLIANCE } from "../Packages/data";

export const generatePartnerStackProductKeys = (
  formationPackage?: IPackage,
  expeditedAddon?: IAddOnPackage,
  boiFilingAddon?: IAddOnPackage
): string => {
  let productKeys = "";

  if (formationPackage) {
    productKeys =
      formationPackage?.displayName === TOTAL_COMPLIANCE.name
        ? `${formationPackage?.displayName} ${formationPackage.duration}`
        : formationPackage.displayName;
  }

  if (expeditedAddon) {
    productKeys += `+${expeditedAddon.item_name}`;
  }

  if (boiFilingAddon) {
    productKeys += `+${boiFilingAddon.item_name}`;
  }

  return productKeys;
};
