import React, { RefCallback } from "react";
import { IMaskInput } from "react-imask";

interface Props {
  onChange: (event: { target: { name: string; value: string } }) => void;
  type: string;
  name: string;
}

const EINMask = React.forwardRef<HTMLElement, Props>(function EINMask(
  props,
  ref
) {
  const { onChange, type, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask="XX-XXXX000"
      definitions={{
        X: {
          mask: "0",
          ...(type === "password" && { displayChar: "•" }),
          placeholderChar: "#",
        },
      }}
      lazy={type === "password" ? true : false}
      inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
      onAccept={(value: string, mask) => {
        onChange({
          target: {
            name: props.name,
            value: mask.unmaskedValue ? value : mask.unmaskedValue,
          },
        });
      }}
      overwrite={"shift"}
    />
  );
});

export default EINMask;
