import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ButtonBox, { RightButtonIconTypes } from "components/ButtonBox";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import AddIcon from "@mui/icons-material/Add";
import { ROUTER_PATHS } from "routes/routes";
import { useGetCountriesQuery } from "graphql/__generated__/operations/Countries.generated";
import { useNavigate } from "react-router-dom";
import utils, { colors } from "utils";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import FormControlElement from "../FormControlElement";
import { Controller, useForm } from "react-hook-form";
import { LoadingComponent } from "components/Loading";
import { Iso31661Alpha3 } from "graphql/__generated__/types";
import { isValidPhoneNumber } from "react-phone-number-input";
import ISOCountries from "i18n-iso-countries";
import { SignupSelect } from "views/SignupFlow/styles";
import { useUpdateCompanyMemberMutation } from "graphql/__generated__/operations/UpdateCompanyAddMember.generated";
import { useRemoveCompanyMemberMutation } from "graphql/__generated__/operations/RemoveCompanyMember.generated";
import { useRemoveAllCompanyMembersMutation } from "graphql/__generated__/operations/RemoveAllCompanyMembers.generated";
import useCompany from "graphql/hooks/UseCompany";
import MemberCard from "./MemberCard";
import { INITIAL_MEMBER_VALUES } from "../helpers/initialMembersData";
import ErrorBox from "components/ErrorBox";
import { SSNRegex, Windows1252Regex } from "utils/regexValidations";
import { t } from "i18next";
import SSNMask from "components/SSNMask";
import { usePostHog } from "posthog-js/react";
import { CustomPhoneInput } from "components/Form/CustomInput/CustomPhoneStyles";

const OnboardingMembers = () => {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);
  const [updateCompanyMember] = useUpdateCompanyMemberMutation();
  const [removeCompanyMember] = useRemoveCompanyMemberMutation();
  const [removeAllCompanyMembers] = useRemoveAllCompanyMembersMutation();

  const { currentCompany } = useCompany();

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editMemberPosition, setEditMemberPosition] = useState<null | number>(
    null
  );
  const [showSSN, setShowSSN] = useState(false);
  const [showConfirmSSN, setShowConfirmSSN] = useState(false);

  const { data, loading } = useGetCountriesQuery();
  const countryList = data?.countries;

  const { control, formState, getValues, watch, setValue, reset } = useForm<
    typeof INITIAL_MEMBER_VALUES
  >({
    defaultValues: { ...INITIAL_MEMBER_VALUES },
    mode: "onChange",
  });

  useEffect(() => {
    const shouldSetDefaultValues =
      currentCompany?.members?.length && editMemberPosition !== null;

    if (shouldSetDefaultValues) {
      const editableMember = currentCompany.members!.find(
        (member) => member.position === editMemberPosition
      );

      setValue("legalFirstName", editableMember?.legalFirstName || "");
      setValue("legalLastName", editableMember?.legalLastName || "");
      setValue("address.city", editableMember?.address?.city || "");

      const country = editableMember?.address?.country;
      if (country) {
        setValue("address.country", country);
      }

      const state = editableMember?.address?.state;
      if (state) {
        setValue("address.state", state);
      }

      const isNaturalPerson = editableMember?.isNaturalPerson;
      if (!isNaturalPerson) {
        setValue("isNaturalPerson", false);
        setValue("contactFullName", editableMember?.contactFullName || "");
      }

      setValue("socialSecurityNumber", editableMember?.ssn || "");
      setValue("address.line1", editableMember?.address?.line1 || "");
      setValue("address.line2", editableMember?.address?.line2 || "");
      setValue("address.phone", editableMember?.address?.phone || "");
      setValue("address.postalCode", editableMember?.address?.postalCode || "");
    }
  }, [currentCompany?.members, editMemberPosition, setValue]);

  useEffect(() => {
    if (!showModal) {
      reset();
      setEditMemberPosition(null);
    }
  }, [showModal, reset]);

  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  const isNaturalPerson = watch("isNaturalPerson");
  const countryCode = watch("address.country");
  const address = watch("address");
  const contactFullName = watch("contactFullName");
  const legalFirstName = watch("legalFirstName");
  const legalLastName = watch("legalLastName");
  const socialSecurityNumber = watch("socialSecurityNumber");
  const confirmSocialSecurityNumber = watch("confirmSocialSecurityNumber");

  const countrySubdvisions =
    countryList?.find((country) => country?.code === countryCode)
      ?.subdivisions || [];

  const hasStates = !!countrySubdvisions.length;

  const isUSCustomer = !!(address.country === "USA");

  const companyId = currentCompany?.id || "";

  if (loading && !countryList) {
    return <LoadingComponent />;
  }

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "Individual") {
      setValue("isNaturalPerson", true);
    } else {
      setValue("isNaturalPerson", false);
    }
  };

  const members = currentCompany?.members;

  const toggleModal = () => {
    setShowModal(!showModal);
    setEditMemberPosition(null);
    reset();
  };

  const onAddMember = async (
    data: typeof INITIAL_MEMBER_VALUES,
    position: number | null
  ) => {
    const { socialSecurityNumber, confirmSocialSecurityNumber, ...member } =
      data;

    const availablePosition = currentCompany?.members?.length
      ? currentCompany.members[currentCompany.members.length - 1].position! + 1
      : 0;

    try {
      setIsLoading(true);

      if (position !== null) {
        await removeCompanyMember({
          variables: { companyId, position },
        });
      }

      await updateCompanyMember({
        variables: {
          companyId,
          member: {
            ...member,
            ownershipPercent: 0,
            position: position || availablePosition,
            ...(socialSecurityNumber && { ssn: socialSecurityNumber }),
          },
        },
      });

      toggleModal();
    } catch (error) {
      console.error(error);
    } finally {
      if (position !== null) {
        posthog?.capture("edit-member");
      } else {
        posthog?.capture("add-member");
      }

      posthog?.capture("company-member-inputted", {
        member_type: data.isNaturalPerson ? "Individual" : "Company",
        country: data.address.country,
        ssn: !!data.socialSecurityNumber,
      });

      setIsLoading(false);
    }
  };

  const { line2, ...requiredAddressFields } = address;
  const isAddressValid = Object.values(requiredAddressFields).every(Boolean);
  const areNamesValid = !!(legalFirstName && legalLastName);
  const isCompanyNameValid = !!contactFullName;
  const isSSNValid =
    socialSecurityNumber || confirmSocialSecurityNumber
      ? socialSecurityNumber === confirmSocialSecurityNumber
      : true;

  const isDisabled = () => {
    if (isNaturalPerson) {
      if (isUSCustomer) {
        return !(
          isAddressValid &&
          areNamesValid &&
          isSSNValid &&
          formState.isValid
        );
      }
      return !(isAddressValid && areNamesValid && formState.isValid);
    } else {
      if (isUSCustomer) {
        return !(
          isAddressValid &&
          isCompanyNameValid &&
          isSSNValid &&
          formState.isValid
        );
      } else {
        return !(isAddressValid && isCompanyNameValid && formState.isValid);
      }
    }
  };

  return (
    <>
      <Stack direction="column" gap={3} mb={10}>
        <Typography variant="h1">Add members to your company</Typography>
        <Stack bgcolor={colors.lightGrey} borderRadius="24px" p={3} gap={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5">Members</Typography>
            <Button
              disableElevation
              variant="contained"
              onClick={toggleModal}
              endIcon={<AddIcon fontSize="small" />}
              sx={{
                height: 36,
                borderRadius: "30px",
                bgcolor: colors.grey,
                color: colors.black,
                boxShadow: "none",
                "&:hover": {
                  bgcolor: colors.darkGrey,
                  boxShadow: "none",
                },
              }}
              data-testid="OnboardingMembersAddButton"
            >
              Add
            </Button>
          </Stack>
          <Stack gap={3}>
            {members?.map((member) => (
              <MemberCard
                key={member.position}
                member={member}
                onClickRemove={() => {
                  if (members.length > 1) {
                    removeCompanyMember({
                      variables: { companyId, position: member.position! },
                    });
                  } else {
                    removeAllCompanyMembers({
                      variables: { companyId },
                    });
                  }

                  posthog?.capture("remove-member");
                }}
                onClickEdit={() => {
                  setShowModal(true);
                  setEditMemberPosition(member.position as number);
                }}
              />
            ))}
          </Stack>
        </Stack>
        <Stack direction="row" alignItems={"center"} gap={2}>
          <InfoIcon />
          <Typography>
            For now, let’s focus on the people and companies that make up
            ownership of your company. We’ll set ownership percentage and more
            in a later step.
          </Typography>
        </Stack>
        <ButtonBox
          onClickBack={() => navigate(ROUTER_PATHS.ONBOARDING_DESCRIPTION)}
          onClickNext={() => {
            navigate(ROUTER_PATHS.ONBOARDING_RESPONSIBLE_PARTY);
            posthog?.capture("company-members-added", {
              count: members?.length,
            });
          }}
          disabled={!currentCompany || !members || members?.length === 0}
        />
      </Stack>
      <Modal
        open={showModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        onClose={toggleModal}
        data-testid="OnboardingMembersModal"
      >
        <Stack
          direction="column"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isTablet ? "100%" : 676,
            height: isTablet ? "100%" : "auto",
            maxHeight: isTablet ? "100%" : "90%",
            boxShadow: 24,
            borderRadius: isTablet ? 0 : 4,
            p: 4,
            bgcolor: colors.white,
            overflow: "scroll",
          }}
          gap={3}
        >
          <Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography mb={0} variant="h3">
                {editMemberPosition === null ? "Add Member" : "Edit Member"}
              </Typography>
              <IconButton onClick={toggleModal}>
                <CloseIcon color="primary" />
              </IconButton>
            </Stack>
            <Typography variant="h6">
              The member address must be a physical address.
            </Typography>
          </Stack>
          <Stack component="form">
            <Typography variant="h5">
              Select Individual or Company for this member
            </Typography>
            <Typography variant="h6">
              An individual is a natural person such as an entrepreneur or
              investor. A company is a legal entity such as a corporation or
              LLC.
            </Typography>
            <Stack direction={isMobile ? "column" : "row"} spacing={2} mt={3}>
              <Stack width={isMobile ? "100%" : "50%"}>
                <FormControlElement
                  title="Individual"
                  optionValue="Individual"
                  selectedValue={isNaturalPerson ? "Individual" : "Company"}
                  changeHandler={onChangeHandler}
                />
              </Stack>
              <Stack width={isMobile ? "100%" : "50%"}>
                <FormControlElement
                  title="Company"
                  optionValue="Company"
                  selectedValue={isNaturalPerson ? "Individual" : "Company"}
                  changeHandler={onChangeHandler}
                />
              </Stack>
            </Stack>
            <Divider sx={{ mt: 4, mb: 3 }} />
            <Stack spacing={2}>
              {isNaturalPerson ? (
                <>
                  <Box>
                    <Typography variant="h5">Member Information</Typography>
                    <Typography variant="h6">
                      Use your full legal name as it appears on your passport,
                      birth certificate, or other legal form of identification.
                      The use of nicknames or shortened names may result in
                      increased processing time and/or rejection.
                    </Typography>
                  </Box>
                  <Stack direction={isMobile ? "column" : "row"} spacing={2}>
                    <Stack width={isMobile ? "100%" : "50%"} spacing={1}>
                      <Typography
                        variant="h6"
                        color={utils.secondaryColors.secondaryMain}
                      >
                        Legal First Name
                      </Typography>
                      <Stack>
                        <Controller
                          name="legalFirstName"
                          {...COMMON_PROPS}
                          rules={{
                            pattern: {
                              value: Windows1252Regex,
                              message: t("error.invalidCharacters"),
                            },
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <TextField
                              {...field}
                              name="legalFirstName"
                              id="legalFirstName"
                              placeholder="Enter legal first name"
                              error={error !== undefined}
                              sx={{
                                ".MuiInputBase-root": {
                                  height: "40px",
                                },
                              }}
                            />
                          )}
                        />
                      </Stack>
                    </Stack>
                    <Stack width={isMobile ? "100%" : "50%"} spacing={1}>
                      <Typography
                        variant="h6"
                        color={utils.secondaryColors.secondaryMain}
                      >
                        Legal Last Name
                      </Typography>
                      <Stack>
                        <Controller
                          name="legalLastName"
                          {...COMMON_PROPS}
                          rules={{
                            pattern: {
                              value: Windows1252Regex,
                              message: t("error.invalidCharacters"),
                            },
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <TextField
                              {...field}
                              name="legalLastName"
                              id="legalLastName"
                              placeholder="Enter legal last name"
                              error={error !== undefined}
                              sx={{
                                ".MuiInputBase-root": {
                                  height: "40px",
                                },
                              }}
                            />
                          )}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack
                    width={isMobile ? "100%" : "calc(50% - 8px)"}
                    spacing={1}
                  >
                    <Typography
                      variant="h6"
                      color={utils.secondaryColors.secondaryMain}
                    >
                      Phone Number
                    </Typography>
                    <Controller
                      name="address.phone"
                      {...COMMON_PROPS}
                      rules={{
                        required: true,
                        validate: (value) =>
                          value ? isValidPhoneNumber(value) : true,
                      }}
                      render={({ field: { onChange, value } }) => (
                        <CustomPhoneInput
                          name="address.phone"
                          id="address.phone"
                          onChange={onChange}
                          value={value || ""}
                          defaultCountry="US"
                          international
                          style={{ height: 40 }}
                        />
                      )}
                    />
                  </Stack>
                </>
              ) : (
                <>
                  <Box>
                    <Typography variant="h5">Company Information</Typography>
                    <Typography variant="h6">
                      Use your complete legal company name including the entity
                      ending. The use of DBAs or brand names may result in
                      increased processing time and/or rejection.
                    </Typography>
                  </Box>
                  <Stack direction={isMobile ? "column" : "row"} spacing={2}>
                    <Stack width={isMobile ? "100%" : "50%"} spacing={1}>
                      <Typography
                        variant="h6"
                        color={utils.secondaryColors.secondaryMain}
                      >
                        Company Name
                      </Typography>
                      <Stack>
                        <Controller
                          name="contactFullName"
                          {...COMMON_PROPS}
                          rules={{
                            pattern: {
                              value: Windows1252Regex,
                              message: t("error.invalidCharacters"),
                            },
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <TextField
                              {...field}
                              name="contactFullName"
                              id="contactFullName"
                              value={contactFullName}
                              placeholder="Enter Company Name"
                              error={error !== undefined}
                              sx={{
                                ".MuiInputBase-root": {
                                  height: "40px",
                                },
                              }}
                            />
                          )}
                        />
                      </Stack>
                    </Stack>
                    <Stack width={isMobile ? "100%" : "50%"} spacing={1}>
                      <Typography
                        variant="h6"
                        color={utils.secondaryColors.secondaryMain}
                      >
                        Phone Number
                      </Typography>
                      <Controller
                        name="address.phone"
                        {...COMMON_PROPS}
                        rules={{
                          required: true,
                          validate: (value) =>
                            value ? isValidPhoneNumber(value) : true,
                        }}
                        render={({ field: { onChange, value } }) => (
                          <CustomPhoneInput
                            name="address.phone"
                            id="address.phone"
                            onChange={onChange}
                            value={value || ""}
                            defaultCountry="US"
                            international
                            style={{ height: 40 }}
                          />
                        )}
                      />
                    </Stack>
                  </Stack>
                </>
              )}
            </Stack>
            <Divider sx={{ mt: 4, mb: 3 }} />
            <Stack spacing={2}>
              <Box>
                <Typography variant="h5">Member Street Address</Typography>
                <Typography variant="h6">
                  The state requires all members use a physical street address,
                  including those outside the United States. The use of PO Boxes
                  or similar may result in increased processing time and/or
                  rejection.
                </Typography>
              </Box>
              <Stack direction={isMobile ? "column" : "row"} spacing={2}>
                <Stack
                  width={isMobile ? "100%" : "calc(50% - 8px)"}
                  spacing={1}
                >
                  <Typography
                    variant="h6"
                    color={utils.secondaryColors.secondaryMain}
                  >
                    Address Line 1
                  </Typography>
                  <Controller
                    name="address.line1"
                    {...COMMON_PROPS}
                    rules={{
                      required: true,
                      pattern: {
                        value: Windows1252Regex,
                        message: t("error.invalidCharacters"),
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        name="address.line1"
                        id="address.line1"
                        placeholder="Enter Address Line 1"
                        error={error !== undefined}
                        sx={{
                          ".MuiInputBase-root": {
                            height: "40px",
                          },
                        }}
                      />
                    )}
                  />
                </Stack>
                <Stack
                  width={isMobile ? "100%" : "calc(50% - 8px)"}
                  spacing={1}
                >
                  <Typography
                    variant="h6"
                    color={utils.secondaryColors.secondaryMain}
                  >
                    Address Line 2 (Optional)
                  </Typography>
                  <Controller
                    name="address.line2"
                    {...COMMON_PROPS}
                    rules={{
                      pattern: {
                        value: Windows1252Regex,
                        message: t("error.invalidCharacters"),
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        name="address.line2"
                        id="address.line2"
                        placeholder="Enter Address Line 2"
                        error={error !== undefined}
                        sx={{
                          ".MuiInputBase-root": {
                            height: "40px",
                          },
                        }}
                      />
                    )}
                  />
                </Stack>
              </Stack>
              <Stack direction={isMobile ? "column" : "row"} spacing={2}>
                <Stack width={isMobile ? "100%" : "calc(50% - 8px)"}>
                  <Typography
                    variant="h6"
                    color={utils.secondaryColors.secondaryMain}
                    mb={1}
                  >
                    Country
                  </Typography>
                  <Controller
                    name="address.country"
                    {...COMMON_PROPS}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <Autocomplete
                        fullWidth
                        options={countryList as any}
                        autoHighlight
                        getOptionLabel={(option) =>
                          option.name ? option.name : ""
                        }
                        value={{
                          name:
                            countryList?.find(
                              (country) => country?.code === address.country
                            )?.name || "",
                          code:
                            countryList?.find(
                              (country) => country?.code === address.country
                            )?.code || "",
                        }}
                        onChange={(_, data) => {
                          // update the state value to be an empty string when the country changes
                          setValue("address.state", "");
                          setValue(
                            "address.country",
                            (data?.code as Iso31661Alpha3) || ""
                          );
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option?.code === value.code
                        }
                        disablePortal
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.code && (
                              <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${ISOCountries.alpha3ToAlpha2(
                                  option?.code
                                )?.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${ISOCountries.alpha3ToAlpha2(
                                  option?.code
                                )?.toLowerCase()}.png 2x`}
                                alt=""
                              />
                            )}
                            {option.name} ({option.code})
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...field}
                            {...params}
                            fullWidth
                            onChange={() => {}}
                            id="address.country"
                            placeholder="Select country"
                            name="address.country"
                            type="text"
                            variant="outlined"
                            sx={{
                              ".MuiInputBase-input": {
                                height: "8px",
                              },
                            }}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </Stack>
                <Stack
                  width={isMobile ? "100%" : "calc(50% - 8px)"}
                  spacing={1}
                >
                  <Typography
                    variant="h6"
                    color={utils.secondaryColors.secondaryMain}
                  >
                    State
                  </Typography>
                  <Controller
                    name="address.state"
                    {...COMMON_PROPS}
                    rules={{
                      required: true,
                      pattern: {
                        value: Windows1252Regex,
                        message: t("error.invalidCharacters"),
                      },
                    }}
                    render={({ field, fieldState: { error } }) =>
                      hasStates ? (
                        <SignupSelect
                          {...field}
                          fullWidth
                          displayEmpty
                          error={error !== undefined}
                          onChange={(_, data: any) => {
                            field.onChange(data?.props?.value || "");
                          }}
                        >
                          <MenuItem disabled value="">
                            Select State
                          </MenuItem>
                          {countrySubdvisions.map((subdivision, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={subdivision?.code || ""}
                              >
                                {subdivision?.name || ""}
                              </MenuItem>
                            );
                          })}
                        </SignupSelect>
                      ) : (
                        <TextField
                          {...field}
                          fullWidth
                          id="address.state"
                          placeholder={"Enter state"}
                          name="address.state"
                          type="text"
                          variant="outlined"
                          error={error !== undefined}
                          sx={{
                            ".MuiInputBase-root": {
                              height: "40px",
                            },
                          }}
                        />
                      )
                    }
                  />
                </Stack>
              </Stack>
              <Stack direction={isMobile ? "column" : "row"} spacing={2}>
                <Stack
                  width={isMobile ? "100%" : "calc(50% - 8px)"}
                  spacing={1}
                >
                  <Typography
                    variant="h6"
                    color={utils.secondaryColors.secondaryMain}
                  >
                    City
                  </Typography>
                  <Controller
                    name="address.city"
                    {...COMMON_PROPS}
                    rules={{
                      required: true,
                      pattern: {
                        value: Windows1252Regex,
                        message: t("error.invalidCharacters"),
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        id="address.city"
                        placeholder="Enter city"
                        name="address.city"
                        type="text"
                        variant="outlined"
                        error={error !== undefined}
                        sx={{
                          ".MuiInputBase-root": {
                            height: "40px",
                          },
                        }}
                      />
                    )}
                  />
                </Stack>
                <Stack
                  width={isMobile ? "100%" : "calc(50% - 8px)"}
                  spacing={1}
                >
                  <Typography
                    variant="h6"
                    color={utils.secondaryColors.secondaryMain}
                  >
                    Zip Code
                  </Typography>
                  <Controller
                    name="address.postalCode"
                    {...COMMON_PROPS}
                    rules={{
                      required: true,
                      pattern: {
                        value: Windows1252Regex,
                        message: t("error.invalidCharacters"),
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        id="address.postalCode"
                        placeholder={"Enter zip/postal code"}
                        name="address.postalCode"
                        type="text"
                        autoComplete="postal-code"
                        error={error !== undefined}
                        sx={{
                          ".MuiInputBase-root": {
                            height: "40px",
                          },
                        }}
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Divider sx={{ mt: 4, mb: 3 }} />
            {isUSCustomer && (
              <Stack>
                <Typography variant="h5">
                  Social Security Number (Optional)
                </Typography>
                <Stack
                  bgcolor={colors.pink}
                  sx={{
                    borderRadius: "24px",
                    justifyContent: "center",
                    padding: 3,
                    mt: 3,
                  }}
                  spacing={2}
                >
                  <Typography variant="h5">🇺🇸 US Resident?</Typography>
                  <Typography>
                    As a US resident, your Employment Identification Number
                    (EIN) application gets prioritized over the backlog of other
                    EIN applications if it is requested with your Social
                    Security Number (SSN) on the application. This can mean
                    receiving your new company’s EIN in a matter of days instead
                    of weeks.
                  </Typography>
                </Stack>
                <Stack spacing={2} mt={2}>
                  <Stack spacing={1}>
                    <Typography
                      variant="h6"
                      color={utils.secondaryColors.secondaryMain}
                    >
                      Social Security Number
                    </Typography>
                    <Stack>
                      <Controller
                        name="socialSecurityNumber"
                        rules={{
                          required: false,
                          pattern: {
                            value: SSNRegex,
                            message: t("error.invalidSSN"),
                          },
                        }}
                        {...COMMON_PROPS}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            name="socialSecurityNumber"
                            id="socialSecurityNumber"
                            placeholder="Enter your Social Security Number"
                            type={showSSN ? "text" : "password"}
                            autoComplete="one-time-code"
                            sx={{
                              ".MuiInputBase-root": {
                                height: "40px",
                              },
                            }}
                            InputProps={{
                              inputComponent: SSNMask as any,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowSSN(!showSSN)}
                                    edge="end"
                                    color="secondary"
                                    size="small"
                                  >
                                    {showSSN ? (
                                      <EyeOutlined />
                                    ) : (
                                      <EyeInvisibleOutlined />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </Stack>
                  <Stack spacing={1}>
                    <Typography
                      variant="h6"
                      color={utils.secondaryColors.secondaryMain}
                    >
                      Confirm Social Security Number
                    </Typography>
                    <Stack>
                      <Controller
                        name="confirmSocialSecurityNumber"
                        rules={{
                          required: false,
                          pattern: {
                            value: SSNRegex,
                            message: t("error.invalidSSN"),
                          },
                        }}
                        {...COMMON_PROPS}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type={showConfirmSSN ? "text" : "password"}
                            name="confirmSocialSecurityNumber"
                            id="confirmSocialSecurityNumber"
                            placeholder="Confirm Social Security Number"
                            autoComplete="one-time-code"
                            sx={{
                              ".MuiInputBase-root": {
                                height: "40px",
                              },
                            }}
                            InputProps={{
                              inputComponent: SSNMask as any,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      setShowConfirmSSN(!showConfirmSSN)
                                    }
                                    edge="end"
                                    color="secondary"
                                    size="small"
                                  >
                                    {showConfirmSSN ? (
                                      <EyeOutlined />
                                    ) : (
                                      <EyeInvisibleOutlined />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Stack>
          <ErrorBox formState={formState} />
          <Stack direction={isMobile ? "column" : "row"} gap={1}>
            <ButtonBox
              onClickBack={toggleModal}
              onClickNext={() => onAddMember(getValues(), editMemberPosition)}
              rightButtonText="Add"
              leftIcon="close"
              rightIcon={RightButtonIconTypes.ADD}
              disableSticky
              disabled={isDisabled()}
              isLoading={isLoading}
            />
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};

export default OnboardingMembers;
