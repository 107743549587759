import { Dispatch, SetStateAction } from "react";
import { IStepProps } from "../../types";

export enum companySubSteps {
  companyInformation = "companyInformation",
  registeredAddress = "registeredAddress",
  einLetter = "einLetter",
}

export interface ISubStepProps extends IStepProps {
  setCurrentSubStep: Dispatch<SetStateAction<companySubSteps>>;
  setShowHeading: Dispatch<SetStateAction<boolean>>;
}

export const isManagedAddressKey = "money.onboarding.isManagedAddress";
