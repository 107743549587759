import {
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { addApplicantSteps } from "./AddApplicantSteps/types";
import { useState } from "react";
import utils from "utils";
import { IAddApplicantBaseProps, IAddApplicantStepsProps } from "./types";
import ApplicantDocuments from "./AddApplicantSteps/ApplicantDocuments";
import ApplicantInformation from "./AddApplicantSteps/ApplicantInformation";
import { CloseRounded } from "@mui/icons-material";
import { t } from "i18next";

interface IAddApplicantProps extends IAddApplicantBaseProps {
  showAddApplicantModal: boolean;
}

const AddApplicant = ({
  showAddApplicantModal,
  isNewApplicant,
  handleOnClose,
}: IAddApplicantProps) => {
  const [currentStep, setCurrentStep] = useState<addApplicantSteps>(
    addApplicantSteps.applicantInformation
  );

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const renderModalContent = (currentStep: addApplicantSteps) => {
    const commonProps: IAddApplicantStepsProps = {
      setCurrentStep,
      handleOnClose,
      isNewApplicant,
    };

    switch (currentStep) {
      case addApplicantSteps.applicantInformation:
        return <ApplicantInformation {...commonProps} />;

      case addApplicantSteps.applicantDocuments:
        return <ApplicantDocuments {...commonProps} />;
    }
  };

  return (
    <Dialog
      fullScreen={isTablet}
      PaperProps={{
        style: {
          ...(!isTablet && { width: "700px" }),
          borderRadius: !isTablet ? "32px" : "0",
          padding: isTablet ? "1rem" : "2rem",
          ...(isTablet && { paddingBottom: "90px" }),
        },
      }}
      open={showAddApplicantModal}
      onClose={() => handleOnClose()}
    >
      <DialogTitle sx={{ padding: "0px" }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          mb={isTablet ? 2 : 4}
        >
          <Typography fontSize={isTablet ? 24 : 28} fontWeight={700}>
            {t("general.applicant")}
          </Typography>
          <IconButton onClick={() => handleOnClose()}>
            <CloseRounded color="primary" />
          </IconButton>
        </Stack>
      </DialogTitle>
      {renderModalContent(currentStep)}
    </Dialog>
  );
};

export default AddApplicant;
