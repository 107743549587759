import { CSSProperties, useState } from "react";
import { useIntercom } from "react-use-intercom";

type Props = {
  children: any;
  style?: CSSProperties;
};

export const IntercomTriggerWrapper: React.FC<Props> = ({
  children,
  style,
}) => {
  const { show, hide } = useIntercom();
  const [isShown, setIsShown] = useState(false);

  const onClickHandler = () => {
    if (isShown) {
      setIsShown(false);
      hide();
    } else {
      setIsShown(true);
      show();
    }
  };

  return (
    <span onClick={onClickHandler} style={{ cursor: "pointer", ...style }}>
      {children}
    </span>
  );
};
