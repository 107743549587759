import { Stack, SxProps, Typography, useMediaQuery } from "@mui/material";
import utils, { colors } from "utils";
import { LightbulbOutlined } from "@mui/icons-material";

type Props = {
  body: string | JSX.Element;
  style?: SxProps;
};

const HintBox = ({ body, style }: Props) => {
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      gap={2}
      bgcolor={colors.lightBlue}
      p={isTablet ? 2 : 3}
      borderRadius={isTablet ? 3 : 6}
      sx={{ ...(style && { ...style }) }}
    >
      <LightbulbOutlined />
      <Typography fontSize={isTablet ? 14 : 16}>{body}</Typography>
    </Stack>
  );
};

export default HintBox;
