import { useGetProductsQuery } from "graphql/__generated__/operations/Products.generated";
import { Product } from "graphql/__generated__/types";
import { SERVICES_DATA } from "../data";
import { useCallback, useMemo } from "react";

const UPSELL_DB_KEYS = Object.values(SERVICES_DATA)
  .filter((i) => i.productKey)
  .map((i) => i.productKey as string);

interface UPSELL_DATA_TYPE {
  name: string;
  value: string;
  price: number;
}
interface UPSELL_PLANS_TYPE {
  [key: string]: UPSELL_DATA_TYPE;
}

export default function useGetUpsellProducts() {
  const { data, loading: isProductQueryLoading } = useGetProductsQuery({
    fetchPolicy: "cache-first",
    errorPolicy: "all",
  });

  const productServices = data?.productService?.payload;

  const filteredUpsellProducts = useMemo(
    () =>
      productServices?.filter((item) =>
        Object.values(UPSELL_DB_KEYS).includes(item?.key as string)
      ),
    [productServices]
  );

  const findPlanByKey = useCallback(
    (key: string) => filteredUpsellProducts?.find((item) => item?.key === key),
    [filteredUpsellProducts]
  );

  const createUpsellData = (
    name: string,
    plan?: Product | null
  ): UPSELL_DATA_TYPE => ({
    name,
    value: plan?.stripePricingId ?? "",
    price: (plan?.usdPriceCents ?? 0) / 100,
  });

  const UPSELL_PLANS: UPSELL_PLANS_TYPE = useMemo(
    () =>
      UPSELL_DB_KEYS.reduce((result: UPSELL_PLANS_TYPE, key) => {
        const plan = findPlanByKey(key);

        return {
          ...result,
          [key]: createUpsellData(key, plan),
        };
      }, {}),
    [findPlanByKey]
  );

  return { UPSELL_PLANS, isProductQueryLoading };
}
