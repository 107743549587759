import { Box } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { BooksSectionItems, MoneyRoutes, TabsData, TaxRoutes } from "../data";
import Item from "./Item";
import SubItem from "./SubItem";
import { SidebarItemsWrapper } from "./styles";
import { ROUTER_PATHS } from "routes/routes";
import { MenuBookOutlined } from "@mui/icons-material";
import utils from "utils";
import useCompany from "graphql/hooks/UseCompany";
import { useMemo } from "react";
import { CompanyServices } from "graphql/__generated__/types";
import UserControlHub from "./UserControlHub";
import { LoadingComponent } from "components/Loading";
import { usePostHog } from "posthog-js/react";

const LinkStyles = {
  display: "block",
  textDecoration: "none",
  marginBottom: 8,
};

const SidebarContent = () => {
  const currentPathName = useLocation().pathname;
  const posthog = usePostHog();

  const { currentCompany } = useCompany();

  const moneyService = useMemo(
    () =>
      currentCompany?.services?.find(
        ({ service }) => service === CompanyServices.MONEY
      ),
    [currentCompany?.services]
  );

  const isBooksSelected = utils.isRouteMatchingWithKey("books");

  if (!currentCompany) {
    return <LoadingComponent />;
  }

  const renderTabs = () => {
    return TabsData.map((item, index) => (
      <Link key={index} to={item.route} style={LinkStyles}>
        <Item $isSelected={currentPathName === item.route} sidebarItem={item} />
      </Link>
    ));
  };

  const renderTaxTab = () => (
    <Link to={ROUTER_PATHS.TAXES} style={LinkStyles}>
      <Item
        $isSelected={currentPathName === ROUTER_PATHS.TAXES}
        sidebarItem={TaxRoutes}
      />
    </Link>
  );

  const renderBooksTabs = () => (
    <>
      <Link to={ROUTER_PATHS.BOOKS} style={LinkStyles}>
        <Item
          $isSelected={isBooksSelected}
          sidebarItem={{
            icon: <MenuBookOutlined />,
            label: "Books",
          }}
        />
      </Link>

      {isBooksSelected &&
        BooksSectionItems.map((item, index) => {
          return (
            <Link key={index} to={item.route} style={LinkStyles}>
              <SubItem
                $isSelected={currentPathName === item.route}
                sidebarItem={item}
              />
            </Link>
          );
        })}
    </>
  );

  const renderMoneyTab = () => (
    <Link
      to={ROUTER_PATHS.MONEY}
      style={LinkStyles}
      onClick={() => posthog?.capture("money_sidebar_item_click")}
    >
      <Item
        $isSelected={utils.isRouteMatchingWithKey("money")}
        sidebarItem={MoneyRoutes}
      />
    </Link>
  );

  return (
    <>
      <SidebarItemsWrapper>
        {renderTabs()}
        {renderTaxTab()}
        {renderBooksTabs()}
        {moneyService && renderMoneyTab()}
      </SidebarItemsWrapper>
      <Box
        style={{
          position: "absolute",
          bottom: "0",
          width: "100%",
        }}
      >
        <UserControlHub />
      </Box>
    </>
  );
};

export default SidebarContent;
