import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import countries from "resources/countries";
import ISOCountries from "i18n-iso-countries";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { useUpdateCustomerCountryOfResidenceMutation } from "graphql/__generated__/operations/UpdateCustomerCountryOfResidence.generated";
import useSignupFlow from "graphql/hooks/UseSignupFlow";
import { Iso31661Alpha3 } from "graphql/__generated__/types";
import utils from "utils";
import ButtonBox from "components/ButtonBox";
import { ROUTER_PATHS } from "routes/routes";
import { usePostHog } from "posthog-js/react";

ISOCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const deniedCountriesList = ["RUS"];

const CountryOfResidence = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const posthog = usePostHog();

  const { customerId, countryOfResidence } = useSignupFlow();

  const [selectedValue, setSelectedValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [updateCustomerCountryOfResidence] =
    useUpdateCustomerCountryOfResidenceMutation();

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  useEffect(() => {
    if (countryOfResidence) {
      setSelectedValue(countryOfResidence);
    }
  }, [countryOfResidence]);

  const renderCountryAcceptance = () => {
    if (deniedCountriesList.includes(selectedValue)) {
      return (
        <Typography color="#d32f2f" mt={2}>
          This country is not currently supported.
        </Typography>
      );
    }

    if (selectedValue === "USA") {
      return (
        <Typography mt={2}>
          As a US Resident, when you use your Social Security Number for
          formation, doola can offer you expedited formation.
        </Typography>
      );
    }
  };

  const isDisabled =
    !selectedValue || deniedCountriesList.includes(selectedValue);

  // TODO: Uncomment for phase 1 with company origin
  // const onBackHandler = () => {
  //   posthog?.capture("funnel-back", {
  //     screen: FormationScreenNames.CountryOfResidence,
  //   });

  //   navigate(ROUTER_PATHS.PRE_PURCHASE_COMPANY_ORIGIN);
  // };

  const onSubmitHandler = async () => {
    setIsLoading(true);

    try {
      await updateCustomerCountryOfResidence({
        variables: {
          customerId,
          countryOfResidence: selectedValue as Iso31661Alpha3,
        },
      });

      posthog?.capture("residence-country-selected", {
        country_selected: selectedValue,
      });

      navigate(ROUTER_PATHS.PRE_PURCHASE_ENTITY_TYPE);
    } catch {
      setIsLoading(false);
    }
  };

  return (
    <Stack direction="column" gap={3}>
      <Typography variant={isTablet ? "h3" : "h2"}>
        {t("signup.countryOfResidence.header")}
      </Typography>
      <Stack position="relative">
        <Typography
          variant="h6"
          pb={1}
          color={utils.secondaryColors.secondaryMain}
        >
          {t("general.country")}
        </Typography>
        <Autocomplete
          options={[
            { code: "", name: "" },
            ...countries.map((country) => ({
              code: country.code,
              name: country.label,
            })),
          ]}
          autoHighlight
          getOptionLabel={(option) => (option.name ? option.name : "")}
          value={{
            name: ISOCountries.getName(selectedValue, "en") || "",
            code: ISOCountries.alpha3ToAlpha2(selectedValue) || "",
          }}
          onChange={(_, data) => {
            setSelectedValue(
              ISOCountries.alpha2ToAlpha3(data?.code || "") || ""
            );
          }}
          isOptionEqualToValue={(option, value) => option?.code === value.code}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              {option.code && (
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option?.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option?.code.toLowerCase()}.png 2x`}
                  alt=""
                />
              )}
              {option.name}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              onChange={() => {}}
              id={"countryOfResidence"}
              placeholder={t("general.countryPlaceholder") as string}
              name={"countryOfResidence"}
              type="text"
              variant="outlined"
              sx={{
                ".MuiInputBase-input": {
                  height: "8px",
                },
              }}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
        {renderCountryAcceptance()}
      </Stack>
      <Stack direction="row" alignItems={"center"} gap={2}>
        <InfoIcon />
        <Typography>{t("signup.countryOfResidence.subtitle")}</Typography>
      </Stack>
      <ButtonBox
        // onClickBack={onBackHandler}
        showBackButton={false}
        onClickNext={onSubmitHandler}
        disabled={isDisabled}
        isLoading={isLoading}
      />
    </Stack>
  );
};

export default CountryOfResidence;
