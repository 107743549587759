import {
  IOnboardingStep,
  OnboardingStepsCategories,
  OnboardingStepsData,
} from "./data";
import { Box, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import PrepareInfo from "./Steps/PrepareInfo";
import { IStepProps } from "./types";
import { Dispatch, SetStateAction, useState } from "react";
import { colors } from "utils";
import Member from "./Steps/Member";
import RiskAssessmentInformation from "./Steps/RiskAssessmentInformation";
import Company from "./Steps/Company";
import ApplicantDetails from "./Steps/ApplicantDetails";
import ReadyToSend from "./Steps/ReadyToSend";

interface IStepContentProps extends IStepProps {
  currentStep: IOnboardingStep;
  setIsFinalStep: Dispatch<SetStateAction<boolean>>;
}

const StepContent = ({
  currentStep,
  setCurrentStep,
  setIsFinalStep,
}: IStepContentProps) => {
  const [showHeading, setShowHeading] = useState(true);

  const renderStepContent = (currentStep: IOnboardingStep) => {
    const commonProps: IStepProps = {
      setCurrentStep: setCurrentStep,
    };

    switch (currentStep) {
      case OnboardingStepsData[OnboardingStepsCategories.prepareInfo]:
        return <PrepareInfo {...commonProps} />;
      case OnboardingStepsData[OnboardingStepsCategories.companyInformation]:
        return <Company {...commonProps} setShowHeading={setShowHeading} />;
      case OnboardingStepsData[OnboardingStepsCategories.membersInformation]:
        return <Member {...commonProps} setShowHeading={setShowHeading} />;
      case OnboardingStepsData[OnboardingStepsCategories.applicantInformation]:
        return <ApplicantDetails {...commonProps} />;
      case OnboardingStepsData[
        OnboardingStepsCategories.riskAssessmentInformation
      ]:
        return <RiskAssessmentInformation {...commonProps} />;
      case OnboardingStepsData[OnboardingStepsCategories.readyToSend]:
        return <ReadyToSend {...commonProps} setIsFinalStep={setIsFinalStep} />;
    }
  };

  return (
    <Box>
      {showHeading && (
        <>
          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
            {currentStep?.icon}
            <Typography fontSize={16} fontWeight={500}>
              {currentStep.title && t(currentStep.title)}
            </Typography>
          </Stack>
          {currentStep?.description && (
            <Typography fontSize={16} color={colors.contentTertiary} mt={0.5}>
              {t(currentStep.description)}
            </Typography>
          )}
        </>
      )}
      {renderStepContent(currentStep)}
    </Box>
  );
};

export default StepContent;
