import { MenuItem, Stack, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { calculateFeeValue, getStateOptions } from "services";
import FormControlElement from "../components/FormControlElement";
import { FormationScreenNames } from "../services";
import { SignupSelect } from "../styles";
import { useTranslation } from "react-i18next";
import { EntityType, Iso31662Us } from "graphql/__generated__/types";
import { useUpdateCompanyStateMutation } from "graphql/__generated__/operations/UpdateCompanyState.generated";
import useSignupFlow from "graphql/hooks/UseSignupFlow";
import utils, { colors } from "utils";
import ButtonBox from "components/ButtonBox";
import stateKeys from "resources/stateKeys";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { LoadingComponent } from "components/Loading";
import { ROUTER_PATHS } from "routes/routes";
import useGetStateFeesProducts from "hooks/useGetStateFees";
import { usePostHog } from "posthog-js/react";
import StateNotification from "../components/StateNotification";

const WYoption = {
  title: "Wyoming",
  subtitle: "signup.entityState.wyoming.subtitle",
  value: "WY",
};

const DEoption = {
  title: "Delaware",
  subtitle: "signup.entityState.delaware.subtitle",
  value: "DE",
};

const OtherOption = {
  title: "Other",
  subtitle: "signup.entityState.other.subtitle",
  value: "true",
};

const CompanyState = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const posthog = usePostHog();

  const [selectedValue, setSelectedValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOtherOption, setIsOtherOption] = useState<boolean>(false);

  const {
    entityType,
    entityState,
    companyId,
    countryOfResidence,
    hasDataLoaded,
  } = useSignupFlow();
  const [updateCompanyState] = useUpdateCompanyStateMutation();

  const { filteredFeesProducts, isProductQueryLoading } =
    useGetStateFeesProducts();

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);

  const LLCNonUs =
    entityType === EntityType.LLC &&
    countryOfResidence &&
    countryOfResidence !== "USA";

  useEffect(() => {
    if (entityType === EntityType.DAO) {
      setSelectedValue("WY");
      setIsOtherOption(false);
      return;
    }

    if (entityType === EntityType.CCORP) {
      setSelectedValue(entityState || "DE");
      setIsOtherOption(
        entityState ? entityState !== "WY" && entityState !== "DE" : false
      );
      return;
    }

    if (LLCNonUs) {
      setSelectedValue(entityState || "WY");
      setIsOtherOption(entityState ? entityState !== "WY" : false);
      return;
    }

    setSelectedValue(entityState || "");
    setIsOtherOption(false);
  }, [entityType, entityState, LLCNonUs]);

  if (!hasDataLoaded || isProductQueryLoading) {
    return <LoadingComponent />;
  }

  const feeValue = () => calculateFeeValue(selectedValue, filteredFeesProducts);

  const onSubmitHandler = async () => {
    setIsLoading(true);

    await updateCompanyState({
      variables: { companyId, state: selectedValue },
    });

    posthog?.capture("entity-state-selected", {
      entity_state: selectedValue,
    });

    navigate(ROUTER_PATHS.PRE_PURCHASE_COMPANY_NAME);
  };

  const onBackHandler = () => {
    posthog?.capture("funnel-back", {
      screen: FormationScreenNames.CompanyState,
    });

    navigate(ROUTER_PATHS.PRE_PURCHASE_ENTITY_TYPE);
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
    setIsOtherOption(false);
  };

  const renderEntityTitle = () => {
    switch (entityType) {
      case EntityType.DAO:
        return t("signup.entityType.dao.title");
      case EntityType.CCORP:
        return t("signup.entityType.ccorp.title");
      default:
        return t("signup.entityType.llc.title");
    }
  };

  const renderStateSelector = () => {
    switch (entityType) {
      case EntityType.DAO:
        return (
          <FormControlElement
            title={WYoption.title}
            subtitle={t(WYoption.subtitle) as string}
            optionValue={WYoption.value}
            selectedValue={selectedValue}
            changeHandler={onChangeHandler}
            sideBySideTitle={!isMobile}
          />
        );
      case EntityType.CCORP:
        return (
          <>
            <FormControlElement
              title={DEoption.title}
              subtitle={t(DEoption.subtitle) as string}
              optionValue={DEoption.value}
              selectedValue={selectedValue}
              changeHandler={onChangeHandler}
              chipText={t("general.recommended")!}
              sideBySideTitle={!isMobile}
            />
            <FormControlElement
              title={WYoption.title}
              subtitle={t(WYoption.subtitle) as string}
              optionValue={WYoption.value}
              selectedValue={selectedValue}
              changeHandler={onChangeHandler}
              sideBySideTitle={!isMobile}
            />
            <FormControlElement
              title={OtherOption.title}
              subtitle={t(OtherOption.subtitle) as string}
              optionValue={OtherOption.value}
              selectedValue={isOtherOption.toString()}
              changeHandler={() => {
                setSelectedValue("");
                setIsOtherOption(true);
              }}
              sideBySideTitle={!isMobile}
            />
            {isOtherOption && (
              <Stack spacing={1}>
                <Typography
                  variant="h6"
                  color={utils.secondaryColors.secondaryMain}
                >
                  {t("general.state")}
                </Typography>
                <SignupSelect
                  displayEmpty
                  value={selectedValue}
                  onChange={(e) => setSelectedValue(e.target.value as string)}
                >
                  <MenuItem
                    disabled
                    value={
                      (selectedValue === "WY" ? "WY" : "") ||
                      (selectedValue === "DE" ? "DE" : "")
                    }
                  >
                    {t("signup.entityState.selectPreferred")}
                  </MenuItem>
                  {getStateOptions(EntityType.CCORP)
                    .filter(({ value }) => value !== "WY" && value !== "DE")
                    .map(({ name, value }, index) => {
                      return (
                        <MenuItem key={index} value={value}>
                          {name}
                        </MenuItem>
                      );
                    })}
                </SignupSelect>
              </Stack>
            )}
          </>
        );
      default:
        if (countryOfResidence === "USA") {
          return (
            <Stack spacing={1}>
              <Typography
                variant="h6"
                color={utils.secondaryColors.secondaryMain}
              >
                {t("general.state")}
              </Typography>
              <SignupSelect
                displayEmpty
                value={selectedValue}
                onChange={(e) => setSelectedValue(e.target.value as string)}
              >
                <MenuItem disabled value="">
                  {t("signup.entityState.selectPreferred")}
                </MenuItem>
                {getStateOptions(EntityType.LLC).map(
                  ({ name, value }, index) => {
                    return (
                      <MenuItem key={index} value={value}>
                        {name}
                      </MenuItem>
                    );
                  }
                )}
              </SignupSelect>
            </Stack>
          );
        } else {
          return (
            <Stack gap={2}>
              <FormControlElement
                title={WYoption.title}
                subtitle={t(WYoption.subtitle) as string}
                optionValue={WYoption.value}
                selectedValue={selectedValue}
                changeHandler={onChangeHandler}
                chipText={t("general.recommended")!}
                sideBySideTitle={!isMobile}
              />
              <FormControlElement
                title={OtherOption.title}
                subtitle={t(OtherOption.subtitle) as string}
                optionValue={OtherOption.value}
                selectedValue={isOtherOption.toString()}
                changeHandler={() => {
                  setSelectedValue("");
                  setIsOtherOption(true);
                }}
                sideBySideTitle={!isMobile}
              />
              {isOtherOption && (
                <Stack spacing={1}>
                  <Typography
                    variant="h6"
                    color={utils.secondaryColors.secondaryMain}
                  >
                    {t("general.state")}
                  </Typography>
                  <SignupSelect
                    displayEmpty
                    value={selectedValue}
                    onChange={(e) => setSelectedValue(e.target.value as string)}
                  >
                    <MenuItem
                      disabled
                      value={selectedValue === "WY" ? "WY" : ""}
                    >
                      {t("signup.entityState.selectPreferred")}
                    </MenuItem>
                    {getStateOptions(EntityType.CCORP)
                      .filter(({ value }) => value !== "WY")
                      .map(({ name, value }, index) => {
                        return (
                          <MenuItem key={index} value={value}>
                            {name}
                          </MenuItem>
                        );
                      })}
                  </SignupSelect>
                </Stack>
              )}
            </Stack>
          );
        }
    }
  };

  return (
    <Stack direction="column" gap={3}>
      <Typography variant={isTablet ? "h3" : "h2"}>
        {t("signup.entityState.heading", {
          value: renderEntityTitle(),
        })}
      </Typography>
      {renderStateSelector()}
      {selectedValue && (
        <Stack
          sx={{
            padding: 3,
            borderRadius: "24px",
            bgcolor: colors.lightGrey,
            border: `1px solid ${colors.lightGrey}`,
          }}
          gap={2}
        >
          <Typography fontSize={16} fontWeight={500}>
            {stateKeys[selectedValue]} requirements
          </Typography>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography color={colors.black}>One-Time Filing Fee</Typography>
            <Typography color={colors.contentSecondary}>
              ${feeValue()}.00
            </Typography>
          </Stack>
        </Stack>
      )}
      {countryOfResidence === "USA" && entityType === EntityType.LLC && (
        <Stack direction="row" alignItems={"center"} gap={2}>
          <InfoIcon />
          <Typography>{t("signup.entityState.recommend.us")}</Typography>
        </Stack>
      )}
      {selectedValue === Iso31662Us.CA && <StateNotification />}
      <ButtonBox
        onClickBack={onBackHandler}
        onClickNext={onSubmitHandler}
        disabled={!selectedValue}
        isLoading={isLoading}
        data-testid="entityStateNextButton"
      />
    </Stack>
  );
};

export default CompanyState;
