import { Skeleton, Stack, Typography } from "@mui/material";
import { colors } from "utils";
import { NavigateNext } from "@mui/icons-material";
import { t } from "i18next";
import PercentRoundedIcon from "@mui/icons-material/PercentRounded";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import useGetTaxRelatedProducts from "../hooks/useGetTaxRelatedProducts";
import { moneyFormatter } from "utils/formatters";
import useCompany from "graphql/hooks/UseCompany";
import { CompanyServices, ServiceCategory } from "graphql/__generated__/types";
import Button from "components/Button";
import ServicePurchaseModal from "components/ServicePurchaseModal";
import { useState } from "react";
import { SERVICES_DATA } from "views/DashboardApp/Services/data";
import { usePostHog } from "posthog-js/react";
import { useRequestStripeCheckoutSessionDdsMutation } from "graphql/__generated__/operations/RequestStripeCheckoutSessionFromDDS.generated";
import { useUpdateCustomerStripeIdMutation } from "graphql/__generated__/operations/UpdateCustomerStripeId.generated";
import useCustomer from "graphql/hooks/useCustomer";
import { TAX_SEASON_START_DATE, redirectTaxesBaseUri } from "../constants";
import { useCountdown } from "../hooks/useCountdown";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import FilingModal from "./FilingModal";
import { format as formatDate } from "fecha";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const TaxFilings = () => {
  const posthog = usePostHog();
  const { currentCompany } = useCompany();
  const { currentUser } = useCustomer();
  const { TAX_PLANS, isProductQueryLoading } = useGetTaxRelatedProducts();
  const [checkoutWithStripe] = useRequestStripeCheckoutSessionDdsMutation();
  const [updateCustomerStripeId] = useUpdateCustomerStripeIdMutation();
  const [months, days, hours] = useCountdown(TAX_SEASON_START_DATE);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPurchaseModalOpen, setIsPurchaseModalOpen] =
    useState<boolean>(false);
  const [isFilingModalOpen, setIsFilingModalOpen] = useState<boolean>(false);

  if (!currentCompany || isProductQueryLoading)
    return (
      <Skeleton
        width={"100%"}
        height={324}
        sx={{ borderRadius: 8, bgcolor: colors.lightAccentYellow }}
        variant="rectangular"
        animation="wave"
      />
    );

  const taxService = currentCompany?.services?.find(
    (s) => s.service === CompanyServices.TAX
  );

  const isTaxSeasonLive = months + days + hours <= 0;
  const isTaxSubmitted =
    taxService?.status === ServiceCategory.ACTIVE ||
    taxService?.subStatus !== "WaitingForCustomerInfo";

  const formationFilingDate =
    currentCompany?.registeredAgent?.inHouse?.formationFilingDate;

  const isCompanyFormedThisYear = formationFilingDate
    ? dayjs.utc(formationFilingDate).year() === dayjs().year()
    : false;

  const isFileNowDisabled = isTaxSubmitted || isCompanyFormedThisYear;

  const onCheckoutHandler = async () => {
    setIsLoading(true);

    try {
      const companyId = currentCompany.id;
      const customerId = currentUser?.id || "";
      const { data } = await checkoutWithStripe({
        variables: {
          companyId,
          customerId,
          products: [TAX_PLANS.TAX.lookupKey],
          redirectBaseUri: redirectTaxesBaseUri,
          email: currentUser?.email || "",
        },
      });

      const stripeCheckoutUrl =
        data?.company?.requestStripeCheckoutSessionDDS?.url;
      const stripeCustomerId =
        data?.company?.requestStripeCheckoutSessionDDS?.stripeCustomerId;

      if (stripeCustomerId && customerId) {
        await updateCustomerStripeId({
          variables: { customerId, stripeCustomerId },
        });
      }

      if (stripeCheckoutUrl) {
        posthog?.capture(`tax-service-checkout-started`);

        await new Promise((resolve) => window.setTimeout(resolve, 500)); // wait 500ms
        window.location.replace(stripeCheckoutUrl);
      }
    } catch {
      setIsLoading(false);
    }
  };

  return (
    <Stack
      sx={{
        bgcolor: colors.lightAccentYellow,
        borderRadius: 8,
        p: 4,
        height: "100%",
      }}
      gap={4}
    >
      <Stack gap={2} alignItems={"flex-start"}>
        <Stack
          direction={"row"}
          gap={1}
          sx={{
            bgcolor: colors.darkAccentYellow,
            borderRadius: 3,
            px: 1.5,
            py: 1,
            fontSize: "1rem",
            fontWeight: 500,
          }}
          alignItems={"center"}
        >
          <PercentRoundedIcon sx={{ fontSize: "1.2rem" }} />
          {t("dashboard.tax.taxFilings")}
        </Stack>
        <Typography variant="h3" fontWeight={700}>
          {t("dashboard.tax.taxFilings.title")}
        </Typography>
        <Typography fontSize={16}>
          {t("dashboard.tax.taxFilings.text")}
        </Typography>
      </Stack>

      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={2}
      >
        {taxService ? (
          isTaxSeasonLive ? (
            <>
              <Stack>
                <Typography variant="body1" color={colors.contentSecondary}>
                  {isTaxSubmitted && t("dashboard.tax.taxFilings")}
                </Typography>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  {isTaxSubmitted && (
                    <CheckCircleOutlineOutlinedIcon fontSize="small" />
                  )}
                  <Typography
                    fontSize={16}
                    fontWeight={500}
                    color={colors.black}
                  >
                    {isTaxSubmitted && t("general.submitted")}
                  </Typography>
                </Stack>
              </Stack>
              <Button
                disabled={isFileNowDisabled}
                endIcon={<NavigateNext />}
                buttonText={t("dashboard.tax.taxFilings.filenow")}
                onClick={() => {
                  setIsFilingModalOpen(true);

                  posthog?.capture(`tax_filings_file_now_clicked`);
                }}
              />
              <FilingModal
                isModalOpen={isFilingModalOpen}
                onCloseModalHandler={() => setIsFilingModalOpen(false)}
              />
            </>
          ) : (
            <>
              <Stack>
                <Typography variant="body1" color={colors.contentSecondary}>
                  {t("dashboard.tax.taxFilings.startfilingfrom")}
                </Typography>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <CalendarMonthOutlinedIcon fontSize="small" />
                  <Typography
                    fontSize={16}
                    fontWeight={500}
                    color={colors.black}
                  >
                    {formatDate(TAX_SEASON_START_DATE, "MMMM Do")}
                  </Typography>
                </Stack>
              </Stack>
              <Button
                disabled
                endIcon={<NavigateNext />}
                buttonText={t("dashboard.tax.taxFilings.filenow")}
              />
            </>
          )
        ) : (
          <>
            <Stack direction={"row"} alignItems={"flex-end"} spacing={1}>
              <Typography variant="h2" fontSize={28} lineHeight={1}>
                {moneyFormatter(TAX_PLANS.TAX.price)}
              </Typography>
              <Typography variant="body1" color={colors.contentTertiary}>
                /yr
              </Typography>
            </Stack>
            <Button
              endIcon={<NavigateNext />}
              buttonText={t("general.purchase")}
              onClick={() => setIsPurchaseModalOpen(true)}
            />
            <ServicePurchaseModal
              isModalOpen={isPurchaseModalOpen}
              onCloseModalHandler={() => setIsPurchaseModalOpen(false)}
              itemToShow={SERVICES_DATA[CompanyServices.TAX]}
              selectedServiceStatus={null}
              isLoading={isLoading}
              onCheckoutHandler={onCheckoutHandler}
              image={SERVICES_DATA[CompanyServices.TAX].image}
              imageBgColor={SERVICES_DATA[CompanyServices.TAX].imageBgColor}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default TaxFilings;
