import { useState } from "react";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { toast } from "toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import { CognitoPasswordRegex } from "utils/regexValidations";
import { useAuthConfirmForgotPasswordMutation } from "graphql/__generated__/operations/AuthConfirmForgotPassword.generated";
import utils from "utils";
import ButtonBox from "components/ButtonBox";
import { PasswordRequirements } from "components/PasswordRequirements";
import { useTranslation } from "react-i18next";
import CustomInput from "components/Form/CustomInput";
import ErrorBox from "components/ErrorBox";

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState<string>("");

  const { register, control, formState, handleSubmit, watch } = useForm({
    defaultValues: { code: "", password: "", confirmPassword: "" },
    mode: "onBlur",
  });
  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors, register: register };

  const [authConfirmForgotPassword] = useAuthConfirmForgotPasswordMutation();

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const { t } = useTranslation();

  const newPassword = watch("password");

  const userEmail = searchParams.get("username");

  const onSubmitHandler = async ({ code, password }: FieldValues) => {
    setIsLoading(true);

    if (!userEmail) {
      toast.error("No user found");
      return false;
    }

    try {
      await authConfirmForgotPassword({
        variables: {
          input: {
            email: userEmail.trim(),
            confirmationCode: code.trim(),
            newPassword: password.trim(),
          },
        },
      });

      toast.success(t("reset.password.success") as string);

      navigate(ROUTER_PATHS.LOGIN, { replace: true });
    } catch {
      setIsLoading(false);
    }
  };

  return (
    <Stack gap={4}>
      <Typography variant={isTablet ? "h3" : "h2"}>
        {t("reset.password.title")}
      </Typography>
      <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
        <Stack gap={2}>
          <CustomInput
            value={userEmail}
            disabled
            placeholder={t("general.emailPlaceholder") as string}
            label={t("general.email") as string}
            autoComplete="email"
          />
          <Controller
            name="code"
            {...COMMON_PROPS}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <CustomInput
                {...field}
                id="verification-code"
                type="password"
                placeholder={
                  t("reset.password.verificationCodePlaceholder") as string
                }
                label={t("reset.password.verificationCode") as string}
                autoComplete="code"
              />
            )}
          />
          <Controller
            name="password"
            {...COMMON_PROPS}
            rules={{
              required: true,
              validate: (value) =>
                CognitoPasswordRegex.test(value.trim()) ||
                "Password is not complex enough. Please try again.",
            }}
            render={({ field }) => (
              <CustomInput
                {...field}
                id="password"
                isProtected
                placeholder={
                  t("reset.password.newPasswordPlaceholder") as string
                }
                label={t("reset.password.newPassword") as string}
                autoComplete="new-password"
                onKeyUp={(e) =>
                  setPassword((e.target as HTMLInputElement).value)
                }
              />
            )}
          />
          <Controller
            name="confirmPassword"
            {...COMMON_PROPS}
            rules={{
              required: true,
              validate: (value) =>
                value === newPassword || "The passwords do not match",
            }}
            render={({ field }) => (
              <CustomInput
                id="confirm-password-reset"
                {...field}
                isProtected
                placeholder={t("general.confirmPasswordPlaceholder") as string}
                label={t("general.confirmPassword") as string}
                autoComplete="new-password"
              />
            )}
          />
          <PasswordRequirements password={password} />
          <ErrorBox formState={formState} />
          <ButtonBox
            isLoading={isLoading}
            disabled={!formState.isValid}
            onClickBack={() => navigate(ROUTER_PATHS.RECOVER_PASSWORD)}
            onClickNext={() => onSubmitHandler}
            rightButtonText={t("general.update") as string}
          />
        </Stack>
      </form>
    </Stack>
  );
};

export default ResetPassword;
