import { CheckCircleOutlined, QuestionCircleFilled } from "@ant-design/icons";
import { Box, Stack, Typography } from "@mui/material";
import { InfoToolTip } from "components/InfoToolTip";

interface IServiceProps {
  title: string;
  tooltipInfo?: string;
  services?: string[];
}

const Service: React.FC<IServiceProps> = ({ title, tooltipInfo, services }) => {
  return (
    <Box mb={1.5}>
      <Stack direction={"row"} spacing={1}>
        <CheckCircleOutlined style={{ fontSize: 21, color: "#52C41A" }} />
        <Typography component={"div"} fontWeight={700}>
          {title}{" "}
          {tooltipInfo && (
            <InfoToolTip
              title={title}
              description={tooltipInfo as string}
              icon={<QuestionCircleFilled style={{ color: "#FFC53D" }} />}
              $styledCss={{ paddingLeft: "3px" }}
            />
          )}
        </Typography>
      </Stack>
      {services && (
        <Stack ml={4}>
          {services.map((service, index) => (
            <Typography
              key={index}
              sx={{ "&:before": { content: "' • '", mr: 1 } }}
            >
              {service}
            </Typography>
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default Service;
