import ButtonBox from "components/ButtonBox";
import { IStepProps } from "../../types";
import {
  TaxFilingStepsCategories,
  TaxFilingStepsData,
  TaxQuestionResponses,
} from "../../data";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import utils from "utils";
import Question from "./Question";
import useCompany from "graphql/hooks/UseCompany";
import { LoadingComponent } from "components/Loading";
import { useSaveTaxAnswerMutation } from "graphql/__generated__/operations/SaveTaxAnswer.generated";
import { useEffect, useState } from "react";
import { TaxQuestionAnswers } from "graphql/__generated__/types";
import { usePostHog } from "posthog-js/react";

const Questions = ({ setCurrentStep }: IStepProps) => {
  const posthog = usePostHog();
  const { currentCompany } = useCompany();
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [answers, setAnswers] = useState<TaxQuestionAnswers[] | []>([]);

  const [saveTaxAnswer] = useSaveTaxAnswerMutation();

  useEffect(() => {
    const responses =
      currentCompany?.taxQuestions?.map((item) => ({
        questionId: item?.questionId,
        question: item?.question,
        answer: item?.answer ?? TaxQuestionResponses.NO,
      })) ?? [];

    setAnswers(responses);
  }, [currentCompany?.taxQuestions]);

  if (!currentCompany) {
    return <LoadingComponent />;
  }

  const onToggleSwitchHandler = (questionId: string, newValue: string) => {
    setAnswers((prevAnswers: TaxQuestionAnswers[]) => {
      const index = prevAnswers.findIndex(
        (question: TaxQuestionAnswers) => question.questionId === questionId
      );

      // If the item with the specified questionId exists, update its answer
      if (index !== -1) {
        // Create a new array with the updated item
        const updatedAnswers = [...prevAnswers];

        updatedAnswers[index] = {
          ...updatedAnswers[index],
          answer: newValue,
        };

        return updatedAnswers;
      }

      // If the item with the specified questionId doesn't exist, do nothing
      return prevAnswers;
    });
  };

  const onClickNextHandler = async () => {
    try {
      setIsLoading(true);

      const answersMutations = answers.map((item) => {
        const { answer, questionId } = item;

        return saveTaxAnswer({
          variables: {
            companyId: currentCompany?.id,
            answer,
            questionId,
          },
        });
      });

      await Promise.all(answersMutations);

      setCurrentStep(TaxFilingStepsData[TaxFilingStepsCategories.taxDocuments]);
      posthog?.capture(`tax_modal_questions_next_clicked`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Stack mt={3} mb={isTablet ? 9 : 3} gap={2}>
        {answers.length > 0 ? (
          answers.map(
            (item, index) =>
              item && (
                <Question
                  key={index}
                  {...item}
                  onChange={onToggleSwitchHandler}
                />
              )
          )
        ) : (
          <Stack alignItems={"center"} justifyContent={"center"}>
            <Typography variant="h5">
              No questions have been added at this time
            </Typography>
          </Stack>
        )}
      </Stack>
      <ButtonBox
        isLoading={isLoading}
        onClickBack={() =>
          setCurrentStep(
            TaxFilingStepsData[TaxFilingStepsCategories.companyInformation]
          )
        }
        onClickNext={onClickNextHandler}
      />
    </>
  );
};

export default Questions;
