import { AuthTokens } from "graphql/__generated__/types";

export const AUTH_ID_TOKEN_KEY = "Auth.IdToken";
export const AUTH_REFRESH_TOKEN_KEY = "Auth.RefreshToken";
export const AUTH_ACCESS_TOKEN_KEY = "Auth.AccessToken";

export const setTokens = ({
  idToken,
  accessToken,
  refreshToken,
}: AuthTokens) => {
  localStorage.setItem(AUTH_ID_TOKEN_KEY, idToken);
  localStorage.setItem(AUTH_REFRESH_TOKEN_KEY, refreshToken);
  localStorage.setItem(AUTH_ACCESS_TOKEN_KEY, accessToken);
};

export const getTokens = () => {
  const idToken = localStorage.getItem(AUTH_ID_TOKEN_KEY) || "";
  const refreshToken = localStorage.getItem(AUTH_REFRESH_TOKEN_KEY) || "";
  const accessToken = localStorage.getItem(AUTH_ACCESS_TOKEN_KEY) || "";

  return {
    idToken,
    refreshToken,
    accessToken,
  };
};

export const removeTokens = () => {
  localStorage.removeItem(AUTH_ID_TOKEN_KEY);
  localStorage.removeItem(AUTH_REFRESH_TOKEN_KEY);
  localStorage.removeItem(AUTH_ACCESS_TOKEN_KEY);
};
