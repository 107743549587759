import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMoneyLookupQueryVariables = Types.Exact<{
  category: Types.LookupTypeKey;
}>;


export type GetMoneyLookupQuery = { __typename?: 'Query', lookup?: { __typename?: 'LookupResponse', payload?: Array<{ __typename?: 'Lookup', description?: string | null, key?: string | null } | null> | null } | null };


export const GetMoneyLookupDocument = gql`
    query GetMoneyLookup($category: LookupTypeKey!) {
  lookup(category: $category) {
    payload {
      description
      key
    }
  }
}
    `;

/**
 * __useGetMoneyLookupQuery__
 *
 * To run a query within a React component, call `useGetMoneyLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoneyLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoneyLookupQuery({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useGetMoneyLookupQuery(baseOptions: Apollo.QueryHookOptions<GetMoneyLookupQuery, GetMoneyLookupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMoneyLookupQuery, GetMoneyLookupQueryVariables>(GetMoneyLookupDocument, options);
      }
export function useGetMoneyLookupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMoneyLookupQuery, GetMoneyLookupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMoneyLookupQuery, GetMoneyLookupQueryVariables>(GetMoneyLookupDocument, options);
        }
export type GetMoneyLookupQueryHookResult = ReturnType<typeof useGetMoneyLookupQuery>;
export type GetMoneyLookupLazyQueryHookResult = ReturnType<typeof useGetMoneyLookupLazyQuery>;
export type GetMoneyLookupQueryResult = Apollo.QueryResult<GetMoneyLookupQuery, GetMoneyLookupQueryVariables>;