import { useState } from "react";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import MoneyHeader from "../Header";
import utils, { colors } from "utils";
import { useTranslation } from "react-i18next";
import { SearchOutlined, SendOutlined } from "@mui/icons-material";
import CustomInput from "components/Form/CustomInput";
import RecentlyPaid from "../RecentlyPaid";
import useFilteredBeneficiaryList from "../hooks/useFilteredBeneficiaryList";
import { LoadingComponent } from "components/Loading";
import BeneficiaryListItem from "../components/BeneficiaryListItem";
import { BeneficiaryResponse } from "graphql/__generated__/types";
import NoBeneficiaries from "../Beneficiary/components/NoBeneficiaries";
import { getTransferMethod } from "../helpers";
import Button from "components/Button";
import { PlusOutlined } from "@ant-design/icons";
import AddBeneficiary from "../Beneficiary/AddBeneficiary";
import { usePostHog } from "posthog-js/react";

const Transfer = () => {
  const [isAddBeneficiaryModalOpen, setIsAddBeneficiaryModalOpen] =
    useState<boolean>(false);

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const { t } = useTranslation();
  const posthog = usePostHog();

  const {
    filteredBeneficiaries,
    queryString,
    handleSearch,
    isBeneficiariesLoading,
  } = useFilteredBeneficiaryList();

  if (isBeneficiariesLoading) {
    return <LoadingComponent />;
  }

  const renderBeneficiaryList = () => {
    if (!queryString) {
      return null;
    }

    return (
      <Stack
        mt={2}
        p={isTablet ? 3 : 4}
        borderRadius={isTablet ? 6 : 8}
        bgcolor={colors.lightGrey}
        gap={isTablet ? 3 : 4}
      >
        {filteredBeneficiaries?.length ? (
          filteredBeneficiaries?.map(
            (beneficiary: BeneficiaryResponse | null) =>
              beneficiary && (
                <BeneficiaryListItem
                  key={beneficiary.beneficiaryHashId}
                  beneficiaryId={beneficiary.beneficiaryHashId ?? ""}
                  recipientName={beneficiary.beneficiaryName || ""}
                  transferType={getTransferMethod({
                    payoutMethod: beneficiary.payoutMethod || "",
                    destinationCountry: beneficiary.destinationCountry || "",
                    destinationCurrency: beneficiary.destinationCurrency || "",
                  })}
                  countryCode={beneficiary.beneficiaryCountryCode || ""}
                  transferIconColor={colors.grey}
                />
              )
          )
        ) : (
          <NoBeneficiaries />
        )}
      </Stack>
    );
  };

  return (
    <>
      <AddBeneficiary
        isModalOpen={isAddBeneficiaryModalOpen}
        onCloseModalHandler={() => setIsAddBeneficiaryModalOpen(false)}
      />
      <Stack gap={isTablet ? 3 : 4}>
        <MoneyHeader />
        <Grid container item xs={12} spacing={isTablet ? 3 : 10}>
          <Grid item xs={12} md={8}>
            <Stack>
              <Box mb={isTablet ? 3 : 4}>
                <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                  <SendOutlined sx={{ fontSize: "20px" }} />
                  <Typography fontSize={16} fontWeight={500}>
                    {t("money.transferMoney")}
                  </Typography>
                </Stack>
                <Typography fontSize={16} color={colors.contentTertiary} mt={1}>
                  {t("money.transfer.subtitle")}
                </Typography>
              </Box>
              <CustomInput
                placeholder={
                  t("money.beneficiary.search.placeholder") as string
                }
                endIcon={<SearchOutlined />}
                value={queryString}
                onChange={(e) => handleSearch(e.target.value)}
              />
              {renderBeneficiaryList()}
              <Button
                endIcon={
                  <PlusOutlined style={{ fontSize: 16, fontWeight: "700" }} />
                }
                buttonText={t("money.beneficiary.add")}
                onClick={() => {
                  posthog?.capture("money_transfer_page_add_beneficiary_click");
                  setIsAddBeneficiaryModalOpen(true);
                }}
                styles={{
                  alignSelf: "flex-end",
                  marginTop: 1.5,
                  color: colors.black,
                  backgroundColor: colors.lightGrey,
                  ":hover": {
                    backgroundColor: colors.grey,
                  },
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <RecentlyPaid />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default Transfer;
