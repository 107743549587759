import {
  CheckCircleOutline,
  NavigateNext,
  CircleOutlined,
  Schedule,
} from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import {
  CompanyServices,
  ServiceCategory,
  ServiceVariant,
} from "graphql/__generated__/types";
import { colors } from "utils";
import { SERVICE_STATUSES } from "views/DashboardApp/Services/data";
import {
  EinSteps,
  StepStatusType,
  TrackerCategories,
  TrackerData,
} from "../data";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import useCompany from "graphql/hooks/UseCompany";
import stateKeys from "resources/stateKeys";
import { LoadingComponent } from "components/Loading";
import useCustomer from "graphql/hooks/useCustomer";
import { usePostHog } from "posthog-js/react";
import Button from "components/Button";

type Props = {
  category: string;
  subCategory: string;
  status: StepStatusType;
};

const TrackInformation = ({ category, subCategory, status }: Props) => {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { currentCompany } = useCompany();
  const { currentUser } = useCustomer();

  const stepStatus = status[subCategory];

  const isComplete = stepStatus === ServiceCategory.COMPLETED;
  const isInProgress = stepStatus === ServiceCategory.INPROGRESS;
  const isNotStarted = stepStatus === ServiceCategory.NOTSTARTED;

  if (!currentCompany || !currentUser) {
    return <LoadingComponent />;
  }

  const companyName = currentCompany?.name;
  const state = stateKeys[currentCompany.state as keyof typeof stateKeys];

  const trackerSubCategory = TrackerData[category]?.steps[subCategory];
  const trackerSubCategoryStatus = trackerSubCategory?.[stepStatus];

  const countryOfResidence = currentUser.countryOfResidence;
  const isUSResident = countryOfResidence === "USA";

  const einCreationService = currentCompany?.services?.find(
    (s) => s.service === CompanyServices.EINCREATION
  );
  const isExpeditedEinVariant =
    einCreationService?.variant === ServiceVariant.EXPEDITE;

  // The information in the text block varies for EIN service depending on the chosen variant and country of residence.
  const renderSubCategoryInfo = () => {
    if (subCategory === EinSteps.einApplicationProcessing) {
      return isUSResident
        ? `${trackerSubCategory.info}.us`
        : isExpeditedEinVariant
        ? `${trackerSubCategory.info}.expedited`
        : `${trackerSubCategory.info}.standard`;
    }

    return trackerSubCategory.info;
  };

  return (
    <Stack
      sx={{
        bgcolor: isComplete
          ? colors.lightAccentGreen
          : isInProgress
          ? colors.lightAccentYellow
          : colors.grey,
        borderRadius: 8,
        p: 4,
        height: "100%",
      }}
      gap={4}
    >
      <Stack gap={2} alignItems={"flex-start"}>
        <Stack
          direction={"row"}
          gap={1}
          sx={{
            bgcolor: isComplete
              ? colors.darkAccentGreen
              : isInProgress
              ? colors.darkAccentYellow
              : colors.darkGrey,
            borderRadius: 3,
            px: 1.5,
            py: 1,
            fontSize: "1rem",
            fontWeight: 500,
          }}
          alignItems={"center"}
        >
          {isComplete && <CheckCircleOutline sx={{ fontSize: "1.2rem" }} />}
          {isInProgress && <Schedule sx={{ fontSize: "1.2rem" }} />}
          {isNotStarted && <CircleOutlined sx={{ fontSize: "1.2rem" }} />}
          {isNotStarted
            ? category === TrackerCategories.essentials
              ? "Available"
              : "Pending"
            : SERVICE_STATUSES[stepStatus]}
        </Stack>
        <Typography variant="h3" fontWeight={700}>
          {subCategory}
        </Typography>
        <Typography fontSize={16}>
          {t(renderSubCategoryInfo(), {
            state: state,
            company_name: companyName,
          })}
        </Typography>
      </Stack>
      {trackerSubCategoryStatus && (
        <Button
          endIcon={<NavigateNext />}
          buttonText={trackerSubCategoryStatus.buttonLabel}
          onClick={() => {
            posthog?.capture("formation_tracker_button_click", {
              category: category,
              sub_category: subCategory,
              button_route: trackerSubCategoryStatus.buttonRoute,
            });

            navigate(trackerSubCategoryStatus.buttonRoute);
          }}
          styles={{ alignSelf: "flex-end" }}
        />
      )}
    </Stack>
  );
};

export default TrackInformation;
