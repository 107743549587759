import { useEffect, useState, Suspense, useContext } from "react";
import { FormationSteps, getFormationSignupStepContent } from "./services";
import { useSearchParams } from "react-router-dom";
import { getTokens, setTokens } from "utils/auth";
import { useAuthSignInMutation } from "graphql/__generated__/operations/AuthSignIn.generated";
import { useAuthRefreshTokensMutation } from "graphql/__generated__/operations/AuthRefreshTokens.generated";
import { Stack, useMediaQuery } from "@mui/material";
import utils from "utils";
import SignupAside from "./SignupAside";
import { Header } from "components/PrePurchase/Header";
import { LoadingComponent } from "components/Loading";
import { ROUTES_TO_FORMATION_STEP, SignupContext } from "./SignupProvider";
import FlowStepper from "components/FlowStepper";

const SignupFlow = () => {
  const { formationStep } = useContext(SignupContext);

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  // layout that is used on Auth pages and registration with company origin
  // TODO: Uncomment for phase 1 with company origin
  // const flagmanLayout =
  //   formationStep === FormationSteps.Registration ||
  //   formationStep === FormationSteps.CompanyOrigin;

  const flagmanLayout = formationStep === FormationSteps.Registration;

  const showAsideBlock =
    !flagmanLayout &&
    formationStep !== FormationSteps.EntityType &&
    formationStep !== FormationSteps.Packages;

  return (
    <>
      {formationStep === FormationSteps.PurchaseSuccess ? (
        <Suspense fallback={<LoadingComponent />}>
          {getFormationSignupStepContent(formationStep)}
        </Suspense>
      ) : (
        <>
          {!flagmanLayout && <Header />}
          <Stack
            direction={isTablet ? "column" : "row"}
            gap={isTablet ? "0px" : "100px"}
          >
            <Stack
              width={showAsideBlock && !isTablet ? "65%" : "100%"}
              height="100%"
            >
              <Suspense fallback={<LoadingComponent />}>
                {!flagmanLayout && (
                  <Stack width={!showAsideBlock && !isTablet ? "60%" : "100%"}>
                    <FlowStepper
                      currentStep={formationStep}
                      flowRoutes={ROUTES_TO_FORMATION_STEP}
                    />
                  </Stack>
                )}
                {getFormationSignupStepContent(formationStep)}
              </Suspense>
            </Stack>
            {showAsideBlock && (
              <Stack width={isTablet ? "100%" : "35%"} mt={isTablet ? 3 : 7.5}>
                <SignupAside />
              </Stack>
            )}
          </Stack>
        </>
      )}
    </>
  );
};

const SignupFlowContainer = () => {
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [searchParams] = useSearchParams();

  const [authSignIn] = useAuthSignInMutation();
  const [authRefreshTokens] = useAuthRefreshTokensMutation();

  useEffect(() => {
    const customerEmail = searchParams.get("customerEmail");
    const { idToken, refreshToken } = getTokens();

    if (customerEmail) {
      const cognitoTempPass = customerEmail;

      const signInUser = async () => {
        const signInData = await authSignIn({
          variables: {
            credentials: { email: customerEmail!, password: cognitoTempPass },
          },
        });
        const signInTokens = signInData.data?.auth?.signIn?.tokens;

        if (signInTokens) {
          setTokens(signInTokens);
        }

        setIsCheckingAuth(false);
      };

      signInUser();
    } else if (idToken) {
      const checkAuth = async () => {
        // update {idToken} and {accessToken} if {refreshToken} is valid
        await authRefreshTokens({
          variables: {
            input: {
              idToken,
              refreshToken,
            },
          },
          // adding context here allows us to overwrite the headers in the AuthLink to use the headers specified here
          context: {
            isPublicMutation: true,
          },
        })
          .then(({ data }) => {
            setTokens(data?.auth?.refreshTokens?.tokens!);
            setIsCheckingAuth(false);
          })
          .catch((err) => {
            // sign the user in again if their {refreshToken} has expired
            console.error(err);
          });
      };

      checkAuth();
    } else {
      setIsCheckingAuth(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isCheckingAuth) {
    return null;
  }

  return <SignupFlow />;
};

export default SignupFlowContainer;
