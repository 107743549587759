import ButtonBox from "components/ButtonBox";
import { IStepProps } from "../../types";
import { TransferSteps } from "../../data";
import {
  PurposeCode,
  ReasonCode,
  SourceOfFunds,
  WalletTransferRequest,
} from "graphql/__generated__/types";
import { formatString } from "../../helpers";
import { Controller, useForm } from "react-hook-form";
import {
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FlagOutlined } from "@mui/icons-material";
import utils from "utils";
import { useTranslation } from "react-i18next";
import CustomSelect from "components/Form/CustomSelect";
import { Windows1252Regex } from "utils/regexValidations";
import { usePostHog } from "posthog-js/react";

const Details = ({
  setCurrentStep,
  setTransferData,
  transferData,
}: IStepProps) => {
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const { t } = useTranslation();
  const posthog = usePostHog();

  const { control, formState, handleSubmit } = useForm<WalletTransferRequest>({
    defaultValues: { ...transferData },
    mode: "onChange",
  });

  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  const onSubmitHandler = (transfer: WalletTransferRequest) => {
    posthog?.capture("money_transfer_flow_trans_details_next_btn_click");

    setTransferData({ ...transfer });
    setCurrentStep(TransferSteps.review);
  };

  const isDisabled = !formState.isValid;

  return (
    <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
      <Stack gap={5} mb={isTablet ? 9 : 0}>
        <Stack gap={4}>
          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
            <FlagOutlined sx={{ fontSize: "20px" }} />
            <Typography fontSize={16} fontWeight={500}>
              {t("money.transfer.step.details.transactionDetails")}
            </Typography>
          </Stack>
          <Stack direction={isTablet ? "column" : "row"} gap={isTablet ? 2 : 3}>
            <Controller
              name="purposeCode"
              {...COMMON_PROPS}
              rules={{
                pattern: {
                  value: Windows1252Regex,
                  message: t("error.invalidCharacters"),
                },
                required: true,
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomSelect
                  {...field}
                  placeholder={
                    t("money.transfer.step.details.purpose.placeholder") ?? ""
                  }
                  label={t("money.transfer.step.details.purpose.label") ?? ""}
                  error={error !== undefined}
                  styles={{ width: "100%" }}
                  defaultValue={field.value}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    const value = e.target.value;

                    field.onChange(value);
                  }}
                  renderValue={(selected) => formatString(selected as string)}
                >
                  {Object.values(PurposeCode).map((code, index) => (
                    <MenuItem key={index} value={code}>
                      {formatString(code)}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
            />
          </Stack>
          <Stack direction={isTablet ? "column" : "row"} gap={isTablet ? 2 : 3}>
            <Controller
              name="reasonCode"
              {...COMMON_PROPS}
              rules={{
                pattern: {
                  value: Windows1252Regex,
                  message: t("error.invalidCharacters"),
                },
                required: true,
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomSelect
                  {...field}
                  placeholder={
                    t("money.transfer.step.details.reason.placeholder") ?? ""
                  }
                  label={t("money.transfer.step.details.reason.label") ?? ""}
                  error={error !== undefined}
                  styles={{ width: "100%" }}
                  defaultValue={field.value}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    const value = e.target.value;

                    field.onChange(value);
                  }}
                  renderValue={(selected) =>
                    formatString(selected as string, true, false)
                  }
                >
                  {Object.values(ReasonCode).map((code, index) => (
                    <MenuItem key={index} value={code}>
                      {formatString(code, true, false)}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
            />
            <Controller
              name="sourceOfFunds"
              {...COMMON_PROPS}
              rules={{
                pattern: {
                  value: Windows1252Regex,
                  message: t("error.invalidCharacters"),
                },
                required: true,
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomSelect
                  {...field}
                  placeholder={
                    t("money.transfer.step.details.source.placeholder") ?? ""
                  }
                  label={t("money.transfer.step.details.source.label") ?? ""}
                  error={error !== undefined}
                  styles={{ width: "100%" }}
                  defaultValue={field.value}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    const value = e.target.value;

                    field.onChange(value);
                  }}
                  renderValue={(selected) =>
                    formatString(selected as string, false)
                  }
                >
                  {Object.values(SourceOfFunds).map((code, index) => (
                    <MenuItem key={index} value={code}>
                      {formatString(code, false)}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
            />
          </Stack>
        </Stack>
        <ButtonBox
          disabled={isDisabled}
          onClickBack={() => {
            posthog?.capture(
              "money_transfer_flow_trans_details_back_btn_click"
            );
            setCurrentStep(TransferSteps.amount);
          }}
          onClickNext={() => onSubmitHandler}
        />
      </Stack>
    </form>
  );
};

export default Details;
