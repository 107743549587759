import {
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ButtonBox from "components/ButtonBox";
import utils, { colors } from "utils";
import { Controller, useForm } from "react-hook-form";
import { Windows1252Regex } from "utils/regexValidations";
import CustomSelect from "components/Form/CustomSelect";
import CustomInput from "components/Form/CustomInput";
import { t } from "i18next";
import {
  ISubStepProps,
  companySubSteps,
  isManagedAddressKey,
} from "../../types";
import { CorporateFareOutlined, EmailOutlined } from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { INITIAL_ADDRESS_VALUES } from "../../helpers/initialAddressData";
import { LoadingComponent } from "components/Loading";
import {
  LookupTypeKey,
  WalletAddressInput,
  WalletOnboard,
} from "graphql/__generated__/types";
import FormControlElement from "views/SignupFlow/components/FormControlElement";
import useOnboardingData from "views/DashboardApp/Money/Onboarding/hooks/useOnboardingData";
import { useSetMoneyOnboardingMutation } from "graphql/__generated__/operations/MoneyOnboardingValidation.generated";
import ErrorBox from "components/ErrorBox";
import { removeTypename } from "utils/helpers/removeTypename";
import useGetRaAddress from "views/DashboardApp/Money/Onboarding/hooks/useGetRaAddresses";
import { US_KEY, isValueValid } from "views/DashboardApp/Money/helpers";
import useGetMoneyLookup from "views/DashboardApp/Money/hooks/useGetMoneyLookup";
import { usePostHog } from "posthog-js/react";

const RegisteredAddress = ({
  setCurrentSubStep,
  setShowHeading,
}: ISubStepProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { onboardingData, isOnboardingDataLoading } = useOnboardingData();
  const { lookupData: statesData, isLookupDataLoading } = useGetMoneyLookup({
    category: LookupTypeKey.STATE,
  });
  const { raAddressData, isRaAddressDataLoading } = useGetRaAddress();
  const [updateMoneyOnboarding] = useSetMoneyOnboardingMutation();

  const [isManagedAddress, setIsManagedAddress] = useState(
    JSON.parse(localStorage.getItem(isManagedAddressKey) ?? "true")
  );

  const { control, formState, setValue, handleSubmit, trigger } =
    useForm<WalletAddressInput>({
      defaultValues: { ...INITIAL_ADDRESS_VALUES },
      mode: "onChange",
    });

  const posthog = usePostHog();

  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  const filteredOnboardingData: WalletOnboard = useMemo(
    () => removeTypename(onboardingData),
    [onboardingData]
  );

  useEffect(() => {
    setShowHeading(false);

    return () => {
      setShowHeading(true);
    };
  }, [setShowHeading]);

  useEffect(() => {
    const isSameBusinessAddress =
      filteredOnboardingData?.isSameBusinessAddress ?? false;
    setIsManagedAddress(isSameBusinessAddress);
  }, [filteredOnboardingData?.isSameBusinessAddress]);

  useEffect(() => {
    const registeredAddressData = isManagedAddress
      ? {
          ...raAddressData,
          country: US_KEY,
          state: raAddressData?.stateAbbreviation,
        }
      : filteredOnboardingData?.companyInfo?.address;

    posthog?.capture(
      isManagedAddress
        ? "money_onboarding_doola_manage_ra"
        : "money_onboarding_own_ra"
    );

    if (registeredAddressData) {
      setValue("address1", registeredAddressData.address1 || "");
      setValue("address2", registeredAddressData.address2 || "");
      setValue("city", registeredAddressData.city || "");
      setValue("country", US_KEY);
      setValue("postalCode", registeredAddressData.postalCode || "");
      setValue("state", registeredAddressData.state || "");

      if (
        isValueValid(registeredAddressData.address2) &&
        isValueValid(registeredAddressData.postalCode) &&
        isValueValid(registeredAddressData.city) &&
        isValueValid(registeredAddressData.country) &&
        isValueValid(registeredAddressData.address1) &&
        isValueValid(registeredAddressData.state)
      ) {
        trigger();
      }
    }
  }, [
    setValue,
    trigger,
    raAddressData,
    isManagedAddress,
    filteredOnboardingData?.companyInfo?.address,
    posthog,
  ]);

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  if (
    !statesData ||
    isLookupDataLoading ||
    !onboardingData ||
    isOnboardingDataLoading ||
    isRaAddressDataLoading
  ) {
    return (
      <Stack height={"200px"} alignItems={"center"} justifyContent={"center"}>
        <LoadingComponent />
      </Stack>
    );
  }

  const onSubmitHandler = async (data: WalletAddressInput) => {
    posthog?.capture("money_onboarding_registered_address_next_btn_click");

    try {
      setIsLoading(true);

      await updateMoneyOnboarding({
        variables: {
          ...filteredOnboardingData,
          companyInfo: {
            ...filteredOnboardingData?.companyInfo,
            address: data,
          },
          isSameBusinessAddress: isManagedAddress,
        },
      });

      setCurrentSubStep(companySubSteps.einLetter);
    } finally {
      setIsLoading(false);
    }
  };

  const isDisabled = !formState.isValid;

  return (
    <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
      <Stack direction={"row"} alignItems={"center"} gap={1.5}>
        <EmailOutlined />
        <Typography fontSize={16} fontWeight={500}>
          {t("money.onboarding.step.company.registeredAddress.title")}
        </Typography>
      </Stack>
      <Stack gap={5} mt={4} mb={isTablet ? 2 : 0}>
        <Stack direction={isTablet ? "column" : "row"} gap={3} width={"100%"}>
          <FormControlElement
            isDisabled={!raAddressData}
            outerStyles={{ width: "100%" }}
            optionValue={true}
            selectedValue={isManagedAddress}
            changeHandler={() => {
              localStorage.setItem(isManagedAddressKey, "true");
              setIsManagedAddress(true);
            }}
            content={
              <Stack
                width={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={1.5}
              >
                <Stack
                  borderRadius={"50%"}
                  bgcolor={!isManagedAddress ? colors.grey : colors.borderBlue}
                  p={2}
                >
                  <CorporateFareOutlined />
                </Stack>
                <Typography fontWeight={500} fontSize={16} textAlign={"center"}>
                  {t(
                    "money.onboarding.step.company.registeredAddress.type.managed"
                  )}
                </Typography>
              </Stack>
            }
          />
          <FormControlElement
            outerStyles={{ width: "100%" }}
            optionValue={false}
            selectedValue={isManagedAddress}
            changeHandler={() => {
              localStorage.setItem(isManagedAddressKey, "false");
              setIsManagedAddress(false);
            }}
            content={
              <Stack
                width={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={1.5}
              >
                <Stack
                  borderRadius={"50%"}
                  bgcolor={!isManagedAddress ? colors.borderBlue : colors.grey}
                  p={2}
                >
                  <CorporateFareOutlined />
                </Stack>
                <Typography fontWeight={500} fontSize={16} textAlign={"center"}>
                  {t(
                    "money.onboarding.step.company.registeredAddress.type.custom"
                  )}
                </Typography>
              </Stack>
            }
          />
        </Stack>
        <Stack gap={2}>
          <Stack direction={isTablet ? "column" : "row"} gap={3}>
            <Controller
              name="address1"
              {...COMMON_PROPS}
              rules={{
                pattern: {
                  value: Windows1252Regex,
                  message: t("error.invalidCharacters"),
                },
                required: !isManagedAddress,
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  disabled={isManagedAddress}
                  error={error !== undefined}
                  styles={{ width: "100%" }}
                  placeholder={
                    t(
                      "money.onboarding.step.company.registeredAddress.address1.placeholder"
                    ) as string
                  }
                  label={
                    t(
                      "money.onboarding.step.company.registeredAddress.address1"
                    ) as string
                  }
                />
              )}
            />
            <Controller
              name="address2"
              {...COMMON_PROPS}
              rules={{
                pattern: {
                  value: Windows1252Regex,
                  message: t("error.invalidCharacters"),
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  disabled={isManagedAddress}
                  error={error !== undefined}
                  styles={{ width: "100%" }}
                  placeholder={
                    t(
                      "money.onboarding.step.company.registeredAddress.address2.placeholder"
                    ) as string
                  }
                  label={
                    t(
                      "money.onboarding.step.company.registeredAddress.address2"
                    ) as string
                  }
                />
              )}
            />
          </Stack>
          <Stack direction={isTablet ? "column" : "row"} gap={3}>
            <Controller
              name="country"
              {...COMMON_PROPS}
              rules={{
                pattern: {
                  value: Windows1252Regex,
                  message: t("error.invalidCharacters"),
                },
                required: !isManagedAddress,
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  disabled={true}
                  error={error !== undefined}
                  styles={{ width: "100%" }}
                  value={"United States of America"}
                  label={
                    t(
                      "money.onboarding.step.company.registeredAddress.country"
                    ) as string
                  }
                />
              )}
            />
            <Controller
              name="city"
              {...COMMON_PROPS}
              rules={{
                pattern: {
                  value: Windows1252Regex,
                  message: t("error.invalidCharacters"),
                },
                required: !isManagedAddress,
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  disabled={isManagedAddress}
                  error={error !== undefined}
                  styles={{ width: "100%" }}
                  placeholder={
                    t(
                      "money.onboarding.step.company.registeredAddress.city.placeholder"
                    ) as string
                  }
                  label={
                    t(
                      "money.onboarding.step.company.registeredAddress.city"
                    ) as string
                  }
                />
              )}
            />
          </Stack>
          <Stack direction={isTablet ? "column" : "row"} gap={3}>
            <Controller
              name="state"
              {...COMMON_PROPS}
              rules={{
                pattern: {
                  value: Windows1252Regex,
                  message: t("error.invalidCharacters"),
                },
                required: !isManagedAddress,
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomSelect
                  {...field}
                  disabled={isManagedAddress}
                  error={error !== undefined}
                  styles={{ width: "100%" }}
                  placeholder={
                    t(
                      "money.onboarding.step.company.registeredAddress.state.placeholder"
                    ) as string
                  }
                  label={
                    t(
                      "money.onboarding.step.company.registeredAddress.state"
                    ) as string
                  }
                  defaultValue={field.value}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    field.onChange(e.target.value);
                  }}
                  renderValue={(selected) => {
                    const selectedState = statesData.find(
                      (item) => item?.key === selected
                    );

                    return selectedState?.description || "";
                  }}
                >
                  {statesData.map((state, index) => (
                    <MenuItem key={index} value={state?.key || ""}>
                      {state?.description || ""}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
            />
            <Controller
              name="postalCode"
              {...COMMON_PROPS}
              rules={{
                pattern: {
                  value: Windows1252Regex,
                  message: t("error.invalidCharacters"),
                },
                required: !isManagedAddress,
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  disabled={isManagedAddress}
                  error={error !== undefined}
                  styles={{ width: "100%" }}
                  placeholder={
                    t(
                      "money.onboarding.step.company.registeredAddress.postalCode.placeholder"
                    ) as string
                  }
                  label={
                    t(
                      "money.onboarding.step.company.registeredAddress.postalCode"
                    ) as string
                  }
                />
              )}
            />
          </Stack>
        </Stack>
        <ErrorBox formState={formState} />
        <ButtonBox
          isLoading={isLoading}
          disabled={isDisabled}
          onClickBack={() => {
            posthog?.capture(
              "money_onboarding_registered_address_back_btn_click"
            );

            setCurrentSubStep(companySubSteps.companyInformation);
          }}
          onClickNext={() => onSubmitHandler}
        />
      </Stack>
    </form>
  );
};

export default RegisteredAddress;
