import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCompanyDescriptionMutationVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  description: Types.Scalars['String'];
}>;


export type UpdateCompanyDescriptionMutation = { __typename?: 'Mutation', company?: { __typename?: 'CompanyMutation', updateDescription?: { __typename?: 'Company', id: string, description?: string | null, services?: Array<{ __typename?: 'Services', service: Types.CompanyServices, variant?: Types.ServiceVariant | null, status: Types.ServiceCategory, subStatus?: string | null }> | null } | null } | null };


export const UpdateCompanyDescriptionDocument = gql`
    mutation UpdateCompanyDescription($companyId: ID!, $description: String!) {
  company(id: $companyId) {
    updateDescription(new: $description) {
      id
      description
      services {
        service
        variant
        status
        subStatus
      }
    }
  }
}
    `;
export type UpdateCompanyDescriptionMutationFn = Apollo.MutationFunction<UpdateCompanyDescriptionMutation, UpdateCompanyDescriptionMutationVariables>;

/**
 * __useUpdateCompanyDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyDescriptionMutation, { data, loading, error }] = useUpdateCompanyDescriptionMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateCompanyDescriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyDescriptionMutation, UpdateCompanyDescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyDescriptionMutation, UpdateCompanyDescriptionMutationVariables>(UpdateCompanyDescriptionDocument, options);
      }
export type UpdateCompanyDescriptionMutationHookResult = ReturnType<typeof useUpdateCompanyDescriptionMutation>;
export type UpdateCompanyDescriptionMutationResult = Apollo.MutationResult<UpdateCompanyDescriptionMutation>;
export type UpdateCompanyDescriptionMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyDescriptionMutation, UpdateCompanyDescriptionMutationVariables>;