import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCompanyStateMutationVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  state: Types.Scalars['String'];
}>;


export type UpdateCompanyStateMutation = { __typename?: 'Mutation', company?: { __typename?: 'CompanyMutation', updateState?: { __typename?: 'Company', state?: Types.Iso31662Us | null, id: string } | null } | null };


export const UpdateCompanyStateDocument = gql`
    mutation UpdateCompanyState($companyId: ID!, $state: String!) {
  company(id: $companyId) {
    updateState(new: $state) {
      state
      id
    }
  }
}
    `;
export type UpdateCompanyStateMutationFn = Apollo.MutationFunction<UpdateCompanyStateMutation, UpdateCompanyStateMutationVariables>;

/**
 * __useUpdateCompanyStateMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyStateMutation, { data, loading, error }] = useUpdateCompanyStateMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateCompanyStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyStateMutation, UpdateCompanyStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyStateMutation, UpdateCompanyStateMutationVariables>(UpdateCompanyStateDocument, options);
      }
export type UpdateCompanyStateMutationHookResult = ReturnType<typeof useUpdateCompanyStateMutation>;
export type UpdateCompanyStateMutationResult = Apollo.MutationResult<UpdateCompanyStateMutation>;
export type UpdateCompanyStateMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyStateMutation, UpdateCompanyStateMutationVariables>;