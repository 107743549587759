import { format as formatDate } from "fecha";

export const capitalizedSentence = (mySentence: string) => {
  if (!mySentence) return "";

  return mySentence.replace(/(^|\s)\S/g, (match) => match.toUpperCase());
};

export const getInitials = (myString: string) => {
  if (!myString) return { children: "DO" };

  const initials = myString
    .split(" ")
    .map(
      (name, index, arr) => (index === 0 || index === arr.length - 1) && name[0]
    )
    .filter((initial) => initial)
    .join("");

  return { children: initials || "DO" };
};

export const colorWithOpacity = (color: string, opacity: number) =>
  color + opacity;

export const moneyFormatter = (
  value: number,
  round: boolean = true,
  hideSymbol: boolean = false,
  currency: string = "USD"
) => {
  const options: Intl.NumberFormatOptions = {
    style: "currency",
    currency,
    maximumFractionDigits: round ? 0 : 2,
    minimumFractionDigits: round ? 0 : 2,
    currencyDisplay: hideSymbol ? "code" : "symbol", // Adjusted to respect currency sign position
  };

  const formattedValue = new Intl.NumberFormat("en-US", options).format(
    value ? value : 0
  );

  return formattedValue;
};

// Function to format date to user's local timezone
export const formatToLocalTimezone = (date: Date, format: string): string => {
  // Get the user's timezone offset in milliseconds
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;

  // Adjust the date to the user's timezone
  const userDate = new Date(date.getTime() - userTimezoneOffset);

  return formatDate(userDate, format);
};
