import { useEffect, useState } from "react";
import { useGetMoneyTransactionsLazyQuery } from "graphql/__generated__/operations/MoneyTransactions.generated";
import useCompany from "graphql/hooks/UseCompany";

const useGetTransactions = ({ offset = 5, page = 0 } = {}) => {
  const { currentCompany } = useCompany();

  const [isTransactionsDataLoading, setIsTransactionsDataLoading] =
    useState<boolean>(true);

  const [fetchTransactions, { data, refetch }] =
    useGetMoneyTransactionsLazyQuery();

  useEffect(() => {
    setIsTransactionsDataLoading(true);

    if (currentCompany?.id) {
      const getTransactions = async () => {
        try {
          await fetchTransactions({
            variables: {
              companyId: currentCompany.id,
              offset: offset.toString(),
              page: page.toString(),
            },
          });
        } catch (error) {
          console.error("Failed to fetch transactions, error: ", error);
        } finally {
          setIsTransactionsDataLoading(false);
        }
      };

      getTransactions();
    }
  }, [currentCompany?.id, fetchTransactions, offset, page]);

  return {
    transactions: data?.transactions?.payload,
    refetchTransactions: refetch,
    isTransactionsLoading: isTransactionsDataLoading,
  };
};

export default useGetTransactions;
