import styled from "styled-components";
import utils, { colors } from "utils";

export const StepWrapper = styled.div`
  border-bottom: 1px solid ${colors.charcoal}10;
  padding-bottom: 18px;
  margin-bottom: 18px;
  position: relative;
`;

export const StatusLabelWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 5px;

  @media only screen and (max-width: ${utils.screenSize.mobile}) {
    position: absolute;
    right: -26px;
    top: 0px;
    transform: scale(0.8);
  }
`;
