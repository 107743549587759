import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCompanyEntityTypeMutationVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  entityType: Types.EntityType;
}>;


export type UpdateCompanyEntityTypeMutation = { __typename?: 'Mutation', company?: { __typename?: 'CompanyMutation', updateEntityType?: { __typename?: 'Company', id: string, entityType?: Types.EntityType | null } | null } | null };


export const UpdateCompanyEntityTypeDocument = gql`
    mutation UpdateCompanyEntityType($companyId: ID!, $entityType: EntityType!) {
  company(id: $companyId) {
    updateEntityType(new: $entityType) {
      id
      entityType
    }
  }
}
    `;
export type UpdateCompanyEntityTypeMutationFn = Apollo.MutationFunction<UpdateCompanyEntityTypeMutation, UpdateCompanyEntityTypeMutationVariables>;

/**
 * __useUpdateCompanyEntityTypeMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyEntityTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyEntityTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyEntityTypeMutation, { data, loading, error }] = useUpdateCompanyEntityTypeMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      entityType: // value for 'entityType'
 *   },
 * });
 */
export function useUpdateCompanyEntityTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyEntityTypeMutation, UpdateCompanyEntityTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyEntityTypeMutation, UpdateCompanyEntityTypeMutationVariables>(UpdateCompanyEntityTypeDocument, options);
      }
export type UpdateCompanyEntityTypeMutationHookResult = ReturnType<typeof useUpdateCompanyEntityTypeMutation>;
export type UpdateCompanyEntityTypeMutationResult = Apollo.MutationResult<UpdateCompanyEntityTypeMutation>;
export type UpdateCompanyEntityTypeMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyEntityTypeMutation, UpdateCompanyEntityTypeMutationVariables>;