import { Box, Button, Stack, useMediaQuery } from "@mui/material";
import SupportIcon from "@mui/icons-material/Support";
import utils, { colors } from "utils";
import Notification from "components/Notification";
import CompanySelector from "./CompanySelector";
import Profile from "views/DashboardApp/Layout/Header/HeaderContent/Profile";
import { useIntercom } from "react-use-intercom";

const UserControlHub = () => {
  const { show: showIntercom } = useIntercom();
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  return (
    <Box p={isTablet ? 2 : 3}>
      <CompanySelector />
      <Stack direction={"row"} gap={1} mt={1}>
        <Profile popperPlacement="bottom-start" />
        <Button
          disableRipple
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 1,
            py: 2,
            borderRadius: 4,
            backgroundColor: colors.lightGrey,
            "&:hover svg": {
              color: colors.contentInverseTertiary,
              transition: "all .2s ease-in-out",
            },
          }}
          onClick={showIntercom}
        >
          <SupportIcon
            sx={{
              color: colors.black,
            }}
          />
        </Button>
        <Notification />
      </Stack>
    </Box>
  );
};

export default UserControlHub;
