export const ROUTER_PATHS = {
  LOGIN: "/login",
  RECOVER_PASSWORD: "/recover-password",
  RESET_PASSWORD: "/reset-password",
  DASHBOARD: "/dashboard",
  MODAL_FEEDBACK: "feedback-survey",
  DOCUMENTS: "/documents",
  TAXES: "/taxes",
  SERVICES: "/services",
  REFERRALS: "/referrals",
  SETTINGS: "/settings",
  BOOKS: "/books",
  BOOKS_INVOICES: "/books-invoices",
  BOOKS_EXPENSES: "/books-expenses",
  BOOKS_INCOME: "/books-income",
  MONEY: "/money",

  MONEY_OVERVIEW: "/money/overview",
  MONEY_ONBOARDING: "/money/onboarding",
  MONEY_TRANSACTIONS: "/money/transactions",
  MONEY_TRANSFER: "/money/transfer",
  MONEY_SEND: "/money/send",
  MONEY_BENEFICIARY: "/money/beneficiary",

  ONBOARDING_INTRO: "/onboarding-intro",
  ONBOARDING_COMPANY_NAME: "/onboarding-company-name",
  ONBOARDING_ADDRESS: "/onboarding-address",
  ONBOARDING_INDUSTRY: "/onboarding-industry",
  ONBOARDING_DESCRIPTION: "/onboarding-description",
  ONBOARDING_MEMBERS: "/onboarding-members",
  ONBOARDING_RESPONSIBLE_PARTY: "/onboarding-responsible-party",
  ONBOARDING_OWNERSHIP: "/onboarding-ownership",
  ONBOARDING_REVIEW: "/onboarding-review",

  ONBOARDING_EXECUTIVE: "/onboarding-executive",
  ONBOARDING_SHARE: "/onboarding-share",

  PRE_PURCHASE_REGISTRATION: "/signup/registration",
  // TODO: Uncomment for phase 1 with company origin
  // PRE_PURCHASE_COMPANY_ORIGIN: "/signup/company-origin",
  PRE_PURCHASE_COUNTRY_OF_RESIDENCE: "/signup/country-of-residence",
  PRE_PURCHASE_ENTITY_TYPE: "/signup/entity-type",
  PRE_PURCHASE_COMPANY_STATE: "/signup/company-state",
  PRE_PURCHASE_COMPANY_NAME: "/signup/company-name",
  PRE_PURCHASE_PACKAGES: "/signup/packages",
  PRE_PURCHASE_EXPEDITED: "/signup/expedited",
  PRE_PURCHASE_BOI_FILING: "/signup/boi-filing",
  PRE_PURCHASE_REVIEW: "/signup/review",
  PRE_PURCHASE_SUCCESS: "/signup/success",
  PRE_PURCHASE_TC: "/signup/total-compliance",
};
