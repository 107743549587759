import {
  CorporateFareOutlined,
  FormatListBulletedRounded,
  PeopleOutlined,
  HelpOutlineRounded,
  PersonOutlined,
} from "@mui/icons-material";

// Define step categories
export enum OnboardingStepsCategories {
  prepareInfo = "prepareInfo",
  companyInformation = "companyInformation",
  membersInformation = "membersInformation",
  applicantInformation = "applicantInformation",
  riskAssessmentInformation = "riskAssessmentInformation",
  readyToSend = "readyToSend",
}

// Define step type
export interface IOnboardingStep {
  title?: string;
  icon?: React.ReactElement;
  description?: string;
}

export const OnboardingStepsData: Record<
  OnboardingStepsCategories,
  IOnboardingStep
> = {
  [OnboardingStepsCategories.prepareInfo]: {
    title: "money.onboarding.step.prepareInfo.title",
    icon: <FormatListBulletedRounded />,
  },

  [OnboardingStepsCategories.companyInformation]: {
    title: "money.onboarding.step.companyInformation.title",
    icon: <CorporateFareOutlined />,
  },
  [OnboardingStepsCategories.membersInformation]: {
    title: "money.onboarding.step.membersInformation.title",
    icon: <PeopleOutlined />,
    description: "money.onboarding.step.membersInformation.description",
  },
  [OnboardingStepsCategories.applicantInformation]: {
    title: "money.onboarding.step.applicantDetails.title",
    icon: <PersonOutlined />,
    description: "money.onboarding.step.applicantDetails.description",
  },
  [OnboardingStepsCategories.riskAssessmentInformation]: {
    title: "money.onboarding.step.riskAssessmentInformation.title",
    icon: <HelpOutlineRounded />,
  },
  [OnboardingStepsCategories.readyToSend]: {},
};
