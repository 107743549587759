import { FetchResult, ServerError } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { Observable } from "@apollo/client/utilities";
import * as Sentry from "@sentry/react";
import config from "config";
import { ROUTER_PATHS } from "routes/routes";
import { getTokens, removeTokens, setTokens } from "utils/auth";
import { toast } from "toast";
import { AuthRefreshTokensMutation } from "graphql/__generated__/operations/AuthRefreshTokens.generated";
import { GraphQLError } from "graphql";

const NETWORK_UNAUTHORIZED_STATUS_CODE = 401;
const GENERIC_ERROR_MESSAGE = "Something went wrong. Please try again.";

enum WebApiErrorCodes {
  application = "APPLICATION_ERROR",
  unauthorized = "UNAUTHORIZED_ERROR",
  validation = "VALIDATION_ERROR",
}

enum WebApiErrorMessages {
  emailTaken = "Email is already taken",
}

export type WebApiError = GraphQLError & {
  message: string;
  path: string[];
  extensions: {
    code: WebApiErrorCodes;
  };
};

const ErrorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    const graphQLErr = graphQLErrors?.[0] as WebApiError;
    const hasGraphQLUnauthorizedErr =
      graphQLErr?.extensions?.code === WebApiErrorCodes.unauthorized;
    const hasNetworkUnauthorizedErr =
      (networkError as ServerError)?.statusCode ===
      NETWORK_UNAUTHORIZED_STATUS_CODE;
    const hasGraphQLUUnhandledErr =
      graphQLErr?.extensions?.code === WebApiErrorCodes.application;

    if (hasGraphQLUnauthorizedErr || hasNetworkUnauthorizedErr) {
      return new Observable<FetchResult<Record<string, any>>>((observer) => {
        const fetchNewTokenAndRetry = async () => {
          const tokens = getTokens();

          try {
            // refreshes the users token if they have a valid refresh token
            fetch(config.api.GRAPHQL_URL, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer ", // empty auth header expected by the backend
              },
              body: JSON.stringify({
                query: `mutation AuthRefreshTokens($input: RefreshTokensInput!) {
                          auth {
                            refreshTokens(input: $input) {
                              success
                              message
                              tokens {
                                accessToken
                                idToken
                                refreshToken
                              }
                            }
                          }
                        }`,
                variables: {
                  input: {
                    idToken: tokens?.idToken!,
                    refreshToken: tokens?.refreshToken!,
                  },
                },
              }),
            })
              .then((res) => res.json())
              .then(({ data }: { data: AuthRefreshTokensMutation }) => {
                setTokens(data?.auth?.refreshTokens?.tokens!);

                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };

                // Retry the failed request now that there is a new token
                forward(operation).subscribe(subscriber);
              })
              .catch((err) => {
                // if there is no valid refresh token (>30 days) then redirect the user to login again
                window.location.replace(
                  `${config.basePath}${ROUTER_PATHS.LOGIN}`
                );
                removeTokens();
              });
          } catch (err) {
            observer.error(err);
          }
        };

        fetchNewTokenAndRetry();
      });
    }

    if (graphQLErrors)
      graphQLErrors.forEach((error: any) => {
        // log the error in the console so that we can see it in PostHog
        console.error(error);

        if (typeof error === "object") {
          if (hasGraphQLUUnhandledErr) {
            toast.error(error.message || GENERIC_ERROR_MESSAGE);
          } else {
            const emailTakenError = WebApiErrorMessages.emailTaken;

            if (error.message === emailTakenError) {
              toast.error(
                "This email is taken. Please choose a different one, or if you own this email, attempt to log in."
              );
            } else {
              toast.error(GENERIC_ERROR_MESSAGE);
            }
          }

          Sentry.captureException(error.message);
        } else {
          Sentry.captureException(error);
          toast.error(GENERIC_ERROR_MESSAGE);
        }
      });

    if (networkError) {
      // do nothing with network errors
    }
  }
);

export default ErrorLink;
