import ButtonBox from "components/ButtonBox";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import utils, { colors } from "utils";
import {
  BusinessCenterOutlined,
  CorporateFareOutlined,
  PersonOutlined,
} from "@mui/icons-material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { IMemberStepsProps, MemberTypes } from "../types";
import FormControlElement from "views/SignupFlow/components/FormControlElement";

interface IMemberTypeProps extends IMemberStepsProps {
  setMemberType: Dispatch<SetStateAction<MemberTypes | undefined>>;
}

const MemberType = ({
  resetData,
  setMemberType,
  setShowHeading,
}: IMemberTypeProps) => {
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const [isCompany, setIsCompany] = useState(false);

  useEffect(() => {
    setShowHeading(false);

    return () => {
      setShowHeading(true);
    };
  }, [setShowHeading]);

  return (
    <>
      <Stack mb={3}>
        <Stack direction={"row"} alignItems={"center"} gap={1.5}>
          <BusinessCenterOutlined />
          <Typography fontSize={16} fontWeight={500}>
            Member type
          </Typography>
        </Stack>
        <Typography fontSize={16} color={colors.contentTertiary} mt={0.5}>
          Decide whether the member you're adding is a person (Individual
          member) or a business entity (Company member).
        </Typography>
      </Stack>
      <Stack mb={isTablet ? 9 : 3}>
        <Stack direction={isTablet ? "column" : "row"} gap={3} width={"100%"}>
          <FormControlElement
            outerStyles={{ width: "100%" }}
            optionValue={false}
            selectedValue={isCompany}
            changeHandler={() => setIsCompany(false)}
            content={
              <Stack
                width={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={1.5}
              >
                <Stack
                  borderRadius={"50%"}
                  bgcolor={isCompany ? colors.grey : colors.borderBlue}
                  p={2}
                >
                  <PersonOutlined />
                </Stack>
                Individual
              </Stack>
            }
          />
          <FormControlElement
            outerStyles={{ width: "100%" }}
            optionValue={true}
            selectedValue={isCompany}
            changeHandler={() => setIsCompany(true)}
            content={
              <Stack
                width={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={1.5}
              >
                <Stack
                  borderRadius={"50%"}
                  bgcolor={isCompany ? colors.borderBlue : colors.grey}
                  p={2}
                >
                  <CorporateFareOutlined />
                </Stack>
                Company
              </Stack>
            }
          />
        </Stack>
      </Stack>
      <ButtonBox
        onClickBack={() => {
          resetData();
        }}
        onClickNext={() => {
          setMemberType(isCompany ? MemberTypes.COMPANY : MemberTypes.PERSON);
        }}
      />
    </>
  );
};

export default MemberType;
