import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetStripeSessionDetailsQueryVariables = Types.Exact<{
  sessionId: Types.Scalars['String'];
}>;


export type GetStripeSessionDetailsQuery = { __typename?: 'Query', stripeSessionDetails?: { __typename?: 'StripeSessionDetails', id: string, paymentIntentId?: string | null, amountTotal?: number | null, amountSubtotal?: number | null, status?: Types.StripeSessionStatus | null } | null };


export const GetStripeSessionDetailsDocument = gql`
    query GetStripeSessionDetails($sessionId: String!) {
  stripeSessionDetails(sessionId: $sessionId) {
    id
    paymentIntentId
    amountTotal
    amountSubtotal
    status
  }
}
    `;

/**
 * __useGetStripeSessionDetailsQuery__
 *
 * To run a query within a React component, call `useGetStripeSessionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeSessionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeSessionDetailsQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGetStripeSessionDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetStripeSessionDetailsQuery, GetStripeSessionDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripeSessionDetailsQuery, GetStripeSessionDetailsQueryVariables>(GetStripeSessionDetailsDocument, options);
      }
export function useGetStripeSessionDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripeSessionDetailsQuery, GetStripeSessionDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripeSessionDetailsQuery, GetStripeSessionDetailsQueryVariables>(GetStripeSessionDetailsDocument, options);
        }
export type GetStripeSessionDetailsQueryHookResult = ReturnType<typeof useGetStripeSessionDetailsQuery>;
export type GetStripeSessionDetailsLazyQueryHookResult = ReturnType<typeof useGetStripeSessionDetailsLazyQuery>;
export type GetStripeSessionDetailsQueryResult = Apollo.QueryResult<GetStripeSessionDetailsQuery, GetStripeSessionDetailsQueryVariables>;