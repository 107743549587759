import { Divider, Stack, Typography } from "@mui/material";
import { StyledEditButton } from "components/StyledEditButton";
import { usePostHog } from "posthog-js/react";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import {
  selectBoiFilingFormationPackage,
  selectFormationPackage,
} from "state/formationFormSlice";
import { useAppSelector } from "state/hooks";
import { colors } from "utils";
import { moneyFormatter } from "utils/formatters";
import { TOTAL_COMPLIANCE } from "views/SignupFlow/Packages/data";

const BoiFiling = () => {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const selectedFormationPackage = useAppSelector(selectFormationPackage);
  const selectedBoiFilingFormationPackage = useAppSelector(
    selectBoiFilingFormationPackage
  );
  const isTotalCompliancePackage =
    selectedFormationPackage?.name === TOTAL_COMPLIANCE.value;

  return (
    <Stack spacing={2} bgcolor={colors.lightGrey} p={3} borderRadius="24px">
      {!isTotalCompliancePackage ? (
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            justifyContent="space-between"
            width="100%"
          >
            <Typography variant="h5">BOI Filing</Typography>
            <StyledEditButton
              onClick={() => {
                posthog?.capture("company-pre-purchase-review-edit", {
                  review_edit: ROUTER_PATHS.PRE_PURCHASE_BOI_FILING,
                });
                navigate(ROUTER_PATHS.PRE_PURCHASE_BOI_FILING);
              }}
            />
          </Stack>
        </Stack>
      ) : (
        <Typography variant="h5">BOI Filing</Typography>
      )}
      <Stack direction="row" justifyContent="space-between">
        <Typography>Billing</Typography>
        <Typography color={colors.contentSecondary}>
          {isTotalCompliancePackage ? "Included in Package" : "One-time"}
        </Typography>
      </Stack>
      <Divider />
      <Stack direction="row" justifyContent="flex-end">
        <Typography variant="h5" fontWeight={500}>
          {moneyFormatter(
            isTotalCompliancePackage
              ? 0
              : selectedBoiFilingFormationPackage?.price || 0,
            false
          )}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default BoiFiling;
