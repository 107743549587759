import { Box, Typography } from "@mui/material";
import { IconWrapper, ItemWrapper } from "./styles";
import { colors } from "utils";

type Props = {
  $isSelected: boolean;
  sidebarItem: {
    icon?: JSX.Element;
    label: string;
  };
};

export default function Item({ $isSelected, sidebarItem }: Props) {
  return (
    <ItemWrapper key={sidebarItem.label} $isSelected={$isSelected}>
      <IconWrapper $isSelected={$isSelected}>{sidebarItem.icon}</IconWrapper>
      <Box style={{ margin: "auto 0px" }} p={1}>
        <Typography
          variant="body1"
          fontWeight={500}
          color={$isSelected ? colors.black : colors.contentTertiary}
        >
          {sidebarItem.label}
        </Typography>
      </Box>
    </ItemWrapper>
  );
}
