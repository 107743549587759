import ButtonBox from "components/ButtonBox";
import { IStepProps } from "../../types";
import { TransferSteps } from "../../data";
import { Avatar, Stack, Typography, useMediaQuery } from "@mui/material";
import {
  AccountBalanceOutlined,
  EditOutlined,
  Person2Outlined,
  SettingsOutlined,
  TollOutlined,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import utils, { colors } from "utils";
import {
  TransferMethodEnum,
  TransferMethods,
  USD_CURRENCY_KEY,
  USD_CURRENCY_NAME,
  getTransferMethod,
  maskSensitiveData,
} from "views/DashboardApp/Money/helpers";
import { MemoizedAvatar } from "views/DashboardApp/Money/components/Avatar";
import Button from "components/Button";
import {
  BeneficiaryDetails,
  PayoutMethodType,
} from "graphql/__generated__/types";
import { useEffect, useMemo, useState } from "react";
import { removeTypename } from "utils/helpers/removeTypename";
import AddBeneficiary from "views/DashboardApp/Money/Beneficiary/AddBeneficiary";
import useGetExchangeRate from "views/DashboardApp/Money/hooks/useGetExchangeRate";
import useGetCurrency from "views/DashboardApp/Money/hooks/useGetCurrency";
import useGetBeneficiary from "views/DashboardApp/Money/hooks/useGetBeneficiary";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoadingComponent } from "components/Loading";
import { ROUTER_PATHS } from "routes/routes";
import { usePostHog } from "posthog-js/react";
import AlertNotification from "components/AlertNotification";

const TransferInfo = ({ setCurrentStep }: IStepProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const posthog = usePostHog();

  const [currencyName, setCurrencyName] = useState(USD_CURRENCY_NAME);
  const [beneficiaryData, setBeneficiaryData] = useState<{
    beneficiary: BeneficiaryDetails;
    hashId: string;
  }>();
  const [isAddBeneficiaryModalOpen, setIsAddBeneficiaryModalOpen] =
    useState<boolean>(false);

  const { getBeneficiary, isBeneficiaryLoading, beneficiary } =
    useGetBeneficiary();
  const { getExchangeRate, isExchangeRateDataLoading, exchangeRateData } =
    useGetExchangeRate();
  const { getCurrency, isCurrencyDataLoading, currencyData } = useGetCurrency();

  const tranferMethod = useMemo(
    () =>
      beneficiary &&
      getTransferMethod({
        payoutMethod: beneficiary.payoutMethod || "",
        destinationCountry: beneficiary.destinationCountry || "",
        routingCodeType1: beneficiary.routingCodeType1 || "",
      }),
    [beneficiary]
  );

  const isInternationalWire =
    tranferMethod === TransferMethodEnum.INTERNATIONAL_WIRE;

  const isSWIFT =
    beneficiary && beneficiary.payoutMethod === PayoutMethodType.SWIFT;

  const destinationCountry = beneficiary && beneficiary.destinationCountry;

  const destinationCurrency = beneficiary && beneficiary.destinationCurrency;

  const isUSDSelected = destinationCurrency === USD_CURRENCY_KEY;

  const tranferMethodData =
    TransferMethods?.[tranferMethod as TransferMethodEnum];

  const exchangeRate = useMemo(
    () => exchangeRateData?.fx_rate,
    [exchangeRateData?.fx_rate]
  );

  useEffect(() => {
    const beneficiaryId = searchParams.get("recipient");

    if (beneficiaryId) {
      getBeneficiary(beneficiaryId);
    }
  }, [getBeneficiary, searchParams]);

  useEffect(() => {
    if (!!destinationCurrency && !isUSDSelected) {
      getExchangeRate(destinationCurrency);
    }
  }, [destinationCurrency, getExchangeRate, isUSDSelected]);

  useEffect(() => {
    if (!isUSDSelected && destinationCountry && isInternationalWire) {
      getCurrency(destinationCountry);
    }
  }, [destinationCountry, getCurrency, isInternationalWire, isUSDSelected]);

  useEffect(() => {
    if (currencyData?.currencyName && !isCurrencyDataLoading) {
      setCurrencyName(currencyData.currencyName);
    }
  }, [currencyData?.currencyName, isCurrencyDataLoading]);

  const handleAddEditBeneficiary = (
    beneficiary?: BeneficiaryDetails,
    hashId?: string
  ) => {
    if (beneficiary && hashId) {
      setBeneficiaryData({ beneficiary, hashId });
    } else {
      setBeneficiaryData(undefined);
    }

    setIsAddBeneficiaryModalOpen(true);
  };

  if (isBeneficiaryLoading) return <LoadingComponent />;

  return (
    <>
      {beneficiary && (
        <>
          <AddBeneficiary
            isModalOpen={isAddBeneficiaryModalOpen}
            onCloseModalHandler={() => setIsAddBeneficiaryModalOpen(false)}
            beneficiary={beneficiaryData?.beneficiary}
            beneficiaryHashId={beneficiaryData?.hashId}
          />
          <Stack gap={5} mb={isTablet ? 9 : 0}>
            <Stack gap={4}>
              <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                <SettingsOutlined sx={{ fontSize: "20px" }} />
                <Typography fontSize={16} fontWeight={500}>
                  {t("money.transfer.step.transferInfo.transferMethod")}
                </Typography>
              </Stack>
              <Stack
                direction={isTablet ? "column" : "row"}
                gap={isTablet ? 2 : 3}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={1}
                  sx={{
                    bgcolor: colors.lightGrey,
                    width: "100%",
                    p: "18px 24px",
                    borderRadius: "20px",
                  }}
                >
                  <Typography fontWeight={500} fontSize={16}>
                    {tranferMethodData.title}
                  </Typography>
                  <Typography fontSize={8}>•</Typography>
                  <Typography>
                    {t(`money.beneficiary.addBeneficiary.businessDays`, {
                      value: tranferMethodData.daysValue,
                    })}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            {isInternationalWire && (
              <Stack gap={4}>
                <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                  <TollOutlined sx={{ fontSize: "20px" }} />
                  <Typography fontSize={16} fontWeight={500}>
                    {t("money.transfer.step.transferInfo.currency")}
                  </Typography>
                </Stack>
                <Stack
                  direction={isTablet ? "column" : "row"}
                  gap={isTablet ? 2 : 3}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    gap={1}
                    sx={{
                      bgcolor: colors.lightGrey,
                      width: "100%",
                      p: "18px 24px",
                      borderRadius: "20px",
                    }}
                  >
                    {destinationCountry && (
                      <Stack
                        sx={{
                          width: "24px",
                          height: "16px",
                        }}
                      >
                        <img
                          loading="lazy"
                          src={`https://flagcdn.com/w20/${destinationCountry.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${destinationCountry.toLowerCase()}.png 2x`}
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "4px",
                          }}
                        />
                      </Stack>
                    )}
                    <Typography fontSize={16} fontWeight={500}>
                      {destinationCurrency}
                    </Typography>
                    <Typography fontSize={8}>•</Typography>
                    <Typography>{currencyName}</Typography>
                  </Stack>
                </Stack>
              </Stack>
            )}
            <Stack gap={4}>
              <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                <Person2Outlined sx={{ fontSize: "20px" }} />
                <Typography fontSize={16} fontWeight={500}>
                  {t("money.transfer.step.transferInfo.recipient")}
                </Typography>
              </Stack>
              <Stack
                direction={isTablet ? "column" : "row"}
                gap={isTablet ? 2 : 3}
              >
                <Stack
                  width={"100%"}
                  justifyContent={"space-between"}
                  gap={3}
                  borderRadius={6}
                  p={3}
                  bgcolor={colors.lightGrey}
                  overflow={"hidden"}
                >
                  <Stack direction={"row"} alignItems={"center"} gap={2}>
                    <MemoizedAvatar
                      name={beneficiary.beneficiaryName ?? ""}
                      countryCode={beneficiary.beneficiaryCountryCode ?? ""}
                    />
                    <Typography fontSize={16} fontWeight={500}>
                      {beneficiary.beneficiaryName}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography fontSize={14}>
                      {t("money.transfer.step.transferInfo.address")}
                    </Typography>
                    <Typography fontSize={16} fontWeight={500} noWrap>
                      {`${beneficiary.beneficiaryAddress} ${beneficiary.beneficiaryState} ${beneficiary.beneficiaryCity} ${beneficiary.beneficiaryCountryCode}  ${beneficiary.beneficiaryPostcode}`}
                    </Typography>
                  </Stack>
                  <Button
                    onClick={() =>
                      handleAddEditBeneficiary(
                        removeTypename(beneficiary),
                        beneficiary.beneficiaryHashId ?? ""
                      )
                    }
                    styles={{ alignSelf: "end", backgroundColor: colors.grey }}
                    dark={false}
                    endIcon={<EditOutlined />}
                    buttonText={t("money.transfer.step.transferInfo.edit")}
                  />
                </Stack>
                <Stack
                  width={"100%"}
                  justifyContent={"space-between"}
                  gap={3}
                  borderRadius={6}
                  p={3}
                  bgcolor={colors.lightGrey}
                >
                  <Stack direction={"row"} alignItems={"center"} gap={2}>
                    <Avatar
                      sx={{
                        backgroundColor: colors.grey,
                        color: colors.black,
                        width: "48px",
                        height: "48px",
                      }}
                    >
                      <AccountBalanceOutlined />
                    </Avatar>
                    <Typography fontSize={16} fontWeight={"500"}>
                      {beneficiary.beneficiaryBankName}
                    </Typography>
                  </Stack>
                  <Stack
                    width={"100%"}
                    direction={"row"}
                    alignItems={"center"}
                    gap={3}
                  >
                    <Stack>
                      <Typography fontSize={14}>
                        {isSWIFT
                          ? t("money.transfer.step.transferInfo.SWIFT")
                          : t("money.transfer.step.transferInfo.routingNumber")}
                      </Typography>
                      <Typography fontSize={16} fontWeight={"500"} noWrap>
                        {maskSensitiveData(beneficiary.routingCodeValue1 ?? "")}
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography fontSize={14}>
                        {isSWIFT
                          ? t("money.transfer.step.transferInfo.IBAN")
                          : t("money.transfer.step.transferInfo.accountNumber")}
                      </Typography>
                      <Typography fontSize={16} fontWeight={"500"} noWrap>
                        {maskSensitiveData(
                          beneficiary.beneficiaryAccountNumber ?? ""
                        )}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Button
                    styles={{ alignSelf: "end", backgroundColor: colors.grey }}
                    dark={false}
                    endIcon={<EditOutlined />}
                    onClick={() =>
                      handleAddEditBeneficiary(
                        removeTypename(beneficiary),
                        beneficiary.beneficiaryHashId ?? ""
                      )
                    }
                    buttonText={t("money.transfer.step.transferInfo.edit")}
                  />
                </Stack>
              </Stack>
            </Stack>
            {isInternationalWire &&
              destinationCurrency &&
              exchangeRate &&
              !isExchangeRateDataLoading &&
              !isUSDSelected && (
                <AlertNotification
                  body={t(
                    "money.beneficiary.addBeneficiary.currency.exchange.alert",
                    {
                      value: `${parseFloat(exchangeRate).toFixed(
                        2
                      )} ${destinationCurrency}`,
                    }
                  )}
                />
              )}
            <ButtonBox
              onClickBack={() => {
                posthog?.capture(
                  "money_transfer_flow_transfer_info_back_btn_click"
                );
                navigate(ROUTER_PATHS.MONEY_TRANSFER);
              }}
              onClickNext={() => {
                posthog?.capture(
                  "money_transfer_flow_transfer_info_next_btn_click"
                );
                setCurrentStep(TransferSteps.amount);
              }}
            />
          </Stack>
        </>
      )}
    </>
  );
};

export default TransferInfo;
