import { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { FAQDashboard } from "components/FAQs";
import { CustomCard } from "components/Cards";
import { P } from "components/Fonts";
import { FAQ_DATA } from "components/FAQs/data";
import { LinkReferral } from "components/LinkReferral";
import { CustomLinkWrapper, LinkAndButtomsWrapper } from "./types";
import { HowItWork } from "./HowItWorks";
import EmailSender from "./EmailSender";
import useCustomer from "graphql/hooks/useCustomer";

const Referrals = () => {
  const [emailEntered, setEmailEntered] = useState("");
  const { currentUser } = useCustomer();

  return (
    <Grid container spacing={3}>
      <Grid item lg={12}>
        <P size="large" $emphasis>
          Refer & Earn
        </P>
        <P size="medium" $colorOpacity="50%">
          Refer doola and make money!
        </P>
      </Grid>
      <Grid item lg={12}>
        <CustomCard $shadow="strong" $radius="strong">
          <P size="large" $emphasis>
            Get a 15% commission for every new customer you refer to doola!
          </P>

          <Grid container spacing={3} pt={2}>
            <Grid item md={6} xs={12}>
              <P size="small" $margin="0 0 10px 0">
                Send an email invite
              </P>
              <LinkAndButtomsWrapper>
                <CustomLinkWrapper>
                  <TextField
                    id="outlined-basic"
                    label="Email address"
                    variant="outlined"
                    size="small"
                    fullWidth
                    sx={{ height: "44px", marginTop: "5px" }}
                    onChange={(event) => setEmailEntered(event.target.value)}
                    value={emailEntered}
                  />
                </CustomLinkWrapper>
                &nbsp; &nbsp;
                <EmailSender
                  linkToSend={""}
                  referralName={currentUser?.name || ""}
                  emailTo={emailEntered}
                />
              </LinkAndButtomsWrapper>
            </Grid>

            <Grid item md={6} xs={12}>
              <P size="small" $margin="0 0 10px 0">
                Share your custom referral link
              </P>
              <LinkAndButtomsWrapper>
                <CustomLinkWrapper>
                  <LinkReferral />
                </CustomLinkWrapper>
              </LinkAndButtomsWrapper>
            </Grid>
          </Grid>
        </CustomCard>
      </Grid>

      {/* <CustomCard
        styles={{ marginBottom: "20px", padding: "0px" }}
        shadow="strong"
        borderRadius="strong"
      >
        <Grid container lg={12} spacing={1}>
          <Grid item lg={12} spacing={1}>
            <ReferralTable data={mockedReferalsTableData} />
          </Grid>
        </Grid>
      </CustomCard> */}

      <Grid item md={6} xs={12}>
        <HowItWork />
      </Grid>
      <Grid item md={6} xs={12}>
        <FAQDashboard faqs={FAQ_DATA} />
      </Grid>
    </Grid>
  );
};

export default Referrals;

// const mockedReferalsTableData = [
//   {
//     sentOn: "Nov 06, 2020",
//     email: "dundermifflin@gmail.com",
//     statusKey: "invited",
//   },
// ];
