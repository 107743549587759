import { useState } from "react";
import {
  IOnboardingStep,
  OnboardingStepsCategories,
  OnboardingStepsData,
} from "./data";
import { Stack, useMediaQuery } from "@mui/material";
import Stepper from "components/Stepper";
import utils, { colors } from "utils";
import FinalOnboardingStep from "./components/FinalOnboardingStep";
import StepContent from "./StepContent";
import Button from "components/Button";
import { NavigateNext } from "@mui/icons-material";
import { ROUTER_PATHS } from "routes/routes";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { usePostHog } from "posthog-js/react";

const OnBoarding = () => {
  const [currentStep, setCurrentStep] = useState<IOnboardingStep>(
    OnboardingStepsData[OnboardingStepsCategories.prepareInfo]
  );
  const [isFinalStep, setIsFinalStep] = useState<boolean>(false);

  const navigate = useNavigate();
  const posthog = usePostHog();

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);

  const stepKeys = Object.keys(
    OnboardingStepsData
  ) as OnboardingStepsCategories[];
  const totalSteps = stepKeys.length - 1;

  // Find the index of the current step
  const currentStepIndex = stepKeys.findIndex(
    (key) => OnboardingStepsData[key] === currentStep
  );

  const isReadToSendStep = currentStepIndex + 1 === stepKeys.length;

  return (
    <Stack
      gap={5}
      p={isTablet ? 0 : 4}
      mb={isTablet ? 9 : 0}
      alignSelf={"center"}
      sx={{ width: isTablet ? "100%" : "80%" }}
    >
      {!isFinalStep && !isReadToSendStep && (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stepper
            label={t("general.step")}
            currentStep={currentStepIndex + 1}
            numberOfSteps={totalSteps ?? 0}
          />
          {!isMobile && (
            <Button
              dark={false}
              buttonText="Save & Exit"
              endIcon={<NavigateNext />}
              styles={{ alignSelf: "flex-end", bgcolor: colors.grey }}
              onClick={() => {
                posthog?.capture("money_onboarding_save_and_exit_btn_click");
                navigate(ROUTER_PATHS.MONEY);
              }}
            />
          )}
        </Stack>
      )}

      {isFinalStep ? (
        <FinalOnboardingStep />
      ) : (
        <StepContent
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          setIsFinalStep={setIsFinalStep}
        />
      )}
    </Stack>
  );
};

export default OnBoarding;
