import { Container } from "@mui/material";
import { Navigate, Outlet } from "react-router-dom";
import OnboardingProvider from "./OnboardingProvider";
import useCompany from "graphql/hooks/UseCompany";
import { LoadingComponent } from "components/Loading";
import { CompanyServices, ServiceCategory } from "graphql/__generated__/types";
import { ROUTER_PATHS } from "routes/routes";

const OnboardingLayout = () => {
  const { currentCompany } = useCompany();

  const formationService = currentCompany?.services?.find(
    (x) => x.service === CompanyServices.FORMATION
  );

  if (!currentCompany || !formationService) {
    return <LoadingComponent />;
  }

  const hasBeenFiled =
    formationService.status === ServiceCategory.INPROGRESS &&
    formationService.subStatus === "WaitingForRaToSucceed";

  const hasCompletedOnboarding =
    formationService.status === ServiceCategory.COMPLETED;

  // we need to stop people getting back to the postpurchase flow if they've already filed with state or completed their onboarding
  if (hasBeenFiled || hasCompletedOnboarding) {
    return <Navigate to={ROUTER_PATHS.DASHBOARD} />;
  }

  return (
    <OnboardingProvider formationService={formationService!}>
      <Container
        maxWidth="lg"
        sx={{
          px: { xs: 2, sm: 4 },
          position: "relative",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Outlet />
      </Container>
    </OnboardingProvider>
  );
};

export default OnboardingLayout;
