export type InitialIndividualMemberInfoValuesType = {
  firstName: string;
  lastName: string;
  nationality: string;
  dateOfBirth: string;
  professionalDetails: Array<null | string>;
};

export const INTITAL_INDIVIDUAL_MEMBER_INFO_VALUES: InitialIndividualMemberInfoValuesType =
  {
    firstName: "",
    lastName: "",
    nationality: "",
    dateOfBirth: "",
    professionalDetails: [],
  };
