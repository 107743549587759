import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { CloseRounded } from "@mui/icons-material";
import {
  Dialog,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import utils from "utils";
import MonthItem from "./components/MonthItem";
import {
  getYearsFromAccountCreatedYearToCurrYear,
  getMonthNameUptoCurrentDate,
} from "./helpers";
import YearButton from "./components/YearButton";

type Props = {
  isModalOpen: boolean;
  onCloseModalHandler: () => void;
  accountCreatedDate: string;
};

const Statements = ({
  isModalOpen,
  onCloseModalHandler,
  accountCreatedDate,
}: Props) => {
  const [activeYear, setActiveYear] = useState<number>(dayjs().year());

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);
  const { t } = useTranslation();

  const yearsArray = useMemo(
    () =>
      getYearsFromAccountCreatedYearToCurrYear(
        dayjs(accountCreatedDate).year()
      ),
    [accountCreatedDate]
  );

  return (
    <Dialog
      fullScreen={isTablet}
      PaperProps={{
        style: {
          borderRadius: !isTablet ? "32px" : "0",
          width: isTablet ? "100%" : "800px",
          maxHeight: isTablet ? "100%" : 800,
        },
      }}
      open={isModalOpen}
      onClose={onCloseModalHandler}
    >
      <Stack p={isMobile ? 2 : isTablet ? 3 : 4} gap={isTablet ? 3 : 5}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography fontSize={isTablet ? 24 : 28} fontWeight={700}>
            {t("money.transactions.statements")}
          </Typography>
          <IconButton onClick={onCloseModalHandler}>
            <CloseRounded color="primary" />
          </IconButton>
        </Stack>
        <Stack gap={3}>
          <Stack direction={"row"} gap={2} sx={{ overflowX: "auto" }}>
            {yearsArray.map((year, index) => (
              <YearButton
                key={index}
                buttonText={year.toString()}
                isActive={year === activeYear}
                onClick={() => setActiveYear(year)}
              />
            ))}
          </Stack>
          <Stack gap={2}>
            {getMonthNameUptoCurrentDate(accountCreatedDate, activeYear).map(
              (month, index) => (
                <MonthItem key={index} year={activeYear} month={month} />
              )
            )}
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default Statements;
