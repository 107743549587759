import { WalletAddressInput } from "graphql/__generated__/types";

export const INITIAL_ADDRESS_VALUES: WalletAddressInput = {
  address1: "",
  address2: "",
  city: "",
  country: "",
  postalCode: "",
  state: "",
};
