import {
  AccountType,
  BeneficiaryDetails,
  PayoutMethodType,
} from "graphql/__generated__/types";

export const INITIAL_BENEFICIARY_VALUES: BeneficiaryDetails = {
  beneficiaryAccountNumber: "",
  beneficiaryAccountType: AccountType.INDIVIDUAL,
  beneficiaryAddress: "",
  beneficiaryCity: "",
  beneficiaryCountryCode: "",
  beneficiaryName: "",
  beneficiaryPostcode: "",
  beneficiaryState: "",
  destinationCountry: "",
  destinationCurrency: "",
  doolaCompanyId: "",
  payoutMethod: PayoutMethodType.LOCAL,
  routingCodeType1: "",
  routingCodeValue1: "",
};
