import * as Types from '../types';

import { gql } from '@apollo/client';
import { DocumentServiceDocsInfoFragmentDoc } from './Company.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteDocumentDocServiceMutationVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  documentId: Types.Scalars['String'];
}>;


export type DeleteDocumentDocServiceMutation = { __typename?: 'Mutation', company?: { __typename?: 'CompanyMutation', deleteDocumentDocService?: { __typename?: 'Company', id: string, documentServiceDocs?: Array<{ __typename?: 'DocServiceDocuments', companyId?: string | null, documentId?: string | null, url?: string | null, metadata?: { __typename?: 'DocumentMetaData', companyid?: string | null, filename?: string | null, source?: string | null, sourcecategory?: string | null } | null } | null> | null } | null } | null };


export const DeleteDocumentDocServiceDocument = gql`
    mutation DeleteDocumentDocService($companyId: ID!, $documentId: String!) {
  company(id: $companyId) {
    deleteDocumentDocService(documentId: $documentId) {
      id
      documentServiceDocs {
        ...DocumentServiceDocsInfo
      }
    }
  }
}
    ${DocumentServiceDocsInfoFragmentDoc}`;
export type DeleteDocumentDocServiceMutationFn = Apollo.MutationFunction<DeleteDocumentDocServiceMutation, DeleteDocumentDocServiceMutationVariables>;

/**
 * __useDeleteDocumentDocServiceMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentDocServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentDocServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentDocServiceMutation, { data, loading, error }] = useDeleteDocumentDocServiceMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDeleteDocumentDocServiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentDocServiceMutation, DeleteDocumentDocServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentDocServiceMutation, DeleteDocumentDocServiceMutationVariables>(DeleteDocumentDocServiceDocument, options);
      }
export type DeleteDocumentDocServiceMutationHookResult = ReturnType<typeof useDeleteDocumentDocServiceMutation>;
export type DeleteDocumentDocServiceMutationResult = Apollo.MutationResult<DeleteDocumentDocServiceMutation>;
export type DeleteDocumentDocServiceMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentDocServiceMutation, DeleteDocumentDocServiceMutationVariables>;