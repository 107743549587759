import ButtonBox from "components/ButtonBox";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import { Stack, TextField, Typography, useMediaQuery } from "@mui/material";
import utils, { colors } from "utils";
import { CloseOutlined, InfoOutlined } from "@mui/icons-material";
import { moneyFormatter } from "utils/formatters";
import { useEffect, useState } from "react";
import useCompany from "graphql/hooks/UseCompany";
import { useUpdateCompanyCCorpValuationInfoMutation } from "graphql/__generated__/operations/UpdateCompanyCCorpValuationInfo.generated";
import { Controller, useForm } from "react-hook-form";
import { INITIAL_CCORP_VALUATION_VALUES } from "./helpers/initialCCorpValuationData";
import { CcorpValuationInfo } from "graphql/__generated__/types";
import { removeTypename } from "utils/helpers/removeTypename";
import { DecimalRegex, NumberRegex } from "utils/regexValidations";
import ErrorBox from "components/ErrorBox";
import { isDecimalInputValid, isWholeNumberInputValid } from "utils/helpers";
import { useTranslation } from "react-i18next";

const OnboardingShare = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentCompany } = useCompany();

  const [updateCCorpValuationInfo] =
    useUpdateCompanyCCorpValuationInfoMutation();

  const { control, watch, formState, handleSubmit, setValue } =
    useForm<CcorpValuationInfo>({
      defaultValues: { ...INITIAL_CCORP_VALUATION_VALUES },
      mode: "onChange",
    });

  const noOfShares = watch("noOfShares");
  const valuePerShare = watch("valuePerShare");

  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  useEffect(() => {
    const ccorpValuationInfo = removeTypename(
      currentCompany?.ccorpValuationInfo
    );

    if (ccorpValuationInfo) {
      setValue("noOfShares", ccorpValuationInfo?.noOfShares);
      setValue("valuePerShare", ccorpValuationInfo?.valuePerShare);
    }
  }, [currentCompany, setValue]);

  // if there is no responsible party - redirect the user to that step
  useEffect(() => {
    if (currentCompany && !currentCompany.responsibleParty) {
      navigate(ROUTER_PATHS.ONBOARDING_RESPONSIBLE_PARTY);
    }
  }, [currentCompany, navigate]);

  const onSubmitHandler = async (data: CcorpValuationInfo) => {
    try {
      setIsLoading(true);

      await updateCCorpValuationInfo({
        variables: {
          companyId: currentCompany?.id,
          noOfShares: +data.noOfShares,
          valuePerShare: +data.valuePerShare,
        },
      });

      navigate(ROUTER_PATHS.ONBOARDING_REVIEW);
    } finally {
      setIsLoading(false);
    }
  };

  const isDisabled =
    !formState.isValid || noOfShares <= 0 || valuePerShare <= 0;

  return (
    <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
      <Stack direction="column" gap={isTablet ? 3 : 4} mb={4}>
        <Typography variant="h1">
          {t("onboarding.ccorp.share.title")}
        </Typography>
        <Stack direction={isTablet ? "column" : "row"} gap={2}>
          <Stack spacing={1} sx={{ width: "100%" }}>
            <Typography
              variant="h6"
              color={utils.secondaryColors.secondaryMain}
            >
              {t("onboarding.ccorp.share.numberOfShares")}
            </Typography>
            <Controller
              name="noOfShares"
              {...COMMON_PROPS}
              rules={{
                required: true,
                pattern: {
                  value: NumberRegex,
                  message: t("error.invalidNumbers"),
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={error !== undefined}
                  type="number"
                  placeholder={
                    t(
                      "onboarding.ccorp.share.numberOfShares.placeholder"
                    ) as string
                  }
                  onChange={(e) => {
                    const value = e.target.value;

                    if (isWholeNumberInputValid(value)) {
                      field.onChange(value);
                    }
                  }}
                  fullWidth
                  sx={{
                    ".MuiInputBase-root": {
                      height: "40px",
                    },
                  }}
                  inputProps={{
                    min: "0",
                    step: "1",
                  }}
                />
              )}
            />
          </Stack>
          {!isTablet && (
            <Stack spacing={1} justifyContent={"space-around"}>
              <Stack></Stack>
              <CloseOutlined
                sx={{
                  fontSize: "18px",
                  alignSelf: "center",
                }}
              />
            </Stack>
          )}
          <Stack spacing={1} sx={{ width: "100%" }}>
            <Typography
              variant="h6"
              color={utils.secondaryColors.secondaryMain}
            >
              {t("onboarding.ccorp.share.parValueOfShares")}
            </Typography>
            <Controller
              name="valuePerShare"
              {...COMMON_PROPS}
              rules={{
                required: true,
                pattern: {
                  value: DecimalRegex,
                  message: t("error.invalidDecimals"),
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={error !== undefined}
                  type="number"
                  onChange={(e) => {
                    const value = e.target.value;

                    if (isDecimalInputValid(value)) {
                      field.onChange(value);
                    }
                  }}
                  placeholder={
                    t(
                      "onboarding.ccorp.share.parValueOfShares.placeholder"
                    ) as string
                  }
                  fullWidth
                  sx={{
                    ".MuiInputBase-root": {
                      height: "40px",
                    },
                  }}
                  InputProps={{ endAdornment: "$" }}
                  inputProps={{
                    min: "0",
                  }}
                />
              )}
            />
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          borderRadius={3}
          p={3}
          gap={1.5}
          bgcolor={colors.lightGrey}
        >
          <Typography>{t("onboarding.ccorp.share.totalValue")}</Typography>
          <Typography variant="h4">
            {moneyFormatter(noOfShares * valuePerShare, false)}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems={"center"} gap={2}>
          <InfoOutlined sx={{ color: colors.contentTertiary }} />
          <Typography color={colors.contentTertiary}>
            {t("onboarding.ccorp.share.note")}
          </Typography>
        </Stack>
        <ErrorBox formState={formState} />
        <ButtonBox
          onClickBack={() =>
            navigate(ROUTER_PATHS.ONBOARDING_RESPONSIBLE_PARTY)
          }
          onClickNext={() => onSubmitHandler}
          disabled={isDisabled}
          isLoading={isLoading}
        />
      </Stack>
    </form>
  );
};

export default OnboardingShare;
