import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import utils, { colors } from "utils";

export const IconButtonStyled = styled(Button)(
  ({ theme }) => `
  width: 100%;
  display: block;
  border-radius: 12px;
  background-color: ${
    theme.palette.mode === "dark" ? colors.contentSecondary : colors.lightGrey
  };
  padding: ${utils.constants.units.main * 2}px;
  box-shadow: none;

  &:hover, &:focus {
    background-color: ${
      theme.palette.mode === "dark" ? colors.contentTertiary : colors.darkGrey
    };
    box-shadow: none;
  }
`
);
