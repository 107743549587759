import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerateDocServiceUploadUrlMutationVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  objectKey: Types.Scalars['String'];
  companyIdString: Types.Scalars['String'];
  source: Types.Source;
  sourcecategory: Types.Scalars['String'];
}>;


export type GenerateDocServiceUploadUrlMutation = { __typename?: 'Mutation', company?: { __typename?: 'CompanyMutation', generateDocServiceUploadUrl?: { __typename?: 'GeneratedUrl', url: string, tags?: Array<{ __typename?: 'UploadMetaDataType', key?: string | null, value?: string | null } | null> | null } | null } | null };


export const GenerateDocServiceUploadUrlDocument = gql`
    mutation GenerateDocServiceUploadUrl($companyId: ID!, $objectKey: String!, $companyIdString: String!, $source: source!, $sourcecategory: String!) {
  company(id: $companyId) {
    generateDocServiceUploadUrl(
      contentType: "application/pdf"
      objectKey: $objectKey
      metadata: [{key: "companyid", value: $companyIdString}, {key: "sourcecategory", value: $sourcecategory}]
      source: $source
    ) {
      tags {
        key
        value
      }
      url
    }
  }
}
    `;
export type GenerateDocServiceUploadUrlMutationFn = Apollo.MutationFunction<GenerateDocServiceUploadUrlMutation, GenerateDocServiceUploadUrlMutationVariables>;

/**
 * __useGenerateDocServiceUploadUrlMutation__
 *
 * To run a mutation, you first call `useGenerateDocServiceUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateDocServiceUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateDocServiceUploadUrlMutation, { data, loading, error }] = useGenerateDocServiceUploadUrlMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      objectKey: // value for 'objectKey'
 *      companyIdString: // value for 'companyIdString'
 *      source: // value for 'source'
 *      sourcecategory: // value for 'sourcecategory'
 *   },
 * });
 */
export function useGenerateDocServiceUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<GenerateDocServiceUploadUrlMutation, GenerateDocServiceUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateDocServiceUploadUrlMutation, GenerateDocServiceUploadUrlMutationVariables>(GenerateDocServiceUploadUrlDocument, options);
      }
export type GenerateDocServiceUploadUrlMutationHookResult = ReturnType<typeof useGenerateDocServiceUploadUrlMutation>;
export type GenerateDocServiceUploadUrlMutationResult = Apollo.MutationResult<GenerateDocServiceUploadUrlMutation>;
export type GenerateDocServiceUploadUrlMutationOptions = Apollo.BaseMutationOptions<GenerateDocServiceUploadUrlMutation, GenerateDocServiceUploadUrlMutationVariables>;