import { Stack, Typography, useMediaQuery } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import utils, { colors } from "utils";
import FormControlElement from "../components/FormControlElement";
import ButtonBox from "components/ButtonBox";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import { moneyFormatter } from "utils/formatters";
import AlertNotification from "components/AlertNotification";
import useGetBoiFilingProduct from "./hooks/useGetBoiFilingProduct";
import { useCallback, useEffect, useState } from "react";
import { IFormAddonPackage } from "../Interfaces";
import { CompanyServices, ProductDuration } from "graphql/__generated__/types";
import { useAppDispatch, useAppSelector } from "state/hooks";
import {
  selectBoiFilingFormationPackage,
  setBoiFilingFormationPackage,
} from "state/formationFormSlice";
import { LoadingComponent } from "components/Loading";
import { usePostHog } from "posthog-js/react";
import { FormationScreenNames, trackPosthogAddOn } from "../services";

const BoiFiling = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const posthog = usePostHog();

  const { boiFilingData, isProductQueryLoading } = useGetBoiFilingProduct();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const selectedBoiFilingFormation = useAppSelector(
    selectBoiFilingFormationPackage
  );

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);

  useEffect(() => {
    posthog?.capture("boi-filing-products-viewed");
  }, [posthog]);

  const { control, formState, handleSubmit, setValue, watch } =
    useForm<IFormAddonPackage>({
      defaultValues: {
        name: "",
        stripePriceId: boiFilingData.value,
        lookupKey: boiFilingData.lookupKey,
        price: boiFilingData.price,
        duration: ProductDuration.ONETIME,
        isSelected: false,
        isAddonSelected: false,
      },
    });

  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  const isBoiFilingSelected = watch("isSelected");
  const isAddonSelected = watch("isAddonSelected");
  const isDisabled = !isBoiFilingSelected;

  useEffect(() => {
    setValue("stripePriceId", boiFilingData.value);
    setValue("lookupKey", boiFilingData.lookupKey);
    setValue("price", boiFilingData.price);
  }, [
    boiFilingData.lookupKey,
    boiFilingData.price,
    boiFilingData.value,
    setValue,
  ]);

  const onPackageChange = useCallback(
    (newValue: string) => {
      setValue("name", newValue);
      setValue("isSelected", true);
    },
    [setValue]
  );

  useEffect(() => {
    if (selectedBoiFilingFormation?.item_name) {
      setValue("isAddonSelected", true);
      onPackageChange(selectedBoiFilingFormation?.item_name);
    }
  }, [selectedBoiFilingFormation, onPackageChange, setValue]);

  if (isProductQueryLoading) {
    return <LoadingComponent />;
  }

  const onSubmitHandler = (data: IFormAddonPackage) => {
    setIsLoading(true);

    const { name, stripePriceId, price, lookupKey } = data;

    if (isBoiFilingSelected && isAddonSelected) {
      dispatch(
        setBoiFilingFormationPackage({
          item_name: name,
          stripePriceId: stripePriceId,
          lookupKey,
          price: price,
          item_category2: CompanyServices.BOIFILING,
          item_category3: ProductDuration.ONETIME,
        })
      );

      posthog?.capture("boi-filing-product-selected", {
        boi_filing_product_name: name,
        boi_filing_product_id: stripePriceId,
        boi_filing_product_value: price,
        boi_filing_product_term: ProductDuration.ONETIME,
      });

      trackPosthogAddOn(name, stripePriceId, price);
    } else {
      dispatch(setBoiFilingFormationPackage());
    }

    navigate(ROUTER_PATHS.PRE_PURCHASE_REVIEW);
  };

  const onBackHandler = () => {
    posthog?.capture("funnel-back", {
      screen: FormationScreenNames.BoiFiling,
    });

    navigate(ROUTER_PATHS.PRE_PURCHASE_EXPEDITED);
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
      <Stack direction="column" gap={3} mb={isTablet ? 0 : 3}>
        <Typography variant={isTablet ? "h3" : "h2"}>
          {t("signup.boiFiling.screen.title")}
        </Typography>
        <AlertNotification
          body={t("signup.boiFiling.alertNotification.body") as string}
        />
        <Controller
          name={"name"}
          {...COMMON_PROPS}
          rules={{
            required: true,
          }}
          render={({ field: { ref, ...field } }) => (
            <>
              <FormControlElement
                {...field}
                title={t("signup.boiFiling.title")}
                subtitle={t("signup.boiFiling.subtitle") || ""}
                optionValue={boiFilingData.name}
                selectedValue={field.value}
                changeHandler={() => {
                  setValue("isAddonSelected", true);
                  onPackageChange(boiFilingData.name);
                }}
                sideBySideTitle={!isMobile}
                sideContent={
                  <Stack
                    direction={isMobile ? "row" : "column"}
                    {...(isMobile && { gap: 1 })}
                    alignItems="flex-end"
                    data-testid="prePurchaseBoiFilingOption"
                  >
                    <Typography variant="h2" fontSize={28}>
                      {moneyFormatter(boiFilingData.price)}
                    </Typography>
                    <Typography
                      noWrap
                      variant="body1"
                      color={colors.contentTertiary}
                    >
                      One-time
                    </Typography>
                  </Stack>
                }
              />
              <FormControlElement
                {...field}
                title={t("signup.boiFiling.deny.title")}
                subtitle={t("signup.boiFiling.deny.subtitle") || ""}
                optionValue={"Included"}
                selectedValue={field.value}
                sideBySideTitle={!isMobile}
                changeHandler={() => {
                  setValue("isAddonSelected", false);
                  onPackageChange("Included");
                }}
              />
            </>
          )}
        />
        <ButtonBox
          onClickBack={onBackHandler}
          onClickNext={() => onSubmitHandler}
          isLoading={isLoading}
          disabled={isDisabled}
        />
      </Stack>
    </form>
  );
};

export default BoiFiling;
