import { Stack, Typography, useMediaQuery, IconButton } from "@mui/material";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import utils, { colors } from "utils";
import Button from "components/Button";
import NavigateNext from "@mui/icons-material/NavigateNext";
import { usePostHog } from "posthog-js/react";

const MoneyHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentPathName = useLocation().pathname;

  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);
  const posthog = usePostHog();

  const {
    MONEY_OVERVIEW,
    MONEY_TRANSFER,
    MONEY_TRANSACTIONS,
    MONEY_BENEFICIARY,
  } = ROUTER_PATHS;

  return (
    <Stack gap={3.5}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        width={"100%"}
        justifyContent={"space-between"}
      >
        <Typography
          variant="h3"
          minHeight={45}
          fontSize={isMobile ? "24px" : "28px"}
        >
          {t("money.myMoney")}
        </Typography>
        {currentPathName !== MONEY_TRANSFER && (
          <Button
            endIcon={<NavigateNext />}
            buttonText={t("money.transferMoney")}
            onClick={() => {
              posthog?.capture("money_header_transfer_money_btn_click");
              navigate(MONEY_TRANSFER);
            }}
          />
        )}
      </Stack>
      <Stack
        direction={"row"}
        gap={2}
        sx={{
          overflowX: isMobile ? "scroll" : "hidden",
          msOverflowStyle: "none" /* Internet Explorer 10+ */,
          scrollbarWidth: "none" /* Firefox */,
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Button
          dark={currentPathName === MONEY_OVERVIEW}
          startIcon={<DashboardOutlinedIcon />}
          buttonText={t("money.overview")}
          onClick={() => {
            posthog?.capture("money_header_overview_btn_click");
            navigate(MONEY_OVERVIEW);
          }}
          styles={{ minWidth: "130px" }}
        />
        <Button
          dark={currentPathName === MONEY_TRANSFER}
          startIcon={<SendOutlinedIcon />}
          buttonText={t("money.transfer")}
          onClick={() => {
            posthog?.capture("money_header_transfer_btn_click");
            navigate(MONEY_TRANSFER);
          }}
          styles={{ minWidth: "130px" }}
        />
        <Button
          dark={currentPathName === MONEY_TRANSACTIONS}
          startIcon={<FormatListBulletedOutlinedIcon />}
          buttonText={t("money.transactions")}
          onClick={() => {
            posthog?.capture("money_header_transactions_btn_click");
            navigate(MONEY_TRANSACTIONS);
          }}
          styles={{ minWidth: "155px" }}
        />
        <IconButton
          onClick={() => {
            posthog?.capture("money_header_beneficiary_btn_click");
            navigate(MONEY_BENEFICIARY);
          }}
          sx={{
            width: 48,
            height: 48,
            bgcolor:
              currentPathName === MONEY_BENEFICIARY
                ? colors.black
                : colors.lightGrey,
            borderRadius: "50%",
            "&:hover": {
              bgcolor:
                currentPathName === MONEY_BENEFICIARY
                  ? colors.black
                  : colors.darkGrey,
            },
          }}
        >
          <PeopleOutlineIcon
            style={{
              fontSize: 20,
              color:
                currentPathName === MONEY_BENEFICIARY
                  ? colors.white
                  : colors.contentSecondary,
            }}
          />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default MoneyHeader;
