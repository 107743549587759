import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { AppBar, AppBarProps, Toolbar, useTheme } from "@mui/material";
import IconButton from "components/@extended/IconButton";
import { ReactNode, useMemo } from "react";
import HeaderContent from "./HeaderContent";

interface Props {
  open: boolean;
  handleDrawerToggle?: () => void;
}

const Header = ({ open, handleDrawerToggle }: Props) => {
  const theme = useTheme();

  const headerContent = useMemo(() => <HeaderContent />, []);

  const iconBackColorOpen =
    theme.palette.mode === "dark" ? "grey.200" : "grey.300";
  const iconBackColor =
    theme.palette.mode === "dark" ? "background.default" : "grey.100";

  const mainHeader: ReactNode = (
    <Toolbar>
      <IconButton
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        edge="start"
        color="secondary"
        variant="light"
        sx={{
          color: "text.primary",
          bgcolor: open ? iconBackColorOpen : iconBackColor,
          ml: { xs: 0, lg: -2 },
          fontSize: "16px",
        }}
      >
        {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </IconButton>
      {headerContent}
    </Toolbar>
  );

  const appBar: AppBarProps = {
    position: "fixed",
    color: "inherit",
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      zIndex: 1200,
    },
  };

  return <AppBar {...appBar}>{mainHeader}</AppBar>;
};

export default Header;
