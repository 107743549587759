import { CloseRounded } from "@mui/icons-material";
import { Box, Dialog, IconButton, Stack, useMediaQuery } from "@mui/material";
import { useState } from "react";
import utils from "utils";
import FinalStep from "./components/FinalStep";
import useUserSurvey from "./hooks/useUserSurvey";
import { LoadingComponent } from "components/Loading";
import Stepper from "components/Stepper";
import { QUESTION_TYPES } from "./data";
import { Text } from "./Questions/Text";
import { Range } from "./Questions/Range";
import { Options } from "./Questions/Options";
import { IQuestionProps } from "./Questions/types";
import { useAddUserSurveyResponseMutation } from "graphql/__generated__/operations/AddUserSurveyResponse.generated";
import useCustomer from "graphql/hooks/useCustomer";
import { UserSurveyAnswerInput } from "graphql/__generated__/types";
import { toast } from "toast";
import useUserSurveyResponse from "../../hooks/useUserSurveyResponse";
import { usePostHog } from "posthog-js/react";
import { t } from "i18next";

interface IFeedbackSurveyProps {
  open: boolean;
  onClose: () => void;
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

const FeedbackSurvey = ({
  open,
  onClose,
  setVisibility,
}: IFeedbackSurveyProps) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [responseData, setResponseData] = useState<{
    [key: string]: UserSurveyAnswerInput;
  }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const posthog = usePostHog();
  const { userSurvey, isUserSurveyLoading } = useUserSurvey();
  const { refetch: refetchUserSurveyResponse } = useUserSurveyResponse();
  const { currentUser } = useCustomer();

  const [addUserSurveyResponse] = useAddUserSurveyResponseMutation();

  const questions = (userSurvey?.payload?.activeQuestionnaire?.questions || [])
    .slice()
    .sort((a, b) => (a?.qidx || 0) - (b?.qidx || 0));

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const isFinalStep = currentStep >= (questions?.length ?? 0);
  const isLastStep = currentStep === questions?.length - 1;
  const currentQuestion = questions?.[currentStep];

  const submitStepData = async (data: UserSurveyAnswerInput) => {
    if (currentQuestion?.id) {
      setResponseData((prevResponseData) => ({
        ...prevResponseData,
        [currentQuestion.id as string]: data,
      }));
    }

    posthog?.capture("user_survey_step_next_click", {
      option_id: data.optionId,
      answer_text: data.answerText,
      ...(currentQuestion?.type === QUESTION_TYPES.RANGE && {
        range_value: data.rangeValue,
      }),
    });

    if (isLastStep) {
      await submitResponseData({
        ...responseData,
        [currentQuestion?.id as string]: data,
      });
    } else {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const onCloseHandler = () => {
    if (isFinalStep) {
      setVisibility(false);

      refetchUserSurveyResponse();
    }

    onClose();
  };

  const submitResponseData = async (data: {
    [key: string]: UserSurveyAnswerInput;
  }) => {
    try {
      setIsLoading(true);

      const answers = Object.values(data).map((response) => ({
        answerText: response.answerText,
        doolaUserSurveyQuestionId: response.doolaUserSurveyQuestionId!,
        optionId: response.optionId,
        rangeValue: response.rangeValue,
        questionType: response.questionType!,
      }));

      const { data: userSurveyResponse } = await addUserSurveyResponse({
        variables: {
          userSurveyResponse: {
            answers,
            doolaCustomerId: currentUser?.id,
            doolaUserSurveyId: userSurvey?.payload?.id!,
          },
        },
      });

      const userSurveyError = userSurveyResponse?.addUserSurveyResponse?.error;

      if (userSurveyError) {
        toast.error(userSurveyError);

        throw userSurveyError;
      }

      posthog?.capture("user_survey_submitted");

      setCurrentStep((prevStep) => prevStep + 1);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderStep = () => {
    const questionType = currentQuestion?.type;

    const commonProps: IQuestionProps = {
      question: currentQuestion,
      currentStep,
      isLastStep,
      setStep: setCurrentStep,
      savedQuestionData: currentQuestion?.id
        ? responseData?.[currentQuestion.id]
        : undefined,
      submitStepData,
      isLoading,
    };

    if (isFinalStep) {
      return <FinalStep onClose={onCloseHandler} />;
    }

    switch (questionType) {
      case QUESTION_TYPES.TEXT:
        return <Text {...commonProps} />;
      case QUESTION_TYPES.RANGE:
        return <Range {...commonProps} />;
      case QUESTION_TYPES.OPTIONS:
        return <Options {...commonProps} />;
    }
  };

  return (
    <Dialog
      fullScreen={isTablet}
      PaperProps={{
        style: {
          borderRadius: !isTablet ? "32px" : "0",
          width: isTablet ? "100%" : "620px",
          padding: isTablet ? "16px" : "32px 32px 24px 32px",
        },
      }}
      open={open}
      onClose={onCloseHandler}
    >
      {isUserSurveyLoading ? (
        <LoadingComponent />
      ) : (
        <Stack gap={5}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            {!isFinalStep ? (
              <Stepper
                label={t("dashboard.feedback.question")}
                currentStep={currentStep + 1}
                numberOfSteps={questions?.length ?? 0}
              />
            ) : (
              <Box />
            )}
            <IconButton onClick={onCloseHandler}>
              <CloseRounded color="primary" />
            </IconButton>
          </Stack>
          {renderStep()}
        </Stack>
      )}
    </Dialog>
  );
};

export default FeedbackSurvey;
