import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMoneyTransactionsAggregateQueryVariables = Types.Exact<{
  companyId: Types.Scalars['String'];
}>;


export type GetMoneyTransactionsAggregateQuery = { __typename?: 'Query', transactionsAggregate?: { __typename?: 'TransactionAggregatePayload', error?: string | null, payload?: Array<{ __typename?: 'TransactionAggregate', moneyIn?: number | null, dimension?: string | null, moneyOut?: number | null } | null> | null } | null };


export const GetMoneyTransactionsAggregateDocument = gql`
    query GetMoneyTransactionsAggregate($companyId: String!) {
  transactionsAggregate(doolaCompanyId: $companyId) {
    error
    payload {
      moneyIn
      dimension
      moneyOut
    }
  }
}
    `;

/**
 * __useGetMoneyTransactionsAggregateQuery__
 *
 * To run a query within a React component, call `useGetMoneyTransactionsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoneyTransactionsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoneyTransactionsAggregateQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetMoneyTransactionsAggregateQuery(baseOptions: Apollo.QueryHookOptions<GetMoneyTransactionsAggregateQuery, GetMoneyTransactionsAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMoneyTransactionsAggregateQuery, GetMoneyTransactionsAggregateQueryVariables>(GetMoneyTransactionsAggregateDocument, options);
      }
export function useGetMoneyTransactionsAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMoneyTransactionsAggregateQuery, GetMoneyTransactionsAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMoneyTransactionsAggregateQuery, GetMoneyTransactionsAggregateQueryVariables>(GetMoneyTransactionsAggregateDocument, options);
        }
export type GetMoneyTransactionsAggregateQueryHookResult = ReturnType<typeof useGetMoneyTransactionsAggregateQuery>;
export type GetMoneyTransactionsAggregateLazyQueryHookResult = ReturnType<typeof useGetMoneyTransactionsAggregateLazyQuery>;
export type GetMoneyTransactionsAggregateQueryResult = Apollo.QueryResult<GetMoneyTransactionsAggregateQuery, GetMoneyTransactionsAggregateQueryVariables>;