import { useState, useEffect } from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { logOutUser } from "state/authSlice";
import { useAppDispatch } from "state/hooks";
import { ROUTER_PATHS } from "routes/routes";
import {
  FormControlLabel,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { EmailRegex } from "utils/regexValidations";
import { useApolloClient } from "@apollo/client/react/hooks/useApolloClient";
import utils, { colors } from "utils";
import StyledCheckbox from "components/FormElements/Checkbox";
import ButtonBox from "components/ButtonBox";
import useSignInAndValidateUser from "hooks/useSignInAndValidate";
import { usePostHog } from "posthog-js/react";
import { toast } from "toast";
import { Trans, useTranslation } from "react-i18next";
import CustomInput from "components/Form/CustomInput";
import bcrypt from "bcryptjs-react";
import HintBox from "components/HintBox";

const Login: React.FC = () => {
  const apolloClient = useApolloClient();
  const dispatch = useAppDispatch();
  const posthog = usePostHog();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [showHelpBox, setShowHelpBox] = useState(false);

  const { loginAndFetchData } = useSignInAndValidateUser();

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const { control, formState, handleSubmit } = useForm({
    defaultValues: { username: "", password: "" },
  });
  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  useEffect(() => {
    posthog?.capture("login-view");
  }, [posthog]);

  useEffect(() => {
    const hashedKey = searchParams.get("key");
    const email = searchParams.get("email")?.toLowerCase();

    if (email && hashedKey) {
      const password = `${email}password`;
      const isKeyAProperHash = bcrypt.compareSync(password, hashedKey);

      if (isKeyAProperHash) {
        setShowHelpBox(true);
      } else {
        setShowHelpBox(false);
      }
    } else {
      setShowHelpBox(false);
    }
  }, [searchParams]);

  useEffect(() => {
    dispatch(logOutUser());

    if (apolloClient) {
      apolloClient.stop();
      apolloClient.clearStore();
    }
  }, [apolloClient, dispatch]);

  const onSubmitHandler = async ({ username, password }: FieldValues) => {
    setIsLoading(true);

    try {
      await loginAndFetchData(username, password);
    } catch {
      setIsLoading(false);

      toast.error("Invalid email or password");
    }
  };

  return (
    <Stack gap={5}>
      <Stack gap={1}>
        <Typography variant={isTablet ? "h3" : "h2"}>
          {t("login.header")} 👋
        </Typography>
        <span>
          <Trans
            i18nKey={"login.subHeader"}
            values={{
              getStarted: t("general.getStarted"),
            }}
            components={[
              <Link
                component={RouterLink}
                to={ROUTER_PATHS.PRE_PURCHASE_REGISTRATION}
                onClick={() => posthog?.capture("login-exit")}
                fontWeight={500}
                color={colors.blue}
                sx={{
                  textDecoration: "none",
                  transition: "all 3s. ease-out",
                  "&:hover": {
                    color: colors.charcoal,
                  },
                }}
              />,
            ]}
          />
        </span>
        {showHelpBox && (
          <HintBox
            body={
              <Trans
                i18nKey={"login.emailCampaignHint"}
                values={{
                  forgotPassword: t("login.forgotPassword"),
                }}
                components={[
                  <Link
                    component={RouterLink}
                    to={ROUTER_PATHS.RECOVER_PASSWORD}
                    fontWeight={500}
                    color={colors.blue}
                    sx={{
                      textDecoration: "none",
                      transition: "all 3s. ease-out",
                      "&:hover": {
                        color: colors.charcoal,
                      },
                    }}
                  />,
                ]}
              />
            }
          />
        )}
      </Stack>
      {/* TODO: We can uncomment this once we work on google sso. */}
      {/* <Stack>
        <Button
          buttonText={t("login.signInWithGoogle")}
          dark={false}
          startIcon={<Google />}
          onClick={() => {}}
        />
        <Divider sx={{ mt: 5, borderBottomWidth: "15px" }}>
          {t("general.or")}
        </Divider>
      </Stack> */}
      <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
        <Stack gap={2}>
          <Controller
            name={"username"}
            {...COMMON_PROPS}
            rules={{
              required: true,
              validate: (value) =>
                EmailRegex.test(value.trim()) ||
                "Please use a valid email address",
            }}
            render={({ field }) => (
              <CustomInput
                {...field}
                id="username"
                placeholder={t("general.emailPlaceholder") as string}
                label={t("general.email") as string}
                autoComplete="username"
              />
            )}
          />
          <Controller
            name="password"
            {...COMMON_PROPS}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <CustomInput
                {...field}
                isProtected
                placeholder={t("general.passwordPlaceholder") as string}
                label={t("general.password") as string}
                autoComplete="password"
              />
            )}
          />
        </Stack>
        <Stack
          my={4}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <FormControlLabel
            control={
              <StyledCheckbox
                checked={checked}
                onChange={(event) => setChecked(event.target.checked)}
                name="checked"
                color="primary"
                size="small"
                sx={{
                  borderRadius: "2px",
                }}
              />
            }
            label={
              <Typography
                variant="h6"
                color={utils.secondaryColors.secondaryDarker}
                fontWeight={500}
              >
                {t("login.rememberMe")}
              </Typography>
            }
          />
          <Link
            variant="h6"
            component={RouterLink}
            to={ROUTER_PATHS.RECOVER_PASSWORD}
            color="text.primary"
            fontWeight={500}
            sx={{ textDecoration: "none" }}
          >
            {t("login.forgotPassword")}
          </Link>
        </Stack>
        <ButtonBox
          isLoading={isLoading}
          disabled={!formState.isValid}
          onClickNext={() => onSubmitHandler}
          rightButtonText={t("general.logIn") as string}
          showBackButton={false}
        />
      </form>
    </Stack>
  );
};

export default Login;
