import * as Types from '../types';

import { gql } from '@apollo/client';
import { CcorpExecutiveInfoFragmentDoc } from './Company.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCompanyCCorpExecutiveInfoMutationVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  president: Types.ExecutiveNameInput;
  secretary: Types.ExecutiveNameInput;
  treasurer: Types.ExecutiveNameInput;
  directors: Array<Types.InputMaybe<Types.ExecutiveNameInput>> | Types.InputMaybe<Types.ExecutiveNameInput>;
}>;


export type UpdateCompanyCCorpExecutiveInfoMutation = { __typename?: 'Mutation', company?: { __typename?: 'CompanyMutation', updateCcorpExecInfo?: { __typename?: 'Company', id: string, ccorpExecInfo?: { __typename?: 'CcorpExecutiveInfo', president: { __typename?: 'executiveName', firstName: string, lastName: string }, secretary: { __typename?: 'executiveName', firstName: string, lastName: string }, treasurer: { __typename?: 'executiveName', firstName: string, lastName: string }, directors: Array<{ __typename?: 'executiveName', firstName: string, lastName: string } | null> } | null, services?: Array<{ __typename?: 'Services', service: Types.CompanyServices, variant?: Types.ServiceVariant | null, status: Types.ServiceCategory, subStatus?: string | null }> | null } | null } | null };


export const UpdateCompanyCCorpExecutiveInfoDocument = gql`
    mutation UpdateCompanyCCorpExecutiveInfo($companyId: ID!, $president: executiveNameInput!, $secretary: executiveNameInput!, $treasurer: executiveNameInput!, $directors: [executiveNameInput]!) {
  company(id: $companyId) {
    updateCcorpExecInfo(
      president: $president
      secretary: $secretary
      treasurer: $treasurer
      directors: $directors
    ) {
      id
      ccorpExecInfo {
        ...CcorpExecutiveInfo
      }
      services {
        service
        variant
        status
        subStatus
      }
    }
  }
}
    ${CcorpExecutiveInfoFragmentDoc}`;
export type UpdateCompanyCCorpExecutiveInfoMutationFn = Apollo.MutationFunction<UpdateCompanyCCorpExecutiveInfoMutation, UpdateCompanyCCorpExecutiveInfoMutationVariables>;

/**
 * __useUpdateCompanyCCorpExecutiveInfoMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyCCorpExecutiveInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyCCorpExecutiveInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyCCorpExecutiveInfoMutation, { data, loading, error }] = useUpdateCompanyCCorpExecutiveInfoMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      president: // value for 'president'
 *      secretary: // value for 'secretary'
 *      treasurer: // value for 'treasurer'
 *      directors: // value for 'directors'
 *   },
 * });
 */
export function useUpdateCompanyCCorpExecutiveInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyCCorpExecutiveInfoMutation, UpdateCompanyCCorpExecutiveInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyCCorpExecutiveInfoMutation, UpdateCompanyCCorpExecutiveInfoMutationVariables>(UpdateCompanyCCorpExecutiveInfoDocument, options);
      }
export type UpdateCompanyCCorpExecutiveInfoMutationHookResult = ReturnType<typeof useUpdateCompanyCCorpExecutiveInfoMutation>;
export type UpdateCompanyCCorpExecutiveInfoMutationResult = Apollo.MutationResult<UpdateCompanyCCorpExecutiveInfoMutation>;
export type UpdateCompanyCCorpExecutiveInfoMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyCCorpExecutiveInfoMutation, UpdateCompanyCCorpExecutiveInfoMutationVariables>;