import { toast as toast_, ToastOptions } from "react-toastify";

import { Toast } from "./toast";

const DEFAULT_OPTIONS = {
  position: toast_.POSITION.BOTTOM_LEFT,
  hideProgressBar: true,
  closeButton: false,
  autoClose: 6000,
  type: "info",
};

class ToastDispatcher {
  _dispatch({
    message,
    subject,
    options,
  }: {
    message: string;
    subject: string;
    options: ToastOptions;
  }) {
    const compiledOptions = {
      ...DEFAULT_OPTIONS,
      ...(options || {}),
    } as ToastOptions;
    toast_(
      () => (
        <Toast severity={compiledOptions["type"]} subject={subject}>
          {message}
        </Toast>
      ),
      compiledOptions
    );
  }

  info(message: string, options: ToastOptions = {}) {
    this._dispatch({
      message,
      subject: "Info",
      options: { ...options, type: "info" },
    });
  }

  success(message: string = "Success!", options: ToastOptions = {}) {
    this._dispatch({
      message,
      subject: "Success",
      options: { ...options, type: "success" },
    });
  }

  warning(message: string = "Warning!", options: ToastOptions = {}) {
    this._dispatch({
      message,
      subject: "Warning",
      options: { ...options, type: "warning" },
    });
  }

  error(message: string = "Something went wrong!", options: ToastOptions = {}) {
    this._dispatch({
      message,
      subject: "Error",
      options: { ...options, type: "error" },
    });
  }
}

export const toast = new ToastDispatcher();
