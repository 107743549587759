import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  NormalizedCacheObject,
} from "@apollo/client";
import { cache } from "cache";
import config from "config";

import errorLink from "./links/error-link";
import authLink from "./links/auth-link";

const httpLink = createHttpLink({
  uri: config.api.GRAPHQL_URL,
});

const httpAuthLink = authLink.concat(httpLink);

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link: ApolloLink.from([errorLink, httpAuthLink]),
});
