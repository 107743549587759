import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import utils, { colors } from "utils";
import { ReactComponent as SearchUser } from "assets/Money/SearchUser.svg";

const NoBeneficiaries = () => {
  const { t } = useTranslation();
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  return (
    <Grid item xs={12}>
      <Stack
        direction={isTablet ? "column" : "row"}
        alignItems={"center"}
        gap={isTablet ? 3 : 4}
        sx={{
          backgroundColor: colors.lightGrey,
          paddingX: isTablet ? 3 : 6,
          paddingY: isTablet ? 3 : 8,
          width: "100%",
          borderRadius: isTablet ? 6 : 8,
        }}
      >
        <SearchUser height={isTablet ? "100px" : "150px"} />
        <Stack textAlign={isTablet ? "center" : "left"}>
          <Typography mb={1} fontSize={16} fontWeight={500}>
            {t("money.beneficiary.noBeneficiaryTitle")}
          </Typography>
          <Typography fontSize={16}>
            {t("money.beneficiary.noBeneficiarySubTitle")}
          </Typography>
        </Stack>
      </Stack>
    </Grid>
  );
};

export default NoBeneficiaries;
