import config, { isProduction } from "config";
import { ROUTER_PATHS } from "routes/routes";

// start date is based on EST timezone
export const TAX_SEASON_START_DATE = new Date("2024-01-29T00:00:00-05:00");

export const redirectTaxesBaseUri = `${config.basePath}${ROUTER_PATHS.TAXES}`;

export const TAX_FILING_CALENDLY_LINK = isProduction
  ? "https://calendly.com/d/33n-2nb-x8c/tax-meeting-with-doola-tax-expert"
  : "https://calendly.com/admin-doola";
export const TAX_FILING_BOOKMATE_CALENDLY_LINK = isProduction
  ? "https://calendly.com/d/45q-7pk-tvv/irs-tax-consultation"
  : "https://calendly.com/tax-bookmate";
export const CPA_CALENDLY_LINK = isProduction
  ? "https://calendly.com/d/5gf-75z-bxy/general-tax-consultation-with-doola-tax-expert"
  : "https://calendly.com/admin-doola";

// for consultation event type
export const TAX_FILING_CONSULTATION = "Tax Filing";
export const CPA_CONSULTATION = "CPA";
