import * as Types from '../types';

import { gql } from '@apollo/client';
import { AuthTokensInfoFragmentDoc } from './AuthRefreshTokens.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AuthSignInMutationVariables = Types.Exact<{
  credentials: Types.EmailPassword;
}>;


export type AuthSignInMutation = { __typename?: 'Mutation', auth?: { __typename?: 'AuthMutation', signIn?: { __typename?: 'AuthResponseTokens', success: boolean, message: string, customerId?: string | null, tokens?: { __typename?: 'AuthTokens', idToken: string, refreshToken: string, accessToken: string } | null } | null } | null };


export const AuthSignInDocument = gql`
    mutation AuthSignIn($credentials: EmailPassword!) {
  auth {
    signIn(credentials: $credentials) {
      success
      message
      customerId
      tokens {
        ...AuthTokensInfo
      }
    }
  }
}
    ${AuthTokensInfoFragmentDoc}`;
export type AuthSignInMutationFn = Apollo.MutationFunction<AuthSignInMutation, AuthSignInMutationVariables>;

/**
 * __useAuthSignInMutation__
 *
 * To run a mutation, you first call `useAuthSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authSignInMutation, { data, loading, error }] = useAuthSignInMutation({
 *   variables: {
 *      credentials: // value for 'credentials'
 *   },
 * });
 */
export function useAuthSignInMutation(baseOptions?: Apollo.MutationHookOptions<AuthSignInMutation, AuthSignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthSignInMutation, AuthSignInMutationVariables>(AuthSignInDocument, options);
      }
export type AuthSignInMutationHookResult = ReturnType<typeof useAuthSignInMutation>;
export type AuthSignInMutationResult = Apollo.MutationResult<AuthSignInMutation>;
export type AuthSignInMutationOptions = Apollo.BaseMutationOptions<AuthSignInMutation, AuthSignInMutationVariables>;