import { CloseRounded } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ButtonBox, { RightButtonIconTypes } from "components/ButtonBox";
import utils from "utils";
import { ConfirmationDialogProps } from "./types";

const ConfirmationDialog = ({
  confirmationDialogDetails,
  setConfirmationDialogDetails,
  handleOnConfirmation,
  body,
  title,
  handleOnConfirmationBtnText,
}: ConfirmationDialogProps) => {
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const handleOnConfirmationDialogClose = () => {
    setConfirmationDialogDetails({ ...confirmationDialogDetails, show: false });
  };

  return (
    <Dialog
      fullScreen={isTablet}
      PaperProps={{
        style: {
          ...(!isTablet && { width: "700px" }),
          borderRadius: !isTablet ? "32px" : "0",
          padding: isTablet ? "1rem" : "2rem",
        },
      }}
      open={confirmationDialogDetails.show}
      onClose={handleOnConfirmationDialogClose}
    >
      <Stack>
        <DialogTitle sx={{ padding: "0px" }} mb={2}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontSize={20} fontWeight={700}>
              {title}
            </Typography>
            <IconButton onClick={handleOnConfirmationDialogClose}>
              <CloseRounded color="primary" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Typography fontSize={16} fontWeight={400} mb={4}>
          {body}
        </Typography>
        <ButtonBox
          styles={{ alignItems: isTablet ? "flex-start" : "flex-end" }}
          rightButtonText={handleOnConfirmationBtnText}
          leftIcon={"close"}
          rightIcon={RightButtonIconTypes.REMOVE}
          onClickBack={handleOnConfirmationDialogClose}
          onClickNext={() => handleOnConfirmation(confirmationDialogDetails.id)}
        />
      </Stack>
    </Dialog>
  );
};

export default ConfirmationDialog;
