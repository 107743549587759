import { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  ChartEvent,
  ActiveElement,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import { TransactionAggregate } from "graphql/__generated__/types";
import utils, { colors } from "utils";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import NoStatisticsUI from "./NoStatisticsUI";
import { ReactComponent as DownloadIcon } from "assets/Money/download.svg";
import { ReactComponent as UploadIcon } from "assets/Money/upload.svg";
import { moneyFormatter } from "utils/formatters";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

type Props = {
  transactionsAggregate: Array<TransactionAggregate | null>;
  monthLabels: Array<string>;
  moneyInData: Array<number>;
  moneyOutData: Array<number>;
  totalMoneyIn: number;
  totalMoneyOut: number;
};

const MoneyChart = ({
  transactionsAggregate,
  monthLabels,
  moneyInData,
  moneyOutData,
  totalMoneyIn,
  totalMoneyOut,
}: Props) => {
  const [moneyIn, setMoneyIn] = useState(totalMoneyIn);
  const [moneyOut, setMoneyOut] = useState(totalMoneyOut);
  const barChartContainerRef = useRef<HTMLInputElement>(null);
  const [clickedIndex, setClickedIndex] = useState<number | null>();
  const [
    clickedOutsideOfBarChartContainer,
    setClickedOutsideOfBarChartContainer,
  ] = useState(true);

  const handleBarBgColorChange = (dataIndex: number, isMoneyIn: boolean) => {
    if (dataIndex === clickedIndex) {
      return isMoneyIn ? colors.blue : colors.contentInverseTertiary;
    } else {
      return isMoneyIn ? colors.borderBlue : colors.darkGrey;
    }
  };

  const data = {
    labels: monthLabels,
    datasets: [
      {
        label: "Credit",
        data: moneyInData,
        backgroundColor: (context: any) => {
          const index = context.dataIndex;
          return handleBarBgColorChange(index, true);
        },
        borderWidth: 0,
        borderRadius: 100,
        hoverBackgroundColor: colors.blue,
      },
      {
        label: "Debit",
        data: moneyOutData,
        backgroundColor: (context: any) => {
          const index = context.dataIndex;
          return handleBarBgColorChange(index, false);
        },
        borderWidth: 0,
        borderRadius: 100,
        hoverBackgroundColor: colors.contentInverseTertiary,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 16,
            weight: 400,
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    onClick: (_: ChartEvent, elements: ActiveElement[]) => {
      if (elements.length > 0) {
        const clickedElementIndex = elements[0].index;

        const moneyInValue = data.datasets[0].data[clickedElementIndex];
        const moneyOutValue = data.datasets[1].data[clickedElementIndex];

        setClickedIndex(clickedElementIndex);

        setMoneyIn(moneyInValue);
        setMoneyOut(moneyOutValue);
      } else {
        setMoneyIn(totalMoneyIn);
        setMoneyOut(totalMoneyOut);
        setClickedIndex(null);
      }
    },
  };

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);

  const barGraphContainerWidth = 800 + (monthLabels.length - 5) * 160;

  const { t } = useTranslation();

  const handleClickOutside = (event: any) => {
    if (
      barChartContainerRef.current &&
      !barChartContainerRef.current.contains(event.target)
    ) {
      setClickedOutsideOfBarChartContainer(true);
      setClickedIndex(null);
    } else {
      setClickedOutsideOfBarChartContainer(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (clickedOutsideOfBarChartContainer) {
      setMoneyIn(totalMoneyIn);
      setMoneyOut(totalMoneyOut);
    }
  }, [clickedOutsideOfBarChartContainer, totalMoneyIn, totalMoneyOut]);

  return (
    <>
      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent={"space-between"}
        alignItems={isMobile ? "flex-start" : "center"}
        gap={isMobile ? 1 : 0}
      >
        <Typography variant="h2" fontSize={isTablet ? 20 : 24} fontWeight={700}>
          {t("money.overview.statistics")}
        </Typography>
        <Stack
          direction={"row"}
          gap={2}
          alignItems={"center"}
          justifyContent={isMobile ? "center" : "flex-end"}
          width={"100%"}
        >
          <Stack
            gap={1}
            direction={"row"}
            sx={{
              border: `1px solid ${colors.borderBlue}`,
              borderRadius: 3,
              py: isMobile ? "5px" : "10px",
              px: isMobile ? "8px" : "16px",
              backgroundColor: colors.lightBlue,
            }}
            alignItems={"center"}
          >
            <DownloadIcon />
            <Typography
              fontSize={isMobile ? 14 : 16}
              fontWeight={500}
              color={colors.blue}
            >
              +{moneyFormatter(moneyIn)}
            </Typography>
          </Stack>
          <Stack
            gap={1}
            direction={"row"}
            sx={{
              border: `1px solid ${colors.darkGrey}`,
              borderRadius: 3,
              py: isMobile ? "5px" : "10px",
              px: isMobile ? "8px" : "16px",
              backgroundColor: colors.grey,
            }}
            alignItems={"center"}
          >
            <UploadIcon />
            <Typography
              fontSize={isMobile ? 14 : 16}
              fontWeight={500}
              color={colors.contentTertiary}
            >
              -{moneyFormatter(moneyOut)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {transactionsAggregate.length > 0 ? (
        <Box sx={{ width: "100%", overflowX: "auto", paddingBottom: "15px" }}>
          <Box
            sx={{
              width: barGraphContainerWidth,
              maxHeight: "260px",
            }}
            ref={barChartContainerRef}
          >
            <Bar data={data} options={options} height="300" width="0" />
          </Box>
        </Box>
      ) : (
        <NoStatisticsUI />
      )}
    </>
  );
};

export default MoneyChart;
