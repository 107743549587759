import { useRef, useState } from "react";
import {
  Box,
  ClickAwayListener,
  Paper,
  Popper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import utils, { colors } from "utils";
import { CompanySelectorContent } from "./CompanySelectorContent";
import Transitions from "components/@extended/Transitions";
import { IconButtonStyled } from "./styles";
import useCompany from "graphql/hooks/UseCompany";
import useCustomer from "graphql/hooks/useCustomer";
import { useGetTokenFieldsQuery } from "graphql/__generated__/operations/Whoami.generated";
import { useAppDispatch } from "state/hooks";
import { setCurrentCompanyId } from "state/authSlice";
import { usePostHog } from "posthog-js/react";
import UnfoldMoreOutlinedIcon from "@mui/icons-material/UnfoldMoreOutlined";

interface ICompanySelectorProps {
  canAddCompany?: boolean;
}

const CompanySelector: React.FC<ICompanySelectorProps> = ({
  canAddCompany = true,
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const posthog = usePostHog();

  const { currentCompany } = useCompany();
  const { currentUser } = useCustomer();
  const { data: tokenData } = useGetTokenFieldsQuery();

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);

  const companyName = currentCompany?.name || "";
  const companyIds = tokenData?.myToken?.authorizedCompanyIds?.split(",");

  const handleToggle = () => {
    posthog?.capture("click-company-switcher");
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleClickOnCompany = (id: string) => {
    posthog?.capture("switch-company", { location: "company-switcher" });
    dispatch(setCurrentCompanyId({ currentCompanyId: id }));
  };

  if (!companyIds || !currentCompany || !currentUser) return null;

  const isDarkMode = utils.isRouteMatchingWithKey("books");
  const iconColor = theme.palette.mode === "dark" ? "grey.200" : "grey.900";

  return (
    <Box>
      <IconButtonStyled
        variant="contained"
        aria-label="open companyselector"
        ref={anchorRef}
        aria-controls={open ? "companyselector-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          spacing={1}
        >
          <Typography
            color={isDarkMode ? colors.white : colors.black}
            fontWeight={500}
            sx={{
              textWrap: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {companyName}
          </Typography>
          <UnfoldMoreOutlinedIcon sx={{ color: iconColor }} />
        </Stack>
      </IconButtonStyled>
      <Popper
        placement={"bottom-start"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal={false}
        sx={{ zIndex: "1200" }}
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <ClickAwayListener onClickAway={handleClose}>
                <Paper
                  sx={{
                    boxShadow: `0 4px 16px 0 ${colors.contentInverseTertiary}`,
                    width: 330,
                    minWidth: 240,
                    maxWidth: 330,
                    [theme.breakpoints.down("md")]: {
                      maxWidth: 300,
                    },
                    borderRadius: 6,
                    p: 2,
                    pr: 1,
                  }}
                >
                  <CompanySelectorContent
                    companyIds={[...companyIds]}
                    currentCompany={currentCompany}
                    onClickCompany={handleClickOnCompany}
                    canAddCompany={canAddCompany}
                  />
                </Paper>
              </ClickAwayListener>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default CompanySelector;
