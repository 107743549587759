import { OnboardingStakeholderType } from "graphql/__generated__/types";

export enum MemberDialogFormModeEnum {
  add = "add",
  edit = "edit",
}

export type MemberDialogStateProps = {
  show: boolean;
  stakeholderId: string;
  mode: MemberDialogFormModeEnum;
  onboardingStakeholderType?: OnboardingStakeholderType | null;
};
