import { IStepProps } from "../../types";
import ButtonBox from "components/ButtonBox";
import { AddBeneficiarySteps } from "../../data";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAddMoneyBeneficiaryMutation } from "graphql/__generated__/operations/MoneyAddBeneficiary.generated";
import { useUpdateMoneyBeneficiaryMutation } from "graphql/__generated__/operations/MoneyUpdateBeneficiary.generated";
import useCompany from "graphql/hooks/UseCompany";
import {
  CurrencyDetail,
  LookupTypeKey,
  PayoutMethodType,
} from "graphql/__generated__/types";
import { useTranslation } from "react-i18next";
import {
  FormControlLabel,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import utils, { colors } from "utils";
import useGetMoneyLookups from "views/DashboardApp/Money/hooks/useGetMoneyLookups";
import useGetBeneficiaries from "../../../hooks/useGetBeneficiaries";
import useGetCurrency from "../../../../hooks/useGetCurrency";
import useGetExchangeRate from "../../../../hooks/useGetExchangeRate";
import useGetRoutingCode from "../../../hooks/useGetRoutingCode";
import {
  INITIAL_TRANSFER_METHOD_INFORMATION_VALUES,
  TransferMethodInformationDetails,
} from "./helpers";
import { Controller, useForm } from "react-hook-form";
import StyledCheckbox from "components/FormElements/Checkbox";
import { Windows1252Regex } from "utils/regexValidations";
import CustomInput from "components/Form/CustomInput";
import CustomSelect from "components/Form/CustomSelect";
import { LoadingComponent } from "components/Loading";
import { AccountBalanceOutlined, SettingsOutlined } from "@mui/icons-material";
import { toast } from "toast";
import {
  RoutingCodeTypes,
  TransferMethodEnum,
  TransferMethods,
  USD_CURRENCY_KEY,
  US_KEY,
  getTransferMethod,
} from "views/DashboardApp/Money/helpers";
import AlertNotification from "components/AlertNotification";

interface ITransferMethodInformationProps extends IStepProps {
  setIsFinalStep: Dispatch<SetStateAction<boolean>>;
}

const TransferMethodInformation = ({
  setCurrentStep,
  setIsFinalStep,
  beneficiaryData,
  beneficiaryHashId,
}: ITransferMethodInformationProps) => {
  const { currentCompany } = useCompany();

  const [addBeneficiary] = useAddMoneyBeneficiaryMutation();
  const [updateBeneficiary] = useUpdateMoneyBeneficiaryMutation();
  const { refetchBeneficiaries } = useGetBeneficiaries();

  const { getCurrency, isCurrencyDataLoading, currencyData } = useGetCurrency();
  const { getRoutingCode, isRoutingCodeDataLoading, routingCodeData } =
    useGetRoutingCode();
  const { getExchangeRate, isExchangeRateDataLoading, exchangeRateData } =
    useGetExchangeRate();
  const {
    isCurrencyDataLoading: isUSCurrencyDataLoading,
    currencyData: USCurrencyData,
  } = useGetCurrency(US_KEY);
  const { lookupsData, isLookupsDataLoading } = useGetMoneyLookups({
    neededLookups: [LookupTypeKey.COUNTRYOFOPERATION, LookupTypeKey.STATE],
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmation, setConfirmation] = useState<boolean>(false);

  const isEditMode = !!beneficiaryHashId;

  const { t } = useTranslation();
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const { control, formState, handleSubmit, trigger, setValue, watch } =
    useForm<TransferMethodInformationDetails>({
      defaultValues: { ...INITIAL_TRANSFER_METHOD_INFORMATION_VALUES },
      mode: "onChange",
    });

  const destinationCountry = watch("destinationCountry");
  const destinationCurrency = watch("destinationCurrency");
  const transferMethod = watch("transferMethod");
  const routingCodeType1 = watch("routingCodeType1");

  const isInternationalWire = useMemo(
    () => transferMethod === TransferMethodEnum.INTERNATIONAL_WIRE,
    [transferMethod]
  );

  const isUSDSelected = useMemo(
    () => destinationCurrency === USD_CURRENCY_KEY,
    [destinationCurrency]
  );

  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  const currencies: { [key: string]: CurrencyDetail } = useMemo(
    () =>
      currencyData?.currencyCode && USCurrencyData?.currencyCode
        ? {
            ...(currencyData.feeInfo && {
              [currencyData.currencyCode]: currencyData,
            }),
            [USCurrencyData.currencyCode]: USCurrencyData,
          }
        : {},
    [USCurrencyData, currencyData]
  );

  const exchangeRate = useMemo(
    () => exchangeRateData?.fx_rate,
    [exchangeRateData?.fx_rate]
  );

  const configureRoutingCode = useCallback(() => {
    if (routingCodeData) {
      const routingCodeType = isUSDSelected
        ? RoutingCodeTypes.SWIFT
        : routingCodeData;

      const payoutMethod =
        routingCodeType === RoutingCodeTypes.SWIFT
          ? PayoutMethodType.SWIFT
          : PayoutMethodType.LOCAL;

      setValue("routingCodeType1", routingCodeType);
      setValue("payoutMethod", payoutMethod);
    }
  }, [isUSDSelected, routingCodeData, setValue]);

  useEffect(() => {
    if (destinationCountry) {
      getCurrency(destinationCountry);
      getRoutingCode(destinationCountry);
    }
  }, [destinationCountry, getCurrency, getRoutingCode]);

  useEffect(() => {
    if (
      routingCodeData &&
      transferMethod === TransferMethodEnum.INTERNATIONAL_WIRE
    ) {
      configureRoutingCode();
    }
  }, [configureRoutingCode, routingCodeData, transferMethod]);

  useEffect(() => {
    if (!!destinationCurrency && !isUSDSelected) {
      getExchangeRate(destinationCurrency);
    }
  }, [destinationCurrency, getExchangeRate, isUSDSelected]);

  useEffect(() => {
    if (isEditMode && beneficiaryData) {
      setValue(
        "beneficiaryAccountNumber",
        beneficiaryData.beneficiaryAccountNumber || ""
      );
      setValue("destinationCountry", beneficiaryData.destinationCountry || "");
      setValue(
        "destinationCurrency",
        beneficiaryData.destinationCurrency || ""
      );
      setValue(
        "payoutMethod",
        beneficiaryData.payoutMethod ||
          INITIAL_TRANSFER_METHOD_INFORMATION_VALUES.payoutMethod
      );
      setValue("routingCodeType1", beneficiaryData.routingCodeType1 || "");
      setValue("routingCodeValue1", beneficiaryData.routingCodeValue1 || "");

      setValue(
        "transferMethod",
        getTransferMethod({
          payoutMethod: beneficiaryData.payoutMethod || "",
          destinationCountry: beneficiaryData.destinationCountry,
          routingCodeType1: beneficiaryData.routingCodeType1,
        })
      );

      if (
        beneficiaryData.beneficiaryAccountNumber &&
        beneficiaryData.destinationCountry &&
        beneficiaryData.destinationCurrency &&
        beneficiaryData.payoutMethod &&
        beneficiaryData.routingCodeType1 &&
        beneficiaryData.routingCodeValue1
      ) {
        trigger();
      }
    } else {
      USCurrencyData?.iso2Name &&
        setValue("destinationCountry", USCurrencyData?.iso2Name);
      USCurrencyData?.currencyCode &&
        setValue("destinationCurrency", USCurrencyData?.currencyCode);
    }
  }, [
    USCurrencyData?.currencyCode,
    USCurrencyData?.iso2Name,
    beneficiaryData,
    isEditMode,
    setValue,
    trigger,
  ]);

  const onSubmitHandler = async (
    transferMethodInformation: TransferMethodInformationDetails
  ) => {
    try {
      setIsLoading(true);

      const { transferMethod, ...transferMethodData } =
        transferMethodInformation;

      if (isEditMode) {
        const data = await updateBeneficiary({
          variables: {
            ...beneficiaryData,
            beneficiaryHashId: beneficiaryHashId,
            doolaCompanyId: currentCompany?.id || "",
            ...transferMethodData,
          },
        });

        const errors = data?.data?.updateBeneficiary?.error;

        if (errors) {
          if (
            errors
              .toString()
              .includes(
                "Update beneficiary not allowed as transaction(s) are in progress."
              )
          ) {
            toast.error(t("money.beneficiary.addBeneficiary.error") as string);
          } else {
            toast.error(errors);
          }
          return;
        }
      } else {
        const data = await addBeneficiary({
          variables: {
            ...beneficiaryData,
            doolaCompanyId: currentCompany?.id || "",
            ...transferMethodData,
          },
        });

        const errors = data?.data?.beneficiary?.error;

        if (errors) {
          toast.error(errors);
          return;
        }
      }

      await refetchBeneficiaries();

      setIsFinalStep(true);
    } catch (error) {
      console.log(
        `Error while ${
          isEditMode ? "updating" : "adding"
        } beneficiary : , ${error}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  const isComponentLoading =
    isLookupsDataLoading || isUSCurrencyDataLoading || isLoading;

  const isDisabled =
    !formState.isValid ||
    !confirmation ||
    isComponentLoading ||
    isRoutingCodeDataLoading;

  if (isComponentLoading) {
    return (
      <Stack height={"200px"} alignItems={"center"} justifyContent={"center"}>
        <LoadingComponent />
      </Stack>
    );
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
      <Stack gap={4} mb={isTablet ? 9 : 0}>
        <Stack gap={3}>
          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
            <SettingsOutlined sx={{ fontSize: "20px" }} />
            <Typography fontSize={16} fontWeight={500}>
              {t("money.beneficiary.addBeneficiary.transferMethod")}
            </Typography>
          </Stack>
          <Stack direction={isTablet ? "column" : "row"} gap={isTablet ? 2 : 3}>
            <Controller
              name="transferMethod"
              {...COMMON_PROPS}
              rules={{
                pattern: {
                  value: Windows1252Regex,
                  message: t("error.invalidCharacters"),
                },
                required: true,
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomSelect
                  {...field}
                  disabled={isEditMode || isRoutingCodeDataLoading}
                  error={error !== undefined}
                  styles={{ width: "100%" }}
                  defaultValue={field.value}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    const value = e.target.value;

                    field.onChange(value);

                    const item = TransferMethods?.[value as TransferMethodEnum];

                    if (value === TransferMethodEnum.INTERNATIONAL_WIRE) {
                      configureRoutingCode();
                    } else {
                      setValue("payoutMethod", item.paymentMethod);
                      setValue("routingCodeType1", item.routingCodeType1);
                    }

                    USCurrencyData?.iso2Name &&
                      setValue("destinationCountry", USCurrencyData?.iso2Name);
                    USCurrencyData?.currencyCode &&
                      setValue(
                        "destinationCurrency",
                        USCurrencyData?.currencyCode
                      );
                  }}
                  renderValue={(value) => {
                    const item = TransferMethods?.[value as TransferMethodEnum];

                    return (
                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <Typography fontWeight={500} fontSize={16}>
                          {item.title}
                        </Typography>
                        <Typography fontSize={8}>•</Typography>
                        <Typography>
                          {t(`money.beneficiary.addBeneficiary.businessDays`, {
                            value: item.daysValue,
                          })}
                        </Typography>
                      </Stack>
                    );
                  }}
                >
                  {Object.entries(TransferMethods).map(
                    ([key, value], index) => (
                      <MenuItem
                        key={index}
                        value={value.title ?? ""}
                        sx={{
                          display: "flex",
                          ...(isTablet && {
                            flexDirection: "column",
                            alignItems: "start",
                          }),
                          ...(!isTablet && {
                            gap: 1,
                          }),
                        }}
                      >
                        <Typography fontWeight={500} fontSize={16}>
                          {key}
                        </Typography>
                        {!isTablet && <Typography fontSize={8}>•</Typography>}
                        <Typography>
                          {t(`money.beneficiary.addBeneficiary.businessDays`, {
                            value: value.daysValue,
                          })}
                        </Typography>
                      </MenuItem>
                    )
                  )}
                </CustomSelect>
              )}
            />
          </Stack>
        </Stack>
        <Stack gap={3}>
          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
            <AccountBalanceOutlined sx={{ fontSize: "20px" }} />
            <Typography fontSize={16} fontWeight={500}>
              {t("money.beneficiary.addBeneficiary.bankDetails")}
            </Typography>
          </Stack>
          {transferMethod === TransferMethodEnum.INTERNATIONAL_WIRE ? (
            <>
              <Stack
                direction={isTablet ? "column" : "row"}
                gap={isTablet ? 2 : 3}
              >
                {lookupsData?.COUNTRYOFOPERATION && (
                  <Controller
                    name="destinationCountry"
                    {...COMMON_PROPS}
                    rules={{
                      pattern: {
                        value: Windows1252Regex,
                        message: t("error.invalidCharacters"),
                      },
                      required: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <CustomSelect
                        {...field}
                        disabled={isEditMode}
                        error={error !== undefined}
                        styles={{ width: "100%" }}
                        placeholder={
                          t(
                            "money.beneficiary.addBeneficiary.country"
                          ) as string
                        }
                        label={
                          t(
                            "money.beneficiary.addBeneficiary.country.label"
                          ) as string
                        }
                        value={destinationCountry}
                        defaultValue={field.value}
                        onChange={(e: SelectChangeEvent<unknown>) => {
                          field.onChange(e.target.value);

                          setValue("destinationCurrency", "");

                          configureRoutingCode();
                        }}
                      >
                        {lookupsData.COUNTRYOFOPERATION.map((item, index) => (
                          <MenuItem key={index} value={item?.key ?? ""}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    )}
                  />
                )}
                <Controller
                  name="destinationCurrency"
                  {...COMMON_PROPS}
                  rules={{
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomSelect
                      {...field}
                      disabled={
                        isCurrencyDataLoading ||
                        !destinationCountry ||
                        isUSCurrencyDataLoading ||
                        isEditMode
                      }
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      placeholder={
                        t("money.beneficiary.addBeneficiary.currency") as string
                      }
                      label={
                        t(
                          "money.beneficiary.addBeneficiary.currency.label"
                        ) as string
                      }
                      defaultValue={field.value}
                      renderValue={(value) => {
                        const item: CurrencyDetail =
                          currencies?.[value as string];

                        return value ? (
                          <Stack
                            direction={"row"}
                            gap={1}
                            alignItems={"center"}
                          >
                            {item?.iso2Name && (
                              <Stack
                                sx={{
                                  width: "24px",
                                  height: "16px",
                                }}
                              >
                                <img
                                  loading="lazy"
                                  src={`https://flagcdn.com/w20/${item?.iso2Name.toLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w40/${item?.iso2Name.toLowerCase()}.png 2x`}
                                  alt=""
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    borderRadius: "4px",
                                  }}
                                />
                              </Stack>
                            )}
                            <Typography>{item?.currencyCode}</Typography>
                            <Typography fontSize={8}>•</Typography>
                            <Typography>{item?.currencyName}</Typography>
                          </Stack>
                        ) : null;
                      }}
                    >
                      {Object.values(currencies).map((item, index) => (
                        <MenuItem
                          key={index}
                          value={item?.currencyCode ?? ""}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 1,
                          }}
                        >
                          {item?.iso2Name && (
                            <Stack
                              sx={{
                                width: "24px",
                                height: "16px",
                              }}
                            >
                              <img
                                loading="lazy"
                                src={`https://flagcdn.com/w20/${item?.iso2Name.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${item?.iso2Name.toLowerCase()}.png 2x`}
                                alt=""
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  borderRadius: "4px",
                                }}
                              />
                            </Stack>
                          )}
                          <Typography>{item?.currencyCode}</Typography>
                          <Typography fontSize={8}>•</Typography>
                          <Typography>{item?.currencyName}</Typography>
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  )}
                />
              </Stack>
              <Stack
                direction={isTablet ? "column" : "row"}
                gap={isTablet ? 2 : 3}
              >
                <Controller
                  name="beneficiaryAccountNumber"
                  {...COMMON_PROPS}
                  rules={{
                    required: true,
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        field.onChange(e.target.value.replace(/\s/g, ""));
                      }}
                      placeholder={
                        routingCodeType1 === RoutingCodeTypes.SWIFT
                          ? (t(
                              "money.beneficiary.addBeneficiary.IBAN"
                            ) as string)
                          : (t(
                              "money.beneficiary.addBeneficiary.accountNumber"
                            ) as string)
                      }
                      label={
                        routingCodeType1 === RoutingCodeTypes.SWIFT
                          ? (t(
                              "money.beneficiary.addBeneficiary.IBAN.label"
                            ) as string)
                          : (t(
                              "money.beneficiary.addBeneficiary.accountNumber.label"
                            ) as string)
                      }
                    />
                  )}
                />
                <Controller
                  name="routingCodeValue1"
                  {...COMMON_PROPS}
                  rules={{
                    required: true,
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        field.onChange(e.target.value.replace(/\s/g, ""));
                      }}
                      placeholder={
                        t("money.beneficiary.addBeneficiary.routingValue", {
                          value: routingCodeType1,
                        }) as string
                      }
                      label={
                        t(
                          "money.beneficiary.addBeneficiary.routingValue.label",
                          {
                            value: routingCodeType1,
                          }
                        ) as string
                      }
                    />
                  )}
                />
              </Stack>
            </>
          ) : (
            <Stack
              direction={isTablet ? "column" : "row"}
              gap={isTablet ? 2 : 3}
            >
              <Controller
                name="routingCodeValue1"
                {...COMMON_PROPS}
                rules={{
                  required: true,
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    error={error !== undefined}
                    styles={{ width: "100%" }}
                    placeholder={
                      routingCodeType1 === RoutingCodeTypes.SWIFT
                        ? (t("money.beneficiary.addBeneficiary.routingValue", {
                            value: routingCodeType1,
                          }) as string)
                        : (t(
                            "money.beneficiary.addBeneficiary.routingNumber"
                          ) as string)
                    }
                    label={
                      routingCodeType1 === RoutingCodeTypes.SWIFT
                        ? (t(
                            "money.beneficiary.addBeneficiary.routingValue.label",
                            {
                              value: routingCodeType1,
                            }
                          ) as string)
                        : (t(
                            "money.beneficiary.addBeneficiary.routingNumber.label"
                          ) as string)
                    }
                  />
                )}
              />
              <Controller
                name="beneficiaryAccountNumber"
                {...COMMON_PROPS}
                rules={{
                  required: true,
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    error={error !== undefined}
                    styles={{ width: "100%" }}
                    placeholder={
                      t(
                        "money.beneficiary.addBeneficiary.accountNumber"
                      ) as string
                    }
                    label={
                      t(
                        "money.beneficiary.addBeneficiary.accountNumber.label"
                      ) as string
                    }
                  />
                )}
              />
            </Stack>
          )}
        </Stack>
        {isInternationalWire &&
          destinationCurrency &&
          exchangeRate &&
          !isExchangeRateDataLoading &&
          !isUSDSelected && (
            <AlertNotification
              body={t(
                "money.beneficiary.addBeneficiary.currency.exchange.alert",
                {
                  value: `${parseFloat(exchangeRate).toFixed(
                    2
                  )} ${destinationCurrency}`,
                }
              )}
            />
          )}
        <Stack spacing={2} bgcolor={colors.lightGrey} p={3} borderRadius="24px">
          <FormControlLabel
            control={
              <StyledCheckbox
                iconBgColor={colors.lightGrey}
                iconbgcolorchecked={colors.black}
                iconBorderColor={colors.black}
                onChange={() => setConfirmation(!confirmation)}
                checked={confirmation}
              />
            }
            label={
              <Typography fontSize={16} color={colors.contentSecondary}>
                {t("money.beneficiary.addBeneficiary.confirmation")}
              </Typography>
            }
            sx={{ alignItems: "center", mr: 0, gap: 1 }}
          />
        </Stack>
        {isInternationalWire && !isEditMode && (
          <AlertNotification
            body={t("money.beneficiary.addBeneficiary.currency.alert")}
          />
        )}
        <ButtonBox
          isLoading={isLoading || isComponentLoading}
          disabled={isDisabled}
          rightButtonText="Save"
          leftIcon="back"
          onClickBack={() =>
            setCurrentStep(AddBeneficiarySteps.beneficiaryInformation)
          }
          onClickNext={() => onSubmitHandler}
        />
      </Stack>
    </form>
  );
};

export default TransferMethodInformation;
