import { Stack, IconButton, useMediaQuery } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import utils, { colors } from "utils";
import { DeleteOutline, SendOutlined } from "@mui/icons-material";

export enum RightButtonIconTypes {
  ADD = "add",
  NEXT = "next",
  REMOVE = "remove",
  SEND = "send",
}

type LeftIcon = "close" | "back";
type RightIcon = RightButtonIconTypes;

type Props = {
  styles?: React.CSSProperties;
  rightButtonText?: string;
  leftIcon?: LeftIcon;
  rightIcon?: RightIcon;
  disabled?: boolean;
  isLoading?: boolean;
  onClickBack?: () => void;
  onClickNext: () => void;
  showBackButton?: boolean;
  showRightIcon?: boolean;
  disableSticky?: boolean;
};

const ButtonBox = ({
  styles,
  rightButtonText = "Next",
  leftIcon = "back",
  rightIcon = RightButtonIconTypes.NEXT,
  disabled = false,
  isLoading = false,
  onClickBack,
  onClickNext,
  disableSticky = false,
  showBackButton = true,
  showRightIcon = true,
}: Props) => {
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);

  const renderRightIcon = () => {
    switch (rightIcon) {
      case RightButtonIconTypes.ADD:
        return <AddIcon />;

      case RightButtonIconTypes.REMOVE:
        return <DeleteOutline />;

      case RightButtonIconTypes.SEND:
        return <SendOutlined />;

      default:
        return <NavigateNextIcon />;
    }
  };

  const renderLeftIcon = () =>
    leftIcon === "close" ? <CloseIcon /> : <NavigateBeforeIcon />;

  return (
    <Stack
      sx={{
        ...(styles && { ...styles }),
        ...(isTablet &&
          !disableSticky && {
            position: "fixed",
            left: 0,
            bottom: 0,
            width: "100%",
            bgcolor: colors.white,
            borderTop: `1px solid ${colors.grey}`,
            zIndex: 1,
          }),
        py: 1,
      }}
    >
      <Stack
        direction="row"
        gap={1}
        sx={{
          ...(isTablet &&
            !disableSticky && {
              pl: isMobile ? 2 : 3,
              py: isMobile ? 0 : 1,
            }),
        }}
      >
        {showBackButton && (
          <IconButton
            onClick={onClickBack}
            sx={{
              bgcolor: colors.grey,
              color: colors.black,
              height: "56px",
              width: "56px",
              "&:hover": {
                bgcolor: colors.darkGrey,
              },
            }}
          >
            {renderLeftIcon()}
          </IconButton>
        )}
        <LoadingButton
          type="submit"
          loading={isLoading}
          disabled={disabled}
          onClick={onClickNext}
          variant="contained"
          endIcon={showRightIcon ? renderRightIcon() : ""}
          sx={{
            borderRadius: "30px",
            p: showRightIcon ? "16px 32px 16px 40px" : "16px 40px",
            boxShadow: "none",
            "&:hover": {
              bgcolor:
                rightIcon === RightButtonIconTypes.REMOVE
                  ? colors.red
                  : colors.charcoal,
              boxShadow: "none",
              color:
                rightIcon === RightButtonIconTypes.REMOVE
                  ? colors.lightRed
                  : colors.white,
            },
            backgroundColor:
              rightIcon === RightButtonIconTypes.REMOVE
                ? colors.lightRed
                : colors.black,
            color:
              rightIcon === RightButtonIconTypes.REMOVE
                ? colors.red
                : colors.white,
          }}
          data-testid="OnboardingStepNextButton"
        >
          {rightButtonText}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default ButtonBox;
