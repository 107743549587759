import { IStepProps } from "../../types";
import {
  Checkbox,
  ListItemText,
  MenuItem,
  SelectChangeEvent,
  Stack,
  useMediaQuery,
} from "@mui/material";
import ButtonBox from "components/ButtonBox";
import utils from "utils";
import { OnboardingStepsCategories, OnboardingStepsData } from "../../data";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Windows1252Regex } from "utils/regexValidations";
import { t } from "i18next";
import CustomSelect from "components/Form/CustomSelect";
import {
  INITIAL_RISK_ASSESSMENT_INFO_VALUES,
  InitialRiskAssessmentInfoValuesType,
} from "./helpers/InitialRiskAssessmentInfoData";
import { LookupTypeKey, WalletOnboard } from "graphql/__generated__/types";
import { LoadingComponent } from "components/Loading";
import useGetMoneyLookups from "views/DashboardApp/Money/hooks/useGetMoneyLookups";
import useOnboardingData from "../../hooks/useOnboardingData";
import { useSetMoneyOnboardingMutation } from "graphql/__generated__/operations/MoneyOnboardingValidation.generated";
import { isValueValid } from "views/DashboardApp/Money/helpers";
import { removeTypename } from "utils/helpers/removeTypename";
import { usePostHog } from "posthog-js/react";

const RiskAssessmentInformation = ({ setCurrentStep }: IStepProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { onboardingData, isOnboardingDataLoading } = useOnboardingData();
  const { lookupsData, isLookupsDataLoading } = useGetMoneyLookups({
    neededLookups: [
      LookupTypeKey.TOTALEMPLOYEES,
      LookupTypeKey.ANNUALTURNOVER,
      LookupTypeKey.INDUSTRYSECTOR,
      LookupTypeKey.INTENDEDUSEOFACCOUNT,
      LookupTypeKey.COUNTRYOFOPERATION,
    ],
  });
  const [updateMoneyOnboarding] = useSetMoneyOnboardingMutation();

  const { control, formState, setValue, handleSubmit, trigger } =
    useForm<InitialRiskAssessmentInfoValuesType>({
      defaultValues: { ...INITIAL_RISK_ASSESSMENT_INFO_VALUES },
      mode: "onChange",
    });

  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  useEffect(() => {
    const riskAssessmentData = onboardingData?.riskAssessmentInfo;

    if (riskAssessmentData) {
      setValue(
        "totalNumberOfEmployees",
        riskAssessmentData.totalNumberOfEmployees || ""
      );
      setValue("annualTurnover", riskAssessmentData.annualTurnover || "");
      setValue("industrySector", riskAssessmentData.industrySector || "");
      setValue(
        "intendedUseOfAccount",
        riskAssessmentData.intendedUseOfAccount || ""
      );
      setValue(
        "countriesOfOperation",
        riskAssessmentData.countriesOfOperation || []
      );
      setValue(
        "transactionCountries",
        riskAssessmentData.transactionCountries || []
      );

      if (
        isValueValid(riskAssessmentData.annualTurnover) &&
        isValueValid(riskAssessmentData.countriesOfOperation) &&
        isValueValid(riskAssessmentData.industrySector) &&
        isValueValid(riskAssessmentData.intendedUseOfAccount) &&
        isValueValid(riskAssessmentData.totalNumberOfEmployees) &&
        isValueValid(riskAssessmentData.transactionCountries)
      ) {
        trigger();
      }
    }
  }, [onboardingData, setValue, trigger]);

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const posthog = usePostHog();

  if (
    !lookupsData ||
    isLookupsDataLoading ||
    !onboardingData ||
    isOnboardingDataLoading
  ) {
    return (
      <Stack height={"200px"} alignItems={"center"} justifyContent={"center"}>
        <LoadingComponent />
      </Stack>
    );
  }

  const onSubmitHandler = async (data: InitialRiskAssessmentInfoValuesType) => {
    posthog?.capture("money_onboarding_risk_assessment_info_apply_btn_click");

    const filteredOnboardingData: WalletOnboard =
      removeTypename(onboardingData);

    try {
      setIsLoading(true);

      await updateMoneyOnboarding({
        variables: {
          ...filteredOnboardingData,
          riskAssessmentInfo: data,
        },
      });

      setCurrentStep(
        OnboardingStepsData[OnboardingStepsCategories.readyToSend]
      );
    } finally {
      setIsLoading(false);
    }
  };

  const isDisabled = !formState.isValid;

  return (
    <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
      <Stack gap={5} mt={4} mb={isTablet ? 2 : 0}>
        <Stack gap={2}>
          <Stack direction={isTablet ? "column" : "row"} gap={3}>
            {lookupsData?.TOTALEMPLOYEES && (
              <Controller
                name="totalNumberOfEmployees"
                {...COMMON_PROPS}
                rules={{
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                  required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomSelect
                    {...field}
                    error={error !== undefined}
                    styles={{ width: "100%" }}
                    placeholder={
                      t(
                        "money.onboarding.step.riskAssessmentInformation.employees.placeholder"
                      ) as string
                    }
                    label={
                      t(
                        "money.onboarding.step.riskAssessmentInformation.employees.label"
                      ) as string
                    }
                    defaultValue={field.value}
                    onChange={(e: SelectChangeEvent<unknown>) => {
                      field.onChange(e.target.value);
                    }}
                  >
                    {lookupsData.TOTALEMPLOYEES.map((item, index) => (
                      <MenuItem key={index} value={item?.key ?? ""}>
                        {item.description}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                )}
              />
            )}
            {lookupsData?.ANNUALTURNOVER && (
              <Controller
                name="annualTurnover"
                {...COMMON_PROPS}
                rules={{
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                  required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomSelect
                    {...field}
                    error={error !== undefined}
                    styles={{ width: "100%" }}
                    placeholder={
                      t(
                        "money.onboarding.step.riskAssessmentInformation.annualTurnover.placeholder"
                      ) as string
                    }
                    label={
                      t(
                        "money.onboarding.step.riskAssessmentInformation.annualTurnover.label"
                      ) as string
                    }
                    defaultValue={field.value}
                    onChange={(e: SelectChangeEvent<unknown>) => {
                      field.onChange(e.target.value);
                    }}
                  >
                    {lookupsData.ANNUALTURNOVER.map((item, index) => (
                      <MenuItem key={index} value={item?.key ?? ""}>
                        {item.description}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                )}
              />
            )}
          </Stack>
          <Stack direction={isTablet ? "column" : "row"} gap={3}>
            {lookupsData?.INDUSTRYSECTOR && (
              <Controller
                name="industrySector"
                {...COMMON_PROPS}
                rules={{
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                  required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomSelect
                    {...field}
                    error={error !== undefined}
                    styles={{ width: "100%" }}
                    placeholder={
                      t(
                        "money.onboarding.step.riskAssessmentInformation.industry.placeholder"
                      ) as string
                    }
                    label={
                      t(
                        "money.onboarding.step.riskAssessmentInformation.industry.label"
                      ) as string
                    }
                    defaultValue={field.value}
                    onChange={(e: SelectChangeEvent<unknown>) => {
                      field.onChange(e.target.value);
                    }}
                  >
                    {lookupsData.INDUSTRYSECTOR.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item?.key ?? ""}
                        sx={{
                          display: "block",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item.description}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                )}
              />
            )}
            {lookupsData?.INTENDEDUSEOFACCOUNT && (
              <Controller
                name="intendedUseOfAccount"
                {...COMMON_PROPS}
                rules={{
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                  required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomSelect
                    {...field}
                    error={error !== undefined}
                    styles={{ width: "100%" }}
                    placeholder={
                      t(
                        "money.onboarding.step.riskAssessmentInformation.intendedUse.placeholder"
                      ) as string
                    }
                    label={
                      t(
                        "money.onboarding.step.riskAssessmentInformation.intendedUse.label"
                      ) as string
                    }
                    defaultValue={field.value}
                    onChange={(e: SelectChangeEvent<unknown>) => {
                      field.onChange(e.target.value);
                    }}
                  >
                    {lookupsData.INTENDEDUSEOFACCOUNT.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item?.key ?? ""}
                        sx={{
                          display: "block",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item.description}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                )}
              />
            )}
          </Stack>
          <Stack direction={isTablet ? "column" : "row"} gap={3}>
            {lookupsData?.COUNTRYOFOPERATION && (
              <Controller
                name="countriesOfOperation"
                {...COMMON_PROPS}
                rules={{
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                  required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomSelect
                    {...field}
                    error={error !== undefined}
                    styles={{ width: "100%" }}
                    placeholder={
                      t(
                        "money.onboarding.step.riskAssessmentInformation.countryOperation.placeholder"
                      ) as string
                    }
                    label={
                      t(
                        "money.onboarding.step.riskAssessmentInformation.countryOperation.label"
                      ) as string
                    }
                    multiple
                    defaultValue={field.value}
                    onChange={(e: SelectChangeEvent<unknown>) => {
                      const selectedValue = e.target.value;

                      field.onChange(
                        typeof selectedValue === "string"
                          ? selectedValue.split(",")
                          : selectedValue
                      );
                    }}
                    renderValue={(selected) => {
                      const selectedCountries = selected as string[];
                      const descriptions = selectedCountries.map((key) => {
                        const selectedCountry =
                          lookupsData.COUNTRYOFOPERATION.find(
                            (item) => item?.key === key
                          );

                        return selectedCountry?.description || "";
                      });

                      return descriptions.join(", ");
                    }}
                  >
                    {lookupsData.COUNTRYOFOPERATION.map((item, index) => {
                      const key = item?.key ?? "";
                      const isChecked = field.value.includes(key);

                      return (
                        <MenuItem key={index} value={key}>
                          <Checkbox checked={isChecked} />
                          <ListItemText primary={item?.description} />
                        </MenuItem>
                      );
                    })}
                  </CustomSelect>
                )}
              />
            )}
            {lookupsData?.COUNTRYOFOPERATION && (
              <Controller
                name="transactionCountries"
                {...COMMON_PROPS}
                rules={{
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                  required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomSelect
                    {...field}
                    error={error !== undefined}
                    styles={{ width: "100%" }}
                    placeholder={
                      t(
                        "money.onboarding.step.riskAssessmentInformation.transactionCountry.placeholder"
                      ) as string
                    }
                    label={
                      t(
                        "money.onboarding.step.riskAssessmentInformation.transactionCountry.label"
                      ) as string
                    }
                    multiple
                    defaultValue={field.value}
                    onChange={(e: SelectChangeEvent<unknown>) => {
                      const selectedValue = e.target.value;

                      field.onChange(
                        typeof selectedValue === "string"
                          ? selectedValue.split(",")
                          : selectedValue
                      );
                    }}
                    renderValue={(selected) => {
                      const selectedCountries = selected as string[];
                      const descriptions = selectedCountries.map((key) => {
                        const selectedCountry =
                          lookupsData.COUNTRYOFOPERATION.find(
                            (item) => item?.key === key
                          );

                        return selectedCountry?.description || "";
                      });

                      return descriptions.join(", ");
                    }}
                  >
                    {lookupsData.COUNTRYOFOPERATION.map((item, index) => {
                      const key = item?.key ?? "";
                      const isChecked = field.value.includes(key);

                      return (
                        <MenuItem key={index} value={key}>
                          <Checkbox checked={isChecked} />
                          <ListItemText primary={item?.description} />
                        </MenuItem>
                      );
                    })}
                  </CustomSelect>
                )}
              />
            )}
          </Stack>
        </Stack>
        <ButtonBox
          isLoading={isLoading}
          disabled={isDisabled}
          rightButtonText={t("general.apply") ?? ""}
          onClickBack={() => {
            posthog?.capture(
              "money_onboarding_risk_assessment_info_back_btn_click"
            );

            setCurrentStep(
              OnboardingStepsData[
                OnboardingStepsCategories.applicantInformation
              ]
            );
          }}
          onClickNext={() => onSubmitHandler}
        />
      </Stack>
    </form>
  );
};

export default RiskAssessmentInformation;
