import { Stack, Typography, useMediaQuery } from "@mui/material";
import { t } from "i18next";
import utils, { colors } from "utils";
import { ReactComponent as Rocket } from "assets/Rocket.svg";
import ButtonBox from "components/ButtonBox";
import { Dispatch, SetStateAction, useState } from "react";
import { IStepProps } from "../../types";
import useOnboardingData from "../../hooks/useOnboardingData";
import { removeTypename } from "utils/helpers/removeTypename";
import { WalletOnboard } from "graphql/__generated__/types";
import { useSetMoneyFinalDocMutation } from "graphql/__generated__/operations/MoneyOnboardFinal.generated";
import { OnboardingStepsCategories, OnboardingStepsData } from "../../data";
import { ErrorOutlineOutlined, WarningAmberRounded } from "@mui/icons-material";
import { LoadingComponent } from "components/Loading";
import { usePostHog } from "posthog-js/react";

interface IReadToSendProps extends IStepProps {
  setIsFinalStep: Dispatch<SetStateAction<boolean>>;
}

const ReadyToSend = ({ setIsFinalStep, setCurrentStep }: IReadToSendProps) => {
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const posthog = usePostHog();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [errors, setErrors] = useState<(string | null)[]>([]);

  const { onboardingData, isOnboardingDataLoading } = useOnboardingData();

  const [updateMoneyFinalDocument] = useSetMoneyFinalDocMutation();

  const onSubmitHandler = async () => {
    posthog.capture("money_onboarding_ready_to_send_btn_click");

    try {
      setIsLoading(true);

      const filteredResponseData: WalletOnboard =
        removeTypename(onboardingData);

      const { data: responseData } = await updateMoneyFinalDocument({
        variables: { ...filteredResponseData },
      });

      const errors = (responseData && responseData?.onboard?.error) ?? [];

      if (errors?.length > 0) {
        setErrors(errors);
        return;
      }

      setIsFinalStep(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (!onboardingData || isOnboardingDataLoading) {
    return (
      <Stack height={"200px"} alignItems={"center"} justifyContent={"center"}>
        <LoadingComponent />
      </Stack>
    );
  }

  const isDisabled = !!errors.length || isLoading;

  return (
    <Stack gap={5}>
      {errors.length > 0 ? (
        <Stack gap={3}>
          <Stack gap={1}>
            <Stack direction={"row"} alignItems={"center"} gap={1.5}>
              <ErrorOutlineOutlined />
              <Typography fontSize={16} fontWeight={500}>
                {t("money.onboarding.step.readyToSend.header.title")}
              </Typography>
            </Stack>
            <Typography fontSize={16} color={colors.contentTertiary}>
              {t("money.onboarding.step.readyToSend.header.description1")}
              <br />
              {t("money.onboarding.step.readyToSend.header.description2")}
            </Typography>
          </Stack>
          <Stack
            sx={{ bgcolor: colors.lightRed, borderRadius: 6, p: 3 }}
            gap={2}
          >
            <WarningAmberRounded />
            <Stack gap={1}>
              {errors.map((error, index) => (
                <Typography key={index} fontSize={"1rem"}>
                  {"-> "}
                  {error}
                </Typography>
              ))}
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <>
          <Stack alignItems={"flex-start"}>
            <Rocket height={isTablet ? "220px" : "250px"} />
          </Stack>
          <Stack gap={2}>
            <Typography variant="h3" color={colors.black}>
              {t("money.onboarding.step.readyToSend.title")}
            </Typography>
            <Typography color={colors.contentSecondary}>
              {t("money.onboarding.step.readyToSend.description1")}
              <br />
              {t("money.onboarding.step.readyToSend.description2")}
            </Typography>
          </Stack>
        </>
      )}
      <ButtonBox
        isLoading={isLoading}
        disabled={isDisabled}
        rightButtonText={t("general.send") ?? ""}
        onClickBack={() => {
          posthog.capture("money_onboarding_ready_to_send_back_btn_click");

          setCurrentStep(
            OnboardingStepsData[
              OnboardingStepsCategories.riskAssessmentInformation
            ]
          );
        }}
        onClickNext={onSubmitHandler}
      />
    </Stack>
  );
};

export default ReadyToSend;
