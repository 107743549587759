import { useGetProductsQuery } from "graphql/__generated__/operations/Products.generated";
import { Product } from "graphql/__generated__/types";
import { PRODUCTS_DB_KEYS } from "resources/productsLookupKeys";

export default function useGetProcessingProducts() {
  const { data, loading: isProductQueryLoading } = useGetProductsQuery({
    fetchPolicy: "cache-first",
    errorPolicy: "all",
  });

  const productServices = data?.productService?.payload;

  const filteredFormationProducts = productServices?.filter((item) =>
    [
      PRODUCTS_DB_KEYS.EXPEDITE_FORMATION,
      PRODUCTS_DB_KEYS.EXPIDITE_EIN,
    ].includes(item?.key as PRODUCTS_DB_KEYS)
  );

  const findPlanByKey = (key: string) =>
    filteredFormationProducts?.find((item) => item?.key === key);

  const createFormationData = (plan?: Product | null) => ({
    name: plan?.name ?? "",
    lookupKey: plan?.key ?? "",
    value: plan?.stripePricingId ?? "",
    price: (plan?.usdPriceCents ?? 0) / 100,
  });

  const EXPEDITE_EIN = findPlanByKey(PRODUCTS_DB_KEYS.EXPIDITE_EIN);
  const EXPEDITE_FORMATION = findPlanByKey(PRODUCTS_DB_KEYS.EXPEDITE_FORMATION);

  const PROCESSING_PLANS = {
    EXPEDITED_EIN_DATA: createFormationData(EXPEDITE_EIN),
    EXPEDITED_FORMATION_DATA: createFormationData(EXPEDITE_FORMATION),
  };

  return { PROCESSING_PLANS, isProductQueryLoading };
}
