import { EntityType } from "graphql/__generated__/types";
import stateKeys from "./stateKeys";

export const ENTITY_ENDINGS = {
  [EntityType.LLC]: ["LLC", "L.L.C", "Limited Liability Company"],
  [EntityType.CCORP]: [
    "Corporation",
    "Corp.",
    "Incorporated",
    "Inc.",
    "Company",
  ],
  [EntityType.DAO]: ["DAO LLC", "DAO L.L.C", "DAO Limited Liability Company"],
};

type entityTypeProps = Record<
  EntityType,
  {
    value: EntityType.LLC | EntityType.DAO | EntityType.CCORP;
    states: {
      name: string | JSX.Element;
      value: string;
    }[];
  }
>;

const entityTypes: entityTypeProps = {
  DAO: {
    value: EntityType.DAO,
    states: [
      {
        name: "Wyoming",
        value: "WY",
      },
    ],
  },
  CCorp: {
    value: EntityType.CCORP,
    states: Object.entries(stateKeys).map(([key, value]) => ({
      name: value,
      value: key,
    })),
  },
  LLC: {
    value: EntityType.LLC,
    states: Object.entries(stateKeys).map(([key, value]) => ({
      name: value,
      value: key,
    })),
  },
};

export default entityTypes;
