import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { t } from "i18next";
import utils, { colors } from "utils";
import { ReactComponent as EWalletTransfers } from "assets/Money/e-wallet-transfers.svg";
import ButtonBox from "components/ButtonBox";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";

const FinalTransferStep = () => {
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const navigate = useNavigate();

  return (
    <>
      <Stack alignItems={"flex-start"}>
        <EWalletTransfers height={isTablet ? "220px" : "250px"} />
      </Stack>
      <Box>
        <Typography variant="h3" color={colors.black}>
          {t("money.transfer.step.final.title")}
        </Typography>
        <Typography color={colors.contentSecondary} mt={2} mb={4}>
          {t("money.transfer.step.final.description")}
        </Typography>
        <ButtonBox
          rightButtonText={"Back to Money"}
          showBackButton={false}
          onClickNext={() => {
            navigate(ROUTER_PATHS.MONEY_OVERVIEW);
          }}
        />
      </Box>
    </>
  );
};

export default FinalTransferStep;
