import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveMoneyBeneficiaryMutationVariables = Types.Exact<{
  doolaCompanyId: Types.Scalars['String'];
  beneficiaryHashId: Types.Scalars['String'];
}>;


export type RemoveMoneyBeneficiaryMutation = { __typename?: 'Mutation', deleteBeneficiary?: { __typename?: 'DataServiceResponse', error?: string | null, payload?: string | null } | null };


export const RemoveMoneyBeneficiaryDocument = gql`
    mutation RemoveMoneyBeneficiary($doolaCompanyId: String!, $beneficiaryHashId: String!) {
  deleteBeneficiary(
    doolaCompanyId: $doolaCompanyId
    beneficiaryHashId: $beneficiaryHashId
  ) {
    error
    payload
  }
}
    `;
export type RemoveMoneyBeneficiaryMutationFn = Apollo.MutationFunction<RemoveMoneyBeneficiaryMutation, RemoveMoneyBeneficiaryMutationVariables>;

/**
 * __useRemoveMoneyBeneficiaryMutation__
 *
 * To run a mutation, you first call `useRemoveMoneyBeneficiaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMoneyBeneficiaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMoneyBeneficiaryMutation, { data, loading, error }] = useRemoveMoneyBeneficiaryMutation({
 *   variables: {
 *      doolaCompanyId: // value for 'doolaCompanyId'
 *      beneficiaryHashId: // value for 'beneficiaryHashId'
 *   },
 * });
 */
export function useRemoveMoneyBeneficiaryMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMoneyBeneficiaryMutation, RemoveMoneyBeneficiaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMoneyBeneficiaryMutation, RemoveMoneyBeneficiaryMutationVariables>(RemoveMoneyBeneficiaryDocument, options);
      }
export type RemoveMoneyBeneficiaryMutationHookResult = ReturnType<typeof useRemoveMoneyBeneficiaryMutation>;
export type RemoveMoneyBeneficiaryMutationResult = Apollo.MutationResult<RemoveMoneyBeneficiaryMutation>;
export type RemoveMoneyBeneficiaryMutationOptions = Apollo.BaseMutationOptions<RemoveMoneyBeneficiaryMutation, RemoveMoneyBeneficiaryMutationVariables>;