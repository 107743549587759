import { useEffect } from "react";
import { useGetCustomerLazyQuery } from "graphql/__generated__/operations/Customer.generated";
import { useGetTokenFieldsLazyQuery } from "graphql/__generated__/operations/Whoami.generated";
import { getTokens } from "utils/auth";

const useCustomer = () => {
  const [fetchTokenData, { data: tokenData }] = useGetTokenFieldsLazyQuery();

  const [fetchCurrentUser, { data, loading: isCustomerLoading }] =
    useGetCustomerLazyQuery();

  const { idToken } = getTokens();

  useEffect(() => {
    if (idToken) {
      fetchTokenData();
    }
  }, [fetchTokenData, idToken]);

  useEffect(() => {
    const customerId = tokenData?.myToken?.authorizedCustomerId;
    // double check if we need the formation step here
    if (customerId) {
      fetchCurrentUser({
        variables: { customerId },
        errorPolicy: "all",
      });
    }
  }, [fetchCurrentUser, tokenData]);

  return {
    currentUser: data?.customer,
    isCustomerLoading,
  };
};

export default useCustomer;
