import styled from "styled-components";
import utils, { colors } from "utils";
import { ShadowStrong, HoverPointer } from "assets/GlobalStyled";

interface ItemProps {
  readonly $isSelected: boolean;
}

export const ItemWrapper = styled.div<ItemProps>`
  ${(props) => (props.$isSelected ? ShadowStrong : "")};
  background: ${({ $isSelected }) =>
    $isSelected ? colors.lightGrey : "transparent"};
  color: ${({ $isSelected }) =>
    $isSelected ? colors.charcoal : colors.contentTertiary};
  border-radius: 16px;
  box-shadow: none;
  margin: 0px 24px 0px 24px;
  display: flex;
  padding: 6px;
  transition: all 0.1s ease-out;
  ${HoverPointer}

  &:hover, &:focus {
    background: ${colors.lightGrey};
  }

  @media only screen and (max-width: ${utils.screenSize.tablet}) {
    margin: 0px 16px 0px 16px;
  }
`;

export const IconWrapper = styled.div<ItemProps>`
  display: flex;
  align-items: center;
  font-size: 18px;
  margin: auto 10px;
  color: ${({ $isSelected }) =>
    $isSelected ? colors.charcoal : colors.contentTertiary};
`;
