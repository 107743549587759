import { Grid, Stack, Typography } from "@mui/material";
import { CustomButton } from "components/Buttons";
import { CustomButtonVariants } from "components/Buttons/types";

const ErrorFallback = ({
  error,
  resetErrorBoundary,
}: {
  error: any;
  resetErrorBoundary: any;
}) => {
  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        sx={{ minHeight: "100vh" }}
        p={2}
      >
        <Grid item xs={12}>
          <Stack>
            <Typography variant="h2" mb={3}>
              Something went wrong...
            </Typography>
            <Typography variant="h3">
              We are working on fixing the problem,
            </Typography>
            <Typography variant="h3" mb={4}>
              Please try again
            </Typography>
            <CustomButton
              $variant={CustomButtonVariants.black}
              onClick={resetErrorBoundary}
              width={"100%"}
            >
              Refresh the page
            </CustomButton>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default ErrorFallback;
