import { Skeleton, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import utils, { colors } from "utils";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import NoRecentlyPaidUI from "../components/NoRecentlyPaidUI";
import Button from "components/Button";
import NavigateNext from "@mui/icons-material/NavigateNext";
import BeneficiaryListItem from "../components/BeneficiaryListItem";
import useGetRecentBeneficiaries from "../hooks/useGetRecentBeneficiaries";
import { BeneficiaryResponse } from "graphql/__generated__/types";
import { getTransferMethod } from "../helpers";
import { usePostHog } from "posthog-js/react";

const RecentlyPaid = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const currentPathName = useLocation().pathname;
  const posthog = usePostHog();

  const { recentBeneficiaries, isRecentBeneficiariesLoading } =
    useGetRecentBeneficiaries();

  const isDataLoading = isRecentBeneficiariesLoading || !recentBeneficiaries;

  return (
    <>
      {isDataLoading ? (
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={"400px"}
          animation="wave"
          sx={{ borderRadius: isTablet ? 6 : 8 }}
        />
      ) : (
        <Stack
          bgcolor={colors.lightAccentYellow}
          p={isTablet ? 3 : 4}
          borderRadius={isTablet ? 6 : 8}
          gap={isTablet ? 3 : 4}
        >
          <Typography
            variant="h2"
            fontSize={isTablet ? "20px" : "24px"}
            fontWeight={"700"}
          >
            {t("money.recentlyPaid")}
          </Typography>
          {recentBeneficiaries.length ? (
            <>
              {recentBeneficiaries?.map(
                (recentBeneficiary: BeneficiaryResponse | null) =>
                  recentBeneficiary && (
                    <BeneficiaryListItem
                      key={recentBeneficiary.beneficiaryHashId}
                      beneficiaryId={recentBeneficiary.beneficiaryHashId || ""}
                      recipientName={recentBeneficiary.beneficiaryName || ""}
                      transferType={getTransferMethod({
                        payoutMethod: recentBeneficiary.payoutMethod || "",
                        destinationCountry:
                          recentBeneficiary.destinationCountry || "",
                        destinationCurrency:
                          recentBeneficiary.destinationCurrency || "",
                      })}
                    />
                  )
              )}
              {currentPathName !== ROUTER_PATHS.MONEY_TRANSFER && (
                <Button
                  endIcon={<NavigateNext />}
                  buttonText={t("money.transferMoney")}
                  onClick={() => {
                    posthog?.capture(
                      "money_recently_paid_transfer_money_btn_click"
                    );
                    navigate(ROUTER_PATHS.MONEY_TRANSFER);
                  }}
                  styles={{ alignSelf: "flex-end" }}
                />
              )}
            </>
          ) : (
            <NoRecentlyPaidUI />
          )}
        </Stack>
      )}
    </>
  );
};

export default RecentlyPaid;
