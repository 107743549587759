import { Stack } from "@mui/material";
import { IntercomTriggerWrapper } from "components/IntercomTriggerWrapper";
import CompanyItem from "../CompanyItem";
import { Company } from "graphql/__generated__/types";
import { usePostHog } from "posthog-js/react";
import { Add } from "@mui/icons-material";
import Button from "components/Button";

type CompanySelectorProps = {
  companyIds: string[];
  currentCompany: Company;
  canAddCompany: boolean;
  onClickCompany: (id: string) => void;
};

export const CompanySelectorContent: React.FC<CompanySelectorProps> = ({
  companyIds,
  currentCompany,
  canAddCompany,
  onClickCompany,
}) => {
  const posthog = usePostHog();

  const onAddAnotherCompanyClick = () => {
    posthog?.capture("add-company", { location: "company-switcher" });
  };

  return (
    <Stack gap={2} sx={{ pr: 1, maxHeight: "350px", overflow: "auto" }}>
      <Stack gap={1}>
        {companyIds.map((id, index) => (
          <CompanyItem
            key={index}
            companyId={id}
            onClick={() => onClickCompany(id)}
            currentCompanyId={currentCompany?.id}
          />
        ))}
      </Stack>

      {canAddCompany && (
        <Stack alignItems={"flex-end"}>
          <IntercomTriggerWrapper>
            <Button
              endIcon={<Add />}
              buttonText={"Add another company"}
              onClick={onAddAnotherCompanyClick}
            />
          </IntercomTriggerWrapper>
        </Stack>
      )}
    </Stack>
  );
};
