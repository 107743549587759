import { CloseRounded } from "@mui/icons-material";
import { Box, Dialog, IconButton, Stack, useMediaQuery } from "@mui/material";
import Stepper from "components/Stepper";
import utils from "utils";
import FinalFilingModalStep from "./components/FinalFilingModalStep";
import {
  ITaxFilingStep,
  TaxFilingStepsCategories,
  TaxFilingStepsData,
} from "./data";
import StepContent from "./StepContent";
import { useMemo, useState } from "react";
import { t } from "i18next";
import { inHouseCustomersEmails } from "./inHouseCustomersData";
import useCustomer from "graphql/hooks/useCustomer";

interface IFilingModalProps {
  isModalOpen: boolean;
  onCloseModalHandler: () => void;
}

const FilingModal = ({
  isModalOpen,
  onCloseModalHandler,
}: IFilingModalProps) => {
  const { currentUser } = useCustomer();

  const [currentStep, setCurrentStep] = useState<ITaxFilingStep>(
    TaxFilingStepsData[TaxFilingStepsCategories.prepareInfo]
  );
  const [isFinalStep, setIsFinalStep] = useState<boolean>(false);
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);

  // Check if the user is an in-house customer
  const isInHouseCustomer = useMemo(
    () => inHouseCustomersEmails.includes(currentUser?.email || ""),

    // Todo: revert this to below logic after required time

    // Check if there's a 'YES' answer in tax questions
    // return (currentCompany?.taxQuestions || []).some(
    //   (question) => question?.answer === TaxQuestionResponses.YES
    // );
    [currentUser?.email]
  );

  const stepKeys = Object.keys(
    TaxFilingStepsData
  ) as TaxFilingStepsCategories[];
  const modalLength = stepKeys.length;

  // Find the index of the current step
  const currentStepIndex = stepKeys.findIndex(
    (key) => TaxFilingStepsData[key] === currentStep
  );

  return (
    <Dialog
      fullScreen={isTablet}
      PaperProps={{
        style: {
          borderRadius: !isTablet ? "32px" : "0",
          width: isTablet ? "100%" : "800px",
        },
      }}
      open={isModalOpen}
      onClose={onCloseModalHandler}
    >
      <Stack p={isMobile ? 2 : isTablet ? 3 : 4} gap={5}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {!isFinalStep ? (
            <Stepper
              label={t("general.step")}
              currentStep={currentStepIndex + 1}
              numberOfSteps={modalLength ?? 0}
            />
          ) : (
            <Box />
          )}
          <IconButton onClick={onCloseModalHandler}>
            <CloseRounded color="primary" />
          </IconButton>
        </Stack>
        {isFinalStep ? (
          <FinalFilingModalStep onClose={onCloseModalHandler} />
        ) : (
          <StepContent
            isInHouseCustomer={isInHouseCustomer}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            setIsFinalStep={setIsFinalStep}
            onCloseModalHandler={onCloseModalHandler}
          />
        )}
      </Stack>
    </Dialog>
  );
};

export default FilingModal;
