import { Box, Typography } from "@mui/material";
import { colors } from "utils";

interface IStepperProps {
  currentStep: number;
  numberOfSteps: number;
  label: string;
}

const Stepper = ({ currentStep, numberOfSteps, label }: IStepperProps) => {
  return (
    <Box>
      <Box
        style={{
          backgroundColor: colors.grey,
          width: "180px",
          height: "8px",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            backgroundColor: colors.green,
            width: `${(currentStep / numberOfSteps) * 100}%`,
            minWidth: "8px",
            transition: "width 0.5s",
            height: "8px",
            borderRadius: "12px",
          }}
        />
      </Box>
      <Typography color={colors.contentTertiary} mt={1}>
        {label} {currentStep}/{numberOfSteps}
      </Typography>
    </Box>
  );
};

export default Stepper;
