import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateMoneyBeneficiaryMutationVariables = Types.Exact<{
  beneficiaryAccountNumber: Types.Scalars['String'];
  beneficiaryAccountType?: Types.InputMaybe<Types.AccountType>;
  beneficiaryAddress?: Types.InputMaybe<Types.Scalars['String']>;
  beneficiaryCity?: Types.InputMaybe<Types.Scalars['String']>;
  beneficiaryCountryCode: Types.Scalars['String'];
  beneficiaryHashId: Types.Scalars['String'];
  beneficiaryName: Types.Scalars['String'];
  beneficiaryPostcode?: Types.InputMaybe<Types.Scalars['String']>;
  beneficiaryState?: Types.InputMaybe<Types.Scalars['String']>;
  destinationCountry: Types.Scalars['String'];
  destinationCurrency: Types.Scalars['String'];
  doolaCompanyId: Types.Scalars['String'];
  payoutMethod?: Types.InputMaybe<Types.PayoutMethodType>;
  routingCodeType1: Types.Scalars['String'];
  routingCodeValue1: Types.Scalars['String'];
}>;


export type UpdateMoneyBeneficiaryMutation = { __typename?: 'Mutation', updateBeneficiary?: { __typename?: 'WalletBeneficiaryResponse', error?: string | null, payload?: { __typename?: 'BeneficiaryResponse', beneficiaryAccountNumber?: string | null, beneficiaryAccountType?: Types.AccountType | null, beneficiaryBankAccountType?: string | null, beneficiaryBankCode?: string | null, beneficiaryBankName?: string | null, beneficiaryAddress?: string | null, beneficiaryCity?: string | null, beneficiaryCountryCode?: string | null, beneficiaryCreatedAt?: string | null, beneficiaryHashId?: string | null, beneficiaryName?: string | null, beneficiaryPostcode?: string | null, beneficiaryState?: string | null, destinationCountry?: string | null, destinationCurrency?: string | null, doolaCompanyId?: string | null, doolaCustomerId?: string | null, payoutHashId?: string | null, payoutMethod?: string | null, routingCodeType1?: string | null, routingCodeValue1?: string | null } | null } | null };


export const UpdateMoneyBeneficiaryDocument = gql`
    mutation UpdateMoneyBeneficiary($beneficiaryAccountNumber: String!, $beneficiaryAccountType: AccountType, $beneficiaryAddress: String, $beneficiaryCity: String, $beneficiaryCountryCode: String!, $beneficiaryHashId: String!, $beneficiaryName: String!, $beneficiaryPostcode: String, $beneficiaryState: String, $destinationCountry: String!, $destinationCurrency: String!, $doolaCompanyId: String!, $payoutMethod: PayoutMethodType, $routingCodeType1: String!, $routingCodeValue1: String!) {
  updateBeneficiary(
    beneficiaryHashId: $beneficiaryHashId
    beneficiaryDetails: {beneficiaryAccountNumber: $beneficiaryAccountNumber, beneficiaryAccountType: $beneficiaryAccountType, beneficiaryAddress: $beneficiaryAddress, beneficiaryCity: $beneficiaryCity, beneficiaryCountryCode: $beneficiaryCountryCode, beneficiaryName: $beneficiaryName, beneficiaryPostcode: $beneficiaryPostcode, beneficiaryState: $beneficiaryState, destinationCountry: $destinationCountry, destinationCurrency: $destinationCurrency, doolaCompanyId: $doolaCompanyId, payoutMethod: $payoutMethod, routingCodeType1: $routingCodeType1, routingCodeValue1: $routingCodeValue1}
  ) {
    payload {
      beneficiaryAccountNumber
      beneficiaryAccountType
      beneficiaryBankAccountType
      beneficiaryBankCode
      beneficiaryBankName
      beneficiaryAddress
      beneficiaryCity
      beneficiaryCountryCode
      beneficiaryCreatedAt
      beneficiaryHashId
      beneficiaryName
      beneficiaryPostcode
      beneficiaryState
      destinationCountry
      destinationCurrency
      doolaCompanyId
      doolaCustomerId
      payoutHashId
      payoutMethod
      routingCodeType1
      routingCodeValue1
    }
    error
  }
}
    `;
export type UpdateMoneyBeneficiaryMutationFn = Apollo.MutationFunction<UpdateMoneyBeneficiaryMutation, UpdateMoneyBeneficiaryMutationVariables>;

/**
 * __useUpdateMoneyBeneficiaryMutation__
 *
 * To run a mutation, you first call `useUpdateMoneyBeneficiaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMoneyBeneficiaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMoneyBeneficiaryMutation, { data, loading, error }] = useUpdateMoneyBeneficiaryMutation({
 *   variables: {
 *      beneficiaryAccountNumber: // value for 'beneficiaryAccountNumber'
 *      beneficiaryAccountType: // value for 'beneficiaryAccountType'
 *      beneficiaryAddress: // value for 'beneficiaryAddress'
 *      beneficiaryCity: // value for 'beneficiaryCity'
 *      beneficiaryCountryCode: // value for 'beneficiaryCountryCode'
 *      beneficiaryHashId: // value for 'beneficiaryHashId'
 *      beneficiaryName: // value for 'beneficiaryName'
 *      beneficiaryPostcode: // value for 'beneficiaryPostcode'
 *      beneficiaryState: // value for 'beneficiaryState'
 *      destinationCountry: // value for 'destinationCountry'
 *      destinationCurrency: // value for 'destinationCurrency'
 *      doolaCompanyId: // value for 'doolaCompanyId'
 *      payoutMethod: // value for 'payoutMethod'
 *      routingCodeType1: // value for 'routingCodeType1'
 *      routingCodeValue1: // value for 'routingCodeValue1'
 *   },
 * });
 */
export function useUpdateMoneyBeneficiaryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMoneyBeneficiaryMutation, UpdateMoneyBeneficiaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMoneyBeneficiaryMutation, UpdateMoneyBeneficiaryMutationVariables>(UpdateMoneyBeneficiaryDocument, options);
      }
export type UpdateMoneyBeneficiaryMutationHookResult = ReturnType<typeof useUpdateMoneyBeneficiaryMutation>;
export type UpdateMoneyBeneficiaryMutationResult = Apollo.MutationResult<UpdateMoneyBeneficiaryMutation>;
export type UpdateMoneyBeneficiaryMutationOptions = Apollo.BaseMutationOptions<UpdateMoneyBeneficiaryMutation, UpdateMoneyBeneficiaryMutationVariables>;