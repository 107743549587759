import { CloseRounded } from "@mui/icons-material";
import {
  Box,
  Dialog,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import utils from "utils";
import FinalAddBeneficiaryStep from "./components/FinalAddBeneficiaryStep";
import { AddBeneficiarySteps } from "./data";
import StepContent from "./StepContent";
import { useEffect, useState } from "react";
import { t } from "i18next";
import { INITIAL_BENEFICIARY_VALUES } from "./helpers";
import { BeneficiaryDetails } from "graphql/__generated__/types";

interface IAddBeneficiaryProps {
  isModalOpen: boolean;
  onCloseModalHandler: () => void;
  beneficiary?: BeneficiaryDetails;
  beneficiaryHashId?: string;
}

const AddBeneficiary = ({
  isModalOpen,
  onCloseModalHandler,
  beneficiary = INITIAL_BENEFICIARY_VALUES,
  beneficiaryHashId,
}: IAddBeneficiaryProps) => {
  const [currentStep, setCurrentStep] = useState<AddBeneficiarySteps>(
    AddBeneficiarySteps.beneficiaryInformation
  );

  const [isFinalStep, setIsFinalStep] = useState<boolean>(false);
  const [beneficiaryData, setBeneficiaryData] =
    useState<BeneficiaryDetails>(beneficiary);

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);

  useEffect(() => {
    setBeneficiaryData(beneficiary);
  }, [beneficiary]);

  const reset = () => {
    setCurrentStep(AddBeneficiarySteps.beneficiaryInformation);
    setIsFinalStep(false);
    setBeneficiaryData(INITIAL_BENEFICIARY_VALUES);
  };

  const onClose = () => {
    onCloseModalHandler();

    setTimeout(() => {
      reset();
    }, 1000);
  };

  return (
    <Dialog
      fullScreen={isTablet}
      PaperProps={{
        style: {
          borderRadius: !isTablet ? "32px" : "0",
          width: isTablet ? "100%" : "800px",
        },
      }}
      open={isModalOpen}
      onClose={onClose}
    >
      <Stack p={isMobile ? 2 : isTablet ? 3 : 4} gap={4}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {isFinalStep ? (
            <Box></Box>
          ) : (
            <Typography fontSize={isTablet ? 24 : 28} fontWeight={700}>
              {t("money.beneficiary.addBeneficiary.title")}
            </Typography>
          )}
          <IconButton onClick={onClose}>
            <CloseRounded color="primary" />
          </IconButton>
        </Stack>
        {isFinalStep ? (
          <FinalAddBeneficiaryStep onClose={onClose} />
        ) : (
          <StepContent
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            setIsFinalStep={setIsFinalStep}
            beneficiaryData={beneficiaryData}
            beneficiaryHashId={beneficiaryHashId}
            setBeneficiaryData={setBeneficiaryData}
            onCloseModalHandler={onClose}
          />
        )}
      </Stack>
    </Dialog>
  );
};

export default AddBeneficiary;
