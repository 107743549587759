import { ReactNode, useMemo } from "react";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import {
  createTheme,
  ThemeOptions,
  ThemeProvider,
  Theme,
  TypographyVariantsOptions,
} from "@mui/material/styles";
import useConfig from "hooks/useConfig";
import Palette from "./palette";
import Typography from "./typography";
import CustomShadows from "./shadows";
import { CustomShadowProps } from "./types";

import InterRegularTtf from "fonts/Inter/Inter-Regular.ttf";
import InterRegularWoff from "fonts/Inter/Inter-Regular.woff";
import InterRegularWoff2 from "fonts/Inter/Inter-Regular.woff2";
import InterMediumTtf from "fonts/Inter/Inter-Medium.ttf";
import InterMediumWoff from "fonts/Inter/Inter-Medium.woff";
import InterMediumWoff2 from "fonts/Inter/Inter-Medium.woff2";
import InterBoldTtf from "fonts/Inter/Inter-Bold.ttf";
import InterBoldWoff from "fonts/Inter/Inter-Bold.woff";
import InterBoldWoff2 from "fonts/Inter/Inter-Bold.woff2";

type ThemeCustomizationProps = {
  children: ReactNode;
};

export default function ThemeCustomization({
  children,
}: ThemeCustomizationProps) {
  const { themeDirection, mode, fontFamily } = useConfig();

  const theme: Theme = useMemo<Theme>(() => Palette(mode), [mode]);

  const themeTypography: TypographyVariantsOptions =
    useMemo<TypographyVariantsOptions>(
      () => Typography(fontFamily),
      [fontFamily]
    );
  const themeCustomShadows: CustomShadowProps = useMemo<CustomShadowProps>(
    () => CustomShadows(theme),
    [theme]
  );

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1440,
        },
      },
      direction: themeDirection,
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
      palette: theme.palette,
      customShadows: themeCustomShadows,
      typography: themeTypography,
      components: {
        MuiCssBaseline: {
          styleOverrides: `
              @font-face {
                font-family: "Inter";
                src: local("InterBold"),
                  url(${InterBoldTtf}) format("truetype")
                  url(${InterBoldWoff2}) format('woff2'),
                  url(${InterBoldWoff}) format('woff');
                font-weight: bold;
              }
              
              @font-face {
                font-family: "Inter";
                src: local("InterRegular"),
                    url(${InterRegularTtf}) format("truetype")
                    url(${InterRegularWoff2}) format('woff2'),
                    url(${InterRegularWoff}) format('woff');
              }

              @font-face {
                font-family: "Inter";
                src: local("InterMedium"),
                  url(${InterMediumTtf}) format("truetype")
                  url(${InterMediumWoff2}) format('woff2'),
                  url(${InterMediumWoff}) format('woff');
                font-weight: 500;
              }
          `,
        },
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
    }),
    [themeDirection, theme, themeTypography, themeCustomShadows]
  );

  const themes: Theme = createTheme(themeOptions);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
