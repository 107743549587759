import { GOOGLE_OPTIMIZE_EXPERIMENTS, SENTRY_DNS } from "configs";
import { POSTHOG_KEYS } from "configs/posthog";
import { DefaultConfigProps } from "models/ThemeConfig";
import { ROUTER_PATHS } from "routes/routes";

const local = {
  stageName: "local",
  basePath: "http://localhost:3333",
  api: {
    URL: "https://api.development.aws.doola.com",
    // GRAPHQL_URL: "https://api.development.aws.doola.com/web-api/graphql",
    GRAPHQL_URL: "http://localhost:4000/graphql", // TODO: change back to point to development web-api
  },
  GTM: {
    gtmId: "GTM-W2G8KHM",
  },
  GoogleOptimize: {
    experimentId: GOOGLE_OPTIMIZE_EXPERIMENTS.local,
  },
  books: {
    hurdlrCliendId:
      "RfXStwtTVcbW3Oy7Dn12o9fLPTdT4HVJ2cBkEralsS58Dhkoz3Icq67m95V9",
    environment: "SANDBOX",
  },
  partnerstack: {
    token: "TAWFf48Nlr3A7LQoeALWCVaXzgo4eDAO", // created 27th of March
    public_key: "pk_6KYSsG0jxq9NVPEiz6SnvApH4zTkiXK6",
    secret_key: "sk_rwp4nEjsB2Z99mgXjPsKRpYr6t3RwRMD",
  },
  intercomAppId: "tmtnoscr",
  stripeKey:
    "pk_test_51HRL70GIhH9I2BWrPoDGl8N5K6VDrefxhkbstXmNC6461xcrJCvAFQU6nbYPoIgvjEFl4MvSQej616FcCJjOYOiA00pULyyZ6d",
  sentryDns: SENTRY_DNS.local,
  posthogKeys: POSTHOG_KEYS.local,
};

const development = {
  stageName: "dev",
  basePath: "https://app.development.aws.doola.com",
  api: {
    URL: "https://api.development.aws.doola.com",
    GRAPHQL_URL: "https://api.development.aws.doola.com/web-api/graphql",
  },
  GTM: {
    gtmId: "GTM-W2G8KHM",
  },
  GoogleOptimize: {
    experimentId: GOOGLE_OPTIMIZE_EXPERIMENTS.development,
  },
  books: {
    hurdlrCliendId:
      "RfXStwtTVcbW3Oy7Dn12o9fLPTdT4HVJ2cBkEralsS58Dhkoz3Icq67m95V9",
    environment: "SANDBOX",
  },
  partnerstack: {
    token: "TAWFf48Nlr3A7LQoeALWCVaXzgo4eDAO", // created 27th of March
    public_key: "pk_6KYSsG0jxq9NVPEiz6SnvApH4zTkiXK6",
    secret_key: "sk_rwp4nEjsB2Z99mgXjPsKRpYr6t3RwRMD",
  },
  intercomAppId: "tmtnoscr",
  stripeKey:
    "pk_test_51HRL70GIhH9I2BWrPoDGl8N5K6VDrefxhkbstXmNC6461xcrJCvAFQU6nbYPoIgvjEFl4MvSQej616FcCJjOYOiA00pULyyZ6d",
  sentryDns: SENTRY_DNS.development,
  posthogKeys: POSTHOG_KEYS.development,
};

const staging = {
  stageName: "staging",
  basePath: "https://app.staging.aws.doola.com",
  api: {
    URL: "https://api.staging.aws.doola.com",
    GRAPHQL_URL: "https://graphql.staging.aws.doola.com/graphql",
  },
  GTM: {
    gtmId: "GTM-W2G8KHM",
  },
  GoogleOptimize: {
    experimentId: GOOGLE_OPTIMIZE_EXPERIMENTS.staging,
  },
  books: {
    hurdlrCliendId:
      "RfXStwtTVcbW3Oy7Dn12o9fLPTdT4HVJ2cBkEralsS58Dhkoz3Icq67m95V9",
    environment: "SANDBOX",
  },
  partnerstack: {
    token: "TAWFf48Nlr3A7LQoeALWCVaXzgo4eDAO", // created 27th of March
    public_key: "pk_6KYSsG0jxq9NVPEiz6SnvApH4zTkiXK6",
    secret_key: "sk_rwp4nEjsB2Z99mgXjPsKRpYr6t3RwRMD",
  },
  intercomAppId: "tmtnoscr",
  stripeKey:
    "pk_test_51HRL70GIhH9I2BWrPoDGl8N5K6VDrefxhkbstXmNC6461xcrJCvAFQU6nbYPoIgvjEFl4MvSQej616FcCJjOYOiA00pULyyZ6d",
  sentryDns: SENTRY_DNS.staging,
  posthogKeys: POSTHOG_KEYS.staging,
};

const sandbox = {
  stageName: "sandbox",
  basePath: "https://app.sandbox.aws.doola.com",
  api: {
    URL: "https://api.sandbox.aws.doola.com",
    GRAPHQL_URL: "https://graphql.sandbox.aws.doola.com/graphql",
  },
  GTM: {
    gtmId: "GTM-W2G8KHM",
  },
  GoogleOptimize: {
    experimentId: GOOGLE_OPTIMIZE_EXPERIMENTS.staging,
  },
  books: {
    hurdlrCliendId:
      "RfXStwtTVcbW3Oy7Dn12o9fLPTdT4HVJ2cBkEralsS58Dhkoz3Icq67m95V9",
    environment: "SANDBOX",
  },
  partnerstack: {
    token: "TAWFf48Nlr3A7LQoeALWCVaXzgo4eDAO", // created 27th of March
    public_key: "pk_6KYSsG0jxq9NVPEiz6SnvApH4zTkiXK6",
    secret_key: "sk_rwp4nEjsB2Z99mgXjPsKRpYr6t3RwRMD",
  },
  intercomAppId: "pu5xix6z",
  stripeKey:
    "pk_test_51HRL70GIhH9I2BWrPoDGl8N5K6VDrefxhkbstXmNC6461xcrJCvAFQU6nbYPoIgvjEFl4MvSQej616FcCJjOYOiA00pULyyZ6d",
  sentryDns: SENTRY_DNS.sandbox,
  posthogKeys: POSTHOG_KEYS.sandbox,
};

const production = {
  stageName: "prod",
  basePath: "https://app.doola.com",
  api: {
    URL: "https://api.production.aws.doola.com",
    GRAPHQL_URL: "https://api.production.aws.doola.com/web-api/graphql",
  },
  GTM: {
    gtmId: "GTM-TC26L6Q",
  },
  GoogleOptimize: {
    experimentId: GOOGLE_OPTIMIZE_EXPERIMENTS.production,
  },
  partnerstack: {
    token: "TAWFf48Nlr3A7LQoeALWCVaXzgo4eDAO", // created 27th of March
    public_key: "pk_R7cNwCSUIYgC4InogzFCeWdnF4RQwyDm",
    secret_key: "sk_S7lUarYmvms3QUzIa3NjePtWep26Ek15",
  },
  intercomAppId: "pu5xix6z",
  stripeKey:
    "pk_live_51HRL70GIhH9I2BWrxFa2p6VKDDiNsDnHYAysU6hmoHGe5HWV75B16HLaWZdcPrq9vOZqxiSRdPG2EZiXH4HYLGcu00rdjJXKf1",
  sentryDns: SENTRY_DNS.production,
  posthogKeys: POSTHOG_KEYS.production,
  books: {
    hurdlrCliendId:
      "f8kZK66vZ8E1L0634Q41a48zfvBtGgonketr2v35A40m6wFy9VGK3hm9nVOM",
    environment: "PROD",
  },
};

const getConfig = (env: string) => {
  switch (env) {
    case "prod":
    case "production":
      return production;
    case "sandbox":
      return sandbox;
    case "staging":
      return staging;
    case "local":
      return local;
    default:
      return development;
  }
};

export const isProduction = process.env.REACT_APP_ENV === "prod";
export const sidebarWidth = 260;
export const themeConfig: DefaultConfigProps = {
  defaultPath: ROUTER_PATHS.DASHBOARD,
  fontFamily: "'Inter', sans-serif",
  i18n: "en",
  miniDrawer: false,
  container: true,
  mode: "light",
  presetColor: "default",
  themeDirection: "ltr",
};

const config = {
  ...(process.env.REACT_APP_ENV
    ? getConfig(process.env.REACT_APP_ENV)
    : development),
};

export default config;
