import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCompanyActivityMutationVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  activity: Types.Scalars['String'];
}>;


export type UpdateCompanyActivityMutation = { __typename?: 'Mutation', company?: { __typename?: 'CompanyMutation', updateActivity?: { __typename?: 'Company', id: string, activity?: string | null, services?: Array<{ __typename?: 'Services', service: Types.CompanyServices, variant?: Types.ServiceVariant | null, status: Types.ServiceCategory, subStatus?: string | null }> | null } | null } | null };


export const UpdateCompanyActivityDocument = gql`
    mutation UpdateCompanyActivity($companyId: ID!, $activity: String!) {
  company(id: $companyId) {
    updateActivity(new: $activity) {
      id
      activity
      services {
        service
        variant
        status
        subStatus
      }
    }
  }
}
    `;
export type UpdateCompanyActivityMutationFn = Apollo.MutationFunction<UpdateCompanyActivityMutation, UpdateCompanyActivityMutationVariables>;

/**
 * __useUpdateCompanyActivityMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyActivityMutation, { data, loading, error }] = useUpdateCompanyActivityMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      activity: // value for 'activity'
 *   },
 * });
 */
export function useUpdateCompanyActivityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyActivityMutation, UpdateCompanyActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyActivityMutation, UpdateCompanyActivityMutationVariables>(UpdateCompanyActivityDocument, options);
      }
export type UpdateCompanyActivityMutationHookResult = ReturnType<typeof useUpdateCompanyActivityMutation>;
export type UpdateCompanyActivityMutationResult = Apollo.MutationResult<UpdateCompanyActivityMutation>;
export type UpdateCompanyActivityMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyActivityMutation, UpdateCompanyActivityMutationVariables>;