import {
  Stack,
  Typography,
  useTheme,
  FormControlLabel,
  Radio,
  Chip,
} from "@mui/material";
import { Member } from "graphql/__generated__/types";
import { colors } from "utils";

type MemberProps = {
  member: Member;
  value: number | null;
  checked: boolean;
  chipText?: string;
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const MemberCard = ({
  member,
  value,
  checked,
  chipText,
  onChangeHandler,
}: MemberProps) => {
  const theme = useTheme();

  return (
    <Stack spacing={1}>
      <FormControlLabel
        control={
          <Radio
            disableRipple
            name={"memberPosition"}
            checked={checked}
            onChange={onChangeHandler}
            value={value}
            sx={{
              padding: 0,
              color: theme.palette.secondary.light,
              "&.Mui-checked": {
                color: colors.blue,
              },
            }}
          />
        }
        label={
          <Stack spacing={3} direction="row" justifyContent="space-between">
            <Stack>
              <Stack direction={"row"} gap={2}>
                <Typography variant="h5">
                  {member.contactFullName ||
                    `${member.legalFirstName} ${member.legalLastName}`}
                </Typography>
                {chipText && (
                  <Chip
                    label={chipText}
                    sx={{
                      height: 24,
                      background: colors.green,
                      color: colors.white,
                      padding: 0,
                      borderRadius: 2,
                    }}
                  />
                )}
              </Stack>
              <Typography>
                SSN 🇺🇸: {member.ssn ? "Provided" : " N/A"}
              </Typography>
            </Stack>
          </Stack>
        }
        labelPlacement={"start"}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 3,
          margin: 0,
          "& .MuiFormControlLabel-label": {
            width: "100%",
          },
        }}
      />
    </Stack>
  );
};

export default MemberCard;
