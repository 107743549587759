import { useMediaQuery } from "@mui/material";
import { P } from "components/Fonts";
import InfoModal from "components/InfoModal";
import { useState } from "react";
import utils from "utils";
import { ToolTipDescription } from "./Description";
import { StyledTooltip } from "./StyledTooltip";
import { HelperIconWrapper, InfoIconWrapper, ToolTipWrapper } from "./styles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
export { InfoIconWrapper, ToolTipWrapper };

type Props = {
  title: JSX.Element | string;
  description: JSX.Element | string;
  $styledCss?: any;
  icon?: JSX.Element | undefined;
  shortVersion?: boolean;
  container?: HTMLElement;
};

export const InfoToolTip: React.FC<Props> = (props) => {
  const [refresh, setRefresh] = useState(false);

  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  return isMobile ? (
    <IconWithModal {...props} />
  ) : (
    <StyledTooltip
      PopperProps={{ container: props.container }}
      onOpen={() => setRefresh(!refresh)}
      title={
        <ToolTipWrapper>
          <InfoIconWrapper>{props.icon ?? <HelpOutlineIcon />}</InfoIconWrapper>
          <div>
            <P size="small" $emphasis>
              <span
                dangerouslySetInnerHTML={{ __html: props.title as string }}
              />
            </P>
            <br />
            <ToolTipDescription refresh={refresh} {...props} />
          </div>
        </ToolTipWrapper>
      }
    >
      <HelperIconWrapper $styledCss={props.$styledCss}>
        {props.icon ?? <HelpOutlineIcon />}
      </HelperIconWrapper>
    </StyledTooltip>
  );
};

const IconWithModal: React.FC<Props> = ({
  title,
  description,
  $styledCss,
  icon = <HelpOutlineIcon />,
}) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <HelperIconWrapper
        $styledCss={$styledCss}
        onClick={(event) => {
          event.preventDefault();
          setOpenModal(true);
        }}
      >
        {icon}
      </HelperIconWrapper>
      {openModal && (
        <InfoModal
          title={title}
          description={description}
          handleClose={() => setOpenModal(false)}
          fullWidth
        />
      )}
    </>
  );
};
