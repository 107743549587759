import {
  Box,
  Stack,
  Typography,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import utils, { colors } from "utils";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { MemoizedAvatar } from "./Avatar";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import { usePostHog } from "posthog-js/react";

type Props = {
  beneficiaryId: string;
  recipientName: string;
  transferType: string;
  countryCode?: string;
  transferIconColor?: string;
};

const BeneficiaryListItem = ({
  recipientName,
  transferType,
  countryCode,
  transferIconColor = colors.darkAccentYellow,
  beneficiaryId,
}: Props) => {
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const navigate = useNavigate();
  const posthog = usePostHog();

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      onClick={() => {
        posthog?.capture("money_beneficiary_item_click", {
          beneficiaryId,
        });

        navigate(`${ROUTER_PATHS.MONEY_SEND}?recipient=${beneficiaryId}`);
      }}
      sx={{ cursor: "pointer" }}
    >
      <Stack direction={"row"} alignItems={"center"}>
        <Box mr={2}>
          <MemoizedAvatar name={recipientName} countryCode={countryCode} />
        </Box>
        <Box>
          <Typography fontSize={16} fontWeight={"500"}>
            {recipientName}
          </Typography>
          <Typography fontSize={isTablet ? 12 : 14}>{transferType}</Typography>
        </Box>
      </Stack>
      <Stack>
        <IconButton
          sx={{
            width: 36,
            height: 36,
            bgcolor: transferIconColor,
            borderRadius: "50%",
          }}
        >
          <SendOutlinedIcon style={{ fontSize: 16, color: colors.black }} />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default BeneficiaryListItem;
