import {
  CompanyServices,
  ServiceCategory,
  Services,
} from "graphql/__generated__/types";
import {
  EinSteps,
  EssentialSteps,
  FormationSteps,
  StepStatusType,
} from "./data";

const isServiceComplete = (service: Services) => {
  return (
    service.status === ServiceCategory.COMPLETED ||
    service.status === ServiceCategory.ACTIVE
  );
};

const isServiceInProgress = (service: Services) => {
  return service.status === ServiceCategory.INPROGRESS;
};

const isServiceNotStarted = (service: Services) => {
  return service.status === ServiceCategory.NOTSTARTED;
};

type ServiceHandler = (service: Services, newStatus: StepStatusType) => void;

const handleFormationService: ServiceHandler = (service, newStatus) => {
  const updateStatus = (step: string, category: ServiceCategory) => {
    newStatus[step] = category;
  };

  if (isServiceComplete(service)) {
    updateStatus(
      FormationSteps.articlesOfOrganisation,
      ServiceCategory.COMPLETED
    );
    updateStatus(FormationSteps.orderProcessed, ServiceCategory.COMPLETED);
    updateStatus(
      FormationSteps.companyAndMemberProfile,
      ServiceCategory.COMPLETED
    );
    updateStatus(FormationSteps.formationFiled, ServiceCategory.COMPLETED);
  } else if (
    isServiceInProgress(service) &&
    service.subStatus === "WaitingForRaToSucceed"
  ) {
    updateStatus(FormationSteps.orderProcessed, ServiceCategory.COMPLETED);
    updateStatus(
      FormationSteps.companyAndMemberProfile,
      ServiceCategory.COMPLETED
    );
    updateStatus(FormationSteps.formationFiled, ServiceCategory.INPROGRESS);
  } else if (isServiceNotStarted(service)) {
    if (service.subStatus === "WaitingForMemberData") {
      updateStatus(FormationSteps.orderProcessed, ServiceCategory.COMPLETED);
      updateStatus(
        FormationSteps.companyAndMemberProfile,
        ServiceCategory.INPROGRESS
      );
    } else {
      updateStatus(FormationSteps.orderProcessed, ServiceCategory.INPROGRESS);
    }
  }
};

const handleEinService: ServiceHandler = (service, newStatus) => {
  const updateStatus = (step: string, category: ServiceCategory) => {
    newStatus[step] = category;
  };

  if (isServiceComplete(service)) {
    updateStatus(EinSteps.signSS4Form, ServiceCategory.COMPLETED);
    updateStatus(EinSteps.einApplicationProcessing, ServiceCategory.COMPLETED);
    updateStatus(EinSteps.einLetterRecieved, ServiceCategory.COMPLETED);
    updateStatus(
      EinSteps.waitingToCompleteFormation,
      ServiceCategory.COMPLETED
    );
  } else if (isServiceInProgress(service)) {
    if (
      service.subStatus === "WaitingForDocumentsFromIRS" ||
      service.subStatus === "WaitingToSendToIRS"
    ) {
      updateStatus(EinSteps.signSS4Form, ServiceCategory.COMPLETED);
      updateStatus(
        EinSteps.einApplicationProcessing,
        ServiceCategory.INPROGRESS
      );
    } else if (service.subStatus === "WaitingForSS4Signature") {
      updateStatus(EinSteps.signSS4Form, ServiceCategory.INPROGRESS);
    }
    updateStatus(
      EinSteps.waitingToCompleteFormation,
      ServiceCategory.COMPLETED
    );
  } else if (isServiceNotStarted(service)) {
    updateStatus(
      EinSteps.waitingToCompleteFormation,
      ServiceCategory.INPROGRESS
    );
  }
};

const handleEssentialService: ServiceHandler = (service, newStatus) => {
  newStatus[EssentialSteps[service.service]] = ServiceCategory.COMPLETED;
};

export const updateServiceStatus = (
  services: Services[],
  setStatus: React.Dispatch<React.SetStateAction<StepStatusType>>
) => {
  // resetting all the services to the NOTSTARTED status
  const newStatus: StepStatusType = Object.values({
    ...FormationSteps,
    ...EinSteps,
    ...EssentialSteps,
  }).reduce((r, key) => ({ ...r, [key]: ServiceCategory.NOTSTARTED }), {});

  const serviceHandlers = {
    [CompanyServices.FORMATION]: handleFormationService,
    [CompanyServices.EINCREATION]: handleEinService,
    [CompanyServices.ANNUALSTATEFILING]: handleEssentialService,
    [CompanyServices.BOOKKEEPING]: handleEssentialService,
    [CompanyServices.TAX]: handleEssentialService,
  } as Record<CompanyServices, ServiceHandler>;

  services.forEach((service) => {
    const handler = serviceHandlers[service.service];
    if (handler) {
      handler(service, newStatus);
    }
  });

  setStatus(newStatus);
};
