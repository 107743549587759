type DataObject = Record<string, any>;

/**
 * Recursively removes the "__typename" property from objects in the provided data.
 * @param data - The data object or array to process.
 * @returns A new object or array with "__typename" removed.
 */

export const removeTypename = (data: any): any => {
  // Check if data is an array
  if (Array.isArray(data)) {
    // If array, map over each item and apply removeTypename recursively
    return data.map((item) => removeTypename(item));
  } else if (typeof data === "object" && data !== null) {
    // If data is an object and not null
    const result: DataObject = {};

    // Iterate through object properties
    for (const key in data) {
      // Check if the property is not "__typename"
      if (key !== "__typename") {
        // Apply removeTypename recursively to the property value
        result[key] = removeTypename(data[key]);
      }
    }

    // Return the new object without "__typename"
    return result;
  }

  // If data is neither an array nor an object, return it as is
  return data;
};
