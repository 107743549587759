import { BeneficiaryResponse } from "graphql/__generated__/types";
import { useState, useEffect } from "react";
import useGetBeneficiaries from "../Beneficiary/hooks/useGetBeneficiaries";
import useDebounce from "hooks/useDebounce";

const useFilteredBeneficiaryList = () => {
  const [queryString, setQueryString] = useState<string>("");
  const [filteredBeneficiaries, setFilteredBeneficiaries] =
    useState<Array<BeneficiaryResponse | null>>();

  const { beneficiaries, isBeneficiariesLoading } = useGetBeneficiaries();
  const debouncedQueryString = useDebounce(queryString, 500);

  useEffect(() => {
    setFilteredBeneficiaries(beneficiaries || []);
  }, [beneficiaries]);

  useEffect(() => {
    const filteredBeneficiaryList =
      beneficiaries?.filter((beneficiary) =>
        beneficiary?.beneficiaryName
          ?.toLowerCase()
          .includes(debouncedQueryString.toLowerCase())
      ) || [];

    setFilteredBeneficiaries(filteredBeneficiaryList);
  }, [debouncedQueryString, beneficiaries]);

  const handleSearch = (q: string) => {
    setQueryString(q);
  };

  return {
    queryString,
    handleSearch,
    filteredBeneficiaries,
    isBeneficiariesLoading,
  };
};

export default useFilteredBeneficiaryList;
