import { CustomCard } from "components/Cards";
import { LinkReferral } from "components/LinkReferral";
import { ROUTER_PATHS } from "routes/routes";
import { colors } from "utils";
import { Stack, Typography } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Button from "components/Button";

export const InviteSomeone: React.FC<{}> = () => {
  const navigate = useNavigate();

  return (
    <CustomCard
      $styles={{
        background: colors.lightAccentGreen,
      }}
      $radius="strong"
      $padding={4}
    >
      <Typography variant="h3" mb={2}>
        Earn 15% on every referral 🚀
      </Typography>
      <LinkReferral />
      <Typography fontSize={16} color={colors.black} mt={2}>
        Know an entrepreneur who would love doola? For each person you invite
        that joins doola, you can earn a 15% commission*.
      </Typography>
      <Typography variant="caption" color={colors.contentSecondary}>
        *Excluding state fees
      </Typography>
      <Stack alignItems={"flex-end"}>
        <Button
          endIcon={<NavigateNext />}
          buttonText={"Refer & Earn"}
          onClick={() => {
            navigate(ROUTER_PATHS.REFERRALS);
          }}
        />
      </Stack>
    </CustomCard>
  );
};
