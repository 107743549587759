import { LoadingButton } from "@mui/lab";
import { colors } from "utils";
import { JSXElementConstructor, ReactElement } from "react";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";

type Props = {
  styles?: SxProps<Theme>;
  dark?: boolean;
  transparent?: boolean;
  buttonText: string;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  startIcon?: ReactElement<any, string | JSXElementConstructor<any>>;
  endIcon?: ReactElement<any, string | JSXElementConstructor<any>>;
  dataTestId?: string;
};

const Button = ({
  styles,
  dark = true,
  transparent = false,
  buttonText,
  disabled = false,
  isLoading = false,
  onClick = () => {},
  startIcon,
  endIcon,
  dataTestId,
}: Props) => (
  <LoadingButton
    type="submit"
    loading={isLoading}
    disabled={disabled}
    onClick={onClick}
    variant={transparent ? "text" : "contained"}
    startIcon={startIcon}
    endIcon={endIcon}
    sx={{
      ...{
        borderRadius: 6,
        p: endIcon
          ? "10px 16px 10px 24px"
          : startIcon
          ? "10px 24px 10px 16px"
          : "10px 24px",
        boxShadow: "none",
        ...(!dark &&
          !transparent && {
            bgcolor: colors.lightGrey,
            color: colors.black,
          }),
        "&:hover": {
          ...(!transparent && {
            bgcolor: dark ? colors.charcoal : colors.darkGrey,
          }),
          boxShadow: "none",
        },
        "&:disabled": {
          ...(dark && {
            bgcolor: colors.charcoal,
            color: isLoading ? colors.charcoal : colors.contentSecondary,
          }),
        },
      },
      ...(styles && { ...styles }),
    }}
    data-testid={dataTestId}
  >
    {buttonText}
  </LoadingButton>
);

export default Button;
