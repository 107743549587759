import { useEffect, useState } from "react";
import { useGetAccountsLazyQuery } from "graphql/__generated__/operations/MoneyAccounts.generated";
import useCompany from "graphql/hooks/UseCompany";

export default function useGetMoneyAccounts() {
  const { currentCompany } = useCompany();

  const [isAccountsDataLoading, setIsAccountsDataLoading] =
    useState<boolean>(true);

  const [isPendingReview, setIsPendingReview] = useState<boolean>(false);

  const [fetchAccountsData, { data, refetch }] = useGetAccountsLazyQuery({
    errorPolicy: "all",
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    setIsAccountsDataLoading(true);

    if (currentCompany?.id) {
      const getAccounts = async () => {
        try {
          const { data: responseData } = await fetchAccountsData({
            variables: {
              companyId: currentCompany.id,
            },
          });
          const error = responseData?.accounts?.error;

          if (error?.includes("Onboarding is not completed.")) {
            setIsPendingReview(true);
          }
        } catch (error) {
          console.error(
            "Failed to fetch the recent beneficiaries, error: ",
            error
          );
        } finally {
          setIsAccountsDataLoading(false);
        }
      };

      getAccounts();
    }
  }, [currentCompany?.id, fetchAccountsData]);

  return {
    accountsResponse: data?.accounts,
    accountsData: data?.accounts?.payload?.[0],
    refetchAccounts: refetch,
    isAccountsDataLoading,
    isPendingReview,
  };
}
