import {
  OnboardingApplicant,
  OnboardingDocumentType,
  OnboardingStakeholder,
} from "graphql/__generated__/types";
import { IdentityDocumentInfoValuesType } from "./types";

export const capitalizeFirstLetterAndJoin = (array: unknown): string => {
  if (!Array.isArray(array)) {
    return "";
  }

  const capitalizedArray = array.map((item) =>
    typeof item === "string" && item.length > 0
      ? item.charAt(0).toUpperCase() +
        item.slice(1).toLowerCase().split("_").join(" ")
      : item
  );

  return capitalizedArray.join(", ");
};

export const INTITAL_IDENTITY_DOCUMENT_INFO_VALUES: IdentityDocumentInfoValuesType =
  {
    onboardingDocType: OnboardingDocumentType.NATIONAL_ID,
    documentNumber: "",
    documentIssuanceCountry: "",
    expirationDate: "",
  };

export const OnboardingDocumentTypes = [
  {
    key: OnboardingDocumentType.NATIONAL_ID,
    description: "National ID",
  },
  {
    key: OnboardingDocumentType.PASSPORT,
    description: "Passport",
  },
  {
    key: OnboardingDocumentType.DRIVER_LICENCE,
    description: "Driving Licence",
  },
];

export const extractOnboardingApplicant = (
  stakeholder: OnboardingStakeholder
): OnboardingApplicant => {
  const {
    address,
    dateOfBirth,
    documents,
    firstName,
    lastName,
    nationality,
    professionalDetails,
  } = stakeholder;

  return {
    address,
    dateOfBirth,
    documents,
    firstName,
    lastName,
    nationality,
    contactCountryCode: nationality,
    professionalDetailsPositions: professionalDetails,
  };
};
