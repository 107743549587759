import { Suspense, useContext, useEffect } from "react";
import OnboardingAddress from "./OnboardingAddress";
import OnboardingIndustry from "./OnboardingIndustry";
import OnboardingIntro from "./OnboardingIntro";
import OnboardingMembers from "./Members/OnboardingMembers";
import OnboardingOwnership from "./OnboardingOwnership";
import {
  OnboardingContext,
  ROUTE_TO_ONBOARDING_STEP,
} from "./OnboardingProvider";
import OnboardingCompanyName from "./OnboardingCompanyName";
import OnboardingReview from "./Review/OnboardingReview";
import OnboardingDescription from "./OnboardingDescription";
import OnboardingResponsibleParty from "./ResponsibleParty/OnboardingResponsibleParty";
import useCompany from "graphql/hooks/UseCompany";
import { Stack, useMediaQuery } from "@mui/material";
import { Logo } from "components/Logos";
import { LoadingComponent } from "components/Loading";
import FlowStepper from "components/FlowStepper";
import OnboardingAside from "./OnboardingAside";
import utils from "utils";
import { usePostHog } from "posthog-js/react";
import OnboardingExecutive from "./OnboardingExecutive";
import OnboardingShare from "./OnboardingShare";
import { EntityType } from "graphql/__generated__/types";

const renderOnboardingStep = {
  0: <OnboardingIntro />,
  1: <OnboardingCompanyName />,
  2: <OnboardingAddress />,
  3: <OnboardingIndustry />,
  4: <OnboardingDescription />,
  5: <OnboardingMembers />,
  6: <OnboardingResponsibleParty />,
  7: <OnboardingOwnership />,
  8: <OnboardingReview />,
};

const renderOnboardingCCorpStep = {
  5: <OnboardingExecutive />,
  7: <OnboardingShare />,
};

const Onboarding = () => {
  const { currentOnboardingStep } = useContext(OnboardingContext);
  const { currentCompany } = useCompany();
  const posthog = usePostHog();

  const isCCorpFlow = currentCompany?.entityType === EntityType.CCORP;

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  useEffect(() => {
    const screenUrl = Object.keys(ROUTE_TO_ONBOARDING_STEP).find(
      (key) => ROUTE_TO_ONBOARDING_STEP[key] === currentOnboardingStep
    );

    posthog?.capture("onboarding-view", {
      onboarding_url: screenUrl,
      entity_type: currentCompany?.entityType,
    });
  }, [currentCompany?.entityType, currentOnboardingStep, posthog]);

  const onboardingSteps = isCCorpFlow
    ? { ...renderOnboardingStep, ...renderOnboardingCCorpStep }
    : renderOnboardingStep;

  return (
    <>
      <Stack pt={3}>
        <Logo width={100} />
      </Stack>
      <Stack
        direction={isTablet ? "column" : "row"}
        gap={isTablet ? "0px" : "100px"}
      >
        <Stack width={isTablet ? "100%" : "65%"} height="100%">
          <Suspense fallback={<LoadingComponent />}>
            <FlowStepper
              currentStep={currentOnboardingStep}
              flowRoutes={ROUTE_TO_ONBOARDING_STEP}
            />
            {
              onboardingSteps[
                currentOnboardingStep as keyof typeof onboardingSteps
              ]
            }
          </Suspense>
        </Stack>
        <Stack width={isTablet ? "100%" : "35%"} mt={isTablet ? 3 : 7.5}>
          <OnboardingAside />
        </Stack>
      </Stack>
    </>
  );
};

export default Onboarding;
