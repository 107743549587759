import { useCallback, useEffect } from "react";
import { useGetCompanyLazyQuery } from "graphql/__generated__/operations/Company.generated";
import { useGetTokenFieldsQuery } from "graphql/__generated__/operations/Whoami.generated";
import { useAppSelector } from "state/hooks";
import { selectCurrentCompanyId } from "state/authSlice";
import { Company } from "graphql/__generated__/types";

// eslint-disable-next-line no-warning-comments

interface ICompany {
  currentCompany: Company;
  isCompanyLoading: boolean;
  refetchCompany: () => Promise<void>;
}

//TODO : Remove this hoook after doc service 2.0 is ready
const useCompanyWithDocs = (): ICompany => {
  const { data: tokenData } = useGetTokenFieldsQuery();

  const [
    fetchCompany,
    { data, loading: isCompanyLoading, fetchMore, refetch },
  ] = useGetCompanyLazyQuery({
    errorPolicy: "all",
  });

  const currentCompanyId = useAppSelector(selectCurrentCompanyId) || "";

  const getCurrentCompanyId = useCallback(() => {
    const companyIds = tokenData?.myToken?.authorizedCompanyIds?.split(",");
    const selectedCompanyId =
      companyIds && [...companyIds].includes(currentCompanyId)
        ? currentCompanyId
        : companyIds?.[0];

    return selectedCompanyId;
  }, [currentCompanyId, tokenData?.myToken?.authorizedCompanyIds]);

  const refetchCompany = async () => {
    const selectedCompanyId = getCurrentCompanyId();

    await refetch({
      companyId: selectedCompanyId,
    });
  };

  const pageInfo = data?.company?.documents?.pageInfo;

  useEffect(() => {
    const selectedCompanyId = getCurrentCompanyId();

    if (selectedCompanyId) {
      fetchCompany({
        variables: { companyId: selectedCompanyId },
      });
    }
  }, [
    currentCompanyId,
    fetchCompany,
    getCurrentCompanyId,
    tokenData?.myToken?.authorizedCompanyIds,
  ]);

  useEffect(() => {
    const edges = data?.company?.documents?.edges;
    const cursor = edges?.[edges.length - 1]?.cursor || "";

    const selectedCompanyId = getCurrentCompanyId();

    if (pageInfo?.hasNextPage && cursor && selectedCompanyId) {
      fetchMore({
        variables: {
          after: cursor,
        },
      });
    }
  }, [
    currentCompanyId,
    data?.company?.documents?.edges,
    fetchMore,
    getCurrentCompanyId,
    pageInfo?.hasNextPage,
    tokenData?.myToken?.authorizedCompanyIds,
  ]);

  return {
    currentCompany: data?.company as Company,
    isCompanyLoading,
    refetchCompany,
  };
};

export default useCompanyWithDocs;
