import { InMemoryCache } from "@apollo/client";

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Customer: {
      merge: true,
      fields: {
        stripeBillingPortalMeta: {
          keyArgs: false,
        },
      },
    },
    Company: {
      merge: true,
      fields: {
        members: {
          merge(_existing, incoming) {
            if (Array.isArray(incoming)) {
              return [...incoming];
            } else {
              return incoming;
            }
          },
        },
        documents: {
          keyArgs: false,
          merge(existing, incoming) {
            if (!incoming) return existing;
            if (!existing) return incoming;

            const map = new Map(
              [...existing.edges, ...incoming.edges].map((pos) => [
                pos.cursor,
                pos,
              ])
            );

            const uniqueDocs = [...map.values()];

            return {
              __typename: "CompanyDocuments",
              pageInfo: incoming.pageInfo,
              edges: uniqueDocs,
            };
          },
        },
        documentServiceDocs: {
          merge(_existing, incoming) {
            return incoming;
          },
        },
      },
    },
    WalletOnboard: {
      fields: {
        stakeholders: {
          merge(_existing, incoming) {
            if (Array.isArray(incoming)) {
              return [...incoming];
            } else {
              return incoming;
            }
          },
        },
      },
    },
  },
});
