import { ROUTER_PATHS } from "./routes";
import GuestGuard from "utils/route-guard/GuestGuard";
import SignupLayout from "views/SignupFlow/SignupLayout";
import SignupFlow from "views/SignupFlow";

const SignupRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <GuestGuard>
          <SignupLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: ROUTER_PATHS.PRE_PURCHASE_REGISTRATION,
          element: <SignupFlow />,
        },
        // TODO: Uncomment for phase 1 with company origin
        // {
        //   path: ROUTER_PATHS.PRE_PURCHASE_COMPANY_ORIGIN,
        //   element: <SignupFlow />,
        // },
        {
          path: ROUTER_PATHS.PRE_PURCHASE_COUNTRY_OF_RESIDENCE,
          element: <SignupFlow />,
        },
        {
          path: ROUTER_PATHS.PRE_PURCHASE_ENTITY_TYPE,
          element: <SignupFlow />,
        },
        {
          path: ROUTER_PATHS.PRE_PURCHASE_COMPANY_STATE,
          element: <SignupFlow />,
        },
        {
          path: ROUTER_PATHS.PRE_PURCHASE_COMPANY_NAME,
          element: <SignupFlow />,
        },
        {
          path: ROUTER_PATHS.PRE_PURCHASE_PACKAGES,
          element: <SignupFlow />,
        },
        {
          path: ROUTER_PATHS.PRE_PURCHASE_TC,
          element: <SignupFlow />,
        },
        {
          path: ROUTER_PATHS.PRE_PURCHASE_EXPEDITED,
          element: <SignupFlow />,
        },
        {
          path: ROUTER_PATHS.PRE_PURCHASE_BOI_FILING,
          element: <SignupFlow />,
        },
        {
          path: ROUTER_PATHS.PRE_PURCHASE_REVIEW,
          element: <SignupFlow />,
        },
        {
          path: ROUTER_PATHS.PRE_PURCHASE_SUCCESS,
          element: <SignupFlow />,
        },
      ],
    },
  ],
};

export default SignupRoutes;
