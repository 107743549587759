import { useState } from "react";
import { Dialog, Card, Stack, Typography } from "@mui/material";
import { CustomButton } from "components/Buttons";
import { CustomButtonVariants } from "components/Buttons/types";
import useCompany from "graphql/hooks/UseCompany";
import {
  useUnitTransferTransactionsOptInMutation,
  useTransferUnitTransactionsMutation,
} from "services/books-api";
import { usePostHog } from "posthog-js/react";

const OptInScreen = () => {
  const posthog = usePostHog();

  const [open, setOpen] = useState(true);
  const [unitTransferTransactionsOptIn] =
    useUnitTransferTransactionsOptInMutation(); // mutation to record whether the user has answered the opt-in question or not
  const [transferUnitTransactions] = useTransferUnitTransactionsMutation(); // mutation to initialise the transfer of transactions from doola Banking to doola Books

  const { currentCompany } = useCompany();
  const companyId = currentCompany?.id || "";

  const handleOnClick = async (isOptingIn: boolean) => {
    try {
      await unitTransferTransactionsOptIn({ companyId, value: isOptingIn });

      if (isOptingIn) {
        await transferUnitTransactions({ companyId });
      }
    } finally {
      posthog?.capture("banking_opt_in", { opt_in_accept: isOptingIn });
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Card sx={{ p: 5 }}>
        <Stack direction="column" justifyContent="center" spacing={2} mb={2}>
          <Typography>
            For the best doola Books experience, doola can automatically
            transfer transaction data from your doola Bank account to doola
            Books. This means less work for you! To opt in to automatic
            transaction data transfer, click <strong>Accept</strong> below.
          </Typography>
          <CustomButton
            onClick={() => handleOnClick(true)}
            width="100%"
            $variant={CustomButtonVariants.green}
          >
            Accept
          </CustomButton>
          <CustomButton
            onClick={() => handleOnClick(false)}
            width="100%"
            $variant={CustomButtonVariants.inversedPrimary}
          >
            I will add transactions manually
          </CustomButton>
        </Stack>
      </Card>
    </Dialog>
  );
};

export default OptInScreen;
