import {
  Box,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DescriptionOutlined, FileUploadOutlined } from "@mui/icons-material";
import { addApplicantSteps } from "../types";
import utils, { colors } from "utils";
import { useTranslation } from "react-i18next";
import ButtonBox from "components/ButtonBox";
import { IAddApplicantStepsProps } from "../../types";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import Button from "components/Button";
import { LoadingComponent } from "components/Loading";
import useCompany from "graphql/hooks/UseCompany";
import {
  LookupTypeKey,
  OnboardingDocumentType,
  Source,
  WalletOnboard,
  WalletOnboardingDocumentInput,
} from "graphql/__generated__/types";
import {
  IdentityDocumentInfoValuesType,
  MoneyDocumentsSourceCategories,
} from "views/DashboardApp/Money/Onboarding/helpers/types";
import { INTITAL_IDENTITY_DOCUMENT_INFO_VALUES } from "views/DashboardApp/Money/Onboarding/helpers/index";
import { removeTypename } from "utils/helpers/removeTypename";
import useOnboardingData from "views/DashboardApp/Money/Onboarding/hooks/useOnboardingData";
import { useSetMoneyOnboardingMutation } from "graphql/__generated__/operations/MoneyOnboardingValidation.generated";
import { useGenerateDocServiceUploadUrlMutation } from "graphql/__generated__/operations/GenerateDocServiceUploadUrl.generated";
import { Controller, useForm } from "react-hook-form";
import { useDeleteDocumentDocServiceMutation } from "graphql/__generated__/operations/DeleteDocumentDocService.generated";
import useGetMoneyLookups from "views/DashboardApp/Money/hooks/useGetMoneyLookups";
import CustomSelect from "components/Form/CustomSelect";
import { Windows1252Regex } from "utils/regexValidations";
import CustomInput from "components/Form/CustomInput";
import CustomDatePicker from "components/Form/CustomDatePicker";
import dayjs from "dayjs";
import UploadedDocument from "components/FileUpload/UploadedDocument";
import { UploadStatuses } from "components/FileUpload/UploadedDocument/helpers";
import { toast } from "toast";
import AlertNotification from "components/AlertNotification";

const ApplicantDocuments = ({
  setCurrentStep,
  handleOnClose,
  isNewApplicant,
}: IAddApplicantStepsProps) => {
  const { t } = useTranslation();

  const { currentCompany, refetchCompany } = useCompany();

  const [fileList, setFileList] = useState<File[]>([]);
  const [fileStatus, setFileStatus] = useState<{
    [key: string]: UploadStatuses;
  }>({});
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [generateDocServiceUploadUrl] =
    useGenerateDocServiceUploadUrlMutation();
  const [deleteDocumentDocService] = useDeleteDocumentDocServiceMutation();
  const { onboardingData, isOnboardingDataLoading } = useOnboardingData();
  const [updateMoneyOnboarding] = useSetMoneyOnboardingMutation();

  const { lookupsData, isLookupsDataLoading } = useGetMoneyLookups({
    neededLookups: [LookupTypeKey.COUNTRYOFOPERATION],
  });

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const { control, formState, getValues, setValue, trigger } =
    useForm<IdentityDocumentInfoValuesType>({
      defaultValues: { ...INTITAL_IDENTITY_DOCUMENT_INFO_VALUES },
      mode: "onChange",
    });

  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  const filteredOnboardingData: WalletOnboard = useMemo(
    () => removeTypename(onboardingData),
    [onboardingData]
  );

  const handleDeleteDocument = async (documentId: string) => {
    try {
      setIsLoading(true);

      if (documentId && currentCompany?.id) {
        await deleteDocumentDocService({
          variables: {
            companyId: currentCompany?.id,
            documentId: documentId,
          },
        });

        await updateMoneyOnboarding({
          variables: {
            ...filteredOnboardingData,
            applicant: {
              ...filteredOnboardingData?.applicant,
              documents: [],
            },
          },
        });
      }
    } catch {
      toast.error(`Error occured while deleting the ${documentId}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const applicantDocument =
      filteredOnboardingData?.applicant?.documents &&
      filteredOnboardingData?.applicant?.documents[0];

    setValue(
      "documentIssuanceCountry",
      applicantDocument?.documentIssuanceCountry || ""
    );
    setValue("documentNumber", applicantDocument?.documentNumber || "");
    setValue("expirationDate", applicantDocument?.expirationDate || "");
    setValue(
      "onboardingDocType",
      applicantDocument?.onboardingDocType ||
        INTITAL_IDENTITY_DOCUMENT_INFO_VALUES.onboardingDocType
    );

    trigger();
  }, [filteredOnboardingData?.applicant?.documents, setValue, trigger]);

  useEffect(() => {
    if (isNewApplicant) {
      const applicantDocument =
        filteredOnboardingData?.applicant?.documents &&
        filteredOnboardingData?.applicant?.documents[0];

      handleDeleteDocument(applicantDocument?.docServiceFileRefId ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewApplicant]);

  if (
    !onboardingData ||
    isOnboardingDataLoading ||
    !lookupsData ||
    isLookupsDataLoading
  ) {
    return (
      <Stack height={"200px"} alignItems={"center"} justifyContent={"center"}>
        <LoadingComponent />
      </Stack>
    );
  }

  const isEditMode = !!filteredOnboardingData?.applicant?.documents?.length;

  const updateStatus = (key: string, status: UploadStatuses) => {
    setFileStatus((prevFileStatus) => ({ ...prevFileStatus, [key]: status }));
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ? [...e.target.files] : [];
    e.target.value = ""; // to reset the input value

    if (!files.length && !currentCompany?.id) {
      return;
    }

    setFileList(files);
    setIsUploading(true);

    await Promise.all(
      files.map(async (file, index) => {
        return await uploadFile(file, currentCompany?.id ?? "", index);
      })
    );

    setIsLoading(true);

    setFileList([]);
    setFileStatus({});
    setIsUploading(false);

    await refetchCompany();

    setIsLoading(false);
  };

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const uploadFile = async (file: File, companyId: string, index: number) => {
    const key = file.name + index;

    updateStatus(key, UploadStatuses.INPROGRESS);
    const { data } = await generateDocServiceUploadUrl({
      variables: {
        companyId: companyId,
        companyIdString: companyId,
        objectKey: file.name,
        source: Source.BANKING,
        sourcecategory:
          MoneyDocumentsSourceCategories.APPLICANT_IDENTITY_DOCUMENT,
      },
    });

    const url = data?.company?.generateDocServiceUploadUrl?.url;

    const tags = data?.company?.generateDocServiceUploadUrl?.tags?.reduce(
      (tags, tag) => {
        if (tag?.key && tag?.value) {
          return { ...tags, [tag.key]: tag.value };
        }

        return tags;
      },
      {} as { [key: string]: string }
    );

    try {
      if (url && tags && Object.keys(tags).length > 0) {
        const response = await fetch(url, {
          method: "PUT",
          body: file,
          headers: {
            ...tags,
            "content-type": file.type,
            "content-length": `${file.size}`,
          },
        });

        if (response.status === 200) {
          updateStatus(key, UploadStatuses.SUCCESSFUL);

          const {
            documentIssuanceCountry,
            documentNumber,
            expirationDate,
            onboardingDocType,
          } = getValues();

          const uploadedDocument: WalletOnboardingDocumentInput = {
            docServiceFileRefId: `${companyId}/${tags["x-amz-meta-filename"]}`,
            fileName: tags["x-amz-meta-filename"],
            fileType: "PDF",
            onboardingDocType,
            expirationDate,
            documentIssuanceCountry,
            documentNumber,
          };

          await updateOnboardingDocument(uploadedDocument);
        }
      }
    } catch (err) {
      updateStatus(key, UploadStatuses.FAILED);

      toast.error(`Error occured while uploading ${file.name}`);
    }
  };

  const updateOnboardingDocument = async (
    uploadedDocument: WalletOnboardingDocumentInput
  ) => {
    try {
      setIsLoading(true);

      await updateMoneyOnboarding({
        variables: {
          ...filteredOnboardingData,
          applicant: {
            ...filteredOnboardingData?.applicant,
            documents: [
              ...(filteredOnboardingData?.applicant?.documents ?? []),
              uploadedDocument,
            ],
          },
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isUploadDisabled = isUploading || !formState.isValid;

  const documentTypeDescription = "National ID";

  return (
    <form noValidate onSubmit={(e) => e.preventDefault()}>
      <Stack mb={3} gap={1}>
        <Stack direction={"row"} alignItems={"center"} gap={1.5}>
          <DescriptionOutlined />
          <Typography fontSize={16} fontWeight={500}>
            {t("money.onboarding.step.applicantDetails.identityDocument")}
          </Typography>
        </Stack>
        <Typography fontSize={16} color={colors.contentTertiary} mt={0.5}>
          {t("general.uploadDescriptionIdentity")}
        </Typography>
      </Stack>
      <Stack gap={3}>
        <Stack gap={2}>
          <Stack direction={isTablet ? "column" : "row"} gap={3}>
            <Controller
              name="onboardingDocType"
              {...COMMON_PROPS}
              rules={{
                pattern: {
                  value: Windows1252Regex,
                  message: t("error.invalidCharacters"),
                },
                required: true,
              }}
              render={({ field }) => (
                <CustomSelect
                  disabled={isEditMode}
                  {...field}
                  styles={{ width: "100%" }}
                  placeholder={
                    t(
                      "money.onboarding.identityDocument.upload.onboardingDocType.placeholder"
                    ) as string
                  }
                  label={
                    t(
                      "money.onboarding.identityDocument.upload.onboardingDocType"
                    ) as string
                  }
                  defaultValue={field.value}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    field.onChange(e.target.value);
                  }}
                >
                  <MenuItem value={OnboardingDocumentType.NATIONAL_ID}>
                    {documentTypeDescription}
                  </MenuItem>
                </CustomSelect>
              )}
            />
            <Controller
              name="documentNumber"
              {...COMMON_PROPS}
              rules={{
                required: true,
                pattern: {
                  value: Windows1252Regex,
                  message: t("error.invalidCharacters"),
                },
              }}
              render={({ field }) => (
                <CustomInput
                  disabled={isEditMode}
                  {...field}
                  styles={{ width: "100%" }}
                  placeholder={
                    t(
                      "money.onboarding.identityDocument.upload.documentNumber.placeholder"
                    ) as string
                  }
                  label={
                    t(
                      "money.onboarding.identityDocument.upload.documentNumber"
                    ) as string
                  }
                />
              )}
            />
          </Stack>
          <Stack direction={isTablet ? "column" : "row"} gap={3}>
            {lookupsData?.COUNTRYOFOPERATION && (
              <Controller
                name="documentIssuanceCountry"
                {...COMMON_PROPS}
                rules={{
                  pattern: {
                    value: Windows1252Regex,
                    message: t("error.invalidCharacters"),
                  },
                  required: true,
                }}
                render={({ field }) => (
                  <CustomSelect
                    disabled={isEditMode}
                    {...field}
                    styles={{ width: "100%" }}
                    placeholder={
                      t(
                        "money.onboarding.identityDocument.upload.documentIssuanceCountry.placeholder"
                      ) as string
                    }
                    label={
                      t(
                        "money.onboarding.identityDocument.upload.documentIssuanceCountry"
                      ) as string
                    }
                    defaultValue={field.value}
                    onChange={(e: SelectChangeEvent<unknown>) => {
                      field.onChange(e.target.value);
                    }}
                  >
                    {lookupsData.COUNTRYOFOPERATION.map((item, index) => (
                      <MenuItem key={index} value={item?.key ?? ""}>
                        {item.description}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                )}
              />
            )}
            <Controller
              name="expirationDate"
              rules={{
                required: true,
              }}
              {...COMMON_PROPS}
              render={({ field: { value, onChange } }) => (
                <CustomDatePicker
                  disabled={isEditMode}
                  value={dayjs(new Date(value ?? 0))}
                  styles={{ width: "100%" }}
                  onChange={(newValue) =>
                    onChange(dayjs(newValue).format("YYYY-MM-DD"))
                  }
                  placeholder={
                    t(
                      "money.onboarding.identityDocument.upload.expirationDate.placeholder"
                    ) as string
                  }
                  label={
                    t(
                      "money.onboarding.identityDocument.upload.expirationDate"
                    ) as string
                  }
                  disablePast
                />
              )}
            />
          </Stack>
        </Stack>
        {!isEditMode && (
          <Stack>
            <input
              type="file"
              accept=".pdf"
              ref={inputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <Box>
              <Button
                disabled={isUploadDisabled}
                dark={false}
                endIcon={<FileUploadOutlined />}
                buttonText={t("general.upload")}
                onClick={handleUploadClick}
                styles={{
                  bgcolor: colors.grey,
                }}
              />
            </Box>
            <Typography color={colors.contentTertiary} mt={2}>
              {t("general.uploadTip", { value: "PDF" })}
            </Typography>
          </Stack>
        )}
      </Stack>

      <Stack gap={5} mt={4} mb={isTablet ? 4 : 0}>
        <Stack>
          {isLoading ? (
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              minHeight={"200px"}
            >
              <LoadingComponent />
            </Stack>
          ) : (
            <>
              {fileList.map((file: File, index) => (
                <UploadedDocument
                  key={index}
                  filename={file.name}
                  status={fileStatus?.[file.name + index]}
                  handleDeleteDocument={handleDeleteDocument}
                />
              ))}
              {onboardingData?.applicant?.documents?.map((file, index) => (
                <UploadedDocument
                  key={index}
                  filename={file?.fileName ?? ""}
                  documentId={file?.docServiceFileRefId ?? ""}
                  handleDeleteDocument={handleDeleteDocument}
                />
              ))}
              {isEditMode && (
                <AlertNotification
                  style={{ mt: 3 }}
                  body={
                    t(
                      "money.onboarding.identityDocument.upload.info.text"
                    ) as string
                  }
                />
              )}
            </>
          )}
        </Stack>
        <ButtonBox
          isLoading={isLoading}
          rightButtonText="Close"
          onClickBack={() =>
            setCurrentStep(addApplicantSteps.applicantInformation)
          }
          onClickNext={() => handleOnClose()}
        />
      </Stack>
    </form>
  );
};

export default ApplicantDocuments;
