import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCustomerEmailMutationVariables = Types.Exact<{
  customerId: Types.Scalars['ID'];
  newEmail: Types.Scalars['String'];
}>;


export type UpdateCustomerEmailMutation = { __typename?: 'Mutation', customer?: { __typename?: 'CustomerMutation', updateEmail?: { __typename?: 'Customer', id?: string | null, email?: string | null } | null } | null };


export const UpdateCustomerEmailDocument = gql`
    mutation UpdateCustomerEmail($customerId: ID!, $newEmail: String!) {
  customer(id: $customerId) {
    updateEmail(new: $newEmail) {
      id
      email
    }
  }
}
    `;
export type UpdateCustomerEmailMutationFn = Apollo.MutationFunction<UpdateCustomerEmailMutation, UpdateCustomerEmailMutationVariables>;

/**
 * __useUpdateCustomerEmailMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerEmailMutation, { data, loading, error }] = useUpdateCustomerEmailMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      newEmail: // value for 'newEmail'
 *   },
 * });
 */
export function useUpdateCustomerEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerEmailMutation, UpdateCustomerEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerEmailMutation, UpdateCustomerEmailMutationVariables>(UpdateCustomerEmailDocument, options);
      }
export type UpdateCustomerEmailMutationHookResult = ReturnType<typeof useUpdateCustomerEmailMutation>;
export type UpdateCustomerEmailMutationResult = Apollo.MutationResult<UpdateCustomerEmailMutation>;
export type UpdateCustomerEmailMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerEmailMutation, UpdateCustomerEmailMutationVariables>;