import {
  Dialog,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import utils from "utils";
import Consultation from "./Consultation";
import { CloseRounded } from "@mui/icons-material";
import { UpcomingConsultations } from "graphql/__generated__/types";

type Props = {
  consultations: (UpcomingConsultations | null)[];
  open: boolean;
  onClose: () => void;
};

const ConsultationsModal = ({ consultations, open, onClose }: Props) => {
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  return (
    <Dialog
      fullScreen={isTablet}
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          display: "flex",
          flexDirection: "column",
          borderRadius: !isTablet ? "32px" : "0",
          width: isTablet ? "100%" : "620px",
          padding: "2rem",
          gap: "40px",
        },
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h2">Scheduled consultations</Typography>
        <IconButton onClick={onClose}>
          <CloseRounded color="primary" />
        </IconButton>
      </Stack>
      <Stack gap={2}>
        {consultations.map(
          (consultation, index) =>
            consultation && (
              <Consultation
                key={index}
                consultation={consultation}
                isModalType
              />
            )
        )}
      </Stack>
    </Dialog>
  );
};

export default ConsultationsModal;
