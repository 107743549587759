import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { ROUTER_PATHS } from "routes/routes";
import { useNavigate } from "react-router-dom";
import useCompany from "graphql/hooks/UseCompany";
import { LoadingComponent } from "components/Loading";
import stateKeys from "resources/stateKeys";
import utils, { colors } from "utils";

type IconProps = {
  label: string;
  Icon: JSX.Element;
};

const Icon = ({ label, Icon }: IconProps) => (
  <Stack direction="column" alignItems="center" gap={1}>
    <Stack
      bgcolor={colors.yellow}
      width="50px"
      height="50px"
      justifyContent="center"
      alignItems="center"
      borderRadius="16px"
    >
      {Icon}
    </Stack>
    <Typography variant="h6">{label}</Typography>
  </Stack>
);

const OnboardingIntro = () => {
  const navigate = useNavigate();
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const { currentCompany } = useCompany();

  if (!currentCompany) {
    return <LoadingComponent />;
  }

  const state = stateKeys[currentCompany.state as keyof typeof stateKeys];

  return (
    <>
      <Stack direction="column" gap={4}>
        <Typography variant="h1">
          Let's get your paperwork ready to file with {state}!
        </Typography>
        <Stack gap={1}>
          <Typography variant="h3">
            File Your {currentCompany.entityType} in {state}
          </Typography>
          <Typography>
            doola will file with the state on your behalf as soon as you
            complete the next few screens. Most customers complete this in just
            a few minutes.
          </Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Icon label="Company Info" Icon={<AccountBalanceIcon />} />
          <ArrowForwardOutlinedIcon
            fontSize="large"
            sx={{ color: colors.grey }}
          />
          <Icon label="Member Info" Icon={<PermIdentityOutlinedIcon />} />
          <ArrowForwardOutlinedIcon
            fontSize="large"
            sx={{ color: colors.grey }}
          />
          <Icon label="Review" Icon={<DescriptionOutlinedIcon />} />
        </Stack>
      </Stack>
      <Stack
        mt={4}
        sx={{
          ...(isTablet && {
            position: "fixed",
            bottom: 0,
            bgcolor: colors.white,
            width: "100%",
            zIndex: 1,
            py: 2,
          }),
        }}
      >
        <Button
          onClick={() => navigate(ROUTER_PATHS.ONBOARDING_COMPANY_NAME)}
          variant="contained"
          endIcon={<NavigateNextIcon />}
          sx={{
            borderRadius: "30px",
            py: 2,
            px: 3,
            width: "280px",
            boxShadow: "none",
            "&:hover": {
              bgcolor: colors.charcoal,
              boxShadow: "none",
            },
          }}
          data-testid="OnboardingIntroNextButton"
        >
          Continue with my Formation
        </Button>
      </Stack>
    </>
  );
};

export default OnboardingIntro;
