import { useEffect, useState } from "react";
import ExpandMoreRounded from "@mui/icons-material/ExpandMoreRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import utils, { colors } from "utils";
import { ReactComponent as TeamImage } from "assets/Team.svg";
import { usePostHog } from "posthog-js/react";

type Tip = {
  title: string;
  content: {
    title: string;
    body: string;
  }[];
};

interface IAccordionStepContent {
  callOut: {
    title: string;
    body: string;
  };
  tips: Tip[];
}

export type AccordionContents = {
  [key: number | string]: IAccordionStepContent;
};

interface IFlowAside {
  accordionStepContent: IAccordionStepContent;
}

const AccordionElement = (tipInfo: Tip) => {
  const posthog = usePostHog();
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setIsExpanded(false);
  }, [tipInfo]);

  return (
    <Accordion
      TransitionProps={{
        timeout: 0,
      }}
      expanded={isExpanded}
      disableGutters
      onChange={(_e, expanded) => {
        setIsExpanded(!isExpanded);

        posthog?.capture(expanded ? "accordion-expand" : "accordion-contract", {
          accordion_name: tipInfo.title,
        });
      }}
      sx={{
        mb: 1,
        mt: 0,
        background: "none",
        border: "none",
        boxShadow: "none",
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary sx={{ padding: 0 }} expandIcon={<ExpandMoreRounded />}>
        <Typography variant="h5" fontWeight={500}>
          {tipInfo.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <Stack direction="column" gap={1}>
          {tipInfo.content.map(({ title, body }, index) => (
            <React.Fragment key={index}>
              <Typography variant="button">{title}</Typography>
              <Typography mb={3}>{body}</Typography>
            </React.Fragment>
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

const FlowAside = ({ accordionStepContent }: IFlowAside) => {
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);

  const { callOut, tips } = accordionStepContent;

  return (
    <Stack direction="column" gap={3} mb={isTablet ? 15 : 3}>
      <Stack
        sx={{
          bgcolor: colors.pink,
          borderRadius: isTablet ? "24px" : isMobile ? "16px" : "32px",
          p: isMobile ? 2 : 3,
        }}
        spacing={2}
      >
        <Typography variant="h3" fontSize={27}>
          {callOut.title}
        </Typography>
        {!isTablet && <TeamImage width="100%" height="230px" />}
        <Typography>{callOut.body}</Typography>
      </Stack>
      <Box>
        {tips.map((tip, index) => (
          <AccordionElement key={index} {...tip} />
        ))}
      </Box>
    </Stack>
  );
};

export default FlowAside;
