import { EditOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { t } from "i18next";
import { colors } from "utils";

export const StyledEditButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    disableElevation
    variant="contained"
    onClick={onClick}
    endIcon={<EditOutlined fontSize="medium" />}
    sx={{
      borderRadius: "30px",
      bgcolor: colors.grey,
      color: colors.black,
      "&:hover": {
        bgcolor: colors.darkGrey,
      },
    }}
  >
    {t("general.edit")}
  </Button>
);
