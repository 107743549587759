import { Check } from "@mui/icons-material";
import {
  FormControlLabel,
  Checkbox,
  Stack,
  Typography,
  useTheme,
  Radio,
  CheckboxProps,
  RadioProps,
  Chip,
  useMediaQuery,
  SxProps,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import utils, { colors } from "utils";

interface IFormControlElementProps {
  title?: string | null;
  subtitle?: string;
  listpoints?: string;
  content?: React.ReactNode;
  optionValue: any;
  selectedValue: any;
  changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: "radio" | "checkbox";
  chipText?: string;
  sideBySideTitle?: boolean;
  sideContent?: React.ReactNode;
  outerStyles?: SxProps<Theme>;
  formStyles?: SxProps<Theme>;
  isDisabled?: boolean;
  leftContent?: React.ReactNode;
}

const FormControlElement: React.FC<IFormControlElementProps> = ({
  title,
  subtitle,
  listpoints,
  content,
  optionValue,
  selectedValue,
  type = "radio",
  chipText,
  changeHandler,
  sideBySideTitle = true,
  sideContent,
  outerStyles,
  formStyles,
  isDisabled = false,
  leftContent,
}) => {
  const theme = useTheme();

  const Wrapper = (props: RadioProps | CheckboxProps) => {
    if (type === "radio") {
      return <Radio {...props} />;
    }

    return <Checkbox {...props} />;
  };

  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);

  return (
    <Stack spacing={1} sx={{ ...(outerStyles && { ...outerStyles }) }}>
      <FormControlLabel
        labelPlacement="top"
        disabled={isDisabled}
        control={
          <Stack
            width={"100%"}
            direction={"row-reverse"}
            alignItems="stretch"
            justifyContent="space-between"
          >
            <Wrapper
              disabled={isDisabled}
              disableRipple
              name={title as string}
              checked={selectedValue === optionValue}
              onChange={changeHandler}
              value={optionValue}
              sx={{
                padding: 0,
                color: theme.palette.secondary.light,
                "&.Mui-checked": {
                  color: colors.blue,
                },
              }}
            />
            {chipText && !sideBySideTitle && (
              <Chip
                label={chipText}
                sx={{
                  height: 24,
                  background: colors.green,
                  color: colors.white,
                  padding: 0,
                  borderRadius: 2,
                }}
              />
            )}
          </Stack>
        }
        label={
          <Stack
            direction={sideBySideTitle && !isMobile ? "row" : "column"}
            alignItems={sideBySideTitle ? "center" : "flex-start"}
            gap={3}
            justifyContent="space-between"
          >
            {leftContent && <>{leftContent}</>}
            <Stack width={"100%"} gap={listpoints ? (isMobile ? 2 : 3) : ""}>
              <Stack gap={0.5}>
                <Stack direction={"row"} justifyContent="space-between">
                  <Typography fontSize={20} fontWeight={700}>
                    {title}
                  </Typography>
                  {chipText && sideBySideTitle && (
                    <Chip
                      label={chipText}
                      sx={{
                        height: 24,
                        background: colors.green,
                        color: colors.white,
                        padding: 0,
                        borderRadius: 2,
                      }}
                    />
                  )}
                </Stack>
                {subtitle && <Typography fontSize={16}>{subtitle}</Typography>}
              </Stack>
              {listpoints && (
                <Stack gap={1}>
                  {listpoints.split(",").map((point, index) => (
                    <Stack
                      key={index}
                      gap={1}
                      direction={"row"}
                      alignItems={"center"}
                    >
                      <Check />
                      {point}
                    </Stack>
                  ))}
                </Stack>
              )}
              {content && <>{content}</>}
            </Stack>
            {sideContent && <>{sideContent}</>}
          </Stack>
        }
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          ...(!sideBySideTitle && { flexDirection: "column" }),
          gap: 1,
          padding: "56px 32px 32px 32px",
          margin: 0,
          borderRadius: "24px",
          bgcolor: colors.lightGrey,
          border: `1px solid ${colors.lightGrey}`,
          ...(selectedValue === optionValue && {
            background: colors.lightBlue,
            border: `1px solid ${colors.borderBlue}`,
          }),
          transition: "all .1s ease-out",
          "& .MuiFormControlLabel-label": {
            width: "100%",
          },
          ...(formStyles && { ...formStyles }),
        }}
      />
    </Stack>
  );
};

export default FormControlElement;
