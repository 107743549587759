import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { t } from "i18next";
import utils, { colors } from "utils";
import { ReactComponent as FilingImage } from "assets/Tax/filing_final.min.svg";
import ButtonBox from "components/ButtonBox";

interface IFinalStepProps {
  onClose: () => void;
}

const FinalCpaModalStep = ({ onClose }: IFinalStepProps) => {
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  return (
    <>
      <Stack alignItems={"center"}>
        <FilingImage height={isTablet ? "200px" : "250px"} />
      </Stack>
      <Box mt={5}>
        <Typography variant="h3" color={colors.black}>
          {t("dashboard.tax.taxFilingModal.final.title")} 💛
        </Typography>
        <Typography color={colors.contentSecondary} mt={2} mb={4}>
          {t("dashboard.tax.taxFilingModal.final.description")}
        </Typography>
        <ButtonBox
          rightButtonText={"Close"}
          showRightIcon={false}
          showBackButton={false}
          onClickNext={onClose}
        />
      </Box>
    </>
  );
};

export default FinalCpaModalStep;
