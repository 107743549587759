import { Box } from "@mui/material";
import { colors } from "utils";
import { FormationSteps } from "views/SignupFlow/services";

interface IFlowStepperProps {
  currentStep: number;
  flowRoutes: {
    [x: string]: FormationSteps | number;
  };
}

const FlowStepper = ({ currentStep, flowRoutes }: IFlowStepperProps) => {
  const currentStepPosition = Object.values(flowRoutes).indexOf(currentStep);

  return (
    <Box pt={3} pb={4}>
      <Box
        style={{
          backgroundColor: colors.grey,
          width: "100%",
          height: "4px",
          borderRadius: "60px",
        }}
      >
        <Box
          sx={{
            backgroundColor: colors.green,
            width: `${
              (currentStepPosition / (Object.values(flowRoutes).length - 1)) *
              100
            }%`,
            transition: "width 0.5s",
            height: "4px",
            borderRadius: "60px",
          }}
        />
      </Box>
    </Box>
  );
};

export default FlowStepper;
