import config from "config";
import TagManager from "react-gtm-module";

export class TrackerGA4 {
  private static instance: TrackerGA4;
  private constructor() {
    TagManager.initialize(config.GTM);
  }

  public static getInstance(): TrackerGA4 {
    if (!TrackerGA4.instance) {
      TrackerGA4.instance = new TrackerGA4();
    }
    return TrackerGA4.instance;
  }

  // get GA4 Client Id to set in the postHog
  public getGaClientId = () => {
    const cookie: Record<string, string> = {};

    document.cookie.split(";").forEach((el) => {
      const splitCookie = el.split("=");
      const key = splitCookie[0].trim();
      const value = splitCookie[1];

      cookie[key] = value;
    });

    const gaCookie = cookie["_ga"];

    return gaCookie?.substring(6) ?? undefined;
  };

  // new GA4 events
  public signUpStart = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "signup_start",
      },
    });
  };

  public accountCreated = (data: { customerId: string; email: string }) => {
    const { customerId, email } = data;

    TagManager.dataLayer({
      dataLayer: {
        event: "account_created",
        user_id: customerId,
        email,
      },
    });
  };

  public purchaseGA4 = (data: {
    customerId: string;
    productName: string;
    subscriptionRevenue: number;
    purchase_data: any;
  }) => {
    const { customerId, subscriptionRevenue, productName, purchase_data } =
      data;
    TagManager.dataLayer({
      dataLayer: {
        event: "purchase",
        user_id: customerId,
        subscription_value: subscriptionRevenue,
        product_name: productName,
        purchase_data,
      },
    });
  };
}
