import { useState } from "react";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import { useAuthForgotPasswordMutation } from "graphql/__generated__/operations/AuthForgotPassword.generated";
import utils from "utils";
import { EmailRegex } from "utils/regexValidations";
import ButtonBox from "components/ButtonBox";
import { useTranslation } from "react-i18next";
import CustomInput from "components/Form/CustomInput";
import HintBox from "components/HintBox";

const RecoverPassword: React.FC = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const { register, control, formState, handleSubmit } = useForm({
    defaultValues: { email: "" },
  });
  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors, register: register };

  const [authForgotPassword] = useAuthForgotPasswordMutation();

  const { t } = useTranslation();
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const onSubmitHandler = async ({ email }: FieldValues) => {
    setIsLoading(true);

    try {
      await authForgotPassword({ variables: { email } });

      navigate(
        `${ROUTER_PATHS.RESET_PASSWORD}/?username=` + encodeURIComponent(email),
        {
          replace: true,
        }
      );
    } catch {
      setIsLoading(false);
    }
  };

  return (
    <Stack gap={4}>
      <Typography variant={isTablet ? "h3" : "h2"}>
        {t("recover.password.title")} 🛠️
      </Typography>
      <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
        <Stack gap={isTablet ? 3 : 4}>
          <Controller
            name={"email"}
            {...COMMON_PROPS}
            rules={{
              required: true,
              validate: (value) =>
                EmailRegex.test(value.trim()) ||
                "Please use a valid email address",
            }}
            render={({ field }) => (
              <CustomInput
                {...field}
                placeholder={t("general.emailPlaceholder") as string}
                label={t("general.email") as string}
                autoComplete="email"
              />
            )}
          />
          <HintBox body={t("recover.password.tip") as string} />
          <ButtonBox
            isLoading={isLoading}
            disabled={!formState.isValid}
            onClickBack={() => navigate(ROUTER_PATHS.LOGIN)}
            onClickNext={() => onSubmitHandler}
            rightButtonText="Send"
          />
        </Stack>
      </form>
    </Stack>
  );
};

export default RecoverPassword;
