import { toast } from "toast";
import { CustomButton } from "components/Buttons";
import { CustomButtonVariants } from "components/Buttons/types";
import { createHurdlerAccount } from "services/books-api";
import { Typography } from "@mui/material";
import * as Sentry from "@sentry/react";

type Props = {
  companyId: string;
};

const NoHurdlrAccount = ({ companyId }: Props): JSX.Element => {
  const handleOnClick = () => {
    createHurdlerAccount(companyId).then((token) => {
      if (token.error) {
        Sentry.captureException(token.error);
        console.error(token.error);
        toast.error(token.error);
      } else {
        window.location.reload();
      }
    });
  };

  return (
    <>
      <Typography variant="h4" mb={2}>
        Your account isn't linked to books
      </Typography>
      <CustomButton
        $variant={CustomButtonVariants.black}
        onClick={handleOnClick}
      >
        Click Here for early access
      </CustomButton>
    </>
  );
};

export default NoHurdlrAccount;
