import { CompanyServices, ServiceCategory } from "graphql/__generated__/types";
import { ROUTER_PATHS } from "routes/routes";

interface StepsType {
  [key: string]: string;
}

export interface StepStatusType {
  [key: string]: ServiceCategory;
}

export const TrackerCategories = {
  company: "Company",
  ein: "EIN",
  essentials: "Essentials",
};

export const FormationSteps: StepsType = {
  orderProcessed: "Order Processed",
  companyAndMemberProfile: "Company and Member Profile",
  formationFiled: "Formation Filed",
  articlesOfOrganisation: "Articles of Organization",
};

export const EinSteps: StepsType = {
  waitingToCompleteFormation: "Waiting to Complete Formation",
  signSS4Form: "Sign SS4 Form",
  einApplicationProcessing: "EIN Application Processing",
  einLetterRecieved: "EIN Letter Received",
};

export const EssentialSteps: StepsType = {
  [CompanyServices.ANNUALSTATEFILING]: "Annual Filings",
  [CompanyServices.BOOKKEEPING]: "doola Books",
  [CompanyServices.TAX]: "IRS Tax Filings",
};

// Define the types for TrackerData
interface StepDetails {
  buttonLabel: string;
  buttonRoute: string;
}

interface TrackerSteps {
  info: string;
  [ServiceCategory.NOTAVAILABLE]?: StepDetails;
  [ServiceCategory.NOTSTARTED]?: StepDetails;
  [ServiceCategory.INPROGRESS]?: StepDetails;
  [ServiceCategory.CANCELED]?: StepDetails;
  [ServiceCategory.ACTIVE]?: StepDetails;
  [ServiceCategory.COMPLETED]?: StepDetails;
}

interface TrackerCategory {
  steps: {
    [key: string]: TrackerSteps;
  };
}

interface TrackerDataProps {
  [key: string]: TrackerCategory;
}

export const TrackerData: TrackerDataProps = {
  [TrackerCategories.company]: {
    steps: {
      [FormationSteps.orderProcessed]: {
        info: "dashboard.tracker.formation.orderProcessed",
      },
      [FormationSteps.companyAndMemberProfile]: {
        info: "dashboard.tracker.formation.companyAndMemberProfile",
      },
      [FormationSteps.formationFiled]: {
        info: "dashboard.tracker.formation.formationFiled",
      },
      [FormationSteps.articlesOfOrganisation]: {
        info: "dashboard.tracker.formation.articlesOfOrganisation",
        [ServiceCategory.COMPLETED]: {
          buttonLabel: "Check Documents",
          buttonRoute: ROUTER_PATHS.DOCUMENTS,
        },
      },
    },
  },
  [TrackerCategories.ein]: {
    steps: {
      [EinSteps.waitingToCompleteFormation]: {
        info: "dashboard.tracker.ein.waitingToCompleteFormation",
      },
      [EinSteps.signSS4Form]: {
        info: "dashboard.tracker.ein.signSS4Form",
      },
      [EinSteps.einApplicationProcessing]: {
        info: "dashboard.tracker.ein.einApplicationProcessing",
      },
      [EinSteps.einLetterRecieved]: {
        info: "dashboard.tracker.ein.einLetterRecieved",
        [ServiceCategory.COMPLETED]: {
          buttonLabel: "Check Documents",
          buttonRoute: ROUTER_PATHS.DOCUMENTS,
        },
      },
    },
  },
  [TrackerCategories.essentials]: {
    steps: {
      [EssentialSteps[CompanyServices.ANNUALSTATEFILING]]: {
        info: "dashboard.tracker.essentials.annualStateFiling",
        [ServiceCategory.NOTSTARTED]: {
          buttonLabel: "Learn More",
          buttonRoute: `${ROUTER_PATHS.SERVICES}?service=${CompanyServices.ANNUALSTATEFILING}`,
        },
        [ServiceCategory.COMPLETED]: {
          buttonLabel: `To ${
            EssentialSteps[CompanyServices.ANNUALSTATEFILING]
          }`,
          buttonRoute: `${ROUTER_PATHS.SERVICES}?service=${CompanyServices.ANNUALSTATEFILING}`,
        },
      },
      [EssentialSteps[CompanyServices.BOOKKEEPING]]: {
        info: "dashboard.tracker.essentials.bookkeeping",
        [ServiceCategory.NOTSTARTED]: {
          buttonLabel: "Learn More",
          buttonRoute: `${ROUTER_PATHS.SERVICES}?service=${CompanyServices.BOOKKEEPING}`,
        },
        [ServiceCategory.COMPLETED]: {
          buttonLabel: `To ${EssentialSteps[CompanyServices.BOOKKEEPING]}`,
          buttonRoute: `${ROUTER_PATHS.BOOKS}`,
        },
      },
      [EssentialSteps[CompanyServices.TAX]]: {
        info: "dashboard.tracker.essentials.tax",
        [ServiceCategory.NOTSTARTED]: {
          buttonLabel: "Learn More",
          buttonRoute: `${ROUTER_PATHS.SERVICES}?service=${CompanyServices.TAX}`,
        },
        [ServiceCategory.COMPLETED]: {
          buttonLabel: `To ${EssentialSteps[CompanyServices.TAX]}`,
          buttonRoute: `${ROUTER_PATHS.SERVICES}?service=${CompanyServices.TAX}`,
        },
      },
    },
  },
};
