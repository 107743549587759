import { Stack, Typography, useMediaQuery } from "@mui/material";
import ButtonBox from "components/ButtonBox";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { FormationScreenNames } from "../services";
import { ROUTER_PATHS } from "routes/routes";
import utils, { colors } from "utils";
import useGetMainProducts from "../Packages/hooks/useGetMainProducts";
import { LoadingComponent } from "components/Loading";
import { Controller, FormProvider, useForm } from "react-hook-form";
import FormControlElement from "../components/FormControlElement";
import { IPackage } from "../Packages";
import {
  selectFormationPackage,
  setBoiFilingFormationPackage,
  setExpeditedFormationPackage,
  setFormationPackage,
} from "state/formationFormSlice";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { PackageCard } from "../Packages/PackageCard";
import { ProductDuration } from "graphql/__generated__/types";
import { moneyFormatter } from "utils/formatters";
import { STARTER, TOTAL_COMPLIANCE } from "../Packages/data";
import { usePostHog } from "posthog-js/react";

const TotalCompliance = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const posthog = usePostHog();

  const selectedFormationPackage = useAppSelector(selectFormationPackage);

  const { PLANS, defaultPlan, isProductQueryLoading } = useGetMainProducts();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentDuration, setCurrentDuration] = useState<ProductDuration>(
    ProductDuration.MONTHLY
  );

  const methods = useForm<IPackage>({
    defaultValues: {
      name: selectedFormationPackage?.name,
      displayName: selectedFormationPackage?.displayName,
      stripePriceId: selectedFormationPackage?.stripePriceId,
      price: selectedFormationPackage?.price,
      duration: selectedFormationPackage?.duration || ProductDuration.MONTHLY,
      lookupKey: selectedFormationPackage?.lookupKey,
    },
  });
  const { control, formState, handleSubmit, setValue, trigger } = methods;
  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);

  useEffect(() => {
    posthog?.capture("packages-viewed");
    posthog?.capture("total_compliance_viewed");
  }, [posthog]);

  useEffect(() => {
    if (
      selectedFormationPackage &&
      selectedFormationPackage.name === TOTAL_COMPLIANCE.value
    ) {
      setCurrentDuration(selectedFormationPackage.duration);
    }
  }, [selectedFormationPackage]);

  useEffect(() => {
    if (defaultPlan && !selectedFormationPackage) {
      setValue("name", PLANS[TOTAL_COMPLIANCE.value].value);
      setValue("displayName", PLANS[TOTAL_COMPLIANCE.value].displayName);
      setValue("stripePriceId", defaultPlan.stripePricingId);
      setValue("price", defaultPlan.usdPrice);
      setValue("lookupKey", defaultPlan.key ?? "");
      setValue("duration", defaultPlan.duration ?? ProductDuration.MONTHLY);

      trigger();

      setCurrentDuration(defaultPlan.duration ?? ProductDuration.MONTHLY);
    }
  }, [isProductQueryLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isProductQueryLoading) {
    return <LoadingComponent />;
  }

  const onPackageChange = (newValue: string, duration?: ProductDuration) => {
    setValue("name", newValue);

    if (!!newValue && duration) {
      const newSelectedPackage = PLANS[TOTAL_COMPLIANCE.value];
      const newSelectedPackageDuration = newSelectedPackage.duration[duration];

      setValue("displayName", newSelectedPackage.displayName);
      setValue("stripePriceId", newSelectedPackageDuration!.stripePriceId);
      setValue("price", newSelectedPackageDuration!.price);
      setValue("lookupKey", newSelectedPackageDuration!.lookupKey);
      setValue("duration", duration);

      setCurrentDuration(duration);
    }
  };

  const onSubmitHandler = (data: IPackage) => {
    setIsLoading(true);

    const { name, displayName, stripePriceId, price, duration, lookupKey } =
      data;

    const isTotalComplianceChosen = name === TOTAL_COMPLIANCE.value;

    if (isTotalComplianceChosen) {
      dispatch(
        setFormationPackage({
          name,
          displayName,
          stripePriceId,
          price,
          duration,
          lookupKey,
        })
      );

      dispatch(setExpeditedFormationPackage());
      dispatch(setBoiFilingFormationPackage());

      posthog?.capture("product-selected", {
        product_name: name,
        product_id: stripePriceId,
        product_value: price,
        product_term: duration,
      });

      navigate(ROUTER_PATHS.PRE_PURCHASE_REVIEW);
    } else {
      navigate(ROUTER_PATHS.PRE_PURCHASE_PACKAGES);
    }
  };

  const onBackHandler = () => {
    posthog?.capture("funnel-back", {
      screen: FormationScreenNames.TotalCompliance,
    });

    navigate(ROUTER_PATHS.PRE_PURCHASE_COMPANY_NAME);
  };

  const isDisabled = !formState.isValid;

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
        <Stack direction="column" gap={3} mb={isTablet ? 0 : 3}>
          <Typography variant={isTablet ? "h3" : "h2"}>
            {t("signup.tc.title")}
          </Typography>
          <Controller
            name={"name"}
            {...COMMON_PROPS}
            rules={{
              required: true,
            }}
            render={({ field: { ref, ...field } }) => (
              <>
                <FormControlElement
                  {...field}
                  title={TOTAL_COMPLIANCE.name}
                  subtitle={t("signup.tc.description") as string}
                  listpoints={t("signup.tc.featureList") as string}
                  optionValue={TOTAL_COMPLIANCE?.value}
                  selectedValue={field.value}
                  changeHandler={() =>
                    onPackageChange(TOTAL_COMPLIANCE.value, currentDuration)
                  }
                  sideBySideTitle={false}
                  sideContent={
                    <Stack
                      width={"100%"}
                      direction={"row"}
                      {...(isMobile && { gap: 1 })}
                      alignItems="flex-end"
                      justifyContent={"space-between"}
                    >
                      <Stack
                        direction={"row"}
                        {...(isMobile && { gap: 1 })}
                        alignItems="flex-end"
                      >
                        <Typography variant="h2" fontSize={28}>
                          {moneyFormatter(
                            PLANS[TOTAL_COMPLIANCE.value].duration[
                              currentDuration
                            ]?.price as number
                          )}
                        </Typography>
                        <Typography
                          variant="body1"
                          color={colors.contentTertiary}
                        >
                          /
                          {currentDuration === ProductDuration.ANNUAL
                            ? "yr"
                            : "month"}
                        </Typography>
                      </Stack>
                      <PackageCard
                        plan={PLANS[TOTAL_COMPLIANCE.value]}
                        isSelected={TOTAL_COMPLIANCE?.value === field.value}
                        onPackageChange={onPackageChange}
                        isTotalCompliancePage
                      />
                    </Stack>
                  }
                />
                <FormControlElement
                  {...field}
                  title={`${t("signup.tc.deny.title")},`}
                  subtitle={t("signup.tc.deny.text") as string}
                  optionValue={PLANS[STARTER.value].value}
                  selectedValue={field.value}
                  changeHandler={() =>
                    onPackageChange(PLANS[STARTER.value].value)
                  }
                  sideBySideTitle={!isMobile}
                />
              </>
            )}
          />
          <Stack direction="row" alignItems={"center"} gap={2}>
            <InfoIcon />
            <Typography>{t("signup.tc.tooltip")}</Typography>
          </Stack>
          <ButtonBox
            onClickBack={onBackHandler}
            onClickNext={() => onSubmitHandler}
            isLoading={isLoading}
            disabled={isDisabled}
          />
        </Stack>
      </form>
    </FormProvider>
  );
};

export default TotalCompliance;
