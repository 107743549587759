import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCompanyNameAlternateMutationVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  names: Array<Types.InputMaybe<Types.CompanyNameInput>> | Types.InputMaybe<Types.CompanyNameInput>;
}>;


export type UpdateCompanyNameAlternateMutation = { __typename?: 'Mutation', company?: { __typename?: 'CompanyMutation', updateNameOptions?: { __typename?: 'Company', id: string, name?: string | null, services?: Array<{ __typename?: 'Services', service: Types.CompanyServices, variant?: Types.ServiceVariant | null, status: Types.ServiceCategory, subStatus?: string | null }> | null, nameOptions?: Array<{ __typename?: 'CompanyName', name: string, entityTypeEnding: string, position?: number | null, status?: Types.CompanyNameStatus | null } | null> | null } | null } | null };


export const UpdateCompanyNameAlternateDocument = gql`
    mutation UpdateCompanyNameAlternate($companyId: ID!, $names: [CompanyNameInput]!) {
  company(id: $companyId) {
    updateNameOptions(names: $names) {
      id
      name
      services {
        service
        variant
        status
        subStatus
      }
      nameOptions {
        name
        entityTypeEnding
        position
        status
      }
    }
  }
}
    `;
export type UpdateCompanyNameAlternateMutationFn = Apollo.MutationFunction<UpdateCompanyNameAlternateMutation, UpdateCompanyNameAlternateMutationVariables>;

/**
 * __useUpdateCompanyNameAlternateMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyNameAlternateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyNameAlternateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyNameAlternateMutation, { data, loading, error }] = useUpdateCompanyNameAlternateMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      names: // value for 'names'
 *   },
 * });
 */
export function useUpdateCompanyNameAlternateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyNameAlternateMutation, UpdateCompanyNameAlternateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyNameAlternateMutation, UpdateCompanyNameAlternateMutationVariables>(UpdateCompanyNameAlternateDocument, options);
      }
export type UpdateCompanyNameAlternateMutationHookResult = ReturnType<typeof useUpdateCompanyNameAlternateMutation>;
export type UpdateCompanyNameAlternateMutationResult = Apollo.MutationResult<UpdateCompanyNameAlternateMutation>;
export type UpdateCompanyNameAlternateMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyNameAlternateMutation, UpdateCompanyNameAlternateMutationVariables>;