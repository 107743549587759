import { useGetProductsQuery } from "graphql/__generated__/operations/Products.generated";
import { PRODUCTS_DB_KEYS } from "resources/productsLookupKeys";

export default function useGetBoiFilingProduct() {
  const { data, loading: isProductQueryLoading } = useGetProductsQuery({
    fetchPolicy: "cache-first",
    errorPolicy: "all",
  });

  const productServices = data?.productService?.payload;

  const boiFilingProduct = productServices?.find(
    (item) => (item?.key as PRODUCTS_DB_KEYS) === PRODUCTS_DB_KEYS.BOI_FILING
  );

  const boiFilingData = {
    name: boiFilingProduct?.name ?? "",
    lookupKey: boiFilingProduct?.key ?? "",
    value: boiFilingProduct?.stripePricingId ?? "",
    price: (boiFilingProduct?.usdPriceCents ?? 0) / 100,
  };

  return { boiFilingData, isProductQueryLoading };
}
