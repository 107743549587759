import { Alert, Grid, Stack, Typography } from "@mui/material";
import { P } from "components/Fonts";
import { useGetHurdlerTokensMutation } from "services/books-api";
import { useEffect } from "react";
import useCompany from "graphql/hooks/UseCompany";
import { HurdlrTokenResponse } from "models/Requests";
import config from "config";
import { LoadingComponent } from "components/Loading";
import { Logo } from "components/Logos";
import NoHurdlrAccount from "./NoHurdlrAccount";
import HurdlrAuthorised from "./HurdlrAuthorised";
import { BooksTypesEnum, RenderProps } from ".";
import OptInScreen from "./OptInScreen";

const NO_HURDLR_ACCOUNT = "does not have a valid hurdlr account";

const HurdlrAuthorisedContainer = ({
  elementToRender,
}: RenderProps): JSX.Element => {
  const [getHurdlerToken, { data, isLoading, error }] =
    useGetHurdlerTokensMutation();

  const { currentCompany } = useCompany();

  const companyId = currentCompany?.id as string;
  const { hurdlrCliendId } = config.books;
  const { environment } = config.books;

  const accessToken = data?.payload?.accessToken;
  const hasSeenOptInScreen = data?.payload.trackUnitTransactionOptIn !== null; // null, false, or true

  const showOptInScreen = !hasSeenOptInScreen;

  useEffect(() => {
    if (companyId) {
      getHurdlerToken({
        companyId: companyId,
      });
    }
  }, [getHurdlerToken, companyId]);

  let renderItem = (
    <HurdlrAuthorised elementToRender={BooksTypesEnum.DASHBOARD} />
  );

  if (isLoading) {
    renderItem = <LoadingComponent />;
  }

  if (error) {
    const d: HurdlrTokenResponse = (error as any).data;
    if (d.error.includes(NO_HURDLR_ACCOUNT)) {
      renderItem = <NoHurdlrAccount companyId={companyId} />;
    } else {
      renderItem = (
        <Alert severity="error">
          <Typography>{d.error}</Typography>
        </Alert>
      );
    }
  }

  if (accessToken) {
    const hurdlrInitParams = {
      clientId: hurdlrCliendId,
      accessToken: accessToken,
      environment: environment,
    };

    (window as any).Hurdlr.init(hurdlrInitParams);

    renderItem = <HurdlrAuthorised elementToRender={elementToRender} />;
  }

  return (
    <Stack direction="column" spacing={2} pt={1}>
      <Stack direction={"row"} spacing={1} mb={2}>
        <Logo />
        <P
          size="30px"
          $weight="700"
          style={{
            lineHeight: 1,
            marginTop: "7px",
          }}
        >
          Books
        </P>
      </Stack>

      <Grid item lg={12} mb={2}>
        {showOptInScreen ? <OptInScreen /> : renderItem}
      </Grid>
    </Stack>
  );
};

export default HurdlrAuthorisedContainer;
