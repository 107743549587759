import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserSurveyResponseQueryVariables = Types.Exact<{
  key: Types.Scalars['String'];
  doolaCustomerId: Types.Scalars['String'];
}>;


export type UserSurveyResponseQuery = { __typename?: 'Query', userSurveyResponse?: { __typename?: 'UserSurveyResponsesResult', error?: string | null, payload?: Array<{ __typename?: 'UserSurveyResponse', doolaCustomerId?: string | null, created?: string | null } | null> | null } | null };


export const UserSurveyResponseDocument = gql`
    query UserSurveyResponse($key: String!, $doolaCustomerId: String!) {
  userSurveyResponse(key: $key, doolaCustomerId: $doolaCustomerId) {
    error
    payload {
      doolaCustomerId
      created
    }
  }
}
    `;

/**
 * __useUserSurveyResponseQuery__
 *
 * To run a query within a React component, call `useUserSurveyResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSurveyResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSurveyResponseQuery({
 *   variables: {
 *      key: // value for 'key'
 *      doolaCustomerId: // value for 'doolaCustomerId'
 *   },
 * });
 */
export function useUserSurveyResponseQuery(baseOptions: Apollo.QueryHookOptions<UserSurveyResponseQuery, UserSurveyResponseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserSurveyResponseQuery, UserSurveyResponseQueryVariables>(UserSurveyResponseDocument, options);
      }
export function useUserSurveyResponseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserSurveyResponseQuery, UserSurveyResponseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserSurveyResponseQuery, UserSurveyResponseQueryVariables>(UserSurveyResponseDocument, options);
        }
export type UserSurveyResponseQueryHookResult = ReturnType<typeof useUserSurveyResponseQuery>;
export type UserSurveyResponseLazyQueryHookResult = ReturnType<typeof useUserSurveyResponseLazyQuery>;
export type UserSurveyResponseQueryResult = Apollo.QueryResult<UserSurveyResponseQuery, UserSurveyResponseQueryVariables>;