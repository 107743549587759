import * as Types from '../types';

import { gql } from '@apollo/client';
import { AuthTokensInfoFragmentDoc } from './AuthRefreshTokens.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AuthSignUpMutationVariables = Types.Exact<{
  input: Types.NewUser;
}>;


export type AuthSignUpMutation = { __typename?: 'Mutation', auth?: { __typename?: 'AuthMutation', signUp?: { __typename?: 'SignUpResponse', success: boolean, message: string, isReturningUser: boolean, customerId: string, companyIds?: Array<string | null> | null, email: string, tokens: { __typename?: 'AuthTokens', idToken: string, refreshToken: string, accessToken: string } } | null } | null };


export const AuthSignUpDocument = gql`
    mutation AuthSignUp($input: NewUser!) {
  auth {
    signUp(input: $input) {
      success
      message
      isReturningUser
      customerId
      companyIds
      email
      tokens {
        ...AuthTokensInfo
      }
    }
  }
}
    ${AuthTokensInfoFragmentDoc}`;
export type AuthSignUpMutationFn = Apollo.MutationFunction<AuthSignUpMutation, AuthSignUpMutationVariables>;

/**
 * __useAuthSignUpMutation__
 *
 * To run a mutation, you first call `useAuthSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authSignUpMutation, { data, loading, error }] = useAuthSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthSignUpMutation(baseOptions?: Apollo.MutationHookOptions<AuthSignUpMutation, AuthSignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthSignUpMutation, AuthSignUpMutationVariables>(AuthSignUpDocument, options);
      }
export type AuthSignUpMutationHookResult = ReturnType<typeof useAuthSignUpMutation>;
export type AuthSignUpMutationResult = Apollo.MutationResult<AuthSignUpMutation>;
export type AuthSignUpMutationOptions = Apollo.BaseMutationOptions<AuthSignUpMutation, AuthSignUpMutationVariables>;