import { IStepProps } from "../../types";
import ButtonBox from "components/ButtonBox";
import { TransferSteps } from "../../data";
import { useEffect, useMemo, useState } from "react";
import {
  TransferMethodEnum,
  USD_CURRENCY_KEY,
  US_KEY,
  getTransferMethod,
  maskSensitiveData,
} from "views/DashboardApp/Money/helpers";
import { Stack, Typography } from "@mui/material";
import useGetMoneyAccounts from "views/DashboardApp/Money/hooks/useGetMoneyAccounts";
import useGetBeneficiary from "views/DashboardApp/Money/hooks/useGetBeneficiary";
import useGetExchangeRate from "views/DashboardApp/Money/hooks/useGetExchangeRate";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import utils, { colors } from "utils";
import {
  AccountBalanceOutlined,
  AccountBalanceWalletOutlined,
  AttachMoneyOutlined,
  WarningAmberRounded,
} from "@mui/icons-material";
import CustomInput from "components/Form/CustomInput";
import Avatar from "components/@extended/Avatar";
import { moneyFormatter } from "utils/formatters";
import {
  convertToLocalCurrency,
  convertToUSD,
  getPercentAmount,
  getCurrencySymbol,
  increaseByPercent,
} from "../../helpers";
import { LoadingComponent } from "components/Loading";
import useGetCurrencyFeeInfo from "views/DashboardApp/Money/hooks/useGetCurrencyFeeInfo";
import { usePostHog } from "posthog-js/react";
import { isNumericInputValid } from "utils/helpers";
import AlertNotification from "components/AlertNotification";

interface IRenderEndIconProps {
  destinationCountry: string;
  destinationCurrency: string;
}

const RenderEndIcon = ({
  destinationCountry,
  destinationCurrency,
}: IRenderEndIconProps) => (
  <Stack direction={"row"} alignItems={"center"} gap={1}>
    {destinationCountry && (
      <Stack
        sx={{
          width: "24px",
          height: "16px",
        }}
      >
        <img
          loading="lazy"
          src={`https://flagcdn.com/w20/${destinationCountry.toLowerCase()}.png`}
          srcSet={`https://flagcdn.com/w40/${destinationCountry.toLowerCase()}.png 2x`}
          alt=""
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "4px",
          }}
        />
      </Stack>
    )}
    <Typography color={colors.black} fontWeight={500}>
      {destinationCurrency}
    </Typography>
  </Stack>
);

const Amount = ({
  setCurrentStep,
  transferData,
  setTransferData,
}: IStepProps) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const posthog = usePostHog();

  const { accountsData, isAccountsDataLoading } = useGetMoneyAccounts();
  const { getBeneficiary, isBeneficiaryLoading, beneficiary } =
    useGetBeneficiary();
  const { getExchangeRate, isExchangeRateDataLoading, exchangeRateData } =
    useGetExchangeRate();
  const {
    getCurrencyFeeInfo,
    isCurrencyFeeInfoDataLoading,
    currencyFeeInfoData,
  } = useGetCurrencyFeeInfo();

  const [amount, setAmount] = useState<string>("");
  const [localAmount, setLocalAmount] = useState<string>("");

  const USDBalance = useMemo(
    () =>
      accountsData?.balances?.find(
        (balance) => balance?.curSymbol === USD_CURRENCY_KEY
      )?.balance || 0,
    [accountsData?.balances]
  );

  const accountNumber = maskSensitiveData(accountsData?.uniquePaymentId ?? "");

  const tranferMethod = useMemo(
    () =>
      beneficiary &&
      getTransferMethod({
        payoutMethod: beneficiary.payoutMethod || "",
        destinationCountry: beneficiary.destinationCountry || "",
        routingCodeType1: beneficiary.routingCodeType1 || "",
      }),
    [beneficiary]
  );

  const destinationCountry = beneficiary && beneficiary.destinationCountry;

  const destinationCurrency = beneficiary && beneficiary.destinationCurrency;

  const exchangeRate = useMemo(
    () => +(exchangeRateData?.fx_rate ?? ""),
    [exchangeRateData?.fx_rate]
  );

  const isInternationalWire =
    tranferMethod === TransferMethodEnum.INTERNATIONAL_WIRE;

  const totalMarkup =
    (currencyFeeInfoData?.doolaRate ?? 0) +
    (currencyFeeInfoData?.niumRate ?? 0);

  const isUSDSelected = useMemo(
    () => destinationCurrency === USD_CURRENCY_KEY,
    [destinationCurrency]
  );

  useEffect(() => {
    const amount = transferData.amount;

    if (amount) {
      setAmount(amount);

      if (isInternationalWire && exchangeRate) {
        setLocalAmount(convertToLocalCurrency(amount, exchangeRate));
      }
    }
  }, [exchangeRate, isInternationalWire, transferData]);

  useEffect(() => {
    if (isInternationalWire && destinationCurrency && !isUSDSelected) {
      getExchangeRate(destinationCurrency);
      getCurrencyFeeInfo(destinationCurrency);
    }
  }, [
    destinationCurrency,
    getCurrencyFeeInfo,
    getExchangeRate,
    isInternationalWire,
    isUSDSelected,
  ]);

  useEffect(() => {
    const beneficiaryId = searchParams.get("recipient");

    if (beneficiaryId) {
      getBeneficiary(beneficiaryId);
    }
  }, [getBeneficiary, searchParams]);

  const isLoading =
    isBeneficiaryLoading ||
    isAccountsDataLoading ||
    (isInternationalWire && isExchangeRateDataLoading) ||
    isCurrencyFeeInfoDataLoading;

  if (isLoading) return <LoadingComponent />;

  const insufficientFunds =
    increaseByPercent(+amount, totalMarkup) > USDBalance;
  const minimumAmountToSend = !!amount && +amount < 1;

  const hasError = insufficientFunds || minimumAmountToSend;

  const isDisabled = !amount || isLoading || hasError;

  const onSubmitHandler = () => {
    posthog?.capture("money_transfer_flow_amount_screen_next_btn_click");

    setTransferData({
      ...transferData,
      amount,
      localAmount,
      beneficiaryHashId: beneficiary?.beneficiaryHashId ?? "",
    });

    setCurrentStep(TransferSteps.details);
  };

  return (
    <Stack gap={5} mb={isTablet ? 9 : 0}>
      <Stack gap={4}>
        <Stack direction={"row"} alignItems={"center"} gap={1.5}>
          <AttachMoneyOutlined sx={{ fontSize: "20px" }} />
          <Typography fontSize={16} fontWeight={500}>
            {t("money.transfer.step.amount.amount")}
          </Typography>
        </Stack>
        {isInternationalWire ? (
          <Stack gap={3}>
            <Stack direction={isTablet ? "column" : "row"} gap={3}>
              <CustomInput
                error={hasError}
                placeholder="0.00"
                styles={{ width: "100%", color: colors.contentTertiary }}
                label={t("money.transfer.step.amount.recipientGets")}
                value={localAmount || ""}
                type="text"
                inputProps={{
                  step: "0.01",
                }}
                startIcon={
                  <Stack m={0}>
                    {getCurrencySymbol(destinationCurrency ?? "")}
                  </Stack>
                }
                endIcon={
                  <RenderEndIcon
                    destinationCountry={destinationCountry ?? ""}
                    destinationCurrency={destinationCurrency ?? ""}
                  />
                }
                onChange={(e) => {
                  const inputValue = e.target.value;

                  if (isNumericInputValid(inputValue)) {
                    setLocalAmount(inputValue);

                    if (inputValue === "") {
                      setAmount("");
                    } else {
                      setAmount(convertToUSD(inputValue, exchangeRate));
                    }
                  }
                }}
              />
            </Stack>
            <Stack
              gap={1}
              sx={{
                bgcolor: colors.lightGrey,
                width: "100%",
                p: 3,
                borderRadius: "20px",
              }}
            >
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography color={colors.contentTertiary}>
                  {t("money.transfer.rate")}
                </Typography>
                <Typography color={colors.contentTertiary} fontWeight={500}>
                  {`1 USD = ${exchangeRate.toFixed(2)} ${destinationCurrency}`}
                </Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography color={colors.contentTertiary}>
                  {t("money.transfer.youSend")}
                </Typography>
                <Typography color={colors.contentTertiary} fontWeight={500}>
                  {moneyFormatter(+amount, false)}
                </Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography color={colors.contentTertiary}>
                  {t("money.transfer.transferFee", {
                    feeValue: totalMarkup.toFixed(2),
                  })}
                </Typography>
                <Typography color={colors.contentTertiary} fontWeight={500}>
                  {moneyFormatter(
                    getPercentAmount(+amount, totalMarkup),
                    false
                  )}
                </Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography fontSize={16}>
                  {t("money.transfer.yourTotal")}
                </Typography>
                <Typography fontSize={16} fontWeight={500}>
                  {moneyFormatter(
                    increaseByPercent(+amount, totalMarkup),
                    false
                  )}
                </Typography>
              </Stack>
              <AlertNotification
                style={{ flexDirection: "row", p: 2, borderRadius: 4 }}
                body={t("money.transfer.estimatedAmounts")}
              />
            </Stack>
          </Stack>
        ) : (
          <CustomInput
            error={hasError}
            placeholder="0.00"
            styles={{ width: "100%", color: colors.contentTertiary }}
            label={t("money.transfer.step.amount.recipientGets")}
            value={amount}
            type="text"
            inputProps={{
              step: "0.01",
            }}
            startIcon={
              <Stack m={0}>{getCurrencySymbol(USD_CURRENCY_KEY)}</Stack>
            }
            endIcon={
              <RenderEndIcon
                destinationCountry={US_KEY}
                destinationCurrency={USD_CURRENCY_KEY}
              />
            }
            onChange={(e) => {
              const inputValue = e.target.value;

              if (isNumericInputValid(inputValue)) {
                setAmount(inputValue);
              }
            }}
          />
        )}
      </Stack>
      <Stack gap={4}>
        <Stack direction={"row"} alignItems={"center"} gap={1.5}>
          <AccountBalanceOutlined sx={{ fontSize: "20px" }} />
          <Typography fontSize={16} fontWeight={500}>
            {t("money.transfer.step.amount.source")}
          </Typography>
        </Stack>
        <Stack direction={isTablet ? "column" : "row"} gap={isTablet ? 2 : 3}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={2}
            sx={{
              bgcolor: colors.lightGrey,
              width: "100%",
              p: 3,
              borderRadius: "20px",
            }}
          >
            <Avatar
              sx={{
                backgroundColor: colors.lightAccentYellow,
                color: colors.black,
                width: "48px",
                height: "48px",
              }}
            >
              <AccountBalanceWalletOutlined />
            </Avatar>
            <Stack gap={1} overflow={"hidden"}>
              <Typography fontSize={16} fontWeight={500} noWrap>
                {accountsData?.accountName}
              </Typography>
              <Typography fontSize={14}>
                {`${moneyFormatter(USDBalance, false)} / ${accountNumber}`}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {hasError && (
        <Stack sx={{ bgcolor: colors.lightRed, borderRadius: 6, p: 3 }} gap={2}>
          <WarningAmberRounded />
          <Stack gap={1}>
            {insufficientFunds && (
              <>
                <Typography variant="h3" fontSize={"1rem"}>
                  {t(
                    "money.transfer.step.amount.error.insufficientFunds.title"
                  )}
                </Typography>
                <Typography variant="body1" fontSize={"1rem"}>
                  {t(
                    "money.transfer.step.amount.error.insufficientFunds.description"
                  )}
                </Typography>
              </>
            )}
            {minimumAmountToSend && (
              <Typography variant="body1" fontSize={"1rem"}>
                {t(
                  "money.transfer.step.amount.error.minimumAmount.description"
                )}
              </Typography>
            )}
          </Stack>
        </Stack>
      )}
      <ButtonBox
        isLoading={isLoading}
        disabled={isDisabled}
        onClickBack={() => {
          posthog?.capture("money_transfer_flow_amount_screen_back_btn_click");
          setCurrentStep(TransferSteps.transferInfo);
        }}
        onClickNext={onSubmitHandler}
      />
    </Stack>
  );
};

export default Amount;
