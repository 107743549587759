import { CircularProgress, Stack, Typography } from "@mui/material";
import { colors } from "utils";
import {
  DescriptionOutlined,
  CloseOutlined,
  CheckCircleOutline,
} from "@mui/icons-material";
import { UploadStatuses } from "./helpers";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";

type IUploadedDocumentProps = {
  filename: string;
  status?: UploadStatuses;
  documentId?: string;
  handleDeleteDocument: (docId: string, fileName: string) => Promise<void>;
};

const getRandomNumber = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min)) + min;

const UploadedDocument = ({
  filename,
  status,
  documentId,
  handleDeleteDocument,
}: IUploadedDocumentProps) => {
  const [randomNumber, setRandomNumber] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setRandomNumber(getRandomNumber(20, 81));
  }, []);

  const getProgressValue = () => {
    switch (status) {
      case UploadStatuses.INPROGRESS:
        return randomNumber;
      case UploadStatuses.SUCCESSFUL:
        return 100;
      default:
        return 2;
    }
  };

  const removeDocumentHandler = async (docId: string, fileName: string) => {
    try {
      setIsLoading(true);

      await handleDeleteDocument(docId, fileName);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      bgcolor={colors.lightGrey}
      borderRadius={6}
      p={2}
      pl={3}
    >
      <Stack direction={"row"} alignItems={"center"} gap={3}>
        <DescriptionOutlined
          sx={{
            color: isLoading ? colors.contentInverseTertiary : colors.black,
          }}
        />
        <Stack direction={"row"} alignItems={"center"} gap={1}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={isLoading ? colors.contentInverseTertiary : colors.black}
          >
            {filename}
          </Typography>
          {status &&
            (status === UploadStatuses.SUCCESSFUL ? (
              <CheckCircleOutline
                sx={{ fontSize: "1.2rem", color: colors.green }}
              />
            ) : (
              <CircularProgress
                variant="determinate"
                value={getProgressValue()}
                size={"1rem"}
                thickness={5}
                sx={{
                  color: colors.black,
                }}
              />
            ))}
        </Stack>
      </Stack>
      {documentId && (
        <LoadingButton
          loading={isLoading}
          disabled={isLoading}
          onClick={() => removeDocumentHandler(documentId, filename)}
          sx={{
            minWidth: 0,
            borderRadius: "50%",
            bgcolor: colors.grey,
            p: 1,
            "&:disabled svg": {
              color: colors.darkGrey,
            },
          }}
        >
          <CloseOutlined
            sx={{
              fontSize: "18px",
              color: colors.red,
            }}
          />
        </LoadingButton>
      )}
    </Stack>
  );
};

export default UploadedDocument;
