import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";

type InitialStateTypes = {
  currentCompanyId?: string;
};

const initialState: InitialStateTypes = {};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setCurrentCompanyId: (
      state,
      action: PayloadAction<{
        currentCompanyId: string;
      }>
    ) => {
      state.currentCompanyId = action.payload.currentCompanyId;
    },
    logOutUser: () => initialState,
  },
});

export const { logOutUser, setCurrentCompanyId } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentCompanyId = (state: RootState) =>
  state.auth.currentCompanyId;
