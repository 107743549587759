// to split the shares equally between any amount of members
export const distributeSharesAmongMembers = (numberOfMembers: number) => {
  const shares: number[] = Array(numberOfMembers).fill(0);

  const equalShare = Math.floor(100 / numberOfMembers);
  const remainingShares = 100 % numberOfMembers;

  // Distribute equal shares
  shares.fill(equalShare);

  // Distribute remaining shares
  for (let i = 0; i < remainingShares; i++) {
    shares[i]++;
  }

  // Convert shares to numbers with two decimal places
  return shares.map((share) => parseFloat(share.toFixed(2)));
};
