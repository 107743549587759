import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCustomerQueryVariables = Types.Exact<{
  customerId: Types.Scalars['ID'];
}>;


export type GetCustomerQuery = { __typename?: 'Query', customer?: { __typename?: 'Customer', id?: string | null, email?: string | null, name?: string | null, firstName?: string | null, lastName?: string | null, phoneNumber?: string | null, ssn?: string | null, stripeCustomerId?: string | null, companyIds?: Array<string | null> | null, timezone?: string | null, lastUpdated?: string | null, countryOfResidence?: Types.Iso31661Alpha3 | null, referralMetadata?: { __typename?: 'CustomerReferralMetadata', link: string, token: string } | null, secretOffersRedeemed?: Array<{ __typename?: 'SecretOffersRedeemed', offerId: number, instructions: string } | null> | null } | null };


export const GetCustomerDocument = gql`
    query GetCustomer($customerId: ID!) {
  customer(id: $customerId) {
    id
    email
    name
    firstName
    lastName
    phoneNumber
    ssn
    stripeCustomerId
    companyIds
    timezone
    lastUpdated
    referralMetadata {
      link
      token
    }
    secretOffersRedeemed {
      offerId
      instructions
    }
    countryOfResidence
  }
}
    `;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
      }
export function useGetCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
        }
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;