import {
  Stack,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { EntityType } from "graphql/__generated__/types";
import { useTranslation } from "react-i18next";
import { colors } from "utils";
import { EntityTypesData } from "../../data";

export const EntityListItems = () => {
  const { t } = useTranslation();

  return (
    <>
      {Object.values(EntityTypesData).map(({ listItems }, index) => (
        <Stack width="100%" key={index} px={3}>
          <List>
            {listItems.map(({ renderIcon, content }) => (
              <ListItem
                key={content}
                alignItems="flex-start"
                sx={{ pt: 1, px: 0 }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: "auto",
                    pr: 1,
                    fontSize: "24px",
                    color: colors.black,
                  }}
                >
                  {renderIcon()}
                </ListItemIcon>
                <ListItemText primary={t(content)} />
              </ListItem>
            ))}
          </List>
        </Stack>
      ))}
    </>
  );
};
export const EntityListItem = ({ type }: { type: EntityType }) => {
  const { t } = useTranslation();
  const { listItems } = EntityTypesData[type];

  return (
    <Stack width="100%">
      <List>
        {listItems.map(({ renderIcon, content }) => (
          <ListItem key={content} alignItems="center" sx={{ pt: 1, px: 0 }}>
            <ListItemIcon
              sx={{
                minWidth: "auto",
                pr: 1,
                fontSize: "24px",
                color: colors.black,
              }}
            >
              {renderIcon()}
            </ListItemIcon>
            <ListItemText primary={t(content)} />
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};
