import { useEffect, useState } from "react";
import {
  Button,
  ClickAwayListener,
  Drawer,
  Popper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ProductDuration } from "graphql/__generated__/types";
import utils, { colors } from "utils";
import { moneyFormatter } from "utils/formatters";
import { IPlan, PackageValue, STARTER } from "./data";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { PopperCard } from "./PopperCard";
import { useFormContext } from "react-hook-form";
import { useAppSelector } from "state/hooks";
import { selectFormationPackage } from "state/formationFormSlice";

interface IPackageCard {
  plan: IPlan;
  isSelected: boolean;
  onPackageChange: (newValue: PackageValue, duration: ProductDuration) => void;
  isTotalCompliancePage?: boolean;
}

export const PackageCard = ({
  plan,
  isSelected,
  onPackageChange,
  isTotalCompliancePage = false,
}: IPackageCard) => {
  const [planDuration, setPlanDuration] = useState<ProductDuration>(
    plan.value === STARTER.value
      ? ProductDuration.ANNUAL
      : ProductDuration.MONTHLY
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const selectedFormationPackage = useAppSelector(selectFormationPackage);

  const { watch, setValue } = useFormContext();
  const currentSelectedDuration: ProductDuration = watch("duration");

  useEffect(() => {
    if (selectedFormationPackage?.name === plan.value) {
      setPlanDuration(selectedFormationPackage.duration);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isSelected) {
      onPackageChange(plan.value, planDuration);
    }
  }, [planDuration, isSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const { hasMultiplePlans } = plan;

  const { price } =
    plan.duration[planDuration]! ?? plan.duration[ProductDuration.ANNUAL]!;
  const isAnnualDuration = planDuration === ProductDuration.ANNUAL;

  const isPopperOpened = Boolean(anchorEl);

  const onClickToggleDurationHandler = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    const newSelectedPackageDuration =
      plan.duration[currentSelectedDuration] ||
      plan.duration[ProductDuration.ANNUAL];

    setValue("name", plan.value);
    setValue("stripePriceId", newSelectedPackageDuration!.stripePriceId);
    setValue("price", newSelectedPackageDuration!.price);

    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onDurationChangeHandler = (duration: ProductDuration) => {
    onPackageChange(plan.value, duration);

    setPlanDuration(duration);
    setAnchorEl(null);
  };

  const renderPackageCardDurationSwitchContent = () => (
    <Stack
      gap={1}
      sx={{
        width: isTablet ? "100%" : "330px",
        p: 1,
        borderRadius: 6,
        background: colors.white,
        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
      }}
    >
      {Object.entries(plan.duration).map(([key, values], index) => (
        <PopperCard
          key={index}
          currentPlanDuration={planDuration}
          duration={key as ProductDuration}
          price={values.price}
          onDurationChange={onDurationChangeHandler}
        />
      ))}
    </Stack>
  );

  return (
    <>
      {!isTotalCompliancePage && (
        <Stack direction={"row"} alignItems={"flex-end"} spacing={0.5}>
          {price && (
            <Typography variant="h2" fontSize={28}>
              {moneyFormatter(price)}
            </Typography>
          )}
          <Typography variant="body1" color={colors.contentTertiary}>
            /{isAnnualDuration ? "yr" : "month"}
          </Typography>
        </Stack>
      )}
      {hasMultiplePlans ? (
        <>
          <Button
            disableElevation
            onClick={onClickToggleDurationHandler}
            variant="contained"
            endIcon={<ExpandMoreRoundedIcon />}
            sx={{
              alignSelf: "flex-start",
              borderRadius: "30px",
              bgcolor: isSelected ? colors.borderBlue : colors.grey,
              color: colors.black,
              "&:hover": {
                bgcolor: isSelected ? colors.borderBlueLight : colors.darkGrey,
              },
              mt: 1,
            }}
            data-testid="prePurchaseDurationChangeButton"
          >
            {isAnnualDuration ? "Annual" : "Monthly"} Subscription
          </Button>
          {isTablet ? (
            <Drawer
              open={isPopperOpened}
              anchor={"bottom"}
              onClose={() => setAnchorEl(null)}
              PaperProps={{
                style: {
                  borderRadius: "24px 24px 0 0",
                  background: colors.white,
                },
              }}
            >
              {renderPackageCardDurationSwitchContent()}
            </Drawer>
          ) : (
            <Popper open={isPopperOpened} anchorEl={anchorEl}>
              <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                {renderPackageCardDurationSwitchContent()}
              </ClickAwayListener>
            </Popper>
          )}
        </>
      ) : (
        <Stack
          direction={isTablet ? "row" : "column"}
          gap={isTablet ? 0.5 : 0}
          flexWrap={"wrap"}
        >
          <Button
            disabled
            sx={{
              alignSelf: "flex-start",
              borderRadius: "30px",
              bgcolor: isSelected ? colors.borderBlueLight : colors.grey,
              "&.Mui-disabled": {
                color: colors.contentTertiary,
              },
              p: "6px 16px",
              mt: 1,
            }}
          >
            {isAnnualDuration ? "Annual" : "Monthly"} Subscription
          </Button>
        </Stack>
      )}
      {!isTotalCompliancePage && (
        <Typography mt={2} color={colors.contentSecondary}>
          {plan.description}
        </Typography>
      )}
    </>
  );
};
