import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMoneyCurrencyQueryVariables = Types.Exact<{
  countryCode: Types.Scalars['String'];
}>;


export type GetMoneyCurrencyQuery = { __typename?: 'Query', getCurrency?: { __typename?: 'CurrencyDetailList', error?: string | null, payload?: Array<{ __typename?: 'CurrencyDetail', countryName?: string | null, currencyCode?: string | null, currencyName?: string | null, iso2Name?: string | null, feeInfo?: { __typename?: 'FeeInfo', currencyCode?: string | null, destination?: string | null, doolaRate?: number | null, niumRate?: number | null } | null } | null> | null } | null };


export const GetMoneyCurrencyDocument = gql`
    query GetMoneyCurrency($countryCode: String!) {
  getCurrency(countryCode: $countryCode) {
    error
    payload {
      countryName
      currencyCode
      currencyName
      iso2Name
      feeInfo {
        currencyCode
        destination
        doolaRate
        niumRate
      }
    }
  }
}
    `;

/**
 * __useGetMoneyCurrencyQuery__
 *
 * To run a query within a React component, call `useGetMoneyCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoneyCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoneyCurrencyQuery({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useGetMoneyCurrencyQuery(baseOptions: Apollo.QueryHookOptions<GetMoneyCurrencyQuery, GetMoneyCurrencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMoneyCurrencyQuery, GetMoneyCurrencyQueryVariables>(GetMoneyCurrencyDocument, options);
      }
export function useGetMoneyCurrencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMoneyCurrencyQuery, GetMoneyCurrencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMoneyCurrencyQuery, GetMoneyCurrencyQueryVariables>(GetMoneyCurrencyDocument, options);
        }
export type GetMoneyCurrencyQueryHookResult = ReturnType<typeof useGetMoneyCurrencyQuery>;
export type GetMoneyCurrencyLazyQueryHookResult = ReturnType<typeof useGetMoneyCurrencyLazyQuery>;
export type GetMoneyCurrencyQueryResult = Apollo.QueryResult<GetMoneyCurrencyQuery, GetMoneyCurrencyQueryVariables>;