import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMoneyBeneficiaryQueryVariables = Types.Exact<{
  doolaCompanyId: Types.Scalars['String'];
  beneficiaryId: Types.Scalars['String'];
}>;


export type GetMoneyBeneficiaryQuery = { __typename?: 'Query', getBeneficiary?: { __typename?: 'BeneficiaryResponsePayload', error?: string | null, payload?: { __typename?: 'BeneficiaryResponse', beneficiaryAccountNumber?: string | null, beneficiaryAccountType?: Types.AccountType | null, beneficiaryAddress?: string | null, beneficiaryBankAccountType?: string | null, beneficiaryBankCode?: string | null, beneficiaryBankName?: string | null, beneficiaryCity?: string | null, beneficiaryCountryCode?: string | null, beneficiaryCreatedAt?: string | null, beneficiaryHashId?: string | null, beneficiaryName?: string | null, beneficiaryPostcode?: string | null, beneficiaryState?: string | null, destinationCountry?: string | null, destinationCurrency?: string | null, doolaCompanyId?: string | null, doolaCustomerId?: string | null, payoutHashId?: string | null, payoutMethod?: string | null, routingCodeType1?: string | null, routingCodeValue1?: string | null } | null } | null };


export const GetMoneyBeneficiaryDocument = gql`
    query GetMoneyBeneficiary($doolaCompanyId: String!, $beneficiaryId: String!) {
  getBeneficiary(beneficiaryId: $beneficiaryId, doolaCompanyId: $doolaCompanyId) {
    error
    payload {
      beneficiaryAccountNumber
      beneficiaryAccountType
      beneficiaryAddress
      beneficiaryBankAccountType
      beneficiaryBankCode
      beneficiaryBankName
      beneficiaryCity
      beneficiaryCountryCode
      beneficiaryCreatedAt
      beneficiaryHashId
      beneficiaryName
      beneficiaryPostcode
      beneficiaryState
      destinationCountry
      destinationCurrency
      doolaCompanyId
      doolaCustomerId
      payoutHashId
      payoutMethod
      routingCodeType1
      routingCodeValue1
    }
  }
}
    `;

/**
 * __useGetMoneyBeneficiaryQuery__
 *
 * To run a query within a React component, call `useGetMoneyBeneficiaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoneyBeneficiaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoneyBeneficiaryQuery({
 *   variables: {
 *      doolaCompanyId: // value for 'doolaCompanyId'
 *      beneficiaryId: // value for 'beneficiaryId'
 *   },
 * });
 */
export function useGetMoneyBeneficiaryQuery(baseOptions: Apollo.QueryHookOptions<GetMoneyBeneficiaryQuery, GetMoneyBeneficiaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMoneyBeneficiaryQuery, GetMoneyBeneficiaryQueryVariables>(GetMoneyBeneficiaryDocument, options);
      }
export function useGetMoneyBeneficiaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMoneyBeneficiaryQuery, GetMoneyBeneficiaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMoneyBeneficiaryQuery, GetMoneyBeneficiaryQueryVariables>(GetMoneyBeneficiaryDocument, options);
        }
export type GetMoneyBeneficiaryQueryHookResult = ReturnType<typeof useGetMoneyBeneficiaryQuery>;
export type GetMoneyBeneficiaryLazyQueryHookResult = ReturnType<typeof useGetMoneyBeneficiaryLazyQuery>;
export type GetMoneyBeneficiaryQueryResult = Apollo.QueryResult<GetMoneyBeneficiaryQuery, GetMoneyBeneficiaryQueryVariables>;