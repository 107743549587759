import entityTypes from "resources/entityTypes";
import { EntityType, Product } from "graphql/__generated__/types";

const buildEntityTypesWithStates = () => {
  return [
    ...Object.entries(entityTypes).map(([key, value]) => ({
      name: value.value,
      value: key,
    })),
  ];
};

export const ENTITY_TYPES_OPTIONS = buildEntityTypesWithStates();

export const getStateOptions = (selectedEntityType: EntityType) => {
  return [...entityTypes[selectedEntityType].states.map((state) => state)];
};

export const calculateFeeValue = (
  companyState: string,
  stateFeeProducts?: (Product | null)[]
) => {
  const stateProduct = stateFeeProducts?.find((item) =>
    item?.key.startsWith(companyState)
  );

  const feeValue = (stateProduct?.usdPriceCents ?? 0) / 100;

  return feeValue;
};
