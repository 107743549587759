import { TabContext, TabList } from "@mui/lab";
import { Tab } from "@mui/material";
import { t } from "i18next";
import utils from "utils";
import { ProductDuration } from "graphql/__generated__/types";

type Props = {
  planDuration: ProductDuration;
  onChange: (_event: React.SyntheticEvent, newValue: ProductDuration) => void;
};

const DurationTabSelector = ({ planDuration, onChange }: Props) => {
  return (
    <TabContext value={planDuration}>
      <TabList
        variant="fullWidth"
        onChange={onChange}
        sx={{
          // width: matchDownSM ? "100%" : "60%",
          borderRadius: 1,
          mb: 3,
          border: `1px solid ${utils.secondaryColors.secondaryLight}`,
          "& .MuiTabs-flexContainer": {
            display: "flex",
            flexDirection: "row",
          },
        }}
        TabIndicatorProps={{
          style: { display: "none" },
        }}
      >
        <Tab
          value={ProductDuration.ANNUAL}
          label={t("general.annual")}
          data-testid="DurationTabSwitcher_Annual"
          sx={{
            fontSize: 16,
            fontWeight: 700,
            color: utils.secondaryColors.secondaryDark,

            "&.Mui-selected": {
              bgcolor: "#ffc53d",
            },
          }}
        />
        <Tab
          value={ProductDuration.MONTHLY}
          label={t("general.monthly")}
          data-testid="DurationTabSwitcher_Monthly"
          sx={{
            fontSize: 16,
            fontWeight: 700,
            color: utils.secondaryColors.secondaryDark,

            "&.Mui-selected": {
              bgcolor: "#ffc53d",
            },
          }}
        />
      </TabList>
    </TabContext>
  );
};

export default DurationTabSelector;
