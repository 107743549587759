import ButtonBox from "components/ButtonBox";
import { IStepProps } from "../../types";
import { TaxFilingStepsCategories, TaxFilingStepsData } from "../../data";
import { MenuItem, Stack } from "@mui/material";
import CustomInput from "components/Form/CustomInput";
import CustomSelect from "components/Form/CustomSelect";
import { AttachMoney } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import utils from "utils";
import { INITIAL_INFORMATION_VALUES } from "./helpers/initialInformationData";
import { Controller, useForm } from "react-hook-form";
import { EmailRegex, Windows1252Regex } from "utils/regexValidations";
import { t } from "i18next";
import useCompany from "graphql/hooks/UseCompany";
import { LoadingComponent } from "components/Loading";
import useCustomer from "graphql/hooks/useCustomer";
import { useEffect, useState } from "react";
import { useEditTaxCompanyInfoMutation } from "graphql/__generated__/operations/EditTaxCompanyInfo.generated";
import { usePostHog } from "posthog-js/react";

const TAX_FILING_STATUS_OPTIONS = [
  "US-Owned Single Member LLC",
  "Foreign-Owned Single Member LLC",
  "Partnership (Multi-Member LLC)",
  "C-Corp",
  "S-Corp",
];

const Information = ({ setCurrentStep }: IStepProps) => {
  const posthog = usePostHog();
  const { currentUser } = useCustomer();
  const { currentCompany } = useCompany();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [editTaxCompanyInfo] = useEditTaxCompanyInfoMutation();

  const { control, formState, setValue, handleSubmit } = useForm<
    typeof INITIAL_INFORMATION_VALUES
  >({
    defaultValues: { ...INITIAL_INFORMATION_VALUES },
    mode: "onChange",
  });

  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  useEffect(() => {
    const companyTaxInfo = currentCompany?.companyTaxInfo?.companyTaxInfo;

    if (companyTaxInfo) {
      setValue("taxFilingStatus", companyTaxInfo.taxFilingStatus ?? "");
      setValue("ein", companyTaxInfo.ein ?? "");
      setValue("grossRevenue", companyTaxInfo.grossRevenue ?? "");
      setValue("assets", companyTaxInfo.assets ?? "");
      setValue("email", companyTaxInfo.email ?? "");
      setValue("businessDescription", companyTaxInfo.businessDescription ?? "");
    } else {
      if (currentCompany?.description) {
        setValue("businessDescription", currentCompany?.description);
      }

      if (currentUser?.email) {
        setValue("email", currentUser?.email);
      }
    }
  }, [currentCompany, currentUser, setValue]);

  if (!currentCompany || !currentUser) return <LoadingComponent />;

  const onSubmitHandler = async (data: typeof INITIAL_INFORMATION_VALUES) => {
    try {
      setIsLoading(true);

      await editTaxCompanyInfo({
        variables: {
          companyId: currentCompany?.id,
          taxInfo: {
            ...data,
          },
        },
      });

      setCurrentStep(TaxFilingStepsData[TaxFilingStepsCategories.questions]);
      posthog?.capture(`tax_modal_company_info_next_clicked`);
    } finally {
      setIsLoading(false);
    }
  };

  const isDisabled = !formState.isValid;

  return (
    <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
      <Stack gap={4} mt={3} mb={isTablet ? 9 : 0}>
        <Stack gap={2}>
          <Stack direction={isTablet ? "column" : "row"} gap={3}>
            <Controller
              name="taxFilingStatus"
              {...COMMON_PROPS}
              rules={{
                required: true,
                pattern: {
                  value: Windows1252Regex,
                  message: t("error.invalidCharacters"),
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomSelect
                  {...field}
                  error={error !== undefined}
                  styles={{ width: "100%" }}
                  placeholder="Select status"
                  label="Tax filing status"
                  defaultValue={field.value}
                >
                  {TAX_FILING_STATUS_OPTIONS.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
            />
            <Controller
              name="ein"
              {...COMMON_PROPS}
              rules={{
                required: true,
                pattern: {
                  value: Windows1252Regex,
                  message: t("error.invalidCharacters"),
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  autoComplete=""
                  error={error !== undefined}
                  isProtected
                  styles={{ width: "100%" }}
                  placeholder="Enter EIN"
                  label="EIN"
                />
              )}
            />
          </Stack>
          <Stack direction={isTablet ? "column" : "row"} gap={3}>
            <Controller
              name="grossRevenue"
              {...COMMON_PROPS}
              rules={{
                required: true,
                pattern: {
                  value: Windows1252Regex,
                  message: t("error.invalidCharacters"),
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  error={error !== undefined}
                  styles={{ width: "100%" }}
                  type="number"
                  placeholder="Enter gross revenue"
                  label="Estimated gross revenue"
                  endIcon={<AttachMoney />}
                />
              )}
            />
            <Controller
              name="assets"
              {...COMMON_PROPS}
              rules={{
                required: true,
                pattern: {
                  value: Windows1252Regex,
                  message: t("error.invalidCharacters"),
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  error={error !== undefined}
                  styles={{ width: "100%" }}
                  type="number"
                  placeholder="Enter assets"
                  label="Estimated assets"
                  endIcon={<AttachMoney />}
                />
              )}
            />
          </Stack>
          <Stack direction={isTablet ? "column" : "row"} gap={3}>
            <Controller
              name="email"
              {...COMMON_PROPS}
              rules={{
                required: true,
                pattern: {
                  value: Windows1252Regex,
                  message: t("error.invalidCharacters"),
                },
                validate: (value) =>
                  EmailRegex.test(value.trim()) ||
                  "Please use a valid email address",
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  error={error !== undefined}
                  styles={{ width: "100%" }}
                  placeholder="Enter email address"
                  label="E-mail address"
                />
              )}
            />
          </Stack>
          <Stack direction={isTablet ? "column" : "row"} gap={3}>
            <Controller
              name="businessDescription"
              {...COMMON_PROPS}
              rules={{
                required: true,
                pattern: {
                  value: Windows1252Regex,
                  message: t("error.invalidCharacters"),
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  error={error !== undefined}
                  multiline
                  styles={{ width: "100%" }}
                  placeholder="Describe your business..."
                  label="Description of your business"
                  rows={6}
                />
              )}
            />
          </Stack>
        </Stack>
        <ButtonBox
          isLoading={isLoading}
          disabled={isDisabled}
          onClickBack={() =>
            setCurrentStep(
              TaxFilingStepsData[TaxFilingStepsCategories.companyMembers]
            )
          }
          onClickNext={() => onSubmitHandler}
        />
      </Stack>
    </form>
  );
};

export default Information;
