import { OnboardingStakeholderType } from "graphql/__generated__/types";

export const stakeholdersInitObj = {
  address: {
    address1: "",
    address2: "",
    city: "",
    country: "",
    postalCode: "",
    state: "",
  },
  businessEntityType: null,
  businessName: "",
  businessRegistrationNumber: "",
  businessType: null,
  dateOfBirth: "",
  documents: [],
  firstName: "",
  lastName: "",
  nationality: "",
  ownershipPercentage: "0",
  professionalDetails: [],
  registeredCountry: "",
  type: OnboardingStakeholderType.PERSON,
  uuid: "",
};
