import { useState } from "react";
import {
  DeleteOutline,
  EditOutlined,
  MoreHorizOutlined,
  SendOutlined,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Button from "components/Button";
import {
  BeneficiaryDetails,
  BeneficiaryResponse,
} from "graphql/__generated__/types";
import { useTranslation } from "react-i18next";
import utils, { colors } from "utils";
import { MemoizedAvatar } from "views/DashboardApp/Money/components/Avatar";
import { ConfirmationDialogDetails } from "components/ConfirmationDialog/types";
import ConfirmationDialog from "components/ConfirmationDialog";
import { removeTypename } from "utils/helpers/removeTypename";
import { getTransferMethod } from "views/DashboardApp/Money/helpers";
import { ROUTER_PATHS } from "routes/routes";
import { useNavigate } from "react-router-dom";

type Props = {
  beneficiary: BeneficiaryResponse;
  onRemoveHandler: (beneficiaryHashId: string) => Promise<void>;
  onEditHandler: (beneficiary: BeneficiaryDetails, hashId: string) => void;
};

const BeneficiaryItem = ({
  beneficiary,
  onRemoveHandler,
  onEditHandler,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [confirmationDialogDetails, setConfirmationDialogDetails] =
    useState<ConfirmationDialogDetails>({ show: false, id: "" });

  const navigate = useNavigate();
  const { t } = useTranslation();
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const open = Boolean(anchorEl);
  const beneficiaryHashId = beneficiary.beneficiaryHashId || "";

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnConfirmation = async () => {
    try {
      await onRemoveHandler(beneficiaryHashId);
    } finally {
      setConfirmationDialogDetails({ show: false, id: "" });
    }
  };

  return (
    <>
      {confirmationDialogDetails.show && (
        <ConfirmationDialog
          confirmationDialogDetails={confirmationDialogDetails}
          setConfirmationDialogDetails={setConfirmationDialogDetails}
          handleOnConfirmation={handleOnConfirmation}
          title={t("money.beneficiary.remove.recipient")}
          body={t("money.beneficiary.remove.body")}
          handleOnConfirmationBtnText={t("money.beneficiary.remove.recipient")}
        />
      )}
      <Grid item xs={12} md={6}>
        <Stack
          gap={2}
          sx={{
            background: colors.lightGrey,
            padding: 3,
            borderRadius: 6,
          }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <Box mr={2}>
              <MemoizedAvatar
                name={beneficiary.beneficiaryName || ""}
                countryCode={beneficiary.beneficiaryCountryCode}
              />
            </Box>
            <Box>
              <Typography fontSize={"16px"} fontWeight={"500"}>
                {beneficiary.beneficiaryName}
              </Typography>
              <Typography fontSize={isTablet ? "12px" : "14px"}>
                {getTransferMethod({
                  payoutMethod: beneficiary.payoutMethod || "",
                  destinationCountry: beneficiary.destinationCountry || "",
                  destinationCurrency: beneficiary.destinationCurrency || "",
                })}
              </Typography>
            </Box>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <IconButton
              onClick={handleClick}
              sx={{
                width: 44,
                height: 44,
                bgcolor: colors.grey,
                borderRadius: "50%",
                "&:hover": {
                  bgcolor: colors.darkGrey,
                },
                marginRight: "12px",
              }}
            >
              <MoreHorizOutlined
                style={{
                  fontSize: 20,
                  color: colors.contentSecondary,
                }}
              />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  borderRadius: 6,
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  width: "150px",
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                onClick={() =>
                  onEditHandler(removeTypename(beneficiary), beneficiaryHashId)
                }
                sx={{
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                  fontWeight: "500",
                  minHeight: "0px",
                }}
              >
                <ListItemIcon>
                  <EditOutlined fontSize="small" />
                </ListItemIcon>
                {t("general.edit")}
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() =>
                  setConfirmationDialogDetails({
                    show: true,
                    id: beneficiaryHashId,
                  })
                }
                sx={{
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                  color: colors.red,
                  fontWeight: "500",
                  minHeight: "0px",
                }}
              >
                <ListItemIcon>
                  <DeleteOutline fontSize="small" sx={{ color: colors.red }} />
                </ListItemIcon>
                {t("general.remove")}
              </MenuItem>
            </Menu>
            {isTablet ? (
              <IconButton
                onClick={() =>
                  navigate(
                    `${ROUTER_PATHS.MONEY_SEND}?recipient=${beneficiaryHashId}`
                  )
                }
                sx={{
                  width: 44,
                  height: 44,
                  bgcolor: colors.grey,
                  borderRadius: "50%",
                  "&:hover": {
                    bgcolor: colors.darkGrey,
                  },
                }}
              >
                <SendOutlined
                  style={{
                    fontSize: 20,
                    color: colors.contentSecondary,
                  }}
                />
              </IconButton>
            ) : (
              <Button
                buttonText={t("money.transfer")}
                endIcon={<SendOutlined />}
                styles={{
                  backgroundColor: colors.grey,
                  color: colors.black,
                  ":hover": {
                    background: colors.darkGrey,
                  },
                }}
                onClick={() =>
                  navigate(
                    `${ROUTER_PATHS.MONEY_SEND}?recipient=${beneficiaryHashId}`
                  )
                }
              />
            )}
          </Stack>
        </Stack>
      </Grid>
    </>
  );
};

export default BeneficiaryItem;
