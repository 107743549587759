import { IconButton, Stack, Typography } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DownloadIcon from "@mui/icons-material/Download";
import { colors } from "utils";
import useCompany from "graphql/hooks/UseCompany";
import { useState } from "react";
import { useGetMoneyTransactionStatementsLazyQuery } from "graphql/__generated__/operations/MoneyTransactionStatements.generated";
import { base64ToBlob, getStartAndEndDatesOfAMonth } from "../helpers";
import { LoadingComponent } from "components/Loading";
import { usePostHog } from "posthog-js/react";

type Props = {
  year: number;
  month: string;
};

const DOOLA_MONEY_STATEMENT_PREFIX = "doola_money_statement";

const MonthItem = ({ month, year }: Props) => {
  const { currentCompany, isCompanyLoading } = useCompany();
  const [isStatementsLoading, setIsStatementsLoading] =
    useState<boolean>(false);

  const posthog = usePostHog();

  const [fetchStatements] = useGetMoneyTransactionStatementsLazyQuery();

  if (isCompanyLoading) {
    return <LoadingComponent />;
  }

  const handleDownload = async () => {
    posthog?.capture("money_transactions_statement_download_btn_click");

    const { startDate, endDate } = getStartAndEndDatesOfAMonth(year, month);

    try {
      setIsStatementsLoading(true);

      if (currentCompany?.id) {
        const { data } = await fetchStatements({
          variables: {
            doolaCompanyId: currentCompany.id,
            startDate,
            endDate,
          },
        });

        if (data?.statements) {
          const downloadedStatementFileName = `${DOOLA_MONEY_STATEMENT_PREFIX}_${month}_${year}.pdf`;
          const blob = base64ToBlob(data.statements);
          const link = document.createElement("a");

          link.href = window.URL.createObjectURL(blob);
          link.download = downloadedStatementFileName;

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          posthog?.capture("money_transactions_statement_download_success", {
            downloadedStatementFileName,
          });
        }
      }
    } catch (error) {
      console.error("Failed to fetch statements, error: ", error);
    } finally {
      setIsStatementsLoading(false);
    }
  };

  return (
    <Stack
      p={3}
      borderRadius={6}
      justifyContent={"space-between"}
      bgcolor={colors.lightGrey}
      direction={"row"}
      alignItems={"center"}
    >
      {isStatementsLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <Stack gap={2} direction={"row"} alignItems={"center"}>
            <CalendarTodayIcon sx={{ fontSize: "20px" }} />
            <Typography fontSize={16} fontWeight={500}>
              {month} {year}
            </Typography>
          </Stack>
          <IconButton
            onClick={() => handleDownload()}
            sx={{
              width: 36,
              height: 36,
              bgcolor: colors.grey,
              borderRadius: "50%",
            }}
          >
            <DownloadIcon style={{ fontSize: 16, color: colors.black }} />
          </IconButton>
        </>
      )}
    </Stack>
  );
};

export default MonthItem;
