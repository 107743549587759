import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Checkbox,
  FormControlLabel,
  ListItemText,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { PersonOutlined } from "@mui/icons-material";
import ButtonBox from "components/ButtonBox";
import CustomInput from "components/Form/CustomInput";
import { Windows1252Regex } from "utils/regexValidations";
import CustomDatePicker from "components/Form/CustomDatePicker";
import CustomSelect from "components/Form/CustomSelect";
import { LoadingComponent } from "components/Loading";
import StyledCheckbox from "components/FormElements/Checkbox";
import useGetMoneyLookups from "views/DashboardApp/Money/hooks/useGetMoneyLookups";
import utils, { colors } from "utils";
import {
  LookupTypeKey,
  OnboardingStakeholderBusinessEntityType,
  WalletOnboard,
} from "graphql/__generated__/types";
import {
  capitalizeFirstLetterAndJoin,
  extractOnboardingApplicant,
} from "views/DashboardApp/Money/Onboarding/helpers";
import { addMemberSteps } from "../types";
import {
  INTITAL_INDIVIDUAL_MEMBER_INFO_VALUES,
  InitialIndividualMemberInfoValuesType,
} from "./helpers/intialIndividualMemberInfoData";
import { IAddMemberStepsProps } from "../../types";
import { MemberDialogFormModeEnum } from "../../../types";
import { removeTypename } from "utils/helpers/removeTypename";
import { useSetMoneyOnboardingMutation } from "graphql/__generated__/operations/MoneyOnboardingValidation.generated";
import { isValueValid } from "views/DashboardApp/Money/helpers";

const IndividualMemberDetails = ({
  setAddMemberCurrentStep,
  stakeholderObj,
  setStakeholderObj,
  memberDialogDetails,
  onboardingData,
  isApplicant,
  setIsApplicant,
}: IAddMemberStepsProps) => {
  const [isMemberAddressDifferent, setIsMemberAddressDifferent] =
    useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { lookupsData, isLookupsDataLoading } = useGetMoneyLookups({
    neededLookups: [LookupTypeKey.COUNTRYOFOPERATION, LookupTypeKey.POSITION],
  });

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const { t } = useTranslation();

  const { control, formState, handleSubmit, getValues, trigger, setValue } =
    useForm<InitialIndividualMemberInfoValuesType>({
      defaultValues: { ...INTITAL_INDIVIDUAL_MEMBER_INFO_VALUES },
      mode: "onChange",
    });

  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  const [updateMoneyOnboarding] = useSetMoneyOnboardingMutation();

  const isEditMode = memberDialogDetails.mode === MemberDialogFormModeEnum.edit;

  useEffect(() => {
    const {
      firstName,
      lastName,
      nationality,
      professionalDetails,
      dateOfBirth,
    } = getValues();

    if (
      isValueValid(firstName) &&
      isValueValid(lastName) &&
      isValueValid(nationality) &&
      isValueValid(professionalDetails) &&
      isValueValid(dateOfBirth)
    ) {
      trigger();
    }
  }, [getValues, trigger]);

  useEffect(() => {
    if (isEditMode) {
      const filteredOnboardingData: WalletOnboard =
        removeTypename(onboardingData);

      const companyAddress = filteredOnboardingData.companyInfo?.address;

      const targetedStakeholder = filteredOnboardingData?.stakeholders?.find(
        (stakeholder) => stakeholder?.uuid === memberDialogDetails.stakeholderId
      );
      const referenceObj =
        stakeholderObj?.uuid !== "" ? stakeholderObj : targetedStakeholder;

      const applicant = filteredOnboardingData?.applicant;

      const applicantUniqueKey = `${applicant?.firstName} ${applicant?.lastName} ${applicant?.dateOfBirth}`;
      const stakeholderUniqueKey = `${targetedStakeholder?.firstName} ${targetedStakeholder?.lastName} ${targetedStakeholder?.dateOfBirth}`;

      setIsApplicant(applicantUniqueKey === stakeholderUniqueKey);

      setValue("firstName", referenceObj?.firstName || "");
      setValue("lastName", referenceObj?.lastName || "");
      setValue("nationality", referenceObj?.nationality || "");
      setValue("dateOfBirth", referenceObj?.dateOfBirth || "");
      setValue("professionalDetails", referenceObj?.professionalDetails || []);

      setIsMemberAddressDifferent(
        JSON.stringify(referenceObj?.address) !== JSON.stringify(companyAddress)
      );
    }

    // This is to persist the form value when navigating back to this component
    else {
      setValue("firstName", stakeholderObj?.firstName || "");
      setValue("lastName", stakeholderObj?.lastName || "");
      setValue("nationality", stakeholderObj?.nationality || "");
      setValue("dateOfBirth", stakeholderObj?.dateOfBirth || "");
      setValue(
        "professionalDetails",
        stakeholderObj?.professionalDetails || []
      );
    }
  }, [
    isEditMode,
    memberDialogDetails.stakeholderId,
    onboardingData,
    setIsApplicant,
    setValue,
    stakeholderObj,
  ]);

  if (!lookupsData || isLookupsDataLoading) {
    return (
      <Stack height={"200px"} alignItems={"center"} justifyContent={"center"}>
        <LoadingComponent />
      </Stack>
    );
  }

  const onSubmitHandler = async (
    newStakeholder: InitialIndividualMemberInfoValuesType
  ) => {
    try {
      setIsLoading(true);

      const filteredOnboardingData: WalletOnboard =
        removeTypename(onboardingData);

      const applicant = filteredOnboardingData.applicant;

      const companyAddress = filteredOnboardingData.companyInfo?.address;

      if (isMemberAddressDifferent) {
        if (isEditMode) {
          const targettedStakeholderObj =
            filteredOnboardingData?.stakeholders?.find(
              (stakeholder) =>
                stakeholder?.uuid === memberDialogDetails.stakeholderId
            );

          if (targettedStakeholderObj) {
            setStakeholderObj({
              ...targettedStakeholderObj,
              ...newStakeholder,
            });
          }
        } else {
          setStakeholderObj({
            ...stakeholderObj,
            ...newStakeholder,
            uuid: uuidv4(),
          });
        }

        setAddMemberCurrentStep(addMemberSteps.individualMemberAddress);
      } else {
        if (isEditMode) {
          const targettedStakeholderObj =
            filteredOnboardingData?.stakeholders?.find(
              (stakeholder) =>
                stakeholder?.uuid === memberDialogDetails.stakeholderId
            );

          const stakeholdersWithUpdatedData =
            filteredOnboardingData?.stakeholders?.map((stakeholder) =>
              stakeholder?.uuid === memberDialogDetails.stakeholderId
                ? {
                    ...stakeholder,
                    ...newStakeholder,
                    documents: stakeholder.documents,
                    address: companyAddress,
                  }
                : stakeholder
            );

          const applicantData = extractOnboardingApplicant({
            ...targettedStakeholderObj,
            ...newStakeholder,
          });

          await updateMoneyOnboarding({
            variables: {
              ...filteredOnboardingData,
              stakeholders: stakeholdersWithUpdatedData,
              ...(isApplicant && {
                applicant: {
                  ...applicant,
                  ...applicantData,
                  documents: targettedStakeholderObj?.documents,
                  address: companyAddress,
                  professionalDetailsPositions: [
                    OnboardingStakeholderBusinessEntityType.CONTROL_PRONG,
                  ],
                },
              }),
            },
          });

          setStakeholderObj({
            ...targettedStakeholderObj,
            ...newStakeholder,
            documents: targettedStakeholderObj?.documents,
            address: companyAddress,
          });
        } else {
          const uuid = uuidv4();

          const applicantData = extractOnboardingApplicant({
            ...stakeholderObj,
            ...newStakeholder,
          });

          await updateMoneyOnboarding({
            variables: {
              ...filteredOnboardingData,
              stakeholders: [
                ...(filteredOnboardingData.stakeholders || []),
                {
                  ...stakeholderObj,
                  ...newStakeholder,
                  uuid,
                  address: companyAddress,
                },
              ],
              ...(isApplicant && {
                applicant: {
                  ...applicant,
                  ...applicantData,
                  address: companyAddress,
                  professionalDetailsPositions: [
                    OnboardingStakeholderBusinessEntityType.CONTROL_PRONG,
                  ],
                },
              }),
            },
          });

          setStakeholderObj({
            ...stakeholderObj,
            ...newStakeholder,
            uuid,
            address: companyAddress,
          });
        }

        setAddMemberCurrentStep(addMemberSteps.individualMemberDocuments);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const isDisabled = !formState.isValid;

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} gap={1.5} mb={3}>
        <PersonOutlined />
        <Typography fontSize={16} fontWeight={500}>
          {t("money.onboarding.step.membersInformation.individualMember")}
        </Typography>
      </Stack>
      <Stack gap={4}>
        <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
          <Stack gap={5}>
            <Stack gap={2}>
              <Stack direction={isTablet ? "column" : "row"} gap={3}>
                <Controller
                  name="firstName"
                  {...COMMON_PROPS}
                  rules={{
                    required: true,
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      placeholder={
                        t(
                          "money.onboarding.step.membersInformation.firstName.placeholder"
                        ) as string
                      }
                      label={
                        t(
                          "money.onboarding.step.membersInformation.firstName"
                        ) as string
                      }
                    />
                  )}
                />
                <Controller
                  name="lastName"
                  {...COMMON_PROPS}
                  rules={{
                    required: true,
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      placeholder={
                        t(
                          "money.onboarding.step.membersInformation.lastName.placeholder"
                        ) as string
                      }
                      label={
                        t(
                          "money.onboarding.step.membersInformation.lastName"
                        ) as string
                      }
                    />
                  )}
                />
              </Stack>
              <Stack direction={isTablet ? "column" : "row"} gap={3}>
                {lookupsData?.COUNTRYOFOPERATION && (
                  <Controller
                    name="nationality"
                    {...COMMON_PROPS}
                    rules={{
                      pattern: {
                        value: Windows1252Regex,
                        message: t("error.invalidCharacters"),
                      },
                      required: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <CustomSelect
                        {...field}
                        error={error !== undefined}
                        styles={{ width: "100%" }}
                        placeholder={
                          t(
                            "money.onboarding.step.membersInformation.nationality.placeholder"
                          ) as string
                        }
                        label={
                          t(
                            "money.onboarding.step.membersInformation.nationality"
                          ) as string
                        }
                        defaultValue={field.value}
                        onChange={(e: SelectChangeEvent<unknown>) => {
                          field.onChange(e.target.value);
                        }}
                      >
                        {lookupsData.COUNTRYOFOPERATION.map((item, index) => (
                          <MenuItem key={index} value={item?.key ?? ""}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    )}
                  />
                )}
                <Controller
                  name="dateOfBirth"
                  rules={{
                    required: true,
                  }}
                  {...COMMON_PROPS}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <CustomDatePicker
                      value={dayjs(new Date(value ?? 0))}
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      onChange={(newValue) =>
                        onChange(dayjs(newValue).format("YYYY-MM-DD"))
                      }
                      placeholder={
                        t(
                          "money.onboarding.step.membersInformation.dob.placeholder"
                        ) as string
                      }
                      label={
                        t(
                          "money.onboarding.step.membersInformation.dob"
                        ) as string
                      }
                      maxDate={utils.maxAgeDate}
                      disableFuture
                    />
                  )}
                />
              </Stack>
              <Stack direction={isTablet ? "column" : "row"} gap={3} mb={4}>
                {lookupsData?.POSITION && (
                  <Controller
                    name="professionalDetails"
                    {...COMMON_PROPS}
                    rules={{
                      pattern: {
                        value: Windows1252Regex,
                        message: t("error.invalidCharacters"),
                      },
                      required: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <CustomSelect
                        {...field}
                        error={error !== undefined}
                        styles={{ width: "100%" }}
                        placeholder={
                          t(
                            "money.onboarding.step.membersInformation.position.placeholder"
                          ) as string
                        }
                        label={
                          t(
                            "money.onboarding.step.membersInformation.position"
                          ) as string
                        }
                        multiple
                        defaultValue={field.value}
                        onChange={(e: SelectChangeEvent<unknown>) => {
                          const selectedValue = e.target.value;

                          field.onChange(
                            typeof selectedValue === "string"
                              ? selectedValue.split(",")
                              : selectedValue
                          );
                        }}
                        renderValue={(selected) =>
                          capitalizeFirstLetterAndJoin(selected)
                        }
                      >
                        {lookupsData.POSITION.map((item, index) => {
                          const key = item?.key ?? "";
                          const isChecked = field.value.includes(key);

                          return (
                            <MenuItem key={index} value={key}>
                              <Checkbox checked={isChecked} />
                              <ListItemText primary={item?.description} />
                            </MenuItem>
                          );
                        })}
                      </CustomSelect>
                    )}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
          <Stack
            spacing={2}
            bgcolor={colors.lightGrey}
            p={3}
            borderRadius="24px"
            mb={4}
          >
            <FormControlLabel
              control={
                <StyledCheckbox
                  iconBgColor={colors.lightGrey}
                  iconbgcolorchecked={colors.black}
                  iconBorderColor={colors.black}
                  onChange={() =>
                    setIsMemberAddressDifferent(!isMemberAddressDifferent)
                  }
                  checked={!isMemberAddressDifferent}
                />
              }
              label={
                <Typography fontSize={16} color={colors.contentSecondary}>
                  {t(
                    "money.onboarding.step.membersInformation.individualMember.checkbox.description"
                  )}
                </Typography>
              }
              sx={{ alignItems: "center", mr: 0, gap: 1 }}
            />
          </Stack>
          <Stack
            spacing={2}
            bgcolor={colors.lightGrey}
            p={3}
            borderRadius="24px"
            mb={4}
          >
            <FormControlLabel
              control={
                <StyledCheckbox
                  iconBgColor={colors.lightGrey}
                  iconbgcolorchecked={colors.black}
                  iconBorderColor={colors.black}
                  onChange={() => setIsApplicant(!isApplicant)}
                  checked={isApplicant}
                />
              }
              label={
                <Typography fontSize={16} color={colors.contentSecondary}>
                  {t(
                    "money.onboarding.step.membersInformation.individualMember.applicant.checkbox.description"
                  )}
                </Typography>
              }
              sx={{ alignItems: "center", mr: 0, gap: 1 }}
            />
          </Stack>
          <ButtonBox
            showBackButton={!isEditMode}
            isLoading={isLoading}
            disabled={isDisabled}
            rightButtonText={isMemberAddressDifferent ? "Next" : "Save"}
            onClickBack={() =>
              setAddMemberCurrentStep(addMemberSteps.memberType)
            }
            onClickNext={() => onSubmitHandler}
          />
        </form>
      </Stack>
    </>
  );
};

export default IndividualMemberDetails;
