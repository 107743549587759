import { useCallback, useEffect, useState } from "react";
import { useGetMoneyExchangeRateLazyQuery } from "graphql/__generated__/operations/MoneyExchangeRate.generated";
import { ExchangeRate } from "graphql/__generated__/types";
import { USD_CURRENCY_KEY } from "../helpers";
import useCompany from "graphql/hooks/UseCompany";

interface IExchangeRate {
  getExchangeRate: (countryCode: string, network?: boolean) => Promise<void>;
  exchangeRateData?: ExchangeRate | null;
  isExchangeRateDataLoading: boolean;
}

const SOURCE_CURRENCY_CODE = USD_CURRENCY_KEY;

export default function useGetExchangeRate(
  destinationCurrencyCode?: string
): IExchangeRate {
  const { currentCompany } = useCompany();

  const [isExchangeRateDataLoading, setIsExchangeRateDataLoading] =
    useState<boolean>(false);
  const [exchangeRateData, setExchangeRateData] =
    useState<ExchangeRate | null>();

  const [fetchExchangeRate] = useGetMoneyExchangeRateLazyQuery({
    errorPolicy: "all",
  });

  const getExchangeRate = useCallback(
    async (destinationCurrencyCode: string, network: boolean = false) => {
      setIsExchangeRateDataLoading(true);

      try {
        const { data: exchangeRateData } = await fetchExchangeRate({
          variables: {
            doolaCompanyId: currentCompany?.id ?? "",
            destinationCurrencyCode,
            sourceCurrencyCode: SOURCE_CURRENCY_CODE,
          },
          fetchPolicy: network ? "network-only" : "cache-first",
        });

        const exchangeRate = exchangeRateData?.exchangeRate?.payload;

        if (exchangeRate) {
          setExchangeRateData(exchangeRate);
        }
      } catch (error) {
        console.log("Failed to fetch exchangeRate data, error: ", error);
      } finally {
        setIsExchangeRateDataLoading(false);
      }
    },
    [currentCompany?.id, fetchExchangeRate]
  );

  useEffect(() => {
    if (destinationCurrencyCode && currentCompany?.id) {
      getExchangeRate(destinationCurrencyCode);
    }
  }, [getExchangeRate, destinationCurrencyCode, currentCompany?.id]);

  return {
    getExchangeRate,
    exchangeRateData,
    isExchangeRateDataLoading,
  };
}
