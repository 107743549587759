import { Iso31661Alpha3 } from "graphql/__generated__/types";

export const INITIAL_MEMBER_VALUES = {
  address: {
    city: "",
    country: "USA" as Iso31661Alpha3,
    line1: "",
    line2: "",
    postalCode: "",
    state: "",
    phone: "",
  },
  contactFullName: "",
  isNaturalPerson: true,
  legalFirstName: "",
  legalLastName: "",
  socialSecurityNumber: "",
  confirmSocialSecurityNumber: "",
};
