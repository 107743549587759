import { Stack, Typography } from "@mui/material";
import { ISubStepProps, membersSubSteps } from "../../types";
import PercentIcon from "@mui/icons-material/Percent";
import { colors } from "utils";
import { useTranslation } from "react-i18next";
import ButtonBox from "components/ButtonBox";
import { useEffect, useState } from "react";
import {
  OnboardingStakeholder,
  OnboardingStakeholderType,
  WalletOnboard,
} from "graphql/__generated__/types";
import {
  CorporateFareOutlined,
  PercentOutlined,
  PersonOutlineRounded,
  ReplayOutlined,
  WarningAmberRounded,
} from "@mui/icons-material";
import { format as formatDate } from "fecha";
import CustomInput from "components/Form/CustomInput";
import Button from "components/Button";
import useOnboardingData from "views/DashboardApp/Money/Onboarding/hooks/useOnboardingData";
import { LoadingComponent } from "components/Loading";
import { distributeSharesAmongMembers } from "utils/sharesDistribution";
import { useSetMoneyOnboardingMutation } from "graphql/__generated__/operations/MoneyOnboardingValidation.generated";
import { removeTypename } from "utils/helpers/removeTypename";
import {
  OnboardingStepsCategories,
  OnboardingStepsData,
} from "views/DashboardApp/Money/Onboarding/data";
import { usePostHog } from "posthog-js/react";
import { isValidDateString } from "utils/helpers";

const OwnershipPercentage = ({
  setCurrentStep,
  setCurrentSubStep,
  setShowHeading,
}: ISubStepProps) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [totalPercentage, setTotalPercentage] = useState<number>(0);
  const [stakeholders, setStakeholders] =
    useState<(OnboardingStakeholder | null)[]>();

  const { onboardingData, isOnboardingDataLoading } = useOnboardingData();
  const [updateMoneyOnboarding] = useSetMoneyOnboardingMutation();

  const posthog = usePostHog();

  useEffect(() => {
    setShowHeading(false);

    return () => {
      setShowHeading(true);
    };
  }, [setShowHeading]);

  useEffect(() => {
    if (onboardingData?.stakeholders) {
      setStakeholders(onboardingData.stakeholders);
    }
  }, [onboardingData?.stakeholders]);

  useEffect(() => {
    if (stakeholders) {
      const totalPercentage = Math.fround(
        (stakeholders ?? []).reduce(
          (sum, member) => sum + +(member?.ownershipPercentage ?? 0),
          0
        )
      );

      const hasAtLeastOneMemberWithoutPercentage = stakeholders.some(
        (member) =>
          !member?.ownershipPercentage || +member.ownershipPercentage === 0
      );

      setTotalPercentage(+totalPercentage.toFixed(2));
      setHasError(
        hasAtLeastOneMemberWithoutPercentage || totalPercentage !== 100
      );
    }
  }, [stakeholders]);

  if (!onboardingData || isOnboardingDataLoading) {
    return (
      <Stack height={"200px"} alignItems={"center"} justifyContent={"center"}>
        <LoadingComponent />
      </Stack>
    );
  }

  const handleUpdateOwnership = (ownership: string, uuid: string) => {
    setStakeholders((prevState) => {
      const updatedState = prevState?.map((member) =>
        member?.uuid === uuid
          ? { ...member, ownershipPercentage: ownership }
          : member
      );

      return updatedState;
    });
  };

  const handleOnNext = async () => {
    posthog?.capture("money_onboarding_ownership_percentage_next_btn_click");

    const filteredOnboardingData: WalletOnboard =
      removeTypename(onboardingData);

    try {
      setIsLoading(true);

      await updateMoneyOnboarding({
        variables: {
          ...filteredOnboardingData,
          stakeholders: removeTypename(stakeholders),
        },
      });

      setCurrentStep(
        OnboardingStepsData[OnboardingStepsCategories.applicantInformation]
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onResetMembers = () => {
    setStakeholders((prevState) => {
      const numberOfMembers = prevState?.length || 0;
      const defaultShares = distributeSharesAmongMembers(numberOfMembers);

      const updatedMemberState = (prevState ?? []).map((member, i) => ({
        ...member,
        ownershipPercentage: defaultShares[i].toString(),
      }));

      return updatedMemberState;
    });
  };

  const isDisabled = isLoading || hasError;

  return (
    <>
      <Stack mb={3}>
        <Stack direction={"row"} alignItems={"center"} gap={1.5}>
          <PercentIcon />
          <Typography fontSize={16} fontWeight={500}>
            {t(
              "money.onboarding.step.membersInformation.ownershipPercentage.title"
            )}
          </Typography>
        </Stack>
        <Typography fontSize={16} color={colors.contentTertiary} mt={0.5}>
          {t(
            "money.onboarding.step.membersInformation.ownershipPercentage.description"
          )}
        </Typography>
      </Stack>
      <Stack mb={5} gap={2}>
        {stakeholders &&
          stakeholders.map((member, index) => {
            if (member) {
              const isMemberPerson =
                member.type === OnboardingStakeholderType.PERSON;

              return (
                <Stack
                  key={index}
                  direction={"row"}
                  sx={{
                    bgcolor: colors.lightGrey,
                    borderRadius: "24px",
                    p: 2,
                    pl: 3,
                  }}
                >
                  <Stack
                    direction={"row"}
                    gap={3}
                    alignItems={"center"}
                    width={"100%"}
                  >
                    {isMemberPerson ? (
                      <PersonOutlineRounded />
                    ) : (
                      <CorporateFareOutlined />
                    )}
                    <Stack width={"100%"}>
                      <Typography fontSize={16} fontWeight={500}>
                        {isMemberPerson
                          ? `${member.firstName} ${member.lastName}`
                          : member.businessName}
                      </Typography>
                      <Typography color={colors.contentTertiary}>
                        {(isMemberPerson
                          ? isValidDateString(member.dateOfBirth) &&
                            formatDate(
                              new Date(member.dateOfBirth ?? 0),
                              "MM/DD/YYYY"
                            )
                          : "") ?? t("general.missingInformation")}
                      </Typography>
                    </Stack>
                  </Stack>
                  <CustomInput
                    type="number"
                    value={member.ownershipPercentage}
                    inputProps={{ min: 0, max: 100, step: 1 }}
                    styles={{ width: "180px" }}
                    inputStyles={{
                      borderRadius: "1rem",
                      ...(hasError && { color: colors.red }),
                    }}
                    endIcon={
                      <PercentOutlined
                        sx={{ ...(hasError && { color: colors.red }) }}
                      />
                    }
                    onChange={(e) =>
                      handleUpdateOwnership(e.target.value, member.uuid ?? "")
                    }
                  />
                </Stack>
              );
            }

            return null;
          })}
        <Stack
          direction={"row"}
          sx={{
            bgcolor: colors.lightGrey,
            borderRadius: "24px",
            p: 2,
            pr: 3,
          }}
          justifyContent={"space-between"}
        >
          <Button
            styles={{ bgcolor: colors.grey }}
            dark={false}
            buttonText={"Reset"}
            endIcon={<ReplayOutlined />}
            onClick={onResetMembers}
          />
          <Stack direction={"row"} gap={1.5} alignItems={"center"}>
            <Typography
              color={colors.contentTertiary}
              sx={{ ...(hasError && { color: colors.red }) }}
            >
              {t("general.total")}
            </Typography>
            <Typography
              variant="h4"
              sx={{ ...(hasError && { color: colors.red }) }}
            >{`${totalPercentage}%`}</Typography>
          </Stack>
        </Stack>
        {hasError && (
          <Stack
            sx={{ bgcolor: colors.lightRed, borderRadius: 6, p: 3 }}
            gap={2}
          >
            <WarningAmberRounded />
            <Stack>
              <Typography variant="body2" fontSize={"1rem"}>
                {t("general.ownershipPercentageError.1")}
              </Typography>
              <Typography variant="body1" fontSize={"1rem"}>
                {t("general.ownershipPercentageError.2")}
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
      <ButtonBox
        isLoading={isLoading}
        disabled={isDisabled}
        onClickBack={() => {
          posthog?.capture(
            "money_onboarding_ownership_percentage_back_btn_click"
          );

          setCurrentSubStep(membersSubSteps.membersInformation);
        }}
        onClickNext={handleOnNext}
      />
    </>
  );
};

export default OwnershipPercentage;
