import { Stack, useMediaQuery } from "@mui/material";
import { Logo } from "components/Logos";
import { Outlet, Link as RouterLink } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import utils, { colors } from "utils";
import { ReactComponent as Flagman } from "assets/PrePurchase/flagman.svg";
import { Footer } from "components/PrePurchase/Footer";
import { useEffect } from "react";

const Layout = () => {
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  useEffect(() => {
    document.title = utils.constants.title.guest;
  }, []);

  return (
    <Stack direction={"row"}>
      {!isTablet && (
        <Stack bgcolor={colors.lightBlue} sx={{ px: 6, py: 3, width: "50%" }}>
          <RouterLink to={ROUTER_PATHS.PRE_PURCHASE_REGISTRATION}>
            <Logo width={100} />
          </RouterLink>
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            height={"100%"}
          >
            <Flagman width="100%" height={"500px"} />
          </Stack>
        </Stack>
      )}
      <Stack
        width={isTablet ? "100%" : "50%"}
        px={isTablet ? 2 : 0}
        py={isTablet ? 4 : 0}
      >
        {isTablet && (
          <RouterLink to={ROUTER_PATHS.PRE_PURCHASE_REGISTRATION}>
            <Logo width={100} />
          </RouterLink>
        )}
        <Stack
          minHeight={"100vh"}
          mt={isTablet ? 2 : 0}
          sx={{ pl: isTablet ? 0 : 10, pr: isTablet ? 0 : 20 }}
        >
          <Stack
            flex={1}
            justifyContent={isTablet ? "flex-start" : "center"}
            py={isTablet ? 0 : 7.5}
            maxWidth={isTablet ? "auto" : "sm"}
          >
            <Outlet />
          </Stack>
          {!isTablet && (
            <Stack mt={"auto"} mb={2}>
              <Footer />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Layout;
