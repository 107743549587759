import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { CustomButton } from "components/Buttons";
import { CustomButtonVariants } from "components/Buttons/types";
import { useRequestStripeCheckoutSessionDdsMutation } from "graphql/__generated__/operations/RequestStripeCheckoutSessionFromDDS.generated";
import { t } from "i18next";
import BooksUpsell from "views/DashboardApp/Books/Upsell/BooksUpsell";
import DurationTabSelector from "components/DurationTabSelector";
import useCompany from "graphql/hooks/UseCompany";
import useCustomer from "graphql/hooks/useCustomer";
import { ROUTER_PATHS } from "routes/routes";
import config from "config";
import { ProductDuration } from "graphql/__generated__/types";
import { LoadingComponent } from "components/Loading";
import useGetBooksProducts from "./hooks/useGetBooksProducts";
import { BOOKS, BooksPackageValue } from "./data";
import { usePostHog } from "posthog-js/react";

const redirectBaseUri = `${config.basePath}${ROUTER_PATHS.BOOKS}`;

type Props = {
  showInvoiceUpsell: boolean;
};

const UpsellContainer = ({ showInvoiceUpsell }: Props) => {
  const posthog = usePostHog();

  const [checkoutWithStripe, { loading: isLoading }] =
    useRequestStripeCheckoutSessionDdsMutation();
  const { currentCompany } = useCompany();
  const { currentUser } = useCustomer();

  const [duration, setDuration] = useState<ProductDuration>(
    ProductDuration.ANNUAL
  );
  const [booksPackage, setBooksPackage] = useState<BooksPackageValue>(BOOKS);
  const [isDoolaBooksSelected, setIsDoolaBooksSelected] = useState(false);

  const { BOOKS_PLANS, isProductQueryLoading } = useGetBooksProducts();

  if (isProductQueryLoading) {
    return <LoadingComponent />;
  }

  const companyId = currentCompany?.id || "";
  const email = currentUser?.email || "";

  const handlePlanChange = (packageName: BooksPackageValue) => {
    if (booksPackage === packageName && isDoolaBooksSelected) {
      setIsDoolaBooksSelected(false);
    } else {
      setBooksPackage(packageName);
      setIsDoolaBooksSelected(true);
    }

    posthog?.capture("books_product_selected", {
      product_name: packageName,
    });
  };

  const onTabChangeHandler = (
    _event: React.SyntheticEvent,
    newValue: ProductDuration
  ) => {
    posthog?.capture("books_duration_click", {
      duration_clicked: newValue,
    });

    setDuration(newValue);
  };

  const selectedPlanPriceEnding =
    duration === ProductDuration.ANNUAL
      ? `/yr`
      : `*12 ${t("signup.formation.monthlyInstallments")}`;

  const renderPackageTitle = (packageName: BooksPackageValue) => (
    <Typography fontWeight={700}>
      {packageName} - ${BOOKS_PLANS[packageName].duration[duration]!.price}
      <Typography variant="body2" component={"span"}>
        {selectedPlanPriceEnding}
      </Typography>
    </Typography>
  );

  const customTitle =
    "Upgrade to a doola Books subscription to begin streamlining your bookkeeping process!";

  const onSubmit = async () => {
    const lookupKey =
      booksPackage && BOOKS_PLANS[booksPackage].duration[duration]!.lookupKey;

    try {
      const { data } = await checkoutWithStripe({
        variables: {
          companyId,
          customerId: currentUser?.id || "",
          products: [lookupKey],
          redirectBaseUri,
          email: email || "",
        },
      });

      const stripeCheckoutUrl =
        data?.company?.requestStripeCheckoutSessionDDS?.url;

      if (stripeCheckoutUrl) {
        posthog?.capture("books_upsell_checkout_started", {
          product_selected: booksPackage,
          pricing_selected: duration,
        });

        await new Promise((resolve) => window.setTimeout(resolve, 500)); // wait 500ms
        window.location.replace(stripeCheckoutUrl);
      }
    } catch {
      // error handling delegated upstream to the ErrorLink
    }
  };

  return (
    <Stack p={5} direction="column">
      <Stack justifyContent={"center"} direction="row">
        <DurationTabSelector
          onChange={onTabChangeHandler}
          planDuration={duration}
        />
      </Stack>
      <Stack direction="column">
        <BooksUpsell
          onChange={handlePlanChange}
          renderPackageTitle={renderPackageTitle}
          isDoolaBooksSelected={!!isDoolaBooksSelected}
          booksPackageName={booksPackage}
          customTitle={customTitle}
          showInvoiceUpsell={showInvoiceUpsell}
        />
        <CustomButton
          loading={isLoading}
          onClick={onSubmit}
          disabled={!isDoolaBooksSelected}
          width="100%"
          $variant={CustomButtonVariants.black}
        >
          Purchase doola Books
        </CustomButton>
      </Stack>
    </Stack>
  );
};

export default UpsellContainer;
