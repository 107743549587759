// Check if the dateString is truthy and if it can be parsed into a valid date
export const isValidDateString = (dateString?: string | null): boolean => {
  return !!dateString && !isNaN(Date.parse(dateString));
};

export const isNumericInputValid = (inputValue: string) => {
  return /^\d+(\.\d{0,2})?$/.test(inputValue) || inputValue === "";
};

export const isDecimalInputValid = (inputValue: string) => {
  return (
    /^(?:0(?:\.\d{1,6})?|[1-9]\d*(?:\.\d{0,2})?)$/.test(inputValue) ||
    inputValue === ""
  );
};

export const isWholeNumberInputValid = (inputValue: string) => {
  return /^(?!0+$)\d+$/.test(inputValue) || inputValue === "";
};
