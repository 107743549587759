export const COMISSION_CURRENT_CAMPAIGN = 100;

export const EMAIL_CONTENT = (friend: string, link: string) => [
  {
    paragraph: "Hi There 👋",
    bottomLines: 2,
  },
  {
    paragraph:
      "We're doola and we help founders confidently start a US business from anywhere in the world.",
    bottomLines: 2,
  },
  {
    paragraph:
      "Your friend " +
      friend +
      " thinks you're a great fit and has requested we provide you a VIP onboarding experience from our CEO, Arjun Mahadevan.",
    bottomLines: 1,
  },
  {
    paragraph:
      "It only takes 5 minutes to sign up and we're here to answer any questions you might have.",
    bottomLines: 2,
  },
  {
    paragraph:
      "Here's a special link to bump you to the top of our onboarding queue:",
    bottomLines: 2,
  },
  {
    paragraph: link,
    bottomLines: 2,
  },
  {
    paragraph:
      "Have questions? Please respond to this email and we'll respond ASAP!",
    bottomLines: 2,
  },
  {
    paragraph: "Best,",
    bottomLines: 1,
  },
  {
    paragraph: "The doola Team",
    bottomLines: 1,
  },
];
