import { EMAIL_CONTENT } from "./resources";

export class EmailBuilder {
  fullname: string;
  link: string;
  emailContent: {
    paragraph: string;
    bottomLines: number;
  }[];

  constructor(fullname: string, link: string) {
    this.fullname = fullname;
    this.link = link;
    this.emailContent = EMAIL_CONTENT(fullname, link);
  }

  getEmailAsHTML = () => this._buildEmail("<br/>");
  getEmailAsInnerText = () => this._buildEmail("\n");

  _buildEmail = (lineBreak: string): string =>
    this.emailContent
      .map(
        ({ paragraph, bottomLines }) =>
          paragraph + lineBreak.repeat(bottomLines)
      )
      .join("");
}
