import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMoneyTransactionsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['String'];
  offset: Types.Scalars['String'];
  page: Types.Scalars['String'];
}>;


export type GetMoneyTransactionsQuery = { __typename?: 'Query', transactions?: { __typename?: 'WalletTransactionResponse', error?: string | null, payload?: Array<{ __typename?: 'WalletTransaction', id?: string | null, doolaCompanyId?: string | null, transactionCurrencyCode?: string | null, doolaCustomerId?: string | null, status?: string | null, authCurrencyCode?: string | null, authAmount?: string | null, debit?: boolean | null, comments?: string | null, beneficiaryId?: string | null, beneficiaryName?: string | null, payoutMethod?: string | null, destinationCountry?: string | null, dateOfTransaction?: string | null, createdAt?: string | null, customerHashId?: string | null, displayText?: string | null } | null> | null } | null };


export const GetMoneyTransactionsDocument = gql`
    query GetMoneyTransactions($companyId: String!, $offset: String!, $page: String!) {
  transactions(doolaCompanyId: $companyId, offset: $offset, page: $page) {
    error
    payload {
      id
      doolaCompanyId
      transactionCurrencyCode
      doolaCustomerId
      status
      authCurrencyCode
      authAmount
      debit
      comments
      beneficiaryId
      beneficiaryName
      payoutMethod
      destinationCountry
      dateOfTransaction
      createdAt
      customerHashId
      displayText
    }
  }
}
    `;

/**
 * __useGetMoneyTransactionsQuery__
 *
 * To run a query within a React component, call `useGetMoneyTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoneyTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoneyTransactionsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      offset: // value for 'offset'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetMoneyTransactionsQuery(baseOptions: Apollo.QueryHookOptions<GetMoneyTransactionsQuery, GetMoneyTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMoneyTransactionsQuery, GetMoneyTransactionsQueryVariables>(GetMoneyTransactionsDocument, options);
      }
export function useGetMoneyTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMoneyTransactionsQuery, GetMoneyTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMoneyTransactionsQuery, GetMoneyTransactionsQueryVariables>(GetMoneyTransactionsDocument, options);
        }
export type GetMoneyTransactionsQueryHookResult = ReturnType<typeof useGetMoneyTransactionsQuery>;
export type GetMoneyTransactionsLazyQueryHookResult = ReturnType<typeof useGetMoneyTransactionsLazyQuery>;
export type GetMoneyTransactionsQueryResult = Apollo.QueryResult<GetMoneyTransactionsQuery, GetMoneyTransactionsQueryVariables>;