import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMoneyExchangeRateQueryVariables = Types.Exact<{
  destinationCurrencyCode: Types.Scalars['String'];
  sourceCurrencyCode: Types.Scalars['String'];
  doolaCompanyId: Types.Scalars['String'];
}>;


export type GetMoneyExchangeRateQuery = { __typename?: 'Query', exchangeRate?: { __typename?: 'ExchangeRateResponse', error?: string | null, payload?: { __typename?: 'ExchangeRate', audit_id?: string | null, fx_rate?: string | null } | null } | null };


export const GetMoneyExchangeRateDocument = gql`
    query GetMoneyExchangeRate($destinationCurrencyCode: String!, $sourceCurrencyCode: String!, $doolaCompanyId: String!) {
  exchangeRate(
    destinationCurrencyCode: $destinationCurrencyCode
    sourceCurrencyCode: $sourceCurrencyCode
    doolaCompanyId: $doolaCompanyId
  ) {
    error
    payload {
      audit_id
      fx_rate
    }
  }
}
    `;

/**
 * __useGetMoneyExchangeRateQuery__
 *
 * To run a query within a React component, call `useGetMoneyExchangeRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoneyExchangeRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoneyExchangeRateQuery({
 *   variables: {
 *      destinationCurrencyCode: // value for 'destinationCurrencyCode'
 *      sourceCurrencyCode: // value for 'sourceCurrencyCode'
 *      doolaCompanyId: // value for 'doolaCompanyId'
 *   },
 * });
 */
export function useGetMoneyExchangeRateQuery(baseOptions: Apollo.QueryHookOptions<GetMoneyExchangeRateQuery, GetMoneyExchangeRateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMoneyExchangeRateQuery, GetMoneyExchangeRateQueryVariables>(GetMoneyExchangeRateDocument, options);
      }
export function useGetMoneyExchangeRateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMoneyExchangeRateQuery, GetMoneyExchangeRateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMoneyExchangeRateQuery, GetMoneyExchangeRateQueryVariables>(GetMoneyExchangeRateDocument, options);
        }
export type GetMoneyExchangeRateQueryHookResult = ReturnType<typeof useGetMoneyExchangeRateQuery>;
export type GetMoneyExchangeRateLazyQueryHookResult = ReturnType<typeof useGetMoneyExchangeRateLazyQuery>;
export type GetMoneyExchangeRateQueryResult = Apollo.QueryResult<GetMoneyExchangeRateQuery, GetMoneyExchangeRateQueryVariables>;