import {
  PurposeCode,
  ReasonCode,
  SourceOfFunds,
  SwiftFeeType,
  WalletTransferRequest,
} from "graphql/__generated__/types";

export const INITIAL_TRANSACTION_VALUES: WalletTransferRequest = {
  amount: "",
  beneficiaryHashId: "",
  purposeCode: PurposeCode.IR001_TRANSFER_TO_OWN_ACCOUNT,
  sourceOfFunds: SourceOfFunds.BUSINESS_OWNERSHAREHOLDER,
  swiftFeeType: SwiftFeeType.OUR,
  reasonCode: ReasonCode.TRUSTED_BENEFICIARY_01,
};

export const increaseByPercent = (
  amount: number,
  percentIncrease: number = 1
) => (amount ? amount * (1 + percentIncrease / 100) : 0);

export const getPercentAmount = (amount: number, percent: number = 1) =>
  amount ? amount * (percent / 100) : 0;

export const convertToUSD = (
  localCurrency: string,
  exchangeRate: number
): string => {
  if (exchangeRate === 0) {
    return localCurrency;
  }

  const localCurrencyAmount = +localCurrency;

  if (isNaN(localCurrencyAmount)) {
    return "";
  }

  return (localCurrencyAmount / exchangeRate).toFixed(2);
};

export const convertToLocalCurrency = (
  USD: string,
  exchangeRate: number
): string => {
  if (exchangeRate === 0) {
    return USD;
  }

  const convertedValue = USD ? (+USD * exchangeRate).toFixed(2) : "0.00";

  return convertedValue;
};

export const getCurrencySymbol = (
  currency: string,
  locale: string = "en-US"
) => {
  const parts = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).formatToParts();

  const symbolPart = parts.find((part) => part.type === "currency");

  return symbolPart?.value || null;
};

export const formatString = (
  input: string,
  hasCode: boolean = true,
  codeInFront: boolean = true
): string => {
  const parts = input.split("_");

  const code = hasCode ? (codeInFront ? parts.shift() : parts.pop()) : "";

  const capitalizeFirstLetter = (word: string) =>
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

  const description = parts.map((word, index) =>
    index === 0 ? capitalizeFirstLetter(word) : word.toLowerCase()
  );

  return `${code}${hasCode ? " - " : ""}${description.join(" ")}`;
};
