import { CorporateFareOutlined } from "@mui/icons-material";
import { MenuItem, SelectChangeEvent, Stack, Typography } from "@mui/material";
import ButtonBox from "components/ButtonBox";
import CustomInput from "components/Form/CustomInput";
import CustomSelect from "components/Form/CustomSelect";
import {
  EntityType,
  Iso31661Alpha3,
  TaxMemberInfo,
  TaxMemberInfoInput,
} from "graphql/__generated__/types";
import { INITIAL_MEMBER_VALUES } from "../helpers/initialMembersData";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Windows1252Regex } from "utils/regexValidations";
import { t } from "i18next";
import { useMediaQuery } from "@mui/material";
import utils from "utils";
import ErrorBox from "components/ErrorBox";
import { useGetCountriesQuery } from "graphql/__generated__/operations/Countries.generated";
import { LoadingComponent } from "components/Loading";
import useCompany from "graphql/hooks/UseCompany";
import { useEditTaxMemberInfoMutation } from "graphql/__generated__/operations/EditTaxMemberInfo.generated";
import { IMemberInformationProps } from "../types";

const MemberCompanyInformation = ({
  setShowHeading,
  resetData,
  member,
}: IMemberInformationProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { currentCompany } = useCompany();
  const [editTaxMemberInfo] = useEditTaxMemberInfoMutation();

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const { control, formState, watch, setValue, handleSubmit, trigger } =
    useForm<TaxMemberInfoInput>({
      defaultValues: { ...INITIAL_MEMBER_VALUES },
      mode: "onChange",
    });

  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  const countryCode = watch("address.country");
  const entityType = watch("entityType");

  const { data: countriesData, loading: isCountriesDataLoading } =
    useGetCountriesQuery();
  const countryList = countriesData?.countries;

  const availablePosition = currentCompany?.companyTaxInfo?.members?.length
    ? currentCompany?.companyTaxInfo?.members[
        currentCompany?.companyTaxInfo?.members?.length - 1
      ]?.position! + 1
    : 0;

  useEffect(() => {
    if (member) {
      setValue("contactFullName", member.contactFullName || "");
      setValue("ein", member.ein || "");

      if (member.address) {
        const { __typename, ...address } = member.address;
        setValue("address", address);
      }

      setValue("entityType", member.entityType);
      setValue("isNaturalPerson", false);
      setValue("position", member.position);

      // trigger form check after all the values are set
      setTimeout(() => {
        if (JSON.stringify(member) !== JSON.stringify(INITIAL_MEMBER_VALUES)) {
          trigger();
        }
      }, 1000);
    }
  }, [member, setValue, trigger]);

  useEffect(() => {
    setShowHeading(false);

    return () => {
      setShowHeading(true);
    };
  }, [setShowHeading]);

  if (isCountriesDataLoading && !countryList) {
    return <LoadingComponent />;
  }

  const onSubmitHandler = async (member: TaxMemberInfo) => {
    try {
      setIsLoading(true);

      await editTaxMemberInfo({
        variables: {
          companyId: currentCompany?.id as string,
          memberInfo: {
            member: {
              ...member,
              position: member.position ?? availablePosition,
            },
            position: member.position ?? availablePosition,
          },
        },
      });

      resetData();
    } finally {
      setIsLoading(false);
    }
  };

  const isDisabled = isLoading || isCountriesDataLoading || !formState.isValid;

  return (
    <Stack mb={isTablet ? 9 : 3}>
      <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
        <Stack gap={4}>
          <Stack gap={3}>
            <Stack direction={"row"} alignItems={"center"} gap={1.5}>
              <CorporateFareOutlined />
              <Typography fontSize={16} fontWeight={500}>
                Company member
              </Typography>
            </Stack>
            <Stack gap={2}>
              <Stack direction={isTablet ? "column" : "row"} gap={3}>
                <Controller
                  name="contactFullName"
                  {...COMMON_PROPS}
                  rules={{
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      placeholder="Enter company name"
                      label="Company Name"
                    />
                  )}
                />
                <Controller
                  name="entityType"
                  {...COMMON_PROPS}
                  rules={{
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomSelect
                      {...field}
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      placeholder="Select type"
                      label="Entity type"
                      value={entityType}
                      defaultValue={field.value}
                      onChange={(e: SelectChangeEvent<unknown>) => {
                        field.onChange(e.target.value);
                      }}
                    >
                      {Object.values(EntityType).map((entityType, index) => (
                        <MenuItem key={index} value={entityType}>
                          {entityType}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  )}
                />
              </Stack>
              <Stack direction={isTablet ? "column" : "row"} gap={3}>
                <Controller
                  name="ein"
                  {...COMMON_PROPS}
                  rules={{
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      isProtected
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      placeholder="Enter EIN"
                      label="Employer Identification Number"
                      autoComplete=""
                    />
                  )}
                />
                <Controller
                  name="address.country"
                  {...COMMON_PROPS}
                  rules={{
                    pattern: {
                      value: Windows1252Regex,
                      message: t("error.invalidCharacters"),
                    },
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomSelect
                      {...field}
                      error={error !== undefined}
                      styles={{ width: "100%" }}
                      placeholder="Select country"
                      label="Country"
                      value={countryCode}
                      defaultValue={field.value}
                      onChange={(e: SelectChangeEvent<unknown>) => {
                        field.onChange(e.target.value);
                      }}
                    >
                      {countryList?.map((country, index) => (
                        <MenuItem
                          key={index}
                          value={country?.code as Iso31661Alpha3}
                        >
                          {country?.name}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  )}
                />
              </Stack>
            </Stack>
          </Stack>
          <ErrorBox formState={formState} />
          <ButtonBox
            isLoading={isLoading}
            disabled={isDisabled}
            onClickBack={() => resetData()}
            onClickNext={() => onSubmitHandler}
            rightButtonText={"Save"}
          />
        </Stack>
      </form>
    </Stack>
  );
};

export default MemberCompanyInformation;
