import { Stack, Typography, useMediaQuery } from "@mui/material";
import ButtonBox from "components/ButtonBox";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "state/hooks";
import utils, { colors } from "utils";
import { FormationScreenNames, trackPosthogAddOn } from "../services";
import { ROUTER_PATHS } from "routes/routes";
import {
  selectExpeditedFormationPackage,
  setExpeditedFormationPackage,
} from "state/formationFormSlice";
import { Controller, useForm } from "react-hook-form";
import { CompanyServices, ProductDuration } from "graphql/__generated__/types";
import FormControlElement from "../components/FormControlElement";
import { moneyFormatter } from "utils/formatters";
import useSignupFlow from "graphql/hooks/UseSignupFlow";
import useGetProcessingProducts from "./hooks/useGetProcessingProducts";
import { LoadingComponent } from "components/Loading";
import { IFormAddonPackage, IFormationData } from "../Interfaces";
import { usePostHog } from "posthog-js/react";

const Expedited = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const posthog = usePostHog();

  const { PROCESSING_PLANS, isProductQueryLoading } =
    useGetProcessingProducts();

  const [expediteFormationData, setExpediteFormationData] =
    useState<IFormationData>(PROCESSING_PLANS.EXPEDITED_FORMATION_DATA);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { countryOfResidence } = useSignupFlow();
  const isUSResident = countryOfResidence === "USA";

  const selectedExpeditedFormation = useAppSelector(
    selectExpeditedFormationPackage
  );

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);

  useEffect(() => {
    posthog?.capture("expedited-products-viewed");
  }, [posthog]);

  useEffect(() => {
    if (!isProductQueryLoading) {
      setExpediteFormationData(
        isUSResident
          ? PROCESSING_PLANS.EXPEDITED_FORMATION_DATA
          : PROCESSING_PLANS.EXPEDITED_EIN_DATA
      );
    }
  }, [isUSResident, isProductQueryLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const { control, formState, watch, setValue, handleSubmit } =
    useForm<IFormAddonPackage>({
      defaultValues: {
        name: "",
        stripePriceId: expediteFormationData.value,
        lookupKey: expediteFormationData.lookupKey,
        price: expediteFormationData.price,
        duration: ProductDuration.ONETIME,
        isSelected: false,
        isAddonSelected: false,
      },
    });

  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  const isFormationProcessingSelected = watch("isSelected");
  const isAddonSelected = watch("isAddonSelected");

  useEffect(() => {
    setValue("stripePriceId", expediteFormationData.value);
    setValue("lookupKey", expediteFormationData.lookupKey);
    setValue("price", expediteFormationData.price);
  }, [
    expediteFormationData.lookupKey,
    expediteFormationData.price,
    expediteFormationData.value,
    setValue,
  ]);

  const onPackageChange = useCallback(
    (newValue: string) => {
      setValue("name", newValue);
      setValue("isSelected", true);
    },
    [setValue]
  );

  useEffect(() => {
    if (selectedExpeditedFormation?.item_name) {
      setValue("isAddonSelected", true);
      onPackageChange(selectedExpeditedFormation?.item_name);
    }
  }, [selectedExpeditedFormation, onPackageChange, setValue]);

  if (isProductQueryLoading) {
    return <LoadingComponent />;
  }

  const onSubmitHandler = (data: IFormAddonPackage) => {
    setIsLoading(true);

    const { name, stripePriceId, price, lookupKey } = data;

    if (isFormationProcessingSelected && isAddonSelected) {
      dispatch(
        setExpeditedFormationPackage({
          item_name: name,
          stripePriceId: stripePriceId,
          lookupKey,
          price: price,
          item_category2: isUSResident
            ? CompanyServices.FORMATION
            : CompanyServices.EINCREATION,
          item_category3: ProductDuration.ONETIME,
        })
      );

      posthog?.capture("expedited-product-selected", {
        expedited_product_name: name,
        expedited_product_id: stripePriceId,
        expedited_product_value: price,
        expedited_product_term: ProductDuration.ONETIME,
      });

      trackPosthogAddOn(name, stripePriceId, price);
    } else {
      dispatch(setExpeditedFormationPackage());
    }

    navigate(ROUTER_PATHS.PRE_PURCHASE_BOI_FILING);
  };

  const onBackHandler = () => {
    posthog?.capture("funnel-back", {
      screen: FormationScreenNames.Expedited,
    });

    navigate(ROUTER_PATHS.PRE_PURCHASE_PACKAGES);
  };

  const isDisabled = !isFormationProcessingSelected;

  return (
    <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
      <Stack direction="column" gap={3} mb={isTablet ? 0 : 3}>
        <Typography variant={isTablet ? "h3" : "h2"}>
          {t("signup.expedited.title")}
        </Typography>
        <Controller
          name={"name"}
          {...COMMON_PROPS}
          rules={{
            required: true,
          }}
          render={({ field: { ref, ...field } }) => (
            <>
              <FormControlElement
                {...field}
                title={
                  isUSResident
                    ? t("signup.expedited.formation.title")
                    : t("signup.expedited.ein.title")
                }
                subtitle={
                  (isUSResident
                    ? t("signup.expedited.formation.subtitle")
                    : t("signup.expedited.ein.subtitle")) ?? ""
                }
                optionValue={expediteFormationData.name}
                selectedValue={field.value}
                changeHandler={() => {
                  setValue("isAddonSelected", true);
                  onPackageChange(expediteFormationData.name);
                }}
                sideBySideTitle={!isMobile}
                sideContent={
                  <Stack
                    direction={isMobile ? "row" : "column"}
                    {...(isMobile && { gap: 1 })}
                    alignItems="flex-end"
                    data-testid="prePurchaseExpeditedOption"
                  >
                    <Typography variant="h2" fontSize={28}>
                      {moneyFormatter(expediteFormationData.price)}
                    </Typography>
                    <Typography
                      noWrap
                      variant="body1"
                      color={colors.contentTertiary}
                    >
                      One-time
                    </Typography>
                  </Stack>
                }
              />
              <FormControlElement
                {...field}
                title={
                  isUSResident
                    ? t("signup.expedited.formation.deny.title")
                    : t("signup.expedited.ein.deny.title")
                }
                subtitle={
                  (isUSResident
                    ? t("signup.expedited.formation.deny.subtitle")
                    : t("signup.expedited.ein.deny.subtitle")) ?? ""
                }
                optionValue={"Included"}
                selectedValue={field.value}
                sideBySideTitle={!isMobile}
                changeHandler={() => {
                  setValue("isAddonSelected", false);
                  onPackageChange("Included");
                }}
              />
            </>
          )}
        />
        <ButtonBox
          onClickBack={onBackHandler}
          onClickNext={() => onSubmitHandler}
          isLoading={isLoading}
          disabled={isDisabled}
        />
      </Stack>
    </form>
  );
};

export default Expedited;
