import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import GlobalStyles from "@mui/material/GlobalStyles";
import { Tracker } from "services/tracking";
import { TrackerGA4 } from "services/GA4tracking";
import * as Sentry from "@sentry/react";
import { usePostHog } from "posthog-js/react";
import config, { isProduction } from "config";
import { ToastContainer } from "toast";
import "react-toastify/dist/ReactToastify.min.css";
import { useIntercom } from "react-use-intercom";
import ThemeCustomization from "themes";
import Routes from "routes";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "components/ErrorPage";
import useCustomer from "graphql/hooks/useCustomer";
import { Customer } from "graphql/__generated__/types";
import { getTokens } from "utils/auth";
import decodeJWT from "jwt-decode";

const tracker = Tracker.getInstance();
const GA4Tracker = TrackerGA4.getInstance();

export default function App() {
  const location = useLocation();
  const { update } = useIntercom();
  const posthog = usePostHog();

  const { currentUser } = useCustomer();

  useEffect(() => {
    Sentry.init({
      dsn: config.sentryDns,
      integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Integrations.Breadcrumbs({ console: isProduction }),
      ],
      tracesSampleRate: 0.4,
    });

    // checks GA4 cookie every second. Clear interval if value is loaded
    const intervalId = setInterval(() => {
      const gaClientId = GA4Tracker.getGaClientId();

      if (gaClientId !== undefined) {
        clearInterval(intervalId);

        posthog?.capture("ga4-client-id-set", {
          $set_once: { ga4_client_id: gaClientId },
        });
      }
    }, 500);
  }, [posthog]);

  useEffect(() => {
    tracker.trackPageView();
  }, [location]);

  useEffect(() => {
    if (currentUser?.email) {
      tracker.trackProfitWell(currentUser.email);
      Sentry.setUser({
        email: currentUser.email,
      });
    }
  }, [location, currentUser]);

  useEffect(() => {
    if (currentUser?.email) {
      posthog?.identify(currentUser?.id || "", {
        email: currentUser?.email,
        full_name: currentUser?.name,
      });

      const updateIntercom = async () => {
        const tokens = getTokens();
        const decodedIDToken: Record<string, string> = decodeJWT(
          tokens.idToken
        );
        const intercomHMAC = decodedIDToken["intercom:hmac"];
        updateIntercomWithProps(currentUser, intercomHMAC);
      };

      updateIntercom().catch(console.error);
    }
  }, [currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateIntercomWithProps = (user: Customer, intercomHMAC: string) => {
    update({
      userId: user.id || "",
      name: user.name || "",
      email: user.email || "",
      userHash: intercomHMAC,
    });
  };

  return (
    <ThemeCustomization>
      <GlobalStyles
        styles={{
          ul: {
            margin: 0,
            padding: 0,
            listStyle: "none",
          },
        }}
      />
      <ToastContainer />
      <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
        <Routes />
      </ErrorBoundary>
    </ThemeCustomization>
  );
}
