import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAccountsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['String'];
}>;


export type GetAccountsQuery = { __typename?: 'Query', accounts?: { __typename?: 'MoneyAccountResponse', error?: string | null, payload?: Array<{ __typename?: 'MoneyAccount', accountName?: string | null, accountType?: string | null, accountCreateDate?: string | null, bankAddress?: string | null, bankName?: string | null, currencyCode?: string | null, doolaCompanyId?: string | null, doolaCustomerId?: string | null, fullBankName?: string | null, routingCodeType1?: string | null, routingCodeType2?: string | null, routingCodeValue1?: string | null, routingCodeValue2?: string | null, status?: Types.AccountStatusType | null, uniquePaymentId?: string | null, balances?: Array<{ __typename?: 'MoneyAccountBalances', id?: string | null, balance?: number | null, curSymbol?: string | null, withHoldingBalance?: number | null } | null> | null } | null> | null } | null };


export const GetAccountsDocument = gql`
    query GetAccounts($companyId: String!) {
  accounts(doolaCompanyId: $companyId) {
    payload {
      accountName
      accountType
      accountCreateDate
      bankAddress
      bankName
      currencyCode
      doolaCompanyId
      doolaCustomerId
      fullBankName
      routingCodeType1
      routingCodeType2
      routingCodeValue1
      routingCodeValue2
      status
      uniquePaymentId
      balances {
        id
        balance
        curSymbol
        withHoldingBalance
      }
    }
    error
  }
}
    `;

/**
 * __useGetAccountsQuery__
 *
 * To run a query within a React component, call `useGetAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetAccountsQuery(baseOptions: Apollo.QueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, options);
      }
export function useGetAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, options);
        }
export type GetAccountsQueryHookResult = ReturnType<typeof useGetAccountsQuery>;
export type GetAccountsLazyQueryHookResult = ReturnType<typeof useGetAccountsLazyQuery>;
export type GetAccountsQueryResult = Apollo.QueryResult<GetAccountsQuery, GetAccountsQueryVariables>;