import * as Types from '../types';

import { gql } from '@apollo/client';
import { CompanyTaxInfoFragmentDoc, CompanyTaxMembersFragmentDoc, PandaDocSignedDocsInfoFragmentDoc } from './Company.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InitiateTaxMemberMutationVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
}>;


export type InitiateTaxMemberMutation = { __typename?: 'Mutation', company?: { __typename?: 'CompanyMutation', initiateTaxMember?: { __typename?: 'Company', id: string, companyTaxInfo?: { __typename?: 'TaxInfo', companyTaxInfo?: { __typename?: 'CompanyTaxInfo', assets?: string | null, businessDescription?: string | null, ein?: string | null, email?: string | null, grossRevenue?: string | null, taxFilingStatus?: string | null } | null, members?: Array<{ __typename?: 'TaxMemberInfo', dob?: string | null, entityType?: Types.EntityType | null, ein?: string | null, contactFullName?: string | null, isNaturalPerson?: boolean | null, legalFirstName?: string | null, legalLastName?: string | null, ownershipPercent?: number | null, position?: number | null, ssn?: string | null, address?: { __typename?: 'TaxAddress', line1?: string | null, line2?: string | null, state?: string | null, city?: string | null, phone?: string | null, postalCode?: string | null, country?: string | null } | null } | null> | null } | null } | null } | null };


export const InitiateTaxMemberDocument = gql`
    mutation InitiateTaxMember($companyId: ID!) {
  company(id: $companyId) {
    initiateTaxMember {
      id
      companyTaxInfo {
        ...CompanyTaxInfo
        ...CompanyTaxMembers
      }
    }
  }
}
    ${CompanyTaxInfoFragmentDoc}
${CompanyTaxMembersFragmentDoc}`;
export type InitiateTaxMemberMutationFn = Apollo.MutationFunction<InitiateTaxMemberMutation, InitiateTaxMemberMutationVariables>;

/**
 * __useInitiateTaxMemberMutation__
 *
 * To run a mutation, you first call `useInitiateTaxMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateTaxMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateTaxMemberMutation, { data, loading, error }] = useInitiateTaxMemberMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useInitiateTaxMemberMutation(baseOptions?: Apollo.MutationHookOptions<InitiateTaxMemberMutation, InitiateTaxMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitiateTaxMemberMutation, InitiateTaxMemberMutationVariables>(InitiateTaxMemberDocument, options);
      }
export type InitiateTaxMemberMutationHookResult = ReturnType<typeof useInitiateTaxMemberMutation>;
export type InitiateTaxMemberMutationResult = Apollo.MutationResult<InitiateTaxMemberMutation>;
export type InitiateTaxMemberMutationOptions = Apollo.BaseMutationOptions<InitiateTaxMemberMutation, InitiateTaxMemberMutationVariables>;