import { CcorpExecutiveInfo, ExecutiveName } from "graphql/__generated__/types";

export const initialExecutiveValues: ExecutiveName = {
  firstName: "",
  lastName: "",
};

export const INITIAL_CCORP_EXECUTIVE_VALUES: CcorpExecutiveInfo = {
  president: initialExecutiveValues,
  secretary: initialExecutiveValues,
  treasurer: initialExecutiveValues,
  directors: [initialExecutiveValues],
};
