import { Box, Drawer, useMediaQuery, useTheme } from "@mui/material";
import { LoadingComponent } from "components/Loading";
import utils from "utils";
import MiniDrawerStyled from "./MiniDrawerStyled";
import { useMemo } from "react";
import { sidebarWidth } from "config";
import SidebarHeader from "./SidebarHeader";
import SidebarContent from "./SidebarContent";
import useCompany from "graphql/hooks/UseCompany";

interface Props {
  open: boolean;
  window?: () => Window;
  handleDrawerToggle?: () => void;
}

export const SidebarWebApp = ({ open, handleDrawerToggle, window }: Props) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));

  const { currentCompany } = useCompany();

  const isDarkMode = utils.isRouteMatchingWithKey("books");
  const container =
    window !== undefined ? () => window().document.body : undefined;

  // header content
  const sidebarHeader = useMemo(
    () => <SidebarHeader isDarkMode={isDarkMode} />,
    [isDarkMode]
  );
  const sidebarContent = useMemo(() => <SidebarContent />, []);

  if (!currentCompany) return <LoadingComponent />;

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, zIndex: 1200 }}>
      {!matchDownMD ? (
        <MiniDrawerStyled variant="permanent" open={true}>
          {sidebarHeader}
          {sidebarContent}
        </MiniDrawerStyled>
      ) : (
        <Drawer
          container={container}
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: sidebarWidth,
              borderRight: `1px solid ${theme.palette.divider}`,
              backgroundImage: "none",
              boxShadow: "inherit",
              background: isDarkMode ? "#1e1e1e" : "#fff",
            },
          }}
        >
          {sidebarHeader}
          {sidebarContent}
        </Drawer>
      )}
    </Box>
  );
};
