import ButtonBox from "components/ButtonBox";
import { IStepProps } from "../../types";
import { TaxFilingStepsCategories, TaxFilingStepsData } from "../../data";
import { Box, Skeleton, Stack, Typography, useMediaQuery } from "@mui/material";
import utils, { colors } from "utils";
import {
  CorporateFareOutlined,
  PersonOutlineRounded,
  CalendarMonthOutlined,
  FileUploadOutlined,
} from "@mui/icons-material";
import { t } from "i18next";
import dayjs from "dayjs";
import { useInitiateTaxMemberMutation } from "graphql/__generated__/operations/InitiateTaxMember.generated";
import { useEffect, useState } from "react";
import useCompany from "graphql/hooks/UseCompany";
import AlertNotification from "components/AlertNotification";

const renderLoadingSkeleton = () => (
  <Skeleton
    width={"100%"}
    height={100}
    sx={{ borderRadius: 6, bgcolor: colors.lightGrey }}
    variant="rectangular"
    animation="wave"
  />
);

interface IPrepareInfoProps extends IStepProps {
  isInHouseCustomer: boolean;
  onCloseModalHandler: () => void;
}

const InfoBlock = ({ children }: { children: React.ReactNode }) => {
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  return (
    <Stack
      direction={isTablet ? "column" : "row"}
      alignItems={"flex-start"}
      gap={1.5}
      sx={{
        bgcolor: colors.lightGrey,
        borderRadius: 6,
        p: 3,
      }}
    >
      {children}
    </Stack>
  );
};

const PrepareInfo = ({
  isInHouseCustomer,
  setCurrentStep,
  onCloseModalHandler,
}: IPrepareInfoProps) => {
  const { currentCompany, isCompanyLoading } = useCompany();
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const [initialScreenLoading, setInitialScreenLoading] =
    useState<boolean>(false);

  const [initiateTaxMember] = useInitiateTaxMemberMutation();

  useEffect(() => {
    const prepareInitialTaxMembersData = async () => {
      if (
        !isCompanyLoading &&
        currentCompany?.id &&
        !currentCompany?.companyTaxInfo?.members?.length
      ) {
        setInitialScreenLoading(true);

        try {
          await initiateTaxMember({
            variables: { companyId: currentCompany?.id },
          });

          setInitialScreenLoading(false);
        } catch {}
      }
    };

    prepareInitialTaxMembersData();
  }, [
    currentCompany?.companyTaxInfo?.members?.length,
    currentCompany?.id,
    initiateTaxMember,
    isCompanyLoading,
  ]);

  if (!currentCompany || isCompanyLoading || initialScreenLoading)
    return (
      <Stack my={3} gap={2}>
        {renderLoadingSkeleton()}
        {renderLoadingSkeleton()}
        {renderLoadingSkeleton()}
      </Stack>
    );

  return (
    <>
      <Stack gap={2} mt={3} mb={isTablet ? 9 : 3}>
        <AlertNotification
          body={t("dashboard.tax.taxFilingModal.step.prepareInfo.disclaimer", {
            value: dayjs().year(),
          })}
        />
        {isInHouseCustomer && (
          <>
            <InfoBlock>
              <PersonOutlineRounded />
              <Box>
                <Typography fontSize={16} fontWeight={500}>
                  {t(
                    "dashboard.tax.taxFilingModal.step.prepareInfo.members.title"
                  )}
                </Typography>
                <Typography color={colors.contentTertiary}>
                  {t(
                    "dashboard.tax.taxFilingModal.step.prepareInfo.members.description"
                  )}
                </Typography>
              </Box>
            </InfoBlock>
            <InfoBlock>
              <CorporateFareOutlined />
              <Box>
                <Typography fontSize={16} fontWeight={500}>
                  {t(
                    "dashboard.tax.taxFilingModal.step.prepareInfo.companyInformation.title"
                  )}
                </Typography>
                <Typography color={colors.contentTertiary}>
                  {t(
                    "dashboard.tax.taxFilingModal.step.prepareInfo.companyInformation.description"
                  )}
                </Typography>
              </Box>
            </InfoBlock>
            <InfoBlock>
              <FileUploadOutlined />
              <Box>
                <Typography fontSize={16} fontWeight={500}>
                  {t(
                    "dashboard.tax.taxFilingModal.step.prepareInfo.uploadDocuments.title"
                  )}
                </Typography>
                <Typography color={colors.contentTertiary}>
                  {t(
                    "dashboard.tax.taxFilingModal.step.prepareInfo.uploadDocuments.description"
                  )}
                </Typography>
              </Box>
            </InfoBlock>
          </>
        )}
        <InfoBlock>
          <CalendarMonthOutlined />
          <Box>
            <Typography fontSize={16} fontWeight={500}>
              {t(
                "dashboard.tax.taxFilingModal.step.prepareInfo.bookConsultation.title"
              )}
            </Typography>
            <Typography color={colors.contentTertiary}>
              {t(
                "dashboard.tax.taxFilingModal.step.prepareInfo.bookConsultation.description"
              )}
            </Typography>
          </Box>
        </InfoBlock>
        {/* <InfoBlock>
          <FingerprintRounded />
          <Box>
            <Typography fontSize={16} fontWeight={500}>
              {t(
                "dashboard.tax.taxFilingModal.step.prepareInfo.signDocuments.title"
              )}
            </Typography>
            <Typography color={colors.contentTertiary}>
              {t(
                "dashboard.tax.taxFilingModal.step.prepareInfo.signDocuments.description"
              )}
            </Typography>
          </Box>
        </InfoBlock> */}
      </Stack>
      <ButtonBox
        rightButtonText="Get started"
        leftIcon="close"
        onClickBack={onCloseModalHandler}
        onClickNext={() =>
          setCurrentStep(
            TaxFilingStepsData[
              isInHouseCustomer
                ? TaxFilingStepsCategories.companyMembers
                : TaxFilingStepsCategories.bookConsultation
            ]
          )
        }
      />
    </>
  );
};

export default PrepareInfo;
