import { useEffect } from "react";
import { convertReferral } from "utils/Referral";
import useSignupFlow from "graphql/hooks/UseSignupFlow";
import { useGetStripeSessionDetailsLazyQuery } from "graphql/__generated__/operations/StripeSessionDetails.generated";
import getCookieValue from "utils/getCookieValue";
import {
  ProductDuration,
  StripeSessionStatus,
} from "graphql/__generated__/types";
import { useAppSelector } from "state/hooks";
import {
  IAddOnPackage,
  selectBoiFilingFormationPackage,
  selectExpeditedFormationPackage,
  selectFormationPackage,
} from "state/formationFormSlice";
import { Tracker } from "services/tracking";
import config from "config";
import { toast } from "toast";
import * as Sentry from "@sentry/react";
import getHashed256Value from "utils/getHashed256Value";
import { useTranslation } from "react-i18next";
import useGetStateFeesProducts from "hooks/useGetStateFees";
import { usePostHog } from "posthog-js/react";
import { generatePartnerStackProductKeys } from "./utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoadingComponent } from "components/Loading";
import { Stack } from "@mui/material";
import { ROUTER_PATHS } from "routes/routes";
import { TOTAL_COMPLIANCE } from "../Packages/data";

interface IFilteredPurchasedAddon {
  unitPrice: number;
  itemId: string;
  quantity: string;
}

const tracker = Tracker.getInstance();

const PARTNERSTACK_TRANSACTION_API =
  "https://api.partnerstack.com/api/v2/transactions";
const basicPartnerstackAuth = (window as any).btoa(
  `${config.partnerstack.public_key}:${config.partnerstack.secret_key}`
);

const Success = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const posthog = usePostHog();

  const [searchParams] = useSearchParams();

  const {
    email,
    hasDataLoaded,
    entityType,
    entityState,
    customerFullName,
    companyId,
    customerId,
    stripeCustomerId,
  } = useSignupFlow();
  const [fetchSessionDetails] = useGetStripeSessionDetailsLazyQuery();

  const purchasedFormationProduct = useAppSelector(selectFormationPackage);
  const selectedExpeditedFormationPackage = useAppSelector(
    selectExpeditedFormationPackage
  );
  const selectedBoiFilingFormationPackage = useAppSelector(
    selectBoiFilingFormationPackage
  );
  const partnerStackProductKeys = generatePartnerStackProductKeys(
    purchasedFormationProduct,
    selectedExpeditedFormationPackage,
    selectedBoiFilingFormationPackage
  );

  const { filteredFeesProducts, isProductQueryLoading } =
    useGetStateFeesProducts();

  useEffect(() => {
    convertReferral(email!);
  }, [email]);

  useEffect(() => {
    const sessionId = searchParams.get("session");

    if (!sessionId || !hasDataLoaded || isProductQueryLoading) return;

    const fetchStripeSessionDetails = async () => {
      try {
        const stateProduct = filteredFeesProducts?.find((item) =>
          item?.key.startsWith(entityState as string)
        );

        const stateFee = (stateProduct?.usdPriceCents ?? 0) / 100;

        const { data } = await fetchSessionDetails({
          variables: { sessionId },
        });

        const stripeCheckoutId = data?.stripeSessionDetails?.id || "";
        const status = data?.stripeSessionDetails?.status;
        const paymentIntentId = data?.stripeSessionDetails?.paymentIntentId;

        if (status === StripeSessionStatus.COMPLETE) {
          const ps_xid = getCookieValue("ps_xid");
          const cje = getCookieValue("cje");
          const totalAmount =
            (data?.stripeSessionDetails?.amountTotal || 0) / 100;
          const totalWithoutFees = totalAmount - stateFee;
          const purchasedAddons = [
            selectedExpeditedFormationPackage,
            selectedBoiFilingFormationPackage,
          ].filter(Boolean) as IAddOnPackage[];

          const formatPurchasedAddonsItems: IFilteredPurchasedAddon[] | [] = [
            selectedExpeditedFormationPackage && {
              unitPrice: selectedExpeditedFormationPackage.price,
              itemId: selectedExpeditedFormationPackage.stripePriceId,
              quantity: "1",
            },
            selectedBoiFilingFormationPackage && {
              unitPrice: selectedBoiFilingFormationPackage.price,
              itemId: selectedBoiFilingFormationPackage.stripePriceId,
              quantity: "1",
            },
          ].filter(Boolean) as IFilteredPurchasedAddon[];

          const productName =
            purchasedFormationProduct?.displayName === TOTAL_COMPLIANCE.name
              ? `${purchasedFormationProduct?.displayName} ${purchasedFormationProduct.duration}`
              : purchasedFormationProduct?.displayName;

          tracker.trackCompleteTransaction({
            companyId,
            customerId,
            email,
            entityType: entityType!,
            entityState: entityState!,
            totalAmount,
            totalWithoutFees,
            paymentIntentId: paymentIntentId!,
            stripeCustomerId,
            productName: productName ?? "",
            productPrice: purchasedFormationProduct!.price ?? 0,
            productStripePriceId:
              purchasedFormationProduct!.stripePriceId ?? "",
            isMonthly:
              purchasedFormationProduct!.duration === ProductDuration.MONTHLY,
            purchasedAddons,
            stateProduct,
          });

          // PartnerStack
          if (ps_xid) {
            fetch(`https://partnerlinks.io/conversion/xid`, {
              method: "POST",
              headers: new Headers({
                Authorization: `Bearer ${config.partnerstack.token}`,
                "content-type": "application/json",
              }),
              body: JSON.stringify({
                xid: ps_xid,
                customer_key: customerId,
                email: email,
                name: customerFullName,
              }),
            }).catch((error: any) => {
              Sentry.captureException(error);
            });

            fetch(PARTNERSTACK_TRANSACTION_API, {
              method: "POST",
              headers: new Headers({
                Authorization: `Basic ${basicPartnerstackAuth}`,
                accept: "application/json",
                "content-type": "application/json",
              }),
              body: JSON.stringify({
                customer_external_key: customerId,
                amount: totalWithoutFees * 100,
                currency: "USD",
                key: stripeCheckoutId,
                product_key: partnerStackProductKeys,
              }),
            }).catch((error: any) => {
              Sentry.captureException(error);
            });
          }

          // CJ
          if (cje) {
            getHashed256Value(email || "").then((hashedEmail) => {
              tracker.conversionConfirmationCJ({
                userId: customerId,
                emailHash: hashedEmail,
                orderId: stripeCheckoutId,
                amount: totalWithoutFees,
                cje: cje,
                items: [
                  {
                    unitPrice: purchasedFormationProduct?.price || 0,
                    itemId: purchasedFormationProduct?.stripePriceId || "",
                    quantity: "1",
                  },
                  ...formatPurchasedAddonsItems,
                ],
              });
            });
          }

          posthog?.capture("checkout-completed", {
            product_purchased: purchasedFormationProduct!.stripePriceId,
            additional_product_purchased: purchasedAddons,
            purchase_value: totalAmount,
          });

          posthog?.capture("onboarding-start", {
            entity_type: entityType,
            first_time: true,
          });

          navigate(ROUTER_PATHS.ONBOARDING_INTRO);
        } else {
          toast.error(t("signup.formation.stripePaymentCancelled") as string);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchStripeSessionDetails();
  }, [hasDataLoaded, isProductQueryLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Stack height={200} alignItems={"center"} justifyContent={"center"}>
      <LoadingComponent text="We're checking the information about your payment. You'll be redirected shortly..." />
    </Stack>
  );
};

export default Success;
