import { setContext } from "@apollo/client/link/context";
import { getTokens } from "utils/auth";

const AuthLink = setContext((_, context) => {
  const { headers, isPublicMutation = false } = context;
  const { idToken } = getTokens();

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${isPublicMutation ? "" : idToken}`,
    },
  };
});

export default AuthLink;
