import { CustomCard } from "components/Cards";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import utils from "utils";
import { NavigateNext } from "@mui/icons-material";
import { t } from "i18next";
import Button from "components/Button";
import { Link } from "react-router-dom";

export interface IBannerProps {
  bgColor: string;
  imageSrc: string;
  title: string;
  text: string | React.ReactNode;
  buttonText: string;
  onClick: () => void;
  learnMoreHref?: string;
}

export const Banner: React.FC<IBannerProps> = ({
  bgColor,
  imageSrc,
  title,
  text,
  buttonText,
  onClick,
  learnMoreHref,
}) => {
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  return (
    <CustomCard
      $styles={{
        position: "relative",
        background: bgColor,
      }}
      $shadow="xstrong"
      $radius="strong"
      $padding={4}
    >
      <Stack direction={"row"} gap={4} alignItems={"center"}>
        <Box>
          <Stack mb={4} gap={2}>
            <Typography variant="h3" fontWeight={700}>
              {title}
            </Typography>
            <Typography fontSize={16}>{text}</Typography>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            gap={1}
          >
            <Button
              endIcon={<NavigateNext />}
              buttonText={buttonText}
              onClick={onClick}
            />
            {learnMoreHref && (
              <Link
                to={learnMoreHref}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button transparent buttonText={t("general.learnMore")} />
              </Link>
            )}
          </Stack>
        </Box>
        {!isTablet && <img src={imageSrc} alt={title} />}
      </Stack>
    </CustomCard>
  );
};
