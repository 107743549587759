import {
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ButtonBox from "components/ButtonBox";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FormationScreenNames } from "../services";
import { ROUTER_PATHS } from "routes/routes";
import { useEffect, useState } from "react";
import utils, { colors } from "utils";
import { PackageValue, TOTAL_COMPLIANCE, STARTER } from "./data";
import FormControlElement from "../components/FormControlElement";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { ProductDuration } from "graphql/__generated__/types";
import { PackageCard } from "./PackageCard";
import { ComparisonTable } from "./ComparisonTable";
import { useAppDispatch, useAppSelector } from "state/hooks";
import {
  selectFormationPackage,
  setBoiFilingFormationPackage,
  setExpeditedFormationPackage,
  setFormationPackage,
} from "state/formationFormSlice";
import { LoadingComponent } from "components/Loading";
import useGetMainProducts from "./hooks/useGetMainProducts";
import {
  CloseRounded,
  InfoOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { usePostHog } from "posthog-js/react";

export interface IPackage {
  displayName: string;
  name: PackageValue;
  lookupKey: string;
  stripePriceId?: string;
  price?: number;
  duration: ProductDuration;
}

const Packages = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const posthog = usePostHog();

  const { PLANS, defaultPlan, isProductQueryLoading } = useGetMainProducts();

  const selectedFormationPackage = useAppSelector(selectFormationPackage);

  const methods = useForm<IPackage>({
    defaultValues: {
      name: selectedFormationPackage?.name,
      displayName: selectedFormationPackage?.displayName,
      stripePriceId: selectedFormationPackage?.stripePriceId,
      price: selectedFormationPackage?.price,
      duration: selectedFormationPackage?.duration,
      lookupKey: selectedFormationPackage?.lookupKey,
    },
  });
  const { control, formState, handleSubmit, setValue, trigger } = methods;
  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  const [currentDuration, setCurrentDuration] = useState<ProductDuration>(
    ProductDuration.MONTHLY
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);

  useEffect(() => {
    posthog?.capture("packages-viewed");
  }, [posthog]);

  useEffect(() => {
    if (selectedFormationPackage) {
      setCurrentDuration(selectedFormationPackage.duration);
    }
  }, [selectedFormationPackage]);

  useEffect(() => {
    if (defaultPlan && !selectedFormationPackage) {
      setValue("name", PLANS[TOTAL_COMPLIANCE.value].value);
      setValue("displayName", PLANS[TOTAL_COMPLIANCE.value].displayName);
      setValue("stripePriceId", defaultPlan.stripePricingId);
      setValue("price", defaultPlan.usdPrice);
      setValue("lookupKey", defaultPlan.key ?? "");
      setValue("duration", defaultPlan.duration ?? ProductDuration.MONTHLY);

      trigger();

      setCurrentDuration(defaultPlan.duration ?? ProductDuration.MONTHLY);
    }
  }, [isProductQueryLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isProductQueryLoading) {
    return <LoadingComponent />;
  }

  const onPackageChange = (
    newValue: PackageValue,
    duration: ProductDuration
  ) => {
    const newSelectedPackage = PLANS[newValue];
    const newSelectedPackageDuration =
      newSelectedPackage.duration[duration] ||
      newSelectedPackage.duration[ProductDuration.ANNUAL];

    const packageDuration = newSelectedPackage.duration[duration]
      ? duration
      : ProductDuration.ANNUAL;

    setValue("name", newSelectedPackage.value);
    setValue("displayName", newSelectedPackage.displayName);
    setValue("stripePriceId", newSelectedPackageDuration!.stripePriceId);
    setValue("price", newSelectedPackageDuration!.price);
    setValue("lookupKey", newSelectedPackageDuration!.lookupKey);
    setValue("duration", packageDuration);

    setCurrentDuration(packageDuration);
  };

  const onSubmitHandler = (data: IPackage) => {
    setIsLoading(true);

    const { name, displayName, stripePriceId, price, duration, lookupKey } =
      data;

    dispatch(
      setFormationPackage({
        name,
        displayName,
        stripePriceId,
        price,
        duration,
        lookupKey,
      })
    );

    posthog?.capture("product-selected", {
      product_name: name,
      product_id: stripePriceId,
      product_value: price,
      product_term: duration,
    });

    if (name === STARTER.value) {
      navigate(ROUTER_PATHS.PRE_PURCHASE_EXPEDITED);
    } else {
      dispatch(setExpeditedFormationPackage());
      dispatch(setBoiFilingFormationPackage());
      navigate(ROUTER_PATHS.PRE_PURCHASE_REVIEW);
    }
  };

  const onBackHandler = () => {
    posthog?.capture("funnel-back", {
      screen: FormationScreenNames.Packages,
    });

    navigate(ROUTER_PATHS.PRE_PURCHASE_TC);
  };

  const onShowModalHandler = () => {
    setIsModalOpen(true);

    posthog?.capture("compare_products_modal_open");
  };

  const onCloseModalHandler = () => {
    setIsModalOpen(false);

    posthog?.capture("compare_products_modal_close");
  };

  const isDisabled = !formState.isValid;

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
        <Stack direction="column" gap={3} mb={isTablet ? 15 : 3}>
          <Typography variant={isTablet ? "h3" : "h2"}>
            {t("signup.packages.title")}
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: isTablet
                ? "1fr"
                : "repeat(2, minmax(0, 1fr))",
              gridAutoRows: isTablet ? "none" : "1fr",
              gap: 3,
            }}
          >
            <Controller
              name={"name"}
              {...COMMON_PROPS}
              rules={{
                required: true,
              }}
              render={({ field: { ref, ...field } }) => (
                <>
                  {Object.values(PLANS).map((item, index) => (
                    <FormControlElement
                      {...field}
                      key={index}
                      title={item.name}
                      subtitle={""}
                      optionValue={item.value}
                      selectedValue={field.value}
                      changeHandler={() =>
                        onPackageChange(item.value, currentDuration)
                      }
                      chipText={
                        item.value === TOTAL_COMPLIANCE.value
                          ? (t("general.recommended") as string)
                          : ""
                      }
                      content={
                        <PackageCard
                          plan={item}
                          isSelected={item.value === field.value}
                          onPackageChange={onPackageChange}
                        />
                      }
                    />
                  ))}
                </>
              )}
            />
          </Box>
          <Stack
            gap={1}
            direction={isMobile ? "column" : "row"}
            justifyContent={isMobile ? "flex-start" : "space-between"}
          >
            <Stack
              maxWidth={isTablet ? "400px" : "500px"}
              direction="row"
              alignItems={"center"}
              gap={2}
              color={colors.contentTertiary}
            >
              <InfoOutlined />
              <Typography>{t("signup.companyName.packages")}</Typography>
            </Stack>
            <Button
              disableElevation
              onClick={onShowModalHandler}
              variant="contained"
              endIcon={<VisibilityOutlined />}
              sx={{
                alignSelf: "flex-start",
                borderRadius: "30px",
                bgcolor: colors.grey,
                color: colors.black,
                "&:hover": {
                  bgcolor: colors.darkGrey,
                },
                py: 1.5,
                px: 3,
              }}
            >
              See details
            </Button>
          </Stack>
          <Dialog
            fullScreen={isTablet}
            PaperProps={{
              sx: {
                borderRadius: !isTablet ? 8 : "0",
                width: isTablet ? "100%" : "700px",
                p: isTablet ? 2 : 4,
                pb: 5,
              },
            }}
            open={isModalOpen}
            onClose={onCloseModalHandler}
          >
            <Stack gap={1}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant="h3">Compare our packages</Typography>
                <IconButton onClick={onCloseModalHandler}>
                  <CloseRounded color="primary" />
                </IconButton>
              </Stack>
              <ComparisonTable />
            </Stack>
          </Dialog>
          <ButtonBox
            onClickBack={onBackHandler}
            onClickNext={() => onSubmitHandler}
            disabled={isDisabled}
            isLoading={isLoading}
          />
        </Stack>
      </form>
    </FormProvider>
  );
};

export default Packages;
