import ButtonBox from "components/ButtonBox";
import { IStepProps } from "../../types";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import utils, { colors } from "utils";
import {
  CorporateFareOutlined,
  PersonOutlineRounded,
  FingerprintRounded,
  PeopleOutlined,
} from "@mui/icons-material";
import { t } from "i18next";
import { OnboardingStepsCategories, OnboardingStepsData } from "../../data";
import { usePostHog } from "posthog-js/react";

const InfoBlock = ({ children }: { children: React.ReactNode }) => {
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  return (
    <Stack
      direction={isTablet ? "column" : "row"}
      alignItems={"flex-start"}
      gap={1.5}
      sx={{
        bgcolor: colors.lightGrey,
        borderRadius: 6,
        p: 3,
      }}
    >
      {children}
    </Stack>
  );
};

const PrepareInfo = ({ setCurrentStep }: IStepProps) => {
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const posthog = usePostHog();

  return (
    <>
      <Stack gap={2} mt={4} mb={isTablet ? 2 : 5}>
        <InfoBlock>
          <CorporateFareOutlined />
          <Box>
            <Typography fontSize={16} fontWeight={500}>
              {t("money.onboarding.step.prepareInfo.companyInformation.title")}
            </Typography>
            <Typography color={colors.contentTertiary}>
              {t(
                "money.onboarding.step.prepareInfo.companyInformation.description"
              )}
            </Typography>
          </Box>
        </InfoBlock>
        <InfoBlock>
          <PeopleOutlined />
          <Box>
            <Typography fontSize={16} fontWeight={500}>
              {t("money.onboarding.step.prepareInfo.membersInformation.title")}
            </Typography>
            <Typography color={colors.contentTertiary}>
              {t(
                "money.onboarding.step.prepareInfo.membersInformation.description"
              )}
            </Typography>
          </Box>
        </InfoBlock>
        <InfoBlock>
          <PersonOutlineRounded />
          <Box>
            <Typography fontSize={16} fontWeight={500}>
              {t(
                "money.onboarding.step.prepareInfo.applicantInformation.title"
              )}
            </Typography>
            <Typography color={colors.contentTertiary}>
              {t(
                "money.onboarding.step.prepareInfo.applicantInformation.description"
              )}
            </Typography>
          </Box>
        </InfoBlock>
        <InfoBlock>
          <FingerprintRounded />
          <Box>
            <Typography fontSize={16} fontWeight={500}>
              {t(
                "money.onboarding.step.prepareInfo.riskAssessmentInformation.title"
              )}
            </Typography>
            <Typography color={colors.contentTertiary}>
              {t(
                "money.onboarding.step.prepareInfo.riskAssessmentInformation.description"
              )}
            </Typography>
          </Box>
        </InfoBlock>
      </Stack>
      <ButtonBox
        rightButtonText="Get started"
        showBackButton={false}
        onClickNext={() => {
          posthog?.capture("money_onboarding_get_started_btn_click");

          setCurrentStep(
            OnboardingStepsData[OnboardingStepsCategories.companyInformation]
          );
        }}
      />
    </>
  );
};

export default PrepareInfo;
