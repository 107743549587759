import ButtonBox from "components/ButtonBox";
import { IStepProps } from "../../types";
import { TaxFilingStepsCategories, TaxFilingStepsData } from "../../data";
import useCompany from "graphql/hooks/UseCompany";
import {
  Box,
  Button,
  Grid,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import utils, { colors } from "utils";
import {
  AddRounded,
  PersonOutlineRounded,
  CheckCircleOutlineRounded,
  ErrorOutlineOutlined,
  CloseOutlined,
  CorporateFareOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import CustomLoadingButton from "components/Button";
import { t } from "i18next";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { TaxMemberInfo } from "graphql/__generated__/types";
import MemberInformation from "./MemberInformation";
import { INITIAL_MEMBER_VALUES } from "./helpers/initialMembersData";
import { useEditTaxMemberInfoMutation } from "graphql/__generated__/operations/EditTaxMemberInfo.generated";
import { LoadingButton } from "@mui/lab";
import MemberPercentage from "./MemberPercentage";
import MemberCompanyInformation from "./MemberCompanyInformation";
import MemberType from "./MemberType";
import { MemberTypes } from "./types";
import { format as formatDate } from "fecha";
import { usePostHog } from "posthog-js/react";
import { isValidDateString } from "utils/helpers";

const renderLoadingSkeleton = () => (
  <Grid item xs={12} sm={6} md={4}>
    <Skeleton
      width={"100%"}
      height={200}
      sx={{ borderRadius: 6, bgcolor: colors.lightGrey }}
      variant="rectangular"
      animation="wave"
    />
  </Grid>
);

interface IMembersProps extends IStepProps {
  setShowHeading: Dispatch<SetStateAction<boolean>>;
}

const Members = ({ setShowHeading, setCurrentStep }: IMembersProps) => {
  const posthog = usePostHog();
  const { currentCompany } = useCompany();
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const [member, setMember] = useState<TaxMemberInfo | undefined>();
  const [memberType, setMemberType] = useState<MemberTypes | undefined>();
  const [isPercentageScreen, setIsPercentageScreen] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);

  const [editTaxMemberInfo] = useEditTaxMemberInfoMutation();

  const members = currentCompany?.companyTaxInfo?.members;

  useEffect(() => {
    if (members && members.length > 0) {
      const hasMissingInformation = members.some((member) =>
        member?.isNaturalPerson ? !member.dob : !member?.ein
      );

      setIsValid(!hasMissingInformation);
    }
  }, [members]);

  if (!currentCompany)
    return (
      <Stack my={3} mb={isTablet ? 9 : 3}>
        <Grid container spacing={2}>
          {renderLoadingSkeleton()}
          {renderLoadingSkeleton()}
          {renderLoadingSkeleton()}
          {renderLoadingSkeleton()}
        </Grid>
      </Stack>
    );

  const removeMemberHandler = async (member: TaxMemberInfo) => {
    try {
      setIsLoading(true);

      await editTaxMemberInfo({
        variables: {
          companyId: currentCompany?.id,
          memberInfo: {
            member: {},
            position: member.position!,
          },
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const resetData = () => {
    setMember(undefined);
    setMemberType(undefined);
    setIsPercentageScreen(false);
  };

  const isDisabled = !isValid;

  return (
    <>
      {isPercentageScreen ? (
        <MemberPercentage
          setCurrentStep={setCurrentStep}
          resetData={resetData}
          setShowHeading={setShowHeading}
        />
      ) : member && memberType === MemberTypes.PERSON ? (
        <MemberInformation
          resetData={resetData}
          member={member}
          setShowHeading={setShowHeading}
        />
      ) : member && memberType === MemberTypes.COMPANY ? (
        <MemberCompanyInformation
          resetData={resetData}
          member={member}
          setShowHeading={setShowHeading}
        />
      ) : member && !memberType ? (
        <MemberType
          resetData={resetData}
          setMemberType={setMemberType}
          setShowHeading={setShowHeading}
        />
      ) : (
        <>
          <Stack my={3} mb={isTablet ? 9 : 3}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Stack
                  width={"100%"}
                  minHeight={"200px"}
                  height={"100%"}
                  component={Button}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    bgcolor: colors.lightGrey,
                    borderRadius: 6,
                    p: 3,
                    transition: "all .3s ease-out",
                    "&:hover": {
                      bgcolor: colors.grey,
                    },
                  }}
                  onClick={() => setMember(INITIAL_MEMBER_VALUES)}
                >
                  <Stack borderRadius={"50%"} bgcolor={colors.grey} p={2}>
                    <AddRounded />
                  </Stack>
                </Stack>
              </Grid>
              {members &&
                members.map(
                  (member, index) =>
                    member && (
                      <Grid
                        key={index}
                        width={"100%"}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                      >
                        <Stack
                          height={"100%"}
                          minHeight={"200px"}
                          justifyContent={"space-between"}
                          bgcolor={colors.lightGrey}
                          borderRadius={6}
                          p={3}
                        >
                          <Box>
                            {member.isNaturalPerson ? (
                              <PersonOutlineRounded />
                            ) : (
                              <CorporateFareOutlined />
                            )}
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              gap={0.5}
                            >
                              {(member.contactFullName ||
                                member.legalFirstName ||
                                member.legalLastName) && (
                                <Typography fontSize={16} fontWeight={500}>
                                  {member.contactFullName ||
                                    `${member.legalFirstName} ${member.legalLastName}`}
                                </Typography>
                              )}
                              {/* check for missing member information */}
                              {(
                                member.isNaturalPerson
                                  ? member?.dob
                                  : member?.ein
                              ) ? (
                                <CheckCircleOutlineRounded
                                  sx={{ fontSize: "18px", color: colors.green }}
                                />
                              ) : (
                                <ErrorOutlineOutlined
                                  sx={{
                                    fontSize: "18px",
                                    color: colors.lightContentWarning,
                                  }}
                                />
                              )}
                            </Stack>
                            <Typography color={colors.contentTertiary}>
                              {/* show date of birth or ein or missing information */}
                              {(member.isNaturalPerson
                                ? isValidDateString(member.dob)
                                  ? formatDate(
                                      new Date(member.dob ?? 0),
                                      "MM/DD/YYYY"
                                    )
                                  : t("general.missingInformation")
                                : member.ein && member.entityType) ??
                                t("general.missingInformation")}
                            </Typography>
                          </Box>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            gap={1}
                            mt={3}
                          >
                            <CustomLoadingButton
                              dark={false}
                              endIcon={<VisibilityOutlined />}
                              buttonText={t("general.review")}
                              onClick={() => {
                                setMember(member);
                                setMemberType(
                                  member.isNaturalPerson
                                    ? MemberTypes.PERSON
                                    : MemberTypes.COMPANY
                                );
                              }}
                              styles={{
                                width: "100%",
                                bgcolor: colors.grey,
                              }}
                            />
                            {typeof member.position === "number" && (
                              <LoadingButton
                                onClick={() => removeMemberHandler(member)}
                                sx={{
                                  minWidth: 0,
                                  borderRadius: "50%",
                                  bgcolor: colors.grey,
                                  px: 2,
                                }}
                              >
                                <CloseOutlined
                                  sx={{
                                    fontSize: "18px",
                                    color: colors.red,
                                  }}
                                />
                              </LoadingButton>
                            )}
                          </Stack>
                        </Stack>
                      </Grid>
                    )
                )}
            </Grid>
          </Stack>
          <ButtonBox
            isLoading={isLoading}
            disabled={isDisabled}
            onClickBack={() =>
              setCurrentStep(
                TaxFilingStepsData[TaxFilingStepsCategories.prepareInfo]
              )
            }
            onClickNext={() => {
              setIsPercentageScreen(true);

              posthog?.capture(`tax_modal_members_next_clicked`);
            }}
          />
        </>
      )}
    </>
  );
};

export default Members;
