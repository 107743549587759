import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMoneyBeneficiariesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['String'];
}>;


export type GetMoneyBeneficiariesQuery = { __typename?: 'Query', beneficiaries?: { __typename?: 'BeneficiaryList', error?: string | null, payload?: Array<{ __typename?: 'BeneficiaryResponse', beneficiaryAccountNumber?: string | null, beneficiaryAccountType?: Types.AccountType | null, beneficiaryBankAccountType?: string | null, beneficiaryBankCode?: string | null, beneficiaryBankName?: string | null, beneficiaryCity?: string | null, beneficiaryCountryCode?: string | null, beneficiaryHashId?: string | null, beneficiaryName?: string | null, beneficiaryAddress?: string | null, beneficiaryPostcode?: string | null, beneficiaryState?: string | null, destinationCountry?: string | null, destinationCurrency?: string | null, doolaCompanyId?: string | null, doolaCustomerId?: string | null, payoutMethod?: string | null, routingCodeType1?: string | null, routingCodeValue1?: string | null } | null> | null } | null };


export const GetMoneyBeneficiariesDocument = gql`
    query GetMoneyBeneficiaries($companyId: String!) {
  beneficiaries(doolaCompanyId: $companyId) {
    error
    payload {
      beneficiaryAccountNumber
      beneficiaryAccountType
      beneficiaryBankAccountType
      beneficiaryBankCode
      beneficiaryBankName
      beneficiaryCity
      beneficiaryCountryCode
      beneficiaryHashId
      beneficiaryName
      beneficiaryAddress
      beneficiaryPostcode
      beneficiaryState
      destinationCountry
      destinationCurrency
      doolaCompanyId
      doolaCustomerId
      payoutMethod
      routingCodeType1
      routingCodeValue1
    }
  }
}
    `;

/**
 * __useGetMoneyBeneficiariesQuery__
 *
 * To run a query within a React component, call `useGetMoneyBeneficiariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoneyBeneficiariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoneyBeneficiariesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetMoneyBeneficiariesQuery(baseOptions: Apollo.QueryHookOptions<GetMoneyBeneficiariesQuery, GetMoneyBeneficiariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMoneyBeneficiariesQuery, GetMoneyBeneficiariesQueryVariables>(GetMoneyBeneficiariesDocument, options);
      }
export function useGetMoneyBeneficiariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMoneyBeneficiariesQuery, GetMoneyBeneficiariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMoneyBeneficiariesQuery, GetMoneyBeneficiariesQueryVariables>(GetMoneyBeneficiariesDocument, options);
        }
export type GetMoneyBeneficiariesQueryHookResult = ReturnType<typeof useGetMoneyBeneficiariesQuery>;
export type GetMoneyBeneficiariesLazyQueryHookResult = ReturnType<typeof useGetMoneyBeneficiariesLazyQuery>;
export type GetMoneyBeneficiariesQueryResult = Apollo.QueryResult<GetMoneyBeneficiariesQuery, GetMoneyBeneficiariesQueryVariables>;