import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { t } from "i18next";
import utils, { colors } from "utils";
import { ReactComponent as WalletImage } from "assets/Money/cryptocurrency-wallet-safe.svg";
import ButtonBox, { RightButtonIconTypes } from "components/ButtonBox";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";

interface IFinalStepProps {
  onClose: () => void;
}

const FinalAddBeneficiaryStep = ({ onClose }: IFinalStepProps) => {
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const navigate = useNavigate();

  return (
    <>
      <Stack alignItems={"center"}>
        <WalletImage height={isTablet ? "200px" : "250px"} />
      </Stack>
      <Box>
        <Typography variant="h3" color={colors.black}>
          {t("money.beneficiary.addBeneficiary.final.title")}
        </Typography>
        <Typography color={colors.contentSecondary} mt={2} mb={5}>
          {t("money.beneficiary.addBeneficiary.final.description")}
        </Typography>
        <ButtonBox
          rightButtonText={t("money.transferMoney") ?? ""}
          rightIcon={RightButtonIconTypes.SEND}
          leftIcon="close"
          onClickBack={onClose}
          onClickNext={() => navigate(ROUTER_PATHS.MONEY_TRANSFER)}
        />
      </Box>
    </>
  );
};

export default FinalAddBeneficiaryStep;
