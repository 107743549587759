import { useCallback, useState } from "react";
import DropBox from "./DropBox";
import { Dialog, DialogContent, Theme, useMediaQuery } from "@mui/material";

type Props = {
  handleClose: () => void;
};

const DropzoneContainer = ({ handleClose }: Props) => {
  const matchDownSM = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [files, setFiles] = useState<File[] | null>(null);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (!files) {
        setFiles(acceptedFiles);
      } else {
        const filesMap = new Map(
          [...(files as File[]), ...acceptedFiles].map((f: any) => [f.path, f])
        );

        const uniqueFiles = [...filesMap.values()];
        setFiles(uniqueFiles);
      }
    },
    [files]
  );

  const onRemoveFile = (path: string) => {
    if (files) {
      setFiles((prevFiles) =>
        (prevFiles as File[]).filter((f: any) => f.path !== path)
      );
    }
  };

  const onUpload = () => {
    if (!files || files.length === 0) return;

    console.log(`lets upload these files: ${files}`);

    // const formData = new FormData();

    // files.forEach((file) => {
    //   formData.append("file", file);
    // });

    // axios({
    //   method: "POST",
    //   url: "some rest endpoint from backend",
    //   data: formData,
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   },
    // });
  };

  return (
    <Dialog open onClose={handleClose}>
      <DialogContent sx={{ width: matchDownSM ? "90%" : 600 }}>
        <DropBox
          onDrop={onDrop}
          files={files}
          onRemoveFile={onRemoveFile}
          handleFileUpload={onUpload}
        />
      </DialogContent>
    </Dialog>
  );
};

export default DropzoneContainer;
