import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Stack,
} from "@mui/material";
import { ResponsibleParty } from "graphql/__generated__/types";
import { ExpandMoreRounded } from "@mui/icons-material";

type Props = {
  responsibleParty: ResponsibleParty;
};

const ResponsiblePartyExpandable = ({ responsibleParty }: Props) => {
  return (
    <Accordion
      disableGutters
      onChange={() => {}}
      sx={{
        mb: 1,
        mt: 0,
        background: "none",
        border: "none",
        boxShadow: "none",
      }}
    >
      <AccordionSummary sx={{ padding: 0 }} expandIcon={<ExpandMoreRounded />}>
        <Stack direction="row" spacing={2}>
          <Stack>
            <Stack direction="row" gap={1} alignItems="center">
              <Typography variant="h5">{`${responsibleParty.legalFirstName} ${responsibleParty.legalLastName}`}</Typography>
            </Stack>
            <Typography>
              SSN 🇺🇸: {responsibleParty.ssn ? "Provided" : " N/A"}
            </Typography>
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ mb: 2, pl: 0 }}>
        <Stack direction="column">
          <Typography mb={1 / 2}>{responsibleParty.address.phone}</Typography>
          <Typography>{responsibleParty.address.line1}</Typography>
          {responsibleParty.address.line2 && (
            <Typography>{responsibleParty.address.line2}</Typography>
          )}
          <Typography>{`${responsibleParty.address.city}, ${responsibleParty.address.state}, ${responsibleParty.address.postalCode}`}</Typography>
          <Typography>{responsibleParty.address.country}</Typography>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default ResponsiblePartyExpandable;
