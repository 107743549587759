import * as Types from '../types';

import { gql } from '@apollo/client';
import { UsMailingAddressInfoFragmentDoc } from './Company.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateOtherUsAddressMutationVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  new?: Types.InputMaybe<Types.UsAddressInput>;
}>;


export type UpdateOtherUsAddressMutation = { __typename?: 'Mutation', company?: { __typename?: 'CompanyMutation', updateOtherUsAddress?: { __typename?: 'Company', id: string, services?: Array<{ __typename?: 'Services', service: Types.CompanyServices, variant?: Types.ServiceVariant | null, status: Types.ServiceCategory, subStatus?: string | null }> | null, usMailingAddress?: { __typename?: 'USAddress', line1: string, line2?: string | null, state: string, city: string, postalCode: string, country: Types.Iso31661Alpha3 } | null } | null } | null };


export const UpdateOtherUsAddressDocument = gql`
    mutation UpdateOtherUsAddress($companyId: ID!, $new: USAddressInput) {
  company(id: $companyId) {
    updateOtherUsAddress(new: $new) {
      id
      services {
        service
        variant
        status
        subStatus
      }
      usMailingAddress {
        ...USMailingAddressInfo
      }
    }
  }
}
    ${UsMailingAddressInfoFragmentDoc}`;
export type UpdateOtherUsAddressMutationFn = Apollo.MutationFunction<UpdateOtherUsAddressMutation, UpdateOtherUsAddressMutationVariables>;

/**
 * __useUpdateOtherUsAddressMutation__
 *
 * To run a mutation, you first call `useUpdateOtherUsAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOtherUsAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOtherUsAddressMutation, { data, loading, error }] = useUpdateOtherUsAddressMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      new: // value for 'new'
 *   },
 * });
 */
export function useUpdateOtherUsAddressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOtherUsAddressMutation, UpdateOtherUsAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOtherUsAddressMutation, UpdateOtherUsAddressMutationVariables>(UpdateOtherUsAddressDocument, options);
      }
export type UpdateOtherUsAddressMutationHookResult = ReturnType<typeof useUpdateOtherUsAddressMutation>;
export type UpdateOtherUsAddressMutationResult = Apollo.MutationResult<UpdateOtherUsAddressMutation>;
export type UpdateOtherUsAddressMutationOptions = Apollo.BaseMutationOptions<UpdateOtherUsAddressMutation, UpdateOtherUsAddressMutationVariables>;