import { CustomCard } from "components/Cards";
import { StatusLabel } from "components/StatusLabel";
import { STEPS_DATA } from "./data";
import { StatusLabelWrapper, StepWrapper } from "./styles";
import { SectionType } from "./types";
import { StatussesData, StatussesKey } from "../Services/StatusKey";
import { Typography } from "@mui/material";
import { colors } from "utils";

type Props = {};

export const HowItWork: React.FC<Props> = () => (
  <CustomCard $shadow="strong" $radius="strong">
    <Typography variant="h5" mb={3}>
      How it works
    </Typography>
    {STEPS_DATA.map((sectionData, index) => (
      <Section key={index} {...sectionData} />
    ))}
  </CustomCard>
);

const Section: React.FC<SectionType> = ({
  title,
  description,
  tip,
  referralStatusKey,
}) => {
  const sectionData = StatussesData[referralStatusKey as StatussesKey];
  return (
    <StepWrapper>
      <Typography
        variant="body1"
        fontWeight={500}
        color={colors.contentSecondary}
      >
        {title}
      </Typography>
      <Typography variant="body1" color={colors.contentTertiary}>
        {description}
      </Typography>
      <Typography
        variant="caption"
        fontSize={11}
        color={colors.contentInverseTertiary}
      >
        {tip}
      </Typography>

      <StatusLabelWrapper>
        <StatusLabel label={sectionData.label} color={sectionData.color} />
      </StatusLabelWrapper>
    </StepWrapper>
  );
};
