import { useContext } from "react";
import { FormationSteps } from "./services";
import { LoadingComponent } from "components/Loading";
import useSignupFlow from "graphql/hooks/UseSignupFlow";
import { EntityType } from "graphql/__generated__/types";
import { SignupContext } from "./SignupProvider";
import FlowAside, { AccordionContents } from "components/FlowAside";

const accordionContents: AccordionContents = {
  [FormationSteps.CountryOfResidence]: {
    callOut: {
      title: "Your U.S. Company from where you are",
      body: "doola is trusted by founders all over the world for formation, compliance and more services.",
    },
    tips: [],
  },
  [`${FormationSteps.CompanyState}-US${EntityType.LLC}`]: {
    callOut: {
      title: "Your home state is recommended",
      body: "Selecting your home state makes managing your company and maintaining compliance much simpler.",
    },
    tips: [
      {
        title: "Home State Benefits",
        content: [
          {
            title: "Familiarity",
            body: "Each state has its own set of laws governing LLCs. You'll likely have a better understanding of the legal requirements, compliance obligations, and ongoing reporting obligations specific to your state.",
          },
          {
            title: "Cost Savings",
            body: "Forming an LLC in your own state can potentially save you money. By registering in another state, you may have to do foreign qualification within your home state if you plan to transact in your home state.",
          },
          {
            title: "Access to Local Support",
            body: "In addition to support offered by doola, by forming an LLC in your own state, you can tap into local resources and support networks. This includes access to local business organizations, networking events, mentorship programs, and professional service providers who are familiar with the local business landscape.",
          },
          {
            title: "State Specific Incentives or Benefits",
            body: "Certain states may offer specific incentives or benefits for businesses operating within the state. These can include tax credits, grants, loan programs, or other forms of assistance that are available only to in-state businesses.",
          },
          {
            title: "Proximity to Customers",
            body: "If your target market or customer base is primarily located in your own state, forming an LLC locally can help establish a stronger presence and build trust among your local clientele. It may also make it easier to cater to their specific needs and preferences.",
          },
        ],
      },
    ],
  },
  [`${FormationSteps.CompanyState}-nonUS${EntityType.LLC}`]: {
    callOut: {
      title: "Wyoming is recommended",
      body: "Most LLCs formed by doola for non-US residents are in the state of Wyoming.",
    },
    tips: [
      {
        title: "Wyoming Benefits",
        content: [
          {
            title: "Business-Friendly Environment",
            body: "Wyoming has consistently been recognized as one of the most business-friendly states in the United States. It has a reputation for having favorable laws and regulations that are supportive of small businesses and entrepreneurs.",
          },
          {
            title: "Asset Protection",
            body: "The state provides robust liability protection for LLC owners, shielding personal assets from business liabilities. This means that if your LLC faces legal issues or debts, your personal assets are generally protected, reducing personal liability exposure.",
          },
          {
            title: "Privacy & Confidentiality",
            body: "Wyoming provides a higher level of privacy and confidentiality compared to many other states. The state does not require the disclosure of the names of LLC members on public records, for example. This can be advantageous for individuals who value privacy in their business operations.",
          },
          {
            title: "Low Costs",
            body: "The cost of forming and maintaining an LLC in Wyoming is relatively low compared to other states. The initial filing fees and annual fees for maintaining an LLC are affordable. This can be beneficial, particularly for small businesses or startups with limited budgets.",
          },
        ],
      },
    ],
  },
  [`${FormationSteps.CompanyState}-${EntityType.CCORP}`]: {
    callOut: {
      title: "Delaware & Wyoming are recommended",
      body: "Two of the most business friendly states in the United States.",
    },
    tips: [
      {
        title: "Delaware Benefits",
        content: [
          {
            title: "Established Corporate Law",
            body: "Delaware has a long history of well-developed and business-friendly corporate laws. The Delaware General Corporation Law (DGCL) provides a comprehensive legal framework for corporate governance, shareholder rights, and fiduciary duties. Delaware's specialized court system, the Court of Chancery, is known for its expertise in corporate law and its dedicated judges who handle corporate disputes.",
          },
          {
            title: "Favorable Business Environment",
            body: "The state actively promotes the interests of corporations and offers a supportive regulatory climate. The state government has established agencies and resources to assist businesses, and the Secretary of State's office is experienced in handling corporate filings and maintaining corporate records.",
          },
          {
            title: "Flexibility in Corporate Structure",
            body: "The state allows for various corporate governance provisions, including staggered boards, different classes of stock, and other mechanisms that enable tailored ownership structures, voting rights, and management arrangements. This flexibility can be advantageous when structuring your C-Corp to accommodate the needs and preferences of founders, investors, and future shareholders.",
          },
          {
            title: "Investor and VC Preference",
            body: "Delaware C-Corps have become the preferred choice for many venture capitalists (VCs) and institutional investors. Investors are often more familiar with Delaware corporate law and the rights and protections it provides to shareholders. Choosing Delaware can signal credibility and familiarity to potential investors, making it easier to attract funding and support for your C-Corp.",
          },
          {
            title: "Privacy & Confidentiality",
            body: "Shareholder information, including the names and addresses of officers, directors, and shareholders, does not need to be disclosed publicly during the formation process. This can provide an additional layer of privacy protection for individuals associated with the C-Corp.",
          },
        ],
      },
      {
        title: "Wyoming Benefits",
        content: [
          {
            title: "Business-Friendly Environment",
            body: "Wyoming has consistently been recognized as one of the most business-friendly states in the United States. It has a reputation for having favorable laws and regulations that are supportive of small businesses and entrepreneurs.",
          },
          {
            title: "Low Costs",
            body: "The cost of forming and maintaining a C-Corp in Wyoming is generally lower compared to many other states. The initial filing fees and annual fees for maintaining a C-Corp are affordable, making it an attractive option for small businesses and startups with limited budgets.",
          },
          {
            title: "Flexibility in Corporate Structure",
            body: "Wyoming offers flexibility in structuring and managing C-Corps. The state's laws allow for various corporate governance provisions, enabling tailored ownership structures, voting rights, and management arrangements to meet the specific needs of your business and stakeholders.",
          },
          {
            title: "Tax Advantages",
            body: "Wyoming has favorable tax policies that can benefit C-Corps. The state imposes no corporate income tax, no personal income tax, and no franchise tax on C-Corps. This can result in significant tax savings for your business. Additionally, Wyoming does not have an annual C-Corp tax or inventory tax.",
          },
          {
            title: "Privacy and Confidentiality",
            body: "The state does not require the disclosure of the names of shareholders, directors, or officers on public records, providing an additional layer of privacy and shielding the identities of individuals associated with the C-Corp.",
          },
        ],
      },
    ],
  },
  [`${FormationSteps.CompanyState}-${EntityType.DAO}`]: {
    callOut: {
      title: "Wyoming is recommended",
      body: "Wyoming is at the forefront of offering a legal framework for this innovative entity structure.",
    },
    tips: [
      {
        title: "Wyoming Benefits",
        content: [
          {
            title: "Progressive Legislation",
            body: "The state has enacted legislation that specifically addresses DAOs and provides legal recognition and support for their formation and operation. The Wyoming DAO LLC Act, passed in 2021, provides a clear legal framework for creating and governing DAOs within the state.",
          },
          {
            title: "Limited Liability Protection",
            body: "Like traditional LLCs, a DAO LLC in Wyoming can provide limited liability protection to its members. This means that individual members' personal assets are generally shielded from liabilities and obligations of the DAO.",
          },
          {
            title: "Privacy & Confidentiality",
            body: "Wyoming has privacy-friendly policies that can be beneficial for DAOs. The state does not require the disclosure of the names of DAO members on public records, for example. This can be particularly valuable for DAOs that prioritize privacy and confidentiality in their operations.",
          },
          {
            title: "Supportive Ecosystem",
            body: "The state has established the Wyoming Blockchain Task Force and other initiatives to promote innovation, collaboration, and education in the blockchain space. This supportive environment can provide access to resources, networks, and expertise to help your DAO thrive.",
          },
        ],
      },
    ],
  },
  [FormationSteps.CompanyName]: {
    callOut: {
      title: "Company name is up first",
      body: "This is the official name of the company to the state. Your  brand, website URL, and social media handles can be, and often times are, different.",
    },
    tips: [
      {
        title: "Why Names Get Rejected",
        content: [
          {
            title: "Similar Names",
            body: "If the proposed company name is too similar to an existing company name in the state, the state may reject it to avoid confusion and prevent trademark infringement.",
          },
          {
            title: "Restricted Words",
            body: "Some words and phrases may be restricted or prohibited in company names in certain states. For example, some states may not allow the use of words like 'bank' or 'insurance', for example, unless the company is licensed to provide those services.",
          },
          {
            title: "Offensive Language",
            body: "States may reject company names that contain offensive language or words that are deemed inappropriate.",
          },
          {
            title: "Misleading Names",
            body: "States may reject company names that are misleading or deceptive, such as names that suggest the company is associated with a government agency or that it provides services it does not actually offer.",
          },
          {
            title: "Noncompliance",
            body: "If the proposed company name does not comply with the state's naming requirements, such as including the required company identifier (e.g. 'LLC' or 'Inc.'), the state may reject it.",
          },
        ],
      },
    ],
  },
  [FormationSteps.TotalCompliance]: {
    callOut: {
      title: "You’re in good hands with doola!",
      body: "Total Compliance provides everything you need to ensure your business stays 100% compliant.",
    },
    tips: [
      {
        title: "Dedicated Account Manager",
        content: [
          {
            title: "",
            body: "The dedicated account manager is your business's sole point of contact for all of your business compliance needs with doola. With a dedicated account manager, you never have to worry about who will be handling your questions and resolving any problems you may face. No more contacting multiple Customer Service representatives who may not have a background of your company.",
          },
          {
            title: "",
            body: "Our dedicated account management role is only available to Total Compliance customers. Your account manager will be your direct point of contact with doola and will ensure that any business compliance needs or questions are addressed as quick as possible. Our account managers have helped thousands of founders address their questions and they can help answer your questions too!",
          },
        ],
      },
      {
        title: "All-in-One Subscription",
        content: [
          {
            title: "",
            body: `The "All in One" Total Compliance subscription is a comprehensive service bundle that combines various offerings into a single, convenient package. This type of subscription offers simplicity, cost savings, and convenience, making it a popular choice for business founders looking for a streamlined and efficient way to manage their business compliance needs. Start your Total Compliance plan today and see the difference!`,
          },
        ],
      },
    ],
  },
  [FormationSteps.Expedited]: {
    callOut: {
      title: "We’re almost there",
      body: "doola treats each formation with care, diligently working through each application to get your application done as quickly as possible.",
    },
    tips: [
      {
        title: "What’s a CAA?",
        content: [
          {
            title: "",
            body: "A Certified Acceptance Agent (CAA) is an individual, organization, or entity that has been authorized by the Internal Revenue Service (IRS) to assist foreign individuals and entities in obtaining an Employer Identification Number (EIN). CAA’s are able to directly contact the IRS on a business's behalf, allowing them to work with the IRS to receive EIN numbers much faster than standard processing channels.",
          },
        ],
      },
    ],
  },
  [FormationSteps.BoiFiling]: {
    callOut: {
      title: "You’re in good hands with doola!",
      body: "Watch this area for best practices and tips to guide you through the paperwork.",
    },
    tips: [
      {
        title: "What is the BOI report?",
        content: [
          {
            title: "Definition",
            body: "Beneficial ownership information (BOI) refers to identifying information about the individuals who directly or indirectly own or control a company.",
          },
          {
            title: "Context",
            body: "In 2021, Congress passed the Corporate Transparency Act on a bipartisan basis. This law creates a new beneficial ownership information reporting requirement as part of the U.S. government’s efforts to make it harder for bad actors to hide or benefit from their ill-gotten gains through shell companies or other opaque ownership structures.",
          },
        ],
      },
      {
        title: "When is the BOI report due?",
        content: [
          {
            title: "Deadline",
            body: "Companies formed after January 1st, 2024 are required to file a Beneficial Ownership Information (BOI) report within 90 days of their business being formed.",
          },
        ],
      },
    ],
  },
  [FormationSteps.Review]: {
    callOut: {
      title: "Your application is just a checkout away",
      body: "After completing this order, you’ll go through a guided experience asking you about company structure, members, and ownership.",
    },
    tips: [
      {
        title: "What You’ll Need",
        content: [
          {
            title: "Company Address",
            body: "All companies need a business address. You’re welcome to use an address provided by doola or choose your own, if eligible.",
          },
          {
            title: "Company Industry & Description",
            body: "A simple what your company does which could entitle special treatment from the state or Internal Revenue Service (IRS).",
          },
          {
            title: "Accountability",
            body: "The responsible party assumes accountability for the accuracy and completeness of the information provided to the IRS. They are responsible for signing and filing the entity's tax returns and other required forms.",
          },
          {
            title: "Members",
            body: "All the people that have a percentage or share of your new company and how much they own.",
          },
        ],
      },
    ],
  },
};

const SignupAside = () => {
  const { formationStep } = useContext(SignupContext);

  const { countryOfResidence, entityType } = useSignupFlow();

  let accordionStepContent = accordionContents[formationStep];

  if (formationStep === FormationSteps.CompanyState) {
    if (entityType === EntityType.LLC) {
      if (countryOfResidence === "USA") {
        accordionStepContent =
          accordionContents[`${formationStep}-US${EntityType.LLC}`]!;
      } else {
        accordionStepContent =
          accordionContents[`${formationStep}-nonUS${EntityType.LLC}`]!;
      }
    } else if (entityType === EntityType.DAO) {
      accordionStepContent =
        accordionContents[`${FormationSteps.CompanyState}-${EntityType.DAO}`]!;
    } else if (entityType === EntityType.CCORP) {
      accordionStepContent =
        accordionContents[
          `${FormationSteps.CompanyState}-${EntityType.CCORP}`
        ]!;
    }
  }

  if (!accordionStepContent) {
    return <LoadingComponent />;
  }

  return <FlowAside accordionStepContent={accordionStepContent} />;
};

export default SignupAside;
