import { Stack, SxProps, Typography, useMediaQuery } from "@mui/material";
import utils, { colors } from "utils";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

type Props = {
  body: string;
  style?: SxProps;
};

const AlertNotification = ({ body, style }: Props) => {
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);

  return (
    <Stack
      sx={{
        p: isMobile ? 2 : 3,
        borderRadius: isMobile ? 4 : 6,
        bgcolor: colors.lightBackgroundWarning,
        ...(style && { ...style }),
      }}
      gap={2}
    >
      <WarningAmberRoundedIcon />
      <Typography fontSize={16} fontWeight={400} component="span">
        {body}
      </Typography>
    </Stack>
  );
};

export default AlertNotification;
