import { P } from "components/Fonts";
import { FunctionComponent } from "react";
import { StyledWrapper } from "./styles";
import { Props } from "./types";

export const StatusLabel: FunctionComponent<Props> = ({ label, color }) => {
  return (
    <StyledWrapper color={color}>
      <P size="xsmall">{label}</P>
    </StyledWrapper>
  );
};
