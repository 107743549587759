import { useState, useEffect, ChangeEvent } from "react";
import {
  Divider,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { t } from "i18next";
import { ENTITY_ENDINGS } from "resources/entityTypes";
import utils, { colors } from "utils";
import { SignupSelect } from "views/SignupFlow/styles";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import useCompany from "graphql/hooks/UseCompany";
import { LoadingComponent } from "components/Loading";
import stateKeys from "resources/stateKeys";
import { useUpdateCompanyNameAlternateMutation } from "graphql/__generated__/operations/UpdateCompanyNameAlternate.generated";
import { CompanyNameStatus, EntityType } from "graphql/__generated__/types";
import ButtonBox from "components/ButtonBox";
import StyledCheckbox from "components/FormElements/Checkbox";
import { Windows1252Regex } from "utils/regexValidations";
import { usePostHog } from "posthog-js/react";

const renderEntityTypeEnding = (entityType: EntityType) =>
  ENTITY_ENDINGS[entityType].map((value, index) => (
    <MenuItem key={index} value={value}>
      {value}
    </MenuItem>
  ));

type CompanyNameInput = {
  name: string;
  entityTypeEnding: string;
};

interface FieldsErrors {
  companyName: boolean;
  alternateCompany1: boolean;
  alternateCompany2: boolean;
}

const OnboardingCompanyName = () => {
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { currentCompany } = useCompany();
  const [updateCompanyNameAlternate] = useUpdateCompanyNameAlternateMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [fieldsErrors, setFieldsErrors] = useState<FieldsErrors>({
    companyName: false,
    alternateCompany1: false,
    alternateCompany2: false,
  });
  const [defaultCompanyName, setDefaultCompanyName] =
    useState<null | CompanyNameInput>(null);
  const [alternateCompany1, setAlternateCompany1] =
    useState<null | CompanyNameInput>(null);
  const [alternateCompany2, setAlternateCompany2] =
    useState<null | CompanyNameInput>(null);

  useEffect(() => {
    if (defaultCompanyName === null && currentCompany) {
      // If no nameOptions exist, set the company name
      if (!currentCompany?.nameOptions) {
        setDefaultCompanyName({
          name: currentCompany?.name || "",
          entityTypeEnding: currentCompany?.entityTypeEnding || "",
        });
      } else {
        // Find the name options based on their positions
        const option1 = currentCompany.nameOptions.find(
          (option) => option?.position === 0
        );
        const option2 = currentCompany.nameOptions.find(
          (option) => option?.position === 1
        );
        const option3 = currentCompany.nameOptions.find(
          (option) => option?.position === 2
        );

        // Update state based on available options
        if (option1) {
          setDefaultCompanyName({
            name: option1.name || "",
            entityTypeEnding: option1.entityTypeEnding || "",
          });
        }

        if (option2) {
          setAlternateCompany1({
            name: option2.name,
            entityTypeEnding: option2.entityTypeEnding,
          });
        }

        if (option3) {
          setAlternateCompany2({
            name: option3.name,
            entityTypeEnding: option3.entityTypeEnding,
          });
        }
      }
    }
  }, [currentCompany, defaultCompanyName]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  if (!currentCompany) {
    return <LoadingComponent />;
  }

  const hasAllCompanyNameInfo = [
    defaultCompanyName,
    alternateCompany1,
    alternateCompany2,
  ].every((x) => x?.name && x.entityTypeEnding);

  const hasDefaultCompanyInfo = !!(
    defaultCompanyName?.name && defaultCompanyName?.entityTypeEnding
  );

  const hasValidationErrors = Object.values(fieldsErrors).some(
    (error) => error
  );

  const isDisabled =
    (!hasAllCompanyNameInfo && !isChecked) ||
    !hasDefaultCompanyInfo ||
    hasValidationErrors;

  const state = stateKeys[currentCompany.state as keyof typeof stateKeys];

  const handleOnClickNext = async () => {
    try {
      setIsLoading(true);

      if (isChecked && defaultCompanyName) {
        await updateCompanyNameAlternate({
          variables: {
            companyId: currentCompany?.id || "",
            names: [
              {
                ...defaultCompanyName,
                position: 0,
                status: CompanyNameStatus.INPUTTED,
              },
            ],
          },
        });
      } else {
        if (defaultCompanyName && alternateCompany1 && alternateCompany2) {
          const formattedNames = [
            defaultCompanyName,
            alternateCompany1,
            alternateCompany2,
          ].map((x, index) => ({
            ...x,
            position: index,
            status: CompanyNameStatus.INPUTTED,
          }));

          await updateCompanyNameAlternate({
            variables: {
              companyId: currentCompany?.id || "",
              names: formattedNames,
            },
          });
        }
      }

      posthog?.capture("company-name-set", {
        count: isChecked ? 1 : 3,
      });

      if (isChecked) {
        posthog?.capture("company-name-skipped");
      }

      navigate(ROUTER_PATHS.ONBOARDING_ADDRESS);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const checkFieldValidation = (inputValue: string, fieldName: string) => {
    if (inputValue) {
      const isValid = Windows1252Regex.test(inputValue);

      setFieldsErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: !isValid,
      }));
    } else {
      setFieldsErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: false,
      }));
    }
  };

  return (
    <Stack direction="column" gap={3} mb={5}>
      <Typography variant="h1">Confirm your company name</Typography>
      <Stack bgcolor={colors.lightGrey} p={3} spacing={2} borderRadius="24px">
        <Typography variant="h4">General Information</Typography>
        <Stack direction="row" justifyContent="space-between">
          <Typography>Company Name</Typography>
          <Typography>{currentCompany.name}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography>Entity Type</Typography>
          <Typography>{currentCompany.entityType}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography>State</Typography>
          <Typography>{state}</Typography>
        </Stack>
      </Stack>
      <Stack gap={2}>
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={isMobile ? 1 : 2}
        >
          <Stack spacing={1} sx={{ width: isMobile ? "100%" : "75%" }}>
            <Typography
              variant="h6"
              color={utils.secondaryColors.secondaryMain}
            >
              First company name (preferred)
            </Typography>
            <TextField
              placeholder={t("general.companyNamePlaceholder") as string}
              fullWidth
              value={defaultCompanyName?.name || ""}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const inputValue = e.target.value;

                setDefaultCompanyName((prevState) => ({
                  ...prevState!,
                  name: inputValue,
                }));

                checkFieldValidation(inputValue, "companyName");
              }}
              error={fieldsErrors.companyName}
              sx={{
                ".MuiInputBase-root": {
                  height: "40px",
                },
              }}
            />
          </Stack>
          <Stack spacing={1} sx={{ width: isMobile ? "100%" : "25%" }}>
            <Typography
              variant="h6"
              color={utils.secondaryColors.secondaryMain}
            >
              {t("general.companyEntityEnding")}
            </Typography>
            <SignupSelect
              displayEmpty
              value={defaultCompanyName?.entityTypeEnding || ""}
              onChange={(e: any) =>
                setDefaultCompanyName((prevState) => ({
                  ...prevState!,
                  entityTypeEnding: e.target.value,
                }))
              }
            >
              <MenuItem disabled value="">
                {t("signup.entityName.ending")}
              </MenuItem>
              {renderEntityTypeEnding(
                currentCompany.entityType ?? EntityType.LLC
              )}
            </SignupSelect>
          </Stack>
        </Stack>
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={isMobile ? 1 : 2}
        >
          <Stack spacing={1} sx={{ width: isMobile ? "100%" : "75%" }}>
            <Typography
              variant="h6"
              color={utils.secondaryColors.secondaryMain}
            >
              Alternate Company Name 1
            </Typography>
            <TextField
              placeholder={t("general.companyNamePlaceholder") as string}
              fullWidth
              value={alternateCompany1?.name || ""}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const inputValue = e.target.value;

                setAlternateCompany1((prevState) => ({
                  ...prevState!,
                  name: inputValue,
                }));

                checkFieldValidation(inputValue, "alternateCompany1");
              }}
              error={fieldsErrors.alternateCompany1}
              sx={{
                ".MuiInputBase-root": {
                  height: "40px",
                },
              }}
              data-testid="OnboardingCompanyNameAlternateName1"
            />
          </Stack>
          <Stack spacing={1} sx={{ width: isMobile ? "100%" : "25%" }}>
            <Typography
              variant="h6"
              color={utils.secondaryColors.secondaryMain}
            >
              {t("general.companyEntityEnding")}
            </Typography>
            <SignupSelect
              displayEmpty
              value={alternateCompany1?.entityTypeEnding || ""}
              onChange={(e: any) =>
                setAlternateCompany1((prevState) => ({
                  ...prevState!,
                  entityTypeEnding: e.target.value,
                }))
              }
              data-testid="OnboardingCompanyNameAlternateNameEnding1"
            >
              <MenuItem disabled value="">
                {t("signup.entityName.ending")}
              </MenuItem>
              {renderEntityTypeEnding(
                currentCompany.entityType ?? EntityType.LLC
              )}
            </SignupSelect>
          </Stack>
        </Stack>
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={isMobile ? 1 : 2}
        >
          <Stack spacing={1} sx={{ width: isMobile ? "100%" : "75%" }}>
            <Typography
              variant="h6"
              color={utils.secondaryColors.secondaryMain}
            >
              Alternate Company Name 2
            </Typography>
            <TextField
              placeholder={t("general.companyNamePlaceholder") as string}
              fullWidth
              value={alternateCompany2?.name || ""}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const inputValue = e.target.value;

                setAlternateCompany2((prevState) => ({
                  ...prevState!,
                  name: inputValue,
                }));

                checkFieldValidation(inputValue, "alternateCompany2");
              }}
              error={fieldsErrors.alternateCompany2}
              sx={{
                ".MuiInputBase-root": {
                  height: "40px",
                },
              }}
              data-testid="OnboardingCompanyNameAlternateName2"
            />
          </Stack>
          <Stack spacing={1} sx={{ width: isMobile ? "100%" : "25%" }}>
            <Typography
              variant="h6"
              color={utils.secondaryColors.secondaryMain}
            >
              {t("general.companyEntityEnding")}
            </Typography>
            <SignupSelect
              displayEmpty
              value={alternateCompany2?.entityTypeEnding || ""}
              onChange={(e: any) =>
                setAlternateCompany2((prevState) => ({
                  ...prevState!,
                  entityTypeEnding: e.target.value,
                }))
              }
              data-testid="OnboardingCompanyNameAlternateNameEnding2"
            >
              <MenuItem disabled value="">
                {t("signup.entityName.ending")}
              </MenuItem>
              {renderEntityTypeEnding(
                currentCompany.entityType ?? EntityType.LLC
              )}
            </SignupSelect>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems={"center"} gap={2}>
        <InfoIcon />
        <Typography>
          doola will confirm the availability of your company name in the state
          of {state} while filing your paperwork. In the event the first choice
          is not available, the next choice will be used.
        </Typography>
      </Stack>
      <Divider />
      <FormGroup>
        <FormControlLabel
          control={
            <StyledCheckbox onChange={handleChange} checked={isChecked} />
          }
          label={
            <Stack ml={1}>
              <Typography variant="subtitle1">
                I do not want to offer alternate names
              </Typography>
              <Typography>
                If your preferred company name is unavailable in {state}, you
                will be contacted by doola to provide you with alternative
                options. Please note that this may delay the filing process.
              </Typography>
            </Stack>
          }
          sx={{ alignItems: "flex-start" }}
        />
      </FormGroup>
      {hasValidationErrors && (
        <Typography color={colors.red} fontSize={12}>
          {t("error.invalidCharacters")}
        </Typography>
      )}
      <ButtonBox
        onClickBack={() => navigate(ROUTER_PATHS.ONBOARDING_INTRO)}
        onClickNext={handleOnClickNext}
        disabled={isDisabled}
        isLoading={isLoading}
      />
    </Stack>
  );
};

export default OnboardingCompanyName;
