import { OnboardingApplicantInput } from "graphql/__generated__/types";

export const INTITAL_APPLICANT_INFORMATION_VALUES: OnboardingApplicantInput = {
  additionalInfoApplicationDeclaration: "",
  address: {
    address1: "",
    address2: "",
    city: "",
    country: "",
    postalCode: "",
    state: "",
  },
  contactCountryCode: "",
  contactEmail: "",
  contactNumber: "",
  dateOfBirth: "",
  documents: [],
  firstName: "",
  lastName: "",
  nationality: "",
};
