import { Chip, Stack, Typography, useMediaQuery } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import utils, { colors } from "utils";
import { formatToLocalTimezone, moneyFormatter } from "utils/formatters";
import { MemoizedAvatar } from "./Avatar";
import { WalletTransaction } from "graphql/__generated__/types";
import { getTransferMethod } from "../helpers";

type Props = {
  transaction: WalletTransaction;
};

const PENDING_STATUS = "Pending";

const TransactionItem = ({ transaction }: Props) => {
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const {
    status,
    debit,
    destinationCountry,
    beneficiaryId,
    createdAt,
    authAmount,
    payoutMethod,
    transactionCurrencyCode,
    displayText,
    beneficiaryName,
  } = transaction;

  const recipientName = beneficiaryName || displayText || "";
  const date = createdAt || "";
  const amount = authAmount || "";
  const transferType = getTransferMethod({
    payoutMethod: payoutMethod || "",
    destinationCountry: destinationCountry || "",
    destinationCurrency: transactionCurrencyCode || "",
  });

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Stack direction={"row"} alignItems={"center"}>
        <MemoizedAvatar name={recipientName} countryCode={destinationCountry} />
        <Stack ml={2}>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography fontSize={"16px"} fontWeight={"500"}>
              {recipientName}
            </Typography>
            {status === PENDING_STATUS && (
              <Chip
                label={status}
                sx={{
                  borderRadius: 2,
                  bgcolor: colors.grey,
                  color: colors.black,
                  fontSize: isTablet ? "12px" : "14px",
                  fontWeight: 500,
                  ml: 2,
                }}
              />
            )}
          </Stack>
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            <Typography fontSize={isTablet ? "12px" : "14px"}>
              {formatToLocalTimezone(new Date(date), "MMM DD [at] h:mm A")}
            </Typography>
            {beneficiaryId && (
              <>
                <CircleIcon sx={{ fontSize: "4px" }} />
                <Typography fontSize={isTablet ? "12px" : "14px"}>
                  {transferType}
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Typography
        fontSize={isTablet ? "14px" : "16px"}
        fontWeight={500}
        color={debit ? colors.black : colors.green}
      >
        {debit
          ? `-${moneyFormatter(parseFloat(amount), false)}`
          : `+${moneyFormatter(parseFloat(amount), false)}`}
      </Typography>
    </Stack>
  );
};

export default TransactionItem;
