import styled from "styled-components";

type Props = {
  color: string;
};

export const StyledWrapper = styled.div<Props>`
  margin: auto;
  text-align: center;
  padding: 5px 20px 5px 15px;
  background-color: ${(props) => props.color};
  border-radius: 20px;
  align-items: center;
`;
