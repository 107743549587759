import { PayoutMethodType } from "graphql/__generated__/types";
import {
  RoutingCodeTypes,
  TransferMethodEnum,
} from "views/DashboardApp/Money/helpers";

export type TransferMethodInformationDetails = {
  beneficiaryAccountNumber: string;
  destinationCountry: string;
  destinationCurrency: string;
  payoutMethod: PayoutMethodType;
  routingCodeType1: string;
  routingCodeValue1: string;
  transferMethod: TransferMethodEnum;
};

export const INITIAL_TRANSFER_METHOD_INFORMATION_VALUES: TransferMethodInformationDetails =
  {
    beneficiaryAccountNumber: "",
    destinationCountry: "",
    destinationCurrency: "",
    payoutMethod: PayoutMethodType.LOCAL,
    routingCodeType1: RoutingCodeTypes.ACH_CODE,
    routingCodeValue1: "",
    transferMethod: TransferMethodEnum.ACH,
  };
