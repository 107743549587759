import { useGetProductsQuery } from "graphql/__generated__/operations/Products.generated";
import { ProductDuration } from "graphql/__generated__/types";
import { IPlans, STARTER, TOTAL_COMPLIANCE } from "../data";
import { PRODUCTS_DB_KEYS } from "resources/productsLookupKeys";

export default function useGetMainProducts() {
  const { data, loading: isProductQueryLoading } = useGetProductsQuery({
    fetchPolicy: "cache-first",
    errorPolicy: "all",
  });

  const productServices = data?.productService?.payload;

  const filteredPlans = productServices?.filter((item) =>
    [
      PRODUCTS_DB_KEYS.STARTER,
      PRODUCTS_DB_KEYS.TOTAL_COMPLIANCE_MONTHLY,
      PRODUCTS_DB_KEYS.TOTAL_COMPLIANCE_ANNUAL,
    ].includes(item?.key as PRODUCTS_DB_KEYS)
  );

  const modifiedPlans = filteredPlans?.map((plan) => ({
    ...plan,
    usdPrice: (plan?.usdPriceCents ?? 0) / 100,
  }));

  const findPlanByKey = (key: string) =>
    modifiedPlans?.find((item) => item?.key === key);

  const STARTER_PLAN = findPlanByKey(PRODUCTS_DB_KEYS.STARTER);
  const TOTAL_COMPLIANCE_ANNUAL_PLAN = findPlanByKey(
    PRODUCTS_DB_KEYS.TOTAL_COMPLIANCE_ANNUAL
  );
  const TOTAL_COMPLIANCE_PLAN = findPlanByKey(
    PRODUCTS_DB_KEYS.TOTAL_COMPLIANCE_MONTHLY
  );

  const PLANS: IPlans = {
    [STARTER.value]: {
      value: STARTER.value,
      name: STARTER.name,
      displayName: STARTER.name,
      description:
        "The business basics. Formation & EIN Registration, Registered Agent service and an Operating Agreement to get you started on the right track.",
      hasMultiplePlans: false,
      duration: {
        [STARTER_PLAN?.duration as ProductDuration]: {
          lookupKey: PRODUCTS_DB_KEYS.STARTER,
          price: STARTER_PLAN?.usdPrice,
          stripePriceId: STARTER_PLAN?.stripePricingId,
        },
      },
    },
    [TOTAL_COMPLIANCE.value]: {
      value: TOTAL_COMPLIANCE.value,
      name: TOTAL_COMPLIANCE.name,
      displayName: TOTAL_COMPLIANCE.name,
      description:
        "Everything the Starter package offers PLUS Expedited Business Setup processing, Annual State filings, a 30-min CPA consultation, IRS Tax filings, BOI filing and a Dedicated Account Manager.",
      hasMultiplePlans: true,
      duration: {
        [TOTAL_COMPLIANCE_ANNUAL_PLAN?.duration as ProductDuration]: {
          lookupKey: PRODUCTS_DB_KEYS.TOTAL_COMPLIANCE_ANNUAL,
          price: TOTAL_COMPLIANCE_ANNUAL_PLAN?.usdPrice,
          stripePriceId: TOTAL_COMPLIANCE_ANNUAL_PLAN?.stripePricingId,
        },
        [TOTAL_COMPLIANCE_PLAN?.duration as ProductDuration]: {
          lookupKey: PRODUCTS_DB_KEYS.TOTAL_COMPLIANCE_MONTHLY,
          price: TOTAL_COMPLIANCE_PLAN?.usdPrice,
          stripePriceId: TOTAL_COMPLIANCE_PLAN?.stripePricingId,
        },
      },
    },
  };

  return {
    PLANS,
    defaultPlan: TOTAL_COMPLIANCE_PLAN,
    isProductQueryLoading,
  };
}
