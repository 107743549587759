import { useEffect, useState } from "react";
import { TransferSteps } from "./data";
import { Stack, useMediaQuery } from "@mui/material";
import Stepper from "components/Stepper";
import utils, { colors } from "utils";
import FinalTransferStep from "./components/FinalTransferStep";
import StepContent from "./StepContent";
import Button from "components/Button";
import { NavigateNext } from "@mui/icons-material";
import { ROUTER_PATHS } from "routes/routes";
import { useNavigate, useSearchParams } from "react-router-dom";
import { t } from "i18next";
import { WalletTransferRequest } from "graphql/__generated__/types";
import { INITIAL_TRANSACTION_VALUES } from "./helpers";
import { usePostHog } from "posthog-js/react";

const TransferFlow = () => {
  const [searchParams] = useSearchParams();

  const [currentStep, setCurrentStep] = useState<TransferSteps>(
    TransferSteps.transferInfo
  );
  const [isFinalStep, setIsFinalStep] = useState<boolean>(false);

  const [transferData, setTransferData] = useState<WalletTransferRequest>(
    INITIAL_TRANSACTION_VALUES
  );

  const navigate = useNavigate();

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);
  const posthog = usePostHog();

  const stepKeys = Object.keys(TransferSteps);
  const totalSteps = stepKeys.length;

  // Find the index of the current step
  const currentStepIndex = stepKeys.indexOf(currentStep);

  useEffect(() => {
    const beneficiaryId = searchParams.get("recipient");

    if (!beneficiaryId) {
      navigate(ROUTER_PATHS.MONEY_OVERVIEW);
    }
  }, [navigate, searchParams]);

  return (
    <Stack
      gap={5}
      p={isTablet ? 0 : 4}
      mb={isTablet ? 9 : 0}
      alignSelf={"center"}
      sx={{ width: isTablet ? "100%" : "80%" }}
    >
      {!isFinalStep && (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stepper
            label={t("general.step")}
            currentStep={currentStepIndex + 1}
            numberOfSteps={totalSteps ?? 0}
          />
          {!isMobile && (
            <Button
              dark={false}
              buttonText={t("money.transfer.cancel")}
              endIcon={<NavigateNext />}
              styles={{ alignSelf: "flex-end", bgcolor: colors.grey }}
              onClick={() => {
                posthog?.capture("money_transfer_cancel_transfer_btn_click");
                navigate(ROUTER_PATHS.MONEY_TRANSFER);
              }}
            />
          )}
        </Stack>
      )}

      {isFinalStep ? (
        <FinalTransferStep />
      ) : (
        <StepContent
          currentStep={currentStep}
          transferData={transferData}
          setTransferData={setTransferData}
          setCurrentStep={setCurrentStep}
          setIsFinalStep={setIsFinalStep}
        />
      )}
    </Stack>
  );
};

export default TransferFlow;
