import { useEffect, useState } from "react";
import { useGetMoneyRecentBeneficiariesLazyQuery } from "graphql/__generated__/operations/MoneyRecentBeneficiaries.generated";
import useCompany from "graphql/hooks/UseCompany";

const useGetRecentBeneficiaries = () => {
  const { currentCompany } = useCompany();

  const [isRecentBeneficiariesLoading, setIsRecentBeneficiariesLoading] =
    useState<boolean>(true);

  const [fetchRecentBeneficiaries, { data }] =
    useGetMoneyRecentBeneficiariesLazyQuery({ fetchPolicy: "network-only" });

  useEffect(() => {
    setIsRecentBeneficiariesLoading(true);

    if (currentCompany?.id) {
      const getRecentBeneficiaries = async () => {
        try {
          await fetchRecentBeneficiaries({
            variables: {
              companyId: currentCompany.id,
            },
          });
        } catch (error) {
          console.error(
            "Failed to fetch the recent beneficiaries, error: ",
            error
          );
        } finally {
          setIsRecentBeneficiariesLoading(false);
        }
      };

      getRecentBeneficiaries();
    }
  }, [currentCompany?.id, fetchRecentBeneficiaries]);

  return {
    recentBeneficiaries: data?.recentBeneficiaries?.payload,
    isRecentBeneficiariesLoading,
  };
};

export default useGetRecentBeneficiaries;
