import {
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormationScreenNames } from "../services";
import utils, { colors } from "utils";
import { SignupSelect } from "../styles";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { ENTITY_ENDINGS } from "resources/entityTypes";
import { Trans, useTranslation } from "react-i18next";
import { useUpdateCompanyNameMutation } from "graphql/__generated__/operations/UpdateCompanyName.generated";
import { useUpdateCompanyEntityTypeEndingMutation } from "graphql/__generated__/operations/UpdateCompanyEntityTypeEnding.generated";
import useSignupFlow from "graphql/hooks/UseSignupFlow";
import ButtonBox from "components/ButtonBox";
import { LoadingComponent } from "components/Loading";
import { ROUTER_PATHS } from "routes/routes";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { selectCompanyName, setCompanyName } from "state/formationFormSlice";
import { Windows1252Regex } from "utils/regexValidations";
import { usePostHog } from "posthog-js/react";
import { EntityType } from "graphql/__generated__/types";

const CompanyName = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const posthog = usePostHog();

  const prefilledCompanyName = useAppSelector(selectCompanyName);

  const {
    companyId,
    entityType,
    entityEnding,
    entityName: companyName,
    hasDataLoaded,
  } = useSignupFlow();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [entityTypeEnding, setEntityTypeEnding] = useState<string>("");
  const [entityName, setEntityName] = useState<string>("");
  const [endingArray, setEndingArray] = useState<string[]>(
    ENTITY_ENDINGS[EntityType.LLC]
  );
  const [hasError, setHasError] = useState<boolean>(false);

  const hasLoadedInitially = useRef<boolean>(false);

  const [updateCompanyName] = useUpdateCompanyNameMutation();
  const [updateCompanyTypeEnding] = useUpdateCompanyEntityTypeEndingMutation();

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);

  useEffect(() => {
    posthog?.capture("company-name-viewed");
  }, [posthog]);

  useEffect(() => {
    if (hasDataLoaded && !hasLoadedInitially.current) {
      setEntityTypeEnding(entityEnding || "");
      setEntityName(companyName || prefilledCompanyName || "");
      hasLoadedInitially.current = true;
    }
  }, [companyName, entityEnding, hasDataLoaded, prefilledCompanyName]);

  useEffect(() => {
    if (entityType) {
      setEndingArray(ENTITY_ENDINGS[entityType || EntityType.LLC]);
      setEntityTypeEnding(ENTITY_ENDINGS[entityType || EntityType.LLC][0]);
    }
  }, [entityType]);

  const isEntityNameValid = true; // MOCKED: this will be the response from the API checking if the name is available
  const isDisabled =
    !entityName || !entityTypeEnding || !isEntityNameValid || hasError;

  const navigateToNextStep = () => {
    navigate(ROUTER_PATHS.PRE_PURCHASE_TC);
  };

  const onSubmitHandler = async () => {
    setIsLoading(true);

    await updateCompanyName({
      variables: { companyId, companyName: entityName },
    });

    await updateCompanyTypeEnding({
      variables: { companyId, typeEnding: entityTypeEnding },
    });

    posthog?.capture("company-name-set");
    dispatch(setCompanyName(""));

    navigateToNextStep();
  };

  const onBackHandler = () => {
    posthog?.capture("funnel-back", {
      screen: FormationScreenNames.CompanyName,
    });

    navigate(ROUTER_PATHS.PRE_PURCHASE_COMPANY_STATE);
  };

  const onSkipHandler = async () => {
    setIsLoading(true);

    await updateCompanyTypeEnding({
      variables: { companyId, typeEnding: entityTypeEnding },
    });

    posthog?.capture("company-name-skipped");

    navigateToNextStep();
  };

  if (!hasDataLoaded) {
    return <LoadingComponent />;
  }

  return (
    <Stack direction="column" gap={3}>
      <Typography variant={isTablet ? "h3" : "h2"}>
        {t("signup.entityName.heading")}
      </Typography>
      <Stack spacing={1}>
        <Typography variant="h6" color={utils.secondaryColors.secondaryMain}>
          {t("general.companyName")}
        </Typography>
        <Stack direction={isMobile ? "column" : "row"} gap={isMobile ? 1 : 2}>
          <Stack sx={{ width: isMobile ? "100%" : "75%" }}>
            <TextField
              placeholder={t("general.companyNamePlaceholder") as string}
              fullWidth
              value={entityName}
              onChange={(e) => {
                const inputValue = e.target.value;

                setEntityName(inputValue);
                if (inputValue) {
                  const isValid = Windows1252Regex.test(inputValue);
                  setHasError(!isValid);
                } else {
                  setHasError(false);
                }
              }}
              error={hasError}
              sx={{
                ".MuiInputBase-root": {
                  height: "40px",
                },
              }}
            />
          </Stack>
          <Stack sx={{ width: isMobile ? "100%" : "25%" }}>
            <SignupSelect
              displayEmpty
              value={entityTypeEnding}
              onChange={(e) => setEntityTypeEnding(e.target.value as string)}
            >
              <MenuItem disabled value="">
                {t("signup.entityName.ending")}
              </MenuItem>
              {endingArray.map((value, index) => {
                return (
                  <MenuItem key={index} value={value}>
                    {value}
                  </MenuItem>
                );
              })}
            </SignupSelect>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems={"center"} gap={2}>
        <InfoIcon />
        <Typography>
          <Trans
            i18nKey="signup.companyName.tooltip"
            values={{ value: t("general.skip") }}
            components={[
              <Typography
                component={"span"}
                onClick={onSkipHandler}
                style={{
                  color: colors.black,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              />,
            ]}
          ></Trans>
        </Typography>
      </Stack>
      {hasError && (
        <Typography color={colors.red} fontSize={12}>
          {t("error.invalidCharacters")}
        </Typography>
      )}
      <ButtonBox
        onClickBack={onBackHandler}
        onClickNext={onSubmitHandler}
        disabled={isDisabled}
        isLoading={isLoading}
      />
    </Stack>
  );
};

export default CompanyName;
