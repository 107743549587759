import { Stack, Typography, Button } from "@mui/material";
import { Member } from "graphql/__generated__/types";
import { colors } from "utils";

type MemberProps = {
  member: Member;
  onClickRemove: () => void;
  onClickEdit: () => void;
};

const MemberCard = ({ member, onClickRemove, onClickEdit }: MemberProps) => {
  return (
    <Stack spacing={3} direction="row" justifyContent="space-between">
      <Stack>
        <Typography variant="h5">{member.contactFullName}</Typography>
        <Typography>SSN 🇺🇸: {member.ssn ? "Provided" : " N/A"}</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Button
          variant="contained"
          sx={{
            height: 36,
            borderRadius: "30px",
            bgcolor: colors.grey,
            color: colors.red,
            "&:hover": {
              bgcolor: colors.darkGrey,
            },
          }}
          disableElevation
          onClick={() => onClickRemove()}
        >
          Remove
        </Button>
        <Button
          disableElevation
          variant="contained"
          sx={{
            height: 36,
            borderRadius: "30px",
            bgcolor: colors.grey,
            color: colors.black,
            "&:hover": {
              bgcolor: colors.darkGrey,
            },
          }}
          onClick={() => onClickEdit()}
        >
          Edit
        </Button>
      </Stack>
    </Stack>
  );
};

export default MemberCard;
