import { useEffect, useState } from "react";
import { Stack, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import utils, { colors } from "utils";
import useCompany from "graphql/hooks/UseCompany";
import { useUpdateCompanyDescriptionMutation } from "graphql/__generated__/operations/UpdateCompanyDescription.generated";
import ButtonBox from "components/ButtonBox";
import { t } from "i18next";
import { Windows1252Regex } from "utils/regexValidations";
import { usePostHog } from "posthog-js/react";
import { EntityType } from "graphql/__generated__/types";

const OnboardingDescription = () => {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { currentCompany } = useCompany();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [businessDescription, setBusinessDescription] = useState<string>("");
  const [hasError, setHasError] = useState<boolean>(false);

  const [updateCompanyDescription] = useUpdateCompanyDescriptionMutation();

  useEffect(() => {
    setBusinessDescription(currentCompany?.description || "");
  }, [currentCompany?.description]);

  const isDisabled =
    !businessDescription || businessDescription.length > 60 || hasError;

  const onClickNextHandler = async () => {
    setIsLoading(true);

    if (currentCompany?.id) {
      try {
        await updateCompanyDescription({
          variables: {
            companyId: currentCompany?.id,
            description: businessDescription,
          },
        });
      } catch {
        setIsLoading(false);
      }
    }

    posthog?.capture("company-description-set", {
      length: businessDescription.length,
    });

    if (currentCompany.entityType === EntityType.CCORP) {
      navigate(ROUTER_PATHS.ONBOARDING_EXECUTIVE);
    } else {
      navigate(ROUTER_PATHS.ONBOARDING_MEMBERS);
    }
  };

  return (
    <Stack direction="column" gap={3}>
      <Typography variant="h1">Tell us about your business</Typography>
      <Stack>
        <Typography
          variant="h6"
          pt={3}
          pb={1}
          color={utils.secondaryColors.secondaryMain}
        >
          Business Description
        </Typography>
        <Stack>
          <TextField
            placeholder="Enter Business Description"
            fullWidth
            value={businessDescription}
            onChange={(e) => {
              const inputValue = e.target.value;

              setBusinessDescription(inputValue);
              if (inputValue) {
                const isValid = Windows1252Regex.test(inputValue);
                setHasError(!isValid);
              } else {
                setHasError(false);
              }
            }}
            multiline
            minRows={3}
            error={hasError}
            data-testid="OnboardingDescriptionTextarea"
          />
          <Typography
            variant="h6"
            pt={1}
            textAlign="right"
            pb={1}
            color={
              businessDescription.length > 54 && businessDescription.length < 61
                ? colors.yellow
                : businessDescription.length > 60
                ? colors.red
                : utils.secondaryColors.secondaryMain
            }
          >
            {businessDescription ? businessDescription.length : 0} / 60
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems={"center"} gap={2}>
        <InfoIcon />
        <Typography>
          This is also shared with the IRS for the EIN. This short description
          should indicate the type of merchandise sold, products produced, or
          services provided.
        </Typography>
      </Stack>
      {hasError && (
        <Typography color={colors.red} fontSize={12}>
          {t("error.invalidCharacters")}
        </Typography>
      )}
      <ButtonBox
        isLoading={isLoading}
        disabled={isDisabled}
        onClickBack={() => navigate(ROUTER_PATHS.ONBOARDING_INDUSTRY)}
        onClickNext={onClickNextHandler}
      />
    </Stack>
  );
};

export default OnboardingDescription;
