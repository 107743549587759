export const splitNumberIntoParts = (number: number) => {
  const numberString = number.toString();
  const [dollars, cents = "00"] = numberString.split(".");

  // Ensure the cents part has exactly two digits
  const paddedCents = cents.padEnd(2, "0").slice(0, 2);

  return {
    dollars: +dollars,
    cents: paddedCents,
  };
};
