import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMoneyCurrencyFeeInfoQueryVariables = Types.Exact<{
  currencyCode: Types.Scalars['String'];
}>;


export type GetMoneyCurrencyFeeInfoQuery = { __typename?: 'Query', currencyFeeInfo?: { __typename?: 'FeeInfoResponse', error?: string | null, payload?: { __typename?: 'FeeInfo', currencyCode?: string | null, destination?: string | null, doolaRate?: number | null, niumRate?: number | null } | null } | null };


export const GetMoneyCurrencyFeeInfoDocument = gql`
    query GetMoneyCurrencyFeeInfo($currencyCode: String!) {
  currencyFeeInfo(currencyCode: $currencyCode) {
    error
    payload {
      currencyCode
      destination
      doolaRate
      niumRate
    }
  }
}
    `;

/**
 * __useGetMoneyCurrencyFeeInfoQuery__
 *
 * To run a query within a React component, call `useGetMoneyCurrencyFeeInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoneyCurrencyFeeInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoneyCurrencyFeeInfoQuery({
 *   variables: {
 *      currencyCode: // value for 'currencyCode'
 *   },
 * });
 */
export function useGetMoneyCurrencyFeeInfoQuery(baseOptions: Apollo.QueryHookOptions<GetMoneyCurrencyFeeInfoQuery, GetMoneyCurrencyFeeInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMoneyCurrencyFeeInfoQuery, GetMoneyCurrencyFeeInfoQueryVariables>(GetMoneyCurrencyFeeInfoDocument, options);
      }
export function useGetMoneyCurrencyFeeInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMoneyCurrencyFeeInfoQuery, GetMoneyCurrencyFeeInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMoneyCurrencyFeeInfoQuery, GetMoneyCurrencyFeeInfoQueryVariables>(GetMoneyCurrencyFeeInfoDocument, options);
        }
export type GetMoneyCurrencyFeeInfoQueryHookResult = ReturnType<typeof useGetMoneyCurrencyFeeInfoQuery>;
export type GetMoneyCurrencyFeeInfoLazyQueryHookResult = ReturnType<typeof useGetMoneyCurrencyFeeInfoLazyQuery>;
export type GetMoneyCurrencyFeeInfoQueryResult = Apollo.QueryResult<GetMoneyCurrencyFeeInfoQuery, GetMoneyCurrencyFeeInfoQueryVariables>;