import { AccountType } from "graphql/__generated__/types";

export type BeneficiaryInformationDetails = {
  beneficiaryAccountType: AccountType;
  beneficiaryName: string;
  beneficiaryAddress: string;
  beneficiaryCountryCode: string;
  beneficiaryCity: string;
  beneficiaryState: string;
  beneficiaryPostcode: string;
};

export const INITIAL_BENEFICIARY_INFORMATION_VALUES: BeneficiaryInformationDetails =
  {
    beneficiaryAccountType: AccountType.INDIVIDUAL,
    beneficiaryName: "",
    beneficiaryAddress: "",
    beneficiaryCountryCode: "",
    beneficiaryCity: "",
    beneficiaryState: "",
    beneficiaryPostcode: "",
  };
