import { Box, Card, Stack, Typography } from "@mui/material";
import { ProductDuration } from "graphql/__generated__/types";
import { colors } from "utils";
import { moneyFormatter } from "utils/formatters";

interface IPopperCard {
  currentPlanDuration: ProductDuration;
  duration: ProductDuration;
  price?: number;
  onDurationChange: (duration: ProductDuration) => void;
}

export const PopperCard = ({
  currentPlanDuration,
  duration,
  price,
  onDurationChange,
}: IPopperCard) => {
  const isAnnualDuration = duration === ProductDuration.ANNUAL;

  return (
    <Card
      onClick={() => onDurationChange(duration)}
      sx={{
        background:
          currentPlanDuration === duration ? colors.lightGrey : colors.white,
        borderRadius: 6,
        p: 3,
        boxShadow: "none",
        cursor: "pointer",
        transition: "all .3s ease-out",
        "&:hover": {
          bgcolor: colors.grey,
        },
      }}
      data-testid={`prePurchaseDuration${duration}`}
    >
      <Stack gap={1}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Box>
            <Typography fontWeight={500}>
              {isAnnualDuration ? "Annual" : "Monthly"} Subscription
            </Typography>
            <Typography>
              billed once {isAnnualDuration ? "per year" : "a month"}
            </Typography>
          </Box>
        </Stack>
        <Stack direction={"row"} alignItems={"flex-end"} spacing={0.5}>
          {price && (
            <Typography variant="h2" fontSize={28}>
              {moneyFormatter(price)}
            </Typography>
          )}
          <Typography variant="body1" color={colors.contentTertiary}>
            /{isAnnualDuration ? "yr" : "month"}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};
