import styled from "styled-components";

type WrapperToolTipType = {
  shortVersion?: boolean;
};

export const StyledWrapperToolTip = styled.div<WrapperToolTipType>`
  position: relative;
  // border: 1px solid;
  overflow: hidden;
  max-width: 330px;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props.shortVersion ? 2 : 6)};
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  margin-bottom: 20px;
  margin: auto;
`;

export const ReadMoreWrapperStyled = styled.div`
  cursor: pointer;
  float: right;
`;
