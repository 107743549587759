import AuthGuard from "utils/route-guard/AuthGuard";
import Onboarding from "views/DashboardApp/Onboarding/Onboarding";
import OnboardingLayout from "views/DashboardApp/Onboarding/OnboardingLayout";
import { ROUTER_PATHS } from "./routes";

const OnboardingRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <AuthGuard>
          <OnboardingLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: ROUTER_PATHS.ONBOARDING_INTRO,
          element: <Onboarding />,
        },
        {
          path: ROUTER_PATHS.ONBOARDING_COMPANY_NAME,
          element: <Onboarding />,
        },
        {
          path: ROUTER_PATHS.ONBOARDING_ADDRESS,
          element: <Onboarding />,
        },
        {
          path: ROUTER_PATHS.ONBOARDING_INDUSTRY,
          element: <Onboarding />,
        },
        {
          path: ROUTER_PATHS.ONBOARDING_DESCRIPTION,
          element: <Onboarding />,
        },
        {
          path: ROUTER_PATHS.ONBOARDING_MEMBERS,
          element: <Onboarding />,
        },
        {
          path: ROUTER_PATHS.ONBOARDING_RESPONSIBLE_PARTY,
          element: <Onboarding />,
        },
        {
          path: ROUTER_PATHS.ONBOARDING_OWNERSHIP,
          element: <Onboarding />,
        },
        {
          path: ROUTER_PATHS.ONBOARDING_REVIEW,
          element: <Onboarding />,
        },
        {
          path: ROUTER_PATHS.ONBOARDING_EXECUTIVE,
          element: <Onboarding />,
        },
        {
          path: ROUTER_PATHS.ONBOARDING_SHARE,
          element: <Onboarding />,
        },
      ],
    },
  ],
};

export default OnboardingRoutes;
