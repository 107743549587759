import { ROUTER_PATHS } from "routes/routes";
import { GuardProps } from "./types";
import { Navigate, useLocation } from "react-router-dom";
import { getTokens } from "utils/auth";
import useSignupFlow from "graphql/hooks/UseSignupFlow";

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { hasDataLoaded, hasServices } = useSignupFlow();
  const location = useLocation();

  const { idToken } = getTokens();

  if (idToken && !hasDataLoaded) {
    return null;
  }

  if (!idToken || !hasServices) {
    return <Navigate to={ROUTER_PATHS.LOGIN} state={{ from: location }} />;
  }

  return children;
};

export default AuthGuard;
