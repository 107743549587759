import { IQuestionProps } from "./types";
import {
  InputBase,
  Slider,
  SliderThumb,
  Typography,
  useMediaQuery,
} from "@mui/material";
import utils, { colors } from "utils";
import { useCallback, useEffect } from "react";
import { Stack } from "@mui/material";
import ButtonBox from "components/ButtonBox";
import { Controller, useForm } from "react-hook-form";
import { Windows1252Regex } from "utils/regexValidations";
import { t } from "i18next";
import { UserSurveyAnswerInput } from "graphql/__generated__/types";

export const Range = ({
  currentStep,
  question,
  setStep,
  isLastStep,
  savedQuestionData,
  submitStepData,
  isLoading,
}: IQuestionProps) => {
  const { control, trigger, setValue, watch, formState, handleSubmit } =
    useForm<UserSurveyAnswerInput>({
      defaultValues: {
        doolaUserSurveyQuestionId: question?.id ?? "",
        questionType: question?.type ?? "",
        answerText: "",
        rangeValue: "",
      },
      mode: "onTouched",
    });
  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  const rangeValue = watch("rangeValue");

  const onResponseChange = useCallback(
    (range: string) => {
      setValue("rangeValue", range);
    },
    [setValue]
  );

  useEffect(() => {
    if (question?.id && question?.type) {
      if (savedQuestionData) {
        const { answerText, rangeValue } = savedQuestionData;

        setValue("answerText", answerText);
        setValue("rangeValue", rangeValue);
      } else {
        onResponseChange(String((question.end ?? 10) - 2));
        setValue("answerText", "");
      }

      trigger();
      setValue("doolaUserSurveyQuestionId", question.id);
      setValue("questionType", question.type);
    }
  }, [question, setValue, onResponseChange, trigger, savedQuestionData]);

  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);
  const isNotFirstStep = currentStep !== 0;

  return (
    <Stack gap={5}>
      <Typography variant="h3" fontSize={isMobile ? "24px" : "28px"}>
        {question?.question}
      </Typography>
      <Stack>
        <Controller
          name={"doolaUserSurveyQuestionId"}
          {...COMMON_PROPS}
          rules={{
            required: true,
          }}
          render={() => (
            <>
              <Slider
                value={+(rangeValue ?? 0)}
                defaultValue={question?.end ?? 10}
                color="secondary"
                onChange={(_e: Event, newValue: number | number[]) =>
                  onResponseChange(String(newValue))
                }
                size="medium"
                sx={{
                  height: "12px",
                  color: colors.blue,
                  "& .MuiSlider-thumb": {
                    height: "40px",
                    width: "40px",
                  },
                  "& .MuiSlider-rail": {
                    opacity: 1,
                    backgroundColor: colors.lightGrey,
                  },
                }}
                slots={{
                  thumb: (props) => (
                    <SliderThumb {...props}>
                      {props.children}
                      <Typography fontSize={"18px"} color={colors.white}>
                        {+(rangeValue ?? 0)}
                      </Typography>
                    </SliderThumb>
                  ),
                }}
                step={1}
                min={question?.start ?? 0}
                max={question?.end ?? 10}
              />
            </>
          )}
        />
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography color={colors.contentTertiary}>
            <span style={{ color: colors.black }}>{question?.start ?? 0}</span>{" "}
            - Not likely
          </Typography>
          <Typography color={colors.contentTertiary}>
            Highly likely -{" "}
            <span style={{ color: colors.black }}>{question?.end ?? 10}</span>
          </Typography>
        </Stack>
      </Stack>
      <Stack>
        <Controller
          name={"answerText"}
          {...COMMON_PROPS}
          rules={{
            pattern: {
              value: Windows1252Regex,
              message: t("error.invalidCharacters"),
            },
          }}
          render={({ field: { ref, ...field } }) => (
            <InputBase
              {...field}
              fullWidth
              minRows={5}
              multiline
              placeholder="Type your answer here..."
              inputProps={{
                style: {
                  background: colors.grey,
                  border: "none",
                  padding: "1rem",
                  borderRadius: "1rem",
                },
              }}
            />
          )}
        />
        <Typography fontSize={"12px"} px={2} color={colors.contentSecondary}>
          Explain your choice (Optional)
        </Typography>
      </Stack>
      <ButtonBox
        isLoading={isLoading}
        disabled={!formState.isValid}
        {...(isLastStep && {
          rightButtonText: "Submit",
        })}
        showBackButton={false}
        {...(isNotFirstStep && {
          showBackButton: true,
          onClickBack: () => setStep((prevStep) => prevStep - 1),
        })}
        onClickNext={handleSubmit(submitStepData)}
      />
    </Stack>
  );
};
