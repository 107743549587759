import ButtonBox from "components/ButtonBox";
import { IStepProps } from "../../types";
import { TaxFilingStepsCategories, TaxFilingStepsData } from "../../data";
import { Dispatch, SetStateAction, useState } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import {
  TAX_FILING_BOOKMATE_CALENDLY_LINK,
  TAX_FILING_CALENDLY_LINK,
  TAX_FILING_CONSULTATION,
} from "views/DashboardApp/Taxes/constants";
import { Stack } from "@mui/material";
import useCustomer from "graphql/hooks/useCustomer";
import useCompany from "graphql/hooks/UseCompany";
import { LoadingComponent } from "components/Loading";
import { usePostHog } from "posthog-js/react";

interface IBookConsultationProps extends IStepProps {
  isInHouseCustomer: boolean;
  setIsFinalStep: Dispatch<SetStateAction<boolean>>;
}

const BookConsultation = ({
  isInHouseCustomer,
  setIsFinalStep,
  setCurrentStep,
}: IBookConsultationProps) => {
  const posthog = usePostHog();
  const { currentUser } = useCustomer();
  const { currentCompany, refetchCompany } = useCompany();

  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useCalendlyEventListener({
    onEventScheduled: () => {
      setIsDisabled(false);

      posthog?.capture(`tax_modal_consultation_is_scheduled`);
    },
  });

  return (
    <Stack mt={3} gap={4}>
      {currentUser && currentCompany ? (
        <InlineWidget
          styles={{
            height: "500px",
          }}
          url={
            isInHouseCustomer
              ? TAX_FILING_CALENDLY_LINK
              : TAX_FILING_BOOKMATE_CALENDLY_LINK
          }
          prefill={{
            email: currentUser?.email ?? "",
            firstName: currentUser?.firstName ?? "",
            lastName: currentUser?.lastName ?? "",
            name: currentUser?.name ?? "",
            smsReminderNumber: currentUser?.phoneNumber ?? "",
            customAnswers: {
              a1: "",
            },
          }}
          utm={{
            // to connect event with the companyId
            utmSource: "companyId",
            utmCampaign: currentCompany?.id ?? "",
            // to detect either event CPA or TAX
            utmTerm: TAX_FILING_CONSULTATION,
          }}
        />
      ) : (
        <LoadingComponent />
      )}
      <ButtonBox
        disabled={isDisabled}
        showBackButton={isDisabled}
        onClickBack={() =>
          setCurrentStep(
            TaxFilingStepsData[
              isInHouseCustomer
                ? TaxFilingStepsCategories.taxDocuments
                : TaxFilingStepsCategories.prepareInfo
            ]
          )
        }
        onClickNext={() => {
          setIsFinalStep(true);

          refetchCompany();

          posthog?.capture(`tax_modal_book_consultation_next_clicked`);
        }}
      />
    </Stack>
  );
};

export default BookConsultation;
