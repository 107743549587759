import {
  Box,
  Button,
  Divider,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { IntercomTriggerWrapper } from "components/IntercomTriggerWrapper";
import { LinkExternalDomain } from "components/LinkExternalDomain";
import { LoadingComponent } from "components/Loading";
import { Logo } from "components/Logos";
import config from "config";
import { useAuthSignOutMutation } from "graphql/__generated__/operations/AuthSignOut.generated";
import { useGetStripeBillingPortalLazyQuery } from "graphql/__generated__/operations/StripeBillingPortal.generated";
import { DashboardStatus } from "graphql/__generated__/types";
import useCompany from "graphql/hooks/UseCompany";
import useCustomer from "graphql/hooks/useCustomer";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import utils, { colors } from "utils";
import { removeTokens } from "utils/auth";
import CompanySelector from "../Sidebar/SidebarContent/UserControlHub/CompanySelector";

const RETURN_URL = `${config.basePath}${ROUTER_PATHS.DASHBOARD}`;

const DashboardAccessBlocker = () => {
  const navigate = useNavigate();
  const { currentCompany, isCompanyLoading } = useCompany();
  const { currentUser } = useCustomer();
  const [authSignOut] = useAuthSignOutMutation();

  const [isLoading, setIsLoading] = useState(false);

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const [getStripeBillingPortalData, { data }] =
    useGetStripeBillingPortalLazyQuery();

  useEffect(() => {
    if (currentUser?.stripeCustomerId) {
      getStripeBillingPortalData({
        variables: {
          customerId: currentUser?.id || "",
          returnUrl: RETURN_URL,
        },
      });
    }
  }, [
    currentUser?.id,
    currentUser?.stripeCustomerId,
    getStripeBillingPortalData,
  ]);

  const stripeCustomerPortalLink =
    data?.customer?.stripeBillingPortalMeta?.url || "";

  if (isCompanyLoading) {
    return <LoadingComponent />;
  }

  const handleLogout = async () => {
    setIsLoading(true);

    try {
      await authSignOut();
    } catch {
      // error handling delegated upstream to the ErrorLink
    } finally {
      removeTokens();
      navigate(ROUTER_PATHS.LOGIN);

      setIsLoading(true);
    }
  };

  const dashboardAccess = currentCompany?.dashboardStatus;
  const isAccessBilling =
    dashboardAccess === DashboardStatus.CANCELLED ||
    dashboardAccess === DashboardStatus.PASTDUE;

  return (
    <Box
      position={"absolute"}
      top={"50%"}
      left={"50%"}
      {...(!isTablet && { transform: "translate(-50%, -50%)" })}
      {...(isTablet && { my: 3 })}
      width={isTablet ? "100%" : "500px"}
      height={isTablet ? "100%" : "auto"}
      bgcolor={isTablet ? colors.white : colors.lightGrey}
      borderRadius={4}
      sx={{ transform: "translate(-50%, -50%)" }}
    >
      <Stack p={4} alignItems={"center"}>
        <Logo width={150} />
        <CompanySelector canAddCompany={false} />
        <Box mt={4}>
          <Typography variant="body1">
            <b>Limited Dashboard Access:</b> Your dashboard access is currently
            restricted. To learn more about your access, you can{" "}
            <IntercomTriggerWrapper>
              <Typography
                component={"span"}
                color={colors.blue}
                fontWeight={500}
              >
                contact us
              </Typography>
            </IntercomTriggerWrapper>{" "}
            for assistance."
          </Typography>
          {isAccessBilling && stripeCustomerPortalLink && (
            <>
              <Divider
                sx={{ borderColor: colors.contentInverseTertiary, my: 2 }}
              />
              <Stack gap={2}>
                <Typography variant="body1">
                  Your access to the dashboard is currently restricted due to an
                  overdue payment. To regain full dashboard access, please click
                  the <b>"Billing Portal"</b> button below. You will be directed
                  to your billing portal, where you can update your payment
                  information.
                </Typography>
                <LinkExternalDomain to={stripeCustomerPortalLink}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      borderRadius: 8,
                      py: 1.5,
                      px: 3,
                      boxShadow: "none",
                      "&:hover": {
                        bgcolor: colors.charcoal,
                        boxShadow: "none",
                      },
                    }}
                  >
                    Billing portal
                  </Button>
                </LinkExternalDomain>
              </Stack>
            </>
          )}
          <Stack alignItems={"center"} mt={4}>
            {isLoading ? (
              <LoadingComponent />
            ) : (
              <Link
                component={"button"}
                onClick={handleLogout}
                underline="none"
              >
                <Typography component={"span"} fontWeight={500}>
                  Logout
                </Typography>
              </Link>
            )}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default DashboardAccessBlocker;
