import { Typography } from "@mui/material";
import { IntercomTriggerWrapper } from "components/IntercomTriggerWrapper";
import { LinkExternalDomain } from "components/LinkExternalDomain";
import { colors } from "utils";

const HowDoIApplyToMyBankAccountContent: React.FC = () => (
  <Typography variant="body1" color={colors.black}>
    Step one is forming your company! Once that is complete you need to wait for
    your EIN (Employer Identification Number) from the IRS (Internal Revenue
    Service). Once this is complete you can apply for your doola bank account
    and you'll find links on this page to apply after your EIN arrives.
  </Typography>
);

const WhereCanISetupContent: React.FC = () => (
  <Typography variant="body1" color={colors.black}>
    Once you have your US Business Bank Account simply go to www.stripe.com and
    you'll be able to set up Stripe in a few minutes!
  </Typography>
);

const WhatCanIDoWithMyUsStripeContent: React.FC = () => (
  <Typography variant="body1" color={colors.black}>
    Stripe's APIs and functionality help you create amazing products for
    customers globally. Stripe supports payments in over 100 currencies for US
    payment methods and accounts and is used around the world. You can learn
    more about how businesses use Stripe in the link!
  </Typography>
);

const AnyQuestionsContent: React.FC = () => (
  <>
    <Typography variant="body1" color={colors.black}>
      If you cannot find an answer to your question, please make sure to check
      our{" "}
      <LinkExternalDomain to="http://help.doola.com/">
        <Typography component={"span"} color={colors.blue} fontWeight={500}>
          extended FAQs
        </Typography>
      </LinkExternalDomain>{" "}
      or{" "}
      <IntercomTriggerWrapper>
        <Typography component={"span"} color={colors.blue} fontWeight={500}>
          contact us live
        </Typography>
      </IntercomTriggerWrapper>
      .
    </Typography>
  </>
);

export const FAQ_DATA = [
  {
    question: "How do I apply for my bank account? When can I apply?",
    answer: <HowDoIApplyToMyBankAccountContent />,
    readMorelink:
      "https://www.doola.com/blog/how-to-open-a-us-business-bank-account?7c2d7ff0_page=2",
  },
  {
    question: "When can I set up my account to accept payments with Stripe?",
    answer: <WhereCanISetupContent />,
    readMorelink:
      "https://www.doola.com/blog/how-to-create-a-us-stripe-account",
  },
  {
    question: "What can I do with my US Stripe Account?",
    answer: <WhatCanIDoWithMyUsStripeContent />,
    readMorelink: "https://stripe.com/customers",
  },
  {
    question: "Do you have any further questions?",
    answer: <AnyQuestionsContent />,
  },
];
