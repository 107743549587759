import { styled } from "@mui/material/styles";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { colors } from "utils";

interface IconProps {
  iconBgColor?: string;
  iconbgcolorchecked?: string; // use lowercase to remove emotion/styled warning
  iconBorderColor?: string;
}

interface CustomCheckboxProps extends CheckboxProps, IconProps {}

const Icon = styled("span")<IconProps>(
  ({ theme, iconBgColor, iconBorderColor }) => ({
    borderRadius: 5,
    width: 16,
    height: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `2px solid ${iconBorderColor || colors.contentInverseSecondary}`,
    backgroundColor:
      iconBgColor || (theme.palette.mode === "dark" ? "#394b59" : colors.white),
    ".Mui-focusVisible &": {
      outline: `2px auto ${colors.blue}`,
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background:
        theme.palette.mode === "dark"
          ? "rgba(57,75,89,.5)"
          : "rgba(206,217,224,.5)",
    },
  })
);

const CheckedIcon = styled(Icon)(({ iconbgcolorchecked }) => ({
  backgroundColor: iconbgcolorchecked || colors.blue,
  border: "none",
  "&:before": {
    width: 14,
    height: 14,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: iconbgcolorchecked || colors.blue,
  },
}));

const StyledCheckbox = ({
  iconBorderColor,
  iconBgColor,
  iconbgcolorchecked,
  ...props
}: CustomCheckboxProps) => {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<CheckedIcon iconbgcolorchecked={iconbgcolorchecked} />}
      icon={
        <Icon iconBgColor={iconBgColor} iconBorderColor={iconBorderColor} />
      }
      inputProps={{ "aria-label": "Checkbox" }}
      {...props}
    />
  );
};

export default StyledCheckbox;
