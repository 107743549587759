import { IStepProps } from "../../types";
import ButtonBox from "components/ButtonBox";
import { TransferSteps } from "../../data";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import utils, { colors } from "utils";
import {
  TransferMethodEnum,
  USD_CURRENCY_KEY,
  getTransferMethod,
  maskSensitiveData,
} from "views/DashboardApp/Money/helpers";
import useGetMoneyAccounts from "views/DashboardApp/Money/hooks/useGetMoneyAccounts";
import useGetBeneficiary from "views/DashboardApp/Money/hooks/useGetBeneficiary";
import useGetExchangeRate from "views/DashboardApp/Money/hooks/useGetExchangeRate";
import { useMoneyTransferMutation } from "graphql/__generated__/operations/MoneyTransfer.generated";
import { LoadingComponent } from "components/Loading";
import {
  AccountBalanceOutlined,
  AccountBalanceWalletOutlined,
  ArrowForwardOutlined,
  FactCheckOutlined,
  Person2Outlined,
  SendOutlined,
  TollOutlined,
} from "@mui/icons-material";
import Avatar from "components/@extended/Avatar";
import { moneyFormatter } from "utils/formatters";
import { getPercentAmount, increaseByPercent } from "../../helpers";
import { MemoizedAvatar } from "views/DashboardApp/Money/components/Avatar";
import { PayoutMethodType } from "graphql/__generated__/types";
import { toast } from "toast";
import useCompany from "graphql/hooks/UseCompany";
import useGetTransactions from "views/DashboardApp/Money/hooks/useGetTransactions";
import useGetCurrencyFeeInfo from "views/DashboardApp/Money/hooks/useGetCurrencyFeeInfo";
import { usePostHog } from "posthog-js/react";
import AlertNotification from "components/AlertNotification";

interface IReviewProps extends IStepProps {
  setIsFinalStep: Dispatch<SetStateAction<boolean>>;
}

const Review = ({
  setIsFinalStep,
  setCurrentStep,
  transferData,
}: IReviewProps) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const posthog = usePostHog();

  const [isLoading, setIsLoading] = useState(false);

  const [transferMoney] = useMoneyTransferMutation();

  const { currentCompany } = useCompany();
  const { accountsData, refetchAccounts, isAccountsDataLoading } =
    useGetMoneyAccounts();
  const { refetchTransactions } = useGetTransactions();
  const { getBeneficiary, isBeneficiaryLoading, beneficiary } =
    useGetBeneficiary();
  const { getExchangeRate, isExchangeRateDataLoading, exchangeRateData } =
    useGetExchangeRate();
  const {
    getCurrencyFeeInfo,
    isCurrencyFeeInfoDataLoading,
    currencyFeeInfoData,
  } = useGetCurrencyFeeInfo();

  const USDBalance = useMemo(
    () =>
      accountsData?.balances?.find(
        (balance) => balance?.curSymbol === USD_CURRENCY_KEY
      )?.balance || 0,
    [accountsData?.balances]
  );

  const accountNumber = maskSensitiveData(accountsData?.uniquePaymentId ?? "");

  const tranferMethod = useMemo(
    () =>
      beneficiary &&
      getTransferMethod({
        payoutMethod: beneficiary.payoutMethod || "",
        destinationCountry: beneficiary.destinationCountry || "",
        routingCodeType1: beneficiary.routingCodeType1 || "",
      }),
    [beneficiary]
  );

  const destinationCurrency = beneficiary && beneficiary.destinationCurrency;

  const exchangeRate = useMemo(
    () => +(exchangeRateData?.fx_rate ?? ""),
    [exchangeRateData?.fx_rate]
  );

  const isInternationalWire =
    tranferMethod === TransferMethodEnum.INTERNATIONAL_WIRE;

  const isSWIFT =
    beneficiary && beneficiary.payoutMethod === PayoutMethodType.SWIFT;

  const amount = transferData.amount;

  const localAmount =
    isInternationalWire && transferData.localAmount
      ? transferData.localAmount
      : amount;

  const totalMarkup =
    (currencyFeeInfoData?.doolaRate ?? 0) +
    (currencyFeeInfoData?.niumRate ?? 0);

  const finalAmount = isInternationalWire
    ? increaseByPercent(+amount, totalMarkup).toFixed(2)
    : amount;

  const isUSDSelected = useMemo(
    () => destinationCurrency === USD_CURRENCY_KEY,
    [destinationCurrency]
  );

  useEffect(() => {
    if (isInternationalWire && destinationCurrency && !isUSDSelected) {
      getExchangeRate(destinationCurrency, true);
      getCurrencyFeeInfo(destinationCurrency);
    }
  }, [
    destinationCurrency,
    getCurrencyFeeInfo,
    getExchangeRate,
    isInternationalWire,
    isUSDSelected,
  ]);

  useEffect(() => {
    const beneficiaryId = searchParams.get("recipient");

    if (beneficiaryId) {
      getBeneficiary(beneficiaryId);
    }
  }, [getBeneficiary, searchParams]);

  const isComponentLoading =
    isLoading ||
    isBeneficiaryLoading ||
    isAccountsDataLoading ||
    (isInternationalWire && isExchangeRateDataLoading) ||
    isCurrencyFeeInfoDataLoading;

  if (isComponentLoading) return <LoadingComponent />;

  const isDisabled = isComponentLoading;

  const onSubmitHandler = async () => {
    setIsLoading(true);

    const {
      localAmount: transferDataLocalAmount,
      ...transferDataWithoutLocalAmount
    } = transferData;

    try {
      const data = await transferMoney({
        variables: {
          doolaCompanyId: currentCompany?.id || "",
          transferRequest: {
            ...transferDataWithoutLocalAmount,
            amount: localAmount,
            doolaRate: currencyFeeInfoData?.doolaRate?.toString(),
          },
        },
      });

      const errors = data?.data?.transfer?.error;

      if (errors) {
        toast.error(errors);
        return;
      }

      posthog?.capture("money_transfer_flow_send_payment_btn_click");

      refetchAccounts();
      refetchTransactions();

      setIsFinalStep(true);
    } catch (error) {
      console.log(`Error while money transfer : ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack gap={5} mb={isTablet ? 9 : 0}>
      <Stack gap={4}>
        <Stack direction={"row"} alignItems={"center"} gap={1.5}>
          <FactCheckOutlined sx={{ fontSize: "20px" }} />
          <Typography fontSize={16} fontWeight={500}>
            {t("money.transfer.step.review.review")}
          </Typography>
        </Stack>
        {isInternationalWire && (
          <AlertNotification
            style={{ flexDirection: "row" }}
            body={t("money.transfer.estimatedAmounts")}
          />
        )}
        <Stack direction={isTablet ? "column" : "row"} gap={isTablet ? 2 : 3}>
          <Stack
            gap={1}
            sx={{
              bgcolor: colors.lightAccentGreen,
              width: "100%",
              p: 3,
              borderRadius: "20px",
            }}
          >
            <Stack gap={2} alignItems={"flex-start"}>
              <Stack direction={"row"} gap={1.5}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={1.5}
                  sx={{
                    bgcolor: colors.darkAccentGreen,
                    py: 1,
                    px: 2,
                    borderRadius: 1.5,
                    overflow: "hidden",
                  }}
                >
                  <SendOutlined />
                  <Typography fontSize={16} fontWeight={500} noWrap>
                    {tranferMethod}
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={1.5}
                  sx={{
                    bgcolor: colors.darkAccentGreen,
                    py: 1,
                    px: 2,
                    borderRadius: 1.5,
                  }}
                >
                  <TollOutlined />
                  <Typography fontSize={16} fontWeight={500}>
                    {destinationCurrency}
                  </Typography>
                </Stack>
              </Stack>
              <Stack>
                <Typography fontSize={16}>
                  {t("money.transfer.step.review.youSend")}
                </Typography>
                <Typography fontSize={"32px"} fontWeight={700}>
                  {moneyFormatter(
                    +localAmount,
                    false,
                    false,
                    destinationCurrency ?? ""
                  )}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          {isInternationalWire ? (
            <Stack
              justifyContent={"space-between"}
              sx={{
                bgcolor: colors.lightGrey,
                width: "100%",
                p: 3,
                borderRadius: "20px",
              }}
            >
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography color={colors.contentTertiary}>
                  {t("money.transfer.rate")}
                </Typography>
                <Typography color={colors.contentTertiary}>
                  {`1 USD = ${exchangeRate.toFixed(2)} ${destinationCurrency}`}
                </Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography color={colors.contentTertiary}>
                  {t("money.transfer.youSend")}
                </Typography>
                <Typography color={colors.contentTertiary}>
                  {moneyFormatter(+amount, false)}
                </Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography color={colors.contentTertiary}>
                  {t("money.transfer.transferFee", {
                    feeValue: totalMarkup.toFixed(2),
                  })}
                </Typography>
                <Typography color={colors.contentTertiary}>
                  {moneyFormatter(
                    getPercentAmount(+amount, totalMarkup),
                    false
                  )}
                </Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography fontSize={16}>
                  {t("money.transfer.yourTotal")}
                </Typography>
                <Typography fontSize={16} fontWeight={500}>
                  {moneyFormatter(
                    increaseByPercent(+amount, totalMarkup),
                    false
                  )}
                </Typography>
              </Stack>
            </Stack>
          ) : (
            <Stack width={"100%"}></Stack>
          )}
        </Stack>
      </Stack>
      <Stack gap={4}>
        <Stack direction={"row"} alignItems={"center"} gap={1.5}>
          <SendOutlined sx={{ fontSize: "20px" }} />
          <Typography fontSize={16} fontWeight={500}>
            {t("money.transfer.step.review.sender")}
          </Typography>
        </Stack>
        <Stack direction={isTablet ? "column" : "row"} gap={isTablet ? 2 : 3}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={2}
            borderRadius={"20px"}
            p={3}
            bgcolor={colors.lightGrey}
            width={"100%"}
            overflow={"hidden"}
          >
            <Avatar
              sx={{
                backgroundColor: colors.lightAccentYellow,
                color: colors.black,
                width: "48px",
                height: "48px",
              }}
            >
              <AccountBalanceWalletOutlined />
            </Avatar>
            <Stack gap={1} overflow={"hidden"}>
              <Typography fontSize={16} fontWeight={500} noWrap>
                {accountsData?.accountName}
              </Typography>
              <Typography fontSize={16} fontWeight={500} noWrap>
                {accountNumber}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={2}
            borderRadius={"20px"}
            p={3}
            bgcolor={colors.lightAccentYellow}
            width={"100%"}
            overflow={"hidden"}
          >
            <Stack gap={1} overflow={"hidden"}>
              <Typography fontSize={16} fontWeight={500} noWrap>
                {t("money.transfer.step.review.before")}
              </Typography>
              <Typography fontSize={16} fontWeight={500} noWrap>
                {moneyFormatter(USDBalance, false)}
              </Typography>
            </Stack>
            <ArrowForwardOutlined />
            <Stack gap={1} overflow={"hidden"}>
              <Typography fontSize={16} fontWeight={500} noWrap>
                {t("money.transfer.step.review.After")}
              </Typography>
              <Typography fontSize={16} fontWeight={500} noWrap>
                {moneyFormatter(USDBalance - parseFloat(finalAmount), false)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {beneficiary && (
        <Stack gap={4}>
          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
            <Person2Outlined sx={{ fontSize: "20px" }} />
            <Typography fontSize={16} fontWeight={500}>
              {t("money.transfer.step.review.recipient")}
            </Typography>
          </Stack>
          <Stack direction={isTablet ? "column" : "row"} gap={isTablet ? 2 : 3}>
            <Stack
              width={"100%"}
              justifyContent={"space-between"}
              gap={3}
              borderRadius={6}
              p={3}
              bgcolor={colors.lightGrey}
              overflow={"hidden"}
            >
              <Stack direction={"row"} alignItems={"center"} gap={2}>
                <MemoizedAvatar
                  name={beneficiary.beneficiaryName ?? ""}
                  countryCode={beneficiary.beneficiaryCountryCode ?? ""}
                />
                <Typography fontSize={16} fontWeight={500}>
                  {beneficiary.beneficiaryName}
                </Typography>
              </Stack>
              <Stack>
                <Typography fontSize={14}>
                  {t("money.transfer.step.review.address")}
                </Typography>
                <Typography fontSize={16} fontWeight={500} noWrap>
                  {`${beneficiary.beneficiaryAddress} ${beneficiary.beneficiaryState} ${beneficiary.beneficiaryCity} ${beneficiary.beneficiaryCountryCode}  ${beneficiary.beneficiaryPostcode}`}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              width={"100%"}
              justifyContent={"space-between"}
              gap={3}
              borderRadius={6}
              p={3}
              bgcolor={colors.lightGrey}
            >
              <Stack direction={"row"} alignItems={"center"} gap={2}>
                <Avatar
                  sx={{
                    backgroundColor: colors.grey,
                    color: colors.black,
                    width: "48px",
                    height: "48px",
                  }}
                >
                  <AccountBalanceOutlined />
                </Avatar>
                <Typography fontSize={16} fontWeight={"500"}>
                  {beneficiary.beneficiaryBankName}
                </Typography>
              </Stack>
              <Stack
                width={"100%"}
                direction={"row"}
                alignItems={"center"}
                gap={3}
              >
                <Stack>
                  <Typography fontSize={14}>
                    {isSWIFT
                      ? t("money.transfer.step.review.SWIFT")
                      : t("money.transfer.step.review.routingNumber")}
                  </Typography>
                  <Typography fontSize={16} fontWeight={"500"} noWrap>
                    {maskSensitiveData(beneficiary.routingCodeValue1 ?? "")}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography fontSize={14}>
                    {isSWIFT
                      ? t("money.transfer.step.review.IBAN")
                      : t("money.transfer.step.review.accountNumber")}
                  </Typography>
                  <Typography fontSize={16} fontWeight={"500"} noWrap>
                    {maskSensitiveData(
                      beneficiary.beneficiaryAccountNumber ?? ""
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      )}
      <Typography fontSize={16}>
        <Trans
          i18nKey="money.transfer.step.review.confirmation"
          values={{
            value: t("money.transfer.step.review.confirmation.part"),
          }}
          components={[
            <Typography component={"span"} fontSize={16} fontWeight={500} />,
          ]}
        ></Trans>
      </Typography>
      <ButtonBox
        isLoading={isLoading}
        disabled={isDisabled}
        rightButtonText={
          t("money.transfer.step.review.confirmation.part") ?? ""
        }
        onClickBack={() => {
          posthog?.capture("money_transfer_flow_review_back_btn_click");
          setCurrentStep(TransferSteps.details);
        }}
        onClickNext={onSubmitHandler}
      />
    </Stack>
  );
};

export default Review;
