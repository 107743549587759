import {
  Alert,
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { LoadingComponent } from "components/Loading";
import { Logo } from "components/Logos";
import useCompany from "graphql/hooks/UseCompany";
import utils, { colors } from "utils";
import {
  CompanyServices,
  ProductDuration,
  ServiceCategory,
  ServiceVariant,
  StripeSessionStatus,
} from "graphql/__generated__/types";
import { Trans, useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { moneyFormatter } from "utils/formatters";
import {
  IServiceData,
  RA_SERVICES_DATA,
  SERVICES_DATA,
  SERVICE_VARIANTS,
  ServicesSubCategories,
} from "./data";
import { useIntercom } from "react-use-intercom";
import useGetUpsellProducts from "./hooks/useGetUpsellProducts";
import { useRequestStripeCheckoutSessionDdsMutation } from "graphql/__generated__/operations/RequestStripeCheckoutSessionFromDDS.generated";
import config from "config";
import { ROUTER_PATHS } from "routes/routes";
import { useUpdateCustomerStripeIdMutation } from "graphql/__generated__/operations/UpdateCustomerStripeId.generated";
import useCustomer from "graphql/hooks/useCustomer";
import { useSearchParams } from "react-router-dom";
import { toast } from "toast";
import {
  GetStripeSessionDetailsQuery,
  useGetStripeSessionDetailsLazyQuery,
} from "graphql/__generated__/operations/StripeSessionDetails.generated";
import {
  filterServicesByCategory,
  renderServiceDuration,
  renderServiceStatus,
} from "./helpers";
import { usePostHog } from "posthog-js/react";
import ServicePurchaseModal from "components/ServicePurchaseModal";
import CustomButton from "components/Button";

const redirectBaseUri = `${config.basePath}${ROUTER_PATHS.SERVICES}`;

const Services: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { show: showIntercom } = useIntercom();
  const posthog = usePostHog();
  const { currentCompany } = useCompany();
  const { currentUser } = useCustomer();
  const [searchParams, setSearchParams] = useSearchParams();
  const [fetchSessionDetails] = useGetStripeSessionDetailsLazyQuery();
  const [checkoutWithStripe] = useRequestStripeCheckoutSessionDdsMutation();
  const [updateCustomerStripeId] = useUpdateCustomerStripeIdMutation();

  const [sessionDetails, setSessionDetails] = useState<
    GetStripeSessionDetailsQuery | undefined
  >();
  const [itemToShow, setIsItemToShow] = useState<IServiceData | null>(null);
  const [selectedServiceStatus, setSelectedServiceStatus] =
    useState<ServiceCategory | null>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<
    ServicesSubCategories | string | null
  >(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { UPSELL_PLANS, isProductQueryLoading } = useGetUpsellProducts();

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  useEffect(() => {
    const sessionId = searchParams.get("session");

    if (!sessionId || isProductQueryLoading) return;

    const fetchStripeSessionDetails = async () => {
      try {
        const { data } = await fetchSessionDetails({
          variables: { sessionId },
        });

        setSessionDetails(data);

        const stripeCheckoutId = data?.stripeSessionDetails?.id || "";
        const status = data?.stripeSessionDetails?.status;
        const paymentIntentId = data?.stripeSessionDetails?.paymentIntentId;
        const totalAmount =
          (data?.stripeSessionDetails?.amountTotal || 0) / 100;
        if (status === StripeSessionStatus.COMPLETE) {
          toast.success(
            t("services.upsell.stripePaymentSuccessfulToast") as string
          );

          posthog?.capture("upsell-product-checkout-completed", {
            purchase_value: totalAmount,
            paymentIntentId,
            stripeCheckoutId,
          });
        } else {
          toast.error(t("services.upsell.stripePaymentCancelled") as string);
          posthog?.capture("upsell-product-checkout-failed", {
            purchase_value: totalAmount,
            paymentIntentId,
            stripeCheckoutId,
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchStripeSessionDetails();
  }, [isProductQueryLoading, searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const onShowModalHandler = (item: IServiceData, status?: ServiceCategory) => {
    posthog?.capture("service-click", {
      service_name: item.name,
    });

    setIsModalOpen(true);
    setSelectedServiceStatus(status);
    setIsItemToShow(item);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsItemToShow(null);
  };

  const services = useMemo(
    () => currentCompany?.services || [],
    [currentCompany?.services]
  );

  const isRAServiceActive = useMemo(() => {
    const RAService = services.find(
      (service) => service.service === CompanyServices.REGISTEREDAGENT
    );

    return Boolean(RAService && RAService.status === ServiceCategory.ACTIVE);
  }, [services]);

  const subscribedServices: {
    [key: string]: { service: IServiceData; status: ServiceCategory };
  } = useMemo(() => {
    return services.reduce((reducedServices, service) => {
      const key = SERVICE_VARIANTS.includes(service.variant as ServiceVariant)
        ? `${service.service}-${service.variant}`
        : service.service;

      if (
        selectedFilter &&
        SERVICES_DATA[key]?.subCategory !== selectedFilter
      ) {
        return reducedServices;
      }

      return {
        ...reducedServices,
        ...(!!SERVICES_DATA[key] && {
          [key]: { service: SERVICES_DATA[key], status: service.status },
        }),
      };
    }, {});
  }, [selectedFilter, services]);

  useEffect(() => {
    const serviceToShow = searchParams.get("service");

    if (
      currentCompany &&
      !isModalOpen &&
      serviceToShow &&
      (subscribedServices[serviceToShow] ||
        SERVICES_DATA[serviceToShow] ||
        RA_SERVICES_DATA[serviceToShow])
    ) {
      const subscribedServiceData = subscribedServices[serviceToShow];

      if (subscribedServiceData) {
        const { service, status } = subscribedServiceData;
        onShowModalHandler(service, status);
      } else {
        const service =
          SERVICES_DATA[serviceToShow] || RA_SERVICES_DATA[serviceToShow];
        onShowModalHandler(service);
      }
    } else if (isModalOpen) {
      closeModal();
    }
  }, [currentCompany, searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  // filter out all service that the company already has
  const filteredServicesForUpsells = useMemo(() => {
    const services = currentCompany?.services || [];

    return Object.fromEntries(
      Object.entries(SERVICES_DATA).filter(
        ([key]) => !services.some(({ service }) => key.includes(service))
      )
    );
  }, [currentCompany?.services]);

  if (!currentCompany || !currentUser || isProductQueryLoading)
    return <LoadingComponent />;

  if (!currentCompany.services || !services.length)
    return (
      <Stack mb={5}>
        <Typography variant="h2" mb={4}>
          Services
        </Typography>
        <Typography variant="h6">No services at this time...</Typography>
      </Stack>
    );

  const onCloseModalHandler = () => {
    searchParams.delete("service");
    setSearchParams(searchParams);
  };

  const onCheckoutHandler = async (item: IServiceData) => {
    setIsItemToShow(item);
    setIsLoading(true);

    const upsellProduct = item.productKey && UPSELL_PLANS[item.productKey];

    try {
      const companyId = currentCompany.id;
      const customerId = currentUser.id || "";
      const { data } = await checkoutWithStripe({
        variables: {
          companyId,
          customerId,
          products: [item.productKey ?? ""],
          redirectBaseUri,
          email: currentUser?.email || "",
        },
      });

      const stripeCheckoutUrl =
        data?.company?.requestStripeCheckoutSessionDDS?.url;
      const stripeCustomerId =
        data?.company?.requestStripeCheckoutSessionDDS?.stripeCustomerId;

      if (stripeCustomerId && customerId) {
        await updateCustomerStripeId({
          variables: { customerId, stripeCustomerId },
        });
      }

      if (stripeCheckoutUrl && upsellProduct) {
        posthog?.capture(`upsell-product-checkout-started`, {
          upsell_product_name: upsellProduct.name,
          upsell_product_id: upsellProduct.value,
          upsell_product_value: upsellProduct.price,
          upsell_product_term: ProductDuration.ANNUAL,
        });
        await new Promise((resolve) => window.setTimeout(resolve, 500)); // wait 500ms
        window.location.replace(stripeCheckoutUrl);
      }
    } catch {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Stack mb={5}>
        <Typography variant="h2" mb={2}>
          Services
        </Typography>
        {sessionDetails?.stripeSessionDetails?.status ===
          StripeSessionStatus.COMPLETE && (
          <Alert severity="success" sx={{ mb: 2 }}>
            <Trans
              i18nKey="services.upsell.stripePaymentSuccessful"
              values={{
                value: t("services.upsell.stripePaymentSuccessful.value"),
              }}
              components={[
                <Typography
                  component={"span"}
                  onClick={showIntercom}
                  variant="body2"
                  style={{
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                />,
              ]}
            ></Trans>
          </Alert>
        )}
        <Stack direction={"row"} gap={1} mb={4} flexWrap={"wrap"}>
          <Button
            variant={selectedFilter === null ? "contained" : "outlined"}
            onClick={() => setSelectedFilter(null)}
            sx={{
              borderRadius: "24px",
              p: "10px 16px",
              boxShadow: "none",
              ...(selectedFilter === null
                ? {
                    bgcolor: colors.black,
                    "&:hover": {
                      bgcolor: colors.charcoal,
                    },
                  }
                : {
                    "&:hover": {
                      color: colors.charcoal,
                    },
                  }),
              "&:hover": {
                boxShadow: "none",
              },
            }}
          >
            <Typography
              fontWeight={500}
              lineHeight={"16px"}
              textTransform="initial"
            >
              View all
            </Typography>
          </Button>
          {Object.values(ServicesSubCategories).map((category, index) => {
            const isFilterSelected = selectedFilter === category;

            return (
              <Button
                key={index}
                variant={isFilterSelected ? "contained" : "outlined"}
                onClick={() => {
                  posthog?.capture("service-filter-click", {
                    filter_name: category,
                  });

                  setSelectedFilter(category);
                }}
                sx={{
                  borderRadius: "24px",
                  p: "10px 16px",
                  boxShadow: "none",
                  ...(isFilterSelected
                    ? {
                        bgcolor: colors.black,
                        "&:hover": {
                          bgcolor: colors.charcoal,
                        },
                      }
                    : {
                        "&:hover": {
                          color: colors.charcoal,
                        },
                      }),
                  "&:hover": {
                    boxShadow: "none",
                  },
                }}
              >
                <Typography
                  fontWeight={500}
                  lineHeight={"16px"}
                  textTransform="initial"
                >
                  {category}
                </Typography>
              </Button>
            );
          })}
        </Stack>
        <Typography variant="h4" mb={4}>
          My Services
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
            gridAutoRows: isTablet ? "none" : "1fr",
            gap: 2,
          }}
        >
          {Object.entries(subscribedServices).map(([key, service], index) => {
            const showedService: IServiceData = service.service;

            return (
              <Stack
                key={index}
                spacing={2}
                bgcolor={colors.lightGrey}
                p={3}
                borderRadius="24px"
                minHeight={isTablet ? "none" : 280}
                justifyContent={"space-between"}
              >
                <Stack gap={1}>
                  <Stack direction={"row"} alignItems={"baseline"} spacing={1}>
                    {showedService?.logo && <Logo width={60} />}
                    <Typography variant="h5" fontWeight={500}>
                      {showedService.name}
                    </Typography>
                  </Stack>
                  <Typography color={colors.contentSecondary}>
                    {showedService.title}
                  </Typography>
                  <Stack direction={"row"} alignItems={"center"} gap={1}>
                    {renderServiceStatus(service.status)}
                  </Stack>
                </Stack>
                <Stack mt={"auto"} alignItems={"flex-end"}>
                  <Button
                    onClick={() => {
                      searchParams.set("service", key);
                      setSearchParams(searchParams);
                    }}
                    variant="outlined"
                    sx={{ borderRadius: 6 }}
                  >
                    {t("general.showDetails")}
                  </Button>
                </Stack>
              </Stack>
            );
          })}
        </Box>
        <Divider sx={{ my: 3 }} />
        <Typography variant="h4" mb={4}>
          Founders like you also purchased...
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: `repeat(auto-fit, ${
              isTablet ? "1fr" : "minmax(500px, 1fr)"
            })`,
            gridAutoRows: isTablet ? "none" : "1fr",
            gap: 2,
          }}
        >
          {filteredServicesForUpsells &&
            filterServicesByCategory(
              Object.entries(filteredServicesForUpsells),
              selectedFilter
            ).map(([key, upsellService], index) => (
              <Stack
                key={index}
                spacing={2}
                bgcolor={colors.lightGrey}
                p={3}
                borderRadius="24px"
                minHeight={isTablet ? "none" : 190}
                justifyContent={"space-between"}
              >
                <Stack gap={1}>
                  <Stack direction={"row"} alignItems={"baseline"} spacing={1}>
                    {upsellService?.logo && <Logo width={60} />}
                    <Typography variant="h5" fontWeight={500}>
                      {upsellService.name}
                    </Typography>
                  </Stack>
                  <Typography color={colors.contentSecondary}>
                    {upsellService.title}
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  mt={"auto"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  {upsellService?.duration &&
                    (upsellService?.price ||
                      (upsellService.productKey &&
                        UPSELL_PLANS[upsellService.productKey].price)) && (
                      <Stack
                        direction={"row"}
                        alignItems={"flex-end"}
                        spacing={1}
                      >
                        <Typography variant="h2" fontSize={28} lineHeight={1}>
                          {moneyFormatter(
                            upsellService.productKey
                              ? UPSELL_PLANS[upsellService.productKey].price
                              : upsellService.price || 0
                          )}
                        </Typography>
                        <Typography
                          variant="body1"
                          color={colors.contentTertiary}
                        >
                          {renderServiceDuration(upsellService.duration)}
                        </Typography>
                      </Stack>
                    )}
                  <Stack direction={"row"} gap={1}>
                    <CustomButton
                      transparent
                      buttonText={t("general.learnMore")}
                      onClick={() => {
                        searchParams.set("service", key);
                        setSearchParams(searchParams);
                      }}
                    />
                    {upsellService.allowCheckout &&
                      upsellService.productKey && (
                        <CustomButton
                          isLoading={isLoading && upsellService === itemToShow}
                          buttonText={"Buy Now"}
                          onClick={() => {
                            posthog?.capture("call-sales-click", {
                              service_name: upsellService.name,
                            });
                            onCheckoutHandler(upsellService);
                          }}
                        />
                      )}
                  </Stack>
                </Stack>
              </Stack>
            ))}
          {isRAServiceActive &&
            filterServicesByCategory(
              Object.entries(RA_SERVICES_DATA),
              selectedFilter
            ).map(([key, raService], index) => (
              <Stack
                key={index}
                spacing={2}
                bgcolor={colors.lightGrey}
                p={3}
                borderRadius="24px"
                minHeight={isTablet ? "none" : 190}
                justifyContent={"space-between"}
              >
                <Stack gap={1}>
                  <Stack direction={"row"} alignItems={"baseline"} spacing={1}>
                    <Typography variant="h5" fontWeight={500}>
                      {raService.name}
                    </Typography>
                  </Stack>
                  <Typography color={colors.contentSecondary}>
                    {raService.title}
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  mt={"auto"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  {raService?.duration && raService?.price && (
                    <Stack
                      direction={"row"}
                      alignItems={"flex-end"}
                      spacing={1}
                    >
                      <Typography variant="h2" fontSize={28} lineHeight={1}>
                        {moneyFormatter(raService.price)}
                      </Typography>
                      <Typography
                        variant="body1"
                        color={colors.contentTertiary}
                      >
                        {renderServiceDuration(raService.duration)}
                      </Typography>
                    </Stack>
                  )}
                  <CustomButton
                    transparent
                    buttonText={t("general.learnMore")}
                    onClick={() => {
                      searchParams.set("service", key);
                      setSearchParams(searchParams);
                    }}
                  />
                </Stack>
              </Stack>
            ))}
        </Box>
      </Stack>
      <ServicePurchaseModal
        {...{
          isModalOpen,
          onCloseModalHandler,
          itemToShow,
          selectedServiceStatus,
          isLoading,
          onCheckoutHandler,
          image: itemToShow?.image,
          imageBgColor: itemToShow?.imageBgColor,
        }}
      />
    </>
  );
};

export default Services;
