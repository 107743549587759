import { ReactNode } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { colors } from "utils";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { useCountdown } from "../hooks/useCountdown";
import { TAX_SEASON_START_DATE } from "../constants";
import { format as formatDate } from "fecha";

const RenderCountdownTile = ({ children }: { children: ReactNode }) => (
  <Box
    width={"100%"}
    textAlign={"center"}
    bgcolor={colors.borderBlue}
    borderRadius={4}
    p={1}
  >
    {children}
  </Box>
);

const Countdown: React.FC<{}> = () => {
  const [months, days, hours] = useCountdown(TAX_SEASON_START_DATE);

  const isTaxSeasonLive = months + days + hours <= 0;

  return (
    <Stack
      sx={{
        bgcolor: isTaxSeasonLive ? colors.lightAccentGreen : "#a9c8ff",
        borderRadius: "32px",
        p: 4,
        height: "100%",
      }}
    >
      <Stack gap={1} mb={isTaxSeasonLive ? 3 : 7.5}>
        <Typography fontSize={"32px"}>
          {isTaxSeasonLive ? "🔔" : "⏰"}
        </Typography>
        <Typography fontWeight={700} variant="h3">
          {isTaxSeasonLive ? "Tax Season is here" : "Tax Season"}
        </Typography>
        <Stack gap={1} direction={"row"} alignItems={"center"}>
          <CalendarMonthOutlinedIcon sx={{ fontSize: "18px" }} />
          <Typography variant="body1" fontWeight={500} fontSize={"1rem"}>
            Starting {formatDate(TAX_SEASON_START_DATE, "MMMM Do, YYYY")}
          </Typography>
        </Stack>
      </Stack>
      {isTaxSeasonLive ? (
        <Typography variant="body1" mb={3}>
          Tax season is here, and our expert CPAs and tax team are ready to make
          it hassle-free for you.
        </Typography>
      ) : (
        <Stack direction={"row"} gap={2}>
          <RenderCountdownTile>
            <Typography variant="h3" fontWeight={700}>
              {months}
            </Typography>
            <Typography>months</Typography>
          </RenderCountdownTile>
          <RenderCountdownTile>
            <Typography variant="h3" fontWeight={700}>
              {days}
            </Typography>
            <Typography>days</Typography>
          </RenderCountdownTile>
          <RenderCountdownTile>
            <Typography variant="h3" fontWeight={700}>
              {hours}
            </Typography>
            <Typography>hours</Typography>
          </RenderCountdownTile>
        </Stack>
      )}
    </Stack>
  );
};

export default Countdown;
