import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AuthTokensInfoFragment = { __typename?: 'AuthTokens', idToken: string, refreshToken: string, accessToken: string };

export type AuthRefreshTokensMutationVariables = Types.Exact<{
  input: Types.RefreshTokensInput;
}>;


export type AuthRefreshTokensMutation = { __typename?: 'Mutation', auth?: { __typename?: 'AuthMutation', refreshTokens?: { __typename?: 'AuthResponseTokens', success: boolean, message: string, tokens?: { __typename?: 'AuthTokens', idToken: string, refreshToken: string, accessToken: string } | null } | null } | null };

export const AuthTokensInfoFragmentDoc = gql`
    fragment AuthTokensInfo on AuthTokens {
  idToken
  refreshToken
  accessToken
}
    `;
export const AuthRefreshTokensDocument = gql`
    mutation AuthRefreshTokens($input: RefreshTokensInput!) {
  auth {
    refreshTokens(input: $input) {
      success
      message
      tokens {
        ...AuthTokensInfo
      }
    }
  }
}
    ${AuthTokensInfoFragmentDoc}`;
export type AuthRefreshTokensMutationFn = Apollo.MutationFunction<AuthRefreshTokensMutation, AuthRefreshTokensMutationVariables>;

/**
 * __useAuthRefreshTokensMutation__
 *
 * To run a mutation, you first call `useAuthRefreshTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthRefreshTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authRefreshTokensMutation, { data, loading, error }] = useAuthRefreshTokensMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthRefreshTokensMutation(baseOptions?: Apollo.MutationHookOptions<AuthRefreshTokensMutation, AuthRefreshTokensMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthRefreshTokensMutation, AuthRefreshTokensMutationVariables>(AuthRefreshTokensDocument, options);
      }
export type AuthRefreshTokensMutationHookResult = ReturnType<typeof useAuthRefreshTokensMutation>;
export type AuthRefreshTokensMutationResult = Apollo.MutationResult<AuthRefreshTokensMutation>;
export type AuthRefreshTokensMutationOptions = Apollo.BaseMutationOptions<AuthRefreshTokensMutation, AuthRefreshTokensMutationVariables>;