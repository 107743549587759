export type InitialIndividualMemberAddressValuesType = {
  address1: string;
  address2: string;
  city: string;
  country: string;
  postalCode: string;
  state: string;
};

export const INTITAL_INDIVIDUAL_MEMBER_ADDRESS_VALUES: InitialIndividualMemberAddressValuesType =
  {
    address1: "",
    address2: "",
    city: "",
    country: "",
    postalCode: "",
    state: "",
  };
