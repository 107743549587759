import useSignupFlow from "graphql/hooks/UseSignupFlow";
import { Navigate } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import { getTokens } from "utils/auth";
import { GuardProps } from "./types";

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  const { hasDataLoaded, hasServices, hasMembers, companyServices } =
    useSignupFlow();
  const { idToken } = getTokens();

  if (idToken && !hasDataLoaded) {
    return null;
  }

  // go to the dashboard if the token is valid and the user has services and the user has members
  if (idToken && hasServices && (hasMembers || companyServices?.length === 1)) {
    return <Navigate to={ROUTER_PATHS.DASHBOARD} />;
  }

  return children;
};

export default GuestGuard;
