import { Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "utils";

const MIN_PASSWORD_LENGTH = 8;

const StyledList = styled.li<{ $isValid?: boolean }>`
  list-style-type: ${({ $isValid }) => ($isValid ? "none" : "disc")};
  color: ${({ $isValid }) =>
    $isValid ? colors.green : colors.contentInverseTertiary};

  ${({ $isValid }) =>
    $isValid &&
    `&:before{
        content: '✓';
        margin-right: 8px;
        color: ${colors.green}
      };
  `}
`;

export const PasswordRequirements = ({ password }: { password: string }) => {
  const hasAtleastMinChars = password.length >= MIN_PASSWORD_LENGTH; // can update it to pass this as a prop
  const hasUpperAndLowerCase = /[A-Z]/.test(password) && /[a-z]/.test(password);
  const hasOneSymbolAndOneNumber =
    /[^\w\s]/.test(password) && /\d/.test(password);

  return (
    <Typography fontSize={16}>
      <StyledList $isValid={hasAtleastMinChars}>
        At least {MIN_PASSWORD_LENGTH} characters
      </StyledList>
      <StyledList $isValid={hasUpperAndLowerCase}>
        Lowercase (a-z) and uppercase (A-Z)
      </StyledList>
      <StyledList $isValid={hasOneSymbolAndOneNumber}>
        One number (0-9) and a symbol
      </StyledList>
    </Typography>
  );
};
