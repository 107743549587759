import utils from "utils";
import { BooksTypesEnum, RenderProps } from ".";

type elementId =
  | "dashboard-body"
  | "dashboard-income"
  | "expense-body"
  | "invoice-body";

interface IHurdlrProps {
  id: elementId;
  render: BooksTypesEnum;
}

const HurdlrAuthorised = ({ elementToRender }: RenderProps) => {
  let hurdlrAttributes: IHurdlrProps = {
    id: "dashboard-body",
    render: BooksTypesEnum.DASHBOARD,
  };

  if (elementToRender === BooksTypesEnum.INVOICE) {
    hurdlrAttributes = {
      id: "invoice-body",
      render: BooksTypesEnum.INVOICE,
    };
  }

  if (elementToRender === BooksTypesEnum.EXPENSE) {
    hurdlrAttributes = {
      id: "expense-body",
      render: BooksTypesEnum.EXPENSE,
    };
  }

  if (elementToRender === BooksTypesEnum.INCOME) {
    hurdlrAttributes = {
      id: "dashboard-income",
      render: BooksTypesEnum.INCOME,
    };
  }

  const { id, render } = hurdlrAttributes;
  (window as any).Hurdlr.renderScreen(id, render);

  return (
    <div
      id={id}
      style={{
        height: utils.screenSize.pc,
        backgroundColor: "rgba(255, 255, 255, 0.1)",
      }}
    />
  );
};

export default HurdlrAuthorised;
