import { useEffect } from "react";
import useCustomer from "graphql/hooks/useCustomer";
import { useUserSurveyResponseLazyQuery } from "graphql/__generated__/operations/UserSurveyResponse.generated";
import { USER_SURVEY_KEY } from "../Feedback/FeedbackSurvey/hooks/useUserSurvey";

const useUserSurveyResponse = () => {
  const { currentUser, isCustomerLoading } = useCustomer();

  const [
    fetchUserSurveyResponse,
    { data, loading: isUserSurveyResponseLoading, refetch },
  ] = useUserSurveyResponseLazyQuery({
    errorPolicy: "all",
  });

  useEffect(() => {
    if (!isCustomerLoading && currentUser?.id) {
      const doolaCustomerId = currentUser.id;

      fetchUserSurveyResponse({
        variables: { key: USER_SURVEY_KEY, doolaCustomerId },
      });
    }
  }, [currentUser?.id, isCustomerLoading, fetchUserSurveyResponse]);

  const isLoading =
    isUserSurveyResponseLoading || isCustomerLoading || !currentUser?.id;

  return {
    userSurveyResponse: data?.userSurveyResponse,
    isUserSurveyResponseLoading: isLoading,
    refetch,
  };
};

export default useUserSurveyResponse;
