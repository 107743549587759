import Documents from "views/DashboardApp/Documents";
import Dashboard from "views/DashboardApp/Dashboard";
import Referrals from "views/DashboardApp/Referral";
import Services from "views/DashboardApp/Services";
import { SidebarItemT } from "./types";
import { ROUTER_PATHS } from "routes/routes";
import {
  DollarOutlined,
  FileTextOutlined,
  HomeOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import Books, { BooksTypesEnum } from "views/DashboardApp/Books";
import { PercentRounded, CreditCard } from "@mui/icons-material";
import Taxes from "views/DashboardApp/Taxes";
import MoneyGateway from "views/DashboardApp/Money/MoneyGateway";

export const TabsData: SidebarItemT[] = [
  {
    label: "Dashboard",
    route: ROUTER_PATHS.DASHBOARD,
    icon: <HomeOutlined />,
    element: <Dashboard />,
    children: [{ path: ROUTER_PATHS.MODAL_FEEDBACK, element: <Dashboard /> }],
  },
  {
    label: "Documents",
    route: ROUTER_PATHS.DOCUMENTS,
    icon: <FileTextOutlined />,
    element: <Documents />,
  },
  {
    label: "Services",
    route: ROUTER_PATHS.SERVICES,
    icon: <ProfileOutlined />,
    element: <Services />,
  },
  {
    label: "Refer & Earn",
    route: ROUTER_PATHS.REFERRALS,
    icon: <DollarOutlined />,
    element: <Referrals />,
  },
];

export const BooksSectionItems: SidebarItemT[] = [
  {
    label: "Home",
    route: ROUTER_PATHS.BOOKS,
    element: <Books elementToRender={BooksTypesEnum.DASHBOARD} />,
  },

  {
    label: "Invoice Center",
    route: ROUTER_PATHS.BOOKS_INVOICES,
    element: <Books elementToRender={BooksTypesEnum.INVOICE} />,
  },
  {
    label: "Income Center",
    route: ROUTER_PATHS.BOOKS_INCOME,
    element: <Books elementToRender={BooksTypesEnum.INCOME} />,
  },
  {
    label: "Expense Center",
    route: ROUTER_PATHS.BOOKS_EXPENSES,
    element: <Books elementToRender={BooksTypesEnum.EXPENSE} />,
  },
];

export const TaxRoutes: SidebarItemT = {
  label: "Taxes",
  route: ROUTER_PATHS.TAXES,
  icon: <PercentRounded />,
  element: <Taxes />,
};

export const MoneyRoutes: SidebarItemT = {
  label: "Money",
  route: ROUTER_PATHS.MONEY,
  icon: <CreditCard fontSize="small" />,
  element: <MoneyGateway />,
};
