import { Outlet } from "react-router-dom";
import SignupProvider, { SignupContext } from "./SignupProvider";
import Layout from "components/Layout";
import { Container, Stack, useMediaQuery } from "@mui/material";
import { useContext } from "react";
import utils from "utils";
import { Footer } from "components/PrePurchase/Footer";
import { FormationSteps } from "./services";

const LayoutContainer = () => {
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const { formationStep } = useContext(SignupContext);

  // TODO: Uncomment for phase 1 with company origin
  // const showLayout =
  //   formationStep === FormationSteps.Registration ||
  //   formationStep === FormationSteps.CompanyOrigin;

  const showLayout = formationStep === FormationSteps.Registration;

  return (
    <>
      {showLayout ? (
        <Layout />
      ) : (
        <Container
          maxWidth="lg"
          sx={{
            px: { xs: 2, sm: 4 },
            position: "relative",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Outlet />
          {!isTablet && (
            <Stack mt={"auto"} mb={2}>
              <Footer />
            </Stack>
          )}
        </Container>
      )}
    </>
  );
};

const SignupLayout = () => {
  return (
    <SignupProvider>
      <LayoutContainer />
    </SignupProvider>
  );
};
export default SignupLayout;
