import {
  selectBoiFilingFormationPackage,
  selectExpeditedFormationPackage,
  selectFormationPackage,
} from "state/formationFormSlice";
import { useAppSelector } from "state/hooks";
import { TOTAL_COMPLIANCE } from "../Packages/data";
import { calculateFeeValue } from "services";
import useGetStateFeesProducts from "hooks/useGetStateFees";
import useSignupFlow from "graphql/hooks/UseSignupFlow";

export default function useTotalCartValue() {
  const { filteredFeesProducts } = useGetStateFeesProducts();
  const { entityState } = useSignupFlow();
  const selectedFormationPackage = useAppSelector(selectFormationPackage);
  const selectedExpeditedFormationPackage = useAppSelector(
    selectExpeditedFormationPackage
  );
  const selectedBoiFilingFormationPackage = useAppSelector(
    selectBoiFilingFormationPackage
  );

  const isTotalCompliancePackage =
    selectedFormationPackage?.name === TOTAL_COMPLIANCE.value;

  const formationPackagePrice = selectedFormationPackage?.price || 0;
  const expeditedFormationPrice = selectedExpeditedFormationPackage?.price || 0;
  const boiFilingFormationPrice = selectedBoiFilingFormationPackage?.price || 0;
  const addonPackagePrice = expeditedFormationPrice + boiFilingFormationPrice;
  const stateFee = calculateFeeValue(
    entityState as string,
    filteredFeesProducts
  );

  const totalPayToday =
    formationPackagePrice +
    stateFee +
    (!isTotalCompliancePackage ? addonPackagePrice : 0);
  const totalPayLater =
    totalPayToday -
    stateFee -
    (!isTotalCompliancePackage ? addonPackagePrice : 0);

  return { totalPayToday, totalPayLater };
}
