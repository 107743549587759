import {
  FormControlLabel,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import utils, { colors } from "utils";
import { CustomSwitch } from "./components/CustomSwitch";
import { TaxQuestionAnswers } from "graphql/__generated__/types";
import { TaxQuestionResponses } from "../../data";

interface IQuestionProps extends TaxQuestionAnswers {
  onChange: (questionId: string, newValue: string) => void;
}

const Question = ({
  questionId,
  question,
  answer,
  onChange,
}: IQuestionProps) => {
  const isResponseYes = answer === TaxQuestionResponses.YES;

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  return (
    <Stack
      direction={isTablet ? "column" : "row"}
      alignItems={isTablet ? "flex-start" : "center"}
      justifyContent={"space-between"}
      gap={2}
      bgcolor={colors.lightGrey}
      borderRadius={6}
      p={3}
    >
      <Typography fontSize={16} color={colors.black}>
        {question}
      </Typography>
      <FormControlLabel
        sx={{ ...(isTablet && { alignSelf: "flex-end" }) }}
        control={
          <CustomSwitch
            sx={{ m: 1 }}
            checked={isResponseYes}
            onChange={() =>
              onChange(
                questionId ?? "",
                answer === TaxQuestionResponses.YES
                  ? TaxQuestionResponses.NO
                  : TaxQuestionResponses.YES
              )
            }
          />
        }
        label={
          <Typography
            color={isResponseYes ? colors.black : colors.contentInverseTertiary}
            fontWeight={500}
            minWidth={30}
          >
            {answer ?? TaxQuestionResponses.NO}
          </Typography>
        }
        labelPlacement="start"
      />
    </Stack>
  );
};

export default Question;
