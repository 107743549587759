import { Grid, IconButton, Stack, useMediaQuery } from "@mui/material";
import { LoadingComponent } from "components/Loading";
import utils, { colors } from "utils";
import MoneyHeader from "../Header";
import RecentlyPaid from "../RecentlyPaid";
import BeneficiaryItem from "./components/BeneficiaryItem";
import useGetBeneficiaries from "./hooks/useGetBeneficiaries";
import NoBeneficiaries from "./components/NoBeneficiaries";
import AddBeneficiary from "./AddBeneficiary";
import { ChangeEvent, useState } from "react";
import useCompany from "graphql/hooks/UseCompany";
import CustomInput from "components/Form/CustomInput";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import { PlusOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@mui/icons-material";
import {
  BeneficiaryDetails,
  BeneficiaryResponse,
} from "graphql/__generated__/types";
import { useRemoveMoneyBeneficiaryMutation } from "graphql/__generated__/operations/RemoveMoneyBeneficiary.generated";
import useFilteredBeneficiaryList from "../hooks/useFilteredBeneficiaryList";
import { usePostHog } from "posthog-js/react";

const Beneficiary = () => {
  const { t } = useTranslation();
  const { currentCompany } = useCompany();

  const [isAddBeneficiaryModalOpen, setIsAddBeneficiaryModalOpen] =
    useState<boolean>(false);
  const [beneficiaryData, setBeneficiaryData] = useState<{
    beneficiary: BeneficiaryDetails;
    hashId: string;
  }>();

  const { refetchBeneficiaries } = useGetBeneficiaries();
  const [deleteBeneficiary] = useRemoveMoneyBeneficiaryMutation();

  const {
    filteredBeneficiaries,
    queryString,
    handleSearch,
    isBeneficiariesLoading,
  } = useFilteredBeneficiaryList();

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const posthog = usePostHog();

  if (!currentCompany || isBeneficiariesLoading) {
    return <LoadingComponent />;
  }

  const handleRemoveBeneficiary = async (beneficiaryHashId: string) => {
    try {
      await deleteBeneficiary({
        variables: {
          doolaCompanyId: currentCompany?.id,
          beneficiaryHashId,
        },
      });

      refetchBeneficiaries();
    } catch (error) {
      console.log("Error while removing the beneficiary: ", error);
    }
  };

  const handleAddEditBeneficiary = (
    beneficiary?: BeneficiaryDetails,
    hashId?: string
  ) => {
    if (beneficiary && hashId) {
      setBeneficiaryData({ beneficiary, hashId });
    } else {
      setBeneficiaryData(undefined);
    }

    setIsAddBeneficiaryModalOpen(true);
  };

  return (
    <Stack>
      <MoneyHeader />
      <AddBeneficiary
        isModalOpen={isAddBeneficiaryModalOpen}
        onCloseModalHandler={() => setIsAddBeneficiaryModalOpen(false)}
        beneficiary={beneficiaryData?.beneficiary}
        beneficiaryHashId={beneficiaryData?.hashId}
      />
      <Grid container item xs={12} spacing={isTablet ? 3 : 10}>
        <Grid item xs={12} md={8}>
          <Stack gap={3} mt={4}>
            <Stack gap={isTablet ? 2 : 3} direction={"row"}>
              <CustomInput
                styles={{ width: "100%" }}
                placeholder={
                  t("money.beneficiary.search.placeholder") as string
                }
                endIcon={<SearchOutlined />}
                value={queryString}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleSearch(e.target.value)
                }
              />
              {isTablet ? (
                <IconButton
                  onClick={() => {
                    posthog?.capture(
                      "money_beneficiary_page_add_beneficiary_click"
                    );
                    handleAddEditBeneficiary();
                  }}
                  sx={{
                    width: 48,
                    height: 48,
                    backgroundColor: colors.black,
                    borderRadius: "50%",
                  }}
                >
                  <PlusOutlined
                    style={{
                      fontSize: 16,
                      fontWeight: "700",
                      color: colors.white,
                    }}
                  />
                </IconButton>
              ) : (
                <Button
                  buttonText={t("money.beneficiary.add")}
                  endIcon={
                    <PlusOutlined style={{ fontSize: 16, fontWeight: "700" }} />
                  }
                  onClick={() => {
                    posthog?.capture(
                      "money_beneficiary_page_add_beneficiary_click"
                    );
                    handleAddEditBeneficiary();
                  }}
                  styles={{ minWidth: "180px" }}
                />
              )}
            </Stack>
            <Grid container spacing={2}>
              {filteredBeneficiaries ? (
                filteredBeneficiaries?.length ? (
                  filteredBeneficiaries?.map(
                    (beneficiary: BeneficiaryResponse | null) =>
                      beneficiary && (
                        <BeneficiaryItem
                          key={beneficiary?.beneficiaryHashId}
                          beneficiary={beneficiary}
                          onRemoveHandler={handleRemoveBeneficiary}
                          onEditHandler={handleAddEditBeneficiary}
                        />
                      )
                  )
                ) : (
                  <NoBeneficiaries />
                )
              ) : (
                <Stack
                  width={"100%"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  minHeight={"200px"}
                >
                  <LoadingComponent />
                </Stack>
              )}
            </Grid>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <RecentlyPaid />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Beneficiary;
