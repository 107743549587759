import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FormationScreenNames } from "../services";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FormControlElement from "../components/FormControlElement";
import { useUpdateCompanyEntityTypeMutation } from "graphql/__generated__/operations/UpdateCompanyEntityType.generated";
import { useUpdateCompanyStateMutation } from "graphql/__generated__/operations/UpdateCompanyState.generated";
import { EntityType } from "graphql/__generated__/types";
import useSignupFlow from "graphql/hooks/UseSignupFlow";
import utils, { colors } from "utils";
import ButtonBox from "components/ButtonBox";
import EntityTypeQuiz from "./EntityTypeQuiz";
import { LoadingComponent } from "components/Loading";
import {
  CustomExpandIconDesktop,
  CustomExpandIconTablet,
} from "../components/AccordionCustomExpandIcons";
import { EntityListItem, EntityListItems } from "./components/EntityListItems";
import { ROUTER_PATHS } from "routes/routes";
import { usePostHog } from "posthog-js/react";

const values = [
  {
    title: "signup.entityType.llc.title",
    subtitle: "signup.entityType.llc.subtitle",
    value: EntityType.LLC,
  },
  {
    title: "signup.entityType.ccorp.title",
    subtitle: "signup.entityType.ccorp.subtitle",
    value: EntityType.CCORP,
  },
  {
    title: "signup.entityType.dao.title",
    subtitle: "signup.entityType.dao.subtitle",
    value: EntityType.DAO,
  },
];

const EntityTypeStep = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { companyId, entityType, hasDataLoaded } = useSignupFlow();

  const [selectedValue, setSelectedValue] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isBlockLoading, setIsBlockLoading] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);
  const [showQuizResult, setShowQuizResult] = useState(false);

  const [updateCompanyState] = useUpdateCompanyStateMutation();
  const [updateCompanyEntityType] = useUpdateCompanyEntityTypeMutation();

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  useEffect(() => {
    const entityTypes = [EntityType.CCORP, EntityType.DAO, EntityType.LLC];

    if (entityType && entityTypes.includes(entityType)) {
      setSelectedValue(entityType);
    }
  }, [entityType]);

  const onSubmitHandler = async () => {
    setIsLoading(true);

    try {
      await updateCompanyEntityType({
        variables: { companyId, entityType: selectedValue as EntityType },
      });

      posthog?.capture("entity-selected", {
        entity_type: selectedValue,
      });

      navigate(ROUTER_PATHS.PRE_PURCHASE_COMPANY_STATE);
    } catch {
      setIsLoading(false);
    }
  };

  const handleClose = async (stateAbbr?: string, entityType?: EntityType) => {
    setShowQuiz(false);
    setIsBlockLoading(true);

    try {
      if (stateAbbr && entityType) {
        setSelectedValue(entityType);
        setShowQuizResult(true);

        await updateCompanyState({
          variables: { companyId, state: stateAbbr },
        });

        await updateCompanyEntityType({
          variables: { companyId, entityType: entityType as EntityType },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsBlockLoading(false);
    }
  };

  const onBackHandler = () => {
    posthog?.capture("funnel-back", {
      screen: FormationScreenNames.EntityType,
    });

    navigate(ROUTER_PATHS.PRE_PURCHASE_COUNTRY_OF_RESIDENCE);
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const isDisabled = !selectedValue || !hasDataLoaded;

  return (
    <Stack direction="column" gap={3} mb={isTablet ? 15 : 4}>
      <Typography
        width={isTablet ? "100%" : "60%"}
        variant={isTablet ? "h3" : "h2"}
      >
        {t("signup.entityType.header")}
      </Typography>
      {isBlockLoading ? (
        <Box height={150}>
          <LoadingComponent />
        </Box>
      ) : (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: isTablet ? "1fr" : "repeat(3, minmax(0, 1fr))",
            gridAutoRows: isTablet ? "none" : "1fr",
            gap: 2,
          }}
        >
          {values.map(({ title, subtitle, value }, index) => (
            <FormControlElement
              key={index}
              title={t(title)}
              subtitle={t(subtitle) as string}
              optionValue={value}
              selectedValue={selectedValue}
              changeHandler={onChangeHandler}
              chipText={
                showQuizResult && entityType === value ? "Quiz Result" : ""
              }
              content={
                isTablet ? (
                  <Accordion
                    disableGutters
                    sx={{
                      width: "auto",
                      background: "none",
                      border: "none",
                      boxShadow: "none",
                      ".MuiAccordionSummary-root": {
                        minHeight: 0,
                      },
                      ".MuiAccordionSummary-content": {
                        m: 0,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<CustomExpandIconTablet />}
                      sx={{
                        display: "inline-flex",
                        flexGrow: 0,
                        p: 0,
                        ".MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                          transform: "none",
                        },
                      }}
                    />
                    <AccordionDetails sx={{ p: 0 }}>
                      <EntityListItem type={value} />
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  ""
                )
              }
            />
          ))}
        </Box>
      )}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        position="relative"
      >
        <Box>
          {!isTablet && (
            <Accordion
              disableGutters
              sx={{
                width: "auto",
                background: "none",
                border: "none",
                boxShadow: "none",
                flexGrow: 1,
                ".MuiAccordionSummary-root": {
                  minHeight: 0,
                },
                ".MuiAccordionSummary-content": {
                  m: 0,
                },
              }}
            >
              <AccordionSummary
                expandIcon={<CustomExpandIconDesktop />}
                sx={{
                  display: "inline-flex",
                  flexGrow: 0,
                  gap: 1,
                  p: 0,
                }}
              >
                <Typography fontSize={16} fontWeight={500}>
                  {t("signup.entityType.accordion.compare")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ mt: 3, p: 0 }}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
                    gap: 2,
                    mb: 3,
                  }}
                >
                  <EntityListItems />
                </Box>
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={2}
          position={isTablet ? "relative" : "absolute"}
          right={0}
        >
          <Typography fontSize={14} fontWeight={500}>
            {t("signup.entityType.quiz")}
          </Typography>
          <Button
            onClick={() => {
              setShowQuiz(true);
              posthog?.capture("entity-quiz-start");
            }}
            variant="contained"
            sx={{
              borderRadius: "30px",
              p: "6px 16px",
              bgcolor: colors.grey,
              color: colors.black,
              boxShadow: "none",

              "&:hover": {
                bgcolor: colors.darkGrey,
                boxShadow: "none",
              },
            }}
          >
            {t("signup.entityType.button.quiz")}
          </Button>
        </Stack>
      </Stack>
      <ButtonBox
        onClickBack={onBackHandler}
        onClickNext={onSubmitHandler}
        disabled={isDisabled}
        isLoading={isLoading}
      />
      {showQuiz && <EntityTypeQuiz onClose={handleClose} />}
    </Stack>
  );
};

export default EntityTypeStep;
