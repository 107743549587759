import { useGetMoneyOnboardLazyQuery } from "graphql/__generated__/operations/MoneyOnboard.generated";
import useCompany from "graphql/hooks/UseCompany";
import useCustomer from "graphql/hooks/useCustomer";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";

const useOnboardingData = () => {
  const { currentCompany } = useCompany();
  const { currentUser } = useCustomer();
  const navigate = useNavigate();

  const [selectedCompany, setIsSelectedCompany] = useState<string>();
  const [isOnboardingDataLoading, setIsOnboardingDataLoading] =
    useState<boolean>(true);

  const [fetchOnboardData, { data }] = useGetMoneyOnboardLazyQuery();

  useEffect(() => {
    if (currentCompany?.id && currentUser?.id) {
      if (!!selectedCompany && selectedCompany !== currentCompany.id) {
        return navigate(ROUTER_PATHS.MONEY);
      }

      setIsSelectedCompany(currentCompany.id);

      const getOnboardingData = async () => {
        try {
          await fetchOnboardData({
            variables: {
              companyId: currentCompany.id,
              customerId: currentUser.id || "",
            },
          });
        } catch (error) {
          console.log("Failed to fetch onboarding data, error: ", error);
        } finally {
          setIsOnboardingDataLoading(false);
        }
      };

      getOnboardingData();
    }
  }, [
    currentCompany?.id,
    currentUser?.id,
    fetchOnboardData,
    navigate,
    selectedCompany,
  ]);

  return {
    onboardingData: data?.onboardingDocument?.payload,
    isOnboardingDataLoading,
  };
};

export default useOnboardingData;
