import { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import useCompany from "graphql/hooks/UseCompany";
import { LoadingComponent } from "components/Loading";
import stateKeys from "resources/stateKeys";
import { AddressType } from "graphql/__generated__/types";
import AddressTypeSelection from "./AddressTypeSelection";
import AddressInput from "./AddressInput";
import { useUpdateAddressTypeMutation } from "graphql/__generated__/operations/UpdateAddressType.generated";
import useCustomer from "graphql/hooks/useCustomer";
import { usePostHog } from "posthog-js/react";

const OnboardingAddress = () => {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { currentCompany } = useCompany();
  const { currentUser } = useCustomer();
  const [updateAddressType] = useUpdateAddressTypeMutation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAddress, setShowAddress] = useState(false);
  const [selectedAddressType, setSelectedAddressType] = useState<
    AddressType | ""
  >("");

  useEffect(() => {
    setSelectedAddressType(currentCompany?.addressType || "");
  }, [currentCompany?.addressType]);

  if (!currentCompany || !currentUser) {
    return <LoadingComponent />;
  }

  const { countryOfResidence } = currentUser || {};

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAddressType(event.target.value as AddressType);
  };

  const state = stateKeys[currentCompany.state as keyof typeof stateKeys];

  const onClickNextAddressType = async () => {
    try {
      setIsLoading(true);

      if (selectedAddressType) {
        await updateAddressType({
          variables: {
            companyId: currentCompany?.id,
            addressType: selectedAddressType,
          },
        });
      }

      if (selectedAddressType === AddressType.OTHERUS) {
        setShowAddress(true);
      } else {
        posthog?.capture("company-address-set", {
          address_type: selectedAddressType,
        });

        navigate(ROUTER_PATHS.ONBOARDING_INDUSTRY);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack direction="column" gap={3}>
      <Typography variant="h1">
        {showAddress
          ? "Add your company address"
          : "Choose an address for your company"}
      </Typography>
      {!showAddress && (
        <AddressTypeSelection
          showUSAddress={countryOfResidence === "USA"}
          selectedAddressType={selectedAddressType}
          isLoading={isLoading}
          state={state}
          onChangeHandler={onChangeHandler}
          onClickNextAddressType={onClickNextAddressType}
          onClickBackAddressType={() =>
            navigate(ROUTER_PATHS.ONBOARDING_COMPANY_NAME)
          }
        />
      )}
      {showAddress && (
        <AddressInput onClickBackAddressInput={() => setShowAddress(false)} />
      )}
    </Stack>
  );
};

export default OnboardingAddress;
