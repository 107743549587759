import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, TextField, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import utils from "utils";
import { Iso31661Alpha3 } from "graphql/__generated__/types";
import { useUpdateOtherUsAddressMutation } from "graphql/__generated__/operations/UpdateOtherUsAddress.generated";
import useCompany from "graphql/hooks/UseCompany";
import { ROUTER_PATHS } from "routes/routes";
import ButtonBox from "components/ButtonBox";
import stateKeys from "resources/stateKeys";
import { LoadingComponent } from "components/Loading";
import { Controller, useForm } from "react-hook-form";
import { Windows1252Regex } from "utils/regexValidations";
import { t } from "i18next";
import ErrorBox from "components/ErrorBox";
import { usePostHog } from "posthog-js/react";

interface IUsAddress {
  line1: string;
  line2?: string;
  postalCode: string;
  city: string;
}

type Props = {
  onClickBackAddressInput: () => void;
};

const AddressInput = ({ onClickBackAddressInput }: Props) => {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const [updateUsAddress] = useUpdateOtherUsAddressMutation();
  const { currentCompany } = useCompany();

  const [isLoading, setIsLoading] = useState(false);

  const { control, formState, handleSubmit, setValue } = useForm<IUsAddress>({
    defaultValues: {
      line1: "",
      line2: "",
      postalCode: "",
      city: "",
    },
    mode: "onChange",
  });

  const { errors } = formState;
  const COMMON_PROPS = { control: control, errors: errors };

  useEffect(() => {
    const usAddress = currentCompany?.usMailingAddress;

    if (usAddress) {
      setValue("line1", usAddress?.line1 || "");
      setValue("line2", usAddress?.line2 || "");
      setValue("postalCode", usAddress?.postalCode || "");
      setValue("city", usAddress?.city || "", { shouldValidate: true });
    }
  }, [currentCompany?.usMailingAddress, setValue]);

  const onClickNextAddressInput = async (data: IUsAddress) => {
    setIsLoading(true);

    try {
      const newAddress = {
        line1: data.line1,
        line2: data.line2,
        postalCode: data.postalCode,
        state: currentCompany?.state || "",
        city: data.city,
        country: "USA" as Iso31661Alpha3,
      };

      await updateUsAddress({
        variables: {
          companyId: currentCompany?.id || "",
          new: newAddress,
        },
      });

      posthog?.capture("own-company-address-set");

      navigate(ROUTER_PATHS.ONBOARDING_INDUSTRY);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!currentCompany) {
    return <LoadingComponent />;
  }

  const state = stateKeys[currentCompany?.state as keyof typeof stateKeys];

  return (
    <form noValidate onSubmit={handleSubmit(onClickNextAddressInput)}>
      <Stack spacing={2}>
        <Stack spacing={1} sx={{ width: "100%" }}>
          <Typography variant="h6" color={utils.secondaryColors.secondaryMain}>
            Address Line 1
          </Typography>
          <Controller
            name="line1"
            {...COMMON_PROPS}
            rules={{
              required: true,
              pattern: {
                value: Windows1252Regex,
                message: t("error.invalidCharacters"),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                name="line1"
                placeholder="Enter Address Line 1"
                fullWidth
                error={error !== undefined}
                sx={{
                  ".MuiInputBase-root": {
                    height: "40px",
                  },
                }}
              />
            )}
          />
        </Stack>
        <Stack spacing={1} sx={{ width: "100%" }}>
          <Typography variant="h6" color={utils.secondaryColors.secondaryMain}>
            Address Line 2
          </Typography>
          <Controller
            name="line2"
            {...COMMON_PROPS}
            rules={{
              pattern: {
                value: Windows1252Regex,
                message: t("error.invalidCharacters"),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                name="line2"
                placeholder="Enter Address Line 2 (optional)"
                fullWidth
                error={error !== undefined}
                sx={{
                  ".MuiInputBase-root": {
                    height: "40px",
                  },
                }}
              />
            )}
          />
        </Stack>
        <Stack spacing={1} sx={{ width: "100%" }}>
          <Typography variant="h6" color={utils.secondaryColors.secondaryMain}>
            City
          </Typography>
          <Controller
            name="city"
            {...COMMON_PROPS}
            rules={{
              required: true,
              pattern: {
                value: Windows1252Regex,
                message: t("error.invalidCharacters"),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                name="city"
                placeholder="Enter City"
                fullWidth
                error={error !== undefined}
                sx={{
                  ".MuiInputBase-root": {
                    height: "40px",
                  },
                }}
              />
            )}
          />
        </Stack>
        <Stack spacing={1} sx={{ width: "100%" }}>
          <Typography variant="h6" color={utils.secondaryColors.secondaryMain}>
            State
          </Typography>
          <TextField
            disabled
            fullWidth
            value={state}
            sx={{
              ".MuiInputBase-root": {
                height: "40px",
                background: utils.secondaryColors.secondaryLighter,
              },
            }}
          />
        </Stack>
        <Stack spacing={1} sx={{ width: "100%" }}>
          <Typography variant="h6" color={utils.secondaryColors.secondaryMain}>
            Zip Code
          </Typography>
          <Controller
            name="postalCode"
            {...COMMON_PROPS}
            rules={{
              required: true,
              pattern: {
                value: Windows1252Regex,
                message: t("error.invalidCharacters"),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                name="postalCode"
                placeholder="Enter Zip Code"
                fullWidth
                error={error !== undefined}
                sx={{
                  ".MuiInputBase-root": {
                    height: "40px",
                  },
                }}
              />
            )}
          />
        </Stack>
        <Stack direction="row" alignItems={"center"} gap={2} pt={2}>
          <InfoIcon />
          <Typography>
            This address could appear on public state records and will be used
            to send you legal and tax documents.
          </Typography>
        </Stack>
        <ErrorBox formState={formState} />
        <ButtonBox
          isLoading={isLoading}
          disabled={!formState.isValid}
          onClickBack={onClickBackAddressInput}
          onClickNext={() => onClickNextAddressInput}
        />
      </Stack>
    </form>
  );
};

export default AddressInput;
