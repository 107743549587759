import { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  Paper,
  Popper,
  PopperPlacementType,
  Stack,
  Typography,
} from "@mui/material";
import MainCard from "components/MainCard";
import Transitions from "components/@extended/Transitions";
import { useNavigate } from "react-router-dom";
import ProfileTab from "./ProfileTab";
import Avatar from "components/@extended/Avatar";
import { LoadingComponent } from "components/Loading";
import { getInitials } from "utils/formatters";
import { removeTokens } from "utils/auth";
import { ROUTER_PATHS } from "routes/routes";
import useCompany from "graphql/hooks/UseCompany";
import useCustomer from "graphql/hooks/useCustomer";
import { useGetStripeBillingPortalLazyQuery } from "graphql/__generated__/operations/StripeBillingPortal.generated";
import { useAuthSignOutMutation } from "graphql/__generated__/operations/AuthSignOut.generated";
import config from "config";
import { colors } from "utils";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";

const RETURN_URL = `${config.basePath}${ROUTER_PATHS.DASHBOARD}`;

interface Props {
  popperPlacement?: PopperPlacementType;
}

const Profile = ({ popperPlacement = "bottom-end" }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { currentCompany } = useCompany();
  const { currentUser, isCustomerLoading } = useCustomer();
  const [authSignOut] = useAuthSignOutMutation();

  const [
    getStripeBillingPortalData,
    { data, loading: isStripeBillingLoading },
  ] = useGetStripeBillingPortalLazyQuery();

  useEffect(() => {
    if (currentUser?.stripeCustomerId) {
      getStripeBillingPortalData({
        variables: {
          customerId: currentUser?.id || "",
          returnUrl: RETURN_URL,
        },
      });
    }
  }, [
    currentUser?.id,
    currentUser?.stripeCustomerId,
    getStripeBillingPortalData,
  ]);

  const stripeCustomerPortalLink =
    data?.customer?.stripeBillingPortalMeta?.url || "";

  const anchorRef = useRef<any>(null);

  const [open, setOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await authSignOut();
    } catch {
      // error handling delegated upstream to the ErrorLink
    } finally {
      removeTokens();
      navigate(ROUTER_PATHS.LOGIN);
    }
  };

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const currentUserName = currentUser?.name || "";

  return (
    <>
      <ButtonBase
        disableRipple
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          p: 1,
          bgcolor: colors.lightGrey,
          borderRadius: 4,
          "&:hover svg": {
            color: colors.contentInverseTertiary,
            transition: "all .2s ease-in-out",
          },
          "&:focus-visible": {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2,
          },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <PermIdentityOutlinedIcon
          sx={{
            color: colors.black,
          }}
        />
      </ButtonBase>
      <Popper
        placement={popperPlacement}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal={false}
        sx={{ zIndex: "1200" }}
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
            {
              name: "preventOverflow",
              enabled: true,
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down("md")]: {
                    maxWidth: 250,
                  },
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    <CardContent sx={{ px: 2.5, pt: 3 }}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Stack
                            direction="row"
                            spacing={1.25}
                            alignItems="center"
                          >
                            <Avatar
                              type="outlined"
                              sx={{ bgcolor: "#FF7A45" }}
                              {...getInitials(currentUserName)}
                            />
                            <Stack>
                              <Typography variant="h6">
                                {currentUserName}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                {currentCompany?.name}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                    </CardContent>
                    {isCustomerLoading || isStripeBillingLoading ? (
                      <CardContent sx={{ px: 2.5, pt: 3 }}>
                        <LoadingComponent />
                      </CardContent>
                    ) : (
                      open && (
                        <ProfileTab
                          handleLogout={handleLogout}
                          stripeCustomerPortalLink={
                            stripeCustomerPortalLink || ""
                          }
                        />
                      )
                    )}
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default Profile;
