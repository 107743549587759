import { css } from "styled-components";
import { colors } from "utils";

export const CustomWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: ${colors.darkGrey};
    cursor: pointer;
  }
`;
