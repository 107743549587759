import { useCallback, useEffect } from "react";
//TODO : revert after doc service 2.0 is ready
// import { useGetCompanyLazyQuery } from "graphql/__generated__/operations/Company.generated";
import { useGetCompanyWithoutDocServiceLazyQuery } from "graphql/__generated__/operations/CompanyWithoutDocService.generated";
import { useGetTokenFieldsQuery } from "graphql/__generated__/operations/Whoami.generated";
import { useAppSelector } from "state/hooks";
import { selectCurrentCompanyId } from "state/authSlice";
import { Company } from "graphql/__generated__/types";

// eslint-disable-next-line no-warning-comments
// TODO : Remove this temporary patch after doc service is fixed from backend team

interface ICompany {
  currentCompany: Company;
  isCompanyLoading: boolean;
  refetchCompany: () => Promise<void>;
}

//TODO : Clean whole hook after doc service 2.0 is ready
const useCompany = (): ICompany => {
  const { data: tokenData } = useGetTokenFieldsQuery();

  //TODO : revert after doc service 2.0 is ready
  // const [fetchCompany, { data, fetchMore, refetch }] = useGetCompanyLazyQuery({
  //   errorPolicy: "all",
  // });

  //TODO : remove after doc service 2.0 is ready
  const [
    fetchCompanyWithoutDocService,
    {
      data,
      // data: dataCompanyWithoutDocService,
      loading: isCompanyLoading,
      fetchMore: fetchMoreCompanyWithoutDocService,
      refetch: refetchCompanyWithoutDocService,
    },
  ] = useGetCompanyWithoutDocServiceLazyQuery({
    errorPolicy: "all",
  });

  const currentCompanyId = useAppSelector(selectCurrentCompanyId) || "";

  const getCurrentCompanyId = useCallback(() => {
    const companyIds = tokenData?.myToken?.authorizedCompanyIds?.split(",");
    const selectedCompanyId =
      companyIds && [...companyIds].includes(currentCompanyId)
        ? currentCompanyId
        : companyIds?.[0];

    return selectedCompanyId;
  }, [currentCompanyId, tokenData?.myToken?.authorizedCompanyIds]);

  const refetchCompany = async () => {
    const selectedCompanyId = getCurrentCompanyId();

    //TODO : revert after doc service 2.0 is ready
    // await refetch({
    //   companyId: selectedCompanyId,
    // });
    await refetchCompanyWithoutDocService({
      companyId: selectedCompanyId,
    });
  };

  const pageInfo = data?.company?.documents?.pageInfo;

  useEffect(() => {
    const selectedCompanyId = getCurrentCompanyId();

    if (selectedCompanyId) {
      //TODO : revert after doc service 2.0 is ready
      // fetchCompany({
      //   variables: { companyId: selectedCompanyId },
      // });
      fetchCompanyWithoutDocService({
        variables: { companyId: selectedCompanyId },
      });
    }
  }, [
    currentCompanyId,
    //TODO : revert after doc service 2.0 is ready
    // fetchCompany,
    fetchCompanyWithoutDocService,
    getCurrentCompanyId,
    tokenData?.myToken?.authorizedCompanyIds,
  ]);

  useEffect(() => {
    const edges = data?.company?.documents?.edges;
    const cursor = edges?.[edges.length - 1]?.cursor || "";

    const selectedCompanyId = getCurrentCompanyId();

    if (pageInfo?.hasNextPage && cursor && selectedCompanyId) {
      //TODO : revert after doc service 2.0 is ready
      // fetchMore({
      //   variables: {
      //     after: cursor,
      //   },
      // });
      fetchMoreCompanyWithoutDocService({
        variables: {
          after: cursor,
        },
      });
    }
  }, [
    currentCompanyId,
    data?.company?.documents?.edges,
    //TODO : revert after doc service 2.0 is ready
    // fetchMore,
    fetchMoreCompanyWithoutDocService,
    getCurrentCompanyId,
    pageInfo?.hasNextPage,
    tokenData?.myToken?.authorizedCompanyIds,
  ]);

  return {
    currentCompany: data?.company as Company,
    isCompanyLoading,
    refetchCompany,
  };
};

export default useCompany;
