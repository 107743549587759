import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Stack,
  Tooltip,
} from "@mui/material";
import { Member } from "graphql/__generated__/types";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { ExpandMoreRounded } from "@mui/icons-material";

type Props = {
  member: Member;
  isResponsibleParty?: boolean;
};

const MemberExpandable = ({ member, isResponsibleParty = false }: Props) => {
  return (
    <Accordion
      disableGutters
      onChange={() => {}}
      sx={{
        mb: 1,
        mt: 0,
        background: "none",
        border: "none",
        boxShadow: "none",
      }}
    >
      <AccordionSummary sx={{ padding: 0 }} expandIcon={<ExpandMoreRounded />}>
        <Stack direction="row" spacing={2}>
          <Stack>
            <Stack direction="row" gap={1} alignItems="center">
              {isResponsibleParty && (
                <Tooltip title="Responsible Party" placement="top" arrow>
                  <PersonOutlineOutlinedIcon />
                </Tooltip>
              )}
              <Typography variant="h5">{`${member.contactFullName}`}</Typography>
              <Typography
                variant="h5"
                sx={{ "&:before": { content: "' • '", mr: 0.5 } }}
                fontWeight={500}
              >
                {member.ownershipPercent || 0}%
              </Typography>
            </Stack>
            <Typography>SSN 🇺🇸: {member.ssn ? "Provided" : " N/A"}</Typography>
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ mb: 2, pl: 0 }}>
        <Stack direction="column">
          <Typography mb={1 / 2}>{member.address.phone}</Typography>
          <Typography>{member.address.line1}</Typography>
          {member.address.line2 && (
            <Typography>{member.address.line2}</Typography>
          )}
          <Typography>{`${member.address.city}, ${member.address.state}, ${member.address.postalCode}`}</Typography>
          <Typography>{member.address.country}</Typography>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default MemberExpandable;
