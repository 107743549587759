import { useCallback, useEffect, useState } from "react";
import { useGetMoneyCurrencyLazyQuery } from "graphql/__generated__/operations/MoneyCurrency.generated";
import { CurrencyDetail } from "graphql/__generated__/types";

interface ICurrency {
  getCurrency: (countryCode: string) => Promise<void>;
  currencyData?: CurrencyDetail | null;
  isCurrencyDataLoading: boolean;
}

export default function useGetCurrency(countryCode?: string): ICurrency {
  const [isCurrencyDataLoading, setIsCurrencyDataLoading] =
    useState<boolean>(true);
  const [currencyData, setCurrencyData] = useState<CurrencyDetail | null>();

  const [fetchCurrency] = useGetMoneyCurrencyLazyQuery({
    errorPolicy: "all",
  });

  const getCurrency = useCallback(
    async (countryCode: string) => {
      setIsCurrencyDataLoading(true);

      try {
        const { data: currencyData } = await fetchCurrency({
          variables: { countryCode },
        });

        const currency = currencyData?.getCurrency?.payload?.[0];

        if (currency) {
          setCurrencyData(currency);
        }
      } catch (error) {
        console.log("Failed to fetch currency data, error: ", error);
      } finally {
        setIsCurrencyDataLoading(false);
      }
    },
    [fetchCurrency]
  );

  useEffect(() => {
    if (countryCode) {
      getCurrency(countryCode);
    }
  }, [getCurrency, countryCode]);

  return {
    getCurrency,
    currencyData,
    isCurrencyDataLoading,
  };
}
