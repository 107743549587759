import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCompanyAcceptTosMutationVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
}>;


export type UpdateCompanyAcceptTosMutation = { __typename?: 'Mutation', company?: { __typename?: 'CompanyMutation', acceptTos?: { __typename?: 'Company', id: string } | null } | null };


export const UpdateCompanyAcceptTosDocument = gql`
    mutation UpdateCompanyAcceptTos($companyId: ID!) {
  company(id: $companyId) {
    acceptTos {
      id
    }
  }
}
    `;
export type UpdateCompanyAcceptTosMutationFn = Apollo.MutationFunction<UpdateCompanyAcceptTosMutation, UpdateCompanyAcceptTosMutationVariables>;

/**
 * __useUpdateCompanyAcceptTosMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyAcceptTosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyAcceptTosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyAcceptTosMutation, { data, loading, error }] = useUpdateCompanyAcceptTosMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useUpdateCompanyAcceptTosMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyAcceptTosMutation, UpdateCompanyAcceptTosMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyAcceptTosMutation, UpdateCompanyAcceptTosMutationVariables>(UpdateCompanyAcceptTosDocument, options);
      }
export type UpdateCompanyAcceptTosMutationHookResult = ReturnType<typeof useUpdateCompanyAcceptTosMutation>;
export type UpdateCompanyAcceptTosMutationResult = Apollo.MutationResult<UpdateCompanyAcceptTosMutation>;
export type UpdateCompanyAcceptTosMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyAcceptTosMutation, UpdateCompanyAcceptTosMutationVariables>;