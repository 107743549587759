import { useEffect } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import { ROUTER_PATHS } from "routes/routes";
import GuestGuard from "utils/route-guard/GuestGuard";
import EntryPage from "views/EntryPage";
import LoginRoutes from "./LoginRoutes";
import MainDashboardRoutes from "./MainDashboardRoutes";
import SignupRoutes from "./SignupRoutes";
import OnboardingRoutes from "./OnboardingRoutes";
import { usePostHog } from "posthog-js/react";

export default function Routes() {
  const location = useLocation();
  const posthog = usePostHog();

  useEffect(() => {
    posthog?.capture("$pageview");
  }, [location, posthog]);

  return useRoutes([
    {
      path: "/",
      element: (
        <GuestGuard>
          <EntryPage />
        </GuestGuard>
      ),
    },
    {
      path: "*",
      element: <Navigate to={ROUTER_PATHS.DASHBOARD} />,
    },
    LoginRoutes,
    MainDashboardRoutes,
    SignupRoutes,
    OnboardingRoutes,
  ]);
}
