import dayjs from "dayjs";

// Get all the month names up to the current date for the year passed as an arg
export const getMonthNameUptoCurrentDate = (
  accountCreatedDate: string,
  year: number
) => {
  const currentDate = dayjs();
  const currentYear = currentDate.year();
  const isCurrentYear = year === currentYear;
  const accountCreatedMonthIndex = dayjs(accountCreatedDate).month();
  const accountCreatedYear = dayjs(accountCreatedDate).year();

  if (year > currentYear) {
    console.error("Invalid year. Cannot get statements for future year.");
    return [];
  }

  const startMonth = 0;
  const endMonth = isCurrentYear ? currentDate.month() + 1 : 12;

  const monthNamesArray = Array.from(
    { length: endMonth - startMonth },
    (_, index) => dayjs(`${year}-${index + 1}-01`).format("MMMM")
  );

  if (accountCreatedYear === year) {
    return monthNamesArray.slice(accountCreatedMonthIndex);
  }

  return monthNamesArray;
};

export const base64ToBlob = (base64Data: string) => {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = Array.from(slice, (char) => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: "application/octet-stream" });
};

export const getStartAndEndDatesOfAMonth = (
  year: number,
  monthName: string
) => {
  const monthsArray = Array.from({ length: 12 }, (_, index) =>
    dayjs().month(index).format("MMMM")
  );

  const monthIndex = monthsArray.findIndex((month) => month === monthName);

  const dateForMonth = dayjs().year(year).month(monthIndex);

  // Get the end date of the specified month
  const endDate = dateForMonth.endOf("month");
  const formattedEndDate = endDate.format("YYYY-MM-DD");

  // Get the start date of the specified month
  const startDate = dateForMonth.startOf("month");
  const formattedStartDate = startDate.format("YYYY-MM-DD");

  return { startDate: formattedStartDate, endDate: formattedEndDate };
};

// This function returns all the years starting from the accountCreatedYear to currentYear
export const getYearsFromAccountCreatedYearToCurrYear = (
  accountCreatedYear: number
): number[] => {
  const currentYear = dayjs().year();

  return Array.from(
    { length: currentYear - accountCreatedYear + 1 },
    (_, index) => currentYear - index
  );
};
