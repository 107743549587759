import { useCallback, useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { LoadingComponent } from "components/Loading";
import useCompany from "graphql/hooks/UseCompany";
import DropzoneContainer from "./DropzoneContainer";
import { LinkExternalDomain } from "components/LinkExternalDomain";
import { DocumentCard } from "./DocumentCard";
import { Stack } from "@mui/material";

const Documents = () => {
  const [showUpload, setShowUpload] = useState(false);
  const { currentCompany, isCompanyLoading, refetchCompany } = useCompany();

  const documents = currentCompany?.documents?.edges;

  const hasLoadedRef = useRef(false);

  const reFetch = useCallback(async () => {
    await refetchCompany();
  }, [refetchCompany]);

  useEffect(() => {
    if (!hasLoadedRef.current) {
      reFetch();
      hasLoadedRef.current = true;
    }
  }, [reFetch]);

  if (isCompanyLoading) return <LoadingComponent />;

  if (documents?.length) {
    const filteredDocs = documents?.filter(Boolean);

    return (
      <>
        <Grid container spacing={3}>
          <Grid container item lg={12}>
            <Stack mb={5}>
              <Typography variant="h2" mb={1}>
                Documents
              </Typography>
              <Typography variant="subtitle1" fontWeight={400}>
                You have {filteredDocs.length} documents
              </Typography>
            </Stack>
            {/* <Grid item xs={4}>
              <CustomButton
                variant={CustomButtonVariants.black}
                onClick={() => setShowUpload(true)}
              >
                Upload
              </CustomButton>
            </Grid> */}
          </Grid>
          {filteredDocs.map((doc, index) => (
            <Grid item lg={3} xs={12} key={index}>
              <LinkExternalDomain to={doc?.node.url || ""}>
                <DocumentCard
                  name={doc?.node.fileName || doc?.node.name || ""}
                  type={doc?.node.type || ""}
                  img={doc?.node.url || ""}
                />
              </LinkExternalDomain>
            </Grid>
          ))}
        </Grid>
        {showUpload && (
          <DropzoneContainer handleClose={() => setShowUpload(false)} />
        )}
      </>
    );
  }

  return (
    <Grid
      container
      spacing={2}
      m={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ height: "calc(100vh - 118px)" }}
    >
      <div style={{ opacity: 0.75 }}>
        <Typography align="center" variant="body2">
          There are currently no documents associated with your account.
          <br />
          As documents are created, they will start to be available here.
        </Typography>
      </div>
      {/* <CustomButton
        style={{ marginTop: "16px", marginBottom: "0px" }}
        variant={CustomButtonVariants.black}
        onClick={() => setShowUpload(true)}
      >
        Upload
      </CustomButton> */}
      {showUpload && (
        <DropzoneContainer handleClose={() => setShowUpload(false)} />
      )}
    </Grid>
  );
};

export default Documents;
