import { useCallback, useEffect, useState } from "react";

const useCountdown = (targetDate: Date) => {
  const targetDateTime = targetDate.getTime();

  const calculateTimeLeft = useCallback(() => {
    const now = new Date().getTime();
    const timeLeft = targetDateTime - now;

    const months = Math.floor(timeLeft / (1000 * 60 * 60 * 24 * 30));
    const days = Math.floor(
      (timeLeft % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24)
    );
    const hours = Math.floor(
      (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );

    return { months, days, hours };
  }, [targetDateTime]);

  const [countdown, setCountdown] = useState(calculateTimeLeft());

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(calculateTimeLeft());
    }, 60000);

    return () => clearInterval(interval);
  }, [targetDateTime, calculateTimeLeft]);

  return [countdown.months, countdown.days, countdown.hours];
};

export { useCountdown };
