import { Box, Stack } from "@mui/material";
import Profile from "./Profile";
import Notification from "components/Notification";

const HeaderContent = () => {
  return (
    <>
      <Box sx={{ width: "100%", ml: { xs: 0, md: 1 } }} />
      <Stack direction={"row"} alignItems={"center"} gap={1}>
        <Notification />
        <Profile />
      </Stack>
    </>
  );
};

export default HeaderContent;
