import { Box, Stack, Typography } from "@mui/material";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { colors } from "utils";
import { Trans, useTranslation } from "react-i18next";
import { LinkExternalDomain } from "components/LinkExternalDomain";

const LLCDetailLink =
  "https://www.ftb.ca.gov/file/business/types/limited-liability-company/index.html";

const CorpDetailLink =
  "https://www.ftb.ca.gov/file/business/types/corporations/index.html";

const StateNotification = () => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        padding: 3,
        borderRadius: "24px",
        bgcolor: colors.lightBackgroundWarning,
      }}
      gap={2}
    >
      <WarningAmberRoundedIcon />
      <Box fontSize={16}>
        <Typography fontSize={16} fontWeight={500} component="span">
          {t("general.note")}
        </Typography>{" "}
        <Trans
          i18nKey={"signup.companyState.notification.text.1"}
          values={{
            corpLink: t("general.here"),
            llcLink: t("general.here"),
          }}
          components={[
            <LinkExternalDomain
              to={CorpDetailLink}
              style={{ fontSize: 16, fontWeight: 500 }}
            />,
            <LinkExternalDomain
              to={LLCDetailLink}
              style={{ fontSize: 16, fontWeight: 500 }}
            />,
          ]}
        />
      </Box>
      <Stack fontSize={16}>
        {t("signup.companyState.notification.text.2")}
      </Stack>
    </Stack>
  );
};

export default StateNotification;
