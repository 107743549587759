import { NavigateNext } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import { Logo } from "components/Logos";
import { ROUTER_PATHS } from "routes/routes";
import { MoneyLandingPageFeatures } from "./data";
import utils, { colors } from "utils";
import Button from "components/Button";
import { usePostHog } from "posthog-js/react";
import useGetMoneyAccounts from "../hooks/useGetMoneyAccounts";

const MoneyLandingPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { isAccountsDataLoading, isPendingReview } = useGetMoneyAccounts();

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  return (
    <Stack gap={isTablet ? 3 : 10}>
      <Stack
        direction={isTablet ? "column" : "row"}
        gap={isTablet ? 5 : 10}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack width={"100%"}>
          <Stack direction={"row"}>
            <Logo width={60} />
            <Typography
              variant="body1"
              ml={0.5}
              mt={0.5}
              fontSize={16}
              fontWeight={500}
            >
              {t("money")}
            </Typography>
          </Stack>
          <Typography variant="h1" fontWeight={700} mt={3} mb={4}>
            {t("money.landingPage.heroTitle")}
          </Typography>
          <Stack direction={"row"} gap={2}>
            <Button
              dark={!isPendingReview}
              isLoading={isAccountsDataLoading}
              disabled={isPendingReview}
              onClick={() => {
                navigate(ROUTER_PATHS.MONEY_ONBOARDING);
                posthog?.capture("money_landing_page_apply_now_btn_click");
              }}
              buttonText={t(
                isPendingReview ? "general.pendingReview" : "general.applyNow"
              )}
              endIcon={isPendingReview ? <></> : <NavigateNext />}
            />
            <Link
              to={"https://doola.com/money/"}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                posthog?.capture("money_landing_page_learn_more_btn_click")
              }
            >
              <Button dark={false} buttonText={t("general.learnMore")} />
            </Link>
          </Stack>
        </Stack>
        <Stack width={"100%"}>
          <iframe
            height={320}
            src="https://www.youtube.com/embed/Wo0oZTdKuo0?si=WWWoCO_Mm7fQ2bLO"
            title="doola Money"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            style={{
              border: "none",
              outline: "none",
              borderRadius: isTablet ? "24px" : "32px",
            }}
          />
        </Stack>
      </Stack>
      <Stack direction={isTablet ? "column" : "row"} gap={4}>
        {MoneyLandingPageFeatures.map((feature, index) => (
          <Stack
            key={index}
            width={"100%"}
            p={4}
            borderRadius={isTablet ? 6 : 8}
            bgcolor={index === 0 ? colors.lightPrimaryBlue : colors.lightGrey}
          >
            <img src={feature.image} alt="doola Money" width={"100%"} />
            <Typography variant="h3" mb={1} mt={4}>
              {t(feature.title)}
            </Typography>
            <Typography variant="body1">{t(feature.description)}</Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default MoneyLandingPage;
