import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AuthSignOutMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type AuthSignOutMutation = { __typename?: 'Mutation', auth?: { __typename?: 'AuthMutation', signOut?: { __typename?: 'AuthResponse', success: boolean, message: string } | null } | null };


export const AuthSignOutDocument = gql`
    mutation AuthSignOut {
  auth {
    signOut {
      success
      message
    }
  }
}
    `;
export type AuthSignOutMutationFn = Apollo.MutationFunction<AuthSignOutMutation, AuthSignOutMutationVariables>;

/**
 * __useAuthSignOutMutation__
 *
 * To run a mutation, you first call `useAuthSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authSignOutMutation, { data, loading, error }] = useAuthSignOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useAuthSignOutMutation(baseOptions?: Apollo.MutationHookOptions<AuthSignOutMutation, AuthSignOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthSignOutMutation, AuthSignOutMutationVariables>(AuthSignOutDocument, options);
      }
export type AuthSignOutMutationHookResult = ReturnType<typeof useAuthSignOutMutation>;
export type AuthSignOutMutationResult = Apollo.MutationResult<AuthSignOutMutation>;
export type AuthSignOutMutationOptions = Apollo.BaseMutationOptions<AuthSignOutMutation, AuthSignOutMutationVariables>;