import { Stack, Typography, useMediaQuery } from "@mui/material";
import { ReactComponent as UserDairy } from "assets/Money/UserDairy.svg";
import { useTranslation } from "react-i18next";
import utils from "utils";

const NoRecentlyPaidUI = () => {
  const { t } = useTranslation();
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  return (
    <Stack alignItems={"center"} gap={3}>
      <UserDairy width="100%" height={isTablet ? "80px" : "110px"} />
      <Stack textAlign={"center"}>
        <Typography mb={1} fontSize={"16px"} fontWeight={500}>
          {t("money.overview.noRecentyPaidTitle")}
        </Typography>
        <Typography fontSize={"16px"}>
          {t("money.overview.noRecentyPaidSubtitle")}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default NoRecentlyPaidUI;
