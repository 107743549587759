import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCompanyActivityOtherMutationVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  activity: Types.Scalars['String'];
}>;


export type UpdateCompanyActivityOtherMutation = { __typename?: 'Mutation', company?: { __typename?: 'CompanyMutation', updateActivityOther?: { __typename?: 'Company', id: string, activityOther?: string | null, services?: Array<{ __typename?: 'Services', service: Types.CompanyServices, variant?: Types.ServiceVariant | null, status: Types.ServiceCategory, subStatus?: string | null }> | null } | null } | null };


export const UpdateCompanyActivityOtherDocument = gql`
    mutation UpdateCompanyActivityOther($companyId: ID!, $activity: String!) {
  company(id: $companyId) {
    updateActivityOther(new: $activity) {
      id
      activityOther
      services {
        service
        variant
        status
        subStatus
      }
    }
  }
}
    `;
export type UpdateCompanyActivityOtherMutationFn = Apollo.MutationFunction<UpdateCompanyActivityOtherMutation, UpdateCompanyActivityOtherMutationVariables>;

/**
 * __useUpdateCompanyActivityOtherMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyActivityOtherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyActivityOtherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyActivityOtherMutation, { data, loading, error }] = useUpdateCompanyActivityOtherMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      activity: // value for 'activity'
 *   },
 * });
 */
export function useUpdateCompanyActivityOtherMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyActivityOtherMutation, UpdateCompanyActivityOtherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyActivityOtherMutation, UpdateCompanyActivityOtherMutationVariables>(UpdateCompanyActivityOtherDocument, options);
      }
export type UpdateCompanyActivityOtherMutationHookResult = ReturnType<typeof useUpdateCompanyActivityOtherMutation>;
export type UpdateCompanyActivityOtherMutationResult = Apollo.MutationResult<UpdateCompanyActivityOtherMutation>;
export type UpdateCompanyActivityOtherMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyActivityOtherMutation, UpdateCompanyActivityOtherMutationVariables>;