import {
  CorporateFareOutlined,
  FormatListBulletedRounded,
  PeopleOutlined,
  HelpOutlineRounded,
  DescriptionOutlined,
  CalendarMonthOutlined,
} from "@mui/icons-material";

// Questions Responses
export enum TaxQuestionResponses {
  YES = "Yes",
  NO = "No",
}

// Define step categories
export enum TaxFilingStepsCategories {
  prepareInfo = "prepareInfo",
  companyMembers = "companyMembers",
  companyInformation = "companyInformation",
  questions = "questions",
  taxDocuments = "taxDocuments",
  bookConsultation = "bookConsultation",
  // signDocuments = "signDocuments",
}

// Define step type
export interface ITaxFilingStep {
  title: string;
  icon: React.ReactElement;
  description?: string;
}

export const TaxFilingStepsData: Record<
  TaxFilingStepsCategories,
  ITaxFilingStep
> = {
  [TaxFilingStepsCategories.prepareInfo]: {
    title: "dashboard.tax.taxFilingModal.step.prepareInfo.title",
    icon: <FormatListBulletedRounded />,
  },
  [TaxFilingStepsCategories.companyMembers]: {
    title: "dashboard.tax.taxFilingModal.step.members.title",
    icon: <PeopleOutlined />,
    description: "dashboard.tax.taxFilingModal.step.members.description",
  },
  [TaxFilingStepsCategories.companyInformation]: {
    title: "dashboard.tax.taxFilingModal.step.companyInformation.title",
    icon: <CorporateFareOutlined />,
  },
  [TaxFilingStepsCategories.questions]: {
    title: "dashboard.tax.taxFilingModal.step.questions.title",
    icon: <HelpOutlineRounded />,
  },
  [TaxFilingStepsCategories.taxDocuments]: {
    title: "dashboard.tax.taxFilingModal.step.taxDocuments.title",
    icon: <DescriptionOutlined />,
    description: "dashboard.tax.taxFilingModal.step.taxDocuments.description",
  },
  [TaxFilingStepsCategories.bookConsultation]: {
    title: "dashboard.tax.taxFilingModal.step.bookConsultation.title",
    icon: <CalendarMonthOutlined />,
    description:
      "dashboard.tax.taxFilingModal.step.bookConsultation.description",
  },
  // [TaxFilingStepsCategories.signDocuments]: {
  //   title: "dashboard.tax.taxFilingModal.step.signDocuments.title",
  //   icon: <FingerprintRounded />,
  //   description: "dashboard.tax.taxFilingModal.step.signDocuments.description",
  // },
};
