import { Customer, Company, AddressType } from "graphql/__generated__/types";

type Props = {
  user: Customer;
  company: Company;
};

const formatAddress = (company: Company) => {
  const addressType = company.addressType;

  if (addressType === AddressType.FIRSTMEMBER) {
    return "Your mailing address (the one displayed here)";
  }

  return addressType;
};

export const buildProfileData = ({ user, company }: Props) => {
  return {
    contactDetails: [
      {
        label: "Full Name",
        value: user.name || "-",
      },
      {
        label: "Email address",
        value: user.email || "-",
      },
      {
        label: "Phone number",
        value: company.members?.[0]?.address.phone || user.phoneNumber || "-",
      },
    ],
    companyInfo: [
      {
        label: "Company Name",
        value: company.name || "-",
      },
      {
        label: "Entity type",
        value: company.entityType || "-",
      },
      {
        label: "Activity",
        value: company.activity || "-",
      },
      {
        label: "Description",
        value: company.description || "-",
      },
      {
        label: "Website",
        value: company.url || "-",
      },
    ],
    mailingAddress: [
      {
        label: "Address line 1",
        value: company.members?.[0]?.address.line1 || "-",
      },
      {
        label: "Address line 2",
        value: company.members?.[0]?.address.line2 || "-",
      },
      {
        label: "City",
        value: company.members?.[0]?.address.city || "-",
      },
      {
        label: "State",
        value: company.members?.[0]?.address.state || "-",
      },
      {
        label: "Zip / Postal code",
        value: company.members?.[0]?.address.postalCode || "-",
      },
      {
        label: "Country",
        value: company.members?.[0]?.address.country || "-",
      },
      {
        label: "Address for your new company",
        value: formatAddress(company) || "",
      },
    ],
    memberData: (company.members || []).map((member) => {
      const data = [
        {
          label: "Full name",
          value: `${member.legalFirstName} ${member.legalLastName}`,
        },
        {
          label: "Ownership",
          value: `${member.ownershipPercent}%`,
        },
        {
          label: "Is a company",
          value: member.isNaturalPerson ? "no" : "yes",
        },
        {
          label: "Company Name (only for company as a member)",
          value: member.isNaturalPerson ? "-" : member?.contactFullName || "-",
        },
      ];

      return data;
    }, []),
    extraData: [
      {
        label: "Your SSN",
        value: user.ssn || "-",
        sensitiveData: !!user.ssn,
      },
      {
        label: "Company ITIN",
        value: company.ITIN || "-",
        sensitiveData: !!company.ITIN,
      },
      {
        label: "Company EIN",
        value: company.EIN || "-",
        sensitiveData: !!company.EIN,
      },
    ],
  };
};
