import { useAuthSignInMutation } from "graphql/__generated__/operations/AuthSignIn.generated";
import { useAuthSignOutMutation } from "graphql/__generated__/operations/AuthSignOut.generated";
import { useGetCustomerLazyQuery } from "graphql/__generated__/operations/Customer.generated";
import { useGetTokenFieldsLazyQuery } from "graphql/__generated__/operations/Whoami.generated";
import { Customer } from "graphql/__generated__/types";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "state/hooks";
import { logOutUser } from "state/authSlice";
import { removeTokens, setTokens } from "../utils/auth";
import { toast } from "toast";
import { ROUTER_PATHS } from "routes/routes";
import { usePostHog } from "posthog-js/react";
import { useGetCompanyWithoutDocServiceLazyQuery } from "graphql/__generated__/operations/CompanyWithoutDocService.generated";

export default function useSignInAndValidateUser() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const posthog = usePostHog();

  const [getCurrentUser] = useGetCustomerLazyQuery();
  //TODO : switch back to useGetCompanyLazyQuery after getCompany is fixed
  const [getCurrentCompany] = useGetCompanyWithoutDocServiceLazyQuery();
  const [getTokenFields] = useGetTokenFieldsLazyQuery();
  const [authSignIn] = useAuthSignInMutation();
  const [authSignOut] = useAuthSignOutMutation();

  const internalDashboardRoute =
    location.state?.from?.pathname + location.state?.from?.search ||
    ROUTER_PATHS.DASHBOARD; // redirect to an authenticated route when logged in

  const _validateUser = async (user: Customer) => {
    const companyId = user?.companyIds?.[0];

    try {
      if (companyId && user.id) {
        const { data: currentCompany } = await getCurrentCompany({
          variables: {
            companyId,
          },
          errorPolicy: "all",
        });

        const hasServices = !!currentCompany?.company?.services?.length;

        if (hasServices) {
          return navigate(internalDashboardRoute);
        } else {
          // if the user doesn't have services, they must still be part of the signup flow
          return navigate(ROUTER_PATHS.PRE_PURCHASE_TC);
        }
      } else {
        dispatch(logOutUser());
        await _handleLogout();
      }
    } catch (error) {
      throw error;
    }
  };

  const _handleLogout = async () => {
    try {
      await authSignOut();

      removeTokens();

      toast.error(
        "It seems that there's not company associated to your account. Please contact our team via live chat."
      );

      return navigate(ROUTER_PATHS.LOGIN);
    } catch (error) {
      throw error;
    }
  };

  const loginAndFetchData = async (email: string, password: string) => {
    try {
      const signInData = await authSignIn({
        variables: { credentials: { email, password } },
      });

      if (!signInData.data?.auth?.signIn?.success) {
        throw new Error();
      }

      const tokens = signInData.data?.auth?.signIn?.tokens;

      if (tokens) {
        setTokens(tokens);
      }

      const { data } = await getTokenFields({
        fetchPolicy: "network-only",
      });

      const customerId = data?.myToken?.authorizedCustomerId || "";

      const { data: currentUser } = await getCurrentUser({
        variables: {
          customerId,
        },
        errorPolicy: "all",
      });

      posthog?.capture("login-successful", { email_attempted: email });

      if (currentUser?.customer) {
        await _validateUser(currentUser.customer);
      }
    } catch (error) {
      posthog?.capture("login-failed", { email_attempted: email });

      throw error;
    }
  };

  return { loginAndFetchData };
}
