import { IStepProps } from "../../types";
import { Dispatch, SetStateAction, useState } from "react";
import { ISubStepProps, membersSubSteps } from "./types";
import MembersInformation from "./SubSteps/MemberInformation";
import OwnershipPercentage from "./SubSteps/OwnershipPercentage";

interface IMembersInformationProps extends IStepProps {
  setShowHeading: Dispatch<SetStateAction<boolean>>;
}

const Member = ({
  setCurrentStep,
  setShowHeading,
}: IMembersInformationProps) => {
  const [currentSubStep, setCurrentSubStep] = useState<membersSubSteps>(
    membersSubSteps.membersInformation
  );

  const renderSubStepContent = (currentSubStep: membersSubSteps) => {
    const commonProps: ISubStepProps = {
      setCurrentStep: setCurrentStep,
      setCurrentSubStep: setCurrentSubStep,
      setShowHeading: setShowHeading,
    };

    switch (currentSubStep) {
      case membersSubSteps.membersInformation:
        return <MembersInformation {...commonProps} />;

      case membersSubSteps.ownershipPercentage:
        return <OwnershipPercentage {...commonProps} />;
    }
  };

  return <>{renderSubStepContent(currentSubStep)}</>;
};

export default Member;
