import { useDropzone } from "react-dropzone";
import { Chip, Stack, styled } from "@mui/material";
import { CustomButton } from "components/Buttons";
import { CustomButtonVariants } from "components/Buttons/types";

// we should define our own colors
const getColor = (props: any) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const StyledContainer = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "40px",
  borderWidth: "2px",
  borderRadius: "10px",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "black",
  fontWeight: "bold",
  fontSize: "1.4rem",
  outline: "none",
  transition: "border 0.24s ease-in-out",
  "&:hover": {
    cursor: "pointer",
  },
});

type Props = {
  onDrop: (acceptedFiles: any) => void;
  files: File[] | null;
  onRemoveFile: (path: string) => void;
  handleFileUpload: () => void;
};

const DropBox = ({ onDrop, files, onRemoveFile, handleFileUpload }: Props) => {
  const { getRootProps, getInputProps, isDragAccept, isFocused, isDragReject } =
    useDropzone({
      onDrop,
      noKeyboard: true,
    });

  const renderChips = files?.map((file: any, index: number) => (
    <Chip
      key={index}
      label={file.path}
      onClick={() => {}}
      onDelete={() => onRemoveFile(file.path)}
    />
  ));

  return (
    <>
      <StyledContainer
        style={{
          borderColor: getColor({ isDragAccept, isFocused, isDragReject }),
        }}
        className="dropbox"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <p>Drop files here, or click to select file</p>
      </StyledContainer>
      <Stack flexWrap="wrap" gap={1} direction="row" spacing={1} mt={2} mb={2}>
        {renderChips}
      </Stack>
      <CustomButton
        onClick={handleFileUpload}
        disabled={!files || files.length === 0}
        width="100%"
        $variant={CustomButtonVariants.green}
      >
        Upload
      </CustomButton>
    </>
  );
};

export default DropBox;
