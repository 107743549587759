import { Typography, Stack } from "@mui/material";
import FormControlElement from "../FormControlElement";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { AddressType } from "graphql/__generated__/types";
import ButtonBox from "components/ButtonBox";

type Props = {
  showUSAddress: boolean;
  isLoading: boolean;
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  state: string;
  selectedAddressType: AddressType | "";
  onClickBackAddressType: () => void;
  onClickNextAddressType: () => void;
};

const AddressTypeSelection = ({
  showUSAddress,
  isLoading,
  onChangeHandler,
  state,
  selectedAddressType,
  onClickBackAddressType,
  onClickNextAddressType,
}: Props) => {
  return (
    <>
      <Stack direction="column" gap={2}>
        {showUSAddress && (
          <FormControlElement
            title="I have my own address"
            subtitle={`Great if you have a place of business within the state of ${state}`}
            optionValue={`${AddressType.OTHERUS}`}
            selectedValue={selectedAddressType}
            changeHandler={onChangeHandler}
          />
        )}
        <FormControlElement
          title="Registered Agent Address"
          subtitle="Only for receiving state compliance notifications"
          optionValue={`${AddressType.REGISTEREDAGENT}`}
          selectedValue={selectedAddressType}
          changeHandler={onChangeHandler}
          chipText={"Included"}
        />
      </Stack>
      <Stack direction="row" alignItems={"center"} gap={2}>
        <InfoIcon />
        <Typography>
          The state of {state} requires an address located within {state} on
          file to send state compliance notifications.
        </Typography>
      </Stack>
      <ButtonBox
        isLoading={isLoading}
        disabled={!selectedAddressType}
        onClickBack={onClickBackAddressType}
        onClickNext={onClickNextAddressType}
      />
    </>
  );
};

export default AddressTypeSelection;
