import { Dispatch, SetStateAction, useState } from "react";
import { IStepProps } from "../../types";
import { ISubStepProps, companySubSteps } from "./types";
import CompanyInformation from "./SubSteps/CompanyInformation";
import RegisteredAddress from "./SubSteps/RegisteredAddress";
import EinLetter from "./SubSteps/EinLetter";

interface ICompanyProps extends IStepProps {
  setShowHeading: Dispatch<SetStateAction<boolean>>;
}

const Company = ({ setCurrentStep, setShowHeading }: ICompanyProps) => {
  const [currentSubStep, setCurrentSubStep] = useState<companySubSteps>(
    companySubSteps.companyInformation
  );

  const renderSubStepContent = (currentSubStep: companySubSteps) => {
    const commonProps: ISubStepProps = {
      setCurrentStep: setCurrentStep,
      setCurrentSubStep: setCurrentSubStep,
      setShowHeading: setShowHeading,
    };

    switch (currentSubStep) {
      case companySubSteps.companyInformation:
        return <CompanyInformation {...commonProps} />;
      case companySubSteps.registeredAddress:
        return <RegisteredAddress {...commonProps} />;
      case companySubSteps.einLetter:
        return <EinLetter {...commonProps} />;
    }
  };

  return <>{renderSubStepContent(currentSubStep)}</>;
};

export default Company;
