import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { AffiliateCard } from "./AffiliateCard";
import { affiliatesData } from "./data";

import "swiper/css";

export const Affiliate = () => {
  return (
    <Swiper
      modules={[Autoplay]}
      loop
      autoplay={{
        delay: 3000,
        disableOnInteraction: true,
      }}
    >
      {affiliatesData.map((affiliate, index) => (
        <SwiperSlide key={index}>
          <AffiliateCard affiliate={affiliate} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
