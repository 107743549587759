import { ProductDuration } from "graphql/__generated__/types";
import { PRODUCTS_DB_KEYS } from "resources/productsLookupKeys";

export const STARTER = {
  name: "Starter",
  value: "STARTER",
};

export const TOTAL_COMPLIANCE = {
  name: "Total Compliance",
  value: "TOTAL_COMPLIANCE",
};

export const TOTAL_COMPLIANCE_ANNUAL = {
  name: "Total Compliance",
  value: "TOTAL_COMPLIANCE",
};

export type PackageValue = typeof STARTER.value | typeof TOTAL_COMPLIANCE.value;

export interface IPlan {
  value: PackageValue;
  name: string;
  displayName: string;
  description: string;
  hasMultiplePlans: boolean;
  duration: {
    [key in ProductDuration]?: {
      lookupKey: PRODUCTS_DB_KEYS;
      price?: number;
      stripePriceId?: string;
    };
  };
}

export interface IPlans {
  [key: string]: IPlan;
}
