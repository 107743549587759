import { STARTER, TOTAL_COMPLIANCE } from "../data";

interface IFeature {
  name: string;
  description: string;
  packages: {
    [packageValue: string]: boolean | string;
  };
}

interface IPackagesFeatures {
  name: string;
  features: IFeature[];
}

interface IPackagesFeaturesList extends Array<IPackagesFeatures> {}

export const PACKAGES_FEATURES: IPackagesFeaturesList = [
  {
    name: "Formation",
    features: [
      {
        name: "signup.packages.features.formationFilling",
        description:
          "doola navigates the complexities of business formation with the state. A step by step wizard guides you through organizing member information, picking a company name, and more. doola’s experts handle all the paperwork to ensure compliance with state regulations.",
        packages: {
          [STARTER.value]: true,
          [TOTAL_COMPLIANCE.value]: true,
        },
      },
      {
        name: "signup.packages.features.ein",
        description:
          "doola submits the paperwork to the Internal Revenue Service (IRS) on your behalf once the state has approved your company. The IRS will issue a 9 digit number that allows your business to open a bank account and hire employees in the US.",
        packages: {
          [STARTER.value]: true,
          [TOTAL_COMPLIANCE.value]: true,
        },
      },
      {
        name: "signup.packages.features.einExpedite",
        description:
          "Accelerate your ability to open a bank account and hire employees by using doola’s Certified Acceptance Agent (CAA). The CAA will act as your intermediary with the Internal Revenue Service (IRS) to skip the backlog and get your application processed sooner.",
        packages: {
          [STARTER.value]: "+$300 One-time",
          [TOTAL_COMPLIANCE.value]: true,
        },
      },
    ],
  },
  {
    name: "Compliance",
    features: [
      {
        name: "signup.packages.features.registeredAgent",
        description:
          "Ensure compliance and receive a level of privacy from doola’s Registered Agents. Available to act as your address of record with the state, they receive and forward legal documents, tax notices, and other official correspondence from the state or Internal Revenue Service (IRS).",
        packages: {
          [STARTER.value]: true,
          [TOTAL_COMPLIANCE.value]: true,
        },
      },
      {
        name: "signup.packages.features.operatingAgreement",
        description:
          "doola will provide the legal document that outlines the inner workings and structure of the Limited Liability Company (LLC) as set forth by you during formation. The operating agreement includes the rights, responsibilities, and obligations of the LLC's members (owners) and provides a framework for how the company will be managed and operated.",
        packages: {
          [STARTER.value]: true,
          [TOTAL_COMPLIANCE.value]: true,
        },
      },
      {
        name: "signup.packages.features.annualStateFilings",
        description:
          "Many states require yearly updates about company structure and charge performance based compliance fees, often called a Franchise Tax. doola handles the calculations and sends updates on your behalf. State charges are not included as part of the filing.",
        packages: {
          [STARTER.value]: "+$199 /yr",
          [TOTAL_COMPLIANCE.value]: true,
        },
      },
      {
        name: "signup.packages.features.boiFiling",
        description:
          "As of 2024, all companies are required to file a BOI report with FinCEN after forming a business in the US or upon changes in ownership structure. The BOI report contains information about the owners of your company to protect against fraudulent activity and money laundering.",
        packages: {
          [STARTER.value]: "+$149 One-time",
          [TOTAL_COMPLIANCE.value]: true,
        },
      },
    ],
  },
  {
    name: "Tax",
    features: [
      {
        name: "signup.packages.features.cpaConsultation",
        description:
          "Phone consultation with a licensed professional working with doola to provide valuable insights and decision making assistance in fields related to taxation, accounting, auditing, and other financial matters.",
        packages: {
          [STARTER.value]: "+$300 /hr",
          [TOTAL_COMPLIANCE.value]: "1 Free Consultation",
        },
      },
      {
        name: "signup.packages.features.irsTaxFiling",
        description:
          "doola can prepare your annual tax return for submission to the Internal Revenue Service (IRS). Experts at doola will keep you informed of changes to tax laws and help you claim any applicable credits.",
        packages: {
          [STARTER.value]: "+$1,999 /yr",
          [TOTAL_COMPLIANCE.value]: true,
        },
      },
    ],
  },
  {
    name: "Customer Support",
    features: [
      {
        name: "signup.packages.features.dedicatedAccountManager",
        description:
          "Dedicated point of contact for your business. Work with someone who knows you and your business. Share your goals with your Account Manager and rely on their expertise on how to get the most out of doola. They can also get you access to the latest tools, releases, and more.",
        packages: {
          [STARTER.value]: "+$600 /yr",
          [TOTAL_COMPLIANCE.value]: true,
        },
      },
    ],
  },
];
