const getHashed256Value = async (value: string) => {
  const trimmedLowerCasedValue = value.trim().toLowerCase();

  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(trimmedLowerCasedValue);

  // hash the message
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");

  return hashHex;
};

export default getHashed256Value;
