export enum PRODUCTS_DB_KEYS {
  STARTER = "FORMATION_FORM_ANNUAL",
  TOTAL_COMPLIANCE_MONTHLY = "TOTAL_COMPLIANCE_MONTHLY",
  TOTAL_COMPLIANCE_ANNUAL = "FORMATION_EVERYTHING_ANNUAL",
  EXPIDITE_EIN = "EXPIDITE_EIN_ONE_TIME",
  EXPEDITE_FORMATION = "EXPEDITE_FORMATION_ONE_TIME",
  ANNUAL_STATE_FILINGS = "ANNUAL_STATE_FILINGS",
  ANNUAL_TAX_PACKAGE = "ANNUAL_TAX_PACKAGE",
  RECORDKEEPING_INVOICE_ONLY_ANNUAL = "RECORDKEEPING_INVOICE_ONLY_ANNUAL",
  RECORDKEEPING_INVOICE_ONLY_MONTHLY = "RECORDKEEPING_INVOICE_ONLY_MONTHLY",
  RECORDKEEPING_COMPLETE_ANNUAL = "RECORDKEEPING_COMPLETE_ANNUAL",
  RECORDKEEPING_COMPLETE_MONTHLY = "RECORDKEEPING_COMPLETE_MONTHLY",
  CPA_CONSULTATIONS = "CPA_CONSULTATION_ONE_TIME",
  STATE_FEES_DB_KEY = "_LLC_FORMATION_FEE", // all the state fees keys start with state abbr and this string
  BOI_FILING = "BOI_FILING",
}
