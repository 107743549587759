import { Stack, Typography, useMediaQuery } from "@mui/material";
import ButtonBox from "components/ButtonBox";
import { useEffect, useState } from "react";
import { IStepProps } from "../../../types";
import utils, { colors } from "utils";
import { TaxFilingStepsCategories, TaxFilingStepsData } from "../../../data";
import { IMemberStepsProps } from "../types";
import {
  CorporateFareOutlined,
  PercentOutlined,
  PersonOutlineRounded,
  ReplayOutlined,
  WarningAmberRounded,
} from "@mui/icons-material";
import CustomInput from "components/Form/CustomInput";
import Button from "components/Button";
import useCompany from "graphql/hooks/UseCompany";
import { TaxMemberInfo } from "graphql/__generated__/types";
import { distributeSharesAmongMembers } from "utils/sharesDistribution";
import { useEditTaxMemberInfoMutation } from "graphql/__generated__/operations/EditTaxMemberInfo.generated";
import { format as formatDate } from "fecha";
import { usePostHog } from "posthog-js/react";
import { t } from "i18next";
import { isValidDateString } from "utils/helpers";

interface IMemberPercentageProps extends IStepProps, IMemberStepsProps {}

const MemberPercentage = ({
  setCurrentStep,
  resetData,
  setShowHeading,
}: IMemberPercentageProps) => {
  const posthog = usePostHog();
  const { currentCompany, isCompanyLoading } = useCompany();
  const [editTaxMemberInfo] = useEditTaxMemberInfoMutation();

  const [membersState, setMembersState] = useState<
    TaxMemberInfo[] | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [totalPercentage, setTotalPercentage] = useState<number>(0);

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  const members = currentCompany?.companyTaxInfo?.members;

  useEffect(() => {
    if (!membersState && members) {
      const numberOfMembers = members?.length;

      const defaultShares = distributeSharesAmongMembers(numberOfMembers);

      const formattedMembers = members.map((member, i) => {
        const { __typename, ownershipPercent, ...memberData } = member!;
        const { __typename: addressTypeName, ...addressData } =
          member?.address!;

        return {
          ownershipPercent: ownershipPercent || defaultShares[i],
          ...memberData,
          ssn: memberData.ssn || "",
          address: addressData,
        };
      });

      setMembersState(formattedMembers);
    }
  }, [members, membersState]);

  useEffect(() => {
    setShowHeading(false);

    return () => {
      setShowHeading(true);
    };
  }, [setShowHeading]);

  useEffect(() => {
    const totalPercentage = Math.fround(
      (membersState ?? []).reduce(
        (sum, member) => sum + (member.ownershipPercent ?? 0),
        0
      )
    );

    const hasAtLeastOneMemberWithoutPercentage = membersState?.some(
      (member) => !member?.ownershipPercent || member.ownershipPercent === 0
    );

    setTotalPercentage(+totalPercentage.toFixed(2));

    setHasError(
      hasAtLeastOneMemberWithoutPercentage || totalPercentage !== 100
    );
  }, [membersState]);

  const handleUpdateOwnership = (ownership: string, position: number) => {
    const ownershipFloat = parseFloat(ownership);

    if (isNaN(ownershipFloat)) {
      setMembersState((prevState) => {
        const updatedState = prevState?.map((member) =>
          member.position === position
            ? { ...member, ownershipPercent: 0 }
            : member
        );

        return updatedState;
      });
    } else {
      setMembersState((prevState) => {
        const updatedState = prevState?.map((member) =>
          member.position === position
            ? { ...member, ownershipPercent: ownershipFloat }
            : member
        );

        return updatedState;
      });
    }
  };

  const handleOnNext = async () => {
    try {
      setIsLoading(true);

      await Promise.all(
        (membersState ?? [])?.map(async (member) => {
          if (typeof member.position === "number") {
            return await editTaxMemberInfo({
              variables: {
                companyId: currentCompany?.id!,
                memberInfo: {
                  member: {
                    ...member,
                  },
                  position: member.position,
                },
              },
            });
          }
        })
      );

      setCurrentStep(
        TaxFilingStepsData[TaxFilingStepsCategories.companyInformation]
      );
      posthog?.capture(`tax_modal_percentage_next_clicked`);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onResetMembers = () => {
    setMembersState((prevState) => {
      const numberOfMembers = prevState?.length || 0;
      const defaultShares = distributeSharesAmongMembers(numberOfMembers);

      const updatedMemberState = (prevState ?? []).map((member, i) => ({
        ...member,
        ownershipPercent: defaultShares[i],
      }));

      return updatedMemberState;
    });
  };

  const isDisabled = isCompanyLoading || isLoading || hasError;

  return (
    <>
      <Stack mb={isTablet ? 9 : 3} gap={3}>
        <Stack>
          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
            <PercentOutlined />
            <Typography fontSize={16} fontWeight={500}>
              Ownership percentage
            </Typography>
          </Stack>
          <Typography fontSize={16} color={colors.contentTertiary} mt={0.5}>
            Typically this percentage is based on equity contributions at the
            time of formation and expected split of profits.
          </Typography>
        </Stack>
        <Stack gap={2}>
          {membersState &&
            membersState.map(
              (member, index) =>
                member && (
                  <Stack
                    key={index}
                    direction={"row"}
                    sx={{
                      bgcolor: colors.lightGrey,
                      borderRadius: "24px",
                      p: 2,
                      pl: 3,
                    }}
                  >
                    <Stack
                      direction={"row"}
                      gap={3}
                      alignItems={"center"}
                      width={"100%"}
                    >
                      {member.isNaturalPerson ? (
                        <PersonOutlineRounded />
                      ) : (
                        <CorporateFareOutlined />
                      )}
                      <Stack width={"100%"}>
                        {(member.contactFullName ||
                          member.legalFirstName ||
                          member.legalLastName) && (
                          <Typography fontSize={16} fontWeight={500}>
                            {member.contactFullName ??
                              `${member.legalFirstName} ${member.legalLastName}`}
                          </Typography>
                        )}
                        <Typography color={colors.contentTertiary}>
                          {(member.isNaturalPerson
                            ? isValidDateString(member.dob)
                              ? formatDate(
                                  new Date(member.dob ?? 0),
                                  "MM/DD/YYYY"
                                )
                              : t("general.missingInformation")
                            : member.entityType) ??
                            t("general.missingInformation")}
                        </Typography>
                      </Stack>
                    </Stack>
                    <CustomInput
                      type="number"
                      value={member.ownershipPercent}
                      inputProps={{ min: 0, max: 100, step: 1 }}
                      styles={{ width: "180px" }}
                      inputStyles={{
                        borderRadius: "1rem",
                        ...(hasError && { color: colors.red }),
                      }}
                      endIcon={
                        <PercentOutlined
                          sx={{ ...(hasError && { color: colors.red }) }}
                        />
                      }
                      onChange={(e) =>
                        handleUpdateOwnership(e.target.value, member.position!)
                      }
                    />
                  </Stack>
                )
            )}
          <Stack
            direction={"row"}
            sx={{
              bgcolor: colors.lightGrey,
              borderRadius: "24px",
              p: 2,
              pr: 3,
            }}
            justifyContent={"space-between"}
          >
            <Button
              styles={{ bgcolor: colors.grey }}
              dark={false}
              buttonText={"Reset"}
              endIcon={<ReplayOutlined />}
              onClick={onResetMembers}
            />
            <Stack direction={"row"} gap={1.5} alignItems={"center"}>
              <Typography
                color={colors.contentTertiary}
                sx={{ ...(hasError && { color: colors.red }) }}
              >
                {t("general.total")}
              </Typography>
              <Typography
                variant="h4"
                sx={{ ...(hasError && { color: colors.red }) }}
              >{`${totalPercentage}%`}</Typography>
            </Stack>
          </Stack>
        </Stack>
        {hasError && (
          <Stack
            sx={{ bgcolor: colors.lightRed, borderRadius: 6, p: 3 }}
            gap={2}
          >
            <WarningAmberRounded />
            <Stack>
              <Typography variant="body2" fontSize={"1rem"}>
                {t("general.ownershipPercentageError.1")}
              </Typography>
              <Typography variant="body1" fontSize={"1rem"}>
                {t("general.ownershipPercentageError.2")}
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
      <ButtonBox
        isLoading={isLoading}
        disabled={isDisabled}
        onClickBack={() => resetData()}
        onClickNext={handleOnNext}
      />
    </>
  );
};

export default MemberPercentage;
