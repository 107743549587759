import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MakeCompanyOwnerMutationVariables = Types.Exact<{
  companyId: Types.Scalars['String'];
  customerId: Types.Scalars['ID'];
}>;


export type MakeCompanyOwnerMutation = { __typename?: 'Mutation', customer?: { __typename?: 'CustomerMutation', makeCompanyOwner?: { __typename?: 'Customer', id?: string | null, email?: string | null } | null } | null };


export const MakeCompanyOwnerDocument = gql`
    mutation MakeCompanyOwner($companyId: String!, $customerId: ID!) {
  customer(id: $customerId) {
    makeCompanyOwner(new: $companyId) {
      id
      email
    }
  }
}
    `;
export type MakeCompanyOwnerMutationFn = Apollo.MutationFunction<MakeCompanyOwnerMutation, MakeCompanyOwnerMutationVariables>;

/**
 * __useMakeCompanyOwnerMutation__
 *
 * To run a mutation, you first call `useMakeCompanyOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeCompanyOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeCompanyOwnerMutation, { data, loading, error }] = useMakeCompanyOwnerMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useMakeCompanyOwnerMutation(baseOptions?: Apollo.MutationHookOptions<MakeCompanyOwnerMutation, MakeCompanyOwnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeCompanyOwnerMutation, MakeCompanyOwnerMutationVariables>(MakeCompanyOwnerDocument, options);
      }
export type MakeCompanyOwnerMutationHookResult = ReturnType<typeof useMakeCompanyOwnerMutation>;
export type MakeCompanyOwnerMutationResult = Apollo.MutationResult<MakeCompanyOwnerMutation>;
export type MakeCompanyOwnerMutationOptions = Apollo.BaseMutationOptions<MakeCompanyOwnerMutation, MakeCompanyOwnerMutationVariables>;