import { Grid, Stack } from "@mui/material";
import ProgressTracker from "./components/ProgressTracker";
import TrackInformation from "./components/TrackInformation";
import {
  Company,
  CompanyServices,
  ServiceCategory,
} from "graphql/__generated__/types";
import { StepStatusType, TrackerCategories, TrackerData } from "./data";
import { useCallback, useEffect, useState } from "react";
import { updateServiceStatus } from "./services";
import {
  CorporateFareOutlined,
  DescriptionOutlined,
  RocketOutlined,
} from "@mui/icons-material";
import Button from "components/Button";

type Props = {
  company: Company;
};

const Tracker = ({ company }: Props) => {
  const [selectedCategory, setSelectedCategory] = useState(
    TrackerCategories.company
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    Object.keys(TrackerData[selectedCategory].steps)[0]
  );
  const [status, setStatus] = useState<StepStatusType>({});

  const services = company.services;
  const isEinService = services?.some(
    (service) => service.service === CompanyServices.EINCREATION
  );

  const setSubCategory = useCallback(
    (category: string) => {
      const steps = Object.keys(TrackerData[category].steps);
      const firstStep = steps[0];
      const lastStep = steps[steps.length - 1];

      if (category !== TrackerCategories.essentials) {
        if (status[firstStep] === ServiceCategory.NOTSTARTED) {
          setSelectedSubCategory(firstStep);
        } else if (status[lastStep] === ServiceCategory.COMPLETED) {
          setSelectedSubCategory(lastStep);
        } else {
          const inProgressStep = steps.find(
            (step) => status[step] === ServiceCategory.INPROGRESS
          );

          if (inProgressStep) {
            setSelectedSubCategory(inProgressStep);
          } else {
            setSelectedSubCategory(firstStep);
          }
        }
      } else {
        setSelectedSubCategory(firstStep);
      }
    },
    [status]
  );

  useEffect(() => {
    setSubCategory(selectedCategory);
  }, [status, selectedCategory, setSubCategory]);

  const setCategory = useCallback(
    (category: string) => {
      setSubCategory(category);
      setSelectedCategory(category);
    },
    [setSubCategory]
  );

  useEffect(() => {
    if (services) {
      updateServiceStatus(services, setStatus);
    }
  }, [services]);

  return (
    <Grid container item xs={12} spacing={4}>
      <Grid container item component={Stack} gap={2} xs={12} direction={"row"}>
        {Object.values(TrackerCategories)
          .filter(
            (category) => !(!isEinService && category === TrackerCategories.ein)
          )
          .map((category, index) => (
            <Button
              key={index}
              dark={selectedCategory === category}
              startIcon={
                <>
                  {category === TrackerCategories.company && (
                    <CorporateFareOutlined />
                  )}
                  {category === TrackerCategories.ein && (
                    <DescriptionOutlined />
                  )}
                  {category === TrackerCategories.essentials && (
                    <RocketOutlined />
                  )}
                </>
              }
              buttonText={category}
              onClick={() => setCategory(category)}
            />
          ))}
      </Grid>
      <Grid container item xs={12} spacing={4}>
        <Grid item xs={12} md={4}>
          <ProgressTracker
            category={selectedCategory}
            subCategory={selectedSubCategory}
            setSubCategory={setSelectedSubCategory}
            status={status}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          {selectedSubCategory && (
            <TrackInformation
              category={selectedCategory}
              subCategory={selectedSubCategory}
              status={status}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Tracker;
