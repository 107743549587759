import { Stack, Typography, useMediaQuery } from "@mui/material";
import { ReactComponent as Audit } from "assets/Money/audit.svg";
import { useTranslation } from "react-i18next";
import utils from "utils";

const NoStatisticsUI = () => {
  const { t } = useTranslation();
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  return (
    <Stack alignItems={"center"} gap={3} height={"100%"}>
      <Audit width="100%" height={isTablet ? "120px" : "180px"} />
      <Stack textAlign={"center"}>
        <Typography mb={1} fontSize={16} fontWeight={500}>
          {t("money.overview.noStatistics.title")}
        </Typography>
        <Typography fontSize={16}>
          {t("money.overview.noStatistics.subtitle")}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default NoStatisticsUI;
