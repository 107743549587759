import relay from "assets/Affiliates/relay.svg";
import relay_cover from "assets/Affiliates/relay_cover.png";
import hubspot from "assets/Affiliates/hubspot.svg";
import hubspot_cover from "assets/Affiliates/hubspot_cover.png";
import openphone from "assets/Affiliates/openphone.svg";
import openphone_cover from "assets/Affiliates/openphone_cover.png";
import coverdash from "assets/Affiliates/coverdash.svg";
import coverdash_cover from "assets/Affiliates/coverdash_cover.png";
import vpm from "assets/Affiliates/vpm.svg";
import vpm_cover from "assets/Affiliates/vpm_cover.png";
import { colors } from "utils";

export interface Affiliate {
  name: string;
  buttonText: string;
  logo: string;
  cover: string;
  color: string;
  link: string;
  text: { type: string; source: string; list?: string }[];
}

export const affiliatesData: Affiliate[] = [
  {
    name: "relay",
    buttonText: "Get started",
    logo: relay,
    cover: relay_cover,
    color: colors.lightAccentGreen,
    link: "https://www.doola.com/go/banking-relay",
    text: [{ type: "para", source: "description" }],
  },
  {
    name: "hubspot",
    buttonText: "Get free CRM",
    logo: hubspot,
    cover: hubspot_cover,
    color: colors.lightAccentYellow,
    link: "https://www.doola.com/go/crm",
    text: [{ type: "para", source: "description" }],
  },
  {
    name: "openphone",
    buttonText: "Try for free",
    logo: openphone,
    cover: openphone_cover,
    color: colors.borderBlue,
    link: "https://get.openphone.com/doola",
    text: [{ type: "para", source: "description" }],
  },
  {
    name: "coverdash",
    buttonText: "Get a quote",
    logo: coverdash,
    cover: coverdash_cover,
    color: colors.lightAccentGreen,
    link: "https://www.coverdash.com/partners/doola/smb",
    text: [{ type: "para", source: "description" }],
  },
  {
    name: "vpm",
    buttonText: "Get started",
    logo: vpm,
    cover: vpm_cover,
    color: colors.borderBlue,
    link: "https://www.doola.com/go/mail",
    text: [
      { type: "para", source: "description" },
      { type: "para", source: "description2" },
      {
        type: "list",
        source: "description.serviceTitle",
        list: "description.services",
      },
    ],
  },
];
