import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoadingComponent } from "components/Loading";
import { ROUTER_PATHS } from "routes/routes";
// import { EntityType } from "graphql/__generated__/types";
// import { setEntityState, setEntityType } from "state/formationFormSlice";
// import { setCustomerEmail } from "state/apiSlice";
// import setCookie from "utils/setCookie";
import { useAppDispatch } from "state/hooks";
import { setCompanyName } from "state/formationFormSlice";
import { Box } from "@mui/material";
import bcrypt from "bcryptjs-react";

const EntryPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  // const entityTypes = [EntityType.CCORP, EntityType.DAO, EntityType.LLC];

  const navigateToLogin = () => {
    navigate({
      pathname: ROUTER_PATHS.LOGIN,
      search: searchParams.toString(),
    });
  };

  const navigateToPrePurchaseRegistration = () => {
    navigate({
      pathname: ROUTER_PATHS.PRE_PURCHASE_REGISTRATION,
      search: searchParams.toString(),
    });
  };

  useEffect(() => {
    const companyNamePreSelectedByQueryParams =
      searchParams.get("business_name");
    const hashedKey = searchParams.get("key");
    const email = searchParams.get("email")?.toLowerCase();
    // const entityPreSelectedByQueryParams = queryParams.get(
    //   "selectedEntity"
    // ) as EntityType;

    // const cjEventValue = searchParams.get("cjevent");
    // const partnerStackClickID = searchParams.get("ps_xid");
    // const partnerStackPartnerID = searchParams.get("ps_partner_key");

    if (companyNamePreSelectedByQueryParams) {
      dispatch(setCompanyName(companyNamePreSelectedByQueryParams));
    }

    if (email && hashedKey) {
      const password = `${email}password`;
      const isKeyAProperHash = bcrypt.compareSync(password, hashedKey);

      if (isKeyAProperHash) {
        // await loginAndFetchData(email, password);
        navigateToLogin();
      } else {
        navigateToPrePurchaseRegistration();
      }
    } else {
      navigateToPrePurchaseRegistration();
    }

    // if (
    //   entityPreSelectedByQueryParams &&
    //   entityTypes.includes(entityPreSelectedByQueryParams)
    // ) {
    //   dispatch(setEntityType(entityPreSelectedByQueryParams));
    // }

    // if (emailByQueryParams) {
    //   dispatch(setCustomerEmail(emailByQueryParams));
    // }

    // if (partnerStackClickID && partnerStackPartnerID) {
    //   setCookie({ cname: "ps_xid", cvalue: partnerStackClickID, exdays: 90 });
    //   setCookie({
    //     cname: "ps_partner_key",
    //     cvalue: partnerStackPartnerID,
    //     exdays: 90,
    //   });
    // }

    // if (cjEventValue) {
    //   setCookie({ cname: "cje", cvalue: cjEventValue, exdays: 390 });
    // }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box my={2}>
      <LoadingComponent />
    </Box>
  );
};

export default EntryPage;
