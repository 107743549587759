import { TaxMemberInfo } from "graphql/__generated__/types";
import { Dispatch, SetStateAction } from "react";

export interface IMemberStepsProps {
  setShowHeading: Dispatch<SetStateAction<boolean>>;
  resetData: () => void;
}

export interface IMemberInformationProps extends IMemberStepsProps {
  member: TaxMemberInfo;
}

export enum MemberTypes {
  COMPANY = "COMPANY",
  PERSON = "PERSON",
}
