import config from "config";
import TagManager from "react-gtm-module";
import { TrackerGA4 } from "./GA4tracking";
import {
  CompanyServices,
  Product,
  ProductDuration,
} from "graphql/__generated__/types";
import { IAddOnPackage } from "state/formationFormSlice";

interface ICJConversionData {
  userId: string;
  emailHash: string;
  orderId: string;
  amount: number;
  cje: string;
  items: {
    unitPrice: number;
    itemId: string;
    quantity: string;
  }[];
}

interface ITransactionCompleted {
  companyId: string;
  customerId: string;
  email: string;
  entityType: string;
  entityState: string;
  totalAmount: number;
  totalWithoutFees: number;
  paymentIntentId: string;
  stripeCustomerId: string;
  productName: string;
  productPrice: number;
  productStripePriceId: string;
  isMonthly: boolean;
  purchasedAddons?: IAddOnPackage[];
  stateProduct: Product | null | undefined;
}

const GA4Tracker = TrackerGA4.getInstance();

export class Tracker {
  public trackerId: string;
  private static instance: Tracker;
  private constructor() {
    this.trackerId = Math.floor(Math.random() * 10000) + "";
    TagManager.initialize(config.GTM);
  }
  public static getInstance(): Tracker {
    if (!Tracker.instance) {
      Tracker.instance = new Tracker();
    }
    return Tracker.instance;
  }

  public completeRegistration = (email: string) => {
    try {
      if ((window as any).fbq) {
        (window as any).fbq("track", "Complete Registation", {
          value: email,
        });
      }
    } catch (error) {
      console.error(error);
    }
    try {
      if ((window as any).ttq) {
        (window as any).ttq.identify({ email: email });

        (window as any).ttq.track("CompleteRegistration");
      }
    } catch (error) {
      console.error(error);
    }
  };

  public transactionComplete = (transactionTotalWithoutFees: number) => {
    try {
      if ((window as any).fbq) {
        (window as any).fbq("track", "Purchase", {
          currency: "USD",
          value: transactionTotalWithoutFees,
        });
      }
    } catch (error) {
      console.error(error);
    }
    try {
      if ((window as any).ttq) {
        (window as any).ttq.track("CompletePayment", {
          currency: "USD",
          value: transactionTotalWithoutFees,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  public conversionConfirmationCJ = (data: ICJConversionData) => {
    if (!(window as any).cj) (window as any).cj = {};

    (window as any).cj.order = {
      enterpriseId: "1565266", // required
      pageType: "conversionConfirmation",
      userId: data.userId,
      emailHash: data.emailHash,
      orderId: data.orderId, // required
      actionTrackerId: "436847", // required, please see Action Tracker Reference Table
      currency: "USD", // required
      amount: data.amount, // required
      cjeventOrder: data.cje,
      // discount: "<DISCOUNT>",
      // coupon: "<COUPON>",
      // campaignId: "<CAMPAIGNID>",
      // businessUnit: "<BUSINESSUNIT>",
      // delivery: "<DELIVERY>",
      paymentMethod: "stripe_payment",
      items: data.items,
    };
  };

  public trackPageView = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  };

  public trackProfitWell = (email?: string) => {
    const dataToSet = email
      ? { event: "start_pw", pw_user_email: email }
      : { event: "start_pw" };

    TagManager.dataLayer({
      dataLayer: dataToSet,
    });
  };

  public trackCompleteTransaction = (data: ITransactionCompleted) => {
    const {
      companyId,
      customerId,
      email,
      entityType,
      entityState,
      totalAmount,
      totalWithoutFees,
      paymentIntentId,
      stripeCustomerId,
      productName,
      productPrice,
      productStripePriceId,
      isMonthly,
      purchasedAddons,
      stateProduct,
    } = data;

    try {
      this.transactionComplete(totalWithoutFees);
    } catch (error) {
      console.error(error);
    }

    // Core items needed for GA
    const coreFormationItems = [
      {
        item_name: productName,
        item_id: productStripePriceId,
        price: productPrice,
        item_category: "Main Product",
        item_category2: CompanyServices.FORMATION,
        item_category3: isMonthly
          ? ProductDuration.MONTHLY
          : ProductDuration.ANNUAL,
        index: 0,
        quantity: 1,
        discount: 0,
        coupon: "",
      },
      {
        item_name: stateProduct?.name,
        item_id: stateProduct?.stripePricingId,
        price: (stateProduct?.usdPriceCents ?? 0) / 100,
        item_category: "Passthrough",
        item_category2: "State Fee",
        item_category3: ProductDuration.ONETIME,
        index: 1,
        quantity: 1,
        discount: 0,
        coupon: "",
      },
    ];

    GA4Tracker.purchaseGA4({
      customerId,
      productName,
      subscriptionRevenue: productPrice,
      purchase_data: {
        transactionId: paymentIntentId,
        companyId,
        customerId,
        email,
        entityType,
        entityState,
        stripeCustomerId,
        currency: "USD",
        monthlyBillingCycle: isMonthly
          ? ProductDuration.MONTHLY
          : ProductDuration.ANNUAL,
        mainProductName: productName,
        mainProductStripeId: productStripePriceId,
        mainProductPrice: productPrice,
        transactionTotal: totalAmount,
        transactionTotalWithoutFees: totalWithoutFees,
        items: [
          ...coreFormationItems,
          ...this._buildAddOnsPurchaseItems(purchasedAddons),
        ],
      },
    });
  };

  _buildAddOnsPurchaseItems = (AddOns?: IAddOnPackage[]) => {
    return AddOns
      ? AddOns.map((item, index) => {
          const {
            item_name,
            price,
            stripePriceId,
            item_category2,
            item_category3,
          } = item;

          return {
            item_name,
            item_id: stripePriceId,
            price,
            index: index + 2,
            item_category: "Add-on",
            item_category2,
            item_category3,
            quantity: 1,
            discount: 0,
            coupon: "",
          };
        })
      : [];
  };
}
