import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ButtonBox from "components/ButtonBox";
import utils, { colors } from "utils";
import { LoadingComponent } from "components/Loading";
import { format as formatDate } from "fecha";
import {
  AddRounded,
  CheckCircleOutlineRounded,
  CloseOutlined,
  CorporateFareOutlined,
  ErrorOutlineOutlined,
  PersonOutlineRounded,
  VisibilityOutlined,
} from "@mui/icons-material";
import CustomLoadingButton from "components/Button";
import { LoadingButton } from "@mui/lab";
import {
  OnboardingStakeholder,
  OnboardingStakeholderType,
  WalletOnboard,
} from "graphql/__generated__/types";
import useOnboardingData from "views/DashboardApp/Money/Onboarding/hooks/useOnboardingData";
import {
  OnboardingStepsData,
  OnboardingStepsCategories,
} from "views/DashboardApp/Money/Onboarding/data";
import { useState } from "react";
import AddMember from "./AddMember";
import { ISubStepProps, membersSubSteps } from "../../types";
import { useSetMoneyOnboardingMutation } from "graphql/__generated__/operations/MoneyOnboardingValidation.generated";
import ConfirmationDialog from "components/ConfirmationDialog";
import { ConfirmationDialogDetails } from "components/ConfirmationDialog/types";
import { MemberDialogFormModeEnum, MemberDialogStateProps } from "./types";
import { removeTypename } from "utils/helpers/removeTypename";
import { InfoToolTip } from "components/InfoToolTip";
import { t } from "i18next";
import { usePostHog } from "posthog-js/react";
import { isValidDateString } from "utils/helpers";

const checkForError = (stakeholder: OnboardingStakeholder) =>
  stakeholder?.validationErrors?.length ? (
    <InfoToolTip
      title={t("general.missingInformation") as string}
      description={
        <ul style={{ listStyleType: "disc", paddingLeft: "12px" }}>
          {Array.from(new Set(stakeholder.validationErrors)).map(
            (error, index) => (
              <li key={index}>{error}</li>
            )
          )}
        </ul>
      }
      icon={
        <ErrorOutlineOutlined
          sx={{
            fontSize: "18px",
            color: colors.lightContentWarning,
            display: "flex",
          }}
        />
      }
    />
  ) : (
    <CheckCircleOutlineRounded sx={{ fontSize: "18px", color: colors.green }} />
  );

const MemberList = ({ setCurrentStep, setCurrentSubStep }: ISubStepProps) => {
  const [memberDialogDetails, setMemberDialogDetails] =
    useState<MemberDialogStateProps>({
      show: false,
      stakeholderId: "",
      mode: MemberDialogFormModeEnum.add,
    });
  const [confirmationDialogDetails, setConfirmationDialogDetails] =
    useState<ConfirmationDialogDetails>({ show: false, id: "" });

  const { onboardingData, isOnboardingDataLoading } = useOnboardingData();
  const [updateMoneyOnboarding] = useSetMoneyOnboardingMutation();

  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const posthog = usePostHog();

  if (isOnboardingDataLoading) {
    return (
      <Stack height={"200px"} alignItems={"center"} justifyContent={"center"}>
        <LoadingComponent />
      </Stack>
    );
  }

  const handleRemoveStakeholder = async (stakeholderId: string) => {
    const filteredOnboardingData: WalletOnboard =
      removeTypename(onboardingData);

    setConfirmationDialogDetails({ show: false, id: "" });

    try {
      await updateMoneyOnboarding({
        variables: {
          ...filteredOnboardingData,
          stakeholders: filteredOnboardingData?.stakeholders?.filter(
            (stakeholder) => stakeholder?.uuid !== stakeholderId
          ),
        },
      });
    } catch (error) {
      console.log("Error while removing the stakeholder: ", error);
    }
  };

  const hasAtleastOneValidationErrForStakeholder =
    onboardingData?.stakeholders?.some((member) => {
      return member?.validationErrors?.length;
    });

  const hasStakeholders = onboardingData?.stakeholders?.length;

  const isDisabled =
    hasAtleastOneValidationErrForStakeholder || !hasStakeholders;

  return (
    <>
      {memberDialogDetails.show && (
        <AddMember
          memberDialogDetails={memberDialogDetails}
          setMemberDialogDetails={setMemberDialogDetails}
        />
      )}
      {confirmationDialogDetails.show && (
        <ConfirmationDialog
          confirmationDialogDetails={confirmationDialogDetails}
          setConfirmationDialogDetails={setConfirmationDialogDetails}
          handleOnConfirmation={(id: string) => handleRemoveStakeholder(id)}
          title={t("money.onboarding.step.membersInformation.removeMember")}
          body={t("money.onboarding.step.membersInformation.removeMemberBody")}
          handleOnConfirmationBtnText="Remove member"
        />
      )}
      <Stack mt={isTablet ? 3 : 4} mb={5}>
        <Grid container spacing={isTablet ? 2 : 3}>
          <Grid item xs={12} sm={6} md={4}>
            <Stack
              width={"100%"}
              minHeight={"200px"}
              height={"100%"}
              component={Button}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                bgcolor: colors.lightGrey,
                borderRadius: 6,
                p: 3,
                transition: "all .3s ease-out",
                "&:hover": {
                  bgcolor: colors.grey,
                },
              }}
              onClick={() => {
                posthog?.capture("money_onboarding_add_stakeholder_click");

                setMemberDialogDetails({
                  show: true,
                  stakeholderId: "",
                  mode: MemberDialogFormModeEnum.add,
                });
              }}
            >
              <Stack borderRadius={"50%"} bgcolor={colors.grey} p={2}>
                <AddRounded />
              </Stack>
            </Stack>
          </Grid>
          {onboardingData?.stakeholders &&
            onboardingData?.stakeholders.map(
              (stakeholder) =>
                stakeholder && (
                  <Grid key={stakeholder.uuid} item xs={12} sm={6} md={4}>
                    <Stack
                      minHeight={"200px"}
                      justifyContent={"space-between"}
                      bgcolor={colors.lightGrey}
                      borderRadius={6}
                      p={3}
                    >
                      <Box>
                        {stakeholder.type ===
                        OnboardingStakeholderType.PERSON ? (
                          <PersonOutlineRounded />
                        ) : (
                          <CorporateFareOutlined />
                        )}
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          gap={0.5}
                        >
                          {stakeholder.type ===
                          OnboardingStakeholderType.PERSON ? (
                            (stakeholder?.firstName ||
                              stakeholder?.lastName) && (
                              <Typography
                                fontSize={16}
                                fontWeight={500}
                                sx={{
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                }}
                              >
                                {`${stakeholder.firstName} ${stakeholder.lastName}`}
                              </Typography>
                            )
                          ) : (
                            <Typography
                              fontSize={16}
                              fontWeight={500}
                              sx={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {stakeholder.businessName}
                            </Typography>
                          )}
                          {checkForError(stakeholder)}
                        </Stack>
                        <Typography color={colors.contentTertiary}>
                          {stakeholder.validationErrors?.length
                            ? t("general.missingInformation")
                            : stakeholder.type ===
                                OnboardingStakeholderType.PERSON &&
                              isValidDateString(stakeholder.dateOfBirth)
                            ? formatDate(
                                new Date(stakeholder.dateOfBirth ?? 0),
                                "MM/DD/YYYY"
                              )
                            : null}
                        </Typography>
                      </Box>
                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        gap={1}
                        mt={3}
                      >
                        <CustomLoadingButton
                          dark={false}
                          endIcon={<VisibilityOutlined />}
                          buttonText={t("general.review")}
                          onClick={() =>
                            setMemberDialogDetails({
                              show: true,
                              stakeholderId: stakeholder.uuid || "",
                              mode: MemberDialogFormModeEnum.edit,
                              onboardingStakeholderType: stakeholder.type,
                            })
                          }
                          styles={{
                            width: "100%",
                            bgcolor: colors.grey,
                          }}
                        />
                        <LoadingButton
                          onClick={() =>
                            setConfirmationDialogDetails({
                              show: true,
                              id: stakeholder.uuid || "",
                            })
                          }
                          sx={{
                            minWidth: 0,
                            borderRadius: "50%",
                            bgcolor: colors.grey,
                            px: 2,
                          }}
                        >
                          <CloseOutlined
                            sx={{
                              fontSize: "18px",
                              color: colors.red,
                            }}
                          />
                        </LoadingButton>
                      </Stack>
                    </Stack>
                  </Grid>
                )
            )}
        </Grid>
      </Stack>
      <ButtonBox
        disabled={isDisabled}
        onClickBack={() => {
          posthog?.capture("money_onboarding_stakeholder_back_btn_click");

          setCurrentStep(
            OnboardingStepsData[OnboardingStepsCategories.companyInformation]
          );
        }}
        onClickNext={() => {
          posthog?.capture("money_onboarding_stakeholder_next_btn_click");

          setCurrentSubStep(membersSubSteps.ownershipPercentage);
        }}
      />
    </>
  );
};

export default MemberList;
