import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompanyServices, ProductDuration } from "graphql/__generated__/types";
import { IPackage } from "views/SignupFlow/Packages";
import { RootState } from "./store";

export interface IAddOnPackage {
  item_name: string;
  stripePriceId: string;
  lookupKey: string;
  price: number;
  item_category2: CompanyServices; // required property for GA. A service name in the system
  item_category3: ProductDuration; // required property for GA. Billing cycle
}

interface IFormationFormState {
  companyName?: string;
  formationPackage?: IPackage;
  expeditedFormation?: IAddOnPackage;
  boiFilingFormation?: IAddOnPackage;
}

export const initialFormationFormState: IFormationFormState = {};

export const formationFormSlice = createSlice({
  name: "formationForm",
  initialState: { ...initialFormationFormState },
  reducers: {
    setCompanyName: (state, action: PayloadAction<string>) => {
      state.companyName = action.payload;
    },
    setFormationPackage: (state, action: PayloadAction<IPackage>) => {
      state.formationPackage = action.payload;
    },
    setExpeditedFormationPackage: (
      state,
      action: PayloadAction<IAddOnPackage | undefined>
    ) => {
      state.expeditedFormation = action.payload;
    },
    setBoiFilingFormationPackage: (
      state,
      action: PayloadAction<IAddOnPackage | undefined>
    ) => {
      state.boiFilingFormation = action.payload;
    },
  },
});

export const {
  setCompanyName,
  setFormationPackage,
  setExpeditedFormationPackage,
  setBoiFilingFormationPackage,
} = formationFormSlice.actions;

export const selectCompanyName = (state: RootState) =>
  state.formationForm.companyName;
export const selectFormationPackage = (state: RootState) =>
  state.formationForm.formationPackage;
export const selectExpeditedFormationPackage = (state: RootState) =>
  state.formationForm.expeditedFormation;
export const selectBoiFilingFormationPackage = (state: RootState) =>
  state.formationForm.boiFilingFormation;

export default formationFormSlice.reducer;
