import { AddBeneficiarySteps } from "./data";
import BeneficiaryInformation from "./Steps/BeneficiaryInformation";
import TransferMethodInformation from "./Steps/TransferMethodInformation";
import { IStepProps } from "./types";
import { Dispatch, SetStateAction } from "react";

interface IStepContentProps extends IStepProps {
  currentStep: AddBeneficiarySteps;
  onCloseModalHandler: () => void;
  setIsFinalStep: Dispatch<SetStateAction<boolean>>;
}

const StepContent = ({
  currentStep,
  setCurrentStep,
  onCloseModalHandler,
  setIsFinalStep,
  beneficiaryData,
  setBeneficiaryData,
  beneficiaryHashId,
}: IStepContentProps) => {
  const renderStepContent = (currentStep: AddBeneficiarySteps) => {
    const commonProps: IStepProps = {
      setCurrentStep,
      beneficiaryData,
      setBeneficiaryData,
      beneficiaryHashId,
    };

    switch (currentStep) {
      case AddBeneficiarySteps.beneficiaryInformation:
        return (
          <BeneficiaryInformation
            {...commonProps}
            onCloseModalHandler={onCloseModalHandler}
          />
        );
      case AddBeneficiarySteps.transferMethodInformation:
        return (
          <TransferMethodInformation
            {...commonProps}
            setIsFinalStep={setIsFinalStep}
          />
        );
    }
  };

  return <>{renderStepContent(currentStep)}</>;
};

export default StepContent;
